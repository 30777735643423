import React, { Fragment, useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import { fechaActualFunc } from '../../../shared/fechas';

moment.locale("es");

const AnioSelector = (props) => {
  const [periodo, setPeriodo] = useState();
  const [maxDate] = useState(new Date(new Date().getFullYear() + 2, 11, fechaActualFunc().getDay()));

  useEffect(() => {
    if(!periodo){
      let dateString = `01-01-${fechaActualFunc().getFullYear()}`;

      if(props.periodoSeleccionado){
        dateString = `01-01-${props.periodoSeleccionado.format('YYYY')}`;
      }
      
      let date = moment(dateString, "MM-DD-YYYY");
      setPeriodo(date);
    }
  }, [periodo, props.periodoSeleccionado]);

  const onSetPeriodo = (value) => {
    setPeriodo(value);
    props.setPeriodoSeleccionado(value);
  }

  return <Fragment>
    <MuiPickersUtilsProvider 
      libInstance={moment}
      utils={MomentUtils}
      locale={"es"}>
      <KeyboardDatePicker
        disableToolbar
        views={['year']}
        variant="inline"
        format="yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Seleccionar período"
        value={periodo}
        onChange={(value) => onSetPeriodo(value)}
        disableFuture={true}
        minDate={new Date(1990, 0, 1)}
        maxDate={maxDate}
        autoOk={true}
      />                  
    </MuiPickersUtilsProvider>
  </Fragment>
}

export default AnioSelector;