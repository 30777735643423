import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, Select, Input, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import { formatNumber, formatNumberWithoutDecimals } from '../../../../shared/formatNumber';
import { storageFechaFija, storageMonedaSigno } from '../../../../shared/sessionData';
import * as actions from '../../../../store/actions/index';
import classes from './PieTableVentasMontoUnidadPorSeleccion.css';
import PieVentasMontoUnidadPorSeleccion from './PieVentasMontoUnidadPorSeleccion';
import TablaVentasMontoUnidadPorSeleccion from './TablaVentasMontoUnidadPorSeleccion';

const PieTableVentasMontoUnidadPorSeleccion = (props) => {
  const { dataSelectedId, dataSelectOptions, ejecucionDePagina, fechaVentasDelMes,
    vendedorId, filtroSeleccionado } = props;
  const [relatedChartVentas, setRelatedChartVentas] = useState([]);
  const [filtroParent, setFiltroParent] = useState([]);

  const dataOptions = {
    marca: {
      title: 'Marca',
      stateKey: 'ventasPorMarca',
      onLoadKey: 'onLoadVentasPorMarca',
      nextTitle: 'Rubro',
    },
    rubro: {
      title: 'Rubro',
      stateKey: 'ventasPorRubro',
      onLoadKey: 'onLoadVentasPorRubro',
      nextTitle: 'Artículo',
    },
    subrubro: {
      title: 'Subrubro',
      stateKey: 'ventasPorSubrubro',
      onLoadKey: 'onLoadVentasPorSubrubro',
      nextTitle: 'Artículo',
    },
    provincia: {
      title: 'Provincia',
      stateKey: 'ventasPorProvincia',
      onLoadKey: 'onLoadVentasPorProvincia',
      nextTitle: 'Cliente',
    },
    zona: {
      title: 'Zona',
      stateKey: 'ventasPorZona',
      onLoadKey: 'onLoadVentasPorZona',
      nextTitle: 'Cliente',
    },
    unidaddenegocio: {
      title: 'Unidad de negocio',
      stateKey: 'ventasPorUnidadDeNegocio',
      onLoadKey: 'onLoadVentasPorUnidadDeNegocio',
      nextTitle: 'Canal',
    },
    canal: {
      title: 'Canal',
      stateKey: 'ventasPorCanal',
      onLoadKey: 'onLoadVentasPorCanal',
      nextTitle: 'Cliente',
    },
    grupodecompra: {
      title: 'Grupo de compra',
      stateKey: 'ventasPorGrupoDeCompra',
      onLoadKey: 'onLoadVentasPorGrupoDeCompra',
      nextTitle: 'Cliente',
    },
    responsable: {
      title: 'Responsable',
      stateKey: 'ventasPorResponsable',
      onLoadKey: 'onLoadVentasPorResponsable',
      nextTitle: 'Vendedor',
    },
    cliente: {
      title: 'Cliente',
      stateKey: 'ventasPorCliente',
      onLoadKey: 'onLoadVentasPorCliente',
      nextTitle: 'Rubro',
    },
    articulo: {
      title: 'Artículo',
      stateKey: 'ventasPorArticulo',
      onLoadKey: 'onLoadVentasPorArticulo',
      nextTitle: 'Cliente',
    },
    vendedor: {
      title: 'Vendedor',
      stateKey: 'ventasPorVendedor',
      onLoadKey: 'onLoadVentasPorVendedor',
      nextTitle: 'Cliente',
    },
    categoriacliente: {
      title: 'Categoría de Cliente',
      stateKey: 'ventasPorCategoriaCliente',
      onLoadKey: 'onLoadVentasPorCategoriaDeCliente',
      nextTitle: 'Cliente',
    }
  };

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  const getFechaVentas = () => {
    const fechaDeDatos = props[dataOptions[dataSelectedId].stateKey] && props[dataOptions[dataSelectedId].stateKey].fecha;
    return fechaDeDatos ? fechaDeDatos : fechaVentasDelMes;
  }

  const getFechasForLoad = () => {
    let result = {};

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      result = {
        mesDesde: filtroSeleccionado.desde.getMonth() + 1,
        anioDesde: filtroSeleccionado.desde.getFullYear(),
        mesHasta: filtroSeleccionado.hasta.getMonth() + 1,
        anioHasta: filtroSeleccionado.hasta.getFullYear()
      }
    } else {
      const fecha = getFechaVentas();
      result = {
        mesDesde: fecha.mes ? fecha.mes : fecha.getMonth() + 1,
        anioDesde: fecha.anio ? fecha.anio : fecha.getFullYear(),
        mesHasta: fecha.mes ? fecha.mes : fecha.getMonth() + 1,
        anioHasta: fecha.anio ? fecha.anio : fecha.getFullYear(),
      }
    }

    return result;
  }

  const getFechaVentasTitle = () => {
    let title = "";

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      title = `${moment(filtroSeleccionado.desde).format('MM/YYYY')} al ${moment(filtroSeleccionado.hasta).format('MM/YYYY')}`
    } else {
      const fecha = getFechaVentas();
      title = moment(fecha ? fecha : storageFechaFija()).format('MM/YYYY');
    }

    return title;
  }

  useEffect(() => {
    let ventas = props[dataOptions[dataSelectedId].stateKey] && props[dataOptions[dataSelectedId].stateKey].ventas;
    if (ejecucionDePagina && ventas && ventas.length <= 0 && relatedChartVentas.length > 0) {
      clearRelatedChart();
    }
  }, [ejecucionDePagina, dataOptions, dataSelectedId, relatedChartVentas]);

  useEffect(() => {
    if (ejecucionDePagina && !props[dataOptions[dataSelectedId].stateKey].estaCargado) {
      const fecha = getFechasForLoad();
      props[dataOptions[dataSelectedId].onLoadKey](fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta);
    }
  }, [ejecucionDePagina, fechaVentasDelMes, dataSelectedId, filtroSeleccionado]);

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    clearRelatedChart();
    props.onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const loading = props[dataOptions[dataSelectedId].stateKey].loading;

    return (
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 400, margin: '10px 0px 0px 10px' }}>
        <div className={classes.LabelVerPor}>
          Ver ventas del{' '}
          {loading ? '--/----' : getFechaVentasTitle()} por:{' '}
        </div>
        <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: '5px' }}
          value={props.dataSelectedId}
          onChange={(e) => handleChangeOptionSelected(e)}
        >
          {dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id}>
                {option.nombre}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  //#region Click Chart
  const [relatedChartTitle, setRelatedChartTitle] = useState('Ventas por');
  const [relatedChartFilename, setRelatedChartFilename] = useState('');
  const [relatedCharLoading, setRelatedChartLoading] = useState(false);
  const [relatedChartTotal, setRelatedChartTotal] = useState('Total:');
  const [relatedChartType, setRelatedChartType] = useState('');

  const getCurrentLevel = () => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    return item;
  }

  const getNextLevel = () => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    return item ? item.subNivel : undefined;
  }

  const clearRelatedChart = () => {
    setRelatedChartTitle('Ventas por');
    setRelatedChartVentas([]);
    setRelatedChartTotal('Total:');
    setRelatedChartType('monto');
    setRelatedChartLoading(false);
    clearSecondLevelChart();
    setFiltroParent([]);
  }

  const onPorcionClickFail = () => {
    setRelatedChartLoading(false);
  }

  const onPorcionMontoClickSuccess = useCallback((response) => {
    setRelatedChartVentas(response);
    let total = 0;
    response.forEach((venta) => {
      total += venta.monto;
    });

    setRelatedChartTotal(`Total: ${storageMonedaSigno()} ${getNumberWithDecimals(total)}`);
    setTimeout(() => setRelatedChartLoading(false), 500);
  }, [props, dataOptions, dataSelectedId]);

  const onPorcionUnidadesClickSuccess = useCallback((response) => {
    setRelatedChartVentas(response);

    let title = dataOptions[dataSelectedId].title;
    let totalUnidades = 0;
    response.forEach((venta) => {
      totalUnidades += venta.unidadesVendidas;
    });

    setRelatedChartTotal(`Total: ${formatNumberWithoutDecimals(totalUnidades)}`);
    setRelatedChartVentas(response);

    if (title === 'Vendedor')
      props.onClearVendedorId();

    setTimeout(() => setRelatedChartLoading(false), 500);
  }, [props, dataOptions, dataSelectedId]);

  const getTitle = useCallback((isMonto, item, currentKey, nextKey, addLineBreak = false) => {
    let title = dataOptions[currentKey].title;
    let nextTitle = dataOptions[nextKey].title;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        {isMonto
          ? <span>Ventas por importe neto{addLineBreak && (<Fragment><br />{'>'}</Fragment>)} por {nextTitle}</span>
          : <span>Ventas por unidades{addLineBreak && (<Fragment><br />{'>'}</Fragment>)} por {nextTitle}</span>
        }
        <span><small>{`${title}: ${item}`}</small></span>
      </div>
    )
  }, [dataOptions]);

  const getHeaderTitle = useCallback((nextKey) => {
    return dataOptions[nextKey].title;
  }, [dataOptions]);

  const onPorcionMontoClick = useCallback((config) => {
    const currentLevel = getCurrentLevel();
    const nextLevel = getNextLevel();

    if (!currentLevel || !nextLevel)
      return;

    let item = config.w.config.labels[config.dataPointIndex];
    const idField = 'id';
    const field = 'nombre';

    if (item !== 'OTROS') {
      clearRelatedChart();
      setRelatedChartLoading(true);
      setRelatedChartTitle(getTitle(true, item, currentLevel.key.toLowerCase(), nextLevel.key.toLowerCase()));
      setRelatedChartType('monto');

      let filename = `Ventas por importe neto de ${dataOptions[dataSelectedId].title}: ${item}`;
      setRelatedChartFilename(filename);

      let ventas = props[dataOptions[dataSelectedId].stateKey].ventas;
      let venta = ventas && ventas.find((v) => {
        let vField = v[field];

        if (vField !== null && vField !== undefined)
          return vField.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (venta !== null && venta !== undefined) {
        const fecha = getFechasForLoad();
        props.onLoadVentasDesagregadasDelMes(venta[idField], currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtroParent,
          onPorcionMontoClickSuccess, onPorcionClickFail);

        const parent = getFiltrosParent(venta[idField], currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [props, dataOptions, dataSelectedId, filtroParent]);

  const onPorcionUnidadesClick = useCallback((config) => {
    const currentLevel = getCurrentLevel();
    const nextLevel = getNextLevel();

    if (currentLevel === null || currentLevel === undefined || nextLevel === null || nextLevel === undefined)
      return;

    let item = config.w.config.labels[config.dataPointIndex];
    const idField = 'id';
    const field = 'nombre';

    if (item !== 'OTROS') {
      clearRelatedChart();
      setRelatedChartLoading(true);
      setRelatedChartTitle(getTitle(false, item, currentLevel.key.toLowerCase(), nextLevel.key.toLowerCase()));
      setRelatedChartType('unidadesVendidas');

      let filename = `Ventas por unidades de ${dataOptions[dataSelectedId].title}: ${item}`;
      setRelatedChartFilename(filename);

      let ventas = props[dataOptions[dataSelectedId].stateKey].ventas;
      let venta = ventas && ventas.find((v) => {
        let vField = v[field];

        if (vField !== null && vField !== undefined)
          return vField.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (venta) {
        const fecha = getFechasForLoad();
        props.onLoadVentasDesagregadasDelMes(venta[idField], currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtroParent,
          onPorcionUnidadesClickSuccess, onPorcionClickFail);

        const parent = getFiltrosParent(venta[idField], currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [props, dataOptions, dataSelectedId, filtroParent]);

  //#endregion

  //#region RELATED CHARTS
  const getNumberRelatedChart = (dataSelectedId) => {
    let item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    let relatedCharts = 0;

    while (item && item.subNivel) {
      item = item.subNivel;
      relatedCharts++;
    }

    return relatedCharts;
  }

  const showRelatedCharts = () => {
    const relatedCharts = getNumberRelatedChart(dataSelectedId);
    return relatedCharts ? relatedCharts > 1 : false;
  }

  const getRelatedChart = () => {
    const relatedCharts = getNumberRelatedChart(dataSelectedId);
    return relatedCharts ? relatedCharts - 1 : 0;
  }

  const relatedChartMd = () => {
    var relatedChart = getRelatedChart();
    return relatedChart === 1 ? 12 : 6;
  }

  const relatedChartLg = () => {
    var relatedChart = getRelatedChart();
    return relatedChart < 4 ? 4 : 3;
  }

  const getFiltrosParent = useCallback((id, currentLevel, filtros) => {
    let tempFiltros = [];

    if (filtros && filtros.length > 0) {
      tempFiltros = tempFiltros.concat(filtros);
    }

    const existItem = tempFiltros.find((filtro) => filtro.entidad === currentLevel);

    if (existItem) {
      tempFiltros.forEach((filtro) => {
        if (filtro.entidad === currentLevel)
          filtro.id = id;
      });
    } else {
      tempFiltros.push({ id: id, entidad: currentLevel });
    }

    return tempFiltros;
  }, []);

  //#region Second Level Chart
  const [secondLevelChartTitle, setSecondLevelChartTitle] = useState('Ventas por');
  const [secondLevelHeaderTitle, setSecondLevelHeaderTitle] = useState('');
  const [secondLevelChartFilename, setSecondLevelChartFilename] = useState('');
  const [secondLevelChartLoading, setSecondLevelChartLoading] = useState(false);
  const [secondLevelChartVentas, setSecondLevelChartVentas] = useState([]);
  const [secondLevelChartTotal, setSecondLevelChartTotal] = useState('Total:');

  const clearSecondLevelChart = () => {
    setSecondLevelChartTitle('Ventas por');
    setSecondLevelHeaderTitle('');
    setSecondLevelChartVentas([]);
    setSecondLevelChartTotal('Total:');
    setSecondLevelChartLoading(false);
    clearThirdLevelChart();
  }

  const onPorcionClickSecondLevelChartFail = () => {
    setSecondLevelChartLoading(false);
  }

  const onPorcionClickSecondLevelChartSuccess = useCallback((response) => {
    setSecondLevelChartVentas(response);
    let total = 0;
    response.forEach((venta) => {
      total += venta[relatedChartType];
    });

    let chartTotal = relatedChartType === 'monto'
      ? `Total: ${storageMonedaSigno()} ${getNumberWithDecimals(total)}`
      : `Total: ${formatNumberWithoutDecimals(total)}`;
    setSecondLevelChartTotal(chartTotal);
    setTimeout(() => setSecondLevelChartLoading(false), 500);
  }, [relatedChartType]);

  const onPorcionClickSecondLevel = useCallback((config) => {
    const currentLevel = getNextLevel();
    const nextLevel = currentLevel ? currentLevel.subNivel : undefined;

    if (currentLevel === null || currentLevel === undefined || nextLevel === null || nextLevel === undefined)
      return;

    let item = config.w.config.labels[config.dataPointIndex];

    if (item !== 'OTROS') {
      const isMonto = relatedChartType === 'monto';
      let currentKey = currentLevel.key.toLowerCase();
      clearSecondLevelChart();
      setSecondLevelChartLoading(true);
      setSecondLevelChartTitle(getTitle(isMonto, item, currentKey, nextLevel.key.toLowerCase()));
      setSecondLevelHeaderTitle(getHeaderTitle(nextLevel.key.toLowerCase()));

      let filename = `Ventas por ${isMonto ? 'importe neto' : 'unidades'} de ${dataOptions[currentKey].title}: ${item}`;
      setSecondLevelChartFilename(filename);

      let ventas = relatedChartVentas;
      let venta = ventas && ventas.find((vField) => {
        if (vField && vField.nombre !== null && vField.nombre !== undefined)
          return vField.nombre.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (venta !== null && venta !== undefined) {
        const fecha = getFechasForLoad();
        props.onLoadVentasDesagregadasDelMes(venta.id, currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtroParent,
          onPorcionClickSecondLevelChartSuccess, onPorcionClickSecondLevelChartFail);
        const parent = getFiltrosParent(venta.id, currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [relatedChartType, relatedChartVentas, dataSelectOptions, dataSelectedId, filtroParent]);

  const getSecondLevel = (idSelected) => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === idSelected);
    return item ? item.subNivel ? item.subNivel : undefined : undefined;
  }

  const getSecondLevelKey = (idSelected) => {
    const item = getSecondLevel(idSelected);
    return item ? item.key.toLowerCase() : undefined;
  }

  //#endregion

  //#region Third Level Chart
  const [thirdLevelChartTitle, setThirdLevelChartTitle] = useState('Ventas por');
  const [thirdLevelHeaderTitle, setThirdLevelHeaderTitle] = useState('');
  const [thirdLevelChartFilename, setThirdLevelChartFilename] = useState('');
  const [thirdLevelChartLoading, setThirdLevelChartLoading] = useState(false);
  const [thirdLevelChartVentas, setThirdLevelChartVentas] = useState([]);
  const [thirdLevelChartTotal, setThirdLevelChartTotal] = useState('Total:');

  const clearThirdLevelChart = () => {
    setThirdLevelChartTitle('Ventas por');
    setThirdLevelHeaderTitle('');
    setThirdLevelChartVentas([]);
    setThirdLevelChartTotal('Total:');
    setThirdLevelChartLoading(false);
    clearFourthLevelChart();
  }

  const onPorcionClickThirdLevelChartFail = () => {
    setThirdLevelChartLoading(false);
  }

  const onPorcionClickThirdLevelChartSuccess = useCallback((response) => {
    setThirdLevelChartVentas(response);
    let total = 0;
    response.forEach((venta) => {
      total += venta[relatedChartType];
    });

    let chartTotal = relatedChartType === 'monto'
      ? `Total: ${storageMonedaSigno()} ${getNumberWithDecimals(total)}`
      : `Total: ${formatNumberWithoutDecimals(total)}`;
    setThirdLevelChartTotal(chartTotal);
    setTimeout(() => setThirdLevelChartLoading(false), 500);
  }, [relatedChartType]);

  const onPorcionClickThirdLevel = useCallback((config) => {
    const currentLevel = getThirdLevel(dataSelectedId);
    const nextLevel = currentLevel ? currentLevel.subNivel : undefined;

    if (currentLevel === null || currentLevel === undefined || nextLevel === null || nextLevel === undefined)
      return;

    let item = config.w.config.labels[config.dataPointIndex];

    if (item !== 'OTROS') {
      const isMonto = relatedChartType === 'monto';
      let currentKey = currentLevel.key.toLowerCase();
      clearThirdLevelChart();
      setThirdLevelChartLoading(true);
      setThirdLevelChartTitle(getTitle(isMonto, item, currentKey, nextLevel.key.toLowerCase()));
      setThirdLevelHeaderTitle(getHeaderTitle(nextLevel.key.toLowerCase()));

      let filename = `Ventas por ${isMonto ? 'importe neto' : 'unidades'}  de ${dataOptions[currentKey].title}: ${item}`;
      setThirdLevelChartFilename(filename);

      let ventas = secondLevelChartVentas;
      let venta = ventas && ventas.find((vField) => {
        if (vField && vField.nombre !== null && vField.nombre !== undefined)
          return vField.nombre.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (venta !== null && venta !== undefined) {
        const fecha = getFechasForLoad();
        props.onLoadVentasDesagregadasDelMes(venta.id, currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtroParent,
          onPorcionClickThirdLevelChartSuccess, onPorcionClickThirdLevelChartFail);
        const parent = getFiltrosParent(venta.id, currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [relatedChartType, secondLevelChartVentas, dataSelectOptions, dataSelectedId, filtroParent]);

  const getThirdLevel = (idSelected) => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === idSelected);
    const secondItem = item ? item.subNivel ? item.subNivel : undefined : undefined;
    return secondItem ? secondItem.subNivel : undefined;
  }

  const getThirdLevelKey = (idSelected) => {
    const item = getThirdLevel(idSelected);
    return item ? item.key.toLowerCase() : undefined;
  }

  //#endregion

  //#region Fourth Level Chart
  const [fourthLevelChartTitle, setFourthLevelChartTitle] = useState('Ventas por');
  const [fourthLevelHeaderTitle, setFourthLevelHeaderTitle] = useState('');
  const [fourthLevelChartFilename, setFourthLevelChartFilename] = useState('');
  const [fourthLevelChartLoading, setFourthLevelChartLoading] = useState(false);
  const [fourthLevelChartVentas, setFourthLevelChartVentas] = useState([]);
  const [fourthLevelChartTotal, setFourthLevelChartTotal] = useState('Total:');

  const clearFourthLevelChart = () => {
    setFourthLevelChartTitle('Ventas por');
    setFourthLevelHeaderTitle('');
    setFourthLevelChartVentas([]);
    setFourthLevelChartTotal('Total:');
    setFourthLevelChartLoading(false);
    clearFifthLevelChart();
  }

  const onPorcionClickFourthLevelChartFail = () => {
    setFourthLevelChartLoading(false);
  }

  const onPorcionClickFourthLevelChartSuccess = useCallback((response) => {
    setFourthLevelChartVentas(response);
    let total = 0;
    response.forEach((venta) => {
      total += venta[relatedChartType];
    });

    let chartTotal = relatedChartType === 'monto'
      ? `Total: ${storageMonedaSigno()} ${getNumberWithDecimals(total)}`
      : `Total: ${formatNumberWithoutDecimals(total)}`;
    setFourthLevelChartTotal(chartTotal);
    setTimeout(() => setFourthLevelChartLoading(false), 500);
  }, [relatedChartType]);

  const onPorcionClickFourthLevel = useCallback((config) => {
    const currentLevel = getFourthLevel(dataSelectedId);
    const nextLevel = currentLevel ? currentLevel.subNivel : undefined;

    if (currentLevel === null || currentLevel === undefined || nextLevel === null || nextLevel === undefined)
      return;

    let item = config.w.config.labels[config.dataPointIndex];

    if (item !== 'OTROS') {
      const isMonto = relatedChartType === 'monto';
      let currentKey = currentLevel.key.toLowerCase();
      clearFourthLevelChart();
      setFourthLevelChartLoading(true);
      setFourthLevelChartTitle(getTitle(isMonto, item, currentKey, nextLevel.key.toLowerCase()));
      setFourthLevelHeaderTitle(getHeaderTitle(nextLevel.key.toLowerCase()));

      let filename = `Ventas por ${isMonto ? 'importe neto' : 'unidades'}  de ${dataOptions[currentKey].title}: ${item}`;
      setFourthLevelChartFilename(filename);

      let ventas = thirdLevelChartVentas;
      let venta = ventas && ventas.find((vField) => {
        if (vField && vField.nombre !== null && vField.nombre !== undefined)
          return vField.nombre.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (venta !== null && venta !== undefined) {
        const fecha = getFechasForLoad();
        props.onLoadVentasDesagregadasDelMes(venta.id, currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtroParent,
          onPorcionClickFourthLevelChartSuccess, onPorcionClickFourthLevelChartFail);
        const parent = getFiltrosParent(venta.id, currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [relatedChartType, thirdLevelChartVentas, dataSelectOptions, dataSelectedId, filtroParent]);

  const getFourthLevel = (idSelected) => {
    const item = getThirdLevel(idSelected);
    return item ? item.subNivel : undefined;
  }

  const getFourthLevelKey = (idSelected) => {
    const item = getFourthLevel(idSelected);
    return item ? item.key.toLowerCase() : undefined;
  }

  //#endregion

  //#region Fifth Level Chart
  const [fifthLevelChartTitle, setFifthLevelChartTitle] = useState('Ventas por');
  const [fifthLevelHeaderTitle, setFifthLevelHeaderTitle] = useState('');
  const [fifthLevelChartFilename, setFifthLevelChartFilename] = useState('');
  const [fifthLevelChartLoading, setFifthLevelChartLoading] = useState(false);
  const [fifthLevelChartVentas, setFifthLevelChartVentas] = useState([]);
  const [fifthLevelChartTotal, setFifthLevelChartTotal] = useState('Total:');

  const clearFifthLevelChart = () => {
    setFifthLevelChartTitle('Ventas por');
    setFifthLevelHeaderTitle('');
    setFifthLevelChartVentas([]);
    setFifthLevelChartTotal('Total:');
    setFifthLevelChartLoading(false);
  }

  const onPorcionClickFifthLevelChartFail = () => {
    setFifthLevelChartLoading(false);
  }

  const onPorcionClickFifthLevelChartSuccess = useCallback((response) => {
    setFifthLevelChartVentas(response);
    let total = 0;
    response.forEach((venta) => {
      total += venta[relatedChartType];
    });

    let chartTotal = relatedChartType === 'monto'
      ? `Total: ${storageMonedaSigno()} ${getNumberWithDecimals(total)}`
      : `Total: ${formatNumberWithoutDecimals(total)}`;
    setFifthLevelChartTotal(chartTotal);
    setTimeout(() => setFifthLevelChartLoading(false), 500);
  }, [relatedChartType]);

  const onPorcionClickFifthLevel = useCallback((config) => {
    const currentLevel = getFifthLevel(dataSelectedId);
    const nextLevel = currentLevel ? currentLevel.subNivel : undefined;

    if (currentLevel === null || currentLevel === undefined || nextLevel === null || nextLevel === undefined)
      return;

    let item = config.w.config.labels[config.dataPointIndex];

    if (item !== 'OTROS') {
      const isMonto = relatedChartType === 'monto';
      let currentKey = currentLevel.key.toLowerCase();
      clearFifthLevelChart();
      setFifthLevelChartLoading(true);
      setFifthLevelChartTitle(getTitle(isMonto, item, currentKey, nextLevel.key.toLowerCase()));
      setFifthLevelHeaderTitle(getHeaderTitle(nextLevel.key.toLowerCase()));

      let filename = `Ventas por ${isMonto ? 'importe neto' : 'unidades'}  de ${dataOptions[currentKey].title}: ${item}`;
      setFifthLevelChartFilename(filename);

      let ventas = fourthLevelChartVentas;
      let venta = ventas && ventas.find((vField) => {
        if (vField && vField.nombre !== null && vField.nombre !== undefined)
          return vField.nombre.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (venta !== null && venta !== undefined) {
        const fecha = getFechasForLoad();
        props.onLoadVentasDesagregadasDelMes(venta.id, currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtroParent,
          onPorcionClickFifthLevelChartSuccess, onPorcionClickFifthLevelChartFail);
        const parent = getFiltrosParent(venta.id, currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [relatedChartType, fourthLevelChartVentas, dataSelectOptions, dataSelectedId, filtroParent]);

  const getFifthLevel = (idSelected) => {
    const item = getFourthLevel(idSelected);
    return item ? item.subNivel : undefined;
  }

  const getFifthLevelKey = (idSelected) => {
    const item = getFifthLevel(idSelected);
    return item ? item.key.toLowerCase() : undefined;
  }
  //#endregion

  //#endregion

  return (
    <Fragment>
      <Grid item xs={12} container spacing={1} className={classes.CombinedChartsCard}>
        <Grid item xs={12}>
          {optionSelector()}
        </Grid>
        <Grid item xs={12} container spacing={1} >
          <Grid item xs={12} lg={4}>
            <PieVentasMontoUnidadPorSeleccion
              legendHeight={50}
              legendPosition={'bottom'}
              height={436}
              width={'100%'}
              chartType={'pie'}
              dataSelectedId={dataSelectedId}
              loading={props[dataOptions[dataSelectedId].stateKey].loading}
              ventas={props[dataOptions[dataSelectedId].stateKey].ventas}
              ventaType={'monto'}
              isCurrency
              title={"Ventas por importe neto"}
              filename="Ventas por importe neto"
              subTitle={`Total: ${storageMonedaSigno()} ${getNumberWithDecimals(props[dataOptions[dataSelectedId].stateKey].total)}`}
              onPorcionClick={onPorcionMontoClick}
              headerTitle={dataOptions[dataSelectedId].title}
              initialLimit={20}
              spaceBetweenChartTitle={'15px'}
              withoutDecimals={props.withoutDecimals}
              key={"PieVentasPorMontoPorSeleccion"}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <TablaVentasMontoUnidadPorSeleccion
              dataSelectedId={dataSelectedId}
              dataOptions={dataOptions}
              dataSelectOptions={dataSelectOptions}
              vendedorId={vendedorId}
              fechaVentasDelMes={getFechaVentas()}
              loading={props[dataOptions[dataSelectedId].stateKey].loading}
              ventas={props[dataOptions[dataSelectedId].stateKey].ventas}
              onShowFiltersModal={props.onShowFiltersModal}
              title={`Ventas del ${props[dataOptions[dataSelectedId].stateKey].loading
                ? '--/----' : getFechaVentasTitle()} por: ${dataOptions[dataSelectedId].title}`}
              withoutDecimals={props.withoutDecimals}
              key={"TablaVentasMontoUnidadPorSeleccion"}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
             <PieVentasMontoUnidadPorSeleccion
               legendHeight={50}
               legendPosition={'bottom'}
               height={436}
               width={'100%'}
               chartType={'pie'}
               dataSelectedId={dataSelectedId}
               loading={props[dataOptions[dataSelectedId].stateKey].loading}
               ventas={props[dataOptions[dataSelectedId].stateKey].ventas}
               ventaType={'unidadesVendidas'}
               title={"Ventas por unidades"}
               filename="Ventas por unidades"
               subTitle={`Total: ${props[dataOptions[dataSelectedId].stateKey] ? formatNumberWithoutDecimals(props[dataOptions[dataSelectedId].stateKey].totalUnidades) : 0}`}
               onPorcionClick={onPorcionUnidadesClick}
               headerTitle={dataOptions[dataSelectedId].title}
               initialLimit={20}
               spaceBetweenChartTitle={'15px'}
               withoutDecimals={props.withoutDecimals}
               key={"PieVentasPorUnidadPorSeleccion"}
             />
           </Grid>
           <Grid item xs={12} lg={4}>
           <PieVentasMontoUnidadPorSeleccion
              legendHeight={50}
              legendPosition={'bottom'}
              height={436}
              width={'100%'}
              chartType={'pie'}
              loading={relatedCharLoading}
              ventas={relatedChartVentas}
              ventaType={relatedChartType}
              title={relatedChartTitle}
              filename={relatedChartFilename}
              subTitle={relatedChartTotal}
              onPorcionClick={onPorcionClickSecondLevel}
              isCurrency={relatedChartType === 'monto'}
              headerTitle={dataOptions[dataSelectedId].nextTitle}
              initialLimit={20}
              withoutDecimals={props.withoutDecimals}
            />
           </Grid>
           {showRelatedCharts() && (
            <Fragment>
              {getRelatedChart() >= 1 && (
                <Grid item xs={12} md={relatedChartMd()} lg={relatedChartLg()}>
                  <PieVentasMontoUnidadPorSeleccion
                    legendHeight={50}
                    legendPosition={'bottom'}
                    height={436}
                    width={'100%'}
                    chartType={'pie'}
                    dataSelectedId={getSecondLevelKey(dataSelectedId) ? getSecondLevelKey(dataSelectedId) : ''}
                    loading={secondLevelChartLoading}
                    ventas={secondLevelChartVentas}
                    ventaType={relatedChartType}
                    title={secondLevelChartTitle}
                    filename={secondLevelChartFilename}
                    subTitle={secondLevelChartTotal}
                    onPorcionClick={onPorcionClickThirdLevel}
                    isCurrency={relatedChartType === 'monto'}
                    headerTitle={secondLevelHeaderTitle}
                    initialLimit={20}
                    withoutDecimals={props.withoutDecimals}
                    secondOrFurtherLevel
                  />
                </Grid>
              )}
              {getRelatedChart() >= 2 && (
              <Grid item xs={12} md={relatedChartMd()} lg={relatedChartLg()}>
                <PieVentasMontoUnidadPorSeleccion
                  legendHeight={50}
                  legendPosition={'bottom'}
                  height={436}
                  width={'100%'}
                  chartType={'pie'}
                  dataSelectedId={getThirdLevelKey(dataSelectedId) ? getThirdLevelKey(dataSelectedId) : ''}
                  loading={thirdLevelChartLoading}
                  ventas={thirdLevelChartVentas}
                  ventaType={relatedChartType}
                  title={thirdLevelChartTitle}
                  filename={thirdLevelChartFilename}
                  subTitle={thirdLevelChartTotal}
                  onPorcionClick={onPorcionClickFourthLevel}
                  isCurrency={relatedChartType === 'monto'}
                  headerTitle={thirdLevelHeaderTitle}
                  initialLimit={20}
                  withoutDecimals={props.withoutDecimals}
                  secondOrFurtherLevel
                />
              </Grid>
              )}
              {getRelatedChart() >= 3 && (
                <Grid item xs={12} md={relatedChartMd()} lg={relatedChartLg()}>
                  <PieVentasMontoUnidadPorSeleccion
                    legendHeight={50}
                    legendPosition={'bottom'}
                    height={436}
                    width={'100%'}
                    chartType={'pie'}
                    dataSelectedId={getFourthLevelKey(dataSelectedId) ? getFourthLevelKey(dataSelectedId) : ''}
                    loading={fourthLevelChartLoading}
                    ventas={fourthLevelChartVentas}
                    ventaType={relatedChartType}
                    title={fourthLevelChartTitle}
                    filename={fourthLevelChartFilename}
                    subTitle={fourthLevelChartTotal}
                    onPorcionClick={onPorcionClickFifthLevel}
                    isCurrency={relatedChartType === 'monto'}
                    headerTitle={fourthLevelHeaderTitle}
                    initialLimit={20}
                    withoutDecimals={props.withoutDecimals}
                    secondOrFurtherLevel
                  />
                </Grid>
              )}
              {getRelatedChart() === 4 && (
                <Grid item xs={12} md={relatedChartMd()} lg={relatedChartLg()}>
                  <PieVentasMontoUnidadPorSeleccion
                    legendHeight={50}
                    legendPosition={'bottom'}
                    height={436}
                    width={'100%'}
                    chartType={'pie'}
                    dataSelectedId={getFifthLevelKey(dataSelectedId) ? getFifthLevelKey(dataSelectedId) : ''}
                    loading={fifthLevelChartLoading}
                    ventas={fifthLevelChartVentas}
                    ventaType={relatedChartType}
                    title={fifthLevelChartTitle}
                    filename={fifthLevelChartFilename}
                    subTitle={fifthLevelChartTotal}
                    isCurrency={relatedChartType === 'monto'}
                    headerTitle={fifthLevelHeaderTitle}
                    initialLimit={20}
                    withoutDecimals={props.withoutDecimals}
                    secondOrFurtherLevel
                  />
                </Grid>
              )}
            </Fragment>
            )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.tipoVentasDesagregadasSelected,
    dataSelectOptions: state.ventas.tipoVentasDesagregadas.tipos,
    fechaVentasDelMes: state.ventas.fechaVentasDelMes,
    filtroSeleccionado: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado,
    vendedorId: state.ventas.vendedorVentasDelMesSelected,
    tipoVentas: state.ventas.evolucionVentasMensualInteranual.items,
    tipoVentasLoading: state.ventas.evolucionVentasMensualInteranual.loading,
    ventasPorMarca: state.ventas.ventasDelMesPorMarcas,
    ventasPorProvincia: state.ventas.ventasDelMesPorProvincias,
    ventasPorRubro: state.ventas.ventasDelMesPorRubros,
    ventasPorSubrubro: state.ventas.ventasDelMesPorSubrubros,
    ventasPorZona: state.ventas.ventasDelMesPorZonas,
    ventasPorUnidadDeNegocio: state.ventas.ventasDelMesPorUnidadesDeNegocios,
    ventasPorCanal: state.ventas.ventasDelMesPorCanales,
    ventasPorGrupoDeCompra: state.ventas.ventasDelMesPorGruposDeCompras,
    ventasPorResponsable: state.ventas.ventasDelMesPorResponsables,
    ventasPorCliente: state.ventas.ventasDelMesPorClientes,
    ventasPorArticulo: state.ventas.ventasDelMesPorArticulos,
    ventasPorVendedor: state.ventas.ventasDelMesPorVendedores,
    ventasPorCategoriaCliente: state.ventas.ventasPorCategoriaDeCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => {
      dispatch(actions.seleccionTipoDeVentasDesagregadas(selecionId));
      dispatch(actions.seleccionDataVentasDelMesPor(selecionId));
      dispatch(actions.updateDataEvolucionVentasPorPeriodos(true));
      dispatch(actions.updateDataComparativasPorPeriodos(true));
    },
    onLoadVentasPorMarca: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorMarca(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorRubro: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorRubro(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorSubrubro: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorSubrubro(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorProvincia: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorProvincia(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorZona: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorZona(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorUnidadDeNegocio: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorUnidadDeNegocio(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorCanal: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorCanal(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorGrupoDeCompra: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorGrupoDeCompras(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorResponsable: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorResponsable(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorCliente: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorCliente(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorArticulo: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorArticulo(undefined, mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorVendedor: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorVendedor(mesDesde, anioDesde, mesHasta, anioHasta)),
    onLoadVentasPorCategoriaDeCliente: (mesDesde, anioDesde, mesHasta, anioHasta) =>
      dispatch(actions.loadVentasDesagregadasPorCategoriaDeCliente(mesDesde, anioDesde, mesHasta, anioHasta)),

    onShowFiltersModal: (title = "categoría de cliente") =>
      dispatch(actions.showListFiltersModal(true, `Ventas detalladas por ${title}`, ['venta'])),

    onClearVendedorId: () => dispatch(actions.seleccionVendedorVentasDelMesSelected(undefined)),

    onLoadVentasDesagregadasDelMes: (id, parent, child, mesDesde, anioDesde, mesHasta, anioHasta, filtrosParent, success, errorFail) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorTipoDeVenta(id, parent, child, mesDesde, anioDesde, mesHasta, anioHasta, filtrosParent, success, errorFail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieTableVentasMontoUnidadPorSeleccion);