import React, { useRef } from 'react';

const IframePortal = () => {
  const iframeRef = useRef();

  const devPortalUrl = 'http://localhost:3003';
  const testPortalUrl = 'https://develop.login.jalisco365.com.ar';
  const prodPortalUrl = 'https://login.jalisco365.com.ar';

  const inDev = process.env.NODE_ENV === 'development';
  const inTest = false;

  return (
    <div>
      <iframe
        ref={iframeRef}
        src={inDev ? devPortalUrl : inTest ? testPortalUrl : prodPortalUrl}
        id="helperFrame"
        title="portal"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default IframePortal;
