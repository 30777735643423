import React from 'react';
import { connect } from 'react-redux';
import TableUtilidadesBase from './TableUtilidadesBase';

const TableUtilidadesPorSubrubro = (props) => {
  return (
    <TableUtilidadesBase
      titleUtilidadPor="Subrubro"
      fieldUtilidadPor="subrubro"
      utilidades={props.utilidades}
      loading={props.loading}
      total={props.total}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    utilidades: state.ventas.utilidadPorSubrubro.utilidades,
    total: state.ventas.utilidadPorSubrubro.total,
    loading: state.ventas.utilidadPorSubrubro.loading,
    error: state.ventas.utilidadPorSubrubro.error,
    estaCargado: state.ventas.utilidadPorSubrubro.estaCargado
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableUtilidadesPorSubrubro);
