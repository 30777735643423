import React, { createRef } from 'react';
import Table from '../../UI/Table/Table';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { storageMonedaSigno } from '../../../shared/sessionData';
import { formatNumber, isEven } from '../../../shared/formatNumber';
import { MTableHeader, MTableToolbar } from 'material-table';
import TablaComprobantesToExcel from '../../../shared/TableToExcel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white'
  },
  iconsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxHeight: '30px'
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
}));

const TablaCheques = (props) => {
  const { data, loading, tiposCheques } = props;

  const classes = useStyles();

  const cellStyles = {
    fontSize: 'smaller',
    paddingBottom: '5px',
    paddingTop: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '0px'
  };

  const headerStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '0px'
  };

  const getName = (tiposCheques) => {
    switch (tiposCheques) {
      case 'EnCartera':
        return 'Cheques en Cartera';
      case 'Propios':
        return 'Cheques Propios';
      case 'Rechazados':
        return 'Cheques Rechazados';
      case 'DelDia':
        return 'Cheques depositados';
      default:
        return;
    }
  };

  const getHeaders = (props, tiposCheques) => {
    switch (tiposCheques) {
      case 'EnCartera':
        return getHeadersEnCartera(props);
      case 'Propios':
        return getHeadersPropios(props);
      case 'Rechazados':
        return getHeadersRechazados(props);
      case 'DelDia':
        return getHeadersEnCartera(props);
      default:
        return;
    }
  };

  const getHeadersEnCartera = (props) => {
    return (
      <MTableHeader {...props}>
        {(props.columns[0].tableData.width = '18%')}
        {(props.columns[1].tableData.width = '11%')}
        {(props.columns[2].tableData.width = '5%')}
        {(props.columns[3].tableData.width = '10%')}
        {(props.columns[4].tableData.width = '10%')}
        {(props.columns[5].tableData.width = '11%')}
        {(props.columns[6].tableData.width = '20%')}
        {(props.columns[7].tableData.width = '10%')}
        {(props.columns[8].tableData.width = '35%')}
        {(props.columns[9].tableData.width = '5%')}
      </MTableHeader>
    );
  };

  const getHeadersPropios = (props) => {
    return (
      <MTableHeader {...props}>
        {(props.columns[0].tableData.width = '18%')}
        {(props.columns[1].tableData.width = '11%')}
        {(props.columns[2].tableData.width = '5%')}
        {(props.columns[3].tableData.width = '10%')}
        {(props.columns[4].tableData.width = '10%')}
        {(props.columns[5].tableData.width = '20%')}
        {(props.columns[6].tableData.width = '30%')}
        {(props.columns[7].tableData.width = '55%')}
      </MTableHeader>
    );
  };

  const getHeadersRechazados = (props) => {
    return (
      <MTableHeader {...props}>
        {(props.columns[0].tableData.width = '18%')}
        {(props.columns[1].tableData.width = '11%')}
        {(props.columns[2].tableData.width = '5%')}
        {(props.columns[3].tableData.width = '10%')}
        {(props.columns[4].tableData.width = '10%')}
        {(props.columns[5].tableData.width = '11%')}
        {(props.columns[6].tableData.width = '20%')}
        {(props.columns[7].tableData.width = '10%')}
        {(props.columns[8].tableData.width = '35%')}
        {(props.columns[9].tableData.width = '5%')}
      </MTableHeader>
    );
  };

  const getColumns = (tiposCheques) => {
    switch (tiposCheques) {
      case 'EnCartera':
        return getColumnsEnCartera();
      case 'Propios':
        return getColumnsPropios();
      case 'Rechazados':
        return getColumnsRechazados();
      case 'DelDia':
        return getColumnsEnCartera();
      default:
        return;
    }
  };

  const getColumnsEnCartera = () => {
    return [
      {
        title: 'Valor',
        field: 'valor',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Nro. Cheque',
        field: 'nroCheque',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Orden',
        field: 'orden',
        align: 'right',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Importe',
        field: 'importe',
        align: 'right',
        cellStyle: cellStyles,
        headerStyle: headerStyle,
        render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.importe)}`
      },
      {
        title: 'Vencimiento',
        field: 'vencimiento',
        align: 'center',
        cellStyle: cellStyles,
        headerStyle: headerStyle,
        render: (rowData) => `${moment(rowData.vencimiento).format('DD/MM/YY')}`
      },
      {
        title: 'Estado',
        field: 'estado',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Banco',
        field: 'banco',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Cliente',
        field: 'clienteId',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Razón Social',
        field: 'razonSocial',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Tenedor',
        field: 'tenedor',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      }
    ];
  };

  const getColumnsPropios = () => {
    return [
      {
        title: 'Valor',
        field: 'valor',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Nro. Cheque',
        field: 'nroCheque',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Orden',
        field: 'orden',
        align: 'right',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Importe',
        field: 'importe',
        align: 'right',
        cellStyle: cellStyles,
        headerStyle: headerStyle,
        render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.importe)}`
      },
      {
        title: 'Vencimiento',
        field: 'vencimiento',
        align: 'center',
        cellStyle: cellStyles,
        headerStyle: headerStyle,
        render: (rowData) => `${moment(rowData.vencimiento).format('DD/MM/YY')}`
      },
      {
        title: 'Estado',
        field: 'estado',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Cuenta Bancaria',
        field: 'cuentaBancaria',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Detalle',
        field: 'detalles',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      }
    ];
  };

  const getColumnsRechazados = () => {
    return [
      {
        title: 'Valor',
        field: 'valor',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Nro. Cheque',
        field: 'nroCheque',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Orden',
        field: 'orden',
        align: 'right',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Importe',
        field: 'importe',
        align: 'right',
        cellStyle: cellStyles,
        headerStyle: headerStyle,
        render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.importe)}`
      },
      {
        title: 'Vencimiento',
        field: 'vencimiento',
        align: 'center',
        cellStyle: cellStyles,
        headerStyle: headerStyle,
        render: (rowData) => `${moment(rowData.vencimiento).format('DD/MM/YY')}`
      },
      {
        title: 'Estado',
        field: 'estado',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Banco',
        field: 'banco',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Cliente',
        field: 'clienteId',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Razón Social',
        field: 'razonSocial',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      },
      {
        title: 'Tenedor',
        field: 'tenedor',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyle
      }
    ];
  };

  const tableRef = createRef();
  return (
    <div className={classes.root}>
      <h4 style={{ margin: '0'}}>{getName(tiposCheques)}</h4>
      <div className={classes.table}>
        <Table
          tableRef={tableRef}
          columns={getColumns(tiposCheques)}
          style={{ width: '100%', borderRadius: '0px', boxShadow: 'none' }}
          data={data}
          loading={loading}
          noBoxShadow
          components={{
            Header: (props) => getHeaders(props, tiposCheques),
            Toolbar: (props) => (
              <div className={classes.iconsHeader}>
                <div style={{ marginRight: '30px' }}>
                  <MTableToolbar style={{ height: '30px' }} {...props} />
                </div>
                <TablaComprobantesToExcel
                  columns={getColumns(tiposCheques)}
                  data={data}
                  name={getName(tiposCheques)}
                  filename={getName(tiposCheques)}
                />
              </div>
            )
          }}
          customOptions={{
            actionsColumnIndex: -1,
            grouping: false,
            draggable: false,
            showTitle: false,
            detailPanelType: 'single',
            detailPanelColumnAlignment: 'right',
            paging: false,
            overflowY: 'auto',
            rowStyle: (rowData, index) => ({
              backgroundColor: isEven(index) ? '#f2f2f2' : 'white'
            })
          }}
          tableKey={"tabla-cheques"}
        />
      </div>
    </div>
  );
};
export default TablaCheques;
