import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';
import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';
import TextoIvaCreaditoFiscalComparativo from '../TextoIvaCreditoFiscalComparativo/TextoIvaCreaditoFiscalComparativo';
import TextoIvaDebitoFiscalComparativo from '../TextoIvaDebitoFiscalComparativo/TextoIvaDebitoFiscalComparativo';

const TextoIvaFiscal = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }
    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.ejecucionDePagina]);

  const isDisabledChart = props.estaOculto && !props.empresa;

  const modalContent = () => {
    props.onShowGlobalModal(
      '',
      <div style={{ minWidth: '279px' }}>
        <TextoIvaDebitoFiscalComparativo />
        <div style={{ height: '10px' }}></div>
        <TextoIvaCreaditoFiscalComparativo />
      </div>,
      true
    );
  };

  return (
    <TextChart
      title={`IVA fiscal del mes ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} ${
        props.empresa ? props.empresa.nombre : ''
      }`}
      bigAmount={Math.abs(props.debitoMesActual) - Math.abs(props.creditoMesActual)}
      bigAmountTooltip="Crédito fiscal"
      smallAmount={Math.abs(props.debitoMesAnterior) - Math.abs(props.creditoMesAnterior)}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} a ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={props.loading}
      porcentaje
      bigCurrency
      comparacionInvertida
      smallCurrency
      cursorPointer={!props.estaOculto && !props.error}
      disabled={isDisabledChart}
      border={props.border && !props.error}
      imgSinDatos={props.error}
      onBodyClick={() => modalContent()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.impuestos.textoIvaFiscal.loading,
    error: state.impuestos.textoIvaFiscal.error,
    creditoMesActual: state.impuestos.textoIvaFiscal.creditoMesActual,
    creditoMesAnterior: state.impuestos.textoIvaFiscal.creditoMesAnterior,
    debitoMesActual: state.impuestos.textoIvaFiscal.debitoMesActual,
    debitoMesAnterior: state.impuestos.textoIvaFiscal.debitoMesAnterior,
    estaCargado: state.impuestos.textoIvaFiscal.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoIvaFiscal(idEmpresa)),
    onUnmountChart: () => dispatch(actions.unmountTextoIvaFiscal()),
    onShowGlobalModal: (title, content, disableCloseButton) =>
      dispatch(actions.showGlobalModal(true, title, content, '', disableCloseButton))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoIvaFiscal);
