import React, { Fragment, useState } from 'react';
import Dialog from '../../components/UI/Dialog/Dialog';
import moment from 'moment';

import * as actions from '../../store/actions';

import classes from './FotoDelDia.css';

import TextoVentasMensuales from '../../components/Indicadores/Ventas/TextoVentasMensuales/TextoVentasMensuales';
import TextoVentasDiarias from '../../components/Indicadores/Ventas/TextoVentasDiarias/TextoVentasDiarias';
import TextoIvaFiscal from '../../components/Indicadores/Impuestos/TextoIvaFiscal/TextoIvaFiscal';
import TextoCobranzasMensual from '../../components/Indicadores/Ventas/TextoCobranzasMensual/TextoCobranzasMensual';

import TextoPlazoMedioVencimientos from '../../components/Indicadores/Ventas/TextoPlazoMedioVencimientosACobrar/TextoPlazoMedioVencimientosACobrar';
import TextoDeudasPorCompras from '../../components/Indicadores/Compras/TextoPlazoMedioVencimientoAPagar/TextoPlazoMedioVencimientoAPagar';
import TextoChequesEnCartera from '../../components/Indicadores/Ventas/TextoChequesEnCartera/TextoChequesEnCartera';
import TextoChequesRechazados from '../../components/Indicadores/Ventas/TextoChequesRechazados/TextoChequesRechazados';

import PieGastosPorCategoria from '../../components/Indicadores/Gastos/PieGastosPorCategoria/PieGastosPorCategoria';
import TextoChequesPropios from '../../components/Indicadores/Compras/TextoChequesPropios/TextoChequesPropios';
import TextoGastosDelMes from '../../components/Indicadores/Gastos/TextoGastosDelMesComparativo/TextoGastosDelMesComparativo';

import TextoPromedioDiasPagos from '../../components/Indicadores/Compras/TextoPromedioDiasPagos/TextoPromedioDiasPagos';
import TextoPagosDelMes from '../../components/Indicadores/Compras/TextoPagosDelMes/TextoPagosDelMes';
import TextoPromedioDiasCobranzas from '../../components/Indicadores/Ventas/TextoPromedioDiasCobranzas/TextoPromedioDiasCobranzas';

import { connect } from 'react-redux';

import PieSaldoAcreedores from '../../components/Indicadores/Compras/PieSaldoAcreedores/PieSaldoAcreedores';
import PieDeudaPorCategoriaDeCliente from '../../components/Indicadores/Ventas/PieDeudaPorCategoriaDeCliente/PieDeudaPorCategoriaDeCliente';
import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import { storageMultiempresa } from '../../shared/sessionData';
import { compareFechaActualFechaFija } from '../../shared/fechas';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import DetalleCheques from '../DetalleCheques/DetalleCheques.js';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const FotoDelDia = (props) => {  
  sessionStorage.setItem('seccionNombre', 'Foto del día');
  
  const [dialogState, setDialogState] = useState({
    show: false,
    content: '',
    empresaSeleccionada: ''
  });

  const showContent = () => {
    switch (dialogState.content) {
      case 'chequesEnCartera':
        return <DetalleCheques tiposCheques='EnCartera' />;

      case 'chequesRechazados':
        return <DetalleCheques tiposCheques='Rechazados' rechazados={true} />;

      case 'chequesPropios':
        return <DetalleCheques tiposCheques='Propios' />;

      default:
        return null;
    }
  };

  const onShowDialogClick = (content) => {
    setDialogState({
      show: true,
      content: content
    });
  };

  const handleCloseDialog = () => {
    setDialogState({
      show: false,
      content: ''
    });
    props.onClearDetail();
  };

  const isMultiemp = storageMultiempresa() === 'true';
  const inPhone = window.screen.width <= 750;

  const closeModal = () => {
    return inPhone ? (
      <Grid container
        direction='row'
        alignItems='center'
        style={{ justifyContent:'flex-end' }}
      >
        <Grid item>
          <IconButton aria-label="close" onClick={() => handleCloseDialog()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    ) : (
      <Fragment></Fragment>
    )
  }

  return (
    <Fragment>
      <div className={classes.fotoDelDiaContainer}>
        {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
        {!props.ejecucionFotoDelDia ? (
          <BoxEjecutarPaginaInfo />
        ) : (
          <Fragment>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}
                container spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch" >
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoVentasMensuales ejecucionDePagina={props.ejecucionFotoDelDia} border />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoVentasDiarias ejecucionDePagina={props.ejecucionFotoDelDia} border />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoIvaFiscal ejecucionDePagina={props.ejecucionFotoDelDia} border />
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={3}>
                  <TextoCobranzasMensual ejecucionDePagina={props.ejecucionFotoDelDia} border />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}
                container spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch">
                <Grid item xs={12} sm={6} md={6} lg={3}
                  className={classes.gridChequesEnCartera} >
                  <TextoChequesEnCartera
                    ejecucionDePagina={props.ejecucionFotoDelDia}
                    onBodyClick={
                      props.estaOcultoChequesEnCartera || !compareFechaActualFechaFija()
                        ? null
                        : () => onShowDialogClick('chequesEnCartera')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoChequesPropios
                    ejecucionDePagina={props.ejecucionFotoDelDia}
                    onBodyClick={
                      props.estaOcultoChequesEnCartera || !compareFechaActualFechaFija()
                        ? null
                        : () => onShowDialogClick('chequesPropios')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoGastosDelMes ejecucionDePagina={props.ejecucionFotoDelDia} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoPagosDelMes ejecucionDePagina={props.ejecucionFotoDelDia} />
                </Grid>
              </Grid>
              
              <Grid item xs={12} sm={12} md={12}
                container spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={6} lg={3}
                  container spacing={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  className={classes.gridContainer}>
                  <Grid item>
                    <TextoChequesRechazados
                      ejecucionDePagina={props.ejecucionFotoDelDia}
                      onBodyClick={
                        props.estaOcultoChequesRechazados || !compareFechaActualFechaFija()
                          ? null
                          : () => onShowDialogClick('chequesRechazados')
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextoPlazoMedioVencimientos ejecucionDePagina={props.ejecucionFotoDelDia} />
                  </Grid>
                  <Grid item>
                    <TextoPromedioDiasCobranzas ejecucionDePagina={props.ejecucionFotoDelDia} />
                  </Grid>
                  <Grid item>
                    <TextoDeudasPorCompras ejecucionDePagina={props.ejecucionFotoDelDia} />
                  </Grid>
                  <Grid item>
                    <TextoPromedioDiasPagos ejecucionDePagina={props.ejecucionFotoDelDia} />
                  </Grid>
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} lg={3}
                  className={classes.gridGastos}>
                  <PieGastosPorCategoria
                    ejecucionDePagina={props.ejecucionFotoDelDia}
                    legendPosition={'bottom'}
                    chartType={'donut'}
                    width={'100%'}
                    height={inPhone ? '350px' : '90%'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}
                  className={classes.gridDeuda}>
                  <PieDeudaPorCategoriaDeCliente 
                    ejecucionDePagina={props.ejecucionFotoDelDia} 
                    legendPosition={'bottom'}
                    chartType={'donut'}
                    width={'100%'}
                    height={inPhone ? '350px' : '90%'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}
                  className={classes.gridSaldoAcreedores}>
                  <PieSaldoAcreedores 
                    ejecucionDePagina={props.ejecucionFotoDelDia} 
                    legendPosition={'bottom'}
                    chartType={'donut'}
                    width={'100%'}
                    height={inPhone ? '350px' : '90%'}
                  />
                </Grid>
              </Grid>            
            </Grid>
          </Fragment>
        )}
      </div>
      <Dialog
        title={closeModal()}
        show={dialogState.show}
        onClose={handleCloseDialog}
        body={<div style={{ minWidth: !inPhone && '500px' }}>{showContent()}</div>}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionFotoDelDia: state.ejecucionPaginas.ejecucionFotoDelDia,
    estaOcultoIvaFiscal: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscal,
    estaOcultoChequesEnCartera: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCartera,
    estaOcultoChequesRechazados: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesRechazados,
    estaOcultoChequesEmitidos: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidos
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarFotoDelDia: () => dispatch(actions.ejecutarFotoDelDia()),
    updateUltimaActualizacion: () =>
      dispatch(actions.updateUltimaActualizacionFotoDelDia(moment(Date.now()).format('DD/MM/YYYY HH:mm'))),
    onClearDetail: () => dispatch(actions.clearDetalleCheques())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FotoDelDia);
