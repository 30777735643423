// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BarUtilidadesTooltip__TooltipContainer__LmUYX {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n}\r\n\r\n.BarUtilidadesTooltip__Title__ZwIHa {\r\n}\r\n.BarUtilidadesTooltip__Divider__zO7vP {\r\n  height: 1px;\r\n  background-color: black;\r\n  opacity: 0.2;\r\n}\r\n\r\n.BarUtilidadesTooltip__Body__\\+RpGd {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.BarUtilidadesTooltip__TextoContainer__vulJV {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.BarUtilidadesTooltip__Circle__oVOjv {\r\n  width: 13px;\r\n  height: 13px;\r\n  border-radius: 50%;\r\n  margin-right: 10px;\r\n}\r\n\r\n.BarUtilidadesTooltip__TooltipPopper__7LWqJ {\r\n  z-index: 1500 !important;\r\n}\r\n\r\n.BarUtilidadesTooltip__SelectRoot__IolSb {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n\r\n.BarUtilidadesTooltip__TitleDialogContent__SsQJ3 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.BarUtilidadesTooltip__TitleDialogTexto__k7LBl {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding-left: 10px;\r\n  padding-right: 5px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipContainer": "BarUtilidadesTooltip__TooltipContainer__LmUYX",
	"Title": "BarUtilidadesTooltip__Title__ZwIHa",
	"Divider": "BarUtilidadesTooltip__Divider__zO7vP",
	"Body": "BarUtilidadesTooltip__Body__+RpGd",
	"TextoContainer": "BarUtilidadesTooltip__TextoContainer__vulJV",
	"Circle": "BarUtilidadesTooltip__Circle__oVOjv",
	"TooltipPopper": "BarUtilidadesTooltip__TooltipPopper__7LWqJ",
	"SelectRoot": "BarUtilidadesTooltip__SelectRoot__IolSb",
	"TitleDialogContent": "BarUtilidadesTooltip__TitleDialogContent__SsQJ3",
	"TitleDialogTexto": "BarUtilidadesTooltip__TitleDialogTexto__k7LBl"
};
export default ___CSS_LOADER_EXPORT___;
