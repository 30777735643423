import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";

const LeyendaFiltros = (props) => {
  const { desde, hasta } = props;
  const desdeText = desde instanceof Date
    ? new moment(desde).format("DD-MM-YYYY")
    : typeof desde === 'string' ? new moment(new Date(desde)).format("DD-MM-YYYY") : desde;
  const hastaText = hasta instanceof Date
    ? new moment(hasta).format("DD-MM-YYYY")
    : typeof hasta === 'string' ? new moment(new Date(hasta)).format("DD-MM-YYYY") : hasta;

  return desdeText && hastaText ? (
    <Typography variant="h6">
      La información visualizada corresponde al período de análisis del {desdeText} al {hastaText}
    </Typography>
  ) : (<Fragment></Fragment>)
}

export default LeyendaFiltros;