import React, { useRef, useEffect, Fragment, useState } from "react"
import * as actions from "../../../../store/actions/index"
import { connect } from "react-redux"
import { formatNumber, isEven } from "../../../../shared/formatNumber"
import { storageMonedaSigno, storageUsuarioId } from "../../../../shared/sessionData"
import Table from "../../../UI/Table/Table"
import FilterListIcon from "@material-ui/icons/FilterList"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { IconButton, Tooltip } from "@material-ui/core"
import Cliente from "../../../../containers/Cliente/Cliente"
import classes from "./TablaDeudaClientes.css"
import TablaDeudaClientesToExcel from "./TablaDeudaClientesToExcel.js"
import BoxEjecucionIndicador from "../../../UI/BoxEjecucionIndicador/BoxEjecucionIndicador"
import { MTableHeader } from "material-table"
import TextoTotalDeudaClientes from "../TextoTotalDeudaClientes/TextoTotalDeudaClientes"
import _ from "lodash"

const TablaDeudaClientes = (props) => {

	const { onOpenClienteModal, ejecucionDePagina, fechaDeudaDelMes, estaCargado,
		ejecucionIndicador, ordenDeuda, cambiarOrden } = props
	const [orderBy, setOrderBy] = useState();
	const [orderDirection, setOrderDirection] = useState();
	const [loadingSort, setLoadingSort] = useState(false);
	const [cargando, setCargando] = useState(false);
	const [loadTable, setLoadTable] = useState(false)
	const signoMoneda = storageMonedaSigno();

	const cols = [
		{ key: "nombre", field: "name" },
		{ key: "monto", field: "monto" },
		{ key: "porcentajeDeParticipacion", field: "porcParticipacion" },
	];

	const loadTableHandle = () => {
		setLoadTable(true)
	}
	useEffect(() => {
		if (ejecucionDePagina && !estaCargado && !cargando && ejecucionIndicador) {
			setCargando(true);
			props.onLoadDeudasDelMesPorOrdenamiento(fechaDeudaDelMes.mes, fechaDeudaDelMes.anio)
		}
	}, [cargando, fechaDeudaDelMes, estaCargado, ejecucionIndicador]);

	useEffect(() => {
		if (ordenDeuda && (orderBy === undefined || orderDirection === undefined)) {
			let splitOrderBy = ordenDeuda.split(',')[0];
			let splitDirection = ordenDeuda.split(',')[1];

			if (!orderBy)
				setOrderBy(cols.findIndex((c) => c.key === splitOrderBy));

			if (!orderDirection)
				setOrderDirection(splitDirection);
		}
	}, [cols, orderBy, orderDirection, ordenDeuda]);

	const inPhone = window.screen.width <= 750;
	const buildActionsButtons = (rowData) => {
		return (
			<div style={{ textAlign: "right" }}>
				<Tooltip title="Ver estado del cliente">
					<IconButton size="small" onClick={() => openEstadoCliente(rowData.id)}>
						<VisibilityIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</div>
		)
	}

	const openEstadoCliente = (idCliente) => {
		props.onClearChart();
		sessionStorage.setItem("idCliente", idCliente);

		const title = "Estado del cliente"
		const body = estadoCliente()
		onOpenClienteModal(true, title, body)
	}

	const estadoCliente = () => {
		return (
			<Cliente />
		)
	}

	const data = () => {
		let order = orderBy === undefined || orderBy < 0 ? 0 : orderBy;
		let direction = orderDirection === undefined ? "asc" : orderDirection;
		let deudasDataTable = _.orderBy(props.deudasPorCliente.deudasDataTable, cols[order].field, direction);

		return deudasDataTable;
	}

	const isLoading = () => {
		return props.deudasPorCliente.loading || loadingSort;
	}

	const onOrderChange = (columnId, columnDirection) => {
		setOrderBy(columnId);
		setOrderDirection(columnDirection);
		const ord = `${cols[columnId].key},${columnDirection}`;
		cambiarOrden(storageUsuarioId(), ord);
	}

	const tableRef = useRef()
	return (
		<div className={classes.TablaDeudaClientesContainer}>
			{!loadTable ?
				(<Fragment>
					<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
						<BoxEjecucionIndicador stretched={inPhone} onClick={() => loadTableHandle()} title='Deuda de clientes' />
						<div style={{ display: 'flex', justifyContent: 'space-between', height: '90%', padding: '10px' }}>
							<TextoTotalDeudaClientes ejecucionDePagina={!ejecucionIndicador} />
						</div>
					</div>
				</Fragment>) :
				(<Fragment>
					<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
						<div className={classes.TitleCard}>
							{!props.deudasPorCliente.loading && (
								<TablaDeudaClientesToExcel
									deudas={data()}
									fecha={`${fechaDeudaDelMes.mes}/${fechaDeudaDelMes.anio}`}
									deudasPor={'Cliente'}
								/>
							)}
							<Tooltip title="Ver filtros del indicador">
								<IconButton size="small" onClick={() => props.onShowFiltersModal()}>
									<FilterListIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</div>
						<Table
							tableRef={tableRef}
							title=""
							loading={isLoading()}
							columns={[
								{
									title: "Cliente",
									field: "name",
									align: "left",
									cellStyle: {
										fontSize: "12px",
										paddingBottom: "5px",
										paddingTop: "5px",
										height: "49px",
									},
									headerStyle: {
										fontSize: "14px",
										paddingTop: "0px",
										paddingBottom: "0px",
										backgroundColor: "#fafafa",
									},
								},
								{
									title: `Deuda`,
									field: "monto",
									align: "right",
									type: "numeric",
									cellStyle: (cellData) => {
										return {
											fontSize: "12px",
											paddingBottom: "5px",
											paddingTop: "5px",
											color: cellData < 0 && "red",
										};
									},
									headerStyle: {
										fontSize: "14px",
										paddingTop: "0px",
										paddingBottom: "0px",
										backgroundColor: "#fafafa",
									},
									render: (rowData) => `${signoMoneda} ${formatNumber(rowData.monto)}`,
								},
								{
									title: `Porc. participación`,
									field: "porcentajeDeParticipacion",
									align: "right",
									type: "numeric",
									cellStyle: (cellData) => {
										return {
											fontSize: "12px",
											paddingBottom: "5px",
											paddingTop: "5px",
											color: cellData < 0 && "red",
										};
									},
									headerStyle: {
										fontSize: "14px",
										paddingTop: "0px",
										paddingBottom: "0px",
										backgroundColor: "#fafafa",
									},
									render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`,
								},
								{
									title: ``,
									field: "id",
									align: "right",
									type: "numeric",
									sorting: false,
									cellStyle: (cellData) => {
										return {
											fontSize: "12px",
											paddingBottom: "5px",
											paddingTop: "5px",
											color: cellData < 0 && "red",
											width: "8%",
										};
									},
									headerStyle: {
										fontSize: "14px",
										paddingTop: "0px",
										paddingBottom: "0px",
										backgroundColor: "#fafafa",
									},
									render: (rowData) => buildActionsButtons(rowData),
								},
							]}
							data={data()}
							noBoxShadow
							components={{
								Header: props =>
									<MTableHeader {...props}
										orderBy={orderBy}
										orderDirection={orderDirection}
										onOrderChange={(orderBy, orderDirection) => {
											setLoadingSort(true);
											onOrderChange(orderBy, orderDirection);
											props.onOrderChange(orderBy, orderDirection);
											setTimeout(() => setLoadingSort(false), 500);
										}}
									/>
							}}
							customOptions={{
								thirdSortClick: false,
								sorting: true,
								grouping: false,
								draggable: false,
								search: false,
								paging: false,
								toolbar: false,
								showTitle: false,
								maxBodyHeight: 500,
								rowStyle: (rowData, index) => ({
									color: rowData.tableData.monto < 0 && "red",
									backgroundColor: !isEven(index) && "#f2f2f2",
								}),
							}}
              tableKey={"tabla-deuda-clientes"}
						/>
					</div>
				</Fragment>
				)}
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		deudasPorCliente: state.ventas.deudaDelMesPorCliente,
		ordenDeuda: state.ventas.deudaDelMesPorCliente.orden,
		fechaDeudaDelMes: state.ventas.fechaDeudasDelMesAnio,
		estaCargado: state.ventas.deudaDelMesPorCliente.estaCargado,
		ejecucionIndicador: state.ventas.deudaDelMesPorCliente.ejecucionIndicador,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onShowFiltersModal: (deudasPor) => dispatch(actions.showListFiltersModal(true, `Deudas del mes por ${deudasPor}`, ["deuda"])),
		onClearChart: () => dispatch(actions.clearIndicadoresCliente()),
		onOpenClienteModal: (show, title, body) => dispatch(actions.showEstadoModal(show, title, body)),
		onLoadDeudasDelMesPorOrdenamiento: (mes, anio) => dispatch(actions.loadDeudaDelMesPorOrdenamiento(2, mes, anio)),
		onPlayIndicador: () => dispatch(actions.ejecucionDeudaDelMesPorOrdenamiento(true)),
		cambiarOrden: (idUsuario, orden, success) => dispatch(actions.cambiarOrdenDeudaCliente(idUsuario, orden, success)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TablaDeudaClientes);
