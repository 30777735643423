import React, {useState, Fragment} from 'react'
import Dialog from '../../components/UI/Dialog/Dialog'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import moment from 'moment'
import { Grid, IconButton } from '@material-ui/core'
import TextoVentasMensuales from '../../components/Indicadores/Ventas/TextoVentasMensuales/TextoVentasMensuales'
import TextoVentasDiarias from '../../components/Indicadores/Ventas/TextoVentasDiarias/TextoVentasDiarias'
import TextoChequesEnCartera from '../../components/Indicadores/Ventas/TextoChequesEnCartera/TextoChequesEnCartera'
import TextoChequesRechazados from '../../components/Indicadores/Ventas/TextoChequesRechazados/TextoChequesRechazados'
import TablaDeudaClientes from '../../components/Indicadores/Ventas/TablaDeudaClientes/TablaDeudaClientes'
import TablaStockValorizado from '../../components/Indicadores/Stock/TablaStockValorizado/TablaStockValorizado'
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo'
import TextoCobranzasDelDia from '../../components/Indicadores/Ventas/TextoCobranzasDelDia/TextoCobranzasDelDia'
import TextoChequesDepositadosDelDia from '../../components/Indicadores/Ventas/TextoChequesDepositadosDelDia/TextoChequesDepositadosDelDia'
import TextoNotasDePedido from '../../components/Indicadores/Ventas/TextoNotasDePedido/TextoNotasDePedido'
import TextoIvaFiscalConSaldo from '../../components/Indicadores/Impuestos/TextoIvaFiscalConSaldo/TextoIvaFiscalConSaldo'
import { compareFechaActualFechaFija } from '../../shared/fechas';
import CloseIcon from '@material-ui/icons/Close';
import DetalleCheques from '../DetalleCheques/DetalleCheques.js';
import TablaVentasPorSeleccion from '../../components/Indicadores/Ventas/TablaVentasPorSeleccion/TablaVentasPorSeleccion'
import PieVentasPorSeleccion from '../../components/Indicadores/Ventas/PieVentasPorSeleccion/PieVentasPorSeleccion';

import classes from './PanelNoganet.css'

const PanelNoganet = (props) => {
  sessionStorage.setItem('seccionNombre', 'Home Noganet');
  const [dialogState, setDialogState] = useState({
    show: false,
    content: '',
    empresaSeleccionada: ''
  });
  
  const inPhone = window.screen.width <= 750;
  const showContent = () => {
    switch (dialogState.content) {
      case 'chequesEnCartera':
        return <DetalleCheques tiposCheques='EnCartera' />;

      case 'chequesRechazados':
        return <DetalleCheques tiposCheques='Rechazados' rechazados={true} />;

      case 'chequesPropios':
        return <DetalleCheques tiposCheques='Propios' />;
      
      case 'chequesDepositadosDelDia':
        return <DetalleCheques tiposCheques='DelDia' delDia={true}/>
      default:
        return null;
    }
  };

  const onShowDialogClick = (content) => {
    setDialogState({
      show: true,
      content: content
    });
  };

  const handleCloseDialog = () => {
    setDialogState({
      show: false,
      content: ''
    });
    props.onClearDetail();
  };

  const closeModal = () => {
    return inPhone ? (
      <Grid container
        direction='row'
        alignItems='center'
        style={{ justifyContent:'flex-end' }}
      >
        <Grid item>
          <IconButton aria-label="close" onClick={() => handleCloseDialog()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    ) : (
      <Fragment></Fragment>
    )
  }

  return ((
      <div className={classes.PanelNoganetContainer}>
        {!props.ejecucionPanelNoganet ? 
        (<BoxEjecutarPaginaInfo/>) :
        (<div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}
                container spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch" >
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoVentasMensuales ejecucionDePagina={props.ejecucionPanelNoganet} border />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoVentasDiarias ejecucionDePagina={props.ejecucionPanelNoganet} border />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextoCobranzasDelDia ejecucionDePagina={props.ejecucionPanelNoganet} evolucion/> 
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={3}>
                  <TextoNotasDePedido ejecucionDePagina={props.ejecucionPanelNoganet} border />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}
                container spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch">

                <Grid item xs={12} sm={12} md={6} lg={3}
                  container spacing={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch" 
                  style={{paddingRight: '0px'}}
                >
                  <Grid item style={{paddingRight: '0px'}}>
                    <TextoIvaFiscalConSaldo ejecucionDePagina={props.ejecucionPanelNoganet} border />
                  </Grid>
                  <Grid item style={{paddingRight: '0px'}}>
                    <TextoChequesDepositadosDelDia ejecucionDePagina={props.ejecucionPanelNoganet} onBodyClick={ !compareFechaActualFechaFija() ? null : () => onShowDialogClick('chequesDepositadosDelDia') }/>
                  </Grid>
                  <Grid item style={{paddingRight: '0px'}}>
                    <TextoChequesEnCartera ejecucionDePagina={props.ejecucionPanelNoganet} onBodyClick={ !compareFechaActualFechaFija() ? null : () => onShowDialogClick('chequesEnCartera') } />
                  </Grid>
                  <Grid item style={{paddingRight: '0px'}}>
                    <TextoChequesRechazados ejecucionDePagina={props.ejecucionPanelNoganet} onBodyClick={ !compareFechaActualFechaFija() ? null : () => onShowDialogClick('chequesRechazados') } /> 
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={9} >
                  <Grid item className={classes.TablaDeuda}> 
                    <TablaDeudaClientes ejecucionDePagina={props.ejecucionPanelNoganet} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}
                container spacing = {1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                style={{paddingRight: '0px'}}>
                  <Grid item xs={12} sm={12} md={6} lg={12} style={{paddingRight: '0px'}}>
                    <TablaStockValorizado ejecucionDePagina={props.ejecucionPanelNoganet}/>
                  </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}
                container spacing = {1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                className={classes.CombinedChartsCard}
                >
                <Grid item xs={12} lg={4}>
                  <PieVentasPorSeleccion
                    inGastos
                    legendHeight={50}
                    legendPosition={'bottom'}
                    height={436}
                    width={'100%'}
                    chartType={'pie'}
                    forcedDataOption={'vendedor'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} style={{paddingRight: '0px'}}>
                  <TablaVentasPorSeleccion />
                </Grid>
              </Grid>
            </Grid>
        </div>)}
        <Dialog
          title={closeModal()}
          show={dialogState.show}
          onClose={handleCloseDialog}
          body={<div style={{ minWidth: !inPhone && '500px' }}>{showContent()}</div>}
        />
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionPanelNoganet: state.ejecucionPaginas.ejecucionPanelNoganet, 
    loading: state.ventas.detalleCheques.loading,
    cheques: state.ventas.detalleCheques.cheques,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarNoganetHome: () => dispatch(actions.ejecutarNoganetHome()),
    updateUltimaActualizacion: () => dispatch(actions.updateUltimaActualizacionNoganetHome(moment(Date.now()).format('DD/MM/YYYY HH:mm'))),
    onClearDetail: () => dispatch(actions.clearDetalleCheques()),
    onLoadDetail: (propios, rechazados, idEmpresa) => dispatch(actions.loadDetalleCheques(idEmpresa, propios, rechazados)),
  };
  
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelNoganet);