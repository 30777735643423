const validateIsEmpty = (value) => {
  return (!value || value === "");
};

const validateIsNotANumber = (value) => {
  return isNaN(value);
};

const validateInList = (value, list) => {
  return (!list.includes(value));
};

const validateInListTipoDato = (value) => {
  return validateInList(value, ["C", "N", "B"]);
};

const validateInListTipoVenta = (value) => {
  return validateInList(value, ["L", "E"]);
};

export const validate = (json, anioFiscal = "") => {
  var result = [];

  const header = json[0];
  var headerErrors = [];

  if (!header.hasOwnProperty('A') || header.A.toString().toLowerCase().trim() !== "tipodato")
    headerErrors.push("La celda A1 debe decir TIPODATO.");

  if (!header.hasOwnProperty('B') || header.B.toString().toLowerCase().trim() !== "mercado")
    headerErrors.push("La celda B1 debe decir MERCADO.");

  if (!header.hasOwnProperty('C') || header.C.toString().toLowerCase().trim() !== "articulo")
    headerErrors.push("La celda C1 debe decir ARTICULO.");

  if (!header.hasOwnProperty('P') || header.P.toString().toLowerCase().trim() !== "total")
    headerErrors.push("La celda P1 debe decir TOTAL.");

  if (!header.hasOwnProperty('Q') || header.Q.toString().toLowerCase().trim() !== anioFiscal)
    headerErrors.push(`La celda Q1 debe contener el año fiscal seleccionado (${anioFiscal}).`);

  if (headerErrors.length > 0) {
    result.push({
      rowNumber: 1,
      product: "Cabecera del archivo",
      errors: headerErrors
    });
    return result;
  }

  json.slice(1).forEach(row => {
    var errors = [];

    // Column "A"
    if (!row.hasOwnProperty('A') || validateIsEmpty(row.A)) {
      errors.push("La columna A no puede estar vacía.");
    }
    else if (validateInListTipoDato(row.A)) {
      errors.push("La columna A debe contener los valores C, N o B.");
    }

    // Column "B"
    if (!row.hasOwnProperty('B') || validateIsEmpty(row.B)) {
      errors.push("La columna B no puede estar vacía.");
    }
    else if (validateInListTipoVenta(row.B)) {
      errors.push("La columna B debe contener los valores L o E.");
    }

    // Columna "C"
    if (!row.hasOwnProperty('C') || validateIsEmpty(row.C)) {
      errors.push("La columna C no puede estar vacía.");
    }

    // Columna "D" a "O"
    ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"].forEach(col => {
      if (!row.hasOwnProperty(col) || validateIsNotANumber(row[col])) {
        errors.push(`La columna ${col} debe contener números.`);
      }
    });

    // Fila duplicada
    const rowsDuplicated = json.filter(e => (
      e.hasOwnProperty('A') && row.hasOwnProperty('A')
      && e.hasOwnProperty('B') && row.hasOwnProperty('B')
      && e.hasOwnProperty('C') && row.hasOwnProperty('C')
      && e.A.toString().trim() === row.A.toString().trim()
      && e.B.toString().trim() === row.B.toString().trim()
      && e.C.toString().trim() === row.C.toString().trim()
      && e.__rowNum__ !== row.__rowNum__
    ));

    if (rowsDuplicated.length > 0) {
      const message = `La fila ${row.__rowNum__} se repite en `
        + `${rowsDuplicated.length === 1 ? 'la fila ' : 'las filas '}`
        + `${rowsDuplicated.map(e => e.__rowNum__).join(', ')}`;
      errors.push(message);
    }

    // Existen las filas C, N y B obligatoriamente
    const tipoDatoOcurrencias = json.filter(e => (
      e.hasOwnProperty('B') && row.hasOwnProperty('B')
      && e.hasOwnProperty('C') && row.hasOwnProperty('C')
      && e.B.toString().trim() === row.B.toString().trim()
      && e.C.toString().trim() === row.C.toString().trim()
    ));

    if (tipoDatoOcurrencias.length !== 3) {
      const ocur = tipoDatoOcurrencias.map(e => e.A);
      const diff = ["C", "N", "B"].filter(e => !ocur.includes(e));
      const message = `El artículo ${row.C.toString().trim()} no contiene `
        + `${diff.length === 1 ? 'el registro con' : 'los registros con'} `
        + `${diff.length === 1 ? diff[0] : diff.slice(0, diff.length - 1).join(", ") + ' y ' + diff[diff.length - 1]} `
        + 'en la columna A.';
      errors.push(message);
    }

    if (errors.length > 0) {
      result.push({
        rowNumber: row.__rowNum__,
        product: row.C,
        errors
      });
    }
  });

  return result;
}