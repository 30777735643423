import * as actionTypes from './actionTypes';
import axios from 'axios';
import { logout } from './auth';
import { storageEmpresaId } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';
import { baseUrlDashboard } from '../../shared/urlApps';

export const monedaDefecto = (token, success, error) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(token);
    axios
      .get(`${baseUrlDashboard()}monedas/Defecto?indicadorId=${idIndicadores.monedaDefecto}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        return success();
      })
      .catch((error) => {
        dispatch(logout());
      });
  };
};

export const seleccionarMoneda = (idMoneda, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(executeCambiarMoneda(true));
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    const baseUrl = baseUrlDashboard();

    axios
      .get(
        `${baseUrl}monedas/cambiarMoneda?monedaId=${idMoneda}&empresaId=${idEmpresa}&indicadorId=${idIndicadores.cambiarMoneda}`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        executeCambiarMoneda(false);
      });
  };
};

const executeCambiarMoneda = (loadingCambiarMoneda) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_MONEDA,
    loadingCambiarMoneda
  };
};

export const loadMonedas = (token, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadMonedasStart());
    const authStr = 'Bearer '.concat(token);
    axios
      .get(`${baseUrlDashboard()}monedas/monedas?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.monedas}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadMonedasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadMonedasFail(error));
          } else {
            dispatch(loadMonedas(token, idEmpresa));
          }
        } else {
          dispatch(loadMonedasFail(error));
        }
      });
  };
};

const loadMonedasStart = () => {
  return {
    type: actionTypes.LOAD_MONEDAS_START
  };
};

const loadMonedasSuccess = (monedas) => {
  return {
    type: actionTypes.LOAD_MONEDAS_SUCCESS,
    monedas
  };
};

const loadMonedasFail = (error) => {
  return {
    type: actionTypes.LOAD_MONEDAS_FAIL,
    error
  };
};
