import { fechaActualFunc } from '../../shared/fechas';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  lineRentabilidadEvolutivo: {
    loading: false,
    error: null,
    estaCargado: false,
    progress: 0
  },
  textoSaldoEfectivo: {
    loading: false,
    error: null,
    monto: 0,
    estaCargado: false
  },
  fechaPeriodoRentabilidad: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear()
  },
  rentabilidadEvolutivoPorSucursales: {
    loading: false,
    error: null,
    estaCargado: false,
    data: [],
    columns: [],
    progress: 0
  },
  filtroRentabilidadPorSucuersales: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear(),
    verRentabilidadPor: 0,
  },
};

//#region load Rentabilidad
const loadLineRentabilidadEvolutivoStart = (state) => {
  return updateObject(state, {
    lineRentabilidadEvolutivo: updateObject(state.lineRentabilidadEvolutivo, {
      loading: true
    })
  });
};

const loadLineRentabilidadEvolutivoSuccess = (state, data, columns) => {
  return updateObject(state, {
    lineRentabilidadEvolutivo: updateObject(state.lineRentabilidadEvolutivo, {
      ...data,
      columns,
      loading: false,
      estaCargado: true
    })
  });
};

const loadLineRentabilidadEvolutivoFail = (state, error) => {
  return updateObject(state, {
    lineRentabilidadEvolutivo: updateObject(state.lineRentabilidadEvolutivo, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearLineRentabilidadEvolutivo = (state) => {
  return updateObject(state, {
    lineRentabilidadEvolutivo: updateObject(state.lineRentabilidadEvolutivo, {
      loading: false,
      error: null,
      cobranzas: [],
      costos: [],
      ganancia: [],
      gastos: [],
      utilidad: [],
      ventas: [],
      estaCargado: false
    })
  });
};

const seleccionFechaPeriodoRentabilidad = (state, mes, anio) => {
  return updateObject(state, {
    fechaPeriodoRentabilidad: { mes, anio }
  });
}
//#endregion

//#region load Rentabilidad Por Sucursales
const loadRentabilidadEvolutivoPorSucursalesStart = (state) => {
  return updateObject(state, {
    rentabilidadEvolutivoPorSucursales: updateObject(state.rentabilidadEvolutivoPorSucursales, {
      data: null,
      columns: [],
      loading: true,
      estaCargado: false
    })
  });
};

const loadRentabilidadEvolutivoPorSucursalesSuccess = (state, data, columns) => {
  return updateObject(state, {
    rentabilidadEvolutivoPorSucursales: updateObject(state.rentabilidadEvolutivoPorSucursales, {
      data,
      columns,
      loading: false,
      estaCargado: true
    })
  });
};

const loadRentabilidadEvolutivoPorSucursalesFail = (state, error) => {
  return updateObject(state, {
    rentabilidadEvolutivoPorSucursales: updateObject(state.rentabilidadEvolutivoPorSucursales, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearRentabilidadEvolutivoPorSucursales = (state) => {
  return updateObject(state, {
    rentabilidadEvolutivoPorSucursales: updateObject(state.rentabilidadEvolutivoPorSucursales, {
      loading: false,
      error: null,
      estaCargado: false,
      columns: [],
      data: []
    })
  });
};

const seleccionFiltroRentabilidadPorSucursales = (state, mes, anio, seleccion) => {
  return updateObject(state, {
    filtroRentabilidadPorSucuersales: updateObject(state.filtroRentabilidadPorSucuersales, {
      mes,
      anio,
      verRentabilidadPor: seleccion
    })
  });
}
//#endregion

//#region textoSaldoEfectivo
const loadTextoSaldoEfectivoStart = (state) => {
  return updateObject(state, {
    textoSaldoEfectivo: updateObject(state.textoSaldoEfectivo, {
      loading: true,
      error: null,
      monto: 0
    })
  });
};

const loadTextoSaldoEfectivoSuccess = (state, monto) => {
  return updateObject(state, {
    textoSaldoEfectivo: updateObject(state.textoSaldoEfectivo, {
      loading: false,
      monto: monto,
      estaCargado: true
    })
  });
};

const loadTextoSaldoEfectivoFail = (state, error) => {
  return updateObject(state, {
    textoSaldoEfectivo: updateObject(state.textoSaldoEfectivo, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LINE_RENTABILIDAD_EVOLUTIVO_START:
      return loadLineRentabilidadEvolutivoStart(state);
    case actionTypes.LINE_RENTABILIDAD_EVOLUTIVO_SUCCESS:
      return loadLineRentabilidadEvolutivoSuccess(state, action.data, action.columns);
    case actionTypes.LINE_RENTABILIDAD_EVOLUTIVO_FAIL:
      return loadLineRentabilidadEvolutivoFail(state, action.error);
    case actionTypes.CLEAR_LINE_RENTABILIDAD_EVOLUTIVO:
      return clearLineRentabilidadEvolutivo(state);

    case actionTypes.TEXTO_SALDO_EFECTIVO_START:
      return loadTextoSaldoEfectivoStart(state);

    case actionTypes.TEXTO_SALDO_EFECTIVO_SUCCESS:
      return loadTextoSaldoEfectivoSuccess(state, action.monto);

    case actionTypes.TEXTO_SALDO_EFECTIVO_FAIL:
      return loadTextoSaldoEfectivoFail(state, action.error);

    case actionTypes.SELECCION_FECHA_PERIODO_RENTABILIDAD:
      return seleccionFechaPeriodoRentabilidad(state, action.mes, action.anio);

    case actionTypes.RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_START:
      return loadRentabilidadEvolutivoPorSucursalesStart(state);
    case actionTypes.RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_SUCCESS:
      return loadRentabilidadEvolutivoPorSucursalesSuccess(state, action.data, action.columns);
    case actionTypes.RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_FAIL:
      return loadRentabilidadEvolutivoPorSucursalesFail(state, action.error);
    case actionTypes.CLEAR_RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES:
      return clearRentabilidadEvolutivoPorSucursales(state);

    case actionTypes.SELECCION_FILTRO_RENTABILIDAD_POR_SUCURSALES:
      return seleccionFiltroRentabilidadPorSucursales(state, action.mes, action.anio, action.seleccion);

    default:
      return state;
  }
};

export default reducer;
