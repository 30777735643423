import React, { useEffect, useState } from 'react';
import { DataGrid, Column, Button } from 'devextreme-react/data-grid';
import { connect } from 'react-redux';
import eyeIcon from '../../../assets/images/eye-icon.svg';
import ModalQueryReq from '../ModalQueryReq/ModalQueryReq';

import classes from './DetailTiemposReq.css';
import { loadDetalleTiemposRequest } from '../../../store/actions';
import Spinner from '../../UI/Spinner/Spinner';

const DetailTiemposReq = (props) => {
  const [queryDialogState, setQueryDialogState] = useState({
    show: false,
    content: ''
  });

  const [loading, setLoading] = useState(false);
  const [formatTiempos, setFormatTiempos] = useState([]);

  useEffect(() => {
    setLoading(true);
    const key = props.data.key;
    let formatTiemposs = [];
    
    loadDetalleTiemposRequest(
      key,
      props.clienteSelectedId,
      (tiemposRequest) => {
        tiemposRequest.map((tiempoRequest) => {
          return formatTiemposs.push({
            serviceId: tiempoRequest.serviceId,
            empresa: tiempoRequest.empresa.nombre,
            fecha: tiempoRequest.fecha,
            tiempo: tiempoRequest.tiempo,
            tiempoApiTraductora: tiempoRequest.tiempoProcesoApiTraductora,
            tiempoQuery: tiempoRequest.tiempoEjecucionQuery,
            usuario: tiempoRequest.usuario && tiempoRequest.usuario.nombre != null ? tiempoRequest.usuario.nombre : '',
            query: tiempoRequest.query
          });
        });
        setFormatTiempos(formatTiemposs)
        setLoading(false);
      },
      (error) => {
      }
    );
  }, []);

  const onVerQueryClick = (e) => {
    setQueryDialogState({
      show: true,
      content: e.row.data.query.slice(10, -1)
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <DataGrid dataSource={formatTiempos} showBorders={true} columnAutoWidth={true}>
          <Column dataField="serviceId" dataType="number" alignment="left" />
          <Column dataField="usuario" />
          <Column dataField="empresa" />
          <Column dataField="fecha" dataType="datetime" format={'dd/MM/yyyy HH:mm:ss'} />
          <Column dataField="tiempo" caption="Tiempo Api Repository" />
          <Column dataField="tiempoApiTraductora" />
          <Column dataField="tiempoQuery" />
          <Column caption="Ver Query" type="buttons">
            <Button cssClass={classes.eyeButton} icon={eyeIcon} onClick={(e) => onVerQueryClick(e)} />
          </Column>
        </DataGrid>
      )}
      {queryDialogState.show && (
        <ModalQueryReq
          show={queryDialogState.show}
          query={queryDialogState.content}
          onClose={() => setQueryDialogState({ show: false, content: '' })}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tiempos: state.configuraciones.loadTiemposRequest.tiempos,
    clienteSelectedId: state.clientes.clientSelected.clienteIdPg
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTiemposReq);
