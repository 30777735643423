import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';

const TextoPlazoMedioVencimientoAPagar = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  return (
    <TextChart
      title="Plazo medio vencimientos a pagar"
      bigAmount={`Días: ${Intl.NumberFormat('de-DE').format(props.promedioDias)}`}
      bigAmountTooltip="Promedio de días"
      smallAmount={props.deuda}
      smallAmountTooltip="Total"
      smallCurrency
      disabled={props.estaOculto}
      loading={props.loading}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.compras.textoDeudasPorCompras.loading,
    deuda: state.compras.textoDeudasPorCompras.deuda,
    promedioDias: state.compras.textoDeudasPorCompras.promedioDias,
    error: state.compras.textoDeudasPorCompras.error,
    estaCargado: state.compras.textoDeudasPorCompras.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.plazoMedioVencAPagar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadTextoPlazoMedioAPagar()),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Plazo medio vencimientos a pagar', ['compra']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPlazoMedioVencimientoAPagar);
