// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmallChartCard__SmallChartCard__L-C-z {\r\n  border: 0px solid #ccc;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  box-sizing: border-box;\r\n  text-align: center;\r\n  background-color: white;\r\n  border-radius: 3px;\r\n  height: 100%;\r\n}\r\n\r\n.SmallChartCard__TitleCard__b2VNW {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.SmallChartCard__imgIndicadorDeshabilitado__drfh0 {\r\n  max-width: 360px;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .SmallChartCard__SmallChartCard__L-C-z {\r\n    width: 100%;\r\n  }\r\n  .SmallChartCard__imgIndicadorDeshabilitado__drfh0 {\r\n    max-width: 305px;\r\n  }\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .SmallChartCard__SmallChartCard__L-C-z {\r\n    width: 100%;\r\n  }\r\n\r\n  .SmallChartCard__imgIndicadorDeshabilitado__drfh0 {\r\n    max-width: 305px;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SmallChartCard": "SmallChartCard__SmallChartCard__L-C-z",
	"TitleCard": "SmallChartCard__TitleCard__b2VNW",
	"imgIndicadorDeshabilitado": "SmallChartCard__imgIndicadorDeshabilitado__drfh0"
};
export default ___CSS_LOADER_EXPORT___;
