import React, { Fragment, useCallback, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions';
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CustomSelector from "../../../../../UI/CustomSelector/CustomSelector";
import ButtonPivotGrid from "../../../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid";
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import Spinner from "../../../../../UI/Spinner/Spinner";
import moment from "moment";
import "moment/locale/es";

import FiltroFechaSelector from "../../../../../UI/FiltroFechaSelector/FiltroFechaSelector";
import BudgetForecastSelector from "../../../../../UI/Informes/BudgetForecastSelector/BudgetForecastSelector";
import LeyendaFiltros from "../../../../../UI/Informes/LeyendaFiltros/LeyendaFiltros";

import ConfirmDialog from '../../../../../UI/Dialog/ConfirmDialog';
import OKDialog from '../../../../../UI/Dialog/OKDialog';
import { CloudDownloadOutlined } from "@material-ui/icons";

moment.locale("es");

function getMonths() {
  return [
    { id: 4, nombre: 'Abril' },
    { id: 5, nombre: 'Mayo' },
    { id: 6, nombre: 'Junio' },
    { id: 7, nombre: 'Julio' },
    { id: 8, nombre: 'Agosto' },
    { id: 9, nombre: 'Septiembre' },
    { id: 10, nombre: 'Octubre' },
    { id: 11, nombre: 'Noviembre' },
    { id: 12, nombre: 'Diciembre' },
    { id: 1, nombre: 'Enero' },
    { id: 2, nombre: 'Febrero' },
    { id: 3, nombre: 'Marzo' },
  ]
}

const useStyles = makeStyles(() => ({
  checkBoxLabel: {
    fontSize: 'x-small',
    "& .MuiFormControlLabel-label": {
      fontSize: "x-small",
    }
  },
}));

const FiltroInformeDos = (props) => {
  const classes = useStyles();
  const { aplicarFiltros, exportarExpandido } = props;
  const { loading, loadingInforme, estaCargado, aniosFiscales, onLoadData, filtros, filtrosInforme,
    onUpdateFiltros, onLoadBudgets, budgetsForecasts, loadingBudgets, anioBudgets,
    optionSelected, dataOptions, onOptionSelectedChange } = props;
  const { exportarExpandidoLoading, exportarExpandidoError,
    exportarExpandidoEstaCargado, exportarExpandidoExcelBase64,
    onExportarExpandidoClear } = props;
  const [anioFiscal, setAnioFiscal] = useState();
  const [anioFiscalDesde, setAnioFiscalDesde] = useState();
  const [anioFiscalHasta, setAnioFiscalHasta] = useState();
  const [mesFiscal, setMesFiscal] = useState('');
  const [meses] = useState(getMonths());
  const [acumulado, setAcumulado] = useState(filtros ? filtros.acumulado : false);
  const [idArchivo, setIdArchivo] = useState('');
  const [desde, setDesde] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [loadingDesde, setLoadingDesde] = useState(false);
  const [hasta, setHasta] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loadingHasta, setLoadingHasta] = useState(false);
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const anioFiscalSelected = anioFiscal !== '';
  const idArchivoSelected = idArchivo !== '';
  const mesSelected = mesFiscal !== '';

  const habilitar = useCallback(() => {
    const dateValid = (desde !== null && desde !== undefined && hasta !== null && hasta !== undefined)
      && desde < hasta;
    const habilitarAplicar = anioFiscal !== '' && idArchivo !== ''
      && (mesFiscal !== '' || dateValid);

    return !habilitarAplicar || loadingInforme;
  }, [anioFiscal, mesFiscal, idArchivo, desde, hasta]);

  useEffect(() => {
    if (!estaCargado && !loading)
      onLoadData();
  }, [estaCargado, loading]);

  useEffect(() => {
    if (!anioFiscalDesde && estaCargado && aniosFiscales && aniosFiscales.length > 0) {
      const anio = Number(aniosFiscales[aniosFiscales.length - 1].id);
      setAnioFiscalDesde(new Date(anio, 0, 1));
    }
  }, [anioFiscalDesde, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (!anioFiscalHasta && estaCargado && aniosFiscales && aniosFiscales.length > 0) {
      const anio = Number(aniosFiscales[0].id);
      setAnioFiscalHasta(new Date(anio, 11, 31));
    }
  }, [anioFiscalHasta, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (filtros && estaCargado && !anioFiscal && aniosFiscales && aniosFiscales.length > 0) {
      let item = aniosFiscales.find((i) => i.id === `${filtros.anioFiscal}`);

      if (item)
        setAnioFiscal(new Date(filtros.anioFiscal, 0, 1).getFullYear());
      else if (anioFiscal !== '')
        setAnioFiscal(new Date().getFullYear());
    }
  }, [filtros, estaCargado, anioFiscal, aniosFiscales]);

  useEffect(() => {
    if (filtros) {
      let item = meses.find((i) => i.id === filtros.mesFiscal);

      if (item)
        setMesFiscal(item);
      else if (mesFiscal !== '')
        setMesFiscal('');
    }
  }, [filtros, meses]);

  useEffect(() => {
    if (filtros && filtros.idArchivo && filtros.anioFiscal === anioBudgets && budgetsForecasts) {
      let item = budgetsForecasts.find((i) => i.id === `${filtros.idArchivo}`);

      if (item)
        setIdArchivo(item);
      else if (idArchivo !== '')
        setIdArchivo('');
    }
  }, [filtros, filtros.idArchivo, anioBudgets, budgetsForecasts]);

  useEffect(() => {
    if (filtros && filtros.acumulado !== undefined) {
      setAcumulado(filtros.acumulado);
    }
  }, [filtros, filtros.acumulado]);

  useEffect(() => {
    if (filtros && !desde && filtros.desde !== desde) {
      setLoadingDesde(true);
      setDesde(filtros.desde);
      setTimeout(() => setLoadingDesde(false), 500);
    }
  }, [filtros, filtros.desde, desde]);

  useEffect(() => {
    if (filtros && !hasta && filtros.hasta !== hasta) {
      setLoadingHasta(true);
      setHasta(filtros.hasta);
      setTimeout(() => setLoadingHasta(false), 500);
    }
  }, [filtros, filtros.hasta, hasta]);

  useEffect(() => {
    if (!minDate && !maxDate && filtros && filtros.anioFiscal && filtros.mesFiscal) {
      let anio = filtros.anioFiscal;
      let mes = filtros.mesFiscal;
      let daysInMonth = 0;
      let min = null;
      let max = null;

      if (acumulado) {
        daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

        min = new Date(anio, 3, 1);
        max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      } else {
        if (mes < 4)
          anio = anio + 1;

        daysInMonth = new Date(anio, mes, 0).getDate();

        min = new Date(anio, mes - 1, 1);
        max = new Date(anio, mes - 1, daysInMonth);
      }

      setMinDate(min);
      setMaxDate(max);
    }

  }, [filtros, minDate, maxDate, acumulado]);

  const onAplicarFiltroClick = () => {
    let archivo = idArchivo ? idArchivo.id : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    let desdeDate = desde ? new Date(desde) : null;
    let hastaDate = hasta ? new Date(hasta) : null;
    onUpdateFiltros(anioFiscal, archivo, mes, acumulado, desdeDate, hastaDate);
    aplicarFiltros(anioFiscal, archivo, mes, acumulado, desde, hasta);
    setCambiosAplicados(true);
  }

  const onExportarExpandido = () => {
    let archivo = idArchivo ? idArchivo.id : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    exportarExpandido(anioFiscal, archivo, mes, acumulado, desde, hasta);
  }

  const onAnioFiscalChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);
    let anio = value ? value.getFullYear() : new Date().getFullYear();
    setAnioFiscal(anio);
    onLoadBudgets(anio);
    setIdArchivo('');
    setMesFiscal('');

    setDesde(new Date(anio, 3, 1));
    setHasta(new Date(anio + 1, 2, 31));
    setMinDate(new Date(anio, 3, 1));
    setMaxDate(new Date(anio + 1, 2, 31));

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);

    setCambiosAplicados(false);
  };

  const onIdArchivoChange = (value) => {
    setIdArchivo(value);
    setMesFiscal('');
    setCambiosAplicados(false);
  };

  const onMesFiscalChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);

    setMesFiscal(value);
    let anio = anioFiscal ? Number(anioFiscal) : undefined;
    let mes = value ? value.id : undefined;
    let daysInMonth = 0;
    let desdeDate = null;
    let hastaDate = null;
    let min = null;
    let max = null;

    if (acumulado) {
      daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

      desdeDate = new Date(anio, 3, 1);
      hastaDate = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      min = new Date(anio, 3, 1);
      max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
    } else {
      if (mes < 4)
        anio = anio + 1;

      daysInMonth = new Date(anio, mes, 0).getDate();

      desdeDate = new Date(anio, mes - 1, 1);
      hastaDate = new Date(anio, mes - 1, daysInMonth);
      min = new Date(anio, mes - 1, 1);
      max = new Date(anio, mes - 1, daysInMonth);
    }

    setDesde(desdeDate);
    setHasta(hastaDate);
    setMinDate(min);
    setMaxDate(max);

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);

    setCambiosAplicados(false);
  };

  const onAcumuladoChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);

    setAcumulado(value);
    let anio = anioFiscal ? Number(anioFiscal) : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    let daysInMonth = 0;
    let desdeDate = null;
    let hastaDate = null;
    let min = null;
    let max = null;

    if (value) {
      daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

      desdeDate = new Date(anio, 3, 1);
      hastaDate = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      min = new Date(anio, 3, 1);
      max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
    } else {
      if (mes < 4)
        anio = anio + 1;

      daysInMonth = new Date(anio, mes, 0).getDate();

      desdeDate = new Date(anio, mes - 1, 1);
      hastaDate = new Date(anio, mes - 1, daysInMonth);
      min = new Date(anio, mes - 1, 1);
      max = new Date(anio, mes - 1, daysInMonth);
    }

    setDesde(desdeDate);
    setHasta(hastaDate);
    setMinDate(min);
    setMaxDate(max);

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);
  };

  const onDesdeChange = (value) => {
    setDesde(value)
    setCambiosAplicados(false);
  };

  const onHastaChange = (value) => {
    setHasta(value);
    setCambiosAplicados(false);
  };

  // #region Exportar Expandido Confirm Dialog
  const [exportarExpandidoConfirmDialogOpen, setExportarExpandidoConfirmDialogOpen] = useState(false);

  const handleExportarExpandidoConfirmDialogOpen = () => {
    setExportarExpandidoConfirmDialogOpen(true);
  };

  const handleExportarExpandidoConfirmDialogClose = () => {
    setExportarExpandidoConfirmDialogOpen(false);
  };
  
  const exportarExpandidoConfirmDialog = () => (
    <ConfirmDialog
      show={exportarExpandidoConfirmDialogOpen}
      title="Exportar Informe 2"
      body={
        <div>
          Este proceso genera un informe abierto por rubro y artículo, por lo que podría demorar varios minutos.
          <br />
          Para continuar, por favor confirme la acción.
        </div>
      }
      onConfirm={() => {
        onExportarExpandido();
        handleExportarExpandidoConfirmDialogClose();
      }}
      onClose={handleExportarExpandidoConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - Confirm dialog

  // #endregion

  // #region Descargar archivo Excel generado
  useEffect(() => {
    if (exportarExpandidoLoading) return;

    if (exportarExpandidoError != null) {
      handleExportarExpandidoErrorDialogOpen();
    }
    else if (exportarExpandidoEstaCargado
      && exportarExpandidoExcelBase64
      && exportarExpandidoExcelBase64.trim().length > 0) {
        handleExportarExpandidoDescargarDialogOpen();
      }

  }, [exportarExpandidoLoading, exportarExpandidoEstaCargado, exportarExpandidoError, exportarExpandidoExcelBase64])

  // Exportar Expandido - Error dialog
  const [exportarExpandidoErrorDialogOpen, setExportarExpandidoErrorDialogOpen] = useState(false);

  const handleExportarExpandidoErrorDialogOpen = () => {
    setExportarExpandidoErrorDialogOpen(true);
  };

  const handleExportarExpandidoErrorDialogClose = () => {
    setExportarExpandidoErrorDialogOpen(false);
  };
  
  const exportarExpandidoErrorDialog = () => (
    <OKDialog
      show={exportarExpandidoErrorDialogOpen}
      title="Exportar Informe 2"
      body={<div>No fue posible exportar el Informe 2.<br />Por favor, vuelva a intentarlo.</div>}
      onConfirm={handleExportarExpandidoErrorDialogClose}
      onClose={handleExportarExpandidoErrorDialogClose}
    >
    </OKDialog>
  );
  // - Exportar Expandido - Error dialog

  // Exportar Expandido - Descargar dialog
  const [exportarExpandidoDescargarDialogOpen, setExportarExpandidoDescargarDialogOpen] = useState(false);

  const handleExportarExpandidoDescargarDialogOpen = () => {
    setExportarExpandidoDescargarDialogOpen(true);
  };

  const handleExportarExpandidoDescargarDialogClose = () => {
    setExportarExpandidoDescargarDialogOpen(false);
  };
  
  const exportarExpandidoDescargarDialog = () => (
    <OKDialog
      show={exportarExpandidoDescargarDialogOpen}
      title="Exportar Informe 2"
      body={<div>El Informe 2 se ha generado correctamente.<br />Por favor, presione OK para descargar el archivo.</div>}
      onConfirm={() => {
        descargarArchivoInformeDosExpandido();
        handleExportarExpandidoDescargarDialogClose();
      }}
      onClose={() => {
        descargarArchivoInformeDosExpandido();
        handleExportarExpandidoDescargarDialogClose();
      }}
    >
    </OKDialog>
  );

  const descargarArchivoInformeDosExpandido = () => {
    const excelBase64 = exportarExpandidoExcelBase64; // base64
    const byteCharacters = atob(excelBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Objetivos de ventas ${anioFiscal}.xlsx`);

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);

    onExportarExpandidoClear();
  };
  // - Exportar Expandido - Descargar dialog

  return (
    <Fragment>
      <Grid item xs={12} md={11} xl={9} container spacing={1} justifyContent="flex-start">
        <Grid container spacing={1} item xs={12} md={6} xl={5}>
          <Grid item xs={4}>
            {loading ? (
              <Spinner size="small" />
            ) : (
              <FiltroFechaSelector
                id="anioFiscal"
                label="Año Fiscal"
                value={anioFiscal}
                onDateChange={(value) => onAnioFiscalChange(value)}
                format="YYYY"
                views={["year"]}
                disabled={loadingInforme}
                minDate={anioFiscalDesde}
                maxDate={anioFiscalHasta}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <BudgetForecastSelector
              id="idArchivo"
              label="Budget / Forecast"
              value={idArchivo}
              items={budgetsForecasts}
              onChange={(event) => onIdArchivoChange(event.target.value)}
              isLoading={loadingBudgets}
              disabled={!anioFiscalSelected || loadingInforme}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              id="mesFiscal"
              label="Mes Fiscal"
              value={mesFiscal}
              items={meses}
              onChange={(event) => onMesFiscalChange(event.target.value)}
              disabled={!idArchivoSelected || loadingInforme}
              upperCase
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} md={6}>
          <Grid item xs={6} md={2}>
            <FormControlLabel
              label="Acumulado"
              className={classes.checkBoxLabel}
              labelPlacement="top"
              control={
                <Checkbox
                  checked={acumulado}
                  onChange={(event) => onAcumuladoChange(event.target.checked)}
                  name="acumulado"
                  color="primary"
                />
              }
              disabled={!mesSelected || loadingInforme}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {loadingDesde ? (
              <Spinner size="small" />
            ) : (
              <FiltroFechaSelector
                label="Fecha desde"
                disabled={loadingInforme}
                value={desde}
                onDateChange={(value) => onDesdeChange(value)}
                format="DD/MM/YYYY"
                minDate={minDate}
                maxDate={maxDate}
                required={true}
                helperText={desde ? null : "Este campo es requerido"}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            {loadingHasta ? (
              <Spinner size="small" />
            ) : (
              <FiltroFechaSelector
                label="Fecha hasta"
                disabled={loadingInforme}
                value={hasta}
                onDateChange={(value) => onHastaChange(value)}
                format="DD/MM/YYYY"
                minDate={minDate}
                maxDate={maxDate}
                required={true}
                helperText={hasta ? null : "Este campo es requerido"}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <ButtonPivotGrid
              tooltipTitle="Aplicar filtros sobre el informe 2"
              buttonSize="small"
              text="Aplicar"
              onClick={onAplicarFiltroClick}
              loading={false}
              icon={<PlayCircleFilledWhiteOutlinedIcon fontSize="small" />}
              disabled={habilitar()}
              backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
            />
            <ButtonPivotGrid
              tooltipTitle="Exportar Expandido"
              buttonSize="small"
              text={exportarExpandidoLoading ? "Exportando..." : "Exportar"}
              onClick={handleExportarExpandidoConfirmDialogOpen}
              loading={exportarExpandidoLoading}
              icon={<CloudDownloadOutlined fontSize="small" />}
              disabled={habilitar() || exportarExpandidoLoading}
              backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} justifyContent='space-between'>
        <Grid container spacing={1} item xs={12} md={6} xl={5}>
          <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
            {loading ? (
              <Spinner size="small" />
            ) : (
              <CustomSelector
                id="selectorObjetivo"
                label=""
                value={optionSelected}
                items={dataOptions}
                onChange={(event) => onOptionSelectedChange(event.target.value)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {
        filtrosInforme && (<Grid item xs={12}>
          <LeyendaFiltros
            desde={filtrosInforme.desde}
            hasta={filtrosInforme.hasta}
          />
        </Grid>)
      }
      {exportarExpandidoConfirmDialog()}
      {exportarExpandidoErrorDialog()}
      {exportarExpandidoDescargarDialog()}
    </Fragment >
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.aniosFiscales.loading,
    error: state.tenacta.aniosFiscales.error,
    aniosFiscales: state.tenacta.aniosFiscales.items,
    estaCargado: state.tenacta.aniosFiscales.estaCargado,
    loadingInforme: state.tenacta.informeDosData.loading,
    budgetsForecasts: state.tenacta.budgetsForecastsInformeDos.items,
    loadingBudgets: state.tenacta.budgetsForecastsInformeDos.loading,
    anioBudgets: state.tenacta.budgetsForecastsInformeDos.anioFiscal,
    filtros: state.tenacta.informeDosFiltros,
    filtrosInforme: state.tenacta.informeDosData.filtros,
    datos: state.tenacta.informeDosData.datos,
    exportarExpandidoLoading: state.tenacta.informeDosExportarExpandido.loading,
    exportarExpandidoEstaCargado: state.tenacta.informeDosExportarExpandido.estaCargado,
    exportarExpandidoError: state.tenacta.informeDosExportarExpandido.error,
    exportarExpandidoExcelBase64: state.tenacta.informeDosExportarExpandido.excelBase64
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(actions.loadAniosFiscales()),
    onUpdateFiltros: (anio, idArchivo, mes, acumulado, desde, hasta) =>
      dispatch(actions.updateInformeDosFiltros(anio, idArchivo, mes, acumulado, desde, hasta)),
    onLoadBudgets: (anioFiscal) => dispatch(actions.loadInformeDosBudgetsForecasts(anioFiscal)),
    onExportarExpandidoClear: () => dispatch(actions.exportarInformeDosExpandidoClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroInformeDos);