import React, { Fragment, useEffect, useState } from 'react';
import classes from './InteranualVentas.css';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import Dialog from '../../../UI/Dialog/Dialog';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TablaInteranualToolbar from '../../../UI/Interanual/ToolbarInteranual/ToolbarInteranual';
import LinesInteranualVentas from './LinesInteranualVentas';
import TablaInteranualContainer from '../../../UI/Interanual/TablaInteranualContainer/TablaInteranualContainer';
import { fechaActualFunc, fechaMinima } from '../../../../shared/fechas';
import Spinner from '../../../UI/Spinner/Spinner';
import PeriodoSelector from '../../../UI/Interanual/PeriodoSelector/PeriodoSelector';
import moment from 'moment';

export const InteranualVentasConFiltro = (props) => {
  const inPhone = window.screen.width <= 750;
  const { periodosVentas, evolucionVentas, agregarPeriodoVenta, eliminarPeriodoVenta } = props;
  const [openAgregar, setOpenAgregar] = useState(false);
  const [periodoSeleccionado, setPeriodoSeleccionado] = useState();
  const [fechaFija] = useState(fechaActualFunc());
  const [fechaPlaceholder] = useState(fechaMinima());
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [periodos, setPeriodos] = useState([]);
  const [agregandoPeriodos, setAgregandoPeriodos] = useState(false);

  useEffect(() => {
    if (periodosVentas.length === 0) {
      agregarPeriodoVenta(fechaFija);
      if(!inPhone)
      {
        agregarPeriodoVenta(fechaPlaceholder);
        agregarPeriodoVenta(fechaPlaceholder);
      }
    }
  }, [periodosVentas, fechaFija, fechaPlaceholder, inPhone, agregarPeriodoVenta]);

  useEffect(() => {
    if (periodos.length === 0) {
      setPeriodos(periodosVentas);
    }
  }, [periodosVentas, periodos]);

  const onAgregarPeriodoVenta = () => {
    let existPeriodo =
      periodosVentas.find((per) => per.getFullYear() === periodoSeleccionado._d.getFullYear()) !== undefined;

    if (existPeriodo) {
      setErrorMessage('Ya se encuentra agregado el período seleccionado.');
    } else {
      const periodoPlaceholder = periodosVentas.find((per) => moment(per).isSame(moment(fechaPlaceholder)))
      if(periodoPlaceholder)
        eliminarPeriodoVenta(periodoPlaceholder)

      agregarPeriodoVenta(new Date(periodoSeleccionado._d.getFullYear(), fechaFija.getMonth(), 1));
      setOpenAgregar(false);
    }
  };

  const onAgregarPeriodo = () => {
    setErrorMessage(undefined);
    if (periodoSeleccionado) onAgregarPeriodoVenta();
  };

  const onEliminarPeriodo = (periodo) => {
    eliminarPeriodoVenta(periodo);
  };

  const onCancelarPeriodo = () => {
    setErrorMessage(undefined);
    setPeriodoSeleccionado(null);
    setOpenAgregar(false);
  };

  const getDatasPorPeriodoVentas = () => {
    let datasPorPeriodos = [];

    evolucionVentas.forEach((element) => {
      datasPorPeriodos.push({
        anio: element.anio,
        datasPorAnio: element.ventasDataTable
      });
    });

    return datasPorPeriodos;
  };

  const getPeriodos = () => {
    return periodosVentas;
  };

  const getTitle = () => {
    return (
      <div style={{ width: '98%', textAlign: 'right' }}>
        <IconButton aria-label="close" onClick={() => setOpenAgregar(false)}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const getBody = () => {
    return (
      <PeriodoSelector
        periodoSeleccionado={periodoSeleccionado}
        setPeriodoSeleccionado={setPeriodoSeleccionado}
        onCancelarPeriodo={onCancelarPeriodo}
        onAgregarPeriodo={onAgregarPeriodo}
        errorMessage={errorMessage}
      />
    );
  };

  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          className={classes.tablasInteranuales}
          style={{ marginBottom: periodos.length > 3 || inPhone ? '20px' : '10px' }}
        >
          <Grid item xs={12}>
            <TablaInteranualToolbar
              periodos={getPeriodos()}
              onAgregarClick={() => {
                setPeriodoSeleccionado();
                setErrorMessage(undefined);
                setOpenAgregar(true);
              }}
              onShowFiltersModal={() => props.onShowFiltersModal('Ventas desglosadas por mes', 'venta')}
              datasPorPeriodos={getDatasPorPeriodoVentas()}
              type={'Venta'}
              showFilter={true}
              onAplicarFiltroStart={() => setAgregandoPeriodos(true)}
              onAplicarFiltroEnd={() => setAgregandoPeriodos(false)}
            />
          </Grid>
          {periodos.length === 0 || agregandoPeriodos ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Spinner />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TablaInteranualContainer
                ejecucionDePagina={props.ejecucionDePagina}
                periodos={getPeriodos()}
                type={'Venta'}
                onEliminarPeriodo={onEliminarPeriodo}
                withoutDecimals={props.withoutDecimals}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.linesInteranuales}>
            <LinesInteranualVentas periodos={getPeriodos()} type={'Venta'} withoutDecimals={props.withoutDecimals} />
          </Grid>
        </Grid>
        
      </Grid>
      {openAgregar && (
        <Dialog
          show={openAgregar}
          title={getTitle()}
          onClose={() => {
            setErrorMessage(undefined);
            setOpenAgregar(false);
          }}
          body={getBody()}
          fullWidth={true}
          maxWidth={'xs'}
        ></Dialog>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    periodosVentas: state.ventas.evolucionVentasMensualInteranual.periodos,
    evolucionVentas: state.ventas.evolucionVentasMensualInteranual.items
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (title, filterType) => dispatch(actions.showListFiltersModal(true, title, [filterType])),
    agregarPeriodoVenta: (periodo) => dispatch(actions.agregarEvolucionDeVentasMensualInteranual(periodo)),
    eliminarPeriodoVenta: (periodo) => dispatch(actions.clearEvolucionDeVentasMensualInteranual(periodo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InteranualVentasConFiltro);
