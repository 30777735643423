import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  loadGruposGastos: {
    loading: false,
    error: null,
    estaCargado: false,
    items: []
  },
  executeCreateGruposGastos: false,
  executeUpdateGruposGastos: false,
  executeDeleteGruposGastos: false,
  gruposGastosSelected: 'select',
};

const executeLoadGruposGastos = (state, loading, estaCargado, items, error) => {
  return updateObject(state, {
    loadGruposGastos: updateObject(state.loadGruposGastos, {
      loading,
      error,
      estaCargado,
      items
    })
  });
}

const executeCreateGruposGastos = (state, loading) => {
  return updateObject(state, {
    executeCreateGruposGastos: loading
  });
};

const executeUpdateGruposGastos = (state, loading) => {
  return updateObject(state, {
    executeUpdateGruposGastos: loading
  });
};

const executeDeleteGruposGastos = (state, loading) => {
  return updateObject(state, {
    executeDeleteGruposGastos: loading
  });
};

const executeUpdateGruposGastosSelected = (state, gruposGastosId) => {
  return updateObject(state, {
    gruposGastosSelected: gruposGastosId
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_LOAD_GRUPO_GASTOS:
      return executeLoadGruposGastos(state, action.loading, action.estaCargado, action.items, action.error);
    case actionTypes.EXECUTE_CREATE_GRUPO_GASTOS:
      return executeCreateGruposGastos(state, action.loading);
    case actionTypes.EXECUTE_UPDATE_GRUPO_GASTOS:
      return executeUpdateGruposGastos(state, action.loading);
    case actionTypes.EXECUTE_DELETE_GRUPO_GASTOS:
      return executeDeleteGruposGastos(state, action.loading);
    case actionTypes.EXECUTE_UPDATE_GRUPO_GASTOS_SELECTED:
      return executeUpdateGruposGastosSelected(state, action.gruposGastosId);
    default:
      return state;
  }
};

export default reducer;
