import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  empresas: {
    loading: false,
    empresas: [],
    error: null,
    estaCargado: false
  },
  ventasMensualesPorEmpresa: {
    loading: false,
    ventas: [],
    totalVentas: 0,
    error: null,
    estaCargado: false
  },
  ventasDiariasPorEmpresa: {
    loading: false,
    ventas: [],
    totalVentas: 0,
    error: null,
    estaCargado: false
  },
  cobranzasMensualesPorEmpresa: {
    loading: true,
    cobranzas: [],
    totalCobranzas: 0,
    error: null,
    estaCargado: false
  },
  saldoDeudoresPorEmpresa: {
    loading: true,
    saldos: [],
    totalSaldos: 0,
    error: null,
    estaCargado: false
  },
  saldoAcreedoresPorEmpresa: {
    loading: true,
    saldos: [],
    totalSaldos: 0,
    error: null,
    estaCargado: false
  },
  chequesEnCarteraPorEmpresa: {
    loading: false,
    cheques: [],
    totalCheques: 0,
    error: null,
    estaCargado: false
  },
  chequesPropiosPorEmpresa: {
    loading: false,
    cheques: [],
    totalCheques: 0,
    error: null,
    estaCargado: false
  },
  gastosDelMesPorEmpresa: {
    loading: false,
    gastos: [],
    totalGastos: 0,
    error: null,
    estaCargado: false
  },
  pagosDelMesPorEmpresa: {
    loading: false,
    pagos: [],
    totalPagos: 0,
    error: null,
    estaCargado: false
  },
  ivaFiscalPorEmpresa: {
    loading: false,
    ivas: [],
    totalIvas: 0,
    error: null,
    estaCargado: false
  },
  rentabilidadPorEmpresa: {
    loading: false,
    rentabilidades: [],
    totalRentabilidad: 0,
    error: null,
    estaCargado: false
  },
  loadingCambiarEmpresa: false
};

////#region execute Cambiar Empresa
const cambiarEmpresa = (state, loading) => {
  return updateObject(state, {
    loadingCambiarEmpresa: loading
  });
};
////#endregion

//#region loadEmpresas
const loadEmpresasStart = (state) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: true,
      empresas: [],
      error: null,
      estaCargado: false
    })
  });
};

const loadEmpresasSuccess = (state, empresas) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: false,
      empresas,
      estaCargado: true
    })
  });
};

const loadEmpresasFail = (state, error) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadVentasMensualesPorEmpresa
const loadVentasMensualesPorEmpresaStart = (state) => {
  return updateObject(state, {
    ventasMensualesPorEmpresa: updateObject(state.ventasMensualesPorEmpresa, {
      loading: true,
      ventas: [],
      totalVentas: 0,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasMensualesPorEmpresaSuccess = (state, ventas, totalVentas) => {
  return updateObject(state, {
    ventasMensualesPorEmpresa: updateObject(state.ventasMensualesPorEmpresa, {
      loading: false,
      ventas,
      totalVentas,
      estaCargado: true
    })
  });
};

const loadVentasMensualesPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    ventasMensualesPorEmpresa: updateObject(state.ventasMensualesPorEmpresa, {
      loading: false,
      ventas: [],
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadVentasDiariasPorEmpresa
const loadVentasDiariasPorEmpresaStart = (state) => {
  return updateObject(state, {
    ventasDiariasPorEmpresa: updateObject(state.ventasDiariasPorEmpresa, {
      loading: true,
      ventas: [],
      totalVentas: 0,
      error: null
    })
  });
};

const loadVentasDiariasPorEmpresaSuccess = (state, ventas, totalVentas) => {
  return updateObject(state, {
    ventasDiariasPorEmpresa: updateObject(state.ventasDiariasPorEmpresa, {
      loading: false,
      ventas,
      totalVentas,
      estaCargado: true
    })
  });
};

const loadVentasDiariasPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    ventasDiariasPorEmpresa: updateObject(state.ventasDiariasPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadCobranzasDelMesPorEmpresa
const loadCobranzasDelMesPorEmpresaStart = (state) => {
  return updateObject(state, {
    cobranzasMensualesPorEmpresa: updateObject(state.cobranzasMensualesPorEmpresa, {
      loading: true,
      cobranzas: [],
      totalCobranzas: 0,
      error: null
    })
  });
};

const loadCobranzasDelMesPorEmpresaSuccess = (state, cobranzas, totalCobranzas) => {
  return updateObject(state, {
    cobranzasMensualesPorEmpresa: updateObject(state.cobranzasMensualesPorEmpresa, {
      loading: false,
      cobranzas,
      totalCobranzas,
      estaCargado: true
    })
  });
};

const loadCobranzasDelMesPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    cobranzasMensualesPorEmpresa: updateObject(state.cobranzasMensualesPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadSaldoDeudoresPorEmpresa
const loadSaldoDeudoresPorEmpresaStart = (state) => {
  return updateObject(state, {
    saldoDeudoresPorEmpresa: updateObject(state.saldoDeudoresPorEmpresa, {
      loading: true,
      saldos: [],
      totalSaldos: 0,
      error: null
    })
  });
};

const loadSaldoDeudoresPorEmpresaSuccess = (state, saldos, totalSaldos) => {
  return updateObject(state, {
    saldoDeudoresPorEmpresa: updateObject(state.saldoDeudoresPorEmpresa, {
      loading: false,
      saldos,
      totalSaldos,
      estaCargado: true
    })
  });
};

const loadSaldoDeudoresPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    saldoDeudoresPorEmpresa: updateObject(state.saldoDeudoresPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadSaldoAcreedoresPorEmpresa
const loadSaldoAcreedoresPorEmpresaStart = (state) => {
  return updateObject(state, {
    saldoAcreedoresPorEmpresa: updateObject(state.saldoAcreedoresPorEmpresa, {
      loading: true,
      saldos: [],
      totalSaldos: 0,
      error: null
    })
  });
};

const loadSaldoAcreedoresPorEmpresaSuccess = (state, saldos, totalSaldos) => {
  return updateObject(state, {
    saldoAcreedoresPorEmpresa: updateObject(state.saldoAcreedoresPorEmpresa, {
      loading: false,
      saldos,
      totalSaldos,
      estaCargado: true
    })
  });
};

const loadSaldoAcreedoresPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    saldoAcreedoresPorEmpresa: updateObject(state.saldoAcreedoresPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadChequesEnCarteraPorEmpresa
const loadChequesEnCarteraPorEmpresaStart = (state) => {
  return updateObject(state, {
    chequesEnCarteraPorEmpresa: updateObject(state.chequesEnCarteraPorEmpresa, {
      loading: true,
      cheques: [],
      totalCheques: 0,
      error: null
    })
  });
};

const loadChequesEnCarteraPorEmpresaSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    chequesEnCarteraPorEmpresa: updateObject(state.chequesEnCarteraPorEmpresa, {
      loading: false,
      cheques,
      totalCheques,
      estaCargado: true
    })
  });
};

const loadChequesEnCarteraPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    chequesEnCarteraPorEmpresa: updateObject(state.chequesEnCarteraPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadChequesPropiosPorEmpresa
const loadChequesPropiosPorEmpresaStart = (state) => {
  return updateObject(state, {
    chequesPropiosPorEmpresa: updateObject(state.chequesPropiosPorEmpresa, {
      loading: true,
      cheques: [],
      totalCheques: 0,
      error: null
    })
  });
};

const loadChequesPropiosPorEmpresaSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    chequesPropiosPorEmpresa: updateObject(state.chequesPropiosPorEmpresa, {
      loading: false,
      cheques,
      totalCheques,
      estaCargado: true
    })
  });
};

const loadChequesPropiosPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    chequesPropiosPorEmpresa: updateObject(state.chequesPropiosPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadGastosDelMesPorEmpresa
const loadGastosDelMesPorEmpresaStart = (state) => {
  return updateObject(state, {
    gastosDelMesPorEmpresa: updateObject(state.gastosDelMesPorEmpresa, {
      loading: true,
      gastos: [],
      totalGastos: 0,
      error: null
    })
  });
};

const loadGastosDelMesPorEmpresaSuccess = (state, gastos, totalGastos) => {
  return updateObject(state, {
    gastosDelMesPorEmpresa: updateObject(state.gastosDelMesPorEmpresa, {
      loading: false,
      gastos,
      totalGastos,
      estaCargado: true
    })
  });
};

const loadGastosDelMesPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    gastosDelMesPorEmpresa: updateObject(state.gastosDelMesPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadPagosDelMesPorEmpresa
const loadPagosDelMesPorEmpresaStart = (state) => {
  return updateObject(state, {
    pagosDelMesPorEmpresa: updateObject(state.pagosDelMesPorEmpresa, {
      loading: true,
      pagos: [],
      totalPagos: 0,
      error: null
    })
  });
};

const loadPagosDelMesPorEmpresaSuccess = (state, pagos, totalPagos) => {
  return updateObject(state, {
    pagosDelMesPorEmpresa: updateObject(state.pagosDelMesPorEmpresa, {
      loading: false,
      pagos,
      totalPagos,
      estaCargado: true
    })
  });
};

const loadPagosDelMesPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    pagosDelMesPorEmpresa: updateObject(state.pagosDelMesPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadIvaFiscalPorEmpresa
const loadIvaFiscalPorEmpresaStart = (state) => {
  return updateObject(state, {
    ivaFiscalPorEmpresa: updateObject(state.ivaFiscalPorEmpresa, {
      loading: true,
      ivas: [],
      totalIvas: 0,
      error: null
    })
  });
};

const loadIvaFiscalPorEmpresaSuccess = (state, ivas, totalIvas) => {
  return updateObject(state, {
    ivaFiscalPorEmpresa: updateObject(state.ivaFiscalPorEmpresa, {
      loading: false,
      ivas,
      totalIvas,
      estaCargado: true
    })
  });
};

const loadIvaFiscalPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    ivaFiscalPorEmpresa: updateObject(state.ivaFiscalPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadRentabilidadPorEmpresa
const loadRentabilidadPorEmpresaStart = (state) => {
  return updateObject(state, {
    rentabilidadPorEmpresa: updateObject(state.rentabilidadPorEmpresa, {
      loading: true
    })
  });
};

const loadRentabilidadPorEmpresaSuccess = (state, rentabilidades, totalRentabilidad) => {
  return updateObject(state, {
    rentabilidadPorEmpresa: updateObject(state.rentabilidadPorEmpresa, {
      rentabilidades,
      totalRentabilidad,
      loading: false,
      estaCargado: true
    })
  });
};

const loadRentabilidadPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    rentabilidadPorEmpresa: updateObject(state.rentabilidadPorEmpresa, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_EMPRESAS_START:
      return loadEmpresasStart(state);
    case actionTypes.LOAD_EMPRESAS_SUCCESS:
      return loadEmpresasSuccess(state, action.empresas);
    case actionTypes.LOAD_EMPRESAS_FAIL:
      return loadEmpresasFail(state, action.error);

    case actionTypes.LOAD_VENTAS_MENSUALES_MULTIEMPRESA_START:
      return loadVentasMensualesPorEmpresaStart(state);
    case actionTypes.LOAD_VENTAS_MENSUALES_MULTIEMPRESA_SUCCESS:
      return loadVentasMensualesPorEmpresaSuccess(state, action.ventasPorEmpresa, action.totalVentas);
    case actionTypes.LOAD_VENTAS_MENSUALES_MULTIEMPRESA_FAIL:
      return loadVentasMensualesPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_VENTAS_DIARIAS_MULTIEMPRESA_START:
      return loadVentasDiariasPorEmpresaStart(state);
    case actionTypes.LOAD_VENTAS_DIARIAS_MULTIEMPRESA_SUCCESS:
      return loadVentasDiariasPorEmpresaSuccess(state, action.ventasPorEmpresa, action.totalVentas);
    case actionTypes.LOAD_VENTAS_DIARIAS_MULTIEMPRESA_FAIL:
      return loadVentasDiariasPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_START:
      return loadCobranzasDelMesPorEmpresaStart(state);
    case actionTypes.LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_SUCCESS:
      return loadCobranzasDelMesPorEmpresaSuccess(state, action.cobranzasPorEmpresa, action.totalCobranzas);
    case actionTypes.LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_FAIL:
      return loadCobranzasDelMesPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_SALDO_DEUDORES_MULTIEMPRESA_START:
      return loadSaldoDeudoresPorEmpresaStart(state);
    case actionTypes.LOAD_SALDO_DEUDORES_MULTIEMPRESA_SUCCESS:
      return loadSaldoDeudoresPorEmpresaSuccess(state, action.saldoPorEmpresa, action.totalSaldo);
    case actionTypes.LOAD_SALDO_DEUDORES_MULTIEMPRESA_FAIL:
      return loadSaldoDeudoresPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_SALDO_ACREEDORES_MULTIEMPRESA_START:
      return loadSaldoAcreedoresPorEmpresaStart(state);
    case actionTypes.LOAD_SALDO_ACREEDORES_MULTIEMPRESA_SUCCESS:
      return loadSaldoAcreedoresPorEmpresaSuccess(state, action.saldoPorEmpresa, action.totalSaldo);
    case actionTypes.LOAD_SALDO_ACREEDORES_MULTIEMPRESA_FAIL:
      return loadSaldoAcreedoresPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_START:
      return loadChequesEnCarteraPorEmpresaStart(state);
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_SUCCESS:
      return loadChequesEnCarteraPorEmpresaSuccess(state, action.chequesPorEmpresa, action.totalCheques);
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_FAIL:
      return loadChequesEnCarteraPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_START:
      return loadChequesPropiosPorEmpresaStart(state);
    case actionTypes.LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_SUCCESS:
      return loadChequesPropiosPorEmpresaSuccess(state, action.chequesPorEmpresa, action.totalCheques);
    case actionTypes.LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_FAIL:
      return loadChequesPropiosPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_GASTOS_DEL_MES_MULTIEMPRESA_START:
      return loadGastosDelMesPorEmpresaStart(state);
    case actionTypes.LOAD_GASTOS_DEL_MES_MULTIEMPRESA_SUCCESS:
      return loadGastosDelMesPorEmpresaSuccess(state, action.gastosPorEmpresa, action.totalGastos);
    case actionTypes.LOAD_GASTOS_DEL_MES_MULTIEMPRESA_FAIL:
      return loadGastosDelMesPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_PAGOS_DEL_MES_MULTIEMPRESA_START:
      return loadPagosDelMesPorEmpresaStart(state);
    case actionTypes.LOAD_PAGOS_DEL_MES_MULTIEMPRESA_SUCCESS:
      return loadPagosDelMesPorEmpresaSuccess(state, action.pagosPorEmpresa, action.totalPagos);
    case actionTypes.LOAD_PAGOS_DEL_MES_MULTIEMPRESA_FAIL:
      return loadPagosDelMesPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_IVA_FISCAL_MULTIEMPRESA_START:
      return loadIvaFiscalPorEmpresaStart(state);
    case actionTypes.LOAD_IVA_FISCAL_MULTIEMPRESA_SUCCESS:
      return loadIvaFiscalPorEmpresaSuccess(state, action.ivaFiscalPorEmpresa, action.totalIvaFiscal);
    case actionTypes.LOAD_IVA_FISCAL_MULTIEMPRESA_FAIL:
      return loadIvaFiscalPorEmpresaFail(state, action.error);

    case actionTypes.LOAD_RENTABILIDAD_MULTIEMPRESA_START:
      return loadRentabilidadPorEmpresaStart(state);
    case actionTypes.LOAD_RENTABILIDAD_MULTIEMPRESA_SUCCESS:
      return loadRentabilidadPorEmpresaSuccess(state, action.rentabilidades, action.totalRentabilidad);
    case actionTypes.LOAD_RENTABILIDAD_MULTIEMPRESA_FAIL:
      return loadRentabilidadPorEmpresaFail(state, action.error);

    case actionTypes.EXECUTE_CAMBIAR_EMPRESA:
      return cambiarEmpresa(state, action.loading);

    default:
      return state;
  }
};

export default reducer;
