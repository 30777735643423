import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import ReactExport from 'react-data-export';
import { getLineaExpandedDataSet } from '../InformeTresHelpers';
import ButtonPivotGrid from '../../../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid';
import { CloudDownloadOutlined } from "@material-ui/icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablasInformeTresExpandidoToExcel = (props) => {
  const { data, name, filename, disabled, backgroundImage } = props;
  const [loading, setLoading] = useState(false);
  const [lineas] = useState(['ASPEN', 'IMETEC', 'ORTOPEDIA', 'TENACTA']);

  const getDataSet = () => {
    let dataSet = [];

    lineas.forEach((linea, index) => {
      const lineaDataSet = getLineaExpandedDataSet(linea, data);

      dataSet.push({
        ySteps: index !== 0 ? 3 : 0,
        columns: lineaDataSet.columns,
        data: lineaDataSet.data
      })
    });

    return dataSet;
  }

  const onExportarExpandidoClick = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }

  const renderButton = (tooltip) => {
    return (
      <ButtonPivotGrid
        tooltipTitle={tooltip}
        buttonSize="small"
        text={loading ? "Exportando..." : "Exportar"}
        onClick={onExportarExpandidoClick}
        loading={loading}
        icon={<CloudDownloadOutlined fontSize="small" />}
        disabled={disabled}
        backgroundImage={backgroundImage}
      />
    )
  }

  return disabled ? (
    <Fragment>
      {renderButton("Aplique los cambios en el filtro, antes de exportar el informe.")}
    </Fragment>
  ) : (
    <ExcelFile
      filename={filename}
      element={renderButton("Exportar informe 3 expandido.")}
    >
      <ExcelSheet dataSet={getDataSet()} name={name} />
    </ExcelFile>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.tenacta.informeTresData.datos
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablasInformeTresExpandidoToExcel);