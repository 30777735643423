import React from 'react';
import { connect } from 'react-redux';
import PieChart from '../../../Charts/PieChart';
import * as actions from '../../../../store/actions/index';

const PieChequesEnCarteraPorEmpresa = (props) => {

  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <PieChart
      title={props.title}
      subTitle={props.subtitle}
      series={props.cheques.map((cheque) => cheque.total)}
      labels={props.cheques.map((cheque) => cheque.nombreEmpresa)}
      ids={props.cheques.map((cheque) => cheque.id)}
      loading={props.loading}
      imgSinDatos={props.totalCheques <= 0}
      chartType={'pie'}
      height={props.height}
      disabled={props.estaOculto}
      width={props.width}
      onPorcionClick={handlePorcionClick}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    cheques: state.multiempresa.chequesEnCarteraPorEmpresa.cheques,
    loading: state.multiempresa.chequesEnCarteraPorEmpresa.loading,
    totalCheques: state.multiempresa.chequesEnCarteraPorEmpresa.totalCheques,
    estaCargado: state.multiempresa.chequesEnCarteraPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCarteraMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadChequesEnCarteraPorEmpresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieChequesEnCarteraPorEmpresa);
