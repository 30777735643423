import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './TablaIndicadoresMultiempresa.css';
import { storageFechaFija, storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaIndicadoresMultiempresaToExcel = (props) => {
  const { empresas, datas } = props;

  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' }
    };
  };

  const formatData = () => {
    var formatData = [];

    datas.map((data, index) => {
      formatData.push([
        {
          value: data.indicador,
          style: { font: { sz: '10', bold: true } }
        }
      ]);
      return empresas.map((empresa) =>
        formatData[index].push({
          value: `${storageMonedaSigno()} ${formatNumber(data[empresa.nombre])}`,
          style: cellsStyleVerifNegative(data[empresa.nombre])
        })
      );
    });

    return formatData;
  };

  const formatDataSet = () => {
    const formatedDataSet = [
      {
        columns: [
          {
            title: `Indicador`,
            width: { wpx: 160 },
            style: headersStyle
          }
        ],
        data: formatData()
      }
    ];

    empresas.map((empresa) => {
      return formatedDataSet[0].columns.push({
        title: empresa.nombre,
        width: { wpx: 160 },
        style: headersStyle
      });
    });

    return formatedDataSet;
  };

  return (
    <ExcelFile
      filename={`Resultados multiempresa del ${storageFechaFija()}`}
      element={
        <Tooltip classes={{ popper: classes.TooltipPopper }} title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'33px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={formatDataSet()} name={`Resultados ${storageFechaFija()}`} />
    </ExcelFile>
  );
};

export default TablaIndicadoresMultiempresaToExcel;
