import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { formatNumberWithoutDecimals, formatNumber } from '../../../../shared/formatNumber';
import LineChart from '../../../Charts/CollapsableLineChart';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import classes from './LineEvolucionVentasPorPeriodo.css';
import CustomSwitch from '../../../UI/CustomSwitch/CustomSwitch';
import FilterListIcon from '@material-ui/icons/FilterList';

import LineChartFiltros from './LineChartFiltros';
import { compareByAsc } from '../../../../shared/sorting';

const LineChartEvolucionVentas = (props) => {
  const { dataSelectedId, dataOptions, dataSelectOptions, dataSelectedPorPeriodosId, onShowFiltersModal } = props;
  const { inSelectRange, onSelectRangeChange, wandKey, clearData } = props;

  const [ventasSeleccionados, setVentasSeleccionados] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [loading, setLoading] = useState(false);
  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  //#region CHART

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const ventasActual = ventasSeleccionados;

    if (ventasActual[seriesIndex].data.length !== 0) {
      ventasActual[seriesIndex].data = [];
    } else {
      const ventasKey = dataOptions[dataSelectedPorPeriodosId].ventasKey;

      let data = showMonto
        ? props[ventasKey].ventas[seriesIndex].montos.sort((a, b) => compareByAsc(a, b, "anio")).map((item) => item.monto)
        : props[ventasKey].ventas[seriesIndex].unidades.sort((a, b) => compareByAsc(a, b, "anio")).map((item) => item.unidadesVendidas);

      ventasActual[seriesIndex].data = data;
    }

    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${ventasSeleccionados
        .filter((venta) => venta.data.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((venta) => {
          let text = showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(venta.data[dataPointIndex])}` : `${formatNumberWithoutDecimals(venta.data[dataPointIndex])}`;
          return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(venta.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${venta.name}: ${text} 
                      </span>
                    </div>
                `;
        })
        .join('')}
              </div>
      </div>
      `;
  };

  const onMarkerClick = (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
    const ventasKey = dataOptions[dataSelectedPorPeriodosId].ventasKey;
    const loadKey = dataOptions[dataSelectedPorPeriodosId].loadKey;
    const desde = props[ventasKey].fechas[dataPointIndex].desde;
    const hasta = props[ventasKey].fechas[dataPointIndex].hasta;
    const anio = props[ventasKey].fechas[dataPointIndex].anio;
    const anioHasta = props[ventasKey].fechas[dataPointIndex].anioHasta;
    const chartLoad = props[loadKey];

    props.onMarkerClick("evolucionVentas", chartLoad, desde, hasta, anio, anioHasta, showMonto, dataSelectedPorPeriodosId);
  };

  const formatMesesToStrings = (fechasProp) => {
    const formatFechas = [];
    const orderedDates = fechasProp.sort((a, b) => a.anio - b.anio);
    orderedDates.map((fecha) => {
      let fechaDesde = fecha.desde < 10 ? `0${fecha.desde}` : `${fecha.desde}`;
      let fechaHasta = fecha.hasta < 10 ? `0${fecha.hasta}` : `${fecha.hasta}`;
      let label = fecha.desde === fecha.hasta
        ? fecha.anio === fecha.anioHasta ? `${fechaDesde}/${fecha.anio}` : `${fechaDesde}/${fecha.anio}-${fechaHasta}/${fecha.anioHasta}`
        : fecha.anio === fecha.anioHasta ? `${fechaDesde}-${fechaHasta}/${fecha.anio}` : `${fechaDesde}/${fecha.anio}-${fechaHasta}/${fecha.anioHasta}`;
      return formatFechas.push(label);
    });

    setFechas(formatFechas);
  };

  const series = (ventas) => {
    let formatVentas = [];
    ventas.map((venta) => {
      return formatVentas.push({
        name: venta.nombre,
        data: []
      });
    });
    return setVentasSeleccionados(formatVentas);
  };

  const middleControls = () => {
    return (
      <Tooltip
        title={
          verifyEmptyVentasSeleccioandos.length > 0
            ? !inSelectRange
              ? 'Al activar esta función, podrá seleccionar 2 períodos para compararlos.'
              : 'Haga click en dos períodos diferentes para poder compararlos. (Seleccione 2 puntos diferentes en el gáfico  y se mostrara la comparación en el gráfico de más abajo)'
            : 'Seleccione por lo menos una categoría para activar la selección de períodos'
        }
      >
        <Typography style={{ marginBottom: '1px', marginTop: '1%', marginRight: '1%' }}>
          {!inSelectRange ? `Active la selección de períodos → ` : `Seleccione 2 períodos para comparar `}
          {
            <IconButton
              disabled={verifyEmptyVentasSeleccioandos.length === 0}
              style={{
                backgroundColor: inSelectRange && verifyEmptyVentasSeleccioandos.length !== 0 && 'rgb(255 208 159)'
              }}
              size="small"
              onClick={(e) => handleChangeSelectRange(e)}
            >
              <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            </IconButton>
          }
        </Typography>
      </Tooltip>
    );
  };

  const chartTitle = () => {
    const description = dataOptions[dataSelectedPorPeriodosId]
      && dataOptions[dataSelectedPorPeriodosId].description
      && dataOptions[dataSelectedPorPeriodosId].description.toLowerCase();

    return (
      <Grid item xs={12} lg={5} className={classes.Title}>
        <Typography className={classes.TitleText}>
          Evolución de ventas totales por período por {description}
        </Typography>
      </Grid>
    )
  }

  const titles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} container direction='row' justifyContent='space-around' alignContent='center' spacing={1} className={classes.Header}>
          {chartTitle()}
          <Grid item xs={11} lg={4} className={classes.SpecialTool} style={{ textAlign: 'center' }}>
            {middleControls()}
          </Grid>

          <Grid item xs={1} lg={3} className={classes.Filters}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => onShowFiltersModal()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Title}>
          <Typography className={classes.SubtitleText} >
            (Haga clic sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información)
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', marginLeft: '0.5%' }}>
          <CustomSwitch
            value={showMonto}
            onChange={() => onShowMontoChange(!showMonto)}
            firstLabel='Unidades'
            secondLabel='Importe Neto'
          />
        </Grid>
      </Grid>
    )
  }

  const collapseTitle = () => {
    return (
      <Fragment>
        {chartTitle()}
      </Fragment>
    )
  }

  const verifyEmptyVentasSeleccioandos = ventasSeleccionados.filter((ventSelect) => ventSelect.data.length !== 0);

  const handleChangeSelectRange = (e) => {
    e.preventDefault();
    onSelectRangeChange(wandKey)
  };

  //#endregion

  //#region CAMBIAR TIPO DE VISUALIZACION
  const [showMonto, setShowMonto] = useState(true);

  const onShowMontoChange = (value) => {
    setShowMonto(value);

    const ventasActual = ventasSeleccionados;
    const ventasKey = dataOptions[dataSelectedPorPeriodosId].ventasKey;

    ventasActual.forEach((venta, index) => {
      if (venta.data && venta.data.length > 0) {
        let data = value
          ? props[ventasKey].ventas[index].montos.map((item) => item.monto)
          : props[ventasKey].ventas[index].unidades.map((item) => item.unidadesVendidas);

        venta.data = data;
      }
    });

    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  }

  //#endregion

  //#region FILTROS

  const aplicarFiltrosSuccess = (data, fechas) => {
    series(data);
    formatMesesToStrings(fechas);
    setClear(true);
    setLoading(false);
  }

  const aplicarFiltrosFail = () => {
    setClear(true);
    setLoading(false);
  }

  const aplicarFiltros = (selectionId, desdeDate, hastaDate, aniosSelected, userSelected) => {
    setLoading(true);
    setVentasSeleccionados([]);
    setFechas([]);
    const loadEvolucionKey = props[dataOptions[selectionId].loadEvolucionKey];
    loadEvolucionKey(desdeDate, hastaDate, aniosSelected, aplicarFiltrosSuccess, aplicarFiltrosFail, userSelected);
  }

  const filtros = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={10} lg={8} xl={6} style={{ paddingLeft: '1%' }}>
          <LineChartFiltros
            dataOptions={dataOptions}
            dataSelectOptions={dataSelectOptions}
            titleProps={props}
            limitDates={false}
            filtros={props.filtros}
            updateFiltros={props.updateFiltros}
            onAplicarFiltro={aplicarFiltros}
            filtrosDefault={{
              ...props.filtrosInteranual,
              dataSelectedId,
              dataSelectedPorPeriodosId
            }}
            interanual
            actualizarData={props.actualizarData}
            updateData={props.updateData}
            multipleAnios
          />
        </Grid>
      </Grid>
    )
  }

  //#endregion

  //#region CLEAR DATA
  const [clear, setClear] = useState(true);

  useEffect(() => {
    if (clear && clearData) {
      setVentasSeleccionados([]);
      setFechas([]);
      setSeriesBandera(false);
      setClear(false);
    }
  }, [clear, clearData]);

  //#endregion

  return (
    <LineChart
      title={titles()}
      collapseTitle={collapseTitle()}
      series={ventasSeleccionados}
      subTitle={filtros()}
      seriesBandera={seriesBandera}
      loading={loading}
      chartTextAlign={'left'}
      eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
      eventsMarkerClick={(event, chartContext, { seriesIndex, dataPointIndex, config }) =>
        inSelectRange && onMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config })
      }
      middleItems={middleControls()}
      legendOnItemClick={false}
      chartHeight={'450px'}
      width={'100%'}
      chartWidth={'99%'}
      chartDropShadow={{
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }}
      toolbarShow={true}
      tooltipEnabled
      customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
        armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
      }
      zoomType={'xy'}
      dataLabelsEnabled={window.screen.width > 500}
      formatDataLabels
      strokeCurve={'smooth'}
      strokeWidth={5}
      fillType={'solid'}
      gridBorderColor={'#e7e7e7'}
      gridRow={{
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }}
      markersSize={4}
      xaxisCategories={fechas}
      xaxisType="category"
      yaxisLabels={{
        show: true,
        formatter: (value) => {
          return showMonto
            ? `${storageMonedaSigno()} ${getNumberWithDecimals(value)}`
            : `${formatNumberWithoutDecimals(value)}`;
        },
        align: 'left',
        offsetX: -16
      }}
      legendFontSize={'11px'}
      legendShowForSingleSeries={true}
      legendShowForNullSeries={true}
      legendShowForZeroSeries={true}
      disableLegendOnItemClickToggle
      disableLengendOnItemHover
      legendPosition={'right'}
      legendHorizontalAlign={'left'}
      legendOffsetY={10}
      onFiltersClick={() => onShowFiltersModal()}
      withoutDecimals={props.withoutDecimals}
      showMonto={showMonto}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataVentasDelMesPorSelected,
    dataSelectedPorPeriodosId: state.ventas.dataEvolucionVentasPorPeriodosSelected,
    filtros: state.ventas.filtroEvolucionVentasPorPeriodos,
    filtrosInteranual: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado,
    ventasPorMarca: state.ventas.evolucionVentasPorPeriodosPorMarca,
    ventasPorRubro: state.ventas.evolucionVentasPorPeriodosPorRubro,
    ventasPorArticulo: state.ventas.evolucionVentasPorPeriodosPorArticulo,
    ventasPorCanal: state.ventas.evolucionVentasPorPeriodosPorCanal,
    ventasPorCliente: state.ventas.evolucionVentasPorPeriodosPorCliente,
    ventasPorGrupoCompra: state.ventas.evolucionVentasPorPeriodosPorGrupoCompra,
    ventasPorProvincia: state.ventas.evolucionVentasPorPeriodosPorProvincia,
    ventasPorResponsable: state.ventas.evolucionVentasPorPeriodosPorResponsable,
    ventasPorUnidadNegocio: state.ventas.evolucionVentasPorPeriodosPorUnidadNegocio,
    ventasPorVendedor: state.ventas.evolucionVentasPorPeriodosPorVendedor,
    ventasPorZona: state.ventas.evolucionVentasPorPeriodosPorZona,
    ventasPorSubrubro: state.ventas.evolucionVentasPorPeriodosPorSubrubro,
    ventasPorCategoriaCliente: state.ventas.evolucionVentasPorPeriodosPorCategoriaCliente,
    actualizarData: state.ventas.actualizarDataEvolucionVentasPorPeriodos,
    clearData: state.ventas.clearDataEvolucionVentasPorPeriodos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFiltros: (desde, hasta, anios, dataSelectedId, aplicado, userSelected) => {
      dispatch(actions.seleccionDataEvolucionVentasPorPeriodos(dataSelectedId));
      dispatch(actions.updateFiltroEvolucionesDeVentasPorPeriodos(desde, hasta, anios, dataSelectedId, aplicado, userSelected));
    },
    updateData: () => dispatch(actions.updateDataEvolucionVentasPorPeriodos(false)),

    //#region Load Ventas Del Mes
    onLoadVentasDelMesPorMarca: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Marca', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorRubro: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Rubro', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorArticulo: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Articulo', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorCanal: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Canal', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorCliente: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Cliente', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorGrupoCompra: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('GrupoDeCompra', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorProvincia: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Provincia', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorResponsable: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Responsable', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorUnidadNegocio: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('UnidadDeNegocio', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorVendedor: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Vendedor', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorZona: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Zona', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorSubrubro: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Subrubro', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorCategoriaCliente: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('CategoriaCliente', desde, hasta, anio, anioHasta, success, errorFail)),
    //#endregion

    //#region Load Evolucion Ventas
    onLoadEvolucionVentasPorMarca: (desde, hasta, anios, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Marca', desde, hasta, anios, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorRubro: (desde, hasta, anios, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Rubro', desde, hasta, anios, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorArticulo: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Articulo', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorCanal: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Canal', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorCliente: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Cliente', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorGrupoCompra: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('GrupoDeCompra', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorProvincia: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Provincia', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorResponsable: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Responsable', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorUnidadNegocio: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('UnidadDeNegocio', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorVendedor: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Vendedor', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorZona: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Zona', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorSubrubro: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('Subrubro', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorCategoriaCliente: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadEvolucionVentasPorPeriodosPorTipoVenta('CategoriaCliente', desde, hasta, anio, success, errorFail, userSelected)),
    //#endregion

    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineChartEvolucionVentas);