import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import TablaInteranualContainerToExcel from '../TablaInteranualContainerToExcel/TablaInteranualContainerToExcel';
import FiltroInteranual from './FiltroInteranual';

const TablaInteranualToolbar = (props) => {
  const { periodos, onAgregarClick, onShowFiltersModal, datasPorPeriodos, type, showFilter } = props;

  return (
    <div style={{ width: '100%', paddingLeft: '1%', paddingRight: '1%' }}>
      <Grid container direction="row" alignContent="center" justifyContent="space-between">
        <Grid item xs={12} lg={8} xl={6} container spacing={1} direction="row" alignContent="center" justifyContent="flex-start">
          <FiltroInteranual
            onAplicarFiltroStart={props.onAplicarFiltroStart}
            onAplicarFiltroEnd={props.onAplicarFiltroEnd}
            onAgregarClick={onAgregarClick}
            periodos={periodos}
            showFilter={showFilter}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={4}
          xl={6}
          container
          spacing={1}
          display="flex"
          direction="row"
          alignContent="center"
          justifyContent="flex-end"
        >
          <Grid item xs={1} lg={3} xl={5} style={{ display: "flex", justifyContent: "flex-end" }}>
            <TablaInteranualContainerToExcel datasPorPeriodo={datasPorPeriodos} montosDe={type} />
          </Grid>
          <Grid item xs={1} lg={1} xl={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => onShowFiltersModal()}>
                <FilterListIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TablaInteranualToolbar;
