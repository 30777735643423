import React, {} from "react";
import { Grid } from '@material-ui/core';
import PivotGridCuboVentas from "../../components/Indicadores/Ventas/CuboVentas/PivotGridCuboVentas";

const CuboVentas = (props) => {
  const { ejecucionDePagina } = props;

  return (
    <Grid container spacing={1} id="informeUno">
      <Grid item xs={12}>
        <PivotGridCuboVentas ejecucionDePagina={ejecucionDePagina} />
      </Grid>
    </Grid>
  )
}

export default CuboVentas;