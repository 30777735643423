import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import * as actions from '../../../../store/actions';

import { storageFechaFija, storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './SunburstVentasPorVendedorPorSeleccion.css';
import PieChart from '../../../Charts/PieChart';
import { formatNumber } from '../../../../shared/formatNumber';

const SunburstVentasPorVendedorPorSeleccion = (props) => {
  const { dataSelectedId } = props;

  /*const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      stateKey: 'ventasPorCategoriaDeCliente',
      onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
    },
    marca: {
      title: 'Marca',
      stateKey: 'ventasPorMarca',
      onLoadKey: 'onLoadVentasPorMarca'
    },
    rubro: {
      title: 'Rubro',
      stateKey: 'ventasPorRubro',
      onLoadKey: 'onLoadVentasPorRubro'
    },
    subrubro: {
      title: 'Subrubro',
      stateKey: 'ventasPorSubrubro',
      onLoadKey: 'onLoadVentasPorSubrubro'
    },
    vendedor: {
      title: 'Vendedor',
      stateKey: 'ventasPorCategoriaDeCliente',
      onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
    },
    provincia: {
      title: 'Provincia',
      stateKey: 'ventasPorProvincia',
      onLoadKey: 'onLoadVentasPorProvincia'
    },
    zona: {
      title: 'Zona',
      stateKey: 'ventasPorZona',
      onLoadKey: 'onLoadVentasPorZona'
    }
  };*/

  const dataSelectOptions = [
    {
      id: 'categoriaDeCliente',
      title: 'Categoría de cliente'
    },
    {
      id: 'marca',
      title: 'Marca'
    },
    {
      id: 'rubro',
      title: 'Rubro'
    },
    {
      id: 'subrubro',
      title: 'Subrubro'
    },
    {
      id: 'vendedor',
      title: 'Vendedor'
    },
    {
      id: 'provincia',
      title: 'Provincia'
    },
    {
      id: 'zona',
      title: 'Zona'
    }
  ];

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    props.onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const fechaDeDatos = props.ventasPorCategoriaDeCliente.fecha;
    const loading = props.ventasPorCategoriaDeCliente.loading;
    return (
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 400, marginRight: '15px' }}>
        <div className={classes.LabelVerPor}>Ventas por vendedor del{' '}
          {loading ? '--/----' : moment(fechaDeDatos ? fechaDeDatos : storageFechaFija()).subtract(1, 'month').format('MM/yyyy')} por:{' '}
        </div>
        <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: '5px' }}
          value={dataSelectedId}
          onChange={(e) => handleChangeOptionSelected(e)}
        >
          {dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id}>
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const data = () => {
    const formatData = [];

    props.ventasPorCategoriaDeCliente.vendedores.map((vendedor) => {
      let sumVentasDelVendedor = 0;

      vendedor.ventas.map((venta) => {
        return (sumVentasDelVendedor += venta.monto);
      });
      return formatData.push(sumVentasDelVendedor);
    });

    return formatData;
  };

  const labels = () => {
    const formatLabels = [];
    props.ventasPorCategoriaDeCliente.vendedores.map((vendedor) => {
      return formatLabels.push(vendedor.nombre);
    });

    return formatLabels;
  };

  const totalSubtitle = () => {
    let sumVentasDelVendedor = 0;

    props.ventasPorCategoriaDeCliente.vendedores.map((vendedor) => {
      return vendedor.ventas.map((venta) => {
        return (sumVentasDelVendedor += venta.monto);
      });
    });

    return `Total: ${storageMonedaSigno()} ${formatNumber(sumVentasDelVendedor)}`;
  };

  const valoresNegativos = () => {
    const formatGastosNegativos = [];

    props.ventasPorCategoriaDeCliente.vendedores.map((vendedor) => {
      return vendedor.ventas
        .filter((vent) => vent.monto < 0)
        .map((venta) => {
          return formatGastosNegativos.push({
            nombre: venta.nombre,
            monto: venta.monto
          });
        });
    });
  };

  return (
    <PieChart
      title={optionSelector()}
      subTitle={totalSubtitle()}
      series={data()}
      labels={labels()}
      valoresNegativos={valoresNegativos()}
      chartType={'pie'}
      disabledCss
      loading={props.ventasPorCategoriaDeCliente.loading}
      imgSinDatos={props.ventasPorCategoriaDeCliente.vendedores.length === 0}
      height={436}
      width={'100%'}
      legendHeight={50}
      legendPosition={'bottom'}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fechaVentasDelMes: state.vendedores.fechaVentasDelMesAnioDelVendedor,
    dataSelectedId: state.vendedores.dataVentasDelMesDeVendedoresPorSelected,
    ventasPorCategoriaDeCliente: state.vendedores.ventasPorVendedorPorCategoriaDeClienteDelMes,
    ventasPorMarca: state.vendedores.ventasPorVendedorPorMarcaDelMes,
    ventasPorRubro: state.vendedores.ventasPorVendedorPorRubroDelMes,
    ventasPorSubrubro: state.vendedores.ventasPorVendedorPorSubrubroDelMes,
    ventasPorProvincia: state.vendedores.ventasPorVendedorPorProvinciaDelMes,
    ventasPorZona: state.vendedores.ventasPorVendedorPorZonaDelMes,
    ventasPorVendedor: state.vendedores.ventasDeVendedoresDelMesPorVendedores
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor(selecionId)),
    onLoadVentasPorMarca: (mes, anio) => dispatch(actions.loadVentasPorVendedorPorMarcaDelMes(mes, anio))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SunburstVentasPorVendedorPorSeleccion);