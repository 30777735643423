import React from 'react';
import { withRouter } from 'react-router-dom';

import classes from './Styles/TextChartCard.css';
import Spinner from '../UI/Spinner/Spinner';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import Tooltip from '@material-ui/core/Tooltip';
import { formatNumber } from '../../shared/formatNumber';
import IndDeshabilitado from '../../assets/images/IndicadorDeshabilitado-Texto.png';
import SinResultado from '../../assets/images/SinResultados-Texto.png';
import { storageMonedaSigno } from '../../shared/sessionData';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton } from '@material-ui/core';

const TextChart = (props) => {
  let percIncrease = Math.round(((props.bigAmount / props.smallAmount - 1) * 100 + Number.EPSILON) * 100) / 100;
  const diferencia = (props.bigAmount - props.smallAmount).toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS'
  });

  if (isNaN(percIncrease)) {
    percIncrease = 0;
  }

  const defineColorSingleValue = () => {
    if(props.bigAmount >= props.smallAmount || (props.bigAmount >= 0 && props.smallAmount === undefined))
      props.comparacionInvertida ? cssClasses.push(classes.BorderRed) : cssClasses.push(classes.BorderGreen);
    if(props.bigAmount < props.smallAmount || (props.bigAmount < 0 && props.smallAmount === undefined))
        props.comparacionInvertida ? cssClasses.push(classes.BorderGreen) : cssClasses.push(classes.BorderRed);
  }
  
  const cssClasses = [classes.TextChartCard];
  if(props.border && !props.disabled)
  {
    if(props.bigAmount && props.bigAmount2){
      if(!isNaN(parseInt(props.bigAmount2, 10)))
      {
        if(props.bigAmount >= props.bigAmount2)
          props.comparacionInvertida ? cssClasses.push(classes.BorderRed) : cssClasses.push(classes.BorderGreen);
        if(props.bigAmount < props.bigAmount2)
          props.comparacionInvertida ? cssClasses.push(classes.BorderGreen) : cssClasses.push(classes.BorderRed);
      }
      else
        defineColorSingleValue()
    }
    else
    {
      if(props.bigAmount >= props.smallAmount || (props.bigAmount >= 0 && props.smallAmount === undefined))
        props.comparacionInvertida ? cssClasses.push(classes.BorderRed) : cssClasses.push(classes.BorderGreen);
      if(props.bigAmount < props.smallAmount || (props.bigAmount < 0 && props.smallAmount === undefined))
        props.comparacionInvertida ? cssClasses.push(classes.BorderGreen) : cssClasses.push(classes.BorderRed);
    } 
  }

  
  // if(props.border && !props.disabled && props.bigAmount && props.bigAmount2 && (props.bigAmount2 >= props.bigAmount)){
  //   props.comparacionInvertida ? cssClasses.push(classes.BorderRed) : cssClasses.push(classes.BorderGreen); 
  // }
  // if (props.border && !props.disabled && (props.bigAmount >= props.smallAmount || (props.bigAmount >= 0 && props.smallAmount === undefined))) {
  //   props.comparacionInvertida ? cssClasses.push(classes.BorderRed) : cssClasses.push(classes.BorderGreen);
  // }
  // if (props.border && !props.disabled && (props.bigAmount < props.smallAmount || (props.bigAmount < 0 && props.smallAmount === undefined))) {
  //   props.comparacionInvertida ? cssClasses.push(classes.BorderGreen) : cssClasses.push(classes.BorderRed);
  // }

  const labelOnClickHandler = (redirectPath) => {
    // if (redirectPath) props.history.push(redirectPath);
    if (redirectPath) window.open(redirectPath);
  };

  const textoGrande = props.bigAmount !== undefined && (
    <Tooltip
      style={{ textAlign: 'left', marginTop: '5px', }}
      title={props.bigAmountTooltip ? props.bigAmountTooltip : ''}
    >
      <div
        style={{
          fontWeight: 'bold',
          marginBottom: '5px',
          marginTop: '0px',
          cursor: props.bigAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
        }}
        className={classes.TextoGrande}
        onClick={() => labelOnClickHandler(props.bigAmoutClickRedirect)}
      >
        {`${
          props.bigCurrency
            ? (props.currencySimbol !== null && props.currencySimbol !== undefined)
              ? `${props.currencySimbol} ${formatNumber(props.disabled ? 0 : props.bigAmount)}` 
              : `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.bigAmount)}`
            : `${props.bigAmount}`
        }`}
      </div>
    </Tooltip>
  );

  const textoChico = props.smallAmount !== undefined && (
    <Tooltip title={props.smallAmountTooltip ? props.smallAmountTooltip : ''}>
      <div
        style={{
          marginTop: '0px',
          cursor: props.smallAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
          paddingRight: '10px'
        }}
        onClick={() => labelOnClickHandler(props.smallAmoutClickRedirect)}
        className={classes.TextoChico}
      >
        {`${props.smallAmountTooltip}: ${
          props.smallCurrency
            ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.smallAmount)}`
            : props.format ? `${formatNumber(props.smallAmount)}` : `${props.smallAmount}`
        } `}
      </div>
    </Tooltip>
  );

  const textoDerecha1 = props.rightAmountTop !== undefined && (
    <Tooltip title={props.rightAmountTopTooltip ? props.rightAmountTopTooltip : ''}>
      <div
        style={{
          marginTop: '0px',
          cursor: props.smallAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
          paddingRight: '10px',
          whiteSpace: 'pre-wrap'
        }}
        onClick={() => labelOnClickHandler(props.smallAmoutClickRedirect)}
        className={classes.TextoChico}
      >
        {`${props.rightAmountTopTooltip}: \r\n${
          props.smallCurrency
            ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.rightAmountTop)}`
            : props.format ? `${formatNumber(props.rightAmountTop)}` : `${props.rightAmountTop}`
        } `}
      </div>
    </Tooltip>
  );

  const textoDerecha2 = props.rightAmountMiddle !== undefined && (
    <Tooltip title={props.rightAmountMiddleTooltip ? props.rightAmountMiddleTooltip : ''}>
      <div
        style={{
          marginTop: '0px',
          cursor: props.smallAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
          paddingRight: '10px',
        }}
        onClick={() => labelOnClickHandler(props.smallAmoutClickRedirect)}
        className={classes.TextoChico}
      >
        {`${props.rightAmountMiddleTooltip}: ${
          props.smallCurrency
            ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.rightAmountMiddle)}`
            : props.format ? `${formatNumber(props.rightAmountMiddle)}` : `${props.rightAmountMiddle}`
        } `}
      </div>
    </Tooltip>
  );

  const textoDerecha3 = props.rightAmountBottom !== undefined && (
    <Tooltip title={props.rightAmountBottomTooltip ? props.rightAmountBottomTooltip : ''}>
      <div
        style={{
          marginTop: '0px',
          cursor: props.smallAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
          paddingRight: '10px'
        }}
        onClick={() => labelOnClickHandler(props.smallAmoutClickRedirect)}
        className={classes.TextoChico}
      >
        {`${props.rightAmountBottomTooltip}: ${
          props.smallCurrency
            ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.rightAmountBottom)}`
            : props.format ? `${formatNumber(props.rightAmountBottom)}` : `${props.rightAmountBottom}`
        } `}
      </div>
    </Tooltip>
  );

  const textoGrande2 = props.bigAmount2 !== undefined && (
    <Tooltip
      style={{ textAlign: 'left', marginTop: '5px' }}
      title={props.bigAmount2Tooltip ? props.bigAmount2Tooltip : ''}
    >
        <div
            style={{
                fontWeight: 'bold',
                marginBottom: '5px',
                marginTop: '0px',
                cursor: props.bigAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default'
            }}
            className={classes.TextoGrande}
            onClick={() => labelOnClickHandler(props.bigAmoutClickRedirect)}
        >
        {`${
          props.bigCurrency2
            ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.bigAmount2)}`
            : `${props.bigAmount2}`
        }`}
      </div>
    </Tooltip>
  )

  const textoChico2 = props.smallAmount2 !== undefined && (
    <Tooltip title={props.smallAmount2Tooltip ? props.smallAmount2Tooltip : ''}>
      <div
        style={{
          marginTop: '0px',
          cursor: props.smallAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
          paddingRight: '10px'
        }}
        onClick={() => labelOnClickHandler(props.smallAmoutClickRedirect)}
        className={classes.TextoChico}
      >
        {`${props.smallAmount2Tooltip}: ${
          props.smallCurrency
            ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.smallAmount2)}`
            : props.format ? `${formatNumber(props.smallAmount2)}` : `${props.smallAmount2}`
        } `}
      </div>
    </Tooltip>
  );

  const flechaUp = (
    <TrendingUpIcon
      fontSize="large"
      style={props.border ? { color: 'white' } : { color: props.comparacionInvertida ? 'red' : 'green' }}
    />
  );
  const flechaDown = (
    <TrendingDownIcon
      fontSize="large"
      style={props.border ? { color: 'white' } : { color: props.comparacionInvertida ? 'green' : 'red' }}
    />
  );

  const flechaFlat = <TrendingFlatIcon fontSize="large" style={props.border && { color: 'white' }} />;

  const mostrarTextoComparacion =
    (props.porcentaje || props.diferencia) &&
    props.smallAmount !== undefined &&
    props.bigAmount !== undefined &&
    props.smallAmount !== 0;
  let iconoSubidaBajada = null;
  let diferenciaOPorcentaje = null;
  if (mostrarTextoComparacion) {
    iconoSubidaBajada = percIncrease === 0 ? flechaFlat : percIncrease > 0 ? flechaUp : flechaDown;

    diferenciaOPorcentaje = props.diferencia ? diferencia : `${Math.abs(percIncrease)}%`;
  }

  return (
    <div className={cssClasses.join(' ')}>
      <div className={classes.CardTitle}>
        <p style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{props.title}</p>
        {props.onFiltersClick && !props.disabled && (
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => props.onFiltersClick()}>
              <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {props.disabled ? (
        <img
          alt="Indicador Inhabilitado"
          className={classes.IndDeshabilitado}
          src={props.customDisabledImage ? props.customDisabledImage : IndDeshabilitado}
        />
      ) : props.loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="small" color={props.colorSpinner} />
        </div>     
      ) : props.imgSinDatos ? (
        <img src={SinResultado} alt="Indicador sin resutado" className={classes.IndDeshabilitado} />
      ) : ( props.smallAmount ?
        (
          <div
            style={{ display: 'flex', flexFlow: 'row', cursor: props.onBodyClick && 'pointer' }}
            onClick={() => props.onBodyClick && props.onBodyClick()}
          >
            <div className={classes.textos} style={{ width: '100%' }}>
              {textoGrande}
              {textoChico}
              {props.bigAmount2 !== 'undefined' ? 
                <div>
                  <p style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{props.bigAmount2Tooltip}</p>
                  {textoGrande2}
                  {textoChico2}
                </div>
                :
                null
              }
            </div>
            {mostrarTextoComparacion && (
              <div className={classes.IconoDiferenciaOPorcentaje}>
                {iconoSubidaBajada}
                {diferenciaOPorcentaje}
              </div>
            )}
          </div>
        ) : 
        (
          <div
            style={{ display: 'flex', flexFlow: 'row', cursor: props.onBodyClick && 'pointer' }}
            onClick={() => props.onBodyClick && props.onBodyClick()}
          >
            <div className={classes.textos} style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
              {textoGrande}
            </div>
            <div style={{display: 'flex', flexFlow: 'column'}}>
              {textoDerecha1}
              {textoDerecha2}
              {textoDerecha3}
            </div>
            {mostrarTextoComparacion && (
              <div className={classes.IconoDiferenciaOPorcentaje}>
                {iconoSubidaBajada}
                {diferenciaOPorcentaje}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default withRouter(TextChart);
