import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';
import { formatNumber } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import * as actions from '../../../../store/actions/index';

import PieChart from '../../../Charts/PieChart';

const PieSaldoDeudores = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }
    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, []);

  const isDisabledChart = props.estaOculto && !props.empresa;

  return (
    <PieChart
      title={`Saldo Deudores ${props.empresa ? props.empresa.nombre : ''} al ${fechaFinPeriodoMesActualFunc()}`}
      labels={props.deudas.map((deuda) => deuda.name)}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(isDisabledChart ? 0 : props.total)}`}
      series={props.deudas.map((deuda) => Math.abs(deuda.value))}
      height={300}
      loading={props.loading && !isDisabledChart}
      imgSinDatos={Math.abs(props.total) <= 0 || isDisabledChart}
      disabled={isDisabledChart}
      width={'100%'}
      chartType={'pie'}
      maxWidthImgSinResult={'275px'}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.deudoresPorVentas.loading,
    deudas: state.ventas.deudoresPorVentas.deudas,
    total: state.ventas.deudoresPorVentas.totalDeudas,
    estaCargado: state.ventas.deudoresPorVentas.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudores
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadDeudoresPorVentas(idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearDeudoresPorVentas())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieSaldoDeudores);
