// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InformesTenacta__PanelContainer__b27TK {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.InformesTenacta__TabsContainer__gJKHy {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}\r\n\r\n.InformesTenacta__TabsScroller__oVFF7 {\r\n  display: flex !important;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.InformesTenacta__TabsScrollerCentered__999qG {\r\n  display: flex !important;\r\n  margin-bottom: 10px;\r\n  justify-content: center;\r\n}\r\n\r\n.InformesTenacta__TabsIndicator__2AJ-Q {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PanelContainer": "InformesTenacta__PanelContainer__b27TK",
	"TabsContainer": "InformesTenacta__TabsContainer__gJKHy",
	"TabsScroller": "InformesTenacta__TabsScroller__oVFF7",
	"TabsScrollerCentered": "InformesTenacta__TabsScrollerCentered__999qG",
	"TabsIndicator": "InformesTenacta__TabsIndicator__2AJ-Q"
};
export default ___CSS_LOADER_EXPORT___;
