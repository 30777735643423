import React, {} from "react";
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

const ImportacionInformeTres = (props) => {
  const { ejecucionDePagina } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportacionInformeTres);