import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import moment from 'moment';

import * as actions from '../../../../store/actions/index';

import LineChart from '../../../Charts/CustomLineChart';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Button from '../../../UI/Button/Button';

import classes from './LineComparacionVentasEntreSucursales.css';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import { Card, FormControl, IconButton, InputLabel, MenuItem, Popper, Select } from '@material-ui/core';
import BoxStepInfo from '../../../UI/BoxStepInfo/BoxStepInfo';
import TablaComparacionVentasEntreSucursales from '../TablaComparacionVentasEntreSucursales/TablaComparacionVentasEntreSucursales';

const LineComparacionVentasEntreSucursales = (props) => {
  const { dataSelectionId } = props;
  const [ventasSeleccionados, setVentasSeleccionados] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [loadingComparacion, setLoadingComparacion] = useState(false);
  const [ventasComparar, setVentasComparar] = useState([]);
  const [formatVentasCompletos] = useState([]);
  const [totalLocal, setTotalLocal] = useState(0);
  const [showTooltipComparacion, setShowTooltipComparacion] = useState(false);
  const [fechaComparacion, setFechaComparacion] = useState('');
  const [sucursalesConSeleccion, setSucursalesConSeleccion] = useState([]);

  const dataOptions = {
    categoriaDeCliente: {
      stringId: 'categoriaDeCliente',
      description: 'Categorías de cliente',
      ventasKey: 'ventasPorCategoria',
      loadKey: 'onLoadVentasDelMesPorCategoriaDeCliente',
      loadDetallesKey: 'onLoadDetallesCategoriaDeCliente'
    },
    vendedor: {
      stringId: 'vendedor',
      description: 'Vendedores',
      ventasKey: 'ventasPorSucursales',
      loadKey: 'onLoadVentasDelMesPorVendedores',
      loadDetallesKey: 'onLoadDetallesVendedor'
    },
    provincia: {
      stringId: 'provincia',
      description: 'Provincias',
      ventasKey: 'ventasPorPrivincias',
      loadKey: 'onLoadVentasDelMesPorProvincias',
      loadDetallesKey: 'onLoadDetallesProvincia'
    },
    zona: {
      stringId: 'zona',
      description: 'Zonas',
      ventasKey: 'ventasPorZonas',
      loadKey: 'onLoadVentasDelMesPorZonas',
      loadDetallesKey: 'onLoadDetallesZona'
    },
    marca: {
      stringId: 'marca',
      description: 'Marcas',
      ventasKey: 'ventasPorMarca',
      loadKey: 'onLoadVentasDelMesPorMarca',
      loadDetallesKey: 'onLoadDetallesaMarca'
    },
    rubro: {
      stringId: 'rubro',
      description: 'Rubros',
      ventasKey: 'ventasPorRubro',
      loadKey: 'onLoadVentasDelMesPorRubro',
      loadDetallesKey: 'onLoadDetallesRubro'
    },
    subrubro: {
      stringId: 'subrubro',
      description: 'Subrubros',
      ventasKey: 'ventasPorSubrubro',
      loadKey: 'onLoadVentasDelMesPorSubrubro',
      loadDetallesKey: 'onLoadDetallesSubrubro'
    }
  };

  useEffect(() => {
    formatVentasCompletos.splice(0, 2);
    ventasComparar.splice(0, 2);
    setVentasComparar([]);
    if (props.ejecucionDePagina && !props.ventasPorSucursales.estaCargado) {
      setVentasComparar([]);
      setTotalLocal(0);
      props.onLoadEvolucionVentasPorSucursales();
    }
  }, [props.ejecucionDePagina, dataSelectionId]);

  useEffect(() => {
    if (ventasSeleccionados.length === 0) {
      series();
      formatMesesToStrings();
    }
  }, [props.ventasPorSucursales.ventas]);

  const series = () => {
    let formatVentas = [];
    const ventas = props.ventasPorSucursales.ventas;
    ventas.map((venta) => {
      sucursalesConSeleccion.push({ id: venta.id, nombre: venta.nombre, estaSeleccionado: false });
      return formatVentas.push({
        name: venta.nombre,
        data: []
      });
    });

    return setVentasSeleccionados(formatVentas);
  };

  const formatMesesToStrings = () => {
    const formatFechas = [];
    const fechasProp = props.ventasPorSucursales.fechas;
    fechasProp.map((fecha) => {
      return formatFechas.push(`${moment(new Date(fecha.anio, fecha.mes, 1)).subtract(1, 'month').format('MM/YY')}`);
    });
    setFechas(formatFechas);
  };

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const ventasActual = ventasSeleccionados;
    if (ventasActual[seriesIndex].data.length !== 0) {
      ventasActual[seriesIndex].data = [];
    } else {
      const montos = props.ventasPorSucursales.ventas[seriesIndex].montos.map((montoMesAnio) => montoMesAnio.monto);
      ventasActual[seriesIndex].data = montos;
    }

    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${ventasSeleccionados
                .filter((venta) => venta.data.length > 0)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((venta) => {
                  return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(venta.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${venta.name}: ${storageMonedaSigno()} ${formatNumber(venta.data[dataPointIndex])} 
                      </span>
                    </div>
                `;
                })
                .join('')}
              </div>
      </div>
      `;
  };

  const onCompararClick = () => {
    ventasComparar.splice(0, 2);
    formatVentasCompletos.splice(0, 2);
    const sucursalesAComparar = sucursalesConSeleccion.filter((vend) => vend.estaSeleccionado);
    const loadKey = dataOptions[dataSelectionId].loadKey;

    const dateComplete = new Date(fechaComparacion.anio, fechaComparacion.mes, 1);

    setLoadingComparacion(true);
    return sucursalesAComparar.map((sucursal) => {
      return props[loadKey](sucursal.id, fechaComparacion.mes, fechaComparacion.anio, (ventasCompleto) => {
        if (ventasComparar.length === 1 && formatVentasCompletos.length === 1) {
          var arrayNuevo = [];
          formatVentasCompletos[0].forEach((ventaCompleto) => {
            const verify = ventasCompleto.filter((venta) => venta.id === ventaCompleto.id);
            if (verify.length === 0) {
              arrayNuevo.push({ ...ventaCompleto, monto: 0, porcentajeDeParticipacion: 0 });
            }
          });

          ventasCompleto.map((venta) => {
            return arrayNuevo.push(venta);
          });
          ventasCompleto.forEach((ventaOrdenado) => {
            const verify = formatVentasCompletos[0].filter((venta) => venta.id === ventaOrdenado.id);

            if (verify.length === 0) {
              formatVentasCompletos[0].push({ ...ventaOrdenado, monto: 0, porcentajeDeParticipacion: 0 });
            }
          });

          var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
          var orderFormatVentasCompletos = formatVentasCompletos[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

          setVentasComparar([
            {
              sucursal: ventasComparar[0].sucursal,
              dateComplete: ventasComparar[0].dateComplete,
              ventas: orderFormatVentasCompletos
            },
            { sucursal: sucursal, dateComplete: dateComplete, ventas: orderArrayNuevo }
          ]);
          setLoadingComparacion(false);
        } else {
          formatVentasCompletos.push(ventasCompleto);
          ventasComparar.push({ sucursal, dateComplete: dateComplete, ventas: ventasCompleto });
        }
      });
    });
  };

  const anchorRef = useRef();

  const middleControls = () => {
    return (
      <div ref={anchorRef}>
        {`Cliquee para seleccionar un mes y dos sucursales para compararlos → `}
        {
          <IconButton
            disabled={props.ventasPorSucursales.loading}
            style={{
              backgroundColor: showTooltipComparacion && 'rgb(255 208 159)'
            }}
            size="small"
            onClick={() => setShowTooltipComparacion(!showTooltipComparacion)}
          >
            <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
          </IconButton>
        }
        {tooltipComparacionContent()}
      </div>
    );
  };

  const onVendedorComparacionClick = (e, index) => {
    const sucursalesActual = [...sucursalesConSeleccion];
    sucursalesActual[index].estaSeleccionado = !sucursalesConSeleccion[index].estaSeleccionado;
    return setSucursalesConSeleccion(sucursalesActual);
  };

  const onQuitarVendedorComparacionClick = (e, index) => {
    const sucursalesActual = [...sucursalesConSeleccion];
    sucursalesActual[index].estaSeleccionado = false;
    return setSucursalesConSeleccion(sucursalesActual);
  };

  const tooltipComparacionContent = () => {
    const verificarCountVendedoresComparacion = sucursalesConSeleccion.filter((vend) => vend.estaSeleccionado);

    return (
      <Popper open={showTooltipComparacion} anchorEl={anchorRef.current} role={undefined}>
        <Card>
          {selectFechas()}
          {sucursalesConSeleccion.map((venta, index) => {
            return (
              <div
                key={index}
                className={classes.selectorSucursalesAComparar}
                style={
                  sucursalesConSeleccion[index].estaSeleccionado
                    ? {
                        paddingLeft: '20px',
                        backgroundColor: 'rgb(255 208 159)'
                      }
                    : undefined
                }
                onClick={(e) =>
                  verificarCountVendedoresComparacion.length < 2
                    ? onVendedorComparacionClick(e, index)
                    : sucursalesConSeleccion[index].estaSeleccionado
                    ? onQuitarVendedorComparacionClick(e, index)
                    : alert('No puede seleccionar mas de dos sucursales')
                }
              >
                {venta.nombre}
              </div>
            );
          })}
          <div style={{ textAlign: 'right' }}>
            <Button
              loading={props.loading}
              disabled={fechaComparacion === '' || verificarCountVendedoresComparacion.length < 2}
              onClick={() => onCompararClick()}
            >
              Comparar
            </Button>
          </div>
        </Card>
      </Popper>
    );
  };

  const selectFechas = () => {
    const fechasProps = props.ventasPorSucursales.fechas;
    return (
      <FormControl style={{ margin: '0px 10px 0px 10px' }}>
        <InputLabel id="sucursalInputLabel">Seleccionar fecha</InputLabel>
        <Select
          style={{ minWidth: 155 }}
          value={fechaComparacion}
          onChange={(e) => {
            setFechaComparacion(e.target.value);
          }}
        >
          {fechasProps.map((fecha, index) => {
            const cutFormat = moment(new Date(fecha.anio, fecha.mes - 1, 1)).format('MM/YY');
            return (
              <MenuItem key={index} value={fecha}>
                {cutFormat}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  /*const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    setVentasSeleccionados([]);
    setFormatVentasCompletos([]);
    setFechas([]);
    setVentasComparar([]);
    setInSelectRange(false);
    setTotalLocal(0);
    props.onChangeOptionSelected(e.target.value);
  };*/

  return (
    <div className={classes.LineComparacionVentasEntreSucursalesContainer}>
      <BoxStepInfo
        texto={
          '1° Paso - Haga click sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información.'
        }
      />
      <LineChart
        title={`Ventas mensuales por ${dataOptions[dataSelectionId].description.toLowerCase()}`}
        series={ventasSeleccionados}
        subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(totalLocal)}`}
        seriesBandera={seriesBandera}
        loading={props.ventasPorSucursales.loading}
        chartTextAlign={'left'}
        eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
        middleItems={middleControls()}
        legendOnItemClick={false}
        chartHeight={'450px'}
        width={'100%'}
        chartWidth={'99%'}
        chartDropShadow={{
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }}
        toolbarShow={true}
        tooltipEnabled
        customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
          armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
        }
        zoomType={'xy'}
        dataLabelsEnabled={window.screen.width > 500}
        formatDataLabels
        strokeCurve={'smooth'}
        strokeWidth={5}
        fillType={'solid'}
        gridBorderColor={'#e7e7e7'}
        gridRow={{
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }}
        markersSize={4}
        xaxisCategories={fechas}
        xaxisType="category"
        yaxisLabels={{
          show: true,
          formatter: (value) => {
            return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
          },
          align: 'left',
          offsetX: -16
        }}
        legendFontSize={'11px'}
        legendShowForSingleSeries={true}
        legendShowForNullSeries={true}
        legendShowForZeroSeries={true}
        disableLegendOnItemClickToggle
        disableLengendOnItemHover
        legendPosition={'right'}
        legendHorizontalAlign={'left'}
        legendOffsetY={10}
        onFiltersClick={() => props.onShowFiltersModal()}
      />
      <div style={{ height: '10px' }} />
      {(ventasComparar.length !== 0 || loadingComparacion) && (
        <TablaComparacionVentasEntreSucursales
          ventasComparar={ventasComparar}
          loading={loadingComparacion}
          loadDetallesKey={dataOptions[dataSelectionId].loadDetallesKey}
          ventasDe={dataOptions[dataSelectionId].description}
          dataSelectionId={props.dataSelectionId}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectionId: state.sucursales.dataVentasDeSucursalesPorSeleccion,

    ventasPorSucursales: state.sucursales.evolucionVentasPorSucursal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selectionId) => dispatch(actions.seleccionDataEvolucionVentas(selectionId)),

    onLoadEvolucionVentasPorSucursales: (success) =>
      dispatch(actions.loadEvolucionVentasPorSucursal(undefined, success)),

    //#region ventas por categoria de cliente
    onLoadVentasDelMesPorCategoriaDeCliente: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorCategoriaDeCliente(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    //#region ventas por vendedores
    onLoadVentasDelMesPorVendedores: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorVendedor(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    //#region ventas por provincias
    onLoadVentasDelMesPorProvincias: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorProvincia(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    //#region ventas por zonas
    onLoadVentasDelMesPorZonas: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorZona(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    //#region ventas por marca
    onLoadVentasDelMesPorMarca: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorMarca(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    //#region ventas por rubro
    onLoadVentasDelMesPorRubro: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorRubro(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    //#region ventas por subrubro
    onLoadVentasDelMesPorSubrubro: (sucursalId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesDeLaSucursalPorSubrubro(mes, anio, sucursalId, success, errorFail)),
    //#endregion

    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineComparacionVentasEntreSucursales);
