import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaVentasPorVendedorDeLaSeleccion.css';
import TablaVentasPorClienteDeLaCategoria from '../../Ventas/TablaVentasPorClienteDeLaCategoria/TablaVentasPorClienteDeLaCategoria';
import TablaVentasPorArticuloDeLaSeleccion from '../../Ventas/TablaVentasPorArticuloDeLaSeleccion/TablaVentasPorArticuloDeLaSeleccion';

const TablaVentasPorVendedorDeLaSeleccion = (props) => {
  const { dataSelectedId } = props;
  const dataExpanded = props.itemExpanded;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes ? props.mes : props.itemExpanded.mes;
  const anio = props.anio ? props.anio : props.itemExpanded.anio;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      onLoadKey: 'onLoadVentasPorVendedorDeLaCategoriaDeCliente'
    },
    marca: {
      title: 'Marca',
      onLoadKey: 'onLoadVentasPorVendedorDeLaMarca'
    },
    rubro: {
      title: 'Rubro',
      onLoadKey: 'onLoadVentasPorVendedorDelRubro'
    },
    subrubro: {
      title: 'Subrubro',
      onLoadKey: 'onLoadVentasPorVendedorDelSubrubro'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadVentasPorVendedorDeLaProvincia'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadVentasPorVendedorDeLaZona'
    }
  };

  useEffect(() => {
    setLoading(true);
    props[dataOptions[dataSelectedId].onLoadKey](mes, anio, props.itemExpanded.id, (response) => {
      setVentasLocal(response);
      setLoading(false);
      props.endRequest && props.endRequest(response.length);
    });
  }, []);

  const columns = [
    {
      title: 'Vendedor',
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        mes: venta.mes,
        anio: venta.anio
      };
    });
  };

  const inCategoriaDeCliente = dataSelectedId === 'categoriaDeCliente';
  const inZona = dataSelectedId === 'zona';
  const inProvincia = dataSelectedId === 'provincia';

  const clientesPrimero = inCategoriaDeCliente || inZona || inProvincia;

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.TablaVentasPorVendedorDeLaSeleccionContainer}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        columns={columns}
        loading={loading}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return clientesPrimero ? (
            <TablaVentasPorClienteDeLaCategoria
              endRequest={() => autoScroll(rowData)}
              vendedorId={rowData.id}
              dataSelectedId={dataSelectedId}
              itemExpanded={dataExpanded}
            />
          ) : (
            <TablaVentasPorArticuloDeLaSeleccion
              endRequest={() => autoScroll(rowData)}
              dataSelectedId={dataSelectedId}
              vendedorId={rowData.id}
              itemExpanded={dataExpanded}
              mes={mes}
              anio={anio}
            />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-ventas-vendedor-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fechaVentasDelMes: state.ventas.fechaVentasDelMes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorVendedorDeLaCategoriaDeCliente: (mes, anio, categoriaId, success, errorFail) =>
      dispatch(actions.loadVentasPorVendedorDeLaCategoriaDeCliente(mes, anio, categoriaId, success, errorFail)),

    onLoadVentasPorVendedorDeLaMarca: (mes, anio, marcaId, success, errorFail) =>
      dispatch(actions.loadVentasPorVendedorDeLaMarca(mes, anio, marcaId, success, errorFail)),

    onLoadVentasPorVendedorDelRubro: (mes, anio, rubroId, success, errorFail) =>
      dispatch(actions.loadVentasPorVendedorDelRubro(mes, anio, rubroId, success, errorFail)),

    onLoadVentasPorVendedorDelSubrubro: (mes, anio, subrubroId, success, errorFail) =>
      dispatch(actions.loadVentasPorVendedorDelSubrubro(mes, anio, subrubroId, success, errorFail)),

    onLoadVentasPorVendedorDeLaProvincia: (mes, anio, provinciaId, success, errorFail) =>
      dispatch(actions.loadVentasPorVendedorDeLaProvincia(mes, anio, provinciaId, success, errorFail)),

    onLoadVentasPorVendedorDeLaZona: (mes, anio, zonaId, success, errorFail) =>
      dispatch(actions.loadVentasPorVendedorDeLaZona(mes, anio, zonaId, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasPorVendedorDeLaSeleccion);
