import React from 'react';
import { connect } from 'react-redux';
import classes from './AnalisisDeSucursales.css';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import { storageMultiempresa } from '../../shared/sessionData';
import Comparacion from './Comparacion';

const AnalisisDeSucursales = (props) => {  
  sessionStorage.setItem('seccionNombre', 'Análisis de sucursales');
  
  const { ejecucionAnalisisDeSucursal } = props;
  const isMultiemp = storageMultiempresa() === 'true';

  return (
    <div className={classes.AnalisisSucursalesContainer}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: !isMultiemp && '10px'
        }}
      >
        {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      </div>
      {!ejecucionAnalisisDeSucursal ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <Comparacion ejecucionDePagina={ejecucionAnalisisDeSucursal} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ejecucionAnalisisDeSucursal: state.ejecucionPaginas.ejecucionAnalisisDeSucursal,
  ultimaActualizacionAnalisisSucursales: state.ultimasActualizaciones.analisisSucursales
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalisisDeSucursales);
