import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';
import { fechaFinPeriodoMesActualFunc, fechaInicioPeriodoMesActualFunc } from '../../../../shared/fechas';

const TextoPromedioDiasCobranzas = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  return (
    <TextChart
      title={`Prom. días de cobranzas ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`}
      bigAmount={'Días: ' + Intl.NumberFormat('de-DE').format(props.dias)}
      smallAmount={`${props.total}`}
      smallAmountTooltip="Total"
      smallCurrency
      disabled={props.estaOculto}
      loading={props.loading}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.textoPromDiasCobranzas.loading,
    dias: state.ventas.textoPromDiasCobranzas.dias,
    total: state.ventas.textoPromDiasCobranzas.total,
    estaCargado: state.ventas.textoPromDiasCobranzas.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.promDiasDeCobranzas,
    indicadores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadTextoPromDiasCobranzas()),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Prom. días de cobranzas', ['cobranza']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPromedioDiasCobranzas);
