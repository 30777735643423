import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Spinner = (props) => {
  let size = 150;
  const [porcentaje, setPorcentaje] = useState(0);

  if (props.size && props.size === 'small') size = 50;
  if (props.size && props.size === 'xs') size = 20;

  useEffect(() => {
    const interval = setInterval(() => {
      if (porcentaje < 100) {
        setPorcentaje((porcentaje) => (porcentaje < 100 ? porcentaje + 1 : limitPorcentaje(interval)));
      }
    }, props.progressTime);

    return () => clearInterval(interval);
  }, [porcentaje, props.progressTime]);

  const limitPorcentaje = (interval) => {
    setPorcentaje(99);
    clearInterval(interval);
  };

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        style={
          ({
            marginTop: size / 4,
            color: props.color && props.color
          },
          props.personalSize && { width: props.personalSize, height: props.personalSize })
        }
        size={size}
      />
      {props.progress && (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            porcentaje
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Spinner;
