const __FILE_NAME__ = "Inflación";

export const validate = (json, anioFiscal = "") => {
  var result = [];

  const header = json[0];
  var headerErrors = [];

  if (!header.hasOwnProperty('M') || header.M.toString().toLowerCase().trim() !== anioFiscal)
    headerErrors.push(`La celda M1 debe contener el año fiscal seleccionado (${anioFiscal}).`);

  if (headerErrors.length > 0) {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: headerErrors
    });
  }

  return result;
}