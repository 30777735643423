import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import TablaCubo from '../../../UI/TablaCuboWrapper/TablaCubo';

const TablaCuboVentasVendedores = (props) => {
  const { fecha } = props;

  useEffect(() => {
    if (!props.estaCargado && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  const onVentasDelMesAnioClick = (mes, anio, index) => {
    props.onChangeFechaVentasDelMes(mes - 1, anio);
    props.onChangeOptionSelected();
    props.clearIndicadores();
    props.onLoadVentasPorCategoriasDeCliente(mes, anio);
  };

  return (
    <TablaCubo
      addMonth
      title={'Ventas desglosadas por mes'}
      titleMontos={'Ventas'}
      datas={props.ventasDataTable}
      fecha={new Date(fecha.anio, fecha.mes, 1)}
      loading={props.loading}
      total={props.totalVentas}
      onSelectMesAnioClick={(mes, anio) => onVentasDelMesAnioClick(mes, anio)}
      onShowFiltersModal={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventasDataTable: state.vendedores.evolucionVentasMensualDeVendedores.ventasDataTable,
    loading: state.vendedores.evolucionVentasMensualDeVendedores.loading,
    fecha: state.vendedores.fechaVentasDelMesAnioDeVendedores,
    totalVentas: state.vendedores.evolucionVentasMensualDeVendedores.totalVentas,
    estaCargado: state.vendedores.evolucionVentasMensualDeVendedores.estaCargado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas desglosadas por mes', ['venta'])),

    onLoadChart: (mes, anio) => dispatch(actions.loadEvolucionDeVentasMensualDeVendedores(mes, anio)),

    onChangeFechaVentasDelMes: (mes, anio) => dispatch(actions.seleccionFechaVentasDelMesAnioDeVendedores(mes, anio)),

    onChangeOptionSelected: () => dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor('categoriaDeCliente')),

    onLoadVentasPorCategoriasDeCliente: (mes, anio) => {
      dispatch(actions.loadVentasDeVendedoresPorCategoriaDeCliente(mes, anio));
      dispatch(actions.loadVentasPorVendedorPorCategoriaDeClienteDelMes(mes, anio));
    },

    clearIndicadores: () => {
      dispatch(actions.clearVentasDeVendedoresDelMesPorMarca());
      dispatch(actions.clearVentasDeVendedoresDelMesPorRubro());
      dispatch(actions.clearVentasDeVendedoresDelMesPorSubrubro());
      dispatch(actions.clearVentassDeVendedoresDelMesPorProvincia());
      dispatch(actions.clearVentasDeVendedoresDelMesPorZona());
      dispatch(actions.clearVentasDeVendedoresDelMesPorVendedor());
      dispatch(actions.clearVentasPorVendedorPorMarcaDelMes());
      dispatch(actions.clearVentasPorVendedorPorRubroDelMes());
      dispatch(actions.clearVentasPorVendedorPorSubrubroDelMes());
      dispatch(actions.clearVentasPorVendedorPorProvinciaDelMes());
      dispatch(actions.clearVentasPorVendedorPoZonaDelMes());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaCuboVentasVendedores);
