import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../../shared/sessionData';

const borderStyle = {
  style: 'thin',
  color: { rgb: "FFD1D1D1" }
};

const border = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle
};

const headersStyle = {
  font: {
    sz: '12',
    bold: true,
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center'
  },
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFF0F6' }
  },
  border
};

const cellsStyle = (value, backgroundColor, bold) => {
  const style = {
    font: {
      sz: '11',
      bold,
      color: {
        fgColor: { rgb: value < 0 ? 'FF0000' : '000000' }
      }
    },
    alignment: {
      horizontal: 'right',
      vertical: 'center'
    },
    fill: {
      patternType: 'solid',
      fgColor: { rgb: backgroundColor }
    },
    border
  }

  return style;
}

const getColumns = (title) => {
  return [
    {
      title: `${title}`,
      field: 'rubro',
      align: 'left'
    },
    {
      title: 'Venta Real (Unidades)',
      field: 'unidades',
      align: 'right'
    },
    {
      title: 'Presupuesto (Unidades)',
      field: 'unidadesPresupuesto',
      align: 'right'
    },
    {
      title: 'Diferencia (Unidades)',
      field: 'unidadesDiferencia',
      align: 'right'
    },
    {
      title: 'Cumplimiento (Unidades)',
      field: 'unidadesCumplimiento',
      align: 'right'
    },
    {
      title: 'Venta Real (Imp. Neto)',
      field: 'importeNeto',
      align: 'right'
    },
    {
      title: 'Presupuesto (Imp. Neto)',
      field: 'importeNetoPresupuesto',
      align: 'right'
    },
    {
      title: 'Diferencia (Imp. Neto)',
      field: 'importeNetoDiferencia',
      align: 'right'
    },
    {
      title: 'Cumplimiento (Imp. Neto)',
      field: 'importeNetoCumplimiento',
      align: 'right'
    },
  ];
};

const getColumnsExpanded = (title) => {
  return [
    {
      title: `${title}`,
      field: 'linea',
      align: 'left'
    },
    {
      title: "Rubro",
      field: 'rubro',
      align: 'left'
    },
    {
      title: 'Artículo',
      field: 'articulo',
      align: 'left'
    },
    {
      title: 'Cliente',
      field: 'Cliente',
      align: 'left'
    },
    {
      title: 'Venta Real (Unidades)',
      field: 'unidades',
      align: 'right'
    },
    {
      title: 'Presupuesto (Unidades)',
      field: 'unidadesPresupuesto',
      align: 'right'
    },
    {
      title: 'Diferencia (Unidades)',
      field: 'unidadesDiferencia',
      align: 'right'
    },
    {
      title: 'Cumplimiento (Unidades)',
      field: 'unidadesCumplimiento',
      align: 'right'
    },
    {
      title: 'Venta Real (Imp. Neto)',
      field: 'importeNeto',
      align: 'right'
    },
    {
      title: 'Presupuesto (Imp. Neto)',
      field: 'importeNetoPresupuesto',
      align: 'right'
    },
    {
      title: 'Diferencia (Imp. Neto)',
      field: 'importeNetoDiferencia',
      align: 'right'
    },
    {
      title: 'Cumplimiento (Imp. Neto)',
      field: 'importeNetoCumplimiento',
      align: 'right'
    },
  ];
}

const columnData = (linea, expanded) => {
  var headerData = [];
  var filterColumns = expanded ? getColumnsExpanded(linea) : getColumns(linea);
  var headerData = [];
  filterColumns.map((column) => {
    return headerData.push({
      title: column.title,
      width: { wpx: column.field === 'rubro' || column.field === 'articulo' ? 250 : 150 },
      style: headersStyle
    });
  });
  return headerData;
}

const getExcelData = (linea, datos) => {
  const datoLinea = datos && datos.find((item) => item.linea && item.linea.trimEnd() === linea);

  if (!datoLinea || !datoLinea.items)
    return [];

  const rubros = datoLinea.items;

  let formatData = [];

  if (linea === 'TENACTA') {
    const rubro = rubros[0];
    formatData.push({
      key: 'TOTAL',
      ...rubro
    });
  } else {
    let total = {
      key: 'TOTAL',
      rubro: `TOTAL ${linea}`,
      unidades: 0,
      unidadesPresupuesto: 0,
      unidadesDiferencia: 0,
      unidadesCumplimiento: 0,
      importeNeto: 0,
      importeNetoPresupuesto: 0,
      importeNetoDiferencia: 0,
      importeNetoCumplimiento: 0
    };

    rubros && rubros.map((rubro) => {
      total.unidades += rubro.unidades;
      total.unidadesPresupuesto += rubro.unidadesPresupuesto;
      total.importeNeto += rubro.importeNeto;
      total.importeNetoPresupuesto += rubro.importeNetoPresupuesto;

      formatData.push(rubro);
    });

    total.unidadesDiferencia = total.unidades - total.unidadesPresupuesto;
    total.unidadesCumplimiento = total.unidades !== 0 && total.unidadesPresupuesto !== 0 ? total.unidades / total.unidadesPresupuesto : 0;
    total.importeNetoDiferencia = total.importeNeto - total.importeNetoPresupuesto;
    total.importeNetoCumplimiento = total.importeNeto !== 0 && total.importeNetoPresupuesto !== 0 ? total.importeNeto / total.importeNetoPresupuesto : 0;

    formatData.push(total);
  }

  return formatData;
}

const formatData = (linea, datos) => {
  var formatData = [];
  const datoLinea = getExcelData(linea, datos);

  datoLinea && datoLinea.map((item, index) => {
    const backgroundColor = item.key === 'TOTAL'
      ? '9DD5E8' : !isEven(index) ? 'FFFFFF' : 'F2F2F2';
    const fontSize = item.key === 'TOTAL' ? '12' : '11';
    const bold = item.key === 'TOTAL';

    return formatData.push([
      {
        value: item.rubro ? item.rubro.toUpperCase() : '',
        style: {
          font: { sz: fontSize, bold },
          alignment: {
            horizontal: 'left',
            vertical: 'center'
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: backgroundColor }
          },
          border
        }
      },
      {
        value: `${formatNumberWithoutDecimals(item.unidades)}`,
        style: cellsStyle(item.unidades, backgroundColor, bold)
      },
      {
        value: `${formatNumberWithoutDecimals(item.unidadesPresupuesto)}`,
        style: cellsStyle(item.unidadesPresupuesto, backgroundColor, bold)
      },
      {
        value: `${formatNumberWithoutDecimals(item.unidadesDiferencia)}`,
        style: cellsStyle(item.unidadesDiferencia, backgroundColor, bold)
      },
      {
        value: `${formatNumber(item.unidadesCumplimiento * 100)} %`,
        style: cellsStyle(item.unidadesCumplimiento * 100, backgroundColor, bold)
      },
      {
        value: `${storageMonedaSigno()} ${formatNumber(item.importeNeto)}`,
        style: cellsStyle(item.importeNeto, backgroundColor, bold)
      },
      {
        value: `${storageMonedaSigno()} ${formatNumber(item.importeNetoPresupuesto)}`,
        style: cellsStyle(item.importeNetoPresupuesto, backgroundColor, bold)
      },
      {
        value: `${storageMonedaSigno()} ${formatNumber(item.importeNetoDiferencia)}`,
        style: cellsStyle(item.importeNetoDiferencia, backgroundColor, bold)
      },
      {
        value: `${formatNumber(item.importeNetoCumplimiento * 100)} %`,
        style: cellsStyle(item.importeNetoCumplimiento * 100, backgroundColor, bold)
      },
    ]);
  });

  return formatData;
};

const getExcelDataExpanded = (linea, datos) => {
  const datoLinea = datos && datos.find((item) => item.linea.trimEnd() === linea);

  if (!datoLinea || !datoLinea.items)
    return [];

  const rubros = datoLinea.items;

  let formatData = [];

  if (linea === 'TENACTA') {
    const rubro = rubros[0];
    formatData.push({
      key: 'TOTAL',
      linea,
      rubro: 'TOTAL',
      articulo: '',
      cliente: '',
      ...rubro
    });
  } else {
    rubros && rubros.map((rubro) => {
      let total = {
        key: 'TOTAL',
        linea,
        rubro: `TOTAL ${rubro.rubro}`,
        articulo: '',
        cliente: '',
        unidades: 0,
        unidadesPresupuesto: 0,
        unidadesDiferencia: 0,
        unidadesCumplimiento: 0,
        importeNeto: 0,
        importeNetoPresupuesto: 0,
        importeNetoDiferencia: 0,
        importeNetoCumplimiento: 0
      };

      rubro.articulos && rubro.articulos.map((articulo) => {
        total.unidades += articulo.unidades;
        total.unidadesPresupuesto += articulo.unidadesPresupuesto;
        total.importeNeto += articulo.importeNeto;
        total.importeNetoPresupuesto += articulo.importeNetoPresupuesto;

        formatData.push({
          linea,
          key: 'articulo',
          cliente: '',
          ...articulo
        });

        if (articulo.clientes) {
          articulo.clientes.map((cliente) => {
            cliente.rubro = '';
            cliente.articulo = '';
            formatData.push({
              linea: '',
              key: 'cliente',
              ...cliente
            });
          })
        }
      });

      total.unidadesDiferencia = total.unidades - total.unidadesPresupuesto;
      total.unidadesCumplimiento = total.unidades !== 0 && total.unidadesPresupuesto !== 0 ? total.unidades / total.unidadesPresupuesto : 0;
      total.importeNetoDiferencia = total.importeNeto - total.importeNetoPresupuesto;
      total.importeNetoCumplimiento = total.importeNeto !== 0 && total.importeNetoPresupuesto !== 0 ? total.importeNeto / total.importeNetoPresupuesto : 0;

      formatData.push(total);
    });
  }

  return formatData;
}

const formatDataExpanded = (linea, datos) => {
  var formatData = [];
  const datoLinea = getExcelDataExpanded(linea, datos);

  datoLinea && datoLinea.map((item, index) => {
    const backgroundColor = item.key === 'TOTAL'
      ? 'C9E6EF' : !isEven(index) ? 'FFFFFF' : 'F2F2F2';
    const fontSize = item.key === 'TOTAL' ? '12' : '11';
    const bold = item.key === 'TOTAL';
    return formatData.push([
      {
        value: item.linea.toUpperCase(),
        style: {
          font: { sz: fontSize, bold: bold },
          alignment: {
            horizontal: 'left',
            vertical: 'center'
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: backgroundColor }
          },
          border
        }
      },
      {
        value: item.rubro.toUpperCase(),
        style: {
          font: { sz: fontSize, bold: bold },
          alignment: {
            horizontal: 'left',
            vertical: 'center'
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: backgroundColor }
          },
          border
        }
      },
      {
        value: item.articulo.toUpperCase(),
        style: {
          font: { sz: '11', bold: false },
          alignment: {
            horizontal: 'left',
            vertical: 'center'
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: backgroundColor }
          },
          border
        }
      },
      {
        value: item.cliente.toUpperCase(),
        style: {
          font: { sz: '11', bold: false },
          alignment: {
            horizontal: 'left',
            vertical: 'center'
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: backgroundColor }
          },
          border
        }
      },
      {
        value: `${formatNumberWithoutDecimals(item.unidades)}`,
        style: cellsStyle(item.unidades, backgroundColor, bold)
      },
      {
        value: item.key === 'cliente' ? '' : `${formatNumberWithoutDecimals(item.unidadesPresupuesto)}`,
        style: cellsStyle(item.unidadesPresupuesto, backgroundColor, bold)
      },
      {
        value: item.key === 'cliente' ? '' : `${formatNumberWithoutDecimals(item.unidadesDiferencia)}`,
        style: cellsStyle(item.unidadesDiferencia, backgroundColor, bold)
      },
      {
        value: item.key === 'cliente' ? '' : `${formatNumber(item.unidadesCumplimiento * 100)} %`,
        style: cellsStyle(item.unidadesCumplimiento * 100, backgroundColor, bold)
      },
      {
        value: `${storageMonedaSigno()} ${formatNumber(item.importeNeto)}`,
        style: cellsStyle(item.importeNeto, backgroundColor, bold)
      },
      {
        value: item.key === 'cliente' ? '' : `${storageMonedaSigno()} ${formatNumber(item.importeNetoPresupuesto)}`,
        style: cellsStyle(item.importeNetoPresupuesto, backgroundColor, bold)
      },
      {
        value: item.key === 'cliente' ? '' : `${storageMonedaSigno()} ${formatNumber(item.importeNetoDiferencia)}`,
        style: cellsStyle(item.importeNetoDiferencia, backgroundColor, bold)
      },
      {
        value: item.key === 'cliente' ? '' : `${formatNumber(item.importeNetoCumplimiento * 100)} %`,
        style: cellsStyle(item.importeNetoCumplimiento * 100, backgroundColor, bold)
      },
    ]);
  });

  return formatData;
};

export const getLineaDataSet = (linea, datos) => {
  return {
    columns: columnData(linea, false),
    data: formatData(linea, datos)
  }
}

export const getLineaExpandedDataSet = (linea, datos) => {
  return {
    columns: columnData(linea, true),
    data: formatDataExpanded(linea, datos)
  }
}