import * as actionTypes from './actionTypes';
import axios from 'axios';
import { storageEmpresaId, storageToken } from '../../shared/sessionData';
import { baseUrlDashboard } from '../../shared/urlApps';
import { getMonthName } from '../../shared/fechas';
import idIndicadores from '../../shared/idIndicadores'

//#region Configuraciones Pivot Grid

export const loadPivotGridConfiguraciones = (key, idUsuario, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadPivotGridConfiguracionesStart(key));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `${baseUrlDashboard()}budgetForecast/GetConfiguraciones?key=${key}&idUsuario=${idUsuario}&idEmpresa=${idEmpresa}`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(loadPivotGridConfiguracionesSuccess(key, response.data));
      })
      .catch((error) => {
        dispatch(loadPivotGridConfiguracionesFail(key, error));
      });
  };
};

export const clearPivotGridConfiguraciones = (key) => {
  return {
    type: actionTypes.CLEAR_PIVOT_GRID_CONFIGURACIONES,
    key
  };
};

export const guardarPivotGridConfiguracion = (configuracion, onSucess, onError) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .post(`${baseUrlDashboard()}budgetForecast/guardarConfiguracion`, configuracion, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        if (onSucess) onSucess(response);
      })
      .catch((error) => {
        console.log(error);

        if (onError) onError();
      });
  };
};

const loadPivotGridConfiguracionesStart = (key) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_START,
    key
  };
};

const loadPivotGridConfiguracionesSuccess = (key, configuraciones) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_SUCCESS,
    key,
    configuraciones
  };
};

const loadPivotGridConfiguracionesFail = (key, error) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_FAIL,
    key,
    error
  };
};

export const loadPivotGridInformaciones = (key, idUsuario, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadPivotGridInformacionesStart(key));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `${baseUrlDashboard()}budgetForecast/GetInformaciones?key=${key}&idUsuario=${idUsuario}&idEmpresa=${idEmpresa}`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(loadPivotGridInformacionesSuccess(key, response.data));
      })
      .catch((error) => {
        dispatch(loadPivotGridInformacionesFail(key, error));
      });
  };
};

const loadPivotGridInformacionesStart = (key) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_START,
    key
  };
};

const loadPivotGridInformacionesSuccess = (key, informaciones) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_SUCCESS,
    key,
    informaciones
  };
};

const loadPivotGridInformacionesFail = (key, error) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_FAIL,
    key,
    error
  };
};

export const clearPivotGridInformaciones = (key) => {
  return {
    type: actionTypes.CLEAR_PIVOT_GRID_INFORMACIONES,
    key
  };
};

export const guardarPivotGridInformacion = (informacion, onSucess, onError) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .post(`${baseUrlDashboard()}budgetForecast/guardarInformacion`, informacion, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        if (onSucess) onSucess(response);
      })
      .catch((error) => {
        console.log(error);

        if (onError) onError();
      });
  };
};

export const loadPivotGridInformacion = (key, informacionId, onSuccess, onError) => {
  return (dispatch) => {
    dispatch(loadPivotGridInformacionStart(key));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}budgetForecast/GetInformacion?key=${key}&informacionId=${informacionId}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadPivotGridInformacionSuccess(key, response.data));
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch(loadPivotGridInformacionFail(key, error));
        onError(error);
      });
  };
};

const loadPivotGridInformacionStart = (key) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_INFORMACION_START,
    key
  };
};

const loadPivotGridInformacionSuccess = (key, informacion) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_INFORMACION_SUCCESS,
    key,
    informacion
  };
};

const loadPivotGridInformacionFail = (key, error) => {
  return {
    type: actionTypes.LOAD_PIVOT_GRID_INFORMACION_FAIL,
    key,
    error
  };
};

export const updateSelectedTotalRows = (key, rows) => {
  return {
    type: actionTypes.UPDATE_SELECTED_TOTAL_ROWS,
    key,
    rows
  };
};

//#endregion

//#region Anios Fiscales
export const loadAniosFiscales = () => {
  return (dispatch) => {
    dispatch(loadAniosFiscalesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}budgetForecast/GetAniosFiscales`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadAniosFiscalesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadAniosFiscalesFail(error));
      });
  };
};

export const clearAniosFiscales = () => {
  return {
    type: actionTypes.CLEAR_ANIOS_FISCALES
  };
};

const loadAniosFiscalesStart = () => {
  return {
    type: actionTypes.LOAD_ANIOS_FISCALES_START
  };
};

const loadAniosFiscalesSuccess = (anios) => {
  return {
    type: actionTypes.LOAD_ANIOS_FISCALES_SUCCESS,
    anios
  };
};

const loadAniosFiscalesFail = (error) => {
  return {
    type: actionTypes.LOAD_ANIOS_FISCALES_FAIL,
    error
  };
};

//#endregion

//#region Budget/Forecast
export const loadBudgetsForecasts = (anioFiscal, onFail = undefined) => {
  return (dispatch) => {
    dispatch(loadBudgetsForecastsStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idEmpresa = storageEmpresaId();
    axios
      .get(`${baseUrlDashboard()}budgetForecast/GetBudgetsForecasts?anioFiscal=${anioFiscal}&idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadBudgetsForecastsSuccess(Number(anioFiscal), response.data));
      })
      .catch((error) => {
        dispatch(loadBudgetsForecastsFail(Number(anioFiscal), error));
      });
  };
};

export const clearBudgetsForecasts = () => {
  return {
    type: actionTypes.CLEAR_BUDGETS_FORECASTS
  };
};

const loadBudgetsForecastsStart = () => {
  return {
    type: actionTypes.LOAD_BUDGETS_FORECASTS_START
  };
};

const loadBudgetsForecastsSuccess = (anio, budgets) => {
  return {
    type: actionTypes.LOAD_BUDGETS_FORECASTS_SUCCESS,
    anio,
    budgets
  };
};

const loadBudgetsForecastsFail = (anio, error) => {
  return {
    type: actionTypes.LOAD_BUDGETS_FORECASTS_FAIL,
    anio,
    error
  };
};

export const loadInformeDosBudgetsForecasts = (anioFiscal) => {
  return (dispatch) => {
    dispatch(loadInformeDosBudgetsForecastsStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idEmpresa = storageEmpresaId();
    axios
      .get(`${baseUrlDashboard()}budgetForecast/GetBudgetsForecastsInformeDos?anioFiscal=${anioFiscal}&idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInformeDosBudgetsForecastsSuccess(Number(anioFiscal), response.data));
      })
      .catch((error) => {
        dispatch(loadInformeDosBudgetsForecastsFail(Number(anioFiscal), error));
      });
  };
};

export const clearInformeDosBudgetsForecasts = () => {
  return {
    type: actionTypes.CLEAR_INFORME_DOS_BUDGETS_FORECASTS
  };
};

const loadInformeDosBudgetsForecastsStart = () => {
  return {
    type: actionTypes.LOAD_INFORME_DOS_BUDGETS_FORECASTS_START
  };
};

const loadInformeDosBudgetsForecastsSuccess = (anio, budgets) => {
  return {
    type: actionTypes.LOAD_INFORME_DOS_BUDGETS_FORECASTS_SUCCESS,
    anio,
    budgets
  };
};

const loadInformeDosBudgetsForecastsFail = (anio, error) => {
  return {
    type: actionTypes.LOAD_INFORME_DOS_BUDGETS_FORECASTS_FAIL,
    anio,
    error
  };
};

//#endregion

//#region Informe 1

export const loadInformeUnoData = (request, onSuccess = undefined, onFail = undefined, config = undefined) => {
  return (dispatch) => {
    dispatch(loadInformeUnoDataStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/GenerarInformeUno`, request, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        let filtro = {
          anioFiscal: request.anioFiscal,
          idArchivo: request.idArchivo,
          mesFiscal: request.mesFiscal,
          acumulado: request.acumulado,
          desde: request.desde,
          hasta: request.hasta,
          anioComparacion: request.anioComparacion
        };

        if (response.data) {
          let data = response.data.datos ? response.data.datos : [];
          let datos = processData(data, response.data.totalUnidades, response.data.totalNeto, response.data.totalBruto);
          let configuracion = response.data.config ? JSON.parse(response.data.config) : config;
          let filtros = response.data.filtros ? response.data.filtros : filtro;
          let aprobado = response.data ? response.data.aprobado : true;
          let esAnterior = response.data ? response.data.esAnterior : false;
          let selectedRows = response.data.filasSubtotales ? response.data.filasSubtotales : [];

          dispatch(loadInformeUnoDataSuccess(datos, configuracion, aprobado, esAnterior, filtros));
          dispatch(loadBudgetsForecasts(filtros.anioFiscal));
          dispatch(
            updateInformeUnoFiltros(
              filtros.anioFiscal,
              filtros.idArchivo,
              filtros.mesFiscal,
              filtros.acumulado,
              filtros.desde,
              filtros.hasta,
              filtros.anioComparacion
            )
          );

          if (onSuccess) {
            const rowData = {
              filasSubtotales: selectedRows,
              configuracion,
              nombre: request.nombre,
              datos
            };

            onSuccess(rowData);
          }
        }
      })
      .catch((error) => {
        if (onFail) onFail(error);

        dispatch(loadInformeUnoDataFail(error));
      });
  };
};

const processData = (data, totalUnidades, totalNeto, totalBruto) => {
  return (
    data &&
    data.map((dato) => {
      return {
        categoria: dato.categoria,
        producto: `${dato.idProducto && dato.idProducto.trimEnd()} - ${dato.producto && dato.producto.trimEnd()}`,
        cliente:
          dato.idCliente && dato.idCliente.trimEnd() !== ''
            ? `${dato.idCliente.trimEnd()} - ${dato.cliente && dato.cliente.trimEnd()}`
            : `${dato.cliente && dato.cliente.trimEnd()}`,
        vendedor: dato.vendedor,
        vendedorVigente: dato.vendedorVigente,
        marca: dato.marca,
        unidadNegocio: dato.unidadNegocio,
        canal: dato.canal,
        grupoCompras: dato.grupoCompras,
        responsable: dato.responsable,
        tipoVenta: dato.tipoVenta,
        anio: dato.anio,
        mes: dato.mes ? `${dato.mes < 10 ? `0${dato.mes}` : `${dato.mes}`} - ${getMonthName(dato.mes - 1)}` : '',

        unidadesFacturadas: dato.unidadesFacturadas,
        unidadesPendientes: dato.unidadesPendientes,
        unidadesTotales: dato.unidadesTotales,
        unidadesDiferencia: dato.unidadesDiferencia,
        unidadesEstimadas: dato.unidadesEstimadas,

        netoFacturado: dato.netoFacturado,
        netoPendiente: dato.netoPendiente,
        netoTotal: dato.netoTotal,
        netoDiferencia: dato.netoDiferencia,
        netoEstimado: dato.netoEstimado,

        brutoFacturado: dato.brutoFacturado,
        brutoPendiente: dato.brutoPendiente,
        brutoTotal: dato.brutoTotal,
        brutoDiferencia: dato.brutoDiferencia,
        brutoEstimado: dato.brutoEstimado,

        descuento: dato.descuento,

        unidadesTotalComparacion: dato.unidadesTotalComparacion,
        unidadesDiferenciaComparacion: dato.unidadesDiferenciaComparacion,
        netoTotalComparacion: dato.netoTotalComparacion,
        netoDiferenciaComparacion: dato.netoDiferenciaComparacion,
        brutoTotalComparacion: dato.brutoTotalComparacion,
        brutoDiferenciaComparacion: dato.brutoDiferenciaComparacion,

        shareUnidades: dato.shareUnidades,
        shareNeto: dato.shareNeto,
        evolucionUnidades: dato.evolucionUnidades,
        evolucionNeto: dato.evolucionNeto,
        totalUnidades,
        totalNeto,
        totalBruto
      };
    })
  );
};

export const clearInformeUnoData = () => {
  return {
    type: actionTypes.CLEAR_INFORME_UNO_DATA
  };
};

export const updateInformeUnoData = (data, config, aprobado = true, esAnterior = false, filtros = {}) => {
  return (dispatch) => {
    dispatch(loadInformeUnoDataSuccess(data, config, aprobado, esAnterior, filtros));
  };
};

const loadInformeUnoDataStart = () => {
  return {
    type: actionTypes.LOAD_INFORME_UNO_DATA_START
  };
};

const loadInformeUnoDataSuccess = (data, config, aprobado, esAnterior, filtros) => {
  return {
    type: actionTypes.LOAD_INFORME_UNO_DATA_SUCCESS,
    data,
    config,
    aprobado,
    esAnterior,
    filtros
  };
};

const loadInformeUnoDataFail = (error) => {
  return {
    type: actionTypes.LOAD_INFORME_UNO_DATA_FAIL,
    error
  };
};

export const updateInformeUnoFiltros = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta, anioComparacion) => {
  return {
    type: actionTypes.UPDATE_INFORME_UNO_FILTROS,
    anioFiscal,
    idArchivo,
    mesFiscal,
    acumulado,
    desde,
    hasta,
    anioComparacion
  };
};

export const updateConfiguracionFields = (key, config) => {
  return {
    type: actionTypes.UPDATE_PIVOT_GRID_CONFIGURACION_FIELDS,
    key,
    config
  };
};
//#endregion

//#region Chart Informe 1
export const updateFiltrosChartInformeUno = (filtros) => {
  return {
    type: actionTypes.UPDATE_FILTROS_CHART_INFORME_UNO,
    filtros
  };
};
//#endregion

//#region Importador Archivo BudgetForecast
export const uploadFileDataToServer = (data, file, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(uploadFileDataToServerStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/ImportarArchivoBudgetForecast?idEmpresa=${storageEmpresaId()}`, data, {
        headers: {
          Authorization: authStr,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch(uploadFileDataToServerSuccess(response.data));
        onSuccess(response.data, file);
      })
      .catch((error) => {
        dispatch(uploadFileDataToServerFail(error));
        onFail(error);
      });
  };
};

const uploadFileDataToServerStart = () => {
  return {
    type: actionTypes.UPLOAD_FILE_DATA_TO_SERVER_START
  };
};

const uploadFileDataToServerSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_FILE_DATA_TO_SERVER_SUCCESS,
    data
  };
};

const uploadFileDataToServerFail = (error) => {
  return {
    type: actionTypes.UPLOAD_FILE_DATA_TO_SERVER_FAIL,
    error
  };
};

export const clearUploadFileDataToServer = () => {
  return {
    type: actionTypes.CLEAR_UPLOAD_FILE_DATA_TO_SERVER
  };
};

export const uploadFileToStorageAccount = (data) => {
  return (dispatch) => {
    dispatch(uploadFileToStorageAccountStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/ExportarArchivoBudgetForecast?idEmpresa=${storageEmpresaId()}`, data, {
        headers: {
          Authorization: authStr,
          Accept: '*/*',
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        dispatch(uploadFileToStorageAccountSuccess());
      })
      .catch((error) => {
        dispatch(uploadFileToStorageAccountFail(error));
      });
  };
};

const uploadFileToStorageAccountStart = () => {
  return {
    type: actionTypes.UPLOAD_FILE_TO_STORAGE_ACCOUNT
  };
};

const uploadFileToStorageAccountSuccess = () => {
  return {
    type: actionTypes.UPLOAD_FILE_TO_STORAGE_ACCOUNT_SUCCESS,
  };
};

const uploadFileToStorageAccountFail = (error) => {
  return {
    type: actionTypes.UPLOAD_FILE_TO_STORAGE_ACCOUNT_FAIL,
    error
  };
};

export const clearUploadFileToStorageAccount = () => {
  return {
    type: actionTypes.CLEAR_UPLOAD_FILE_TO_STORAGE_ACCOUNT
  };
};

//#endregion

//#region Importador Archivo BudgetForecast - Informe 2
export const uploadInformeDosFileDataToServer = (data, fileUnidades, filePorcentajes, fileInflacion, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(uploadInformeDosFileDataToServerStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/ImportarArchivoBudgetForecastInformeDos?idEmpresa=${storageEmpresaId()}`, data, {
        headers: {
          Authorization: authStr,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch(uploadInformeDosFileDataToServerSuccess(response.data));
        onSuccess(response.data, fileUnidades, filePorcentajes, fileInflacion);
      })
      .catch((error) => {
        dispatch(uploadInformeDosFileDataToServerFail(error));
        onFail(error);
      });
  };
};

const uploadInformeDosFileDataToServerStart = () => {
  return {
    type: actionTypes.UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_START
  };
};

const uploadInformeDosFileDataToServerSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_SUCCESS,
    data
  };
};

const uploadInformeDosFileDataToServerFail = (error) => {
  return {
    type: actionTypes.UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_FAIL,
    error
  };
};

export const clearUploadInformeDosFileDataToServer = () => {
  return {
    type: actionTypes.CLEAR_UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER
  };
};
//#endregion

//#region Aprobar BudgetForecast
export const approveBudgetForecast = (idArchivo, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(approveBudgetForecastStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `${baseUrlDashboard()}budgetForecast/AprobarArchivoBudgetForecast?idEmpresa=${storageEmpresaId()}&idArchivo=${idArchivo}`,
        {
          headers: {
            Authorization: authStr,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch(approveBudgetForecastSuccess());
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch(approveBudgetForecastFail(error));
        onFail(error);
      });
  };
};

const approveBudgetForecastStart = () => {
  return {
    type: actionTypes.APPROVE_BUDGET_FORECAST_START
  };
};

const approveBudgetForecastSuccess = (data) => {
  return {
    type: actionTypes.APPROVE_BUDGET_FORECAST_SUCCESS,
    data
  };
};

const approveBudgetForecastFail = (error) => {
  return {
    type: actionTypes.APPROVE_BUDGET_FORECAST_FAIL,
    error
  };
};

export const clearApproveBudgetForecast = () => {
  return {
    type: actionTypes.CLEAR_APPROVE_BUDGET_FORECAST
  };
};
//#endregion

//#region Aprobar BudgetForecast - Informe 2
export const approveInformeDosBudgetForecast = (idArchivo, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(approveInformeDosBudgetForecastStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `${baseUrlDashboard()}budgetForecast/AprobarArchivoBudgetForecastInformeDos?idEmpresa=${storageEmpresaId()}&idArchivo=${idArchivo}`,
        {
          headers: {
            Authorization: authStr,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch(approveInformeDosBudgetForecastSuccess());
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch(approveInformeDosBudgetForecastFail(error));
        onFail(error);
      });
  };
};

const approveInformeDosBudgetForecastStart = () => {
  return {
    type: actionTypes.APPROVE_INFORME_DOS_BUDGET_FORECAST_START
  };
};

const approveInformeDosBudgetForecastSuccess = (data) => {
  return {
    type: actionTypes.APPROVE_INFORME_DOS_BUDGET_FORECAST_SUCCESS,
    data
  };
};

const approveInformeDosBudgetForecastFail = (error) => {
  return {
    type: actionTypes.APPROVE_INFORME_DOS_BUDGET_FORECAST_FAIL,
    error
  };
};

export const clearApproveInformeDosBudgetForecast = () => {
  return {
    type: actionTypes.CLEAR_APPROVE_INFORME_DOS_BUDGET_FORECAST
  };
};
//#endregion

//#region BUDGET FORECAST CONFIG
export const loadBudgetForecastConfig = (onSuccess, onError) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}budgetForecast/GetConfig?empresaId=${storageEmpresaId()}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError(error);
      });
  };
};

export const updateBudgetForecastConfig = (config, onSuccess, onError) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/UpdateConfig?empresaId=${storageEmpresaId()}`, config, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError(error);
      });
  };
};

//#endregion

//#region INFORME 2
export const loadInformeDosUnidades = (request) => {
  return (dispatch) => {
    dispatch(loadInformeDosDataStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/GenerarInformeDos`, request, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        let filtro = {
          anioFiscal: request.anioFiscal,
          idArchivo: request.idArchivo,
          mesFiscal: request.mesFiscal,
          acumulado: request.acumulado,
          desde: request.desde,
          hasta: request.hasta
        };

        if (response.data) {
          let datos = response.data.datos ? response.data.datos : [];
          let filtros = response.data.filtros ? response.data.filtros : filtro;
          let aprobado = response.data ? response.data.aprobado : true;
          let esAnterior = response.data ? response.data.esAnterior : false;

          dispatch(loadInformeDosDataSuccess(datos, aprobado, esAnterior, filtros));
          dispatch(loadInformeDosBudgetsForecasts(filtros.anioFiscal));
          dispatch(
            updateInformeDosFiltros(
              filtros.anioFiscal,
              filtros.idArchivo,
              filtros.mesFiscal,
              filtros.acumulado,
              filtros.desde,
              filtros.hasta
            )
          );
        }
      })
      .catch((error) => {
        dispatch(loadInformeDosDataFail(error));
      });
  };
}

const loadInformeDosDataStart = () => {
  return {
    type: actionTypes.LOAD_INFORME_DOS_DATA_START
  };
};

const loadInformeDosDataSuccess = (data, aprobado, esAnterior, filtros) => {
  return {
    type: actionTypes.LOAD_INFORME_DOS_DATA_SUCCESS,
    data,
    aprobado,
    esAnterior,
    filtros
  };
};

const loadInformeDosDataFail = (error) => {
  return {
    type: actionTypes.LOAD_INFORME_DOS_DATA_FAIL,
    error
  };
};

export const clearInformeDosUnidades = () => {
  return {
    type: actionTypes.CLEAR_INFORME_DOS_DATA
  };
}

export const updateInformeDosFiltros = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta) => {
  return {
    type: actionTypes.UPDATE_INFORME_DOS_FILTROS,
    anioFiscal,
    idArchivo,
    mesFiscal,
    acumulado,
    desde,
    hasta
  };
}

export const loadInformeDosUnidadesPorRubro = (request, onSucess, onFail) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/GenerarInformeDosPorRubro`, request, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response && response.data && onSucess)
          onSucess(response.data);
      })
      .catch((error) => {
        if (onFail)
          onFail(error);
      });
  };
}

//#endregion

//#region EXPORTAR INFORME 2
export const exportarInformeDosExpandido = (request) => {
  return (dispatch) => {
    dispatch(exportarInformeDosExpandidoStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/ExportarInformeDosExpandido`, request, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data) {
          let excelBase64 = response.data;
          dispatch(exportarInformeDosExpandidoSuccess(excelBase64));
        }
      })
      .catch((error) => {
        dispatch(exportarInformeDosExpandidoFail(error));
      });
  };
}

const exportarInformeDosExpandidoStart = () => {
  return {
    type: actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_START
  };
};

const exportarInformeDosExpandidoSuccess = (excelBase64) => {
  return {
    type: actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_SUCCESS,
    excelBase64,
  };
};

const exportarInformeDosExpandidoFail = (error) => {
  return {
    type: actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_FAIL,
    error
  };
};

export const exportarInformeDosExpandidoClear = () => {
  return {
    type: actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_CLEAR
  };
};

//#endregion

//#region Download File

export const downloadFile = (folder, fileName, containerName) => {
  return (dispatch) => {
    dispatch(downloadFileStart())
    const authStr = 'Bearer'.concat(storageToken())
    const fileNameParameter = folder && (folder !== '' || folder !== undefined) ? `${folder}/${fileName}` : `${fileName}`
    axios
      .get(`${baseUrlDashboard()}budgetForecast/DescargarArchivo?fileName=${fileNameParameter}&containerName=${containerName}&idEmpresa=${storageEmpresaId()}`,
        {
          headers: {
            Authorization: authStr,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
      .then((response) => response.data)
      .then((file) => {
        const binaryString = window.atob(file);
        const bytes = new Uint8Array(binaryString.length);
        const arrayBuffer = bytes.map((byte, i) => binaryString.charCodeAt(i));

        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(downloadFileSuccess());
      })
      .catch((error) => {
        dispatch(downloadFileFail(error))
      })
  }
}

const downloadFileStart = () => {
  return {
    type: actionTypes.DOWNLOAD_FILE_START
  };
}

const downloadFileSuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_FILE_SUCCESS,
  }
}

const downloadFileFail = (error) => {
  return {
    type: actionTypes.DOWNLOAD_FILE_FAIL,
    error
  }
}

export const clearDownloadFile = () => {
  return {
    type: actionTypes.CLEAR_DOWNLOAD_FILE
  }
}

//#endregion

//#region INFORME 3

//#region INFORME TRES - BUDGET/FORECAST
export const loadInformeTresBudgetsForecasts = (anioFiscal) => {
  return (dispatch) => {
    dispatch(loadInformeTresBudgetsForecastsStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idEmpresa = storageEmpresaId();
    axios
      .get(`${baseUrlDashboard()}budgetForecast/GetBudgetsForecastsInformeTres?anioFiscal=${anioFiscal}&idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInformeTresBudgetsForecastsSuccess(Number(anioFiscal), response.data));
      })
      .catch((error) => {
        dispatch(loadInformeTresBudgetsForecastsFail(Number(anioFiscal), error));
      });
  };
};

const loadInformeTresBudgetsForecastsStart = () => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_BUDGETS_FORECASTS_START
  };
};

const loadInformeTresBudgetsForecastsSuccess = (anio, budgets) => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_BUDGETS_FORECASTS_SUCCESS,
    anio,
    budgets
  };
};

const loadInformeTresBudgetsForecastsFail = (anio, error) => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_BUDGETS_FORECASTS_FAIL,
    anio,
    error
  };
};

export const clearInformeTresBudgetsForecasts = () => {
  return {
    type: actionTypes.CLEAR_INFORME_TRES_BUDGETS_FORECASTS
  };
};

export const loadUnidadesDeNegocio = () => {
  return (dispatch) => {
    dispatch(loadUnidadesDeNegocioStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idEmpresa = storageEmpresaId();
    axios
      .get(`${baseUrlDashboard()}budgetForecast/ObtenerUnidadesDeNegocio?idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadUnidadesDeNegocioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadUnidadesDeNegocioFail(error));
      });
  };
}

const loadUnidadesDeNegocioStart = () => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_UNIDADES_NEGOCIO_START
  };
  
}

const loadUnidadesDeNegocioSuccess = (unidades) => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_UNIDADES_NEGOCIO_SUCCESS,
    unidades
  };
  
}

const loadUnidadesDeNegocioFail = (error) => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_UNIDADES_NEGOCIO_FAIL,
    error
  };  
}
//#endregion

//#region INFORME TRES - LOAD DATA
export const loadInformeTresUnidades = (request) => {
  return (dispatch) => {
    dispatch(loadInformeTresDataStart());
    const authStr = 'Bearer '.concat(storageToken());

    const body = {
      anioFiscal: request.anioFiscal,
      idArchivo: request.idArchivo,
      mesFiscal: request.mesFiscal,
      acumulado: request.acumulado,
      idEmpresa: request.idEmpresa,
      idUsuario: request.idUsuario,
      desde: request.desde,
      hasta: request.hasta,
      idUnidadDeNegocio: request.unidadDeNegocio && request.unidadDeNegocio.id ? request.unidadDeNegocio.id : '',
      idCanales: request.canal && request.canal.length > 0 ? request.canal.map((canal) => canal.id) : [],
      idVendedores: request.vendedor === 'select' 
        ? request.vendedores.map((vendedor) => vendedor.id)
        : request.vendedor && request.vendedor.id ? [request.vendedor.id] : []
    };

    axios
      .post(`${baseUrlDashboard()}budgetForecast/GenerarInformeTres`, body, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        let filtro = {
          anioFiscal: request.anioFiscal,
          idArchivo: request.idArchivo,
          mesFiscal: request.mesFiscal,
          acumulado: request.acumulado,
          desde: request.desde,
          hasta: request.hasta,
          unidadDeNegocio: request.unidadDeNegocio,
          canal: request.canal,
          vendedor: request.vendedor
        };

        if (response.data) {
          let datos = response.data.datos ? response.data.datos : [];
          let filtros = response.data.filtros ? response.data.filtros : filtro;
          let aprobado = response.data ? response.data.aprobado : true;
          let esAnterior = response.data ? response.data.esAnterior : false;

          dispatch(loadInformeTresDataSuccess(datos, aprobado, esAnterior, filtros));
          dispatch(loadInformeTresBudgetsForecasts(filtros.anioFiscal));
          dispatch(
            updateInformeTresFiltros(
              filtros.anioFiscal,
              filtros.idArchivo,
              filtros.mesFiscal,
              filtros.acumulado,
              filtros.desde,
              filtros.hasta,
              filtros.unidadDeNegocio,
              filtros.canal,
              filtros.vendedor
            )
          );
        }
      })
      .catch((error) => {
        dispatch(loadInformeTresDataFail(error));
      });
  };
}

const loadInformeTresDataStart = () => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_DATA_START
  };
};

const loadInformeTresDataSuccess = (data, aprobado, esAnterior, filtros) => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_DATA_SUCCESS,
    data,
    aprobado,
    esAnterior,
    filtros
  };
};

const loadInformeTresDataFail = (error) => {
  return {
    type: actionTypes.LOAD_INFORME_TRES_DATA_FAIL,
    error
  };
};

export const clearInformeTresUnidades = () => {
  return {
    type: actionTypes.CLEAR_INFORME_TRES_DATA
  };
}

export const updateInformeTresFiltros = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta, unidadDeNegocio, canal, vendedor) => {
  return {
    type: actionTypes.UPDATE_INFORME_TRES_FILTROS,
    anioFiscal,
    idArchivo,
    mesFiscal,
    acumulado,
    desde,
    hasta,
    unidadDeNegocio,
    canal,
    vendedor
  };
}

export const loadInformeTresUnidadesPorRubro = (request, onSucess, onFail) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}budgetForecast/GenerarInformeTresPorRubro`, request, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response && response.data && onSucess)
          onSucess(response.data);
      })
      .catch((error) => {
        if (onFail)
          onFail(error);
      });
  };
}
//#endregion

//#endregion