const findProduct = (arr, row) => {
  return arr.find(p => p.idArticulo === row.A.toString().trim());
}

const createProduct = (data) => {
  return {
    affectedRows: "",
    idArticulo: data.A.toString().trim(),
    canales: []
  };
};

const fillChannels = (product, data, channels) => {
  channels.forEach(ch => {
    const meses = [];
    ch.cols.forEach(col => {
      meses.push(data[col]);
    });
    product.canales.push({ idCanal: ch.channelId, meses });
  });
}

const fillProduct = (product, data) => {
  product.affectedRows += (product.affectedRows.trim() === '' ? '' : ', ') + data.__rowNum__;
  fillChannels(product, data, [
    { channelId: "007", cols: ["B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"] },
    { channelId: "010", cols: ["N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y"] },
    { channelId: "004", cols: ["Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK"] },
    { channelId: "012", cols: ["AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW"] },
    { channelId: "016", cols: ["AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI"] },
    { channelId: "001", cols: ["BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU"] },
    { channelId: "003", cols: ["BV", "BW", "BX", "BY", "BZ", "CA", "CB", "CC", "CD", "CE", "CF", "CG"] },
    { channelId: "002", cols: ["CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS"] },
    { channelId: "005", cols: ["CT", "CU", "CV", "CW", "CX", "CY", "CZ", "DA", "DB", "DC", "DD", "DE"] },
  ]);
};

export const squash = (json) => {
  var result = [];

  json.forEach(row => {
    var product = findProduct(result, row);
    if (product) {
      fillProduct(product, row);
    }
    else {
      var newProduct = createProduct(row);
      fillProduct(newProduct, row);
      result.push(newProduct);
    }
  });

  return result;
}