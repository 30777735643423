import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Table from '../../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classes from './TablaComparacionUtilidadPorSeleccion.css';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TablaComparacionUtilidadDeLaSeleccion from '../TablaComparacionUtilidadDeLaSeleccion/TablaComparacionUtilidadDeLaSeleccion';
import TablaComparacionUtilidadToExcel from './TablaComparacionUtilidadToExcel';
import { compareByAsc, compareByDesc } from '../../../../../shared/sorting';

const TablaComparacionVentasPorSeleccion = (props) => {
  const { fechasComparar = [], dataSelectionId, utilidadesComparar, loadDetallesKey, utilidadesDe } = props;
  const [tableState, setTableState] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });
  const [searchState, setSearchState] = useState('');

  useEffect(() => {
    setTableState([
      { dateComplete: utilidadesComparar[0].dateComplete, utilidadesDetallada: utilidadesComparar[0].utilidades },
      { dateComplete: utilidadesComparar[1].dateComplete, utilidadesDetallada: utilidadesComparar[1].utilidades }
    ]);
  }, []);

  const columns = [
    {
      title: '',
      field: 'nombre',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;
    const filterTableState =
      tableState.length === 2
        ? [
            {
              ...tableState[0],
              utilidadesDetallada: tableState[0].utilidadesDetallada.filter(
                (ventDet) =>
                  ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
              )
            },
            {
              ...tableState[1],
              utilidadesDetallada: tableState[1].utilidadesDetallada.filter(
                (ventDet) =>
                  ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
              )
            }
          ]
        : [];

    filterTableState.map((data, key) => {
      if (key === 0) {
        return data.utilidadesDetallada.map((utilidadDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].utilidadesDetallada[index];

          const ventasDelIndex = ventasDetalldasDelIndex
            ? ventasDetalldasDelIndex
            : { ...utilidadDetallada, monto: 0, porcentajeUtilidadSobreElTotal: 0 };

          totalMonto1 += utilidadDetallada.monto;
          totalMonto2 += ventasDelIndex.monto;
          return formatTableData.push({
            id: utilidadDetallada.id,
            nombre: utilidadDetallada.nombre,
            monto1: utilidadDetallada.monto,
            porcParticipacion1: utilidadDetallada.porcentajeUtilidadSobreElTotal,
            monto2: ventasDelIndex.monto,
            porcParticipacion2: ventasDelIndex.porcentajeUtilidadSobreElTotal,
            difMontos: calcularDif(utilidadDetallada.monto, ventasDelIndex.monto),
            porcComparacion: calcularPorc(utilidadDetallada.monto, ventasDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        nombre: 'Total',
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const handleSearchChange = (e) => {
    setSearchState(e.target.value.toLowerCase());
  };

  const headersBackgroundColor = 'rgb(250, 250, 250)';

  const tableRef = useRef();

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <div
          style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '10px', marginLeft: '20px' }}
        >{`Comparativa de utilidad por ${props.utilidadesDe.toLowerCase()} entre dos periodos seleccionados`}</div>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Buscar"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <TablaComparacionUtilidadToExcel
          ventasPor={`${utilidadesDe}`}
          comprobantes={data()}
          fechas={[
            moment(fechasComparar[0]).subtract(1, 'month').format('MM/YYYY'),
            moment(fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')
          ]}
        />
      </div>
      <Table
        columns={columns}
        tableRef={tableRef}
        data={data()}
        noBoxShadow
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>

                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {moment(utilidadesComparar[0].dateComplete).subtract(1, 'month').format('MM/YYYY')}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {moment(utilidadesComparar[1].dateComplete).subtract(1, 'month').format('MM/YYYY')}
                  </TableCell>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                    align="center"
                  ></TableCell>
                  <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('nombre')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      width: window.screen.width < 1367 ? '265px' : '410px',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="left"
                  >
                    {`${utilidadesDe}`}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto2')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion2')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('difMontos')}
                    style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                    align="right"
                  >
                    Dif. Montos
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcComparacion')}
                    style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                    align="right"
                  >
                    Porc. Comparación
                  </TableCell>
                </TableRow>
              </TableHead>
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            render: (rowData) => {
              return (
                <TablaComparacionUtilidadDeLaSeleccion
                  categoria={rowData}
                  fechasComparar={[utilidadesComparar[0].dateComplete, utilidadesComparar[1].dateComplete]}
                  titleCategory={rowData.nombre}
                  loadDetallesKey={loadDetallesKey}
                  utilidadesDe={utilidadesDe}
                  dataSelectionId={dataSelectionId}
                />
              );
            }
          }
        ]}
        customOptions={{
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          maxBodyHeight: 630,
          showTitle: false,
          tableLayout: 'fixed',
          toolbar: false,
          rowStyle: (rowData, config) => {
            return {
              cursor: 'auto',
              backgroundColor: (!rowData.id && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
            };
          },
          cellStyle: (cellData) => ({
            fontWeight: cellData === 'Total' && 'bolder'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionVentasPorSeleccion);
