import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PieChart from '../../../Charts/PieChart';
import * as actions from '../../../../store/actions/index';

const PiePagosDelMesPorEmpresa = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <PieChart
      title={props.title}
      subTitle={props.subtitle}
      series={props.pagos.map((pago) => pago.total)}
      labels={props.pagos.map((pago) => pago.nombreEmpresa)}
      ids={props.pagos.map((pago) => pago.id)}
      imgSinDatos={props.totalPagos <= 0}
      loading={props.loading}
      chartType={'pie'}
      height={props.height}
      disabled={props.estaOculto}
      width={'100%'}
      onPorcionClick={handlePorcionClick}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    pagos: state.multiempresa.pagosDelMesPorEmpresa.pagos,
    loading: state.multiempresa.pagosDelMesPorEmpresa.loading,
    estaCargado: state.multiempresa.pagosDelMesPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMesMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadPagosDelMesPorEmpresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PiePagosDelMesPorEmpresa);
