import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compareFechaActualFechaFija } from '../../../../shared/fechas';

import SinResultadosPorFecha from '../../../../assets/images/SinResultadosFecha.png';

import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';

const TextoChequesDepositadosDelDia = (props) => {

  useEffect(() => {
    if (props.ejecucionDePagina && !props.estaCargado) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  return (
    <TextChart
      title={`Cheques depositados del día`}
      bigAmount={props.monto}
      bigAmountTooltip=""
      smallAmount={`${props.cantidadCheques}`}
      smallAmountTooltip="Cantidad cheques"
      customDisabledImage={!compareFechaActualFechaFija() && !props.estaOculto ? SinResultadosPorFecha : undefined}
      bigCurrency
      loading={props.loading}
      onBodyClick={() => props.onBodyClick()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.chequesDepositadosDelDia.loading,
    monto: state.ventas.chequesDepositadosDelDia.monto,
    cantidadCheques: state.ventas.chequesDepositadosDelDia.cantidadCheques,
    estaCargado: state.ventas.chequesDepositadosDelDia.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.chequesDepositadosDelDia(idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearChequesDepositadosDelDia()),
    onShowGlobalModal: (title, content) => dispatch(actions.showGlobalModal(true, title, content, ''))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoChequesDepositadosDelDia);
