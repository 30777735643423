import React from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Table from '../../../UI/Table/Table';
import classes from './TablaMultiempresa.css';
import ViewInTable from '../../../../assets/images/ViewInTable.svg';
import { IconButton, Tooltip } from '@material-ui/core';
import TablaMultiempresaToExcel from './TablaMultiempresaToExcel'

const TablaMultiempresas = (props) => {
  const { empresas } = props;

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fafafa'
  };
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const columns = () => {
    const arrColumns = [
      {
        title: 'Empresa',
        field: 'empresa',
        align: 'left',
        cellStyle: cellStyle,
        headerStyle: headerStyle
      },
      {
        title: 'Monto',
        field: 'monto',
        align: 'right',
        cellStyle: cellStyle,
        headerStyle: headerStyle,
        render: (rowData) => `${storageMonedaSigno()}${formatNumber(rowData.monto)}`

      },
      {
        title: 'Porc. Participacion',
        field: 'porcParticipacion',
        align: 'right',
        cellStyle: cellStyle,
        headerStyle: headerStyle,
        render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
      }
    ];
    return arrColumns;
  };
  const data = () => {
    return empresas.map((empresa) => {
      return {
        empresa: empresa.nombreEmpresa,
        monto: empresa.total,
        porcParticipacion: empresa.porcentajeDeParticipacion,
      };
    });
  }

  return (
    <div className={classes.SmallChartCard} style={{ height: '100%', width: props.width, minHeight: props.minHeight }}>
      <div style={{ display: 'flex', margin: 'auto', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title}
          </div>
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como torta">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.TitleCard}>
          {props.subtitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subtitle}</p>}
          <TablaMultiempresaToExcel title={props.title} montos={props.empresas} />
        </div>
        <div className={classes.TablaMultiempresaContainer}>
          <Table
            style={{ width: '100%', marginBottom: '20px' }}
            columns={columns()}
            data={data()}
            loading={props.loading}
            customOptions={{
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: props.height,
            }}
            tableKey={"tabla-multiempresa"}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(TablaMultiempresas)
