export const storageFechaFija = () => {
  return sessionStorage.getItem('fechaFija');
};

export const storageToken = () => {
  return sessionStorage.getItem('token');
};

export const storageExpirationDate = () => {
  return sessionStorage.getItem('expirationDate');
};

export const storageMultiempresa = () => {
  return sessionStorage.getItem('multiempresa');
};

export const storageEsMultiempresa = () => {
  return sessionStorage.getItem('multiempresa') === 'true';
};

export const storageEmpresaId = () => {
  return parseInt(sessionStorage.getItem('empresaId'), 10);
};

export const storageUserEmail = () => {
  return sessionStorage.getItem('userEmail');
};

export const storageMonedaId = () => {
  return sessionStorage.getItem('monedaId');
};

export const storageMonedaSigno = () => {
  return sessionStorage.getItem('monedaSigno');
};

export const storageSucursalId = () => {
  const sucursalId = sessionStorage.getItem('sucursalId');
  return sucursalId ? parseInt(sucursalId, 10) : 0;
};

export const storageUsuarioId = () => {
  return sessionStorage.getItem('usuarioId');
};

export const storageIsAdmin = () => {
  return sessionStorage.getItem('isAdmin');
};

export const getUserInfo = () => {
  return JSON.parse(sessionStorage.getItem('userInfo'));
};

export const storageEmpresaNombre = () => {
  return sessionStorage.getItem('empresaNombre');
};

export const storageSeccionNombre = () => {
  return sessionStorage.getItem('seccionNombre');
};

export const updateUserInfo = (updatedUserInfo) => {
  sessionStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
};

export const storageClienteId = () => {
  return sessionStorage.getItem('clienteId');
};

export const storageIdCliente = () => {
  return sessionStorage.getItem('idCliente');
};

export const limiteInteranual = () => {
  return 6;
};

export const puedeConfigurar = () => {
  return sessionStorage.getItem('ConfiguraComprobantes') === 'true';
};

export const storageAjustaInflacion = () => {
  return sessionStorage.getItem('ajustaInflacion') === 'true';
}

export const storageGrupoGastoId = () => {
  return sessionStorage.getItem('grupoGastosId');
}

export const resultadoSinDecimales = () => {
  return sessionStorage.getItem('resultadoSinDecimales') === 'true';
}