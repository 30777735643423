import React from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../shared/formatNumber";
import { storageMonedaSigno } from "../../../../shared/sessionData";
import PieChart from "../../../Charts/PieChart";

const PieFacturasPendientes = (props) => {
  const { facturas, totalFacturas, loading, noBorder, minHeight } = props;
  const chartSinDatos = facturas.length === 0 || (facturas.length === 1 && facturas[0].value === 0);
  
  const labels = () => {
    return facturas.map((factura) => factura.name);
  };

  const series = () => {
    return facturas.map((factura) => Math.abs(factura.value));
  };


  return (
    <PieChart
      title={`Facturas Pendientes`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(totalFacturas)}`}
      labels={labels()}
      series={series()}
      imgSinDatos={chartSinDatos}
      loading={loading}
      legendPosition={'bottom'}
      chartType="donut"
      legendHorizontalAlign={"left"}
      height={'90%'}
      width={'100%'}
      maxWidthImgSinResult={330}
      noBorder={noBorder}
      minHeight={minHeight}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    facturas: state.ventas.facturasPendientes.facturas,
    totalFacturas: state.ventas.facturasPendientes.totalFacturas,
    estaCargado: state.ventas.facturasPendientes.estaCargado,
    loading: state.ventas.facturasPendientes.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PieFacturasPendientes);