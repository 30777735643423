import React, { Fragment, useEffect, useState } from 'react';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumberWithMonedaSigno } from '../../../../shared/formatNumber';
import AccordionObservacionesComprobante from '../AccordionObservacionesComprobante/AccordionObservacionesComprobante';
import moment from 'moment';
import { Grid, IconButton } from '@material-ui/core';
import * as actions from '../../../../store/actions';
import CloseIcon from '@material-ui/icons/Close';
import classes from './DetallesComprobanteCobranza.css';
import { connect } from 'react-redux';
import TableDetallesCobranza from './TablaDetallesCobranza';
import Spinner from '../../../UI/Spinner/Spinner';

const DetallesComprobanteCobranza = (props) => {
  const { onLoadDetallesComprobante, onChangeModalBody, comprobante } = props;
  const { idComproba, idSucursal, idSistema, fecha, proveedor, referencia, numeroExterno } = comprobante;
  
  const [loading, setLoading] = useState(false);
  const [comprobanteLocal, setComprobanteLocal] = useState({
    fecha: '',
    referencia: '',
    numeroExterno: '',
    idSucursal: '',
    idSistema: '',
    cliente: '',
    idComproba: '',
    detalleComprobante: [],
    subtotal: 0,
    bonificacion1: 0,
    porcBonif1: 0.0,
    bonificacion2: 0,
    porcBonif2: 0.0,
    subtotalBonificado: 0,
    impuestos: 0,
    importe: 0,
    direccion: '',
    localidad: '',
    provincia: '',
    cotizacion: 0,
    simboloMoneda: '$'
  });

  useEffect(() => {
    setLoading(true);
    onLoadDetallesComprobante(
      idSucursal,
      idSistema,
      idComproba,
      referencia,
      true,
      (detallesComprobante) => {
        setComprobanteLocal(detallesComprobante);
        setLoading(false);
      },
      () => {
        onChangeModalBody(<div style={{ textAlign: 'center' }}>Error al cargar los detalles del comprobante</div>);
        setLoading(false);
      }
    );
  }, []);
  
    const buildModalHeader = () => {
        return (
            <div className={classes.TitleCard}>
                <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>DETALLE DE COMPROBANTE</p>
                <div>
                    <IconButton aria-label="close" onClick={() => props.onCloseShowModal()}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        );
    };

    const buildInfoRecibo = (data) => {
        return (
            <InfoComprobanteContainer>
                <InfoComprobanteRow>
                    <InfoTitleDescription title='Cliente' description={proveedor ? proveedor : data.cliente.trim()} xs={3} />
                    <InfoTitleDescription title="Comprobante" description={data.comprobante} xs={2} />
                    <InfoTitleDescription title="Número" description={numeroExterno} xs={3} />
                    <InfoTitleDescription title="Fecha" description={moment(fecha).format('DD/MM/YY')} xs={1} />
                </InfoComprobanteRow>
                <InfoComprobanteRow>
                    <InfoTitleDescription title="Dirección" descriptionSmall={data.direccion.trim()} xs={3} />
                    <InfoTitleDescription title="Localidad" descriptionSmall={data.localidad.trim()} xs={2} />
                    <InfoTitleDescription title="Provincia" descriptionSmall={data.provincia.trim()} xs={3} />
                    <InfoTitleDescription title="Referencia" description={referencia} xs={1} />
                    <InfoTitleDescription title="Estado comprobante" descriptionSmall={data.estado} xs={3} />
                </InfoComprobanteRow>
            </InfoComprobanteContainer>
        );
    };

    const InfoComprobanteContainer = (props) => {
        return (
          <Grid className={classes.infoComprobanteContainer} container spacing={1}>
            {props.children}
          </Grid>
        );
    };
    
    const InfoComprobanteRow = (props) => {
        return (
          <Grid className={classes.infoComprobanteRow} container item xs={12} spacing={5}>
            {props.children}
          </Grid>
        );
    };

    const InfoTitleDescription = ({ xs, title, description, descriptionSmall, style } = props) => {
        return (
            <Grid style={style} item xs={xs} className={classes.titleDescriptionContainer}>
                <Grid item className={classes.title}>
                    {title}
                </Grid>
                <Grid item className={classes.description}>
                    {description}
                </Grid>
                <Grid item className={classes.descriptionSmall}>
                    {descriptionSmall}
                </Grid>
            </Grid>
        );
    };

    const buildInfoTotal = (data) => {
        const titlePorcBonif1 = data.porcentajeBonificacion1 ? `B. 1 (${data.porcentajeBonificacion1})` : 'B. 1 (0.00)';
        const titlePorcBonif2 = data.porcentajeBonificacion2 ? `B. 2 (${data.porcentajeBonificacion2})` : 'B. 2 (0.00)';
    
        return (
          <Grid container>
            <Grid container item xs={11} justifyContent="flex-start" spacing={4}>
              <TitleMontoTotales title="Subtotal" monto={data.subtotal} simbolo={data.simboloMoneda}/>
              <TitleMontoTotales title={titlePorcBonif1} monto={data.bonificacion1} simbolo={data.simboloMoneda}/>
              <TitleMontoTotales title={titlePorcBonif2} monto={data.bonificacion2} simbolo={data.simboloMoneda}/>
              <TitleMontoTotales title="Subtotal" monto={data.subtotalBonificado} simbolo={data.simboloMoneda}/>
              <TitleMontoTotales title="Impuestos" monto={data.impuestos} simbolo={data.simboloMoneda}/>
            </Grid>
            <Grid container item xs={1} justifyContent="flex-end">
              <TitleMontoTotales title="Total" monto={data.importe} simbolo={data.simboloMoneda}/>
            </Grid>
          </Grid>
        );
    };

    const TitleMontoTotales = ({ title, monto, xs, simbolo} = props) => {
        return (
            <Grid item xs={xs} className={classes.titleDescriptionContainer}>
                <Grid item className={classes.titleTotales}>
                    {title}
                </Grid>
                <Grid item className={classes.montoTotales}>
                    {formatNumberWithMonedaSigno(monto, simbolo)}
                </Grid>
            </Grid>
        );
    };

    const buildModalFooter = (data) => {
      let monto = formatNumberWithMonedaSigno(comprobante.total, storageMonedaSigno());
      if(comprobante.debe || comprobante.haber)
      {
        if(comprobante.debe)
          monto = formatNumberWithMonedaSigno(0, storageMonedaSigno()) === comprobante.debe ? comprobante.haber : comprobante.debe
        else
          monto = formatNumberWithMonedaSigno(comprobante.monto, storageMonedaSigno())
      }
      return (
        <InfoComprobanteContainer>
          <InfoComprobanteRow>
            <InfoTitleDescription title="Confección" descriptionSmall={moment(data.fechaConfeccion).format('DD/MM/YY')} xs={1} />
            <InfoTitleDescription title="Cotización" descriptionSmall={`${formatNumberWithMonedaSigno(1, data.simboloMoneda)} = ${formatNumberWithMonedaSigno(data.cotizacion, '$')}`} xs={1.25} />
            <InfoTitleDescription title={`Total ${storageMonedaSigno()}`} descriptionSmall={`${monto}`}/>
          </InfoComprobanteRow>
        </InfoComprobanteContainer>
      );
    };

    return (
        <Fragment>
            <div style={{ opacity: loading && 0.5 }}>
                {buildModalHeader()}
                {buildInfoRecibo(comprobanteLocal)}
                <div style={{ marginBottom: '10px' }} />
                {comprobanteLocal.observaciones && comprobanteLocal.observaciones.length !== 0 && (
                    <AccordionObservacionesComprobante observaciones={comprobanteLocal.observaciones} />
                )}
                <TableDetallesCobranza articulos={comprobanteLocal.detalleComprobante} />
                <div style={{ marginBottom: '10px' }} />
                {buildInfoTotal(comprobanteLocal)}

                <hr />
                {buildModalFooter(comprobanteLocal)}
            </div>
            {loading && (
            <div className={classes.Spinner}>
                <Spinner />
            </div>
            )}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    showModal: state.modals.globalModal.show
})

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseShowModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true , 'lg')),
        onShowModal: (body) => dispatch(actions.showGlobalModal(true, '', body, undefined, true, true, 'lg')),
        onChangeModalBody: (newBody) => dispatch(actions.changeGlobalModalBody(newBody)),
        onLoadDetallesComprobante: (idSucursal, idSistema, idComproba, nroReferencia, cobranza, success, errorFail) =>
        dispatch(actions.loadDetalleComprobante(idSucursal, idSistema, idComproba, nroReferencia, success, errorFail, cobranza))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesComprobanteCobranza);