import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PieChequesPropiosPorEmpresa from '../PieChequesPropiosPorEmpresa/PieChequesPropiosPorEmpresa';
import TablaMultiempresa from '../TablaMultiempresa/TablaMultiempresa';
import { fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

import * as actions from '../../../../store/actions/index';
const MultiChartChequesPropiosPorEmpresa = (props) => {
    const [viewAsPie, setViewAsPie] = useState(true);

    useEffect(() => {
        if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) 
          props.onLoadChart();
        
    }, [props.ejecucionDePagina]);
    
    const handleChangeView = () => {
        setViewAsPie(!viewAsPie)
    }

    return(
        viewAsPie 
        ? 
            <PieChequesPropiosPorEmpresa 
                ejecucionDePagina={props.ejecucionDePagina}
                onPorcionClick={props.onPorcionClick}
                onChangeViewClick={handleChangeView}
                title={`Cheques emitidos pendientes por empresa al ${fechaFinPeriodoMesActualFunc()}`}
                subtitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.totalCheques)}`}
                height={300}
                width={'100%'}
            />
        : 
            <TablaMultiempresa 
                empresas={props.cheques}
                onChangeViewClick={handleChangeView}
                title={`Cheques emitidos pendientes por empresa al ${fechaFinPeriodoMesActualFunc()}`}
                subtitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.totalCheques)}`}
                height={245}
                width={'100%'}
            />
    )
}

const mapStateToProps = (state) => {
    return {
        cheques: state.multiempresa.chequesPropiosPorEmpresa.cheques,
        totalCheques: state.multiempresa.chequesPropiosPorEmpresa.totalCheques,
        loading: state.multiempresa.chequesPropiosPorEmpresa.loading,
        estaCargado: state.multiempresa.chequesPropiosPorEmpresa.estaCargado,
        estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidosPendientesMultiempresa
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      onLoadChart: () => dispatch(actions.loadChequesPropiosPorEmpresa())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiChartChequesPropiosPorEmpresa);