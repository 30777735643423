import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import { Grid, Typography } from '@material-ui/core';
import SelectorAnioFiscal from './SelectorAnioFiscal';
import SelectorArchivo from './SelectorArchivo';
import classes from './ImportadorBudgetForecast.css';
import { Tooltip, IconButton } from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import IconConvertToExcel from '../../../../../assets/images/IconConvertToExcel.png';
import ImportadorBudgetForecastPDF from '../../../../../assets/documents/ImportadorBudgetForecast.pdf';
import TemplateBudgetForecastXLSX from '../../../../../assets/documents/TemplateBudgetForecast.xlsx';

const ImportadorBudgetForecast = (props) => {
  const [anioFiscal, setAnioFiscal] = useState('');
  const [isSelectorArchivoVisible, setIsSelectorArchivoVisible] = useState(false);

  const onAnioFiscalChanged = (anio) => {
    if (anio !== null) {
      setAnioFiscal(anio.id);
      setIsSelectorArchivoVisible(true);
    }
    else {
      setAnioFiscal('');
      setIsSelectorArchivoVisible(false);
    }
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const title = () => {
    return (
      <Typography variant="h5">
        IMPORTADOR BUDGET FORECAST
        
        <Tooltip title="Plantilla Excel">
          <IconButton size="large" style={{marginLeft: 20}} onClick={() => openInNewTab(TemplateBudgetForecastXLSX)}>
            <img alt="" src={IconConvertToExcel} height={'43px'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Manual de usuario">
          <IconButton size="large" style={{marginLeft: 20}} onClick={() => openInNewTab(ImportadorBudgetForecastPDF)}>
            <BookIcon style={{ color: '#696969' }} fontSize="large" />
          </IconButton>
        </Tooltip>
      </Typography>
    )
  }

  const subTitle = () => {
    return (
      <Fragment>
        <Grid container spacing={1} 
          direction="column" justifyContent="flex-start"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} md={11} container>
            <Grid item xs={12} md={8}>
              <SelectorAnioFiscal anioFiscalChangedCallback={onAnioFiscalChanged} />
            </Grid>

            {isSelectorArchivoVisible && (
              <Grid item xs={12} md={11}>
                <hr />
                <SelectorArchivo anioFiscal={anioFiscal} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container
      style={{ height: "100%", width: "95vw", minHeight: 500, 
        border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
        backgroundColor: "white", borderRadius: "3px" }}
    >
      <Grid item xs={12}
        container spacing={1}
        direction='row' justifyContent='space-between' alignContent='flex-start'
      >
        <Grid item xs={9} md={10} 
          container className={classes.TitleContainer}
          style={{ margin: 'auto', marginLeft: '20px' }}>
          <Grid item xs={12} className={classes.Title}>
            {title()}
          </Grid>
          <Grid item xs={12} className={classes.Subtitle}>
            {subTitle()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
  
const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.informeUnoData.loading,
    error: state.tenacta.informeUnoData.error,
    estaCargado: state.tenacta.informeUnoData.estaCargado,
    datos: state.tenacta.informeUnoData.datos,
    config: state.tenacta.informeUnoData.config,
    aprobado: state.tenacta.informeUnoData.aprobado,
    esAnterior: state.tenacta.informeUnoData.esAnterior,
    filtros: state.tenacta.informeUnoData.filtros,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (data) => dispatch(actions.loadInformeUnoData(data)),
    onUpdateInformeUnoData: (data, config, aprobado, esAnterior, filtros) => 
      dispatch(actions.updateInformeUnoData(data, config, aprobado, esAnterior, filtros)),      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportadorBudgetForecast);