import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import TableUtilidadesPorSubrubro from '../../Utilidades/TablesUtilidades/TableUtilidadesPorSubrubro';
import BarUtilidadesChartBase from './BarUtilidadesChartBase';

const BarUtilidadesPorSubrubro = (props) => {
  useEffect(() => {
    if (!props.estaCargado && props.ejecucionDePagina) {
      props.onLoadChart(props.mes, props.anio);
    }
  }, [props.ejecucionDePagina]);

  return (
    <BarUtilidadesChartBase
      title={'Utilidad por subrubro'}
      utilidadPor="subrubro"
      total={props.total}
      utilidades={props.utilidades}
      categories={props.utilidades}
      categoriasCompletas={props.utilidades}
      loading={props.loading}
      chartSinDatos={props.utilidades.length === 0}
      utilidadTable={<TableUtilidadesPorSubrubro />}
      onChangeUtilOcultas={(utilidades) => props.onChangeUtilidadesOcultas(utilidades)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    utilidades: state.ventas.utilidadPorSubrubro.utilidades,
    total: state.ventas.utilidadPorSubrubro.total,
    loading: state.ventas.utilidadPorSubrubro.loading,
    progressLoading: state.ventas.utilidadPorSubrubro.progress,
    error: state.ventas.utilidadPorSubrubro.error,
    estaCargado: state.ventas.utilidadPorSubrubro.estaCargado,
    mes: state.ventas.fechaVentasDelMes.mes,
    anio: state.ventas.fechaVentasDelMes.anio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio) => dispatch(actions.loadUtilidadPorSubrubro(mes, anio)),
    onChangeUtilidadesOcultas: (utilidades) => dispatch(actions.updateUtilidadesPorSubrubroOcultos(utilidades))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarUtilidadesPorSubrubro);
