// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Multiempresa__MultiempresaContainer__nztkZ {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.Multiempresa__FlexHorizontal__wILoX {\r\n  display: flex;\r\n  flex: 1 1;\r\n  justify-content: space-between;\r\n}\r\n\r\n.Multiempresa__FlexVertical__Jx12X {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.Multiempresa__PiesPrimeraColumna__U0Ew3 {\r\n  margin-right: 10px;\r\n  display: flex;\r\n  flex: 1 1;\r\n}\r\n\r\n.Multiempresa__PiesSegundaColumna__71gU7 {\r\n  display: flex;\r\n  flex: 1 1;\r\n}\r\n\r\n.Multiempresa__PiesTerceraColumna__D1gYT {\r\n  margin-left: 10px;\r\n  display: flex;\r\n  flex: 1 1;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .Multiempresa__FlexHorizontal__wILoX {\r\n    display: inline-block;\r\n  }\r\n\r\n  .Multiempresa__PiesPrimeraColumna__U0Ew3 {\r\n    margin-top: 10px;\r\n    margin-right: 0px;\r\n  }\r\n\r\n  .Multiempresa__PiesSegundaColumna__71gU7 {\r\n    margin-top: 10px;\r\n  }\r\n\r\n  .Multiempresa__PiesTerceraColumna__D1gYT {\r\n    margin-left: 0px;\r\n    margin-top: 10px;\r\n  }\r\n\r\n  .Multiempresa__FlexVertical__Jx12X {\r\n    display: inline-block;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MultiempresaContainer": "Multiempresa__MultiempresaContainer__nztkZ",
	"FlexHorizontal": "Multiempresa__FlexHorizontal__wILoX",
	"FlexVertical": "Multiempresa__FlexVertical__Jx12X",
	"PiesPrimeraColumna": "Multiempresa__PiesPrimeraColumna__U0Ew3",
	"PiesSegundaColumna": "Multiempresa__PiesSegundaColumna__71gU7",
	"PiesTerceraColumna": "Multiempresa__PiesTerceraColumna__D1gYT"
};
export default ___CSS_LOADER_EXPORT___;
