import React from 'react';
import { connect } from 'react-redux';
import PieChart from '../../../Charts/PieChart';
import * as actions from '../../../../store/actions/index';

const PieSaldoDeudoresPorEmpresa = (props) => {
 
  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <PieChart
      title={props.title}
      subTitle={props.subtitle}
      series={props.saldosPorEmpresa.map((saldo) => saldo.total)}
      labels={props.saldosPorEmpresa.map((saldo) => saldo.nombreEmpresa)}
      ids={props.saldosPorEmpresa.map((saldo) => saldo.id)}
      imgSinDatos={props.totalSaldos <= 0}
      loading={!props.estaOculto && props.loading && props.ejecucionDePagina}
      chartType={'pie'}
      height={props.height}
      disabled={props.estaOculto}
      width={'100%'}
      onPorcionClick={handlePorcionClick}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    saldosPorEmpresa: state.multiempresa.saldoDeudoresPorEmpresa.saldos,
    loading: state.multiempresa.saldoDeudoresPorEmpresa.loading,
    estaCargado: state.multiempresa.saldoDeudoresPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudoresMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadSaldoDeudoresPorEmpresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieSaldoDeudoresPorEmpresa);
