import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { storageEmpresaId } from '../../../shared/sessionData';
import * as actions from '../../../store/actions/index';

import Spinner from '../Spinner/Spinner';
import { Tooltip } from '@material-ui/core';
import { pathNamePages } from '../../../shared/pathnames';

const EmpresaSelector = (props) => {
  const {
    loading,
    cargandoIndicadores,
    setEmpresasessionStorage,
    onChangeValue,
    empresas,
    indicadoresOcultos,
    onLoadEmpresas,
    onSeleccionarEmpresa,
    onActualizarIndicadoresFotoDelDia,
    onUpdateUltimaActualizacionFotoDelDia,
    onLimpiarIndicadoresFotoDelDia,
    onLimpiarUltimaActualizacionFotoDelDia,
    onActualizarIndicadoresGastos,
    onUpdateUltimaActualizacionGastos,
    onLimpiarIndicadoresGastos,
    onLimpiarUltimaActualizacionGastos,
    onActualizarRentabilidad,
    onUpdateUltimaActualizacionRentabilidad,
    onLimpiarIndicadoresRentabilidad,
    onLimpiarUltimaActualizacionRentabilidad,
    onActualizarAnalisisVentas,
    onUpdateUltimaActualizacionAnalisisVentas,
    onLimpiarIndicadoresAnalisisVentas,
    onLimpiarUltimaActualizacionAnalisisVentas,
    onActualizarAnalisisSucursales,
    onUpdateUltimaActualizacionAnalisisSucursales,
    onLimpiarIndicadoresAnalisisSucursales,
    onLimpiarUltimaActualizacionAnalisisSucursales,
    onActualizarAnalisisVendedores,
    onUpdateUltimaActualizacionAnalisisVendedores,
    onLimpiarIndicadoresAnalisisVendedores,
    onLimpiarUltimaActualizacionAnalisisVendedores,
    onActualizarAnalisisUtilidad,
    onUpdateUltimaActualizacionAnalisisUtilidad,
    onLimpiarIndicadoresAnalisisUtilidad,
    onLimpiarUltimaActualizacionAnalisisUtilidad,
    onLimpiarInformes,
    onActualizarInformes,
    onLimpiarInformeDos,
    onActualizarInformeDos,
    onResetEjecucionPaginas
  } = props;

  const [empresaId, setEmpresaId] = useState(storageEmpresaId());

  useEffect(() => {
    if (empresas.length === 0 && !loading) {
      onLoadEmpresas();
    }
  }, [empresas, onLoadEmpresas, loading]);

  const onChangeEmpresaSuccess = (response) => {
    if (response) {
      const empresa = response.data;
      props.onLimpiarSucursales();
      sessionStorage.setItem('empresaNombre', empresa.empresa);
      sessionStorage.setItem('empresaId', empresa.empresaId);
      sessionStorage.setItem('monedaId', empresa.monedaId);
      sessionStorage.setItem('monedaSigno', empresa.monedaSigno);
      setEmpresaId(empresa.empresaId);
      props.onLoadVendedores();
      props.onLoadSucursales(empresa.empresaId, () => { });
      limpiarIndicadores();
    }
  }

  const onChangeEmpresaError = (error) => {
    console.log(error);
  }

  const onEmpresaClick = (empresa) => {
    if (setEmpresasessionStorage) {
      onSeleccionarEmpresa(empresa.id, onChangeEmpresaSuccess, onChangeEmpresaError);
    } else {
      onChangeValue(empresa);
    }
  };

  //#region Actualizar Indicadores

  const urlRef = global.window.location.pathname;
  const dateActual = moment(Date.now()).format('DD/MM/YYYY HH:mm');

  const actualizarIndicadoresPaginaActivaDesmontarOtras = () => {
    switch (urlRef) {
      case pathNamePages.gastos:
        return actualizarGastosLimpiarOtros();

      case pathNamePages.fotoDelDia:
        return actualizarFotoDelDiaLimpiarOtros();

      case pathNamePages.home:
        return actualizarFotoDelDiaLimpiarOtros();

      case pathNamePages.rentabilidad:
        return actualizarRentabilidadLimpiarOtros();

      case pathNamePages.analisisVentas:
        return actualizarAnalisisVentasLimpiarOtros();

      case pathNamePages.analisisSucursales:
        return actualizarAnalisisSucursalesLimpiarOtros();

      case pathNamePages.analisisVendedores:
        return actualizarAnalisisVendedoresLimpiarOtros();

      case pathNamePages.analisisUtilidad:
        return actualizarAnalisisUtilidadLimpiarOtros();

      case pathNamePages.tenactaInformes:
        return actualizarInformesLimpiarOtros();

      case pathNamePages.tenactaInformeDos:
        return actualizarInformeDosLimpiarOtros();

      default:
        break;
    }
  };

  const actualizarFotoDelDiaLimpiarOtros = () => {
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    if (props.ejecucionFotoDelDia) {
      onUpdateUltimaActualizacionFotoDelDia(dateActual);
      onActualizarIndicadoresFotoDelDia(indicadoresOcultos);
    }
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarGastosLimpiarOtros = () => {
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    if (props.ejecucionGastos) {
      onUpdateUltimaActualizacionGastos(dateActual);
      onActualizarIndicadoresGastos();
    }
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarRentabilidadLimpiarOtros = () => {
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    if (props.ejecucionResultado) {
      onUpdateUltimaActualizacionRentabilidad(dateActual);
      onActualizarRentabilidad();
    }
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarAnalisisVentasLimpiarOtros = () => {
    onUpdateUltimaActualizacionAnalisisVentas(dateActual);
    onActualizarAnalisisVentas();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarAnalisisSucursalesLimpiarOtros = () => {
    onUpdateUltimaActualizacionAnalisisSucursales(dateActual);
    onActualizarAnalisisSucursales();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarAnalisisVendedoresLimpiarOtros = () => {
    props.onLoadVendedores((success) => {
      props.handleChangeVendedor(success.data[0]);
      onLimpiarInformes();
    });

    onUpdateUltimaActualizacionAnalisisVendedores(dateActual);
    onActualizarAnalisisVendedores();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarAnalisisUtilidadLimpiarOtros = () => {
    onUpdateUltimaActualizacionAnalisisUtilidad(dateActual);
    onActualizarAnalisisUtilidad();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarInformes();
    onLimpiarInformeDos();
  };

  const actualizarInformesLimpiarOtros = () => {
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarInformeDos();
    onLimpiarInformes();
    onActualizarInformes();
  };  

  const actualizarInformeDosLimpiarOtros = () => {
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarInformes();
    onLimpiarInformeDos();
    onActualizarInformeDos();
  };

  //#endregion

  //#region Limpiar Indicadores

  const limpiarIndicadores = () => {
    onResetEjecucionPaginas();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresGastos();
    onLimpiarUltimaActualizacionGastos();
    onLimpiarIndicadoresRentabilidad();
    onLimpiarUltimaActualizacionRentabilidad();
    onLimpiarIndicadoresAnalisisVentas();
    onLimpiarUltimaActualizacionAnalisisVentas();
    onLimpiarIndicadoresAnalisisSucursales();
    onLimpiarUltimaActualizacionAnalisisSucursales();
    onLimpiarIndicadoresAnalisisVendedores();
    onLimpiarUltimaActualizacionAnalisisVendedores();
    onLimpiarIndicadoresAnalisisUtilidad();
    onLimpiarUltimaActualizacionAnalisisUtilidad();
    onLimpiarInformes();
    onLimpiarInformeDos();
  }

  //#endregion

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', zIndex: 1200 }}>
      <div>
        {props.empresas.map((empresa, key) => (
          empresa.esGrupoEmpresas && !empresa.inactiva && (<Tooltip
            title="Cargando indicadores..."
            placement="right"
            arrow
            open={cargandoIndicadores && key === props.empresas.length - 1}
            key={key}
          >
            <button
              disabled={cargandoIndicadores}
              key={key}
              style={{
                margin: '0px',
                outline: 'none',
                color: empresa.id === empresaId ? '#FFFFFF' : '#CECECE',
                border: '0.5px solid #CECECE',
                textAlign: 'center',
                font: 'normal normal medium 12px/16px Roboto',
                backgroundColor: empresa.id === empresaId ? '#0CC5FF' : '#FFFFFF',
                borderRadius: '3px 0px 0px 3px',
                cursor: empresa.id !== empresaId && !cargandoIndicadores && 'pointer'
              }}
              onClick={() => empresa.id !== empresaId && onEmpresaClick(empresa)}
            >
              {empresa.nombre}
            </button>
          </Tooltip>)
        ))}
        <span style={{ marginLeft: props.empresas.find(x => x.esGrupoEmpresas) ? 50 : 0 }}></span>
        {props.empresas.map((empresa, key) => (
          !empresa.esGrupoEmpresas && (<Tooltip
            title="Cargando indicadores..."
            placement="right"
            arrow
            open={cargandoIndicadores && key === props.empresas.length - 1}
            key={key}
          >
            <button
              disabled={cargandoIndicadores}
              key={key}
              style={{
                margin: '0px',
                outline: 'none',
                color: empresa.id === empresaId ? '#FFFFFF' : '#CECECE',
                border: '0.5px solid #CECECE',
                textAlign: 'center',
                font: 'normal normal medium 12px/16px Roboto',
                backgroundColor: empresa.id === empresaId ? '#0CC5FF' : '#FFFFFF',
                borderRadius: '3px 0px 0px 3px',
                cursor: empresa.id !== empresaId && !cargandoIndicadores && 'pointer'
              }}
              onClick={() => empresa.id !== empresaId && onEmpresaClick(empresa)}
            >
              {empresa.nombre}
            </button>
          </Tooltip>)
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.multiempresa.empresas.loading,
    empresas: state.multiempresa.empresas.empresas,
    ejecucionFotoDelDia: state.ejecucionPaginas.ejecucionFotoDelDia,
    ejecucionGastos: state.ejecucionPaginas.ejecucionGastos,
    ejecucionMultiempresa: state.ejecucionPaginas.ejecucionMultiempresa,
    ejecucionResultado: state.ejecucionPaginas.ejecucionResultado,
    ejecucionAnalisisDeVenta: state.ejecucionPaginas.ejecucionAnalisisDeVenta,
    ejecucionTiemposReq: state.ejecucionPaginas.ejecucionTiemposReq,
    indicadoresOcultos: {
      ventasDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDelMes,
      ventasDelDia: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDelDia,
      ivaFiscal: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscal,
      cobranzasDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasDelMes,
      gastosPorCategoria: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosPorCategoria,
      saldoDeudores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudores,
      saldoAcreedores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoAcreedores,
      plazoMedioVencACobrar: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.plazoMedioVencACobrar,
      plazoMedioVencAPagar: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.plazoMedioVencAPagar,
      promDiasDeCobranzas: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.promDiasDeCobranzas,
      promDiasDePagos: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.promDiasDePagos,
      chequesEnCartera: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCartera,
      chequesEmitidos: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidos,
      gastosDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMes,
      pagosDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMes,
      deudaPorCategoriaDeCliente:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.deudaPorCategoriaDeCliente
    },
    indicadoresOcultosMultiempresa: {
      ivaFiscalMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscalMultiempresa,
      chequesEnCarteraMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCarteraMultiempresa,
      chequesEmitidosPendientesMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidosPendientesMultiempresa,
      cobranzasMensualesMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasMensualesMultiempresa,
      gastosDelMesMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMesMultiempresa,
      pagosDelMesMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMesMultiempresa,
      saldoAcreedoresMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoAcreedoresMultiempresa,
      saldoDeudoresMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudoresMultiempresa,
      ventasDiariasMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDiariasMultiempresa,
      ventasMensualesMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasMensualesMultiempresa,
      rentabilidadMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.rentabilidadMultiempresa
    },
    cargandoIndicadores:
      state.ventas.pieVentasComparativo.loading ||
      state.ventas.ventasDiariasComparativo.loading ||
      state.ventas.textoPromDiasCobranzas.loading ||
      state.ventas.textoPromedioCobranzas.loading ||
      state.ventas.textoPlazoMedioVencimientos.loading ||
      state.ventas.textoCobranzasMensual.loading ||
      state.ventas.textoChequesEnCartera.loading ||
      state.ventas.deudoresPorVentas.loading ||
      state.ventas.deudaPorCategoriaDeCliente.loading ||
      state.ventas.lineVentasComparativo.loading ||
      state.ventas.utilidadPorMarca.loading ||
      state.ventas.utilidadPorRubro.loading ||
      state.ventas.utilidadPorSubrubro.loading ||
      state.ventas.ventasDelMesPorMarcas.loading ||
      state.ventas.ventasDelMesPorProvincias.loading ||
      state.ventas.ventasDelMesPorRubros.loading ||
      state.ventas.ventasDelMesPorSubrubros.loading ||
      state.ventas.ventasDelMesPorVendedores.loading ||
      state.ventas.ventasDelMesPorZonas.loading ||
      state.ventas.evolucionVentasMensual.loading ||
      state.ventas.evolucionVentasPorCategoriaDeCliente.loading ||
      state.ventas.evolucionVentasPorVendedores.loading ||
      state.ventas.evolucionVentasPorProvincias.loading ||
      state.ventas.evolucionVentasPorZonas.loading ||
      state.ventas.evolucionVentasPorMarca.loading ||
      state.ventas.evolucionVentasPorRubro.loading ||
      state.ventas.evolucionVentasPorSubrubro.loading ||
      state.integradores.lineRentabilidadEvolutivo.loading ||
      state.gastos.evolucionGastosMensual.loading ||
      state.gastos.pieGastosPorCategoria.loading ||
      state.gastos.pieGastosPorCategoriaSinDataTable.loading ||
      state.gastos.evolucionGastosMensual.loading ||
      state.gastos.pieGastosPorCategoria.loading ||
      state.gastos.gastosPorItemDeCategoria.loading ||
      state.gastos.textoGastosDelMes.loading ||
      state.compras.pieSaldoAcreedores.loading ||
      state.compras.textoChequesPropios.loading ||
      state.compras.textoPagosDelMes.loading ||
      state.compras.textoDeudasPorCompras.loading ||
      state.compras.textoPromDiasPagos.loading ||
      state.vendedores.evolucionVentasMensualDelVendedor.loading ||
      state.vendedores.ventasDelMesPorCategoriaDeClienteDelVendedor.loading ||
      state.vendedores.ventasDelMesPorMarcaDelVendedor.loading ||
      state.vendedores.ventasDelMesPorRubroDelVendedor.loading ||
      state.vendedores.ventasDelMesPorSubrubroDelVendedor.loading ||
      state.vendedores.ventasDelMesPorProvinciasDelVendedor.loading ||
      state.vendedores.ventasDelMesPorZonasDelVendedor.loading ||
      state.vendedores.evolucionVentasDelVendedorPorCategoriaDeCliente.loading ||
      state.vendedores.evolucionVentasDelVendedorPorProvincia.loading ||
      state.vendedores.evolucionVentasDelVendedorPorZonas.loading ||
      state.vendedores.evolucionVentasDelVendedorPorMarca.loading ||
      state.vendedores.evolucionVentasDelVendedorPorRubro.loading ||
      state.vendedores.evolucionVentasDelVendedorPorSubrubro.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEmpresas: () => dispatch(actions.loadEmpresas()),
    onLimpiarSucursales: () => dispatch(actions.clearSucursalesFiltro()),
    onLoadSucursales: (empresaId, success) => dispatch(actions.loadSucursalesFiltro(empresaId, false, success)),

    onLoadVendedores: (success) => dispatch(actions.loadVendedores(success)),
    handleChangeVendedor: (vendedor) => dispatch(actions.seleccionVendedorAnalisisVendedores(vendedor)),

    onSeleccionarEmpresa: (empresaId, success, error) =>
      dispatch(actions.seleccionarEmpresa(empresaId, success, error)),
    onActualizarIndicadoresMultiempresa: (indicadoresOcultosMultiempresa) =>
      dispatch(actions.actualizarIndicadoresMultiempresa(indicadoresOcultosMultiempresa)),
    onResetEjecucionPaginas: () => dispatch(actions.resetEjecucionPaginas()),

    //#region Foto del dia
    onActualizarIndicadoresFotoDelDia: (indicadoresOcultos) =>
      dispatch(actions.actualizarIndicadoresFotoDelDia(indicadoresOcultos)),
    onUpdateUltimaActualizacionFotoDelDia: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionFotoDelDia(fechaHora)),
    onLimpiarIndicadoresFotoDelDia: () => dispatch(actions.clearIndicadoresFotoDelDia()),
    onLimpiarUltimaActualizacionFotoDelDia: () => dispatch(actions.clearUltimaActualizacionFotoDelDia()),
    //#endregion

    //#region Gastos
    onActualizarIndicadoresGastos: () => dispatch(actions.actualizarIndicadoresGastos()),
    onUpdateUltimaActualizacionGastos: (fechaHora) => dispatch(actions.updateUltimaActualizacionGastos(fechaHora)),
    onLimpiarIndicadoresGastos: () => dispatch(actions.clearIndicadoresGastos()),
    onLimpiarUltimaActualizacionGastos: () => dispatch(actions.clearUltimaActualizacionGastos()),
    //#endregion

    //#region Timpos Req
    onActualizarTiemposReq: () => dispatch(actions.actualizarTiemposReq()),
    onUpdateUltimaActualizacionTiemposReq: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionTiemposRequest(fechaHora)),
    onLimpiarTiemposReq: () => dispatch(actions.clearTiemposReq()),
    onLimpiarUltimaActualizacionTiemposReq: () => dispatch(actions.clearUltimaActualizacionTiemposRequest()),
    //#endregion

    //#region Rentabilidad
    onActualizarRentabilidad: () => dispatch(actions.actualizarRentabilidad()),
    onUpdateUltimaActualizacionRentabilidad: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionRentabilidad(fechaHora)),
    onLimpiarIndicadoresRentabilidad: () => dispatch(actions.clearRentabilidad()),
    onLimpiarUltimaActualizacionRentabilidad: () => dispatch(actions.clearUltimaActualizacionRentabilidad()),
    //#endregion

    //#region Analisis ventas
    onActualizarAnalisisVentas: () => dispatch(actions.actualizarAnalisisVentas()),
    onUpdateUltimaActualizacionAnalisisVentas: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisVentas(fechaHora)),
    onLimpiarIndicadoresAnalisisVentas: () => dispatch(actions.clearAnalisisVentas()),
    onLimpiarUltimaActualizacionAnalisisVentas: () => dispatch(actions.clearUltimaActualizacionAnalisisVentas()),
    //#endregion

    //#region Analisis sucursales
    onActualizarAnalisisSucursales: () => dispatch(actions.actualizarAnalisisSucursales()),
    onUpdateUltimaActualizacionAnalisisSucursales: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisSucursales(fechaHora)),
    onLimpiarIndicadoresAnalisisSucursales: () => dispatch(actions.clearAnalisisSucursales()),
    onLimpiarUltimaActualizacionAnalisisSucursales: () =>
      dispatch(actions.clearUltimaActualizacionAnalisisSucursales()),
    //#endregion

    //#region Analisis vendedores
    onActualizarAnalisisVendedores: () => dispatch(actions.actualizarAnalisisVendedores()),
    onUpdateUltimaActualizacionAnalisisVendedores: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisVendedores(fechaHora)),
    onLimpiarIndicadoresAnalisisVendedores: () => dispatch(actions.clearAnalisisVendedores()),
    onLimpiarUltimaActualizacionAnalisisVendedores: () =>
      dispatch(actions.clearUltimaActualizacionAnalisisVendedores()),
    //#endregion

    //#region Analisis vendedores
    onActualizarAnalisisUtilidad: () => dispatch(actions.actualizarAnalisisUtilidad()),
    onUpdateUltimaActualizacionAnalisisUtilidad: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisUtilidad(fechaHora)),
    onLimpiarIndicadoresAnalisisUtilidad: () => dispatch(actions.clearAnalisisUtilidad()),
    onLimpiarUltimaActualizacionAnalisisUtilidad: () => dispatch(actions.clearUltimaActualizacionAnalisisUtilidad()),
    //#endregion

    //#region Tenacta
    onActualizarInformes: () => dispatch(actions.actualizarInformes()),
    onLimpiarInformes: () => dispatch(actions.clearInformes()),
    onActualizarInformeDos: () => dispatch(actions.actualizarInformeDos()),
    onLimpiarInformeDos: () => dispatch(actions.clearInformeDos()),
    //#endregion
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmpresaSelector);
