import { fechaActualFunc } from '../../shared/fechas';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  vendedorSelected: {
    id_vendedor: '',
    nombre: ''
  },
  dataEvolucionVentasDelVendedorSelected: 0,
  dataVentasDelMesDelVendedorPorSelected: 'categoriaDeCliente',
  fechaVentasDelMesAnioDelVendedor: {
    mes: fechaActualFunc().getMonth(),
    anio: fechaActualFunc().getFullYear()
  },
  dataVentasDelMesDeVendedoresPorSelected: 'categoriaDeCliente',
  fechaVentasDelMesAnioDeVendedores: {
    mes: fechaActualFunc().getMonth(),
    anio: fechaActualFunc().getFullYear()
  },
  evolucionVentasMensualDelVendedor: {
    ventas: [],
    ventasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorCategoriaDeClienteDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorMarcaDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorRubroDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorSubrubroDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorProvinciasDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorZonasDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  evolucionVentasDelVendedorPorCategoriaDeCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorProvincia: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorZonas: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasMensualDeVendedores: {
    ventas: [],
    ventasDataTable: [],
    totalVentas: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasPorVendedorPorMarcaDelMesSumburstFormat: {
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorVendedorPorCategoriaDeClienteDelMes: {
    vendedores: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorVendedorPorMarcaDelMes: {
    vendedores: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorVendedorPorRubroDelMes: {
    vendedores: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorVendedorPorSubrubroDelMes: {
    vendedores: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorVendedorPorProvinciaDelMes: {
    vendedores: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorVendedorPorZonaDelMes: {
    vendedores: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasDeVendedoresDelMesPorCategoriaDeCliente: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDeVendedoresDelMesPorVendedores: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeVendedoresDelMesPorProvincias: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeVendedoresDelMesPorZonas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeVendedoresDelMesPorMarcas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeVendedoresDelMesPorRubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeVendedoresDelMesPorSubrubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  evolucionVentasDeVendedoresPorVendedor: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  }
};

//#region load evolucion de ventas
const loadEvolucionDeVentasMensualDelVendedorStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorSuccess = (state, ventas, ventasDataTable, total) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventas,
      ventasDataTable,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionDeVentasMensualDelVendedor = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load evolucion ventas por categoria de cliente
const loadVentasDelMesPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorCategoriaDeClienteSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load  ventas por Marca del vendedor
const loadVentasDelMesPorMarcaDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorMarcaDelVendedorSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorMarcaDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorMarcaDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load  ventas por Rubro
const loadVentasDelMesPorRubroDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorRubroDelVendedorSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorRubroDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorRubroDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load  ventas por Subrubro
const loadVentasDelMesPorSubrubroDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorSubrubroDelVendedorSuccess = (
  state,
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  total
) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorSubrubroDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorSubrubroDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load ventas del mes por provincias del vendedor
const loadVentasDelMesPorProvinciasDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorProvinciasDelVendedorSuccess = (state, ventas, ventasNegativo, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      ventas,
      ventasNegativo,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorProvinciasDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorProvinciasDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por zonas
const loadVentasDelMesPorZonasDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorZonasDelVendedorSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorZonasDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorZonasDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load evolucion ventas por categoria de cliente
const loadEvolucionVentasDelVendedorPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        ventas: [],
        fechas: [],
        total: 0,
        loading: true,
        error: null,
        estaCargado: false
      }
    )
  });
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        ventas,
        fechas,
        total,
        loading: false,
        estaCargado: true
      }
    )
  });
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        error,
        loading: false,
        estaCargado: true
      }
    )
  });
};

const clearEvolucionVentasDelVendedorPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        ventas: [],
        fechas: [],
        total: 0,
        loading: false,
        error: null,
        estaCargado: false
      }
    )
  });
};
//#endregion

//#region load evolucion ventas por Provincias
const loadEvolucionVentasDelVendedorPorProvinciasStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorProvinciasSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorProvincias = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Zonas
const loadEvolucionVentasDelVendedorPorZonasStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorZonasSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorZonasFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorZonas = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Marca
const loadEvolucionVentasDelVendedorPorMarcaStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorMarcaSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorMarcaFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorMarca = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Rubro
const loadEvolucionVentasDelVendedorPorRubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorRubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorRubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorRubro = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Subrubro
const loadEvolucionVentasDelVendedorPorSubrubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorSubrubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorSubrubro = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion de ventas
const loadEvolucionDeVentasMensualDeVendedoresStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualDeVendedoresSuccess = (state, ventas, ventasDataTable, totalVentas) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      ventas,
      ventasDataTable,
      totalVentas,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualDeVendedoresFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionDeVentasMensualDeVendedores = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas por vendedor por marca
const loadVentasPorVendedorPorMarcaSumburstFormatStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMesSumburstFormat: updateObject(state.ventasPorVendedorPorMarcaDelMesSumburstFormat, {
      labels: [],
      parents: [],
      values: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorMarcaSumburstFormatSuccess = (state, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMesSumburstFormat: updateObject(state.ventasPorVendedorPorMarcaDelMesSumburstFormat, {
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorMarcaSumburstFormatFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMesSumburstFormat: updateObject(state.ventasPorVendedorPorMarcaDelMesSumburstFormat, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorMarcaSumburstFormat = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMesSumburstFormat: updateObject(
      state.ventasPorVendedorPorMarcaDelMesSumburstFormat,
      initialState.ventasPorVendedorPorMarcaDelMesSumburstFormat
    )
  });
};
//#endregion

//#region load ventas por vendedor por Categoria de cliente
const loadVentasPorVendedorPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorCategoriaDeClienteDelMes: updateObject(state.ventasPorVendedorPorCategoriaDeClienteDelMes, {
      vendedores: [],
      labels: [],
      parents: [],
      values: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorCategoriaDeClienteSuccess = (state, vendedores, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorCategoriaDeClienteDelMes: updateObject(state.ventasPorVendedorPorCategoriaDeClienteDelMes, {
      vendedores,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorCategoriaDeClienteDelMes: updateObject(state.ventasPorVendedorPorCategoriaDeClienteDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorCategoriaDeClienteDelMes: updateObject(
      state.ventasPorVendedorPorCategoriaDeClienteDelMes,
      initialState.ventasPorVendedorPorCategoriaDeClienteDelMes
    )
  });
};
//#endregion

//#region load ventas por vendedor por marca
const loadVentasPorVendedorPorMarcaStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMes: updateObject(state.ventasPorVendedorPorMarcaDelMes, {
      vendedores: [],
      labels: [],
      parents: [],
      values: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorMarcaSuccess = (state, vendedores, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMes: updateObject(state.ventasPorVendedorPorMarcaDelMes, {
      vendedores,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorMarcaFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMes: updateObject(state.ventasPorVendedorPorMarcaDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorMarca = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorMarcaDelMes: updateObject(
      state.ventasPorVendedorPorMarcaDelMes,
      initialState.ventasPorVendedorPorMarcaDelMes
    )
  });
};
//#endregion

//#region load ventas por vendedor por rubro
const loadVentasPorVendedorPorRubroStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorRubroDelMes: updateObject(state.ventasPorVendedorPorRubroDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorRubroSuccess = (state, vendedores, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorRubroDelMes: updateObject(state.ventasPorVendedorPorRubroDelMes, {
      vendedores,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorRubroFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorRubroDelMes: updateObject(state.ventasPorVendedorPorRubroDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorRubro = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorRubroDelMes: updateObject(
      state.ventasPorVendedorPorRubroDelMes,
      initialState.ventasPorVendedorPorRubroDelMes
    )
  });
};
//#endregion

//#region load ventas por vendedor por Subrubro
const loadVentasPorVendedorPorSubrubroStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorSubrubroDelMes: updateObject(state.ventasPorVendedorPorSubrubroDelMes, {
      vendedores: [],
      labels: [],
      parents: [],
      values: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorSubrubroSuccess = (state, vendedores, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorSubrubroDelMes: updateObject(state.ventasPorVendedorPorSubrubroDelMes, {
      vendedores,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorSubrubroDelMes: updateObject(state.ventasPorVendedorPorSubrubroDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorSubrubro = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorSubrubroDelMes: updateObject(
      state.ventasPorVendedorPorSubrubroDelMes,
      initialState.ventasPorVendedorPorSubrubroDelMes
    )
  });
};
//#endregion

//#region load ventas por vendedor por Provincia
const loadVentasPorVendedorPorProvinciaStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorProvinciaDelMes: updateObject(state.ventasPorVendedorPorProvinciaDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorProvinciaSuccess = (state, ventas, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorProvinciaDelMes: updateObject(state.ventasPorVendedorPorProvinciaDelMes, {
      ventas,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorProvinciaFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorProvinciaDelMes: updateObject(state.ventasPorVendedorPorProvinciaDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorProvincia = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorProvinciaDelMes: updateObject(
      state.ventasPorVendedorPorProvinciaDelMes,
      initialState.ventasPorVendedorPorProvinciaDelMes
    )
  });
};
//#endregion

//#region load ventas por vendedor por Zona
const loadVentasPorVendedorPorZonaStart = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorZonaDelMes: updateObject(state.ventasPorVendedorPorZonaDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorVendedorPorZonaSuccess = (state, ventas, fecha) => {
  return updateObject(state, {
    ventasPorVendedorPorZonaDelMes: updateObject(state.ventasPorVendedorPorZonaDelMes, {
      ventas,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorVendedorPorZonaFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedorPorZonaDelMes: updateObject(state.ventasPorVendedorPorZonaDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorVendedorPorZona = (state) => {
  return updateObject(state, {
    ventasPorVendedorPorZonaDelMes: updateObject(
      state.ventasPorVendedorPorZonaDelMes,
      initialState.ventasPorVendedorPorZonaDelMes
    )
  });
};
//#endregion

//#region load ventas de vendedores del mes por categoria de cliente
const loadVentasDeVendedoresDelMesPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorCategoriaDeCliente: updateObject(state.ventasDeVendedoresDelMesPorCategoriaDeCliente, {
      ventas: [],
      ventasDataTable: [],
      ventasNegativo: [],
      fecha: '',
      TEXTO_CHEQUES_EN_CARTERA_FAIL: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDeVendedoresDelMesPorCategoriaDeClienteSuccess = (
  state,
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  totalVentas
) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorCategoriaDeCliente: updateObject(state.ventasDeVendedoresDelMesPorCategoriaDeCliente, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total: totalVentas,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDeVendedoresDelMesPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorCategoriaDeCliente: updateObject(state.ventasDeVendedoresDelMesPorCategoriaDeCliente, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeVendedoresDelMesPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorCategoriaDeCliente: updateObject(state.ventasDeVendedoresDelMesPorCategoriaDeCliente, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de vendedores del mes por vendedores
const loadVentasDeVendedoresDelMesPorVendedoresStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorVendedores: updateObject(state.ventasDeVendedoresDelMesPorVendedores, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeVendedoresDelMesPorVendedoresSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorVendedores: updateObject(state.ventasDeVendedoresDelMesPorVendedores, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeVendedoresDelMesPorVendedoresFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorVendedores: updateObject(state.ventasDeVendedoresDelMesPorVendedores, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDeVendedoresDelMesPorVendedores = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorVendedores: updateObject(state.ventasDeVendedoresDelMesPorVendedores, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de vendedores del mes por provincias
const loadVentasDeVendedoresDelMesPorProvinciasStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorProvincias: updateObject(state.ventasDeVendedoresDelMesPorProvincias, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeVendedoresDelMesPorProvinciasSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorProvincias: updateObject(state.ventasDeVendedoresDelMesPorProvincias, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeVendedoresDelMesPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorProvincias: updateObject(state.ventasDeVendedoresDelMesPorProvincias, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDeVendedoresDelMesPorProvincias = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorProvincias: updateObject(state.ventasDeVendedoresDelMesPorProvincias, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de vendedores del mes por zonas
const loadVentasDeVendedoresDelMesPorZonasStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorZonas: updateObject(state.ventasDeVendedoresDelMesPorZonas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeVendedoresDelMesPorZonasSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorZonas: updateObject(state.ventasDeVendedoresDelMesPorZonas, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeVendedoresDelMesPorZonasFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorZonas: updateObject(state.ventasDeVendedoresDelMesPorZonas, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDeVendedoresDelMesPorZonas = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorZonas: updateObject(state.ventasDeVendedoresDelMesPorZonas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de vendedores del mes por marcas
const loadVentasDeVendedoresDelMesPorMarcasStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorMarcas: updateObject(state.ventasDeVendedoresDelMesPorMarcas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeVendedoresDelMesPorMarcasSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorMarcas: updateObject(state.ventasDeVendedoresDelMesPorMarcas, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeVendedoresDelMesPorMarcasFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorMarcas: updateObject(state.ventasDeVendedoresDelMesPorMarcas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeVendedoresDelMesPorMarcas = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorMarcas: updateObject(state.ventasDeVendedoresDelMesPorMarcas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de vendedores del mes por rubros
const loadVentasDeVendedoresDelMesPorRubrosStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorRubros: updateObject(state.ventasDeVendedoresDelMesPorRubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeVendedoresDelMesPorRubrosSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorRubros: updateObject(state.ventasDeVendedoresDelMesPorRubros, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeVendedoresDelMesPorRubrosFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorRubros: updateObject(state.ventasDeVendedoresDelMesPorRubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeVendedoresDelMesPorRubros = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorRubros: updateObject(state.ventasDeVendedoresDelMesPorRubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de vendedores del mes por subrubros
const loadVentasDeVendedoresDelMesPorSubrubrosStart = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorSubrubros: updateObject(state.ventasDeVendedoresDelMesPorSubrubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeVendedoresDelMesPorSubrubrosSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorSubrubros: updateObject(state.ventasDeVendedoresDelMesPorSubrubros, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeVendedoresDelMesPorSubrubrosFail = (state, error) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorSubrubros: updateObject(state.ventasDeVendedoresDelMesPorSubrubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeVendedoresDelMesPorSubrubros = (state) => {
  return updateObject(state, {
    ventasDeVendedoresDelMesPorSubrubros: updateObject(state.ventasDeVendedoresDelMesPorSubrubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load evolucion ventas de vendedores por vendedores
const loadEvolucionVentasDeVendedoresPorVendedorStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeVendedoresPorVendedor: updateObject(state.evolucionVentasDeVendedoresPorVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeVendedoresPorVendedorSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeVendedoresPorVendedor: updateObject(state.evolucionVentasDeVendedoresPorVendedor, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeVendedoresPorVendedorFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeVendedoresPorVendedor: updateObject(state.evolucionVentasDeVendedoresPorVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeVendedoresPorVendedor = (state) => {
  return updateObject(state, {
    evolucionVentasDeVendedoresPorVendedor: updateObject(
      state.evolucionVentasDeVendedoresPorVendedor,
      initialState.evolucionVentasDeVendedoresPorVendedor
    )
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECCION_VENDEDOR_ANALISIS_VENDEDORES:
      return { ...state, vendedorSelected: action.vendedor };

    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR:
      return { ...state, dataVentasDelMesDelVendedorPorSelected: action.selectionId };

    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR:
      return { ...state, fechaVentasDelMesAnioDelVendedor: { mes: action.mes, anio: action.anio } };

    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR:
      return { ...state, dataVentasDelMesDeVendedoresPorSelected: action.selectionId };

    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES:
      return { ...state, fechaVentasDelMesAnioDeVendedores: { mes: action.mes, anio: action.anio } };

    case actionTypes.SELECCION_DATA_EVOLUCION_VENTAS_DEL_VENDEDOR:
      return { ...state, dataEvolucionVentasDelVendedorSelected: action.selectionId };

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START:
      return loadEvolucionDeVentasMensualDelVendedorStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS:
      return loadEvolucionDeVentasMensualDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL:
      return loadEvolucionDeVentasMensualDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR:
      return clearEvolucionDeVentasMensualDelVendedor(state);

    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START:
      return loadVentasDelMesPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR:
      return clearVentasDelMesPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START:
      return loadVentasDelMesPorMarcaDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorMarcaDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorMarcaDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR:
      return clearVentasDelMesPorMarcaDelVendedor(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START:
      return loadVentasDelMesPorRubroDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorRubroDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorRubroDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR:
      return clearVentasDelMesPorRubroDelVendedor(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START:
      return loadVentasDelMesPorSubrubroDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorSubrubroDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorSubrubroDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR:
      return clearVentasDelMesPorSubrubroDelVendedor(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START:
      return loadVentasDelMesPorProvinciasDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorProvinciasDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorProvinciasDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR:
      return clearVentasDelMesPorProvinciasDelVendedor(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START:
      return loadVentasDelMesPorZonasDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorZonasDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorZonasDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR:
      return clearVentasDelMesPorZonasDelVendedor(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START:
      return loadEvolucionVentasDelVendedorPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadEvolucionVentasDelVendedorPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.fechas,
        action.total
      );
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE:
      return clearEvolucionVentasDelVendedorPorCategoriaDeCliente(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START:
      return loadEvolucionVentasDelVendedorPorProvinciasStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS:
      return loadEvolucionVentasDelVendedorPorProvinciasSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL:
      return loadEvolucionVentasDelVendedorPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS:
      return clearEvolucionVentasDelVendedorPorProvincias(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START:
      return loadEvolucionVentasDelVendedorPorZonasStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS:
      return loadEvolucionVentasDelVendedorPorZonasSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL:
      return loadEvolucionVentasDelVendedorPorZonasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS:
      return clearEvolucionVentasDelVendedorPorZonas(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START:
      return loadEvolucionVentasDelVendedorPorMarcaStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS:
      return loadEvolucionVentasDelVendedorPorMarcaSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL:
      return loadEvolucionVentasDelVendedorPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA:
      return clearEvolucionVentasDelVendedorPorMarca(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START:
      return loadEvolucionVentasDelVendedorPorRubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS:
      return loadEvolucionVentasDelVendedorPorRubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL:
      return loadEvolucionVentasDelVendedorPorRubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO:
      return clearEvolucionVentasDelVendedorPorRubro(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START:
      return loadEvolucionVentasDelVendedorPorSubrubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS:
      return loadEvolucionVentasDelVendedorPorSubrubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL:
      return loadEvolucionVentasDelVendedorPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO:
      return clearEvolucionVentasDelVendedorPorSubrubro(state);

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START:
      return loadEvolucionDeVentasMensualDeVendedoresStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS:
      return loadEvolucionDeVentasMensualDeVendedoresSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL:
      return loadEvolucionDeVentasMensualDeVendedoresFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES:
      return clearEvolucionDeVentasMensualDeVendedores(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_START:
      return loadVentasPorVendedorPorMarcaSumburstFormatStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_SUCCESS:
      return loadVentasPorVendedorPorMarcaSumburstFormatSuccess(
        state,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_FAIL:
      return loadVentasPorVendedorPorMarcaSumburstFormatFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT:
      return clearVentasPorVendedorPorMarcaSumburstFormat(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START:
      return loadVentasPorVendedorPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS:
      return loadVentasPorVendedorPorCategoriaDeClienteSuccess(
        state,
        action.vendedores,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL:
      return loadVentasPorVendedorPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES:
      return clearVentasPorVendedorPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_START:
      return loadVentasPorVendedorPorMarcaStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUCCESS:
      return loadVentasPorVendedorPorMarcaSuccess(
        state,
        action.vendedores,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_FAIL:
      return loadVentasPorVendedorPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES:
      return clearVentasPorVendedorPorMarca(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_START:
      return loadVentasPorVendedorPorRubroStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_SUCCESS:
      return loadVentasPorVendedorPorRubroSuccess(
        state,
        action.vendedores,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_FAIL:
      return loadVentasPorVendedorPorRubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES:
      return clearVentasPorVendedorPorRubro(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_START:
      return loadVentasPorVendedorPorSubrubroStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_SUCCESS:
      return loadVentasPorVendedorPorSubrubroSuccess(
        state,
        action.vendedores,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_FAIL:
      return loadVentasPorVendedorPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES:
      return clearVentasPorVendedorPorSubrubro(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_START:
      return loadVentasPorVendedorPorProvinciaStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_SUCCESS:
      return loadVentasPorVendedorPorProvinciaSuccess(state, action.ventas, action.fecha);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_FAIL:
      return loadVentasPorVendedorPorProvinciaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES:
      return clearVentasPorVendedorPorProvincia(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_START:
      return loadVentasPorVendedorPorZonaStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_SUCCESS:
      return loadVentasPorVendedorPorZonaSuccess(state, action.ventas, action.fecha);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_FAIL:
      return loadVentasPorVendedorPorZonaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES:
      return clearVentasPorVendedorPorZona(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_START:
      return loadVentasDeVendedoresDelMesPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadVentasDeVendedoresDelMesPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadVentasDeVendedoresDelMesPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE:
      return clearVentasDeVendedoresDelMesPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_START:
      return loadVentasDeVendedoresDelMesPorVendedoresStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_SUCCESS:
      return loadVentasDeVendedoresDelMesPorVendedoresSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_FAIL:
      return loadVentasDeVendedoresDelMesPorVendedoresFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR:
      return clearVentasDeVendedoresDelMesPorVendedores(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_START:
      return loadVentasDeVendedoresDelMesPorProvinciasStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_SUCCESS:
      return loadVentasDeVendedoresDelMesPorProvinciasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_FAIL:
      return loadVentasDeVendedoresDelMesPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA:
      return clearVentasDeVendedoresDelMesPorProvincias(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_START:
      return loadVentasDeVendedoresDelMesPorZonasStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_SUCCESS:
      return loadVentasDeVendedoresDelMesPorZonasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_FAIL:
      return loadVentasDeVendedoresDelMesPorZonasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA:
      return clearVentasDeVendedoresDelMesPorZonas(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_START:
      return loadVentasDeVendedoresDelMesPorMarcasStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_SUCCESS:
      return loadVentasDeVendedoresDelMesPorMarcasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_FAIL:
      return loadVentasDeVendedoresDelMesPorMarcasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA:
      return clearVentasDeVendedoresDelMesPorMarcas(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_START:
      return loadVentasDeVendedoresDelMesPorRubrosStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_SUCCESS:
      return loadVentasDeVendedoresDelMesPorRubrosSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_FAIL:
      return loadVentasDeVendedoresDelMesPorRubrosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO:
      return clearVentasDeVendedoresDelMesPorRubros(state);

    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_START:
      return loadVentasDeVendedoresDelMesPorSubrubrosStart(state);
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_SUCCESS:
      return loadVentasDeVendedoresDelMesPorSubrubrosSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_FAIL:
      return loadVentasDeVendedoresDelMesPorSubrubrosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO:
      return clearVentasDeVendedoresDelMesPorSubrubros(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_START:
      return loadEvolucionVentasDeVendedoresPorVendedorStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_SUCCESS:
      return loadEvolucionVentasDeVendedoresPorVendedorSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_FAIL:
      return loadEvolucionVentasDeVendedoresPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR:
      return clearEvolucionVentasDeVendedoresPorVendedor(state);

    default:
      return state;
  }
};

export default reducer;
