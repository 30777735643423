import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import ColumnChart from '../../../../Charts/ColumnChart';
import moment from 'moment';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SeleccionePeriodos from '../../../../../assets/images/SeleccionePeriodos.png';
import TablaComparacionUtilidadPorSeleccion from '../TablaComparacionUtilidadPorSeleccion/TablaComparacionUtilidadPorSeleccion';

const BarComparacionPeriodosUtilidad = (props) => {
  const { utilidadesComparar, loading, utilidadesDe } = props;

  const [showTable, setShowTable] = useState(false);
  const [flagTable, setFlagTable] = useState(false);
  const [, setCategoriasCompacion] = useState({
    id: '',
    nombre: ''
  });
  const [fechasComparacion, setFechasComparacion] = useState([]);
  const [titleCategorySelected, setTitleCategorySelected] = useState('');

  useEffect(() => {
    onLimpiarChartClick();
  }, [sessionStorage.getItem('empresaId'), utilidadesDe]);

  const onBarClick = (config) => {
    if (titleCategorySelected === config.w.globals.categoryLabels[config.dataPointIndex]) {
      return;
    }

    setTitleCategorySelected(config.w.globals.categoryLabels[config.dataPointIndex]);
    if (fechasComparacion.length === 2) {
      fechasComparacion.splice(0, 2);
    }

    const armarCategorias = {
      id: utilidadesComparar[0].utilidades[config.dataPointIndex].id,
      nombre: utilidadesComparar[0].utilidades[config.dataPointIndex].nombre
    };

    utilidadesComparar.map((gasto) => fechasComparacion.push(gasto.dateComplete));
    setCategoriasCompacion(armarCategorias);
    setFechasComparacion(fechasComparacion);
    showTable ? setFlagTable(!flagTable) : setShowTable(true);

    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onLimpiarChartClick = () => {
    setFlagTable(false);
    props.onLimpiarComparacionClick();
    setCategoriasCompacion({
      id: '',
      nombre: ''
    });
    setFechasComparacion([]);
    setShowTable(false);
  };

  return (
    <Fragment>
      {(utilidadesComparar.length > 0 || loading) && (
        <ColumnChart
          title={
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}
            >
              <div>{`Comparativa de utilidades por ${props.utilidadesDe.toLowerCase()} entre dos periodos seleccionados`}</div>
              {utilidadesComparar.length > 0 && (
                <div style={{ paddingRight: '10px' }}>
                  <Tooltip title="Limpiar comparación">
                    <IconButton onClick={() => onLimpiarChartClick()} size="small">
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          }
          customSinDataImg={SeleccionePeriodos}
          customSinDataImgWidth={300}
          series={
            utilidadesComparar.length > 0 &&
            utilidadesComparar
              .sort((a, b) => {
                return new Date(a.dateComplete) - new Date(b.dateComplete);
              })
              .map((utilidad) => {
                return {
                  name: moment(utilidad.dateComplete).subtract(1, 'month').format('MM/YYYY'),
                  data: utilidad.utilidades.map((utilidadesDetail) => utilidadesDetail.monto)
                };
              })
          }
          categories={
            utilidadesComparar.length > 0 &&
            utilidadesComparar[0].utilidades.map((utilidad) => {
              return utilidad.nombre.trim().split(' ');
            })
          }
          onPorcionClick={(config) => utilidadesComparar.length === 2 && onBarClick(config)}
          loading={loading}
          imgSinDatos={utilidadesComparar.length === 0}
          height={350}
          width={'100%'}
          xaxisLabelsShow
          xaxisLabelsTrim={false}
          tooltipXShow
          legendStyleFontSize={'10px'}
          customMarginTop={'0px'}
          legendShowForSingleSeries
          legendShowForNullSeries
          legendShowForZeroSeries
          legendOffSetY={20}
          legendHeight={50}
          maxWidthImgSinResult={330}
          legendPosition={'bottom'}
        />
      )}
      <div id="#divider" style={{ height: '10px' }} />
      {utilidadesComparar.length === 2 && (
        <TablaComparacionUtilidadPorSeleccion
          utilidadesComparar={utilidadesComparar}
          loadDetallesKey={props.loadDetallesKey}
          utilidadesDe={utilidadesDe}
          dataSelectionId={props.dataSelectionId}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowDetalleCategoriaModal: (show, title, body) => dispatch(actions.showGlobalModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarComparacionPeriodosUtilidad);
