import React, { useCallback, useEffect, useRef, useState } from 'react';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classes from './TablaComparacionVentasPorSeleccion.css';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MTableBodyRow } from 'material-table';
import TablaComparacionVentasToExcel from '../TablaComparacionVentasDeLaSeleccion/TablaComparacionVentasToExcel';
import { compareByAsc, compareByDesc } from '../../../../shared/sorting';
import TablaComparacionVentasPorPeriodosDeLaSeleccion from '../TablaComparacionVentasDeLaSeleccion/TablaComparacionVentasPorPeriodosDeLaSeleccion';

const TablaComparacionVentasPorPeriodos = (props) => {
  const { fechasComparar = [], ventasComparar, ventasDe, showMonto,
    dataSelectionId, dataOptions, dataSelectOptions } = props;
  const [tableState, setTableState] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'nombre', direction: 'asc' });
  const [searchState, setSearchState] = useState('');
  const headersBackgroundColor = 'rgb(250, 250, 250)';
  const tableRef = useRef();

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  useEffect(() => {
    setTableState([
      { dateComplete: ventasComparar[0].dateComplete, ventasDetallada: ventasComparar[0].ventas },
      { dateComplete: ventasComparar[1].dateComplete, ventasDetallada: ventasComparar[1].ventas }
    ]);
  }, []);

  const columns = [
    {
      title: '',
      field: 'nombre',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.monto1)}` : `${formatNumberWithoutDecimals(rowData.monto1)}`)
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.monto2)}` : `${formatNumberWithoutDecimals(rowData.monto2)}`)
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.difMontos)}` : `${formatNumberWithoutDecimals(rowData.difMontos)}`)
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const [dataLoading, setDataLoading] = useState(false);
  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;

    const filterTableState =
      tableState.length === 2
        ? [
          {
            ...tableState[0],
            ventasDetallada: tableState[0].ventasDetallada.filter(
              (ventDet) =>
                ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
            )
          },
          {
            ...tableState[1],
            ventasDetallada: tableState[1].ventasDetallada.filter(
              (ventDet) =>
                ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
            )
          }
        ]
        : [];

    filterTableState.map((data, key) => {
      if (key === 0) {
        return data.ventasDetallada.map((ventaDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].ventasDetallada[index];

          const ventasDelIndex = ventasDetalldasDelIndex
            ? ventasDetalldasDelIndex
            : { ...ventaDetallada, monto: 0, porcentajeDeParticipacion: 0 };

          totalMonto1 += showMonto ? ventaDetallada.monto : ventaDetallada.unidadesVendidas;
          totalMonto2 += showMonto ? ventasDelIndex.monto : ventasDelIndex.unidadesVendidas;

          return formatTableData.push({
            id: ventaDetallada.id,
            nombre: ventaDetallada.nombre,
            monto1: showMonto ? ventaDetallada.monto : ventaDetallada.unidadesVendidas,
            porcParticipacion1: showMonto ? ventaDetallada.porcentajeDeParticipacion : ventaDetallada.porcentajeDeParticipacionUnidades,
            monto2: showMonto ? ventasDelIndex.monto : ventasDelIndex.unidadesVendidas,
            porcParticipacion2: showMonto ? ventasDelIndex.porcentajeDeParticipacion : ventasDelIndex.porcentajeDeParticipacionUnidades,
            difMontos: showMonto ? calcularDif(ventaDetallada.monto, ventasDelIndex.monto) : calcularDif(ventaDetallada.unidadesVendidas, ventasDelIndex.unidadesVendidas),
            porcComparacion: showMonto ? calcularPorc(ventaDetallada.monto, ventasDelIndex.monto) : calcularPorc(ventaDetallada.unidadesVendidas, ventasDelIndex.unidadesVendidas),
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        nombre: 'TOTAL GENERAL',
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });

    return formatTableData;
  };

  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = useCallback((sortKey) => {
    setDataLoading(true);
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
    setTimeout(() => setDataLoading(false), 500);
  }, [sortConfig]);

  const handleSearchChange = (e) => {
    setSearchState(e.target.value.toLowerCase());
  };

  const getHeaderTitle = (venta) => {
    let fecha = venta.dateComplete;
    let fechaDesde = fecha.desde < 10 ? `0${fecha.desde}` : `${fecha.desde}`;
    let fechaHasta = fecha.hasta < 10 ? `0${fecha.hasta}` : `${fecha.hasta}`;
    let labelName = fecha.desde === fecha.hasta
      ? fecha.anio === fecha.anioHasta ? `${fechaDesde}/${fecha.anio}` : `${fechaDesde}/${fecha.anio}-${fechaHasta}/${fecha.anioHasta}`
      : fecha.anio === fecha.anioHasta ? `${fechaDesde}-${fechaHasta}/${fecha.anio}` : `${fechaDesde}/${fecha.anio}-${fechaHasta}/${fecha.anioHasta}`;

    return labelName;
  }

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  const getCurrentLevel = () => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectionId);
    return item;
  }

  const getNextLevel = () => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectionId);
    return item ? item.subNivel : undefined;
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '10px', marginLeft: '20px' }}>
          {`Comparativa de ventas por ${ventasDe.toLowerCase()} entre dos periodos seleccionados`}
        </div>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Buscar"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        {props.exportToExcel && (
          <TablaComparacionVentasToExcel
            ventasPor={`${ventasDe}`}
            comprobantes={data()}
            showMonto={showMonto}
            fechas={[
              getHeaderTitle(ventasComparar[0]),
              getHeaderTitle(ventasComparar[1])
            ]}
          />
        )}
      </div>
      <Table
        loading={dataLoading}
        columns={columns}
        tableRef={tableRef}
        data={data()}
        noBoxShadow
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  />
                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  />
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {getHeaderTitle(ventasComparar[0])}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {getHeaderTitle(ventasComparar[1])}
                  </TableCell>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                    align="center"
                  />
                  <TableCell style={{ paddingBottom: '5px', width: '170px' }} />
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  />
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('nombre')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      width: window.screen.width < 1367 ? '265px' : '410px',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="left"
                  >
                    {`${ventasDe}`}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    {showMonto ? 'Monto' : 'Unidades Vendidas'}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto2')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    {showMonto ? 'Monto' : 'Unidades Vendidas'}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion2')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('difMontos')}
                    style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                    align="right"
                  >
                    {showMonto ? 'Dif. Montos' : 'Dif. Unid. Vendidas'}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcComparacion')}
                    style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                    align="right"
                  >
                    Porc. Comparación
                  </TableCell>
                </TableRow>
              </TableHead>
            );
          },
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
                detailPanel={
                  !props.data.id
                    ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                    : props.detailPanel
                }
              />
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            render: (rowData) => {
              return (
                <TablaComparacionVentasPorPeriodosDeLaSeleccion
                  endRequest={() => autoScroll(rowData)}
                  fechasComparar={[ventasComparar[0].dateComplete, ventasComparar[1].dateComplete]}
                  titleCategory={rowData.nombre}
                  ventasDe={ventasDe}
                  itemExpanded={rowData}
                  dataOptions={dataOptions}
                  dataSelectOptions={dataSelectOptions}
                  dataSelectionId={dataSelectionId}
                  showMonto={showMonto}
                  incluirUnidades={true}
                  withoutDecimals={props.withoutDecimals}
                  currentLevel={getCurrentLevel()}
                  nextLevel={getNextLevel()}
                  filtrosParent={[]}
                />
              );
            }
          }
        ]}
        customOptions={{
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          maxBodyHeight: 630,
          showTitle: false,
          tableLayout: 'fixed',
          toolbar: false,
          rowStyle: (rowData, index) => {
            return {
              cursor: 'auto',
              backgroundColor: (!rowData.id && '#fbb040') || (!isEven(index) && '#f2f2f2')
            };
          },
          cellStyle: (cellData) => ({
            fontWeight: cellData === 'TOTAL GENERAL' && 'bolder'
          })
        }}
      />
    </div>
  );
};

export default TablaComparacionVentasPorPeriodos;