import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';
import { MTableHeader } from 'material-table';
import ModalDetallesComprobante from '../ModalDetallesComprobante/ModalDetallesComprobante';

const TableComprobantesUtilidad = (props) => {
  const [comprobantesState, setComprobantesState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    props.onLoadComprobantes(
      props.vendedorId,
      props.articuloId,
      props.clienteId,
      props.mes,
      props.anio,
      (comprobantes) => {
        setComprobantesState(comprobantes);
        setLoading(false);
      },
      () => {
        setComprobantesState([]);
        setLoading(false);
      }
    );
  }, []);

  const headersStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: 'rgb(205, 205, 205)'
  };

  const cellStyles = (value) => {
    return {
      fontSize: 'smaller',
      paddingBottom: '0px',
      paddingTop: '0px',
      paddingLeft: '5px',
      paddingRight: '5px',
      color: value < 0 && 'red'
    };
  };

  const columns = [
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyles(),
      headerStyle: headersStyle,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyles(),
      headerStyle: headersStyle
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'left',
      cellStyle: cellStyles(),
      headerStyle: headersStyle
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyles(),
      headerStyle: headersStyle
    },
    {
      title: `Ventas`,
      field: 'venta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.venta)}`
    },
    {
      title: `Costos`,
      field: 'costo',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.costo)}`
    },
    {
      title: `Porc. Útil. S/Costo`,
      field: 'utilidadPorcSegunCosto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunCosto)}%`
    },
    {
      title: `Porc. Útil. S/Venta`,
      field: 'utilidadPorcSegunVenta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunVenta)}%`
    },
    {
      title: `Porc. Participación`,
      field: 'porcUtilidadSobreElTotal',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.porcUtilidadSobreElTotal)}%`
    },
    {
      title: `Utilidad`,
      field: 'utilidad',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.utilidad)}`
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'center',
      cellStyle: cellStyles(),
      headerStyle: headersStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante venta comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }} />;
      }
    }
  ];

  const minimalColumns = [
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyles(),
      headerStyle: headersStyle,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyles(),
      headerStyle: headersStyle
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'left',
      cellStyle: cellStyles(),
      headerStyle: headersStyle
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyles(),
      headerStyle: headersStyle
    },
    {
      title: `Porc. Participación`,
      field: 'porcUtilidadSobreElTotal',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.porcUtilidadSobreElTotal)}%`
    },
    {
      title: `Utilidad`,
      field: 'utilidad',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData),
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.utilidad)}`
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'center',
      cellStyle: cellStyles(),
      headerStyle: headersStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante venta comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }} />;
      }
    }
  ];

  const data = () => {
    return comprobantesState.map((comprobante) => ({
      idComproba: comprobante.idComproba,
      idSistema: comprobante.idSistema,
      idSucursal: comprobante.idSucursal,
      fecha: comprobante.fecha,
      comprobante: comprobante.comprobante,
      referencia: comprobante.referencia,
      numeroExterno: comprobante.numeroExterno,
      name: comprobante.nombre,
      utilidad: comprobante.monto,
      costo: comprobante.costo,
      venta: comprobante.ventas,
      cantidadVendida: comprobante.unidadesVendidas,
      utilidadPorcSegunCosto: comprobante.utilidadPorcentualSegunCosto,
      utilidadPorcSegunVenta: comprobante.utilidadPorcentualSegunVentas,
      porcUtilidadSobreElTotal: comprobante.porcentajeUtilidadSobreElTotal
    }));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Table
        columns={props.minimalInfo ? minimalColumns : columns}
        style={{ width: props.customWidth ? props.customWidth : '100%', marginTop: 0, marginBottom: '15px' }}
        data={data()}
        noBoxShadow
        loading={loading}
        components={{
          Header: (props) => (
            <MTableHeader {...props}>
              {(props.columns[0].tableData.width = '20px')}
              {(props.columns[1].tableData.width = '250px')}
              {(props.columns[2].tableData.width = '20px')}
            </MTableHeader>
          )
        }}
        customOptions={{
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          toolbar: false,
          showTitle: false,
          detailPanelType: 'single',
          detailPanelColumnAlignment: 'right',
          paging: false,
          maxBodyHeight: 200
        }}
        tableKey={"tabla-comprobantes-de-utilidad"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadComprobantes: (idVendedor, idArticulo, idCliente, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadUtilidadDelArticuloAlCliente(idVendedor, idArticulo, idCliente, mes, anio, success, errorFail)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComprobantesUtilidad);
