import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import CustomPivotGrid from '../../../../UI/CustomPivotGrid/CustomPivotGrid';
import { storageEmpresaId, storageMonedaSigno, storageUsuarioId } from '../../../../../shared/sessionData';
import { Typography, Grid } from '@material-ui/core';
import FiltroInformeUno from './FiltroInformeUno';
import { formatNumberWithoutDecimals } from '../../../../../shared/formatNumber';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { budgetFormat } from '../../../../../shared/pivotGridDefaults/budgetFormat';

const PivotGridInformeUno = (props) => {
  const { loading, datos, config, onLoadData, anioInforme,
    filtros, aprobado, budgetAnterior, onUpdateInformeUnoData,
    onUpdateConfigFields } = props;
  const [signoMoneda] = useState(storageMonedaSigno());
  const [storageKey] = useState("ventas-informe-uno-pivot-grid");
  const [budgetConfig] = useState(budgetFormat(storageMonedaSigno()));
  const [customLoading, setCustomLoading] = useState(false);
  const [nombreFormato, setNombreFormato] = useState("ÚLTIMO FORMATO UTILIZADO");
  const [configFields, setConfigFields] = useState();
  const [rowsColumnsFixed, setRowsColumnsFixed] = useState(true);
  const [gridContainerOverflowX, setGridContainerOverflowX] = useState("hidden");
  const [selectedTotalRows, setSelectedTotalRows] = useState([]);
  const rowFieldsFromDataSource = useRef();
  const [scrollingMode, setScrollingMode] = useState("virtual");

  useEffect(() => {
    if (!config && !loading) {
      let fields = budgetConfig;
      let dataStorage = localStorage.getItem(storageKey);

      if (dataStorage) {
        let configStorage = JSON.parse(dataStorage);

        if (configStorage && configStorage.fields && configStorage.fields.length > 0)
          fields = configStorage.fields;
      }

      onCargarFormato(fields, "ÚLTIMO FORMATO UTILIZADO");
    }
  }, [config, loading]);

  const title = () => {
    return (
      <Typography variant="h5">
        {`INFORME 1: ${nombreFormato}`}
      </Typography>
    )
  }

  const subTitle = () => {
    return (
      <Fragment>
        <Grid item xs={12} container spacing={1} justifyContent="flex-start">
          <FiltroInformeUno
            aplicarFiltros={onAplicarFiltroClick}
          />
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent="flex-start">
          <Grid item xs={12}>
            {!aprobado && (
              <Typography variant="subtitle1" color="error">
                El budget/forecast asociado no está aprobado.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {budgetAnterior && anioInforme && (
              <Typography variant="subtitle1" color="error">
                El budget/forecast asociado es del año: {anioInforme}.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const onCellClick = (e) => { }
  const rawData = useMemo(() => { return datos; }, [datos]);
  const data = useCallback(() => { return rawData; }, [rawData]);

  const rawFields = useMemo(() => {
    return config && config.map((field) => {
      let esPorcentaje = field.name && field.name.indexOf('porc') > -1;
      let esDifComparacion = field.name && field.name.indexOf('DiferenciaComparacion') > -1;
      let esShare = field.name && field.name.indexOf('share') > -1;
      let esEvolucion = field.name && field.name.indexOf('evolucion') > -1;
      let item = { ...field };
      let fieldFormat = budgetConfig.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (fieldFormat) {
        if (fieldFormat.caption)
          item["caption"] = fieldFormat.caption;

        if (fieldFormat.isMeasure !== undefined)
          item["isMeasure"] = fieldFormat.isMeasure;

        if (fieldFormat.format !== undefined)
          item["format"] = fieldFormat.format;

        if (fieldFormat.allowFiltering !== undefined)
          item["allowFiltering"] = fieldFormat.allowFiltering;

        if (fieldFormat.allowSorting !== undefined)
          item["allowSorting"] = fieldFormat.allowSorting;

        if (fieldFormat.allowSortingBySummary !== undefined)
          item["allowSortingBySummary"] = fieldFormat.allowSortingBySummary;
        if (fieldFormat.customizeText !== undefined)
          item["customizeText"] = fieldFormat.customizeText;
      }

      if (esPorcentaje) {
        item["summaryType"] = 'custom';

        let esPorcUnidades = field && field.name === 'porcUnidades';
        let esPorcNeto = field && field.name === 'porcNeto';
        let esPorcBruto = field && field.name === 'porcBruto';

        if (esPorcUnidades) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { diferencia: 0, estimado: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.diferencia += options.value.unidadesDiferencia;
                options.totalValue.estimado += options.value.unidadesEstimadas;
                break;
              case "finalize":
                if (options.totalValue.estimado === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (options.totalValue.diferencia / options.totalValue.estimado);
                }
            }
          }
        } else if (esPorcBruto) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { diferencia: 0, estimado: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.diferencia += options.value.brutoDiferencia;
                options.totalValue.estimado += options.value.brutoEstimado;
                break;
              case "finalize":
                if (options.totalValue.estimado === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (options.totalValue.diferencia / options.totalValue.estimado);
                }
            }
          }
        } else if (esPorcNeto) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { diferencia: 0, estimado: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.diferencia += options.value.netoDiferencia;
                options.totalValue.estimado += options.value.netoEstimado;
                break;
              case "finalize":
                if (options.totalValue.estimado === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (options.totalValue.diferencia / options.totalValue.estimado);
                }
            }
          }
        }
      }

      if (esDifComparacion) {
        item["summaryType"] = 'custom';

        if (item["dataField"])
          delete item["dataField"];

        let esDifCompUnidades = field && field.name === 'unidadesDiferenciaComparacion';
        let esDifCompNeto = field && field.name === 'netoDiferenciaComparacion';
        let esDifCompBruto = field && field.name === 'brutoDiferenciaComparacion';

        if (esDifCompUnidades) {
          if (item["customizeText"])
            delete item["customizeText"];

          item["format"] = ',##0';
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.unidadesTotales;
                options.totalValue.totalComparacion += options.value.unidadesTotalComparacion;
                break;
              case "finalize":
                options.totalValue = (options.totalValue.totalActual - options.totalValue.totalComparacion);
            }
          }
        } else if (esDifCompNeto) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.netoTotal;
                options.totalValue.totalComparacion += options.value.netoTotalComparacion;
                break;
              case "finalize":
                options.totalValue = (options.totalValue.totalActual - options.totalValue.totalComparacion);
            }
          }
        } else if (esDifCompBruto) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.brutoTotal;
                options.totalValue.totalComparacion += options.value.brutoTotalComparacion;
                break;
              case "finalize":
                options.totalValue = (options.totalValue.totalActual - options.totalValue.totalComparacion);
            }
          }
        }
      }

      if (esShare) {
        item["summaryType"] = 'custom';
        let esShareUnidades = field && field.name === 'shareUnidades';
        let esShareNeto = field && field.name === 'shareNeto';

        if (esShareUnidades) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { share: 0, total: 0 };
                break;
              case "calculate":
                options.totalValue.share += options.value.unidadesTotales;
                options.totalValue.total = options.value.totalUnidades;
                break;
              case "finalize":
                if (options.totalValue.share === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (options.totalValue.share / options.totalValue.total);
                }
            }
          }
        } else if (esShareNeto) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { share: 0, total: 0 };
                break;
              case "calculate":
                options.totalValue.share += options.value.netoTotal;
                options.totalValue.total = options.value.totalNeto;
                break;
              case "finalize":
                if (options.totalValue.share === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (options.totalValue.share / options.totalValue.total);
                }
            }
          }
        }
      }

      if (esEvolucion) {
        item["summaryType"] = 'custom';
        let esEvolucionUnidades = field && field.name === 'evolucionUnidades';
        let esEvolucionNeto = field && field.name === 'evolucionNeto';

        if (esEvolucionUnidades) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.totalActual += options.value.unidadesTotales;
                options.totalValue.totalComparacion += options.value.unidadesTotalComparacion;
                break;
              case "finalize":
                const diferencia = options.totalValue.totalActual - options.totalValue.totalComparacion;
                if (options.totalValue.totalComparacion === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (diferencia / options.totalValue.totalComparacion);
                }
            }
          }
        } else if (esEvolucionNeto) {
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.totalActual += options.value.netoTotal;
                options.totalValue.totalComparacion += options.value.netoTotalComparacion;
                break;
              case "finalize":
                const diferencia = options.totalValue.totalActual - options.totalValue.totalComparacion;
                if (options.totalValue.totalComparacion === 0) {
                  options.totalValue = 0;
                } else {
                  options.totalValue = (diferencia / options.totalValue.totalComparacion);
                }
            }
          }
        }
      }

      item["hide"] = selectedTotalRows.find((row) => row.id === field.dataField) === undefined;

      return item;
    });
  }, [config, selectedTotalRows]);
  const fields = useCallback(() => { return rawFields; }, [rawFields]);

  const onAplicarFiltroClick = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta, anioComparacion) => {
    let localConfig = [];
    if (dataSource) {
      localConfig = dataSource.fields();
    } else {
      let item = localStorage.getItem(storageKey);
      localConfig = item ? JSON.parse(item).fields : config;
    }

    localStorage.removeItem(storageKey);

    let formato = processFormato(localConfig);

    const request = {
      anioFiscal: anioFiscal,
      anioComparacion: anioComparacion,
      idArchivo: idArchivo,
      mesFiscal: mesFiscal,
      acumulado,
      idEmpresa: storageEmpresaId(),
      idUsuario: storageUsuarioId(),
      importacion: false,
      desde,
      hasta
    };

    onLoadData(request, formato);
  }

  const customizeTooltip = (args) => {
    const valueText = args.seriesName.indexOf('%') !== -1
      ? `${formatNumberWithoutDecimals(args.originalValue)}%`
      : args.seriesName.indexOf('unidades') !== -1
        ? args.originalValue
        : `${signoMoneda} ${formatNumberWithoutDecimals(args.originalValue)}`;

    return {
      html: `${args.seriesName}<div style='text-align: center;'>${valueText}</div>`,
    };
  }

  const [isLoadingDataSource] = useState([false]);
  const dataSource = new PivotGridDataSource({
    fields: fields(),
    store: {
      type: "array",
      data: data()
    },
    onLoadingChanged: (isLoading) => {
      if (!isLoading) {
        const fields = dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'row');
        rowFieldsFromDataSource.current = fields;
      }
    },
    retrieveFields: false,
  });

  const onUpdateDataSource = () => {
  }

  const processFormato = (formato) => {
    formato && formato.forEach((field) => {
      let item = budgetConfig.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (item) {
        field["caption"] = item.caption;
        field["dataType"] = item.dataType;
        field["name"] = item.name;

        if (item.summaryType)
          field["summaryType"] = item.summaryType;

        if (!field.area)
          field["area"] = "filter";
      }
    });

    budgetConfig.forEach((field) => {
      let item = formato && formato.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (!item) {
        field.area = "filter";
        formato.push(field);
      }
    })

    return formato;
  }

  const onCargarFormato = useCallback((formato, nombreFormato, items = undefined) => {
    setNombreFormato(nombreFormato);
    formato = processFormato(formato);
    setEstaCargado(false);
    const datosCubo = items !== undefined ? items : datos;
    onUpdateInformeUnoData(datosCubo, formato, aprobado, budgetAnterior, filtros);
  }, [datos, aprobado, budgetAnterior, filtros]);

  //#region Overflow X

  const setOverflowX = (modoConfiguracion, fijarFilasColumnas) => {
    const overflowX = modoConfiguracion ? "auto" : fijarFilasColumnas ? "hidden" : "auto";
    setGridContainerOverflowX(overflowX);
  }

  //#endregion

  //#region Cambio Vista
  useEffect(() => {
    let rowFields = rowFieldsFromDataSource.current;

    if (rowFields && rowFields.length !== 0) {
      let expandedFields = rowFields.filter((field) => field.expanded);

      if (!expandedFields || expandedFields.length === 0) {
        let fieldName = rowFields[0].name;
        dataSource.field(fieldName, { expanded: true });
      }
    }
  }, [rowFieldsFromDataSource]);

  useEffect(() => {
    if (!configFields) {
      const item = props.configFields.find((c) => c.key === storageKey);
      const cf = item
        ? item.config
        : {
          showFields: false,
          visible: true,
          showRows: true,
          showFilter: false,
          showData: false,
          showColums: false
        };
      setConfigFields(cf);
    }
  }, [configFields, props.configFields, storageKey]);

  const orderedFields = useMemo(() => {
    return dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'filter')
      .map((field) => {
        return {
          caption: field.caption && field.caption.replace('|', '') && field.caption.replace('-', ''),
          name: field.name,
        }
      })
      .sort((a, b) => { return a.caption < b.caption ? -1 : 1; });
  }, [dataSource]);

  useEffect(() => {
    if (configFields && configFields.showFields && orderedFields && orderedFields.length > 0) {
      orderedFields.forEach((field, index) => { dataSource.field(field.name, { areaIndex: index }); });
      dataSource.load();
    }
  }, [configFields, orderedFields, dataSource]);

  const onViewChange = useCallback((config) => {
    setCustomLoading(true);
    let customConfig = {
      showFields: config.showFields,
      visible: true,
      showRows: true,
      showFilter: config.showFilter,
      showData: config.showData,
      showColums: config.showColums
    };
    setConfigFields(customConfig);
    onUpdateConfigFields(storageKey, customConfig);
    setOverflowX(customConfig.showFields, rowsColumnsFixed);
    setTimeout(() => setCustomLoading(false), 500);
  }, [storageKey, dataSource, rowsColumnsFixed]);
  //#endregion

  //#region Fijar Filas y Columnas
  const onFixRowsColumns = useCallback(() => {
    let value = !rowsColumnsFixed;

    if (value && configFields && configFields.showFields) {
      setConfigFields({
        showFields: false,
        showColums: false,
        showData: false,
        showFilter: false,
        showRows: true,
        visible: true,
      });
    }

    setCustomLoading(true);
    setRowsColumnsFixed(value);
    setOverflowX(configFields.showFields, value);
    let scroll = value ? "virtual" : "standard";
    setScrollingMode(scroll);
    setTimeout(() => setCustomLoading(false), 500);
  }, [rowsColumnsFixed, configFields]);
  //#endregion

  //#region Customization

  //#region On Cell Prepared
  const dataFieldsFromDatasource = dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'data');
  const isTotalDataRow = (cell) => (cell.area === 'data' && cell.rowType === 'T'
    && (cell.columnType === 'T' || cell.columnType === 'GT'));
  const lessThanCeroCell = (cell) => cell.area === 'data' && cell.rowType === 'D'
    && cell.columnType === 'GT' && cell.dataType === 'number' && cell.value && cell.value < 0;
  const isHeader = (cell) => cell.area === 'column' && cell.type === 'GT';
  const isTotalRow = (cell) => cell.area === 'row' && (cell.type === 'T' || cell.type === 'GT');
  const isGranTotal = (cell) => (cell.area === 'data' && cell.rowType === 'GT' && cell.columnType === 'GT')
    || (cell.area === 'column' && cell.type === 'GT' && cell.text === 'Gran Total');
  const isEstimatedCell = (cell) => {
    if (cell.dataIndex !== undefined && cell.area === "data" && cell.path === undefined) {
      const field = dataFieldsFromDatasource.find((f) => f.areaIndex === cell.dataIndex
        && (f.name === "unidadesEstimadas" || f.name === "netoEstimado" || f.name === "brutoEstimado"));
      return field !== null && field !== undefined;
    }

    return false;
  }

  const hideStyle = useMemo(() => { return { fill: 'FFF', font: 'FFF', bold: true, border: null, display: 'none' } }, []);
  const style = useMemo(() => { return { 'font-size': 'small', 'text-transform': 'uppercase' } }, []);

  const onCellPrepared = React.useCallback((e) => {
    const { area, cellElement, cell, columnIndex } = e;

    cell.area = area;

    if (cell.area === 'column' && cell.type === 'GT' && containsSpecialChars(cell.text)) {
      cell.text = removeSpecialChars(cell.text);
      cellElement.innerHTML = removeSpecialChars(cellElement.innerHTML);
      cellElement.innerText = removeSpecialChars(cellElement.innerText);
    }

    let defaultStyle = style;
    const appearance = getConditionalAppearance(cell, columnIndex, selectedTotalRows);

    if (appearance)
      defaultStyle = getCssStyles(appearance);

    if ((cell.path && isTotalRow(cell)) || (cell.rowPath && isTotalDataRow(cell))) {
      let rowFields = rowFieldsFromDataSource.current;
      let pathIndex = cell.path ? cell.path.length - 1 : cell.rowPath.length - 1;
      let rowField = rowFields.find((row) => row.areaIndex === pathIndex);

      if (rowField && rowField.hide)
        defaultStyle = hideStyle;
    }

    Object.assign(cellElement.style, defaultStyle);
  });

  const specialChars = ['| ', '- '];

  const containsSpecialChars = (cellText) => {
    let contains = false;

    if (cellText) {
      specialChars.forEach((item) => {
        if (cellText.includes(item))
          contains = true;
      })
    }
    return contains;
  }

  const removeSpecialChars = (cellText) => {
    if (cellText) {
      cellText = cellText.replace('| ', '');
      cellText = cellText.replace('- ', '');
    }

    return cellText;
  }

  function getCssStyles({ fill, font, bold, border }) {
    let style = {
      'background-color': `#${fill}`,
      color: `#${font}`,
      'font-weight': bold ? 'bold' : undefined,
      'font-size': 'smaller',
      'text-transform': 'uppercase',
    };

    if (border)
      style['border-color'] = `#${border}`;

    return style;
  }

  function getConditionalAppearance(cell, columnIndex, rows) {
    if (isGranTotal(cell) || isTotalRow(cell) || isTotalDataRow(cell))
      return { fill: 'ffbf66', font: '3F3F3F', bold: true, border: null };

    if (isHeader(cell))
      return { fill: 'dbf9ff', font: '3F3F3F', bold: true, border: null };

    if (lessThanCeroCell(cell))
      return { fill: 'ffafaf', font: '3F3F3F', bold: true, border: null };

    if (isEstimatedCell(cell))
      return { fill: 'DEF7E2', font: '006100', bold: false, border: null };

    return { fill: 'FFFFFF', font: '3F3F3F', bold: false, border: null };
  }
  //#endregion

  //#region Data Chart 
  const chartFields = useMemo(() => {
    return budgetConfig && budgetConfig
      .filter((f) => f.caption !== null && f.caption !== undefined)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [budgetConfig]);
  const chartOptions = chartFields && chartFields.filter((f) => f.area === "row");
  const chartSeries = chartFields && chartFields.filter((f) => f.area === "data");
  const unidadesItems = []
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('unidades') > -1 && f.name.indexOf('Total') > -1))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('unidades') > -1 && f.name.indexOf('Estimad') > -1))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('unidades') > -1 && f.name.indexOf('Diferencia') > -1));
  const brutosItems = []
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('bruto') > -1 && f.name.indexOf('Total') > -1))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('bruto') > -1 && f.name.indexOf('Estimad') > -1))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('bruto') > -1 && f.name.indexOf('Diferencia') > -1));
  const netosItems = []
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('neto') > -1 && f.name.indexOf('Total') > -1))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('neto') > -1 && f.name.indexOf('Estimad') > -1))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('neto') > -1 && f.name.indexOf('Diferencia') > -1));
  const chartFilters = [
    {
      id: 'unidades',
      showMonto: false,
      nombre: 'Unidades',
      seriesItems: unidadesItems,
      chartItems: unidadesItems,
      orderItems: chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('unidades') > -1)
    },
    {
      id: 'bruto',
      showMonto: true,
      nombre: 'Importes Brutos',
      seriesItems: brutosItems,
      chartItems: brutosItems,
      orderItems: chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('bruto') > -1)
    },
    {
      id: 'neto',
      showMonto: true,
      nombre: 'Importes Netos',
      seriesItems: netosItems,
      chartItems: netosItems,
      orderItems: chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('neto') > -1)
    },
  ];

  const chartConfig = {
    fields: chartFields,
    options: chartOptions,
    series: chartSeries,
    filters: chartFilters,
    data: datos,
    extraFilters: ['marca', 'categoria', 'tipoVenta'],
    relatedFilter: true,
  };

  //#endregion

  //#region Mostrart Total por row
  const [estaCargado, setEstaCargado] = useState(false);

  const onSelectedTotalRowsChange = useCallback((items) => {
    setCustomLoading(true);
    setSelectedTotalRows(items);
    props.onUpdateSelectedTotalRows(storageKey, items);
    setTimeout(() => setCustomLoading(false), 500);
  }, [storageKey]);

  useEffect(() => {
    if (props.selectedTotalRowsItems && props.selectedTotalRowsItems.length > 0 && !estaCargado) {
      const item = props.selectedTotalRowsItems.find((i) => i.key === storageKey);

      if (item) {
        onSelectedTotalRowsChange(item.rows);
        setEstaCargado(true);
      }
    }
  }, [props.selectedTotalRowsItems, estaCargado]);

  //#endregion

  //#region On Content Ready 
  const dataConfigFields = useMemo(() => { return budgetConfig.filter((item) => item.area === "data"); }, [budgetConfig]);

  const onContentReady = useCallback((e) => {
    let ds = e.component.getDataSource();
    let rowFields = ds.fields() && ds.fields().filter((item) => item.area === "row");
    rowFieldsFromDataSource.current = rowFields;

    if (rowFields && rowFields.length > 0 && dataConfigFields && dataConfigFields.length > 0) {
      let reload = false;

      rowFields.forEach((field) => {
        const dataField = dataConfigFields.find((item) => item.name === field.name);

        if (dataField) {
          ds.field(field.name, { area: "filter" });

          if (!reload)
            reload = true;
        }
      });

      if (reload)
        ds.load();
    }

    isLoadingDataSource[0] = false;
  }, [dataConfigFields]);

  //#endregion

  //#endregion

  return (
    <CustomPivotGrid
      title={() => title()}
      subTitle={() => subTitle()}
      dataSource={dataSource}
      loading={loading || customLoading}
      rawDatos={datos}
      rawFiltros={filtros}
      resetButton={false}
      exportToExcel={true}
      exportToPdf={true}
      fileName={'Informe 1'}
      sheetName={"Informe1"}
      storageKey={storageKey}
      onCellClick={onCellClick}
      onCellPrepared={onCellPrepared}
      showConfig={true}
      customizeTooltip={customizeTooltip}
      onUpdateDataSource={onUpdateDataSource}
      onCargarFormato={onCargarFormato}
      configFields={configFields}
      onContentReady={onContentReady}
      chartConfig={chartConfig}
      onViewChange={onViewChange}
      showChart={true}
      showChartIcon={false}
      rowsColumnsFixed={rowsColumnsFixed}
      onFixRowsColumns={onFixRowsColumns}
      height={rowsColumnsFixed ? "750px" : "100%"}
      containerWidth={rowsColumnsFixed ? window.innerWidth < 1350 ? "92.5vw" : window.innerWidth < 1900 ? "93.5vw" : "94.5vw" : "100%"}
      gridWidth={"100%"}
      minHeight={450}
      gridContainerOverflowX={gridContainerOverflowX}
      onLoadDataImport={props.onLoadDataImport}
      selectedTotalRows={selectedTotalRows}
      onSelectedTotalRowsChange={onSelectedTotalRowsChange}
      totalRowsOptions={rowFieldsFromDataSource && rowFieldsFromDataSource.current}
      isLoadingDataSource={isLoadingDataSource && isLoadingDataSource[0]}
      scrollingMode={scrollingMode}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.informeUnoData.loading,
    error: state.tenacta.informeUnoData.error,
    estaCargado: state.tenacta.informeUnoData.estaCargado,
    datos: state.tenacta.informeUnoData.datos,
    config: state.tenacta.informeUnoData.config,
    aprobado: state.tenacta.informeUnoData.aprobado,
    budgetAnterior: state.tenacta.informeUnoData.esAnterior,
    filtros: state.tenacta.informeUnoFiltros,
    configFields: state.tenacta.pivotGridConfiguracionFields.items,
    anioInforme: state.tenacta.informeUnoData.anioInforme,
    selectedTotalRowsItems: state.tenacta.selectedTotalRows.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (request, config) => dispatch(actions.loadInformeUnoData(request, undefined, undefined, config)),
    onUpdateInformeUnoData: (data, config, aprobado, esAnterior, filtros) =>
      dispatch(actions.updateInformeUnoData(data, config, aprobado, esAnterior, filtros)),
    onUpdateConfigFields: (key, config) => dispatch(actions.updateConfiguracionFields(key, config)),
    onLoadDataImport: (request, success, fail) => dispatch(actions.loadInformeUnoData(request, success, fail)),
    onUpdateSelectedTotalRows: (key, rows) => dispatch(actions.updateSelectedTotalRows(key, rows)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PivotGridInformeUno);