const findProduct = (arr, row) => {
  return arr.find(p => p.idArticulo === row.A.toString().trim());
}

const createProduct = (data) => {
  return {
    affectedRows: "",
    idArticulo: data.A.toString().trim(),
    meses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  };
};

const fillProduct = (product, data) => {
  product.affectedRows += (product.affectedRows.trim() === '' ? '' : ', ') + data.__rowNum__; 
  fillMonths(product, data);
};

const fillMonths = (product, data) => {
  ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"].forEach((col, index) => {
    product.meses[index] = data[col];
  });
}

export const squash = (json) => {
  var result = [];

  json.forEach(row => {
    var product = findProduct(result, row);
    if (product) {
      fillProduct(product, row);
    }
    else {
      var newProduct = createProduct(row);
      fillProduct(newProduct, row);
      result.push(newProduct);
    }
  });

  return result;
}