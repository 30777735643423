import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MTableBodyRow } from 'material-table';

import classes from './TablaComparacionPorCategoriaDeCliente.css';
import TablaComparacionPorItemDeLaCategoria from '../TablaComparacionPorItemDeLaCategoria/TablaComparacionPorItemDeLaCategoria';
import TablaComparacionPorCategoriaDeClienteToExcel from './TablaComparacionPorCategoriaDeClienteToExcel';
import { compareByAsc, compareByDesc } from '../../../../shared/sorting';

const TablaComparacionPorCategoriaDeCliente = (props) => {
  const { fechasComparar = [], gastosComparar, loading } = props;
  const [tableState, setTableState] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });

  useEffect(() => {
    tableState.push(gastosComparar[0].gastos);
    setTimeout(() => armarComparacion(gastosComparar[1].gastos), 500);
  }, [props.flag]);

  const columns = [
    {
      title: 'Item',
      field: 'categoria',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];
    tableState[0].forEach((gastoCompleto) => {
      const verify = arrayAComparar.filter((gasto) => gasto.id_CategoriaItem === gastoCompleto.id_CategoriaItem);
      if (verify.length === 0) {
        arrayNuevo.push({ ...gastoCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((gasto) => {
      return arrayNuevo.push(gasto);
    });

    arrayAComparar.forEach((gastoOrdenado) => {
      const verify = tableState[0].filter((gasto) => gasto.id_CategoriaItem === gastoOrdenado.id_CategoriaItem);

      if (verify.length === 0) {
        tableState[0].push({ ...gastoOrdenado, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.categoria.localeCompare(b.categoria));
    var orderTableState = tableState[0].sort((a, b) => a.categoria.localeCompare(b.categoria));

    setTableState([
      { dateComplete: props.fechasComparar[0], gastosDetallados: orderTableState },
      { dateComplete: props.fechasComparar[1], gastosDetallados: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;

    tableState.map((data, key) => {
      if (key === 0) {
        return data.gastosDetallados.map((gastoDetallado, index) => {
          totalMonto1 += gastoDetallado.monto;
          totalMonto2 += tableState[1].gastosDetallados[index].monto;
          return formatTableData.push({
            categoriaId: gastoDetallado.id_CategoriaItem,
            categoria: gastoDetallado.categoria,
            monto1: gastoDetallado.monto,
            porcParticipacion1: gastoDetallado.porcentajeDeParticipacion,
            monto2: tableState[1].gastosDetallados[index].monto,
            porcParticipacion2: tableState[1].gastosDetallados[index].porcentajeDeParticipacion,
            difMontos: calcularDif(gastoDetallado.monto, tableState[1].gastosDetallados[index].monto),
            porcComparacion: calcularPorc(gastoDetallado.monto, tableState[1].gastosDetallados[index].monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        categoria: 'Total',
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const headerStyle = {
    paddingBottom: '5px',
    paddingTop: '5px',
    backgroundColor: 'rgb(250, 250, 250)'
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <div
          style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '10px', marginLeft: '20px' }}
        >{`Comparativa de gastos por categoría`}</div>
        <TablaComparacionPorCategoriaDeClienteToExcel
          ventasPor={`Categoria de cliente`}
          comprobantes={data()}
          fechas={[
            moment(fechasComparar[0]).subtract(1, 'month').format('MM/YYYY'),
            moment(fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')
          ]}
        />
      </div>
      <Table
        columns={columns}
        data={data()}
        noBoxShadow
        loading={loading}
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingBottom: '5px', paddingTop: '5px', width: '45px' }}></TableCell>
                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  ></TableCell>
                  <TableCell
                    style={{
                      ...headerStyle,
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0'
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {`${moment(gastosComparar[0].dateComplete).subtract(1, 'month').format('MM/YYYY')}`}
                  </TableCell>
                  <TableCell
                    style={{
                      ...headerStyle,
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0'
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {`${moment(gastosComparar[1].dateComplete).subtract(1, 'month').format('MM/YYYY')}`}
                  </TableCell>
                  <TableCell style={{ paddingTop: '5px', paddingBottom: '5px' }} align="center"></TableCell>
                  <TableCell style={{ paddingBottom: '5px' }}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '45px' }}></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('categoria')}
                    style={{
                      ...headerStyle,
                      width: window.screen.width < 1367 ? '265px' : '410px'
                    }}
                    align="left"
                  >
                    Categoria
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto1')}
                    style={{
                      ...headerStyle,
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0'
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion1')}
                    style={{
                      ...headerStyle,
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0'
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto2')}
                    style={{
                      ...headerStyle,
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0'
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion2')}
                    style={{
                      ...headerStyle,
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0'
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('difMontos')}
                    style={{ ...headerStyle }}
                    align="right"
                  >
                    Dif. Montos
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcComparacion')}
                    style={{ ...headerStyle }}
                    align="right"
                  >
                    Porc. Comparación
                  </TableCell>
                </TableRow>
              </TableHead>
            );
          },
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
                detailPanel={
                  !props.data.categoriaId
                    ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                    : props.detailPanel
                }
              />
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            tooltip: 'Mostrar comprobantes',
            render: (rowData) => {
              return (
                <div className={classes.TablasComparacionComprobantesContainer}>
                  <div style={{ width: '100%' }}>
                    <TablaComparacionPorItemDeLaCategoria
                      categoria={rowData}
                      fechasComparar={[gastosComparar[0].dateComplete, gastosComparar[1].dateComplete]}
                      titleCategory={rowData.categoria}
                    />
                  </div>
                </div>
              );
            }
          }
        ]}
        customOptions={{
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          showTitle: false,
          tableLayout: 'fixed',
          toolbar: false,
          maxBodyHeight: 630,
          rowStyle: (rowData, config) => {
            return {
              cursor: 'auto',
              backgroundColor: (rowData.categoria === 'Total' && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
            };
          },
          cellStyle: (cellData) => ({
            fontWeight: cellData.categoria === 'Total' && 'bolder'
          })
        }}
      />
    </div>
  );
};

export default TablaComparacionPorCategoriaDeCliente;
