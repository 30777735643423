import React from 'react';
import { connect } from 'react-redux';
import BoxEjecutarPaginaInfo from '../../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

import classes from './InformesTenacta.css';
import Informes from './Informes';
import Importacion from './Importacion';
import { storageEsMultiempresa } from '../../../shared/sessionData';
import EmpresaSelector from '../../../components/UI/SelectorEmpresa/EmpresaSelector';
import InformesTabs from '../../../components/UI/Informes/InformesTabs/InformesTabs';

const InformesTenacta = (props) => {
  sessionStorage.setItem('seccionNombre', 'Informe 1');
  const isMultiemp = storageEsMultiempresa();

  return (
    <div className={classes.PanelContainer}>
      {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {!props.ejecucionDePagina ? 
      (<BoxEjecutarPaginaInfo/>) :
      (<div>
        <InformesTabs
          tabs={['Budget/Forecast', 'Importar Budget/Forecast']}
          views={[
            <Informes ejecucionDePagina={props.ejecucionDePagina} />,
            <Importacion ejecucionDePagina={props.ejecucionDePagina} />
          ]}
          rootClass={classes.TabsRoot}
        />
      </div>)}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ejecucionDePagina: state.ejecucionPaginas.ejecucionInformesTenacta
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InformesTenacta);