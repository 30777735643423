import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { MTableHeader } from 'material-table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import ModalDetallesComprobante from '../../Comprobantes/ModalDetallesComprobante/ModalDetallesComprobante';
import classes from './PieTableVentasMontoUnidadPorSeleccion.css';

const TablaVentasDesagregadasComprobantes = (props) => {
  const { fechaVentasDelMes, onLoadComprobantes, itemExpanded, tipoParent, filtrosParent, withoutDecimals,
    filtroSeleccionado } = props;
  const [comprobantesState, setComprobantesState] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFechasForLoad = () => {
    let result = {};

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      result = {
        mesDesde: filtroSeleccionado.desde.getMonth() + 1,
        anioDesde: filtroSeleccionado.desde.getFullYear(),
        mesHasta: filtroSeleccionado.hasta.getMonth() + 1,
        anioHasta: filtroSeleccionado.hasta.getFullYear()
      }
    } else {
      result = {
        mesDesde: props.mes ? props.mes : itemExpanded.mesDesde ? itemExpanded.mesDesde : fechaVentasDelMes.mes,
        anioDesde: props.anio ? props.anio : itemExpanded.anioDesde ? itemExpanded.anioDesde : fechaVentasDelMes.anio,
        mesHasta: props.mes ? props.mes : itemExpanded.mesHasta ? itemExpanded.mesHasta : fechaVentasDelMes.mes,
        anioHasta: props.anio ? props.anio : itemExpanded.anioHasta ? itemExpanded.anioHasta : fechaVentasDelMes.anio,
      }
    }

    return result;
  }

  const onLoadSuccess = (comprobantes) => {
    setComprobantesState(comprobantes);
    setLoading(false);
  }

  const onLoadFail = () => {
    setComprobantesState([]);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fecha = getFechasForLoad();    
    const id = itemExpanded.id ? itemExpanded.id : itemExpanded.itemId;
    onLoadComprobantes(id, tipoParent, filtrosParent, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, onLoadSuccess, onLoadFail);
  }, []);

  const headerStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fafafa',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const cellStyle = {
    fontSize: 'smaller',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const columns = [
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyle,
      headerStyle: headerStyle
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'left',
      cellStyle: cellStyle,
      headerStyle: headerStyle
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyle,
      headerStyle: headerStyle
    },
    {
      title: 'Importe',
      field: 'monto',
      align: 'right',
      cellStyle: (cellData) => {
        return {
          fontSize: 'smaller',
          paddingBottom: '0px',
          paddingTop: '0px',
          paddingLeft: '5px',
          paddingRight: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => withoutDecimals ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(rowData.monto)}` : `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante
          venta
          comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }}
          withoutDecimals={withoutDecimals}
        />;
      }
    }
  ];

  const data = () => {
    return comprobantesState.map((comprobante) => ({
      fecha: comprobante.fecha,
      comprobante: comprobante.comprobante,
      referencia: comprobante.referencia,
      numeroExterno: comprobante.numeroExterno,
      monto: comprobante.monto,
      idComproba: comprobante.idComproba,
      idSistema: comprobante.idSistema,
      idSucursal: comprobante.idSucursal
    }));
  };

  return (
    <div className={classes.TablaVentasDesagregadasMontoUnidad}>
      <Table
        columns={columns}
        style={{ width: props.customWidth ? props.customWidth : '100%', marginTop: 0, marginBottom: '15px' }}
        data={data()}
        noBoxShadow
        loading={loading}
        components={{
          Header: (props) => (
            <MTableHeader {...props}>
              {(props.columns[0].tableData.width = '20px')}
              {(props.columns[1].tableData.width = '250px')}
              {(props.columns[2].tableData.width = '20px')}
              {(props.columns[3].tableData.width = window.screen.width > 1600 ? ' 175px' : '165px')}
              {(props.columns[4].tableData.width = window.screen.width > 1600 ? ' 100px' : '135px')}
              {(props.columns[5].tableData.width = '35px')}
            </MTableHeader>
          )
        }}
        customOptions={{
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          toolbar: false,
          showTitle: false,
          detailPanelType: 'single',
          detailPanelColumnAlignment: 'right',
          paging: false,
          maxBodyHeight: comprobantesState && comprobantesState.length < 5 ? 250 : comprobantesState.length < 10 ? 350 : 450,
          rowStyle: (rowData, index) => ({
            cursor: 'auto',
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(index) && '#f2f2f2'
          }),
        }}
        tableKey={"tabla-ventas-desagregadas-comprobantes"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filtroSeleccionado: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadComprobantes: (id, tipoParent, filtrosParent, mesDesde, anioDesde, mesHasta, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasDesagregadasPorComprobantesPorTipoDeVenta(id, tipoParent, filtrosParent, mesDesde, anioDesde, mesHasta, anioHasta, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDesagregadasComprobantes);