import * as actionTypes from './actionTypes';

export const updateUltimaActualizacionFotoDelDia = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA,
    fechaHora
  };
};

export const clearUltimaActualizacionFotoDelDia = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA
  };
};

export const updateUltimaActualizacionGastos = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_GASTOS,
    fechaHora
  };
};

export const clearUltimaActualizacionGastos = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_GASTOS,
    fechaHora
  };
};

export const updateUltimaActualizacionMultiempresa = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_MULTIEMPRESA,
    fechaHora
  };
};

export const clearUltimaActualizacionMultiempresa = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_MULTIEMPRESA,
    fechaHora
  };
};

export const updateUltimaActualizacionRentabilidad = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_RENTABILIDAD,
    fechaHora
  };
};

export const clearUltimaActualizacionRentabilidad = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_RENTABILIDAD,
    fechaHora
  };
};

export const updateUltimaActualizacionTiemposRequest = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST,
    fechaHora
  };
};

export const clearUltimaActualizacionTiemposRequest = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST,
    fechaHora
  };
};

export const updateUltimaActualizacionAnalisisVentas = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS,
    fechaHora
  };
};

export const clearUltimaActualizacionAnalisisVentas = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS,
    fechaHora
  };
};

export const updateUltimaActualizacionAnalisisSucursales = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES,
    fechaHora
  };
};

export const clearUltimaActualizacionAnalisisSucursales = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES,
    fechaHora
  };
};

export const updateUltimaActualizacionAnalisisVendedores = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES,
    fechaHora
  };
};

export const clearUltimaActualizacionAnalisisVendedores = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES,
    fechaHora
  };
};

export const updateUltimaActualizacionAnalisisUtilidad = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD,
    fechaHora
  };
};

export const clearUltimaActualizacionAnalisisUtilidad = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD,
    fechaHora
  };
};

export const updateUltimaActualizacionNoganetHome = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_NOGANET_HOME,
    fechaHora
  };
};

export const clearUltimaActualizacionNoganetHome = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_NOGANET_HOME,
    fechaHora
  };
};

export const updateUltimaActualizacionTenactaHome = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_TENACTA_HOME,
    fechaHora
  };
};

export const clearUltimaActualizacionTenactaHome = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_TENACTA_HOME,
    fechaHora
  };
};