import React, { useState, useEffect, useCallback, Fragment } from "react";
import * as actions from './../../../../store/actions';
import { connect } from 'react-redux';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { compareByDesc } from "../../../../shared/sorting";
import { Close } from "@material-ui/icons";
import FiltrosChartPivotGrid from "./FiltrosChartPivotGrid";
import CustomBarChartPivotGrid from "./CustomBarChartPivotGrid";
import CustomPieChartPivotGrid from "./CustomPieChartPivotGrid";

const BarChartPivotGrid = (props) => {
  const { chartConfig, filtrosChart } = props;
  const { options, series, filters, data, extraFilters, relatedFilter } = chartConfig;
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [optionItems, setOptionItems] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [optionSeries, setOptionSeries] = useState([]);
  const [filteredSeries, setFilteredSeries] = useState(series);
  const [loadingHorizontal, setLoadingHorizontal] = useState(false);
  const [chartItems, setChartItems] = useState([]);
  const [showMonto, setShowMonto] = useState(false);
  const [limit, setLimit] = useState(0);
  const maxItems = 20;

  const proccessData = useCallback((dataField, data, series) => {
    let filterData = [];

    if (data) {
      data.forEach((item) => {
        let itemName = item[dataField];
        let existItemIndex = filterData.findIndex((fil) => fil.name === itemName);

        if (existItemIndex !== null && existItemIndex !== undefined && existItemIndex !== -1) {
          if (series) {
            series.forEach((serie) => {
              filterData[existItemIndex][serie.name] = item[serie.name] + filterData[existItemIndex][serie.name];
            });
          }
        } else {
          let newItem = { name: item[dataField] };

          if (series) {
            series.forEach((serie) => {
              newItem[serie.name] = item[serie.name];
            });
          }

          filterData.push(newItem);
        }
      });
    }

    return filterData;
  }, []);

  useEffect(() => {
    if (options && series && data && !optionItems) {
      let resultItem = {};
      options.forEach((option) => {
        let dataField = option.name;
        let optionData = {
          items: proccessData(dataField, data, series)
        };

        resultItem[dataField] = optionData;
      });
      setOptionItems(resultItem);
    }
  }, [options, series, data, optionItems, proccessData]);

  useEffect(() => {
    setSelectOptions(
      options && options.map((option) => {
        return {
          id: option.name,
          nombre: option.caption && option.caption.replace('- ', ''),
          areaIndex: option.areaIndex
        }
      })
    )
  }, [options]);

  const getSeries = (items, filteredSeries) => {
    return filteredSeries && filteredSeries.map((serie) => {
      return {
        name: serie.caption ? serie.caption.replace('| ', '') : '',
        data: items.map((item) => item[serie.name])
      }
    })
  }

  const applyExtraFiltersToData = (dataField, data, extraFilters, relatedFilters) => {
    let result = [...data];

    if (relatedFilters) {
      Object.keys(relatedFilters).forEach((keyName, keyIndex) => {
        if (relatedFilters[keyName] && relatedFilters[keyName].length > 0)
          result = result.filter((item) => relatedFilters[keyName].includes(item[keyName]));
      });
    }

    if (extraFilters) {
      Object.keys(extraFilters).forEach((keyName, keyIndex) => {
        if (extraFilters[keyName] && extraFilters[keyName].length > 0)
          result = result.filter((item) => extraFilters[keyName].includes(item[keyName]));
      });
    }

    let procData = proccessData(dataField, result, series);

    return procData;
  }

  const onAplicarFiltro = (optionSelected, filterSelected, filteredSeries, ordenSelected, extraFiltersResult, relatedFiltersResult) => {
    setLoading(true);
    setLoadingHorizontal(true);

    setTitle(optionSelected.nombre);
    setFilteredSeries(filteredSeries);
    setShowMonto(filterSelected && filterSelected.showMonto);
    setChartItems(filterSelected && filterSelected.chartItems);

    if (optionItems) {
      let option = optionSelected.id;
      let tempItems = applyExtraFiltersToData(option, data, extraFiltersResult, relatedFiltersResult);

      if (tempItems && tempItems.length > maxItems)
        setLimit(maxItems);
      else
        setLimit(0);

      let orderedItems = tempItems && tempItems.sort((a, b) => compareByDesc(a, b, ordenSelected.name));
      let newSeries = getSeries(orderedItems, filteredSeries);
      setItems(orderedItems);
      setOptionSeries(newSeries);

      const filtros = {
        optionSelectedId: option,
        fieldSelectedId: filterSelected.id,
        orderSelectedId: ordenSelected.name,
        extraFilters: extraFiltersResult,
        relatedFilters: relatedFiltersResult,
      };

      props.onUpdateFiltros(filtros);
    }

    setTimeout(() => {
      setLoading(false);
      setLoadingHorizontal(false);
    }, 500);
  }

  const getItems = useCallback(() => {
    if (!items || (items && items.length === 0))
      return [];

    if (limit > 0 && items.length > limit) {
      let data = [].concat(items);
      data = data.slice(0, limit);
      let others = [].concat(items);
      others = others.slice(limit, items.length);

      let otherItem = {};

      others.forEach(other => {
        Object.keys(other).forEach((keyName) => {
          if (typeof other[keyName] === 'string')
            otherItem[keyName] = other[keyName];

          if (typeof other[keyName] === 'number') {
            if (otherItem[keyName])
              otherItem[keyName] += other[keyName] ? Number(other[keyName]) : 0;
            else
              otherItem[keyName] = other[keyName] ? Number(other[keyName]) : 0;
          }
        });
      });

      otherItem['name'] = "OTROS";
      otherItem['id'] = "0000";
      data.splice(0, 0, otherItem);

      return data;
    }

    return items;
  }, [items, limit]);

  const getPieItems = useCallback((field) => {
    if (!items || (items && items.length === 0))
      return [];

    let ordered = items.sort((a, b) => compareByDesc(a, b, field));
    let data = [].concat(ordered);

    if (limit > 0 && items.length > limit) {
      data = data.slice(0, limit);

      let others = [].concat(items);
      others = others.slice(limit, items.length);

      let otherItem = {};

      others.forEach(other => {
        Object.keys(other).forEach((keyName) => {
          if (typeof other[keyName] === 'string')
            otherItem[keyName] = other[keyName];

          if (typeof other[keyName] === 'number') {
            if (otherItem[keyName])
              otherItem[keyName] += other[keyName] ? Number(other[keyName]) : 0;
            else
              otherItem[keyName] = other[keyName] ? Number(other[keyName]) : 0;
          }
        });
      });

      otherItem['name'] = "OTROS";
      otherItem['id'] = "0000";
      data.splice(0, 0, otherItem);
    }

    return data;
  }, [items, limit]);

  const getOptionSeries = useCallback(() => {
    if (!optionSeries || (optionSeries && optionSeries.length === 0))
      return [];

    if (limit > 0 && optionSeries[0] && optionSeries[0].data && optionSeries[0].data.length > limit) {
      let tempOptions = [];
      optionSeries.forEach((option) => {
        let data = [].concat(option.data);
        data = data.slice(0, limit);
        let others = [].concat(option.data);
        others = others.slice(limit, option.data.length);
        let totalOthers = 0;
        others.forEach((val) => {
          totalOthers += val;
        });
        data.splice(0, 0, totalOthers);
        option.data = data;
        tempOptions.push(option);
      });
      return tempOptions;
    }

    return optionSeries;
  }, [optionSeries, limit]);

  const getPieOptionSeries = useCallback((field) => {
    if (!optionSeries || (optionSeries && optionSeries.length === 0))
      return [];

    let tempOptions = [];

    optionSeries.forEach((option) => {
      let ordered = option.data.sort((a, b) => compareByDesc(a, b, field));
      let data = [].concat(ordered);

      if (limit > 0 && option.data && option.data.length > limit) {
        data = data.slice(0, limit);
        let others = [].concat(ordered);
        others = others.slice(limit, option.data.length);
        let totalOthers = 0;
        others.forEach((val) => {
          totalOthers += val;
        });
        data.splice(0, 0, totalOthers);
      }

      option.data = data;
      tempOptions.push(option);
    });

    return tempOptions;
  }, [optionSeries, limit]);

  const onLimitChange = (value) => {
    let numValue = Number(value);
    setLimit(numValue);
  }

  const getLimit = () => {
    return (
      <Fragment>
        <Grid item xs={6}>
          <Typography style={{ textAlign: 'start', fontWeight: 'bold' }}>
            Límite
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent='end' alignItems='center'>
          <Grid item xs={4}>
            <TextField
              value={limit}
              onChange={(event) => onLimitChange(event.target.value)}
              type="number"
              size='small'
              InputProps={{
                inputProps: {
                  max: getItems().length,
                  min: 0,
                  style: { padding: '2px', textAlign: 'center' }
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {limit && limit > 0 ? (
              <IconButton size='small' onClick={() => setLimit(0)}>
                <Close fontSize='small' />
              </IconButton>
            ) : <Fragment></Fragment>}
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container spacing={1} direction="column" style={{ padding: '20px', maxWidth: '95vw' }}>
      <Grid item xs={12} container>
        <FiltrosChartPivotGrid
          selectOptions={selectOptions}
          filters={filters}
          onAplicarFiltro={onAplicarFiltro}
          filtrosChart={filtrosChart}
          extraFilters={extraFilters}
          relatedFilter={relatedFilter}
          optionItems={optionItems}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ marginTop: '10px' }}
        container justifyContent='space-between' alignItems='center'>
        {getLimit()}
      </Grid>
      <Grid item xs={12}>
        <CustomBarChartPivotGrid
          disabledTitle
          chartStacked
          itemPor={title}
          items={getItems()}
          categories={getItems()}
          filteredSeries={filteredSeries}
          loading={loading}
          chartSinDatos={getItems().length === 0}
          customHeight={550}
          disableBoxShadow
          disabledOcultar
          inHorizontalView={false}
          itemSeries={getOptionSeries()}
          onShowFiltersModal={props.onShowFiltersModal}
          showMonto={showMonto}
          limit={limit}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomBarChartPivotGrid
          disabledTitle
          chartStacked
          itemPor={title}
          items={getItems()}
          categories={getItems()}
          filteredSeries={filteredSeries}
          loading={loadingHorizontal}
          chartSinDatos={getItems().length === 0}
          customHeight={550}
          disableBoxShadow
          disabledOcultar
          inHorizontalView={true}
          itemSeries={getOptionSeries()}
          onShowFiltersModal={props.onShowFiltersModal}
          showMonto={showMonto}
          limit={limit}
        />
      </Grid>
      {(chartItems && chartItems.length > 0) && (
        <Grid item xs={12} container spacing={1}>
          {chartItems.map((fieldChart, index) => (
            <Grid key={index} item xs={12} md={4}>
              <CustomPieChartPivotGrid
                title={`Análisis por ${title}`}
                subTitle={fieldChart.caption ? fieldChart.caption.replace('| ', '') : ''}
                items={getPieItems(fieldChart.name)}
                itemSeries={getPieOptionSeries(fieldChart.name)}
                loading={loading || loadingHorizontal}
                field={fieldChart && fieldChart.name}
                showMonto={showMonto}
                height={436}
                legendPosition={'bottom'}
                legendHeight={50}
                limit={limit}
                headerTitle={title}
                montoTitle={fieldChart.caption ? fieldChart.caption.replace('| ', '') : ''}
                filename={`Análisis de ${fieldChart.caption ? fieldChart.caption.replace('| ', '') : ''} por ${title}`}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    filtrosChart: state.tenacta.filtrosChartInformeUno,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta'])),
    onUpdateFiltros: (filtros) => dispatch(actions.updateFiltrosChartInformeUno(filtros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChartPivotGrid);