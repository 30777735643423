import React from 'react';
import MaterialTable from 'material-table';
import { storageClienteId, storageEmpresaId } from '../../../shared/sessionData';

const Table = (props) => {
  const {
    title,
    columns,
    data,
    loading,
    actions,
    components,
    customOptions,
    actionsTitle,
    editable,
    tableRef,
    noBoxShadow,
    onRowClick,
    detailPanel,
    style,
    tableKey,
    parentChildData,
  } = props;

  //#region Orden default
  const orderColumKey = "order-column";
  const orderDirectionKey = "order-direction";

  const onOrderChange = (columnId, direction) => {
    if (tableKey) {
      const empresaId = storageEmpresaId();
      const clienteId = storageClienteId();
      const prefix = `${clienteId}-${empresaId}-${tableKey}`;
      localStorage.setItem(`${prefix}-${orderColumKey}`, columnId);
      localStorage.setItem(`${prefix}-${orderDirectionKey}`, direction);
    }

    if (props.onOrderChange)
      props.onOrderChange(columnId, direction);
  }

  const getColumnsWithSort = () => {
    if (tableKey) {
      const empresaId = storageEmpresaId();
      const clienteId = storageClienteId();
      const prefix = `${clienteId}-${empresaId}-${tableKey}`;
      const storageColumnId = localStorage.getItem(`${prefix}-${orderColumKey}`);
      const columnId = storageColumnId
        ? Number(storageColumnId) < 0
          ? 0
          : Number(storageColumnId)
        : 0;
      const direction = localStorage.getItem(`${prefix}-${orderDirectionKey}`);

      if (columns[columnId]) {
        columns.forEach(element => {
          if (element["defaultSort"])
            delete element["defaultSort"];
        });

        columns[columnId]["defaultSort"] = direction ? direction : "asc";
      }
    }

    return columns;
  }

  //#endregion

  return (
    <MaterialTable
      style={{ ...style, boxShadow: noBoxShadow && 'none' }}
      tableRef={tableRef}
      title={title}
      columns={getColumnsWithSort()}
      editable={editable}
      data={data}
      isLoading={loading}
      actions={actions}
      components={components}
      detailPanel={detailPanel}
      onRowClick={onRowClick ? (event, rowData) => onRowClick(event, rowData) : undefined}
      onColumnDragged={
        props.onColumnDragged
          ? (sourceIndex, destinationIndex) => props.onColumnDragged(sourceIndex, destinationIndex)
          : undefined
      }
      localization={{
        header: {
          actions: actionsTitle
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'Filas',
          previousTooltip: 'Página anterior',
          nextTooltip: 'Página siguiente',
          firstTooltip: 'Primer página',
          lastTooltip: 'Última página'
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
          exportName: 'Descargar en CSV',
          exportAriaLabel: 'Exportar',
          exportTitle: 'Exportar',
          addRemoveColumns: 'add col'
        },
        body: {
          editTooltip: 'Editar',
          addTooltip: 'Nuevo',
          deleteTooltip: 'Eliminar',
          emptyDataSourceMessage: loading ? 'Cargando...' : 'No hay datos para mostrar',
          editRow: {
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Confirmar',
            deleteText: '¿Confirma que quiere eliminar?',
          }
        }
      }}
      options={{
        ...customOptions
      }}
      onOrderChange={(columnId, direction) => onOrderChange(columnId, direction)}
      icons={props.icons}      
      parentChildData={props.parentChildData ? (row, rows) => parentChildData(row, rows) : undefined }
    />
  );
};

export default Table;
