// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rentabilidad__RentabilidadContainer__OCYs0 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  width: 100%;\r\n}\r\n\r\n.Rentabilidad__lineRentabilidadEvolutivo__wsTrY {\r\n  width: 100% !important;\r\n  min-width: 750px !important;\r\n}\r\n\r\n.Rentabilidad__tableRentabilidadEvolutivo__3UapF {\r\n  width: 100% !important;\r\n  min-width: 750px !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RentabilidadContainer": "Rentabilidad__RentabilidadContainer__OCYs0",
	"lineRentabilidadEvolutivo": "Rentabilidad__lineRentabilidadEvolutivo__wsTrY",
	"tableRentabilidadEvolutivo": "Rentabilidad__tableRentabilidadEvolutivo__3UapF"
};
export default ___CSS_LOADER_EXPORT___;
