import React from 'react';
import classes from './TablaInteranualCubo.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import SinResultados from '../../../../assets/images/SinResultados.png';
import Spinner from '../../Spinner/Spinner';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { fechaActualFunc } from '../../../../shared/fechas';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, formatNumberWithoutDecimals } from '../../../../shared/formatNumber';
import TablaInteranualCuboToExcel from './TablaInteranualCuboToExcel';

const TablaInteranualCubo = (props) => {
  const {
    fecha,
    periodoSeleccionado,
    onSelectMesAnioClick,
    title,
    loading,
    total,
    onEliminarPeriodo,
    datas,
    titleMontos,
    filtroSeleccionado,
    ventas
  } = props;

  const onMesAnioClick = (e, mes, anio, index) => {
    e.preventDefault();
    onSelectMesAnioClick(mes, anio);
  };

  const isPeriodoSelected = () => {
    return filtroSeleccionado && filtroSeleccionado.aplicado && filtroSeleccionado.desde && filtroSeleccionado.hasta
      ? fecha.getFullYear() >= filtroSeleccionado.desde.getFullYear() &&
      fecha.getFullYear() <= filtroSeleccionado.hasta.getFullYear()
      : periodoSeleccionado.getFullYear() === fecha.getFullYear();
  };

  const checkIndexSelected = (mes, anio) => {
    let date = new Date(anio, mes - 1, 1);

    return filtroSeleccionado && filtroSeleccionado.aplicado
      ? date >= filtroSeleccionado.desde && date <= filtroSeleccionado.hasta
      : periodoSeleccionado.getMonth() + 1 === mes && periodoSeleccionado.getFullYear() === anio;
  };

  const canDelete = fecha.getFullYear() !== fechaActualFunc().getFullYear();

  return (
    <div className={classes.tablaCuboContainer}>
      <TableContainer
        style={{
          borderRadius: '5px',
          border: '1px solid #D9D9D9',
          borderColor: isPeriodoSelected() && '#09D4FF',
          overflowX: loading ? 'unset' : 'hidden'
        }}
        component={Paper}
      >
        <Grid container className={classes.TitleCard} style={{ paddingInline: '10px'}}>
          <Grid item xs={9}>
            <p style={{ fontWeight: 'bold', marginTop: ventas ? '20px' : '10px', marginBottom: '3px', fontSize: 'smaller' }}>{title}</p>
            <p style={{ marginTop: '1px', marginBottom: ventas ? '20px' : '4px', fontSize: '12px' }}>
              {props.withoutDecimals
                ? `Total: ${storageMonedaSigno()} ${formatNumberWithoutDecimals(total)}`
                : `Total: ${storageMonedaSigno()} ${formatNumber(total)}`}
            </p>
          </Grid>
          <Grid item xs={1}>
            {canDelete && (
              <Tooltip title={'Eliminar período'} placement="top-end">
                <IconButton aria-label={'Eliminar período'} onClick={onEliminarPeriodo} style={{ paddingBottom: '0' }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={1}>
            {!loading &&
              <TablaInteranualCuboToExcel                
                title={title}
                datasPorAnio={datas}
                montosDe={titleMontos}
              />}
          </Grid>
        </Grid>
        {!loading ? (
          datas.length > 0 ? (
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Año</TableCell>
                  <TableCell align="right">Mes</TableCell>
                  <TableCell align="right">{titleMontos}</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              {datas.map((data, key) => (
                <TableBody key={key}>
                  <TableRow key={key}>
                    <TableCell scope="rowgroup">{data.anio}</TableCell>
                    <TableCell headers={key} scope="row">
                      {data.meses.map((mes, key) => (
                        <ListItem key={key} classes={{ root: classes.listaItems }}>
                          <ListItemText classes={{ root: classes.listaItemsText }}>
                            <Typography classes={{ body1: classes.listaItemsTypography }}> {mes.mes}</Typography>
                            <Divider />
                          </ListItemText>
                        </ListItem>
                      ))}
                    </TableCell>
                    <TableCell classes={{ sizeSmall: classes.cellMonto }}>
                      {data.meses.map((mes, key) => (
                        <ListItem
                          key={key}
                          classes={{ root: `${classes.listaItems} ${classes.cellMontoItem}` }}
                          disableGutters={true}
                        >
                          <ListItemText classes={{ root: classes.listaItemsText }}>
                            <Typography classes={{ body1: classes.listaItemsTypography }}>
                              {' '}
                              {props.withoutDecimals
                                ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(mes.monto)}`
                                : `${storageMonedaSigno()} ${formatNumber(mes.monto)}`}
                            </Typography>
                            <Divider />
                          </ListItemText>
                        </ListItem>
                      ))}
                    </TableCell>
                    <TableCell>
                      {data.meses.map((mes, index) => (
                        <ListItem
                          key={index}
                          onClick={(event) =>
                            !checkIndexSelected(mes.mes, data.anio) &&
                            onMesAnioClick(event, mes.mes, data.anio, mes.mes / data.anio)
                          }
                          classes={{ root: classes.listaItems }}
                          style={{ color: checkIndexSelected(mes.mes, data.anio) && '#09D4FF' }}
                        >
                          <ListItemText classes={{ root: classes.listaItemsText }}>
                            <Typography
                              classes={{ root: classes.iconSeleccionarMes }}
                              style={{ textAlign: 'center', lineHeight: '1.37' }}
                            >
                              <SearchIcon
                                style={{ fontSize: '15px', margin: '0px' }}
                                classes={{ root: classes.iconSeleccionarMes }}
                              />
                            </Typography>
                            <Divider />
                          </ListItemText>
                        </ListItem>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          ) : (
            <img src={SinResultados} alt="Sin resultados" />
          )
        ) : (
          <div style={{ textAlign: 'center', minHeight: '345px' }}>
            <Spinner />
          </div>
        )}
      </TableContainer>
    </div>
  );

  // return (
  //   <div className={classes.tablaCuboContainer}>
  //     <TableContainer
  //       style={{
  //         borderRadius: '5px',
  //         border: '1px solid #D9D9D9',
  //         borderColor: isPeriodoSelected() && '#09D4FF',
  //         overflowX: loading ? 'unset' : 'auto'
  //       }}
  //       component={Paper}
  //     >
  //       <Grid container className={classes.TitleCard}>
  //         <Grid item xs={9}>
  //           <p style={{ fontWeight: 'bold', marginLeft: '20px', marginRight: '20px', marginBottom: '1px' }}>{title}</p>
  //           <p style={{ marginTop: '1px', marginLeft: '20px', marginBottom: '2px', fontSize: '12px' }}>
  //             {props.withoutDecimals
  //               ? `Total: ${storageMonedaSigno()} ${formatNumberWithoutDecimals(total)}`
  //               : `Total: ${storageMonedaSigno()} ${formatNumber(total)}`}
  //           </p>
  //         </Grid>
  //         <Grid item xs={3} container>
  //           <Grid item xs={6}>
  //             {canDelete && (
  //               <Tooltip title={'Eliminar período'} placement="top-end">
  //                 <IconButton aria-label={'Eliminar período'} onClick={onEliminarPeriodo}>
  //                   <DeleteIcon />
  //                 </IconButton>
  //               </Tooltip>
  //             )}
  //           </Grid>
  //           <Grid item xs={6}>
  //             {!loading && <TablaInteranualCuboToExcel title={title} datasPorAnio={datas} montosDe={titleMontos} />}
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       {!loading ? (
  //         datas.length > 0 ? (
  //           <Table stickyHeader size="small" aria-label="a dense table">
  //             <TableHead>
  //               <TableRow>
  //                 <TableCell>Año</TableCell>
  //                 <TableCell align="right">Mes</TableCell>
  //                 <TableCell align="right">{titleMontos}</TableCell>
  //                 <TableCell align="center">Acciones</TableCell>
  //               </TableRow>
  //             </TableHead>
  //             {datas.map((data, key) => (
  //               <TableBody key={key}>
  //                 <TableRow key={key}>
  //                   <TableCell scope="rowgroup">{data.anio}</TableCell>
  //                   <TableCell headers={key} scope="row">
  //                     {data.meses.map((mes, key) => (
  //                       <ListItem key={key} classes={{ root: classes.listaItems }}>
  //                         <ListItemText classes={{ root: classes.listaItemsText }}>
  //                           <Typography classes={{ body1: classes.listaItemsTypography }}> {mes.mes}</Typography>
  //                           <Divider />
  //                         </ListItemText>
  //                       </ListItem>
  //                     ))}
  //                   </TableCell>
  //                   <TableCell classes={{ sizeSmall: classes.cellMonto }}>
  //                     {data.meses.map((mes, key) => (
  //                       <ListItem
  //                         key={key}
  //                         classes={{ root: `${classes.listaItems} ${classes.cellMontoItem}` }}
  //                         disableGutters={true}
  //                       >
  //                         <ListItemText classes={{ root: classes.listaItemsText }}>
  //                           <Typography classes={{ body1: classes.listaItemsTypography }}>
  //                             {' '}
  //                             {props.withoutDecimals
  //                               ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(mes.monto)}`
  //                               : `${storageMonedaSigno()} ${formatNumber(mes.monto)}`}
  //                           </Typography>
  //                           <Divider />
  //                         </ListItemText>
  //                       </ListItem>
  //                     ))}
  //                   </TableCell>
  //                   <TableCell>
  //                     {data.meses.map((mes, index) => (
  //                       <ListItem
  //                         key={index}
  //                         onClick={(event) =>
  //                           !checkIndexSelected(mes.mes, data.anio) &&
  //                           onMesAnioClick(event, mes.mes, data.anio, mes.mes / data.anio)
  //                         }
  //                         classes={{ root: classes.listaItems }}
  //                         style={{ color: checkIndexSelected(mes.mes, data.anio) && '#09D4FF' }}
  //                       >
  //                         <ListItemText classes={{ root: classes.listaItemsText }}>
  //                           <Typography
  //                             classes={{ root: classes.iconSeleccionarMes }}
  //                             style={{ textAlign: 'center', lineHeight: '1.37' }}
  //                           >
  //                             <SearchIcon
  //                               style={{ fontSize: '15px', margin: '0px' }}
  //                               classes={{ root: classes.iconSeleccionarMes }}
  //                             />
  //                           </Typography>
  //                           <Divider />
  //                         </ListItemText>
  //                       </ListItem>
  //                     ))}
  //                   </TableCell>
  //                 </TableRow>
  //               </TableBody>
  //             ))}
  //           </Table>
  //         ) : (
  //           <img src={SinResultados} alt="Sin resultados" />
  //         )
  //       ) : (
  //         <div style={{ textAlign: 'center', minHeight: '220px' }}>
  //           <Spinner />
  //         </div>
  //       )}
  //     </TableContainer>
  //   </div>
  // );
};

export default TablaInteranualCubo;
