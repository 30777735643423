import Close from '@material-ui/icons/Close';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { compareByDesc } from '../../../../shared/sorting';
import PieChart from '../../../Charts/PieChart';
import Table from '../../../UI/Table/Table';
import ViewInTable from '../../../../assets/images/ViewInTable.svg';
import { Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import classes from './PieTableVentasMontoUnidadPorSeleccionTenacta.css';
import Spinner from '../../../UI/Spinner/Spinner';
import ExportToExcelIcon from '../../../UI/Icons/ExportToExcelIcon';

const PieVentasMontoUnidadPorSeleccion = (props) => {
  const { dataSelectedId, loading, ventas, ventaType, title,
    subTitle, isCurrency, onPorcionClick, headerTitle, initialLimit } = props;
  const isDisabledChart = props.estaOculto;
  const [items, setItems] = useState();
  const [limit, setLimit] = useState(initialLimit);

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  const ventasPositivas = useCallback(() => {
    let ventasPos = ventas ? ventas.filter((vnt) => vnt[ventaType] >= 0) : [];
    return ventasPos;
  }, [ventas]);

  useEffect(() => {
    if (limit && limit > 0) {
      let ventas = ventasPositivas();
      let ordered = ventas.sort((a, b) => compareByDesc(a, b, ventaType));
      let filtered = ordered.slice(0, limit);
      let others = ordered.slice(limit);

      if (others && others.length > 0) {
        let otherItem = {
          mes: others[0].mes,
          anio: others[0].anio,
          monto: 0,
          unidadesVendidas: 0,
        };
        otherItem['nombre'] = "OTROS";
        otherItem['id'] = "0000";
        others.forEach(element => {
          otherItem.monto += element.monto;
          otherItem.unidadesVendidas += element.unidadesVendidas;
        });
        filtered.splice(0, 0, otherItem);
      }
      setItems(filtered);
    }
  }, [limit, ventas, ventasPositivas]);

  const ventasNegativas = () => {
    let ventasNegativo = ventas ? ventas.filter((vnt) => vnt[ventaType] < 0) : [];

    return (
      !isDisabledChart && ventasNegativo &&
      ventasNegativo.map((ventaNegativo) => ({
        nombre: ventaNegativo['nombre'],
        monto: ventaNegativo[ventaType]
      }))
    );
  };

  const tooltip = {
    y: {
      formatter: function (val) {
        return isCurrency ? `${storageMonedaSigno()} ${getNumberWithDecimals(val)}` : `${formatNumberWithoutDecimals(val)}`;
      }
    }
  };

  const getSeries = () => {
    let tempItems = limit && limit > 0 ? items : ventasPositivas();
    let series = tempItems ? tempItems.map((venta) => venta[ventaType]) : [];

    return series;
  }

  const getLabels = () => {
    let tempItems = limit && limit > 0 ? items : ventasPositivas();
    let labels = tempItems ? tempItems.map((venta) => venta['nombre']) : [];

    return labels;
  }

  const onLimitChange = (value) => {
    let numValue = Number(value);
    setLimit(numValue);
  }

  const getLimit = () => {
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Límite
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={limit}
            onChange={(event) => onLimitChange(event.target.value)}
            type="number"
            size='small'
            InputProps={{
              inputProps: {
                max: ventas && ventas.length,
                min: 0,
                style: { padding: '2px', textAlign: 'center' }
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {limit && limit > 0 ? (
            <IconButton size='small' onClick={() => setLimit(0)}>
              <Close fontSize='small' />
            </IconButton>
          ) : <Fragment></Fragment>}
        </Grid>
      </Grid>
    )
  }

  //#region Change View
  const [viewAsPie, setViewAsPie] = useState(true);

  const handleChangeView = () => {
    setViewAsPie(!viewAsPie);
  }

  const dataLabels = {
    enabled: true,
    formatter: function (val, opts) {
      let item = opts.w.config.labels[opts.seriesIndex];

      if (item && item.toUpperCase() === 'OTROS')
        return `OTROS ${formatNumber(val)}%`;
      else
        return `${formatNumber(val)}%`;
    },
  }

  const renderPie = () => {
    return (
      <PieChart
        disabledCss
        title={title}
        endItems={ventas && ventas.length !== 0 ? getLimit() : undefined}
        subTitle={subTitle}
        series={getSeries()}
        labels={getLabels()}
        valoresNegativos={ventasNegativas()}
        loading={loading}
        imgSinDatos={ventas.length === 0}
        height={props.height}
        disabled={isDisabledChart}
        width={props.width}
        chartType={props.chartType}
        spaceBetweenChartTitle={props.spaceBetweenChartTitle}
        legendHeight={props.legendHeight}
        legendPosition={props.legendPosition}
        tooltip={tooltip}
        onPorcionClick={onPorcionClick}
        onChangeViewClick={handleChangeView}
        showUnidades={!isCurrency}
        dataLabels={dataLabels}
      />
    )
  }

  const renderTable = () => {
    return (
      <TablaMontoUnidad
        title={title}
        subTitle={subTitle}
        ventas={ventas}
        dataSelectedId={dataSelectedId}
        headerTitle={headerTitle}
        isMonto={ventaType === 'monto'}
        onChangeViewClick={handleChangeView}
        height={(props.height - 50)}
        loading={loading}
        filename={props.filename}
        withoutDecimals={props.withoutDecimals}
      />
    )
  }

  //#endregion

  return loading ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : viewAsPie ? (
    <Fragment>
      {renderPie()}
    </Fragment>
  ) : (
    <Fragment>
      {renderTable()}
    </Fragment>
  );
};

const TablaMontoUnidad = (props) => {
  const { ventas, headerTitle, isMonto, loading } = props;

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#c9c9c9'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '12px',
      paddingBottom: '5px',
      paddingTop: '5px',
      width: width,
      color: value < 0 && 'red'
    }
  };

  const columns = () => {
    const arrColumns = [
      {
        title: headerTitle,
        field: 'nombre',
        align: 'left',
        cellStyle: (cellData) => cellStyles(cellData, '35%'),
        headerStyle: { ...headerStyle, width: '35%' }
      },
      {
        title: 'Monto',
        field: 'monto',
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '30%'),
        headerStyle: { ...headerStyle, width: '30%' },
        render: (rowData) => `${storageMonedaSigno()}${getNumberWithDecimals(rowData.monto)}`

      },
      {
        title: 'Porc. Participacion',
        field: 'porcParticipacion',
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '30%'),
        headerStyle: { ...headerStyle, width: '30%' },
        render: (rowData) => `${getNumberWithDecimals(rowData.porcParticipacion)}%`
      }
    ];

    const unidadesColumn = [
      {
        title: headerTitle,
        field: 'nombre',
        align: 'left',
        cellStyle: (cellData) => cellStyles(cellData, '40%'),
        headerStyle: { ...headerStyle, width: '35%' }
      },
      {
        title: 'Unidades',
        field: 'unidades',
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '30%'),
        headerStyle: headerStyle,
        render: (rowData) => `${formatNumberWithoutDecimals(rowData.unidades)}`

      },
      {
        title: 'Porc. Participacion',
        field: 'porcParticipacionUnidades',
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '30%'),
        headerStyle: { ...headerStyle, width: '35%' },
        render: (rowData) => `${getNumberWithDecimals(rowData.porcParticipacionUnidades)}%`
      }
    ];

    return isMonto ? arrColumns : unidadesColumn;
  };

  const data = () => {
    return ventas.map((venta) => {
      return {
        nombre: venta['nombre'],
        porcParticipacion: venta.porcentajeDeParticipacion,
        monto: venta.monto,
        unidades: venta.unidadesVendidas,
        porcParticipacionUnidades: venta.porcentajeDeParticipacionUnidades,
      };
    });
  }

  //#region Export to Excell
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
    };
  };

  const columnsForExcel = () => {
    const arrColumns = [
      {
        title: headerTitle,
        width: { wpx: 150 },
        align: 'left',
        style: headersStyle
      },
      {
        title: 'Monto',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      },
      {
        title: 'Porc. Participacion',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      }
    ];

    const unidadesColumn = [
      {
        title: headerTitle,
        width: { wpx: 150 },
        align: 'left',
        style: headersStyle
      },
      {
        title: 'Unidades',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle

      },
      {
        title: 'Porc. Participacion',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      }
    ];

    return isMonto ? arrColumns : unidadesColumn;
  }

  const formatData = () => {
    let tableData = [];

    ventas && ventas.map((venta) => {
      let nombre = venta['nombre'];
      const row = [
        {
          value: nombre,
          style: { font: { sz: '10' } }
        }
      ];

      if (isMonto) {
        row.push({
          value: `${storageMonedaSigno()}${getNumberWithDecimals(venta.monto)}`,
          style: cellsStyleVerifNegative(venta.monto)
        });
        row.push({
          value: `${getNumberWithDecimals(venta.porcentajeDeParticipacion)}%`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacion)
        });
      } else {
        row.push({
          value: `${formatNumberWithoutDecimals(venta.unidadesVendidas)}`,
          style: cellsStyleVerifNegative(venta.unidadesVendidas)
        });
        row.push({
          value: `${getNumberWithDecimals(venta.porcentajeDeParticipacionUnidades)}%`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacionUnidades)
        });
      }

      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnsForExcel(),
      data: formatData()
    }
  ];
  //#endregion
  return (
    <div style={{ height: '100%', width: props.width, minHeight: props.minHeight }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginInline: '10px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title}
          </div>
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como torta">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.TitleCard}>
          {props.subTitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subTitle}</p>}
          {ventas && ventas.length > 0 && (<ExportToExcelIcon
            filename={props.filename}
            name={headerTitle}
            dataSet={multiDataSet}
          />)}
        </div>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <div className={classes.TablaMultiempresaContainer}>
            <Table
              style={{ width: '100%', marginBottom: '20px' }}
              columns={columns()}
              data={data()}
              loading={props.loading}
              customOptions={{
                draggable: false,
                search: false,
                paging: false,
                toolbar: false,
                showTitle: false,
                maxBodyHeight: props.height,
                rowStyle: (rowData, index) => ({
                  backgroundColor: isEven(index) ? "#f2f2f2" : "white",
                }),
              }}
              tableKey={"tabla-utilidad-por-cliente-seleccion"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PieVentasMontoUnidadPorSeleccion;