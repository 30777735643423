import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  fotoDelDia: '',
  gastos: '',
  multiempresa: '',
  tiemposReq: '',
  rentabilidad: '',
  analisisVentas: '',
  analisisSucursales: '',
  analisisVendedores: '',
  analisisUtilidad: '',
  noganetHome: '',
  tenactaHome: '',
};

//#region Foto del dia
const updateUltimaActualizacionFotoDelDia = (state, fechaHora) => {
  return updateObject(state, {
    fotoDelDia: `Foto del día: ${fechaHora}`
  });
};

const clearUltimaActualizacionFotoDelDia = (state) => {
  return updateObject(state, {
    fotoDelDia: ``
  });
};
//#endregion

//#region Gastos
const updateUltimaActualizacionGastos = (state, fechaHora) => {
  return updateObject(state, {
    gastos: `Gastos: ${fechaHora}`
  });
};

const clearUltimaActualizacionGastos = (state) => {
  return updateObject(state, {
    gastos: ``
  });
};
//#endregion

//#region Multiempresa
const updateUltimaActualizacionMultiempresa = (state, fechaHora) => {
  return updateObject(state, {
    multiempresa: `Multiempresa: ${fechaHora}`
  });
};

const clearUltimaActualizacionMultiempresa = (state) => {
  return updateObject(state, {
    multiempresa: ``
  });
};
//#endregion

//#region Rentabilidad
const updateUltimaActualizacionRentabilidad = (state, fechaHora) => {
  return updateObject(state, {
    rentabilidad: `Rentabilidad: ${fechaHora}`
  });
};

const clearUltimaActualizacionRentabilidad = (state) => {
  return updateObject(state, {
    rentabilidad: ``
  });
};
//#endregion

//#region Tiempos request
const updateUltimaActualizacionTiemposRequest = (state, fechaHora) => {
  return updateObject(state, {
    tiemposReq: `Tiempos request: ${fechaHora}`
  });
};

const clearUltimaActualizacionTiemposRequest = (state) => {
  return updateObject(state, {
    tiemposReq: ``
  });
};
//#endregion

//#region AnalisisVentas
const updateUltimaActualizacionAnalisisVentas = (state, fechaHora) => {
  return updateObject(state, {
    analisisVentas: `Análisis de ventas: ${fechaHora}`
  });
};

const clearUltimaActualizacionAnalisisVentas = (state) => {
  return updateObject(state, {
    analisisVentas: ``
  });
};
//#endregion

//#region AnalisisSucursales
const updateUltimaActualizacionAnalisisSucursales = (state, fechaHora) => {
  return updateObject(state, {
    analisisSucursales: `Análisis de sucursales: ${fechaHora}`
  });
};

const clearUltimaActualizacionAnalisisSucursales = (state) => {
  return updateObject(state, {
    analisisSucursales: ``
  });
};
//#endregion

//#region AnalisisVendedores
const updateUltimaActualizacionAnalisisVendedores = (state, fechaHora) => {
  return updateObject(state, {
    analisisVendedores: `Análisis de vendedores: ${fechaHora}`
  });
};

const clearUltimaActualizacionAnalisisVendedores = (state) => {
  return updateObject(state, {
    analisisVendedores: ``
  });
};
//#endregion

//#region AnalisisUtilidad
const updateUltimaActualizacionAnalisisUtilidad = (state, fechaHora) => {
  return updateObject(state, {
    analisisUtilidad: `Análisis de utilidad: ${fechaHora}`
  });
};

const clearUltimaActualizacionAnalisisUtilidad = (state) => {
  return updateObject(state, {
    analisisUtilidad: ``
  });
};
//#endregion

//#region NoganetHome
const updateUltimaActualizacionNoganetHome = (state, fechaHora) => {
  return updateObject(state, {
    noganetHome: `Noganet Home: ${fechaHora}`
  });
};

const clearUltimaActualizacionNoganetHome = (state) => {
  return updateObject(state, {
    noganetHome: ``
  });
};
//#endregion

//#region Tenacta Home
const updateUltimaActualizacionTenactaHome = (state, fechaHora) => {
  return updateObject(state, {
    tenactaHome: `Análisis de ventas: ${fechaHora}`
  });
};

const clearUltimaActualizacionTenactaHome = (state) => {
  return updateObject(state, {
    tenactaHome: ``
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA:
      return updateUltimaActualizacionFotoDelDia(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA:
      return clearUltimaActualizacionFotoDelDia(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_GASTOS:
      return updateUltimaActualizacionGastos(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_GASTOS:
      return clearUltimaActualizacionGastos(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_MULTIEMPRESA:
      return updateUltimaActualizacionMultiempresa(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_MULTIEMPRESA:
      return clearUltimaActualizacionMultiempresa(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_RENTABILIDAD:
      return updateUltimaActualizacionRentabilidad(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_RENTABILIDAD:
      return clearUltimaActualizacionRentabilidad(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST:
      return updateUltimaActualizacionTiemposRequest(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST:
      return clearUltimaActualizacionTiemposRequest(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS:
      return updateUltimaActualizacionAnalisisVentas(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS:
      return clearUltimaActualizacionAnalisisVentas(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES:
      return updateUltimaActualizacionAnalisisSucursales(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES:
      return clearUltimaActualizacionAnalisisSucursales(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES:
      return updateUltimaActualizacionAnalisisVendedores(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES:
      return clearUltimaActualizacionAnalisisVendedores(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD:
      return updateUltimaActualizacionAnalisisUtilidad(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD:
      return clearUltimaActualizacionAnalisisUtilidad(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_NOGANET_HOME:
      return updateUltimaActualizacionNoganetHome(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_NOGANET_HOME:
      return clearUltimaActualizacionNoganetHome(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_TENACTA_HOME:
      return updateUltimaActualizacionTenactaHome(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_TENACTA_HOME:
      return clearUltimaActualizacionTenactaHome(state);

    default:
      return state;
  }
};

export default reducer;
