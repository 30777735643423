import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

import LineChequesEnCartera from '../../components/Dashboard/LineChequesEnCartera/LineChequesEnCartera';
import LineChequesPropios from '../../components/Dashboard/LineChequesPropios/LineChequesPropios';
import TextoIvaCreaditoFiscalComparativo from '../../components/Indicadores/Impuestos/TextoIvaCreditoFiscalComparativo/TextoIvaCreaditoFiscalComparativo';
import TextoIvaDebitoFiscalComparativo from '../../components/Indicadores/Impuestos/TextoIvaDebitoFiscalComparativo/TextoIvaDebitoFiscalComparativo';

import Dialog from '../../components/UI/Dialog/Dialog';

import MultiChartChequesEnCarteraPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartChequesEnCarteraPorEmpresa/MultiChartChequesEnCarteraPorEmpresa';
import MultiChartChequesPropiosPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartChequesPropiosPorEmpresa/MultiChartChequesPropiosPorEmpresa';
import MultiChartGastosDelMesPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartGastosDelMesPorEmpresa/MultiChartGastosDelMesPorEmpresa';
import MultiChartVentasDiariasPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartVentasDiariasPorEmpresa/MultiChartVentasDiariasPorEmpresa';
import MultiChartIvaFiscalPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartIvaFiscalPorEmpresa/MultiChartIvaFiscalPorEmpresa';
import MultiChartVentasMensualesPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartVentasMensualesPorEmpresa/MultiChartVentasMensualesPorEmpresa';
import MultiChartPagosDelMesPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartPagosDelMesPorEmpresa/MultiChartPagosDelMesPorEmpresa';
import MultiChartSaldoAcreedorPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartSaldoAcreedorPorEmpresa/MultiChartSaldoAcreedorPorEmpresa';
import MultiChartSaldoDeudorPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartSaldoDeudorPorEmpresa/MultiChartSaldoDeudorPorEmpresa';
import MultiChartCobranzasMensualesPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartCobranzasMensualesPorEmpresa/MultiChartCobranzasMensualesPorEmpresa';
import MultiChartRentabilidadPorEmpresa from '../../components/Indicadores/Multiempresa/MultiChartRentabilidadPorEmpresa/MultiChartRentabilidadPorEmpresa';

import TextoVentasMensuales from '../../components/Indicadores/Ventas/TextoVentasMensuales/TextoVentasMensuales';
import TextoVentasDiarias from '../../components/Indicadores/Ventas/TextoVentasDiarias/TextoVentasDiarias';
import TextoCobranzasMensual from '../../components/Indicadores/Ventas/TextoCobranzasMensual/TextoCobranzasMensual';
import PieSaldoDeudores from '../../components/Indicadores/Ventas/PieSaldoDeudores/PieSaldoDeudores';
import PieSaldoAcreedores from '../../components/Indicadores/Compras/PieSaldoAcreedores/PieSaldoAcreedores';
import TextoGastosDelMesComparativo from '../../components/Indicadores/Gastos/TextoGastosDelMesComparativo/TextoGastosDelMesComparativo';
import TextoChequesEnCartera from '../../components/Indicadores/Ventas/TextoChequesEnCartera/TextoChequesEnCartera';
import TextoChequesPropios from '../../components/Indicadores/Compras/TextoChequesPropios/TextoChequesPropios';
import TextoIvaFiscal from '../../components/Indicadores/Impuestos/TextoIvaFiscal/TextoIvaFiscal';

import classes from './Multiempresa.css';
import TextoPagosDelMes from '../../components/Indicadores/Compras/TextoPagosDelMes/TextoPagosDelMes';
import { fechaActualFunc } from '../../shared/fechas';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import TablaIndicadoresMultiempresa from '../../components/Indicadores/Multiempresa/TablaIndicadoresMultiempresa/TablaIndicadoresMultiempresa';
import { Grid } from '@material-ui/core';

const Multiempresa = (props) => {
  const { estaCargadoEmpresas, onLoadEmpresas } = props;
  sessionStorage.setItem('seccionNombre', 'Multiempresa');
  
  const [dialogState, setDialogState] = useState({
    show: false,
    content: '',
    nombreEmpresaSeleccionada: '',
    idEmpresaSeleccionada: 0
  });
  useEffect(() => {
    if (!estaCargadoEmpresas) {
      onLoadEmpresas();
    }
  }, [estaCargadoEmpresas, onLoadEmpresas]);

  const showContent = () => {
    switch (dialogState.content) {
      case 'ventasMensuales':
        return (
          <TextoVentasMensuales
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
            border
          />
        );

      case 'ventasDiarias':
        return (
          <TextoVentasDiarias
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
            border
          />
        );

      case 'cobranzasMensuales':
        return (
          <TextoCobranzasMensual
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
            border
          />
        );

      case 'ivaFiscal':
        return (
          <div>
            <TextoIvaFiscal
              empresa={{
                nombre: dialogState.nombreEmpresaSeleccionada,
                id: dialogState.idEmpresaSeleccionada
              }}
              border
            />
            <br />
            <TextoIvaDebitoFiscalComparativo
              empresa={{
                nombre: dialogState.nombreEmpresaSeleccionada,
                id: dialogState.idEmpresaSeleccionada
              }}
            />
            <br />
            <TextoIvaCreaditoFiscalComparativo
              empresa={{
                nombre: dialogState.nombreEmpresaSeleccionada,
                id: dialogState.idEmpresaSeleccionada
              }}
            />
          </div>
        );

      case 'saldoDeudores':
        return (
          <PieSaldoDeudores
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
          />
        );

      case 'saldoAcreedores':
        return (
          <PieSaldoAcreedores
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
          />
        );

      case 'chequesEnCartera':
        return (
          <div>
            <TextoChequesEnCartera
              empresa={{
                nombre: dialogState.nombreEmpresaSeleccionada,
                id: dialogState.idEmpresaSeleccionada
              }}
            />
            <div style={{ marginTop: '10px', minHeight: '240px' }}>
              <LineChequesEnCartera
                empresa={{
                  nombre: dialogState.nombreEmpresaSeleccionada,
                  id: dialogState.idEmpresaSeleccionada
                }}
              />
            </div>
          </div>
        );

      case 'chequesPropios':
        return (
          <div>
            <TextoChequesPropios
              empresa={{
                nombre: dialogState.nombreEmpresaSeleccionada,
                id: dialogState.idEmpresaSeleccionada
              }}
            />
            <div style={{ marginTop: '10px', minHeight: '240px' }}>
              <LineChequesPropios
                empresa={{
                  nombre: dialogState.nombreEmpresaSeleccionada,
                  id: dialogState.idEmpresaSeleccionada
                }}
              />
            </div>
          </div>
        );

      case 'gastosDelMes':
        return (
          <TextoGastosDelMesComparativo
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
          />
        );

      case 'pagosDelMes':
        return (
          <TextoPagosDelMes
            empresa={{
              nombre: dialogState.nombreEmpresaSeleccionada,
              id: dialogState.idEmpresaSeleccionada
            }}
          />
        );

      default:
        return null;
    }
  };

  const handlePorcionClick = (config, content) => {
    /*setDialogState({
      show: true,
      content: content,
      nombreEmpresaSeleccionada: config.w.config.labels[config.dataPointIndex],
      idEmpresaSeleccionada: config.w.config.xaxis.categories[config.dataPointIndex]
    });*/
  };

  const handleBarClick = (config, content) => {
    /*setDialogState({
      show: true,
      content,
      nombreEmpresaSeleccionada: config.w.config.labels[config.seriesIndex],
      idEmpresaSeleccionada: config.w.config.xaxis.categories[config.seriesIndex]
    });*/
  };

  const handleCloseDialog = () => {
    setDialogState({
      show: false,
      content: '',
      empresaSeleccionada: ''
    });
  };

  return (
    <div className={classes.MultiempresaContainer}>
      {!props.ejecucionMultiempresa ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <Grid container spacing={1}>
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartChequesEnCarteraPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'chequesEnCartera')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartChequesPropiosPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'chequesPropios')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartGastosDelMesPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'gastosDelMes')}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartVentasDiariasPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'ventasDiarias')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartIvaFiscalPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handleBarClick(config, 'ivaFiscal')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartVentasMensualesPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'ventasMensuales')}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartPagosDelMesPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'pagosDelMes')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartSaldoAcreedorPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'saldoAcreedores')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartSaldoDeudorPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'saldoDeudores')}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartCobranzasMensualesPorEmpresa
                ejecucionDePagina={props.ejecucionMultiempresa}
                onPorcionClick={(config) => handlePorcionClick(config, 'cobranzasMensuales')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MultiChartRentabilidadPorEmpresa ejecucionDePagina={props.ejecucionMultiempresa} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>              
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TablaIndicadoresMultiempresa />
          </Grid>
          <Dialog
            actions={moment(fechaActualFunc()).format('DD/MM/YYYY HH:mm')}
            show={dialogState.show}
            body={<div style={{ minWidth: '500px' }}>{showContent()}</div>}
            onClose={handleCloseDialog}
          />
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionMultiempresa: state.ejecucionPaginas.ejecucionMultiempresa,
    estaCargadoEmpresas: state.multiempresa.empresas.estaCargado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarMultiempresa: () => dispatch(actions.ejecutarMultiempresa()),
    updateUltimaActualizacion: () =>
      dispatch(actions.updateUltimaActualizacionMultiempresa(moment(Date.now()).format('DD/MM/YYYY HH:mm'))),
    onLoadEmpresas: () => dispatch(actions.loadEmpresas())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Multiempresa);
