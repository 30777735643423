import React, { Fragment } from "react";
import { Tooltip, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    }
  },
  options: {
    fontSize: 'small',
  },
}));

const ButtonPivotGrid = (props) => {
  const classes = useStyles();
  const {
    tooltipTitle,
    buttonSize,
    icon,
    text,
    disabled,
    onClick,
    loading,
    backgroundImage
  } = props;

  const color = props.color ? props.color : 'primary';
  const buttonDisabled = disabled || loading;

  const renderButton = () => {
    return (
      <Button
        variant="contained"
        color={color}
        style={{
          width: '100%',
          margin: "5px auto 5px auto",
          color: 'white',
          backgroundImage: backgroundImage
        }}
        className={classes.button}
        size={buttonSize ? buttonSize : "small"}
        startIcon={loading ? <CircularProgress color={color} /> : icon}
        onClick={onClick}
        disabled={buttonDisabled}
        fullWidth={true}
      >
        {text}
      </Button>
    )
  }

  return (
    <span title={tooltipTitle}>
      {renderButton()}
    </span>
  )
}

export default ButtonPivotGrid;