import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import axiosConfig from 'axios';
import { enqueueSnackbar } from './snackbar';
import { storageMonedaId, storageToken } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';
import { baseUrlDashboard } from '../../shared/urlApps';

const ejecucionRequest = (url, success, fail) => {
  const authStr = 'Bearer '.concat(storageToken());
  axios
    .get(url, {
      headers: { Authorization: authStr }
    })
    .then((response) => {
      let total = 0;
      let formatResponse = [];
      response.data.map((data) => {
        return (total += Math.abs(data.total));
      });
      response.data.map((data) => {
        return formatResponse.push({ nombreEmpresa: data.nombreEmpresa, total: Math.abs(data.total), porcentajeDeParticipacion: data.porcentajeDeParticipacion,id: data.id });
      });

      const armadoResponse = {
        dataPorEmpresas: formatResponse,
        total
      };
      return success(armadoResponse);
    })
    .catch((error) => {
      return fail(error);
    });
};

export const loadVentasMensualesMultiempresa = () => {
  return (dispatch) => {
    dispatch(loadVentasMensualesMultiempresaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesEnteroPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasMensualesMultiempresa
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let totalVentas = 0;
        response.data.map((venta) => {
          return (totalVentas += venta.total);
        });
        dispatch(loadVentasMensualesMultiempresaSuccess(response.data, totalVentas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasMensualesMultiempresaFail(error));
          } else {
            dispatch(loadVentasMensualesMultiempresa());
          }
        } else {
          dispatch(loadVentasMensualesMultiempresaFail(error));
        }
      });
  };
};

const loadVentasMensualesMultiempresaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_MENSUALES_MULTIEMPRESA_START
  };
};

const loadVentasMensualesMultiempresaSuccess = (ventasPorEmpresa, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_MENSUALES_MULTIEMPRESA_SUCCESS,
    ventasPorEmpresa,
    totalVentas
  };
};

const loadVentasMensualesMultiempresaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_MENSUALES_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadVentasDiariasMultiempresa = () => {
  return (dispatch) => {
    dispatch(loadVentasDiariasMultiempresaStart());

    ejecucionRequest(
      `/ventas/VentasDelDiaPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.ventasDiariasMultiempresa
      }`,
      (success) => {
        dispatch(loadVentasDiariasMultiempresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasDiariasMultiempresaFail(error));
          } else {
            dispatch(loadVentasDiariasMultiempresa());
          }
        } else {
          dispatch(loadVentasDiariasMultiempresaFail(error));
        }
      }
    );
  };
};

const loadVentasDiariasMultiempresaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DIARIAS_MULTIEMPRESA_START
  };
};

const loadVentasDiariasMultiempresaSuccess = (ventasPorEmpresa, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DIARIAS_MULTIEMPRESA_SUCCESS,
    ventasPorEmpresa,
    totalVentas
  };
};

const loadVentasDiariasMultiempresaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DIARIAS_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadCobranzasMensualesPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadCobranzasMensualesPorEmpresaStart());

    ejecucionRequest(
      `/ventas/CobranzasDelMesComparativoPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.cobranzasMensualesMultiempresa
      }`,
      (success) => {
        dispatch(loadCobranzasMensualesPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadCobranzasMensualesPorEmpresaFail(error));
          } else {
            dispatch(loadCobranzasMensualesPorEmpresa());
          }
        } else {
          dispatch(loadCobranzasMensualesPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadCobranzasMensualesPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_START
  };
};

const loadCobranzasMensualesPorEmpresaSuccess = (cobranzasPorEmpresa, totalCobranzas) => {
  return {
    type: actionTypes.LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_SUCCESS,
    cobranzasPorEmpresa,
    totalCobranzas
  };
};

const loadCobranzasMensualesPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadSaldoDeudoresPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadSaldoDeudoresPorEmpresaStart());

    ejecucionRequest(
      `/ventas/DeudoresPorVentasPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.saldoDeudoresMultiempresa
      }`,
      (success) => {
        dispatch(loadSaldoDeudoresPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadSaldoDeudoresPorEmpresaFail(error));
          } else {
            dispatch(loadSaldoDeudoresPorEmpresa());
          }
        } else {
          dispatch(loadSaldoDeudoresPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadSaldoDeudoresPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_SALDO_DEUDORES_MULTIEMPRESA_START
  };
};

const loadSaldoDeudoresPorEmpresaSuccess = (saldoPorEmpresa, totalSaldo) => {
  return {
    type: actionTypes.LOAD_SALDO_DEUDORES_MULTIEMPRESA_SUCCESS,
    saldoPorEmpresa,
    totalSaldo
  };
};

const loadSaldoDeudoresPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_SALDO_DEUDORES_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadSaldoAcreedoresPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadSaldoAcreedoresPorEmpresaStart());

    ejecucionRequest(
      `/compras/DeudoresPorComprasPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.saldoAcreedoresMultiempresa
      }`,
      (success) => {
        dispatch(loadSaldoAcreedoresPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadSaldoAcreedoresPorEmpresaFail(error));
          } else {
            dispatch(loadSaldoAcreedoresPorEmpresa());
          }
        } else {
          dispatch(loadSaldoAcreedoresPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadSaldoAcreedoresPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_SALDO_ACREEDORES_MULTIEMPRESA_START
  };
};

const loadSaldoAcreedoresPorEmpresaSuccess = (saldoPorEmpresa, totalSaldo) => {
  return {
    type: actionTypes.LOAD_SALDO_ACREEDORES_MULTIEMPRESA_SUCCESS,
    saldoPorEmpresa,
    totalSaldo
  };
};

const loadSaldoAcreedoresPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_SALDO_ACREEDORES_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadChequesRechazadosPorEmpresa = () => {
  return (dispatch) => {}
}

export const loadChequesEnCarteraPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadChequesEnCarteraPorEmpresaStart());

    ejecucionRequest(
      `/cheques/ChequesEnCarteraPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.chequesEnCarteraMultiempresa
      }`,
      (success) => {
        dispatch(loadChequesEnCarteraPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadChequesEnCarteraPorEmpresaFail(error));
          } else {
            dispatch(loadChequesEnCarteraPorEmpresa());
          }
        } else {
          dispatch(loadChequesEnCarteraPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadChequesEnCarteraPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_START
  };
};

const loadChequesEnCarteraPorEmpresaSuccess = (chequesPorEmpresa, totalCheques) => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_SUCCESS,
    chequesPorEmpresa,
    totalCheques
  };
};

const loadChequesEnCarteraPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadChequesPropiosPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadChequesPropiosPorEmpresaStart());

    ejecucionRequest(
      `/cheques/ChequesPropiosPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.chequesEmitidosPendientesMultiempresa
      }`,
      (success) => {
        dispatch(loadChequesPropiosPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadChequesPropiosPorEmpresaFail(error));
          } else {
            dispatch(loadChequesPropiosPorEmpresa());
          }
        } else {
          dispatch(loadChequesPropiosPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadChequesPropiosPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_START
  };
};

const loadChequesPropiosPorEmpresaSuccess = (chequesPorEmpresa, totalCheques) => {
  return {
    type: actionTypes.LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_SUCCESS,
    chequesPorEmpresa,
    totalCheques
  };
};

const loadChequesPropiosPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadGastosDelMesPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadGastosDelMesPorEmpresaStart());
    ejecucionRequest(
      `/gastos/GastosDelMesPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.gastosDelMesMultiempresa
      }`,
      (success) => {
        dispatch(loadGastosDelMesPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadGastosDelMesPorEmpresaFail(error));
          } else {
            dispatch(loadGastosDelMesPorEmpresa());
          }
        } else {
          dispatch(loadGastosDelMesPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadGastosDelMesPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_GASTOS_DEL_MES_MULTIEMPRESA_START
  };
};

const loadGastosDelMesPorEmpresaSuccess = (gastosPorEmpresa, totalGastos) => {
  return {
    type: actionTypes.LOAD_GASTOS_DEL_MES_MULTIEMPRESA_SUCCESS,
    gastosPorEmpresa,
    totalGastos
  };
};

const loadGastosDelMesPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_GASTOS_DEL_MES_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadPagosDelMesPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadPagosDelMesPorEmpresaStart());

    ejecucionRequest(
      `/compras/PagosDelMesPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
        idIndicadores.pagosDelMesMultiempresa
      }`,
      (success) => {
        dispatch(loadPagosDelMesPorEmpresaSuccess(success.dataPorEmpresas, success.total));
      },
      (error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadPagosDelMesPorEmpresaFail(error));
          } else {
            dispatch(loadPagosDelMesPorEmpresa());
          }
        } else {
          dispatch(loadPagosDelMesPorEmpresaFail(error));
        }
      }
    );
  };
};

const loadPagosDelMesPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_PAGOS_DEL_MES_MULTIEMPRESA_START
  };
};

const loadPagosDelMesPorEmpresaSuccess = (pagosPorEmpresa, totalPagos) => {
  return {
    type: actionTypes.LOAD_PAGOS_DEL_MES_MULTIEMPRESA_SUCCESS,
    pagosPorEmpresa,
    totalPagos
  };
};

const loadPagosDelMesPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_PAGOS_DEL_MES_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadIvaFiscalPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadIvaFiscalPorEmpresaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/impuestos/IvaFiscalPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ivaFiscalMultiempresa
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((data) => {
          return (total += data.total);
        });

        dispatch(loadIvaFiscalPorEmpresaSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadIvaFiscalPorEmpresaFail(error));
          } else {
            dispatch(loadIvaFiscalPorEmpresa());
          }
        } else {
          dispatch(loadIvaFiscalPorEmpresaFail(error));
        }
      });
  };
};

const loadIvaFiscalPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_IVA_FISCAL_MULTIEMPRESA_START
  };
};

const loadIvaFiscalPorEmpresaSuccess = (ivaFiscalPorEmpresa, totalIvaFiscal) => {
  return {
    type: actionTypes.LOAD_IVA_FISCAL_MULTIEMPRESA_SUCCESS,
    ivaFiscalPorEmpresa,
    totalIvaFiscal
  };
};

const loadIvaFiscalPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_IVA_FISCAL_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadRentabilidadPorEmpresa = () => {
  return (dispatch) => {
    dispatch(loadRentabilidadPorEmpresaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/integradores/RentabilidadPorEmpresa?idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.rentabilidadMultiempresa
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((data) => {
          return (total += data.total);
        });

        dispatch(loadRentabilidadPorEmpresaSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadRentabilidadPorEmpresaFail(error));
          } else {
            dispatch(loadRentabilidadPorEmpresa());
          }
        } else {
          dispatch(loadRentabilidadPorEmpresaFail(error));
        }
      });
  };
};

const loadRentabilidadPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_RENTABILIDAD_MULTIEMPRESA_START
  };
};

const loadRentabilidadPorEmpresaSuccess = (rentabilidades, totalRentabilidad) => {
  return {
    type: actionTypes.LOAD_RENTABILIDAD_MULTIEMPRESA_SUCCESS,
    rentabilidades,
    totalRentabilidad
  };
};

const loadRentabilidadPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_RENTABILIDAD_MULTIEMPRESA_FAIL,
    error
  };
};

export const loadEmpresas = () => {
  return (dispatch) => {
    dispatch(loadEmpresasStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/empresas/empresas`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadEmpresasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEmpresasFail(error));
          } else {
            dispatch(loadEmpresas());
          }
        } else {
          dispatch(loadEmpresasFail(error));
        }
      });
  };
};

const loadEmpresasStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_START
  };
};

const loadEmpresasSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_SUCCESS,
    empresas
  };
};

const loadEmpresasFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_FAIL,
    error
  };
};

export const seleccionarEmpresa = (empresaId, success, errorFail) => {
  return (dispatch) => {
    dispatch(executeCambiarEmpresa(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .put(
        `${baseUrlDashboard()}empresas/CambiarEmpresaPorDefecto?empresaId=${empresaId}&indicadorId=${
          idIndicadores.cambiarEmpresa
        }`,
        null,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(executeCambiarEmpresa(false));
        return success(response);
      })
      .catch((error) => {
        dispatch(executeCambiarEmpresa(false));
        dispatch(
          enqueueSnackbar({
            message: `No se pudo cambiar de empresa, intente nuevamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
        return errorFail(error);
      });
  };
};

const executeCambiarEmpresa = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_EMPRESA,
    loading
  };
};
