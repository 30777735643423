// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnalisisDeSucursales__AnalisisSucursalesContainer__VfINn {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.AnalisisDeSucursales__CombinedChartsCard__wRQEo {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  margin: 5px !important;\r\n}\r\n\r\n.AnalisisDeSucursales__FlexHorizontal__47HWv {\r\n  display: flex;\r\n  flex-flow: row;\r\n  margin-bottom: 10px;\r\n  /* border: 1px solid red; */\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnalisisSucursalesContainer": "AnalisisDeSucursales__AnalisisSucursalesContainer__VfINn",
	"CombinedChartsCard": "AnalisisDeSucursales__CombinedChartsCard__wRQEo",
	"FlexHorizontal": "AnalisisDeSucursales__FlexHorizontal__47HWv"
};
export default ___CSS_LOADER_EXPORT___;
