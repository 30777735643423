import React, { useEffect, useState,  } from 'react';
import { connect } from 'react-redux';
import { fechaActualFunc } from '../../../../shared/fechas';
import * as actions from '../../../../store/actions/index';
import TablaInteranualCubo from '../../../UI/Interanual/TablaInteranualCubo/TablaInteranualCubo';
import moment from "moment";

const TablaInteranualCuboGastos = (props) => {
  const { fecha, evoluciones, periodoSeleccionado, ejecucionDePagina } = props;
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [fechaFija] = useState(fechaActualFunc());

  const onLoadChartSuccess = (gastos, dataTable, total) => {
    setDataTable(dataTable);
    setTotal(total);
    setLoading(false);
  }

  const onLoadChartFail = () => {
    setLoading(false);
  }

  useEffect(() => {
    if (ejecucionDePagina) {
      let item = evoluciones.find((evo) => evo.anio === fecha.getFullYear());
      
      if(item !== undefined){
        setDataTable(item.gastosDataTable);
        setTotal(item.totalGastos);
      }
    }
  }, [ejecucionDePagina, evoluciones, fecha]);

  useEffect(() => {
    if (ejecucionDePagina) {
      let item = evoluciones.find((evo) => evo.anio === fecha.getFullYear());
      
      if(item === undefined){
        setLoading(true);
        setDataTable([]);
        setTotal(0);
        props.onLoadChart(fechaFija.getMonth() + 1, fecha.getFullYear(), onLoadChartSuccess, onLoadChartFail);
      }
    }
  }, [ejecucionDePagina, evoluciones, fecha]);
  
  const onCategoriaClick = (mes, anio) => {
    props.onChangeFechaDelMes(mes, anio);
    props.onLoadPorCategoria(mes, anio);
  };

  const getTitle = () => {
    const start = `${moment(new Date(fecha.getFullYear() - 1, fechaFija.getMonth(), 1)).format('MM/YY')}`;
    const end = `${moment(new Date(fecha.getFullYear(), fechaFija.getMonth(), 1)).format('MM/YY')}`;

    return `Gastos desglosados por mes del ${start} a ${end}`;
  }

  return (
    <TablaInteranualCubo
      addMonth
      title={getTitle()}
      titleMontos={'Gastos'}
      datas={dataTable}
      fecha={fecha}
      loading={loading}
      total={total}
      onSelectMesAnioClick={(mes, anio) => onCategoriaClick(mes, anio)}
      onShowFiltersModal={() => props.onShowFiltersModal()}
      onEliminarPeriodo={props.onEliminarPeriodo}
      periodoSeleccionado={periodoSeleccionado}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    evoluciones: state.gastos.evolucionMensualInteranual.items,
    periodoSeleccionado: state.gastos.evolucionMensualInteranual.periodoSeleccionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio, onSuccess, onFail) => 
      dispatch(actions.loadEvolucionDeGastosMensualInteranual(mes, anio, onSuccess, onFail)),
    onChangeFechaDelMes: (mes, anio) => dispatch(actions.seleccionFechaDeGastosMensualInteranual(mes, anio)),
    onLoadPorCategoria: (mes, anio) => dispatch(actions.loadPieGastosPorCategoria(mes, anio)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Gastos desglosados por mes', ['gasto']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaInteranualCuboGastos);
