import React, { Fragment, useRef, useEffect, useState } from "react"
import * as actions from "../../../../store/actions/index"
import { connect } from "react-redux"
import { formatNumber, isEven } from "../../../../shared/formatNumber"
import { storageMonedaSigno, storageUsuarioId } from "../../../../shared/sessionData"
import Table from "../../../UI/Table/Table"
import FilterListIcon from "@material-ui/icons/FilterList"
import { IconButton, Tooltip } from "@material-ui/core"
import classes from "./TablaStockValorizado.css"
import TablaStockPorDeposito from "../TablaStockPorDeposito/TablaStockPorDeposito"
import TablaStockValorizadoToExcel from "./TablaStockValorizadoToExcel"
import BoxEjecucionIndicador from "../../../UI/BoxEjecucionIndicador/BoxEjecucionIndicador"
import { MTableHeader } from "material-table"
import _ from "lodash"
import TextoTotalStockValorizado from "../TextoTotalStockValorizado/TextoTotalStockValorizado"

const TablaStockValorizado = (props) => {
  const { ordenStockValorizado, cambiarOrden } = props;
  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState();
  const [loadingSort, setLoadingSort] = useState(false);
  const [cargando, setCargando] = useState(false);
  const signoMoneda = storageMonedaSigno();
	const [loadTable, setLoadTable] = useState(false)

  const cols = [
    { key: "id", field: "id" },
    { key: "nombre", field: "name" },
    { key: "marca", field: "marca" },
    { key: "rubro", field: "rubro" },
    { key: "subrubro", field: "subrubro" },
    { key: "cantidad", field: "cantidad" },
    { key: "costo", field: "costo" },
    { key: "costoTotal", field: "costoTotal" },
    { key: "porcentajeDeParticipacion", field: "porcParticipacion" },
  ];

  const loadTableHandle = () => {
		setLoadTable(true)
	}
  
  useEffect(() => {
    if (props.ejecucionDePagina && !props.estaCargado && !cargando && props.ejecucionIndicador) {
      setCargando(true);
      props.onLoadStockValorizado();
    }
  }, [props.ejecucionDePagina, cargando, props.estaCargado, props.ejecucionIndicador]);


  useEffect(() => {
    if (ordenStockValorizado && (orderBy === undefined || orderDirection === undefined)) {
      let splitOrderBy = ordenStockValorizado.split(',')[0];
      let splitDirection = ordenStockValorizado.split(',')[1];

      if (!orderBy)
        setOrderBy(cols.findIndex((c) => c.key === splitOrderBy));

      if (!orderDirection)
        setOrderDirection(splitDirection);
    }
  }, [cols, orderBy, orderDirection, ordenStockValorizado]);  

  const data = () => {    
		let order = orderBy === undefined || orderBy < 0 ? 0 : orderBy;
		let direction = orderDirection === undefined ? "asc" : orderDirection;
    let stockValorizado = _.orderBy(props.stockValorizado, cols[order].field, direction);

    return stockValorizado;
  }

  const isLoading = () => {
    return props.loading || loadingSort;
  }

  const onOrderChange = (columnId, columnDirection) => {
    setOrderBy(columnId);
    setOrderDirection(columnDirection);
    const ord = `${cols[columnId].key},${columnDirection}`;
    cambiarOrden(storageUsuarioId(), ord);
  } 

  const tableRef = useRef();

  return (
    <div className={classes.TablaStockValorizadoContainer}>
      {!loadTable ? 
      (<Fragment>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}> 
          <BoxEjecucionIndicador onClick={() => loadTableHandle()} title='Stock Valorizado'/>
          <div style={{display: 'flex', justifyContent: 'space-between',  height: '90%', padding: '10px'}}>
            <TextoTotalStockValorizado ejecucionDePagina= {!props.ejecucionIndicador}/>
          </div>
        </div>
      </Fragment>) : 
       (<Fragment>
          <div className={classes.TitleCard}>
            {!props.loading && (
              <TablaStockValorizadoToExcel
                stockValorizado={data()}
              />
            )}
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
                <FilterListIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
          <Table
            tableRef={tableRef}
            title=""
            loading={isLoading()}
            columns={[
              {
                title: "Cod. Artículo",
                field: "id",
                align: "left",
                cellStyle: {
                  fontSize: "12px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  height: "49px",
                  width: "10%"
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  backgroundColor: "#fafafa",
                  width: "10%"
                },
              },
              {
                title: "Nombre",
                field: "name",
                align: "left",
                cellStyle: {
                  fontSize: "12px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  paddingRight: "0px",
                  paddingLeft: "2px",
                  height: "49px",
                  width: "14%"
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  paddingLeft: "2px",
                  backgroundColor: "#fafafa",
                  width: "14%"
                },
              },
              {
                title: "Marca",
                field: "marca",
                align: "left",
                cellStyle: {
                  fontSize: "12px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  paddingRight: "0px",
                  paddingLeft: "2px",
                  height: "49px",
                  width: "14%"
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  paddingLeft: "2px",
                  backgroundColor: "#fafafa",
                  width: "14%"
                },
              },
              {
                title: "Rubro",
                field: "rubro",
                align: "left",
                cellStyle: {
                  fontSize: "12px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  paddingRight: "0px",
                  height: "49px",
                  width: "16%"
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  backgroundColor: "#fafafa",
                  width: "16%"
                },
              },
              {
                title: "Subrubro",
                field: "subrubro",
                align: "left",
                cellStyle: {
                  fontSize: "12px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  paddingRight: "0px",
                  height: "49px",
                  width: "14%"
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  backgroundColor: "#fafafa",
                  width: "14%"
                },
              },
              {
                title: "Cantidad",
                field: "cantidad",
                align: "right",
                cellStyle: {
                  fontSize: "12px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  height: "49px",
                  width: "8%"
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  backgroundColor: "#fafafa",
                  width: "8%"
                },
              },
              {
                title: `Costo`,
                field: "costo",
                align: "right",
                type: "numeric",
                cellStyle: (cellData) => {
                  return {
                    fontSize: "12px",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    paddingRight: "0px",
                    color: cellData < 0 && "red",
                    width: "8%"
                  };
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  backgroundColor: "#fafafa",
                  width: "8%"
                },
                render: (rowData) => `${signoMoneda} ${formatNumber(rowData.costo)}`,
              },
              {
                title: `Costo total`,
                field: "costoTotal",
                align: "right",
                type: "numeric",
                cellStyle: (cellData) => {
                  return {
                    fontSize: "12px",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    paddingRight: "0px",
                    color: cellData < 0 && "red",
                    width: "10%"
                  };
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                  backgroundColor: "#fafafa",
                  width: "10%"
                },
                render: (rowData) => `${signoMoneda} ${formatNumber(rowData.costoTotal)}`,
              },
              {
                title: `Porc. participación`,
                field: "porcParticipacion",
                align: "right",
                type: "numeric",
                cellStyle: (cellData) => {
                  return {
                    fontSize: "12px",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    color: cellData < 0 && "red",
                    width: "8%"
                  };
                },
                headerStyle: {
                  fontSize: "14px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  backgroundColor: "#fafafa",
                  width: "8%"
                },
                render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`,
              },
            ]}
            data={data()}
            noBoxShadow
            components={{
              Header: props => 
                <MTableHeader {...props}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(orderBy, orderDirection) => {
                    setLoadingSort(true);
                    onOrderChange(orderBy, orderDirection);
                    props.onOrderChange(orderBy, orderDirection);
                    setTimeout(() => setLoadingSort(false), 500);
                  }}
                />
            }}
            detailPanel={
              (rowData) => {
                return (
                  <TablaStockPorDeposito
                    articuloId={rowData.id}
                    itemExpanded={{
                      ...rowData,
                    }}
                    isItemExpanded
                  />
                )
              }
            }
            customOptions={{
              thirdSortClick: false,
              sorting: true,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 400,
              rowStyle: (rowData, index) => ({
                color: rowData.tableData.monto < 0 && "red",
                backgroundColor: !isEven(index) && "#f2f2f2",
              }),
            }}
            tableKey={"tabla-stock-valorizado"}
          />
        </Fragment>
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    stockValorizado: state.stock.stockValorizado.stock,
    ordenStockValorizado: state.stock.stockValorizado.orden,
    loading: state.stock.stockValorizado.loading,
    estaCargado: state.stock.stockValorizado.estaCargado,
    ejecucionIndicador: state.stock.stockValorizado.ejecucionIndicador,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadStockValorizado: (idEmpresa) => dispatch(actions.stockValorizado(idEmpresa)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, `Stock valorizado`, ["stock"])),
    onPlayIndicador: () => dispatch(actions.ejecucionStockValorizado(true)),
    cambiarOrden: (idUsuario, orden, success) => dispatch(actions.cambiarOrdenStockValorizado(idUsuario, orden, success)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TablaStockValorizado);