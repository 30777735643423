import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { storageEmpresaId, storageUsuarioId, storageIsAdmin } from "../../../../shared/sessionData";
import { Grid, Typography } from "@material-ui/core";
import ButtonPivotGrid from "../ButtonPivotGrid/ButtonPivotGrid";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Spinner from "../../Spinner/Spinner";
import Table from '../../Table/Table';
import SaveIcon from '@material-ui/icons/Save';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Tooltip } from '@material-ui/core';
import ConfirmDialog from '../../../UI/Dialog/ConfirmDialog';

const AdministrarFormato = (props) => {
  const { pivotGridKey, pivotGridRef, onClose, onCargarFormato } = props;
  const { pivotGridConfiguraciones, onLoadConfiguraciones, onSaveConfiguracion } = props;
  const [idUsuario] = useState(storageUsuarioId());
  const [isAdmin] = useState(false || storageIsAdmin());
  const [formatData, setFormatData] = useState({});

  const [configuracion, setConfiguracion] = useState();
  const selectedRows = () => {
    let rows = props.selectedTotalRowsItems
      ? props.selectedTotalRowsItems.find((i) => i.key === pivotGridKey)
        ? props.selectedTotalRowsItems.find((i) => i.key === pivotGridKey).rows
        : []
      : [];
    return JSON.stringify(rows);
  }

  useEffect(() => {
    if (pivotGridConfiguraciones) {
      const item = pivotGridConfiguraciones.find((config) => config.key === pivotGridKey);

      if (!item || (!item.estaCargado && !item.loading))
        onLoadConfiguraciones(pivotGridKey, idUsuario);
    }
  }, [pivotGridConfiguraciones, pivotGridKey, idUsuario, onLoadConfiguraciones]);

  useEffect(() => {
    if (pivotGridConfiguraciones) {
      const item = pivotGridConfiguraciones.find((config) => config.key === pivotGridKey);

      if (item && item.estaCargado) {
        setConfiguracion(item);
      }
    }
  }, [pivotGridConfiguraciones, pivotGridKey, configuracion]);

  // NEW
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220 220 220)'
  };
  const columns = [
    { title: 'ID', field: 'id', align: 'left', width: "5%", cellStyle: cellStyle, headerStyle: headerStyle, hidden: true },
    { title: 'Nombre', field: 'nombre', align: 'left', width: "70%", cellStyle: cellStyle, headerStyle: headerStyle, defaultSort: 'asc', validate: rowData => Boolean(rowData.nombre && rowData.nombre.trim()), },
    { title: 'Público', field: 'esPublica', align: 'center', width: "25%", cellStyle: cellStyle, headerStyle: headerStyle, type: 'boolean' }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = () => {
    onClose();
  }

  //#region ACTUALIZAR FORMATO
  // Actualizar formato confirm dialog
  const [actualizarFormatoConfirmDialogOpen, setActualizarFormatoConfirmDialogOpen] = useState(false);

  const handleActualizarFormatoConfirmDialogOpen = () => {
    setActualizarFormatoConfirmDialogOpen(true);
  };

  const handleActualizarFormatoConfirmDialogClose = () => {
    setActualizarFormatoConfirmDialogOpen(false);
  };
  
  const actualizarFormatoConfirmDialog = () => (
    <ConfirmDialog
      show={actualizarFormatoConfirmDialogOpen}
      title="Actualizar Formato"
      body="Si confirma la acción, se pisará el formato actual con el nuevo."
      onConfirm={() => {
        handleActualizarFormatoConfirmDialogClose();
        onActualizarFormato(formatData);
      }}
      onClose={handleActualizarFormatoConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - confirm dialog

  const onActualizarSuccess = () => {
    setIsLoading(false);
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    alert("La actualización de la estructura del cubo fue realizada correctamente");
  }

  const onActualizarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onActualizarFormato = (rowData) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey).fields;
      }

      let config = {
        id: rowData.id,
        nombre: rowData.nombre,
        key: pivotGridKey,
        configuracion: JSON.stringify(fields),
        esPublica: rowData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onActualizarSuccess, onActualizarFail);
    }
  }
  //#endregion ACTUALIZAR FORMATO

  //#region USAR FORMATO
  const onUsarFormatoSuccess = (rowData) => {
    const selectedRows = rowData.filasSubtotales ? JSON.parse(rowData.filasSubtotales) : [];
    props.onUpdateSelectedTotalRows(pivotGridKey, selectedRows);
    const formato = JSON.parse(rowData.configuracion);
    onCargarFormato(formato, rowData.nombre);
    setIsLoading(false);
    onClose();
  }

  const onUsarFormato = (rowData) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      setIsLoading(true);
      localStorage.removeItem(pivotGridKey);
      setTimeout(() => onUsarFormatoSuccess(rowData), 1000);
    }
  }
  //#endregion USAR FORMATO

  //#region EDITAR FORMATO
  const onEditarSuccess = () => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onEditarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onEditarFormato = (newData, oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: newData.nombre,
        key: pivotGridKey,
        configuracion: oldData.configuracion,
        esPublica: newData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onEditarSuccess, onEditarFail);
    }
    resolve();
  }
  //#endregion EDITAR FORMATO

  //#region CREAR FORMATO
  const onCrearSuccess = () => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onCrearFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onCrearFormato = (newData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey).fields;
      }

      let config = {
        id: null,
        nombre: newData.nombre,
        key: pivotGridKey,
        configuracion: JSON.stringify(fields),
        esPublica: newData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onCrearSuccess, onCrearFail);
    }
    resolve();
  }
  //#endregion CREAR FORMATO

  //#region BORRAR FORMATO
  const onBorrarSuccess = () => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onBorrarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onBorrarFormato = (oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: oldData.nombre,
        key: pivotGridKey,
        configuracion: oldData.configuracion,
        esPublica: oldData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        borrado: true,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onBorrarSuccess, onBorrarFail);
    }
    resolve();
  }
  //#endregion BORRAR FORMATO

  return isLoading ? (
    <div style={{ textAlign: 'center' }}>
      <Spinner />
    </div>
  ) : (
    <form id="adminPivotGrid" autoComplete="off">
      <Grid container>
        <Grid item xs={12} container spacing={1}
          justifyContent='space-between'>
          <Grid item xs={12} container alignContent='flex-start'>
            <Grid item xs={12}>
              <Typography>
                <h3 style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }}>Administrar Formatos</h3>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table
                style={{ width: '100%' }}
                title=""
                noBoxShadow
                columns={columns}
                data={configuracion && configuracion.configuraciones.filter(c => !c.borrado)}
                editable={{
                  isEditable: rowData => rowData.usuarioId === idUsuario || isAdmin,
                  isDeletable: rowData => rowData.usuarioId === idUsuario,
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      onEditarFormato(newData, oldData, resolve);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      onCrearFormato(newData, resolve);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      onBorrarFormato(oldData, resolve);
                    }),
                }}
                actions={[
                  rowData => {
                    const active = rowData.usuarioId === idUsuario || isAdmin;
                    return {
                      icon: () => (<Tooltip title="Actualizar estructura"><SaveIcon style={{ color: (active ? '#f88d37' : '#bdbdbd') }} /></Tooltip>),
                      tooltip: "Actualizar formato",
                      onClick: (event, rowData) => {
                        setFormatData(rowData)
                        handleActualizarFormatoConfirmDialogOpen();
                      },
                      disabled: !active
                    }
                  },
                  {
                    icon: () => <GetAppIcon style={{ color: '#10c5ff' }} />,
                    tooltip: "Usar formato",
                    onClick: (event, rowData) => onUsarFormato(rowData),
                  },
                ]}
                customOptions={{
                  thirdSortClick: false,
                  sorting: true,
                  grouping: false,
                  draggable: false,
                  search: false,
                  paging: true,
                  toolbar: true,
                  showTitle: false,
                  maxBodyHeight: 350,
                  addRowPosition: 'first',
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50]
                }}
                tableKey={"tabla-administrar-formatos"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}
            container justifyContent='space-between'>
            <Grid item xs={5}>
              <ButtonPivotGrid
                tooltipTitle="Cerrar ventana"
                buttonSize="small"
                text="Cerrar"
                disabled={false}
                onClick={onCancelClick}
                loading={false}
                icon={<CloseOutlinedIcon fontSize="small" />}
                backgroundImage="linear-gradient(45deg, #fbb040, #f15a29)"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {actualizarFormatoConfirmDialog()}
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    pivotGridConfiguraciones: state.tenacta.pivotGridConfiguraciones.items,
    selectedTotalRowsItems: state.tenacta.selectedTotalRows.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadConfiguraciones: (key, idUsuario) =>
      dispatch(actions.loadPivotGridConfiguraciones(key, idUsuario)),
    onSaveConfiguracion: (configuracion, onSuccess, onError) =>
      dispatch(actions.guardarPivotGridConfiguracion(configuracion, onSuccess, onError)),
    onUpdateSelectedTotalRows: (key, rows) => dispatch(actions.updateSelectedTotalRows(key, rows)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrarFormato);