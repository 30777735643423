import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import LineComparacionVentasEntreVendedores from '../../components/Indicadores/Vendedores/LineComparacionVentasEntreVendedores/LineComparacionVentasEntreVendedores';
import LineEvolucionVentasVendedores from '../../components/Indicadores/Vendedores/LineEvolucionVentasVendedores/LineEvolucionVentasVendedores';
import TablaCuboVentasVendedores from '../../components/Indicadores/Vendedores/TablaCuboVentasVendedores/TablaCuboVentasVendedores';
import SunburstVentasPorVendedorPorSeleccion from '../../components/Indicadores/Vendedores/SunburstVentasPorVendedorPorSeleccion/SunburstVentasPorVendedorPorSeleccion';
import classes from './AnalisisDeVendedores.css';
import TablaVentasDeVendedoresPorSeleccion from '../../components/Indicadores/Vendedores/TablaVentasDeVendedoresPorSeleccion/TablaVentasDeVendedoresPorSeleccion';
import BarVentasDeVendedoresPorSeleccion from '../../components/Indicadores/Vendedores/BarVentasDeVendedoresPorSeleccion/BarVentasDeVendedoresPorSeleccion';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  lineEvolucionVentas: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));

const Comparacion = (props) => {
  const classes2 = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={12} lg={4}>
          <TablaCuboVentasVendedores ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <LineEvolucionVentasVendedores ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} container spacing={1} className={classes.CombinedChartsCard}>
        <Grid item xs={12} lg={4}>
          <SunburstVentasPorVendedorPorSeleccion />
        </Grid>
        <Grid item xs={12} lg={8}>
          <BarVentasDeVendedoresPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <TablaVentasDeVendedoresPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
      <Grid item className={classes2.lineEvolucionVentas}>
        <LineComparacionVentasEntreVendedores ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Comparacion);
