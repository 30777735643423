import axios from '../../axios-dashboard';
import { fechaActualFunc } from '../../shared/fechas';
import idIndicadores from '../../shared/idIndicadores';
import { storageMonedaId, storageToken, storageEmpresaId, storageFechaFija } from '../../shared/sessionData';
import * as actionTypes from './actionTypes';

export const seleccionVendedorAnalisisVendedores = (vendedor) => {
  return {
    type: actionTypes.SELECCION_VENDEDOR_ANALISIS_VENDEDORES,
    vendedor
  };
};

export const seleccionDataVentasDelMesDelVendedorPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR,
    selectionId
  };
};

export const seleccionFechaVentasDelMesAnioDelVendedorPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR,
    mes,
    anio
  };
};

export const seleccionDataVentasDelMesDeVendedoresPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR,
    selectionId
  };
};

export const seleccionFechaVentasDelMesAnioDeVendedores = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES,
    mes,
    anio
  };
};

export const seleccionDataEvolucionVentasDelvendedor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_EVOLUCION_VENTAS_DEL_VENDEDOR,
    selectionId
  };
};

export const loadEvolucionDeVentasMensualDelVendedor = (idVendedor, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeVentasMensualDelVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .get(
        `/ventas/VentasPorMes?${
          idVendedor ? `&idVendedor=${idVendedor}&` : ''
        }idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.evolucionVentasMensualDeUnVendedor}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalVentas += venta.monto);
        });
        dispatch(loadEvolucionDeVentasMensualDelVendedorSuccess(response.data, formatVentasParaTabla, totalVentas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeVentasMensualDelVendedorFail(error));
          } else {
            dispatch(loadEvolucionDeVentasMensualDelVendedor(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeVentasMensualDelVendedorFail(error));
        }
      });
  };
};

export const clearEvolucionDeVentasMensualDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR
  };
};

const loadEvolucionDeVentasMensualDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START
  };
};

const loadEvolucionDeVentasMensualDelVendedorSuccess = (ventas, ventasDataTable, totalVentas) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasDataTable,
    totalVentas
  };
};

const loadEvolucionDeVentasMensualDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasPorCategoriaDeClienteDelVendedor = (
  idVendedor,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId()
) => {
  return (dispatch) => {
    dispatch(loadVentasPorCategoriaDeClienteDelVendedorStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorCategoriaDeCliente?idVendedor=${idVendedor}&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDeUnVendedorPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);
        const formatVentasDataTable = [];

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 
          ? new Date(response.data[0].anio, response.data[0].mes - 1) 
          : anio !== 0 && mes !== 0
            ? new Date(`${mes}/01/${anio}`)
            : storageFechaFija();

        dispatch(seleccionFechaVentasDelMesAnioDelVendedorPor(fecha.getMonth(), fecha.getFullYear()));
        dispatch(
          loadVentasPorCategoriaDeClienteDelVendedorSuccess(
            response.data,
            ventasNegativo,
            formatVentasDataTable,
            fecha,
            totalVentas
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasPorCategoriaDeClienteDelVendedorFail(error));
          } else {
            dispatch(loadVentasPorCategoriaDeClienteDelVendedor(mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadVentasPorCategoriaDeClienteDelVendedorFail(error));
        }
      });
  };
};

export const clearVentasPorCategoriaDeClienteDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR
  };
};

const loadVentasPorCategoriaDeClienteDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START
  };
};

const loadVentasPorCategoriaDeClienteDelVendedorSuccess = (ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    ventasDataTable,
    fecha,
    total
  };
};

const loadVentasPorCategoriaDeClienteDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorMarcaDelVendedor = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorMarcaDelVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorMarca?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeUnVendedorPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1)
              : new Date(anio, mes - 1);

          dispatch(seleccionFechaVentasDelMesAnioDelVendedorPor(fecha.getMonth(), fecha.getFullYear()));
          dispatch(loadVentasDelMesPorMarcaDelVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorMarcaDelVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorMarcaDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR
  };
};

const loadVentasDelMesPorMarcaDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START
  };
};

const loadVentasDelMesPorMarcaDelVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDelMesPorMarcaDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorRubroDelVendedor = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorRubroDelVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .get(
        `/ventas/VentasDelMesPorRubro?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeUnVendedorPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1)
              : new Date(anio, mes - 1);

          dispatch(seleccionFechaVentasDelMesAnioDelVendedorPor(fecha.getMonth(), fecha.getFullYear()));
          dispatch(loadVentasDelMesPorRubroDelVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorRubroDelVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorRubroDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR
  };
};

const loadVentasDelMesPorRubroDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START
  };
};

const loadVentasDelMesPorRubroDelVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDelMesPorRubroDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorSubrubroDelVendedor = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorSubrubroDelVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorSubrubro?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeUnVendedorPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1)
              : new Date(anio, mes - 1);

          dispatch(seleccionFechaVentasDelMesAnioDelVendedorPor(fecha.getMonth(), fecha.getFullYear()));

          dispatch(loadVentasDelMesPorSubrubroDelVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorSubrubroDelVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorSubrubroDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR
  };
};

const loadVentasDelMesPorSubrubroDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START
  };
};

const loadVentasDelMesPorSubrubroDelVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDelMesPorSubrubroDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorProvinciaDelVendedor = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorProvinciaDelVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorProvincia?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeUnVendedorPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1)
              : new Date(anio, mes - 1);

          dispatch(seleccionFechaVentasDelMesAnioDelVendedorPor(fecha.getMonth(), fecha.getFullYear()));
          dispatch(loadVentasDelMesPorProvinciaDelVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorProvinciaDelVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorProvinciaDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR
  };
};

const loadVentasDelMesPorProvinciaDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START
  };
};

const loadVentasDelMesPorProvinciaDelVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDelMesPorProvinciaDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorZonaDelVendedor = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorZonaDelVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorZona?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeUnVendedorPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1)
              : new Date(anio, mes - 1);

          dispatch(seleccionFechaVentasDelMesAnioDelVendedorPor(fecha.getMonth(), fecha.getFullYear()));

          dispatch(loadVentasDelMesPorZonaDelVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorZonaDelVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorZonaDelVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR
  };
};

const loadVentasDelMesPorZonaDelVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START
  };
};

const loadVentasDelMesPorZonaDelVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDelMesPorZonaDelVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorArticuloDeLaMarcaDelVendedor = (idVendedor, marcaId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDeLaMarca?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMarca=${marcaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelRubroDelVendedor = (idVendedor, rubroId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelRubro?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idRubro=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelSubrubroDelVendedor = (
  idVendedor,
  subrubroId,
  mes,
  anio,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelSubrubro?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idSubrubro=${subrubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaProvinciaDelVendedor = (
  idVendedor,
  provinciaId,
  mes,
  anio,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaProvincia?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaZonaDelVendedor = (idVendedor, zonaId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaZona?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelVendedor = (vendedorId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelArticuloPorClienteDelVendedor = (idVendedor, idArticlo, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelArticuloPorCliente?mes=${mes}&anio=${anio}&idArticulo=${idArticlo}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelArticuloPorCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelArticuloAlClienteDelVendedor = (
  idVendedor,
  idArticlo,
  idCliente,
  mes,
  anio,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelArticuloAlCliente?mes=${mes}&anio=${anio}&idArticulo=${idArticlo}&idCliente=${idCliente}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelArticuloAlCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelClienteDelVendedor = (
  idVendedor,
  idCliente,
  mes,
  anio,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelCliente?mes=${mes}&anio=${anio}&idVendedor=${idVendedor.trim()}&idCliente=${idCliente.trim()}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadEvolucionVentasDelVendedorPorCategoriaDeCliente = (
  idVendedor,
  idEmpresa = storageEmpresaId(),
  success,
  errorFail
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDelVendedorPorCategoriaDeClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorCategoriaDeCliente?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDeUnVendedorEvolutivoPorCategoriaDeCliente}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDelVendedorPorCategoriaDeClienteSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          if (error.response) {
            if (error.response.statusCode === 500) {
              dispatch(loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail(error));
            } else {
              dispatch(loadEvolucionVentasDelVendedorPorCategoriaDeCliente());
            }
          } else {
            dispatch(loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail(error));
          }
        }
      });
  };
};

export const clearEvolucionVentasDelVendedorPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadEvolucionVentasDelVendedorPorProvincias = (idVendedor, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDelVendedorPorProvinciasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorProvincia?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDeUnVendedorEvolutivoPorProvincia}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDelVendedorPorProvinciasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasDelVendedorPorProvinciasFail(error));
          } else {
            dispatch(loadEvolucionVentasDelVendedorPorProvincias());
          }
        } else {
          dispatch(loadEvolucionVentasDelVendedorPorProvinciasFail(error));
        }
      });
  };
};

export const clearEvolucionVentasDelVendedorPorProvincias = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS
  };
};

const loadEvolucionVentasDelVendedorPorProvinciasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START
  };
};

const loadEvolucionVentasDelVendedorPorProvinciasSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDelVendedorPorProvinciasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL,
    error
  };
};

export const loadEvolucionVentasDelVendedorPorZonas = (idVendedor, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDelVendedorPorZonasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorZona?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDeUnVendedorEvolutivoPorZona}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDelVendedorPorZonasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasDelVendedorPorZonasFail(error));
          } else {
            dispatch(loadEvolucionVentasDelVendedorPorZonas());
          }
        } else {
          dispatch(loadEvolucionVentasDelVendedorPorZonasFail(error));
        }
      });
  };
};

export const clearEvolucionVentasDelVendedorPorZonas = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS
  };
};

const loadEvolucionVentasDelVendedorPorZonasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START
  };
};

const loadEvolucionVentasDelVendedorPorZonasSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDelVendedorPorZonasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL,
    error
  };
};

export const loadEvolucionVentasDelVendedorPorMarcas = (idVendedor, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDelVendedorPorMarcasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorMarca?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDeUnVendedorEvolutivoPorMarca}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDelVendedorPorMarcasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasDelVendedorPorMarcasFail(error));
          } else {
            dispatch(loadEvolucionVentasDelVendedorPorMarcas());
          }
        } else {
          dispatch(loadEvolucionVentasDelVendedorPorMarcasFail(error));
        }
      });
  };
};

export const clearEvolucionVentasDelVendedorPorMarcas = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA
  };
};

const loadEvolucionVentasDelVendedorPorMarcasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START
  };
};

const loadEvolucionVentasDelVendedorPorMarcasSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDelVendedorPorMarcasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL,
    error
  };
};

export const loadEvolucionVentasDelVendedorPorRubros = (idVendedor, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDelVendedorPorRubrosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorRubro?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDeUnVendedorEvolutivoPorRubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDelVendedorPorRubrosSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasDelVendedorPorRubrosFail(error));
          } else {
            dispatch(loadEvolucionVentasDelVendedorPorRubros());
          }
        } else {
          dispatch(loadEvolucionVentasDelVendedorPorRubrosFail(error));
        }
      });
  };
};

export const clearEvolucionVentasDelVendedorPorRubros = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO
  };
};

const loadEvolucionVentasDelVendedorPorRubrosStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START
  };
};

const loadEvolucionVentasDelVendedorPorRubrosSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDelVendedorPorRubrosFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL,
    error
  };
};

export const loadEvolucionVentasDelVendedorPorSubrubro = (idVendedor, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDelVendedorPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorSubrubro?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDeUnVendedorEvolutivoPorSubrubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDelVendedorPorSubrubroSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasDelVendedorPorSubrubroFail(error));
          } else {
            dispatch(loadEvolucionVentasDelVendedorPorSubrubro());
          }
        } else {
          dispatch(loadEvolucionVentasDelVendedorPorSubrubroFail(error));
        }
      });
  };
};

export const clearEvolucionVentasDelVendedorPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO
  };
};

const loadEvolucionVentasDelVendedorPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START
  };
};

const loadEvolucionVentasDelVendedorPorSubrubroSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDelVendedorPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadVentasDelMesPorCategoriaDeClienteDelVendedor = (idVendedor, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorCategoriaDeCliente?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadEvolucionDeVentasMensualDeVendedores = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeVentasMensualDeVendedoresStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMes?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasMensual
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalVentas += venta.monto);
        });
        dispatch(loadEvolucionDeVentasMensualDeVendedoresSuccess(response.data, formatVentasParaTabla, totalVentas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeVentasMensualDeVendedoresFail(error));
          } else {
            dispatch(loadEvolucionDeVentasMensualDeVendedores(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeVentasMensualDeVendedoresFail(error));
        }
      });
  };
};

export const clearEvolucionDeVentasMensualDeVendedores = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES
  };
};

const loadEvolucionDeVentasMensualDeVendedoresStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START
  };
};

const loadEvolucionDeVentasMensualDeVendedoresSuccess = (ventas, ventasDataTable, totalVentas) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS,
    ventas,
    ventasDataTable,
    totalVentas
  };
};

const loadEvolucionDeVentasMensualDeVendedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL,
    error
  };
};

export const loadVentasPorVendedorPorMarcaDelMesSumburstFormat = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPorMarcaDelMesSumburstFormatStart());
    //const authStr = 'Bearer '.concat(storageToken());

    const fakeLabels = ['horacio', 'cain', 'seth', 'enos'];
    const fakeParents = ['Vendedores', 'horacio', 'horacio', 'cain'];
    const fakeValues = [10, 20, 30, 40];
    const fakeDate = new Date(anio, mes, 1);

    return setTimeout(() => {
      return dispatch(
        loadVentasPorVendedorPorMarcaDelMesSumburstFormatSuccess(fakeLabels, fakeParents, fakeValues, fakeDate)
      );
    }, 1000);

    /*axios
      .get(``, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        const { labels, parents, values } = response.data;

        dispatch(loadVentasPorVendedorPorMarcaDelMesSumburstFormatSuccess(labels, parents, values));
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPorMarcaDelMesSumburstFormatFail(error));
      });*/
  };
};

export const clearVentasPorVendedorPorMarcaDelMesSumburstFormat = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT
  };
};

const loadVentasPorVendedorPorMarcaDelMesSumburstFormatStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_START
  };
};

const loadVentasPorVendedorPorMarcaDelMesSumburstFormatSuccess = (labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_SUCCESS,
    labels,
    parents,
    values,
    fecha
  };
};

/*const loadVentasPorVendedorPorMarcaDelMesSumburstFormatFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_FAIL,
    error
  };
};*/

export const loadVentasPorVendedorPorCategoriaDeClienteDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPorCategoriaDeClienteDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/vendedores/VentasPorVendedorPorCategoriaDeClienteDelMes?mes=${mes}&anio=${anio}&idEmpresa=${storageEmpresaId()}&idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { vendedores, labels, parents, values } = response.data;

        return dispatch(
          loadVentasPorVendedorPorCategoriaDeClienteDelMesSuccess(vendedores, labels, parents, values, date)
        );
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPorCategoriaDeClienteDelMesFail(error));
      });
  };
};

export const clearVentasPorVendedorPorCategoriaDeClienteDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES
  };
};

const loadVentasPorVendedorPorCategoriaDeClienteDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START
  };
};

const loadVentasPorVendedorPorCategoriaDeClienteDelMesSuccess = (vendedores, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS,
    vendedores,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorVendedorPorCategoriaDeClienteDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorVendedorPorMarcaDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPorMarcaDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());

    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/vendedores/VentasPorVendedorPorMarcaDelMes?mes=${mes}&anio=${anio}&idEmpresa=${storageEmpresaId()}&idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { vendedores, labels, parents, values } = response.data;

        dispatch(loadVentasPorVendedorPorMarcaDelMesSuccess(vendedores, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPorMarcaDelMesFail(error));
      });
  };
};

export const clearVentasPorVendedorPorMarcaDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES
  };
};

const loadVentasPorVendedorPorMarcaDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_START
  };
};

const loadVentasPorVendedorPorMarcaDelMesSuccess = (vendedores, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUCCESS,
    vendedores,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorVendedorPorMarcaDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorVendedorPorRubroDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPorRubroDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/vendedores/VentasPorVendedorPorRubroDelMes?mes=${mes}&anio=${anio}&idEmpresa=${storageEmpresaId()}&idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { vendedores, labels, parents, values } = response.data;

        return dispatch(loadVentasPorVendedorPorRubroDelMesSuccess(vendedores, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPorRubroDelMesFail(error));
      });
  };
};

export const clearVentasPorVendedorPorRubroDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES
  };
};

const loadVentasPorVendedorPorRubroDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_START
  };
};

const loadVentasPorVendedorPorRubroDelMesSuccess = (vendedores, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_SUCCESS,
    vendedores,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorVendedorPorRubroDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorVendedorPorSubrubroDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPorSubrubroDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/vendedores/VentasPorVendedorPorSubrubroDelMes?mes=${mes}&anio=${anio}&idEmpresa=${storageEmpresaId()}&idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { vendedores, labels, parents, values } = response.data;

        return dispatch(loadVentasPorVendedorPorSubrubroDelMesSuccess(vendedores, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPorSubrubroDelMesFail(error));
      });
  };
};

export const clearVentasPorVendedorPorSubrubroDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES
  };
};

const loadVentasPorVendedorPorSubrubroDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_START
  };
};

const loadVentasPorVendedorPorSubrubroDelMesSuccess = (vendedores, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_SUCCESS,
    vendedores,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorVendedorPorSubrubroDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorVendedorPorProvinciaDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPorProvinciaDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/vendedores/VentasPorVendedorPorProvinciaDelMes?mes=${mes}&anio=${anio}&idEmpresa=${storageEmpresaId()}&idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { ventas } = response.data;

        return dispatch(loadVentasPorVendedorPorProvinciaDelMesSuccess(ventas, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPorProvinciaDelMesFail(error));
      });
  };
};

export const clearVentasPorVendedorPorProvinciaDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES
  };
};

const loadVentasPorVendedorPorProvinciaDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_START
  };
};

const loadVentasPorVendedorPorProvinciaDelMesSuccess = (ventas, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_SUCCESS,
    ventas,
    fecha
  };
};

const loadVentasPorVendedorPorProvinciaDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorVendedorPorZonaDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorPoZonaDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/vendedores/VentasPorVendedorPorZonaDelMes?mes=${mes}&anio=${anio}&idEmpresa=${storageEmpresaId()}&idMoneda=${storageMonedaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { ventas } = response.data;

        return dispatch(loadVentasPorVendedorPoZonaDelMesSuccess(ventas, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorVendedorPoZonaDelMesFail(error));
      });
  };
};

export const clearVentasPorVendedorPoZonaDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES
  };
};

const loadVentasPorVendedorPoZonaDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_START
  };
};

const loadVentasPorVendedorPoZonaDelMesSuccess = (ventas, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_SUCCESS,
    ventas,
    fecha
  };
};

const loadVentasPorVendedorPoZonaDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_FAIL,
    error
  };
};

export const loadVentasDeVendedoresPorCategoriaDeCliente = (mes = 0, anio = 0, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadVentasDeVendedoresPorCategoriaDeClienteStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorCategoriaDeCliente?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);
        const formatVentasDataTable = [];

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = fechaActualFunc();
        fecha = response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date();

        dispatch(
          loadVentasDeVendedoresPorCategoriaDeClienteSuccess(
            response.data,
            ventasNegativo,
            formatVentasDataTable,
            fecha,
            totalVentas
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasDeVendedoresPorCategoriaDeClienteFail(error));
          } else {
            dispatch(loadVentasDeVendedoresPorCategoriaDeCliente(mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadVentasDeVendedoresPorCategoriaDeClienteFail(error));
        }
      });
  };
};

export const clearVentasDeVendedoresPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadVentasDeVendedoresPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadVentasDeVendedoresPorCategoriaDeClienteSuccess = (
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  totalVentas
) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    ventasNegativo,
    ventasDataTable,
    fecha,
    totalVentas
  };
};

const loadVentasDeVendedoresPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadVentasDeVendedoresDelMesPorMarca = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeVendedoresDelMesPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorMarca?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date(anio, mes);

          dispatch(loadVentasDeVendedoresDelMesPorMarcaSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeVendedoresDelMesPorMarcaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeVendedoresDelMesPorMarca = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA
  };
};

const loadVentasDeVendedoresDelMesPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_START
  };
};

const loadVentasDeVendedoresDelMesPorMarcaSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeVendedoresDelMesPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_FAIL,
    error
  };
};

export const loadVentasDeVendedoresDelMesPorRubro = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeVendedoresDelMesPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorRubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date();

          dispatch(loadVentasDeVendedoresDelMesPorRubroSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeVendedoresDelMesPorRubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeVendedoresDelMesPorRubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO
  };
};

const loadVentasDeVendedoresDelMesPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_START
  };
};

const loadVentasDeVendedoresDelMesPorRubroSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeVendedoresDelMesPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_FAIL,
    error
  };
};

export const loadVentasDeVendedoresDelMesPorSubrubro = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeVendedoresDelMesPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorSubrubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date();

          dispatch(loadVentasDeVendedoresDelMesPorSubrubroSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeVendedoresDelMesPorSubrubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeVendedoresDelMesPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO
  };
};

const loadVentasDeVendedoresDelMesPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_START
  };
};

const loadVentasDeVendedoresDelMesPorSubrubroSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeVendedoresDelMesPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadVentasDeVendedoresDelMesPorVendedor = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeVendedoresDelMesPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedor?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));

          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date(anio, mes);
          dispatch(loadVentasDeVendedoresDelMesPorVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeVendedoresDelMesPorVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeVendedoresDelMesPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR
  };
};

const loadVentasDeVendedoresDelMesPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_START
  };
};

const loadVentasDeVendedoresDelMesPorVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeVendedoresDelMesPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDeVendedoresDelMesPorProvincia = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentassDeVendedoresDelMesPorProvinciaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorProvincia?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date();

          dispatch(loadVentassDeVendedoresDelMesPorProvinciaSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentassDeVendedoresDelMesPorProvinciaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentassDeVendedoresDelMesPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA
  };
};

const loadVentassDeVendedoresDelMesPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_START
  };
};

const loadVentassDeVendedoresDelMesPorProvinciaSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentassDeVendedoresDelMesPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_FAIL,
    error
  };
};

export const loadVentasDeVendedoresDelMesPorZona = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeVendedoresDelMesPorZonaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorZona?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1) : new Date(anio, mes);

          dispatch(loadVentasDeVendedoresDelMesPorZonaSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeVendedoresDelMesPorZonaFail(error));
        return errorFail(error);
      });
  };
};

export const clearVentasDeVendedoresDelMesPorZona = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA
  };
};

const loadVentasDeVendedoresDelMesPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_START
  };
};

const loadVentasDeVendedoresDelMesPorZonaSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeVendedoresDelMesPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_FAIL,
    error
  };
};

export const loadVentasPorVendedorDeLaCategoriaDeCliente = (mes, anio, categoriaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedorDeLaCategoria?mes=${mes}&anio=${anio}&idCategoria=${categoriaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadVentasPorVendedorDeLaMarca = (mes, anio, marcaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedorDeLaMarca?mes=${mes}&anio=${anio}&idMarca=${marcaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadVentasPorVendedorDelRubro = (mes, anio, rubroId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedorDelRubro?mes=${mes}&anio=${anio}&idRubro=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadVentasPorVendedorDelSubrubro = (mes, anio, subrubroId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedorDelSubrubro?mes=${mes}&anio=${anio}&idSubrubro=${subrubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadVentasPorVendedorDeLaProvincia = (mes, anio, provinciaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedorDeLaProvincia?mes=${mes}&anio=${anio}&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadVentasPorVendedorDeLaZona = (mes, anio, zonaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedorDeLaZona?mes=${mes}&anio=${anio}&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadVentasDelMesPorClienteDelVendedor = (mes, anio, vendedorId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorClienteDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorClienteDelVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadEvolucionVentasDeVendedoresPorVendedor = (idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeVendedoresPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorVendedor?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeVendedoresPorVendedorSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        dispatch(loadEvolucionVentasDeVendedoresPorVendedorFail(error));
      });
  };
};

export const clearEvolucionVentasDeVendedoresPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR
  };
};

const loadEvolucionVentasDeVendedoresPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_START
  };
};

const loadEvolucionVentasDeVendedoresPorVendedorSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasDeVendedoresPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_FAIL,
    error
  };
};