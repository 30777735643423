import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utilities'

const initialState = {
    stockValorizado: {
        stock: [],
        orden: null,
        loading: false, 
        estaCargando: false, 
        error: null, 
        ejecucionIndicador: false,
    }, 
    stockPorDeposito: {
      stock:[],
      loading: false,
      estaCargando: false, 
      error: null
    }
}

//#region STOCK VALORIZADO
  const stockValorizadoStart = (state) => {
    return updateObject(state, {
        stockValorizado: updateObject(state.stockValorizado, {
        stock: [],
        estaCargado: false,
        loading: true,
        error: null
      })
    });
  };
  
  const stockValorizadoSuccess = (state, stock, orden) => {
    return updateObject(state, {
        stockValorizado: updateObject(state.stockValorizado, {
        stock,
        orden,
        estaCargado: true,
        loading: false,
        error: null
      })
    });
  };
  
  const stockValorizadoFail = (state, error) => {
    return updateObject(state, {
        stockValorizado: updateObject(state.stockValorizado, {
        stock: [],
        estaCargado: true,
        loading: false,
        error
      })
    });
  };
  
  const clearStockValorizado = (state) => {
    return updateObject(state, {
        stockValorizado: updateObject(state.stockValorizado, {
        stock: [],
        orden: null,
        estaCargado: false,
        loading: false,
        error: null
      })
    });
  }

  const ejecucionStockValorizado = (state, ejecucion) => {  
    return updateObject(state, {
      stockValorizado: updateObject(state.stockValorizado, {
        ejecucionIndicador: ejecucion,
      }),
    });
  }
//#endregion

//#region STOCK POR DEPÓSITO
  const stockPorDepositoStart = (state) => {
    return updateObject(state, {
      stockPorDeposito: updateObject(state.stockPorDeposito, {
        stock: [],
        estaCargado: false,
        loading: true,
        error: null
      })
    });
  };

  const stockPorDepositoSuccess = (state, stock) => {
    return updateObject(state, {
      stockPorDeposito: updateObject(state.stockPorDeposito, {
        stock,
        estaCargado: true,
        loading: false,
        error: null
      })
    });
  };

  const stockPorDepositoFail = (state, error) => {
    return updateObject(state, {
      stockPorDeposito: updateObject(state.stockPorDeposito, {
        stock: [],
        estaCargado: true,
        loading: false,
        error
      })
    });
  };

  const clearStockPorDeposito = (state) => {
    return updateObject(state, {
      stockPorDeposito: updateObject(state.stockPorDeposito, {
        stock: [],
        estaCargado: false,
        loading: false,
        error: null
      })
    });
  }
//#endregion

const reducer = (state = initialState, action) => {
    switch(action.type){

      //#region STOCK VALORIZADO
      case actionTypes.STOCK_VALORIZADO_START:
        return stockValorizadoStart(state);
      case actionTypes.STOCK_VALORIZADO_SUCCESS:
        return stockValorizadoSuccess(state, action.stock, action.orden);
      case actionTypes.STOCK_VALORIZADO_FAIL:
        return stockValorizadoFail(state, action.error);
      case actionTypes.CLEAR_STOCK_VALORIZADO:
        return clearStockValorizado(state);
      case actionTypes.EJECUCION_STOCK_VALORIZADO:
        return ejecucionStockValorizado(state, action.ejecucion);
      //#endregion

      //#region STOCK POR DEPÓSITO 
      case actionTypes.STOCK_POR_DEPOSITO_START:
        return stockPorDepositoStart(state);
      case actionTypes.STOCK_POR_DEPOSITO_SUCCESS:
        return stockPorDepositoSuccess(state, action.stock);
      case actionTypes.STOCK_POR_DEPOSITO_FAIL:
        return stockPorDepositoFail(state, action.error);
      case actionTypes.CLEAR_STOCK_POR_DEPOSITO:
        return clearStockPorDeposito(state);
      //#endregion
      
        default:
            return state
    }
}

export default reducer