const findProduct = (arr, row) => {
  return arr.find(p =>
    p.tipoVenta === row.B.toString().trim()
    && p.idArticulo === row.C.toString().trim());
}

const createMonth = () => {
  return {
    unidades: 0,
    importeNeto: 0,
    importeBruto: 0
  };
}

const createProduct = (data) => {
  return {
    affectedRows: "",
    tipoVenta: data.B.toString().trim(),
    idArticulo: data.C.toString().trim(),
    meses: [
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth(),
      createMonth()
    ]
  };
};

const fillProduct = (product, data) => {
  product.affectedRows += (product.affectedRows.trim() === '' ? '' : ', ') + data.__rowNum__; 
  switch (data.A)
  {
    case 'C':
      fillMonths(product, data, 'unidades'); break;
    case 'N':
      fillMonths(product, data, 'importeNeto'); break;
    case 'B':
      fillMonths(product, data, 'importeBruto'); break;
  }
};

const fillMonths = (product, data, field) => {
  ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"].forEach((col, index) => {
    product.meses[index][field] = data[col];
  });
}

export const squash = (json) => {
  var result = [];

  json.forEach(row => {
    var product = findProduct(result, row);
    if (product) {
      fillProduct(product, row);
    }
    else {
      var newProduct = createProduct(row);
      fillProduct(newProduct, row);
      result.push(newProduct);
    }
  });

  return result;
}