import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';
import { fechaActualFunc } from '../../shared/fechas';

const initialState = {
  pieGastosPorCategoria: {
    loading: false,
    error: null,
    estaCargado: false,
    gastos: [],
    categorias: [],
    gastosPorCategoriaDataTable: [],
    fecha: '',
    totalGastos: 0,
    gastosNegativos: []
  },
  pieGastosPorCategoriaSinDataTable: {
    loading: false,
    error: null,
    estaCargado: false,
    gastos: [],
    gastosNegativos: [],
    categorias: [],
    fecha: '',
    total: 0
  },
  textoGastosDelMes: {
    loading: false,
    error: null,
    estaCargado: false,
    mesActual: 0,
    mesAnterior: 0
  },
  evolucionGastosMensual: {
    gastos: [],
    evolucionGastosMensualDataTable: [],
    loading: false,
    error: null,
    estaCargado: false,
    totalGastos: 0
  },
  evolucionGastosMensualPorCategoria: {
    gastos: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  gastosPorItemDeCategoria: {
    gastos: [],
    nombreCategoria: '',
    fecha: '',
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionGastosPorCategoria: {
    gastos: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  comprobantesGastos: {
    comprobantes: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  detalleGastosDelItemDeCompra: {
    comprobantes: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionMensualInteranual: {
    periodos: [],
    periodoSeleccionado: fechaActualFunc(),
    items: []
  },
};

//#region load pie gastos por categoria
const loadPieGastosPorCategoriaStart = (state) => {
  return updateObject(state, {
    pieGastosPorCategoria: updateObject(state.pieGastosPorCategoria, {
      loading: true,
      error: null,
      estaCargado: false,
      gastos: [],
      categorias: [],
      fecha: '',
      totalGastos: 0,

      gastosNegativos: []
    })
  });
};

const loadPieGastosPorCategoriaSuccess = (
  state,
  gastos,
  categorias,
  gastosPorCategoriaDataTable,
  fecha,
  totalGastos,
  gastosNegativos
) => {
  return updateObject(state, {
    pieGastosPorCategoria: updateObject(state.pieGastosPorCategoria, {
      loading: false,
      gastos,
      gastosNegativos,
      categorias,
      gastosPorCategoriaDataTable,
      fecha,
      totalGastos,
      estaCargado: true
    })
  });
};

const loadPieGastosPorCategoriaFail = (state, error) => {
  return updateObject(state, {
    pieGastosPorCategoria: updateObject(state.pieGastosPorCategoria, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearPieGastosPorCategoria = (state) => {
  return updateObject(state, {
    pieGastosPorCategoria: updateObject(state.pieGastosPorCategoria, {
      loading: false,
      error: null,
      estaCargado: false,
      gastos: [],
      categorias: [],
      gastosPorCategoriaDataTable: [],
      fecha: '',
      totalGastos: 0
    })
  });
};
//#endregion

//#region load pie gastos por categoria Sin DataTable
const loadPieGastosPorCategoriaSinDataTableStart = (state) => {
  return updateObject(state, {
    pieGastosPorCategoriaSinDataTable: updateObject(state.pieGastosPorCategoriaSinDataTable, {
      loading: true,
      error: null,
      estaCargado: false,
      gastosNegativos: [],
      gastos: [],
      categorias: [],
      fecha: '',
      total: 0
    })
  });
};

const loadPieGastosPorCategoriaSinDataTableSuccess = (state, gastos, categorias, fecha, total, gastosNegativos) => {
  return updateObject(state, {
    pieGastosPorCategoriaSinDataTable: updateObject(state.pieGastosPorCategoriaSinDataTable, {
      loading: false,
      gastos,
      gastosNegativos,
      categorias,
      fecha,
      total,
      estaCargado: true
    })
  });
};

const loadPieGastosPorCategoriaSinDataTableFail = (state, error) => {
  return updateObject(state, {
    pieGastosPorCategoriaSinDataTable: updateObject(state.pieGastosPorCategoriaSinDataTable, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearPieGastosPorCategoriaSinDataTable = (state) => {
  return updateObject(state, {
    pieGastosPorCategoriaSinDataTable: updateObject(state.pieGastosPorCategoriaSinDataTable, {
      loading: false,
      error: null,
      estaCargado: false,
      gastos: [],
      gastosNegativos: [],
      categorias: [],
      fecha: '',
      total: 0
    })
  });
};
//#endregion

//#region texto gastos del mes
const loadTextoGastosDelMesStart = (state) => {
  return updateObject(state, {
    textoGastosDelMes: updateObject(state.textoGastosDelMes, {
      loading: true,
      error: null,
      estaCargado: false,
      mesActual: 0,
      mesAnterior: 0
    })
  });
};

const loadTextoGastosDelMesSuccess = (state, mesActual, mesAnterior) => {
  return updateObject(state, {
    textoGastosDelMes: updateObject(state.textoGastosDelMes, {
      loading: false,
      mesActual: mesActual,
      mesAnterior: mesAnterior,
      estaCargado: true
    })
  });
};

const loadTextoGastosDelMesFail = (state, error) => {
  return updateObject(state, {
    textoGastosDelMes: updateObject(state.textoGastosDelMes, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoGastosDelMes = (state) => {
  return updateObject(state, {
    textoGastosDelMes: updateObject(state.textoGastosDelMes, {
      loading: false,
      error: null,
      estaCargado: false,
      mesActual: 0,
      mesAnterior: 0
    })
  });
};
//#endregion

//#region Evolucion de gastos mensuales con objetivos
const loadEvolucionGastosMensualStart = (state) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      gastos: [],
      evolucionGastosMensualDataTable: [],
      loading: true,
      error: null,
      estaCargado: false,
      totalGastos: 0
    })
  });
};

const loadEvolucionGastosMensualSuccess = (
  state,
  evolucionGastosMensual,
  evolucionGastosMensualDataTable,
  totalGastos
) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      gastos: evolucionGastosMensual,
      loading: false,
      evolucionGastosMensualDataTable,
      totalGastos,
      estaCargado: true
    })
  });
};

const loadEvolucionGastosMensualFail = (state, error) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearEvolucionGastosMensual = (state) => {
  return updateObject(state, {
    evolucionGastosMensual: updateObject(state.evolucionGastosMensual, {
      gastos: [],
      evolucionGastosMensualDataTable: [],
      loading: false,
      error: null,
      estaCargado: false,
      totalGastos: 0
    })
  });
};
//#endregion

//#region Evolucion de gastos mensuales por categoria
const loadEvolucionGastosMensualPorCategoriaStart = (state) => {
  return updateObject(state, {
    evolucionGastosMensualPorCategoria: updateObject(state.evolucionGastosMensualPorCategoria, {
      gastos: [],
      total: 0,
      loading: true,
      error: null
    })
  });
};

const loadEvolucionGastosMensualPorCategoriaSuccess = (state, evolucionGastosMensualPorCategoria, total) => {
  return updateObject(state, {
    evolucionGastosMensualPorCategoria: updateObject(state.evolucionGastosMensualPorCategoria, {
      gastos: evolucionGastosMensualPorCategoria,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionGastosMensualPorCategoriaError = (state, error) => {
  return updateObject(state, {
    evolucionGastosMensualPorCategoria: updateObject(state.evolucionGastosMensualPorCategoria, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region Gastos por items de la categoria seleccionada
const loadGastosPorItemDeCategoriaSelecStart = (state) => {
  return updateObject(state, {
    gastosPorItemDeCategoria: updateObject(state.gastosPorItemDeCategoria, {
      loading: true,
      nombreCateogira: '',
      fecha: '',
      gastos: [],
      error: null
    })
  });
};

const loadGastosPorItemDeCategoriaSelecSuccess = (state, gastosPorItemDeCategoria, nombreCategoria, fecha) => {
  return updateObject(state, {
    gastosPorItemDeCategoria: updateObject(state.gastosPorItemDeCategoria, {
      gastos: gastosPorItemDeCategoria,
      nombreCategoria,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadGastosPorItemDeCategoriaSelecError = (state, error) => {
  return updateObject(state, {
    gastosPorItemDeCategoria: updateObject(state.gastosPorItemDeCategoria, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};
//#endregion

//#region load comprobantes de gastos
const loadComprobantesGastosStart = (state) => {
  return updateObject(state, {
    comprobantesGastos: updateObject(state.comprobantesGastos, {
      loading: true,
      comprobantes: [],
      error: null
    })
  });
};

const loadComprobantesGastosSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesGastos: updateObject(state.comprobantesGastos, {
      loading: false,
      comprobantes,
      estaCargado: true
    })
  });
};

const loadComprobantesGastosFail = (state, error) => {
  return updateObject(state, {
    comprobantesGastos: updateObject(state.comprobantesGastos, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load evolucion gastos por categoria
const loadEvolucionGastosPorCategoriaStart = (state) => {
  return updateObject(state, {
    evolucionGastosPorCategoria: updateObject(state.evolucionGastosPorCategoria, {
      gastos: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionGastosPorCategoriaSuccess = (state, gastos, fechas, total) => {
  return updateObject(state, {
    evolucionGastosPorCategoria: updateObject(state.evolucionGastosPorCategoria, {
      gastos,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionGastosPorCategoriaFail = (state, error) => {
  return updateObject(state, {
    evolucionGastosPorCategoria: updateObject(state.evolucionGastosPorCategoria, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionGastosPorCategoria = (state) => {
  return updateObject(state, {
    evolucionGastosPorCategoria: updateObject(state.evolucionGastosPorCategoria, {
      gastos: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load Detalle Gastos Del Item De Compra
const loadDetalleGastosDelItemDeCompraStart = (state) => {
  return updateObject(state, {
    detalleGastosDelItemDeCompra: updateObject(state.detalleGastosDelItemDeCompra, {
      comprobantes: [],
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadDetalleGastosDelItemDeCompraSuccess = (state, comprobantes) => {
  return updateObject(state, {
    detalleGastosDelItemDeCompra: updateObject(state.detalleGastosDelItemDeCompra, {
      comprobantes,
      loading: false,
      estaCargado: true
    })
  });
};

const loadDetalleGastosDelItemDeCompraFail = (state, error) => {
  return updateObject(state, {
    detalleGastosDelItemDeCompra: updateObject(state.detalleGastosDelItemDeCompra, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearDetalleGastosDelItemDeCompra = (state) => {
  return updateObject(state, {
    detalleGastosDelItemDeCompra: updateObject(state.detalleGastosDelItemDeCompra, {
      comprobantes: [],
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion de gastos interanual
const setItem = (state, anio, gastos, gastosDataTable, totalGastos, loading, estaCargado, error) => {
  let items = state.evolucionMensualInteranual.items;
  let findItem = items.find((item) => item.anio === anio);

  if(findItem !== undefined){
    items = items.filter((item) => item.anio !== anio);
  }

  let newItem =  {
    anio,
    gastos,
    gastosDataTable,
    totalGastos,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

const loadEvolucionDeGastosMensualInteranualStart = (state, anio) => {
  let items = setItem(state, anio, [], [], 0, true, false, null);

  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      items: items,
    })
  });
};

const loadEvolucionDeGastosMensualInteranualSuccess = (state, anio, gastos, gastosDataTable, totalGastos) => {
  let items = setItem(state, anio, gastos, gastosDataTable, totalGastos, false, true, null);
  
  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      items: items,
    })
  });
};

const loadEvolucionDeGastosMensualInteranualFail = (state, anio, error) => {
  let items = setItem(state, anio, [], [], 0, false, true, error);

  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      items: items,
    })
  });
};

const clearEvolucionDeGastosMensualInteranual = (state, periodo) => {
  let anio = periodo.getFullYear();
  let items = state.evolucionMensualInteranual.items
    .filter((item) => item.anio !== anio);
  let periodos = state.evolucionMensualInteranual.periodos
    .filter((per) => per.getFullYear() !== periodo.getFullYear());

  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      items: items,
      periodos: periodos,
    })
  });
};

const clearEvolucionesDeGastosMensualInteranual = (state) => {
  let periodos = state.evolucionMensualInteranual.periodos;

  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      items: [],
      periodos: periodos,
    })
  });
};

const agregarEvolucionDeGastosMensualInteranual = (state, periodo) => {
  let periodos = state.evolucionMensualInteranual.periodos;
  periodos.push(periodo);

  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      periodos: periodos,
    })
  });
}

const seleccionFechaGastosDelMesAnio = (state, mes, anio) => {
  return updateObject(state, {
    evolucionMensualInteranual: updateObject(state.evolucionMensualInteranual, {
      periodoSeleccionado: new Date(anio, mes - 1, 1),
    })
  });
}
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PIE_GASTOS_POR_CATEGORIA_START:
      return loadPieGastosPorCategoriaStart(state);

    case actionTypes.PIE_GASTOS_POR_CATEGORIA_SUCCESS:
      return loadPieGastosPorCategoriaSuccess(
        state,
        action.gastos,
        action.categorias,
        action.gastosPorCategoriaDataTable,
        action.fecha,
        action.totalGastos,
        action.gastosNegativos
      );
    case actionTypes.CLEAR_PIE_GASTOS_POR_CATEGORIA:
      return clearPieGastosPorCategoria(state);

    case actionTypes.PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_START:
      return loadPieGastosPorCategoriaSinDataTableStart(state);
    case actionTypes.PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_SUCCESS:
      return loadPieGastosPorCategoriaSinDataTableSuccess(
        state,
        action.gastos,
        action.categorias,
        action.fecha,
        action.total,
        action.gastosNegativos
      );
    case actionTypes.PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_FAIL:
      return loadPieGastosPorCategoriaSinDataTableFail(state, action.error);
    case actionTypes.CLEAR_PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE:
      return clearPieGastosPorCategoriaSinDataTable(state);

    case actionTypes.PIE_GASTOS_POR_CATEGORIA_FAIL:
      return loadPieGastosPorCategoriaFail(state, action.error);

    case actionTypes.TEXTO_GASTOS_DEL_MES_START:
      return loadTextoGastosDelMesStart(state);
    case actionTypes.TEXTO_GASTOS_DEL_MES_SUCCESS:
      return loadTextoGastosDelMesSuccess(state, action.mesActual, action.mesAnterior);
    case actionTypes.TEXTO_GASTOS_DEL_MES_FAIL:
      return loadTextoGastosDelMesFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_GASTOS_DEL_MES:
      return clearTextoGastosDelMes(state);

    case actionTypes.EVOLUCION_GASTOS_MENSUAL_START:
      return loadEvolucionGastosMensualStart(state);
    case actionTypes.EVOLUCION_GASTOS_MENSUAL_SUCCESS:
      return loadEvolucionGastosMensualSuccess(
        state,
        action.evolucionGastosMensual,
        action.evolucionGastosMensualDataTable,
        action.totalGastos
      );
    case actionTypes.EVOLUCION_GASTOS_MENSUAL_FAIL:
      return loadEvolucionGastosMensualFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_GASTOS_MENSUAL:
      return clearEvolucionGastosMensual(state);

    case actionTypes.EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_START:
      return loadEvolucionGastosMensualPorCategoriaStart(state);

    case actionTypes.EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_SUCCESS:
      return loadEvolucionGastosMensualPorCategoriaSuccess(
        state,
        action.evolucionGastosMensualPorCategoria,
        action.total
      );

    case actionTypes.EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_ERROR:
      return loadEvolucionGastosMensualPorCategoriaError(state, action.error);

    case actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_START:
      return loadGastosPorItemDeCategoriaSelecStart(state);

    case actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_SUCCESS:
      return loadGastosPorItemDeCategoriaSelecSuccess(
        state,
        action.gastosPorItemDeCategoria,
        action.nombreCategoria,
        action.fecha
      );

    case actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_ERROR:
      return loadGastosPorItemDeCategoriaSelecError(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_GASTOS_START:
      return loadComprobantesGastosStart(state);
    case actionTypes.LOAD_COMPROBANTES_GASTOS_SUCCESS:
      return loadComprobantesGastosSuccess(state, action.comprobantesGastos);
    case actionTypes.LOAD_COMPROBANTES_GASTOS_FAIL:
      return loadComprobantesGastosFail(state, action.error);

    case actionTypes.LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_START:
      return loadEvolucionGastosPorCategoriaStart(state);
    case actionTypes.LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_SUCCESS:
      return loadEvolucionGastosPorCategoriaSuccess(state, action.gastos, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_FAIL:
      return loadEvolucionGastosPorCategoriaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_GASTOS_POR_CATEGORIA:
      return clearEvolucionGastosPorCategoria(state);

    case actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START:
      return loadDetalleGastosDelItemDeCompraStart(state);
    case actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS:
      return loadDetalleGastosDelItemDeCompraSuccess(state, action.comprobantes);
    case actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL:
      return loadDetalleGastosDelItemDeCompraFail(state, action.error);
    case actionTypes.CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA:
      return clearDetalleGastosDelItemDeCompra(state);

    case actionTypes.LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_START:
      return loadEvolucionDeGastosMensualInteranualStart(state, action.anio);
    case actionTypes.LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_SUCCESS:
      return loadEvolucionDeGastosMensualInteranualSuccess(state, action.anio, action.ventas, action.ventasDataTable, action.totalVentas);
    case actionTypes.LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_FAIL:
      return loadEvolucionDeGastosMensualInteranualFail(state, action.anio, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_GASTOS_INTERANUAL:
      return clearEvolucionDeGastosMensualInteranual(state, action.periodo);
    case actionTypes.CLEAR_EVOLUCIONES_DE_GASTOS_INTERANUAL:
      return clearEvolucionesDeGastosMensualInteranual(state);
    case actionTypes.AGREGAR_EVOLUCION_DE_GASTOS_INTERANUAL:
      return agregarEvolucionDeGastosMensualInteranual(state, action.periodo);
    case actionTypes.SELECCION_FECHA_DE_GASTOS_INTERANUAL:
      return seleccionFechaGastosDelMesAnio(state, action.mes, action.anio);

    default:
      return state;
  }
};

export default reducer;
