import React, { useRef } from 'react';
import moment from 'moment';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';

import classes from './Styles/BigChartCard.css';
import { formatNumber } from '../../shared/formatNumber';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import { storageMonedaSigno } from '../../shared/sessionData';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';
import { colorsChart } from '../../shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const LineChart = (props) => {
  let chartRef = useRef(null);
  
  const options = {
    series: [
      {
        name: props.textoTooltip,
        data: props.series
      }
    ],
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 10,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: true,
        tools: {
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        }
      },
      zoom: props.disableZoom
        ? undefined
        : {
            enabled: true,
            type: props.zoomType ? props.zoomType : 'xy',
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1
              }
            }
          }
    },
    colors: props.colors ? props.colors : colorsChart,
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: !props.disableGrid,
      borderColor: props.gridBorderColor ? props.gridBorderColor : '#e7e7e7',
      row: props.gridRow
        ? props.gridRow
        : {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (index, config) {
          const formatDate = moment(props.categories[index - 1]).format('MM/YYYY');
          return `${props.titleTooltip ? props.titleTooltip : ''} ${
            formatDate === 'Invalid date' || formatDate === 'Fecha inválida'
              ? `${props.categories[index - 1]}`
              : formatDate
          }`;
        }
      },
      y: {
        formatter: function (val) {
          return `${storageMonedaSigno()} ${formatNumber(val)}`;
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        inverseColors: false,
        opacityFrom: 5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return `${storageMonedaSigno()}${formatNumber(val)}`;
        }
      }
    },
    xaxis: {
      labels: {
        show: props.showXLabels,
        rotate: -45,
        rotateAlways: props.labelsRotateAlways,
        formatter: function (value, opts) {
          const formatDate = moment(new Date(value)).format('MM/YY');
          return formatDate === 'Invalid date' || formatDate === 'Fecha inválida' ? value : formatDate;
        }
      },
      categories: props.categories,
      tooltip: {
        enabled: false
      }
    }
  };

  return (
    <div ref={chartRef} className={classes.BigChartCard} style={{ width: props.width, minHeight: props.loading && '240px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>{props.title}</p>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.subTitle && <p style={{ marginTop: '1px', fontSize: '12px' }}>{props.subTitle}</p>}
        {props.children}
      </div>
      {props.disabled ? (
        <img alt="Indicador Inhabilitado" src={IndicadorDeshabilitado} />
      ) : props.loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      ) : props.imgSinDatos ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="Sin resultados" src={SinResultados} style={{ maxWidth: '400px' }} />
        </div>
      ) : (
        <Chart options={options} series={options.series} type="area" height={props.height} />
      )}
    </div>
  );
};

export default LineChart;
