import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { Grid, Typography } from "@material-ui/core";
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import moment from "moment";
import "moment/locale/es";
import ButtonPivotGrid from "../CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid";
import FiltroFechaSelector from "../FiltroFechaSelector/FiltroFechaSelector";
import Spinner from "../Spinner/Spinner";

moment.locale("es");

const FiltroInformeUno = (props) => {
  const { aplicarFiltros } = props;
  const { loadingInforme, onLoadData, filtros, filtrosInforme, onUpdateFiltros } = props;

  const [fechaDesde, setFechaDesde] = useState(null);
  const [loadingDesde, setLoadingDesde] = useState(false);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [loadingHasta, setLoadingHasta] = useState(false);
  const [anioComparacion, setAnioComparacion] = useState('');
  const [loadingAnioComp, setLoadingAnioComp] = useState(false);
  const [cargado, setCargado] = useState(false);
  const [minDate, setMinDate] = useState(new Date(new Date().getFullYear() - 10, 0, 1));
  const [maxDate, setMaxDate] = useState(new Date(new Date().getFullYear() + 10, 11, 31));
  const [loading, setLoading] = useState(false);
  const [cambiosAplicados, setCambiosAplicados] = useState(filtros ? filtros.filtroAplicado : true);

  const habilitar = useCallback(() => {
    const dateValid = (fechaDesde !== null && fechaDesde !== undefined && fechaHasta !== null && fechaHasta !== undefined);
    const habilitarAplicar = dateValid;

    return !habilitarAplicar || loadingInforme;
  }, [fechaDesde, fechaHasta, loadingInforme]);

  useEffect(() => {
    if (!loading && !cargado) {
      const onSuccess = (config) => {
        if (config) {
          setMinDate(new Date(config.anioInicio, config.mesInicio - 1, 1));
          const daysInMonth = new Date(config.anioFin, config.mesFin - 1, 1).getDate();
          setMaxDate(new Date(config.anioFin, config.mesFin - 1, daysInMonth));
        }

        setCargado(true);
        setLoading(false);
      }

      const onError = (error) => {
        console.log(error);
        setCargado(true);
        setLoading(false);
      }

      setLoading(true);
      onLoadData(onSuccess, onError);
    }
  }, [cargado, loading, onLoadData]);

  useEffect(() => {
    if (filtros && !fechaDesde && filtros.desde) {
      const tempDate = new Date(filtros.desde);

      if (tempDate !== fechaDesde) {
        setLoadingDesde(true);
        setFechaDesde(tempDate);
        setTimeout(() => setLoadingDesde(false), 100);
      }
    }
  }, [filtros, filtros.desde, fechaDesde]);

  useEffect(() => {
    if (filtros && !fechaHasta && filtros.hasta) {
      const tempDate = new Date(filtros.hasta);

      if (tempDate !== fechaHasta) {
        setLoadingHasta(true);
        setFechaHasta(tempDate);
        setTimeout(() => setLoadingHasta(false), 100);
      }
    }
  }, [filtros, filtros.hasta, fechaHasta]);

  useEffect(() => {
    if (filtros && !anioComparacion && filtros.anioComparacion) {
      const tempDate = new Date(filtros.anioComparacion, 0, 1);

      if (tempDate !== anioComparacion){
        setLoadingAnioComp(true);
        setAnioComparacion(tempDate);
        setTimeout(() => setLoadingAnioComp(false), 100);
      }
    }
  }, [filtros, filtros.anioComparacion, anioComparacion]);

  const onAplicarFiltroClick = () => {
    let desdeDate = fechaDesde ? new Date(fechaDesde) : null;
    let hastaDate = fechaHasta ? new Date(fechaHasta) : null;
    let comparacion = anioComparacion ? anioComparacion.getFullYear() : undefined;
    onUpdateFiltros(desdeDate, hastaDate, comparacion, true);
    aplicarFiltros(desdeDate, hastaDate, comparacion);
    setCambiosAplicados(true);
  }

  const onDesdeChange = (value) => {
    setFechaDesde(value)
    let hastaDate = fechaHasta ? new Date(fechaHasta) : null;
    let comparacion = anioComparacion ? anioComparacion.getFullYear() : undefined;
    onUpdateFiltros(value, hastaDate, comparacion, false);
    setCambiosAplicados(false);
  };

  const onHastaChange = (value) => {
    setFechaHasta(value);
    let desdeDate = fechaDesde ? new Date(fechaDesde) : null;
    let comparacion = anioComparacion ? anioComparacion.getFullYear() : undefined;
    onUpdateFiltros(desdeDate, value, comparacion, false);
    setCambiosAplicados(false);
  };

  const onAnioComparacionChange = (value) => {
    setAnioComparacion(value);
    let desdeDate = fechaDesde ? new Date(fechaDesde) : null;
    let hastaDate = fechaHasta ? new Date(fechaHasta) : null;
    let comparacion = value ? value.getFullYear() : undefined;
    onUpdateFiltros(desdeDate, hastaDate, comparacion, false);
    setCambiosAplicados(false);
  }

  return (
    <Fragment>
      <Grid item xs={12} md={10} lg={8} xl={6} container spacing={1} justifyContent="flex-start">
        <Grid item xs={6} md={3}>
          {loadingDesde ? (
            <Spinner size="small" />
          ) : (
            <FiltroFechaSelector
              label="Fecha desde"
              disabled={loadingInforme}
              value={fechaDesde}
              onDateChange={(value) => onDesdeChange(value)}
              format="DD/MM/YYYY"
              minDate={minDate}
              maxDate={maxDate}
              required={true}
              helperText={fechaDesde ? null : "Este campo es requerido"}
            />)
          }
        </Grid>
        <Grid item xs={6} md={3}>
          {loadingHasta ? (
            <Spinner size="small" />
          ) : (
            <FiltroFechaSelector
              label="Fecha hasta"
              disabled={loadingInforme}
              value={fechaHasta}
              onDateChange={(value) => onHastaChange(value)}
              format="DD/MM/YYYY"
              minDate={minDate}
              maxDate={maxDate}
              required={true}
              helperText={fechaHasta ? null : "Este campo es requerido"}
            />)
          }
        </Grid>
        <Grid item xs={6} md={3}>
          {loadingAnioComp ? (
            <Spinner size="small" />
          ) : (
            <FiltroFechaSelector
              label="Año Comparación"
              value={anioComparacion}
              onDateChange={(value) => onAnioComparacionChange(value)}
              format="YYYY"
              views={["year"]}
              disabled={loadingInforme}
              minDate={minDate}
              maxDate={maxDate}
            />)
          }
        </Grid>
        <Grid item xs={6} md={2}>
          <ButtonPivotGrid
            tooltipTitle="Aplicar filtros sobre el informe 1"
            buttonSize="small"
            text="Aplicar"
            onClick={onAplicarFiltroClick}
            loading={false}
            icon={<PlayCircleFilledWhiteOutlinedIcon fontSize="small" />}
            disabled={habilitar()}
            backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
          />
        </Grid>
      </Grid>
      {filtrosInforme && (<Grid item xs={12}>
        <LeyendaFiltros
          desde={filtrosInforme.desde}
          hasta={filtrosInforme.hasta}
        />
      </Grid>)}
    </Fragment>
  )
}

//#region Leyenda Filtro Utilizado
const LeyendaFiltros = (props) => {
  const { desde, hasta } = props;
  const desdeText = desde instanceof Date
    ? new moment(desde).format("DD-MM-YYYY")
    : typeof desde === 'string' ? new moment(new Date(desde)).format("DD-MM-YYYY") : desde;
  const hastaText = hasta instanceof Date
    ? new moment(hasta).format("DD-MM-YYYY")
    : typeof hasta === 'string' ? new moment(new Date(hasta)).format("DD-MM-YYYY") : hasta;

  return desdeText && hastaText ? (
    <Typography variant="h6">
      La información visualizada corresponde al período de análisis del {desdeText} al {hastaText}
    </Typography>
  ) : (<Fragment></Fragment>)
}

//#endregion

const mapStateToProps = (state) => {
  return {
    loadingInforme: state.ventas.cuboVentasData.loading,
    filtrosInforme: state.ventas.cuboVentasData.filtros,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (onSuccess, onError) => dispatch(actions.loadBudgetForecastConfig(onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroInformeUno);