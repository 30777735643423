// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableComprobantesGastos__tableComprobantesGastosContainer__pQAWb {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableComprobantesGastosContainer": "TableComprobantesGastos__tableComprobantesGastosContainer__pQAWb"
};
export default ___CSS_LOADER_EXPORT___;
