import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClickSobreCategoriaDelGrafico from '../../../../assets/images/ClickSobreCategoriaDelGrafico.png';
import { compareByAsc, compareByDesc } from '../../../../shared/sorting';

import classes from './TablaComparacionVentasDeLaSeleccion.css';
import { MTableBodyRow } from 'material-table';
import TablaComparacionVentasPorPeriodosComprobantes from './TablaComparacionVentasPorPeriodosComprobantes';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const TablaComparacionVentasPorPeriodosDeLaSeleccion = (props) => {
  const { fechasComparar = [], dataSelectionId, showMonto, onLoadDetallesComparacion,
    dataOptions, dataSelectOptions, currentLevel, nextLevel, filtrosParent } = props;
  const [tableState, setTableState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'porcParticipacion1', direction: 'desc' });
  const [searchState] = useState('');
  const [titleTotal] = useState(`TOTAL ${props.titleCategory}`);
  const tableRef = useRef();

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  const onLoadSuccess = (data) => {
    if (tableState.length === 1) {
      armarComparacion(data);
      setLoading(false);
    } else {
      tableState.push(data);
    }

    if (props.endRequest)
      props.endRequest(data.length);
    else
      setTimeout(() => window.scroll({ top: document.body.offsetHeight, left: 0, behavior: 'smooth' }), 100);
  };

  const onLoadFail = () => {
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      fechasComparar.map((fecha) => {
        const id = props.itemExpanded.id ? props.itemExpanded.id : props.itemExpanded.itemId;
        return onLoadDetallesComparacion(id, currentLevel.key, nextLevel.key, fecha.desde, fecha.hasta, fecha.anio, filtrosParent, onLoadSuccess, onLoadFail);
      });
    }, 500);
  }, []);

  const getCurrentLevel = () => {
    return nextLevel;
  }

  const getCurrentLevelKey = () => {
    return nextLevel ? nextLevel.key : "";
  }

  const getCurrentLevelTitle = () => {
    let title = nextLevel && nextLevel.key
      ? dataOptions[nextLevel.key.toLowerCase()].description
      : dataOptions[dataSelectionId].description;

    return title;
  }

  const getNextLevel = () => {
    return nextLevel ? nextLevel.subNivel : undefined;
  }

  const getDataSelectedId = () => {
    return nextLevel ? nextLevel.key ? nextLevel.key.toLowerCase() : "" : "";
  }

  const mostrarComprobantes = () => {
    const disabled = getNextLevel() === undefined || getNextLevel() === null;
    return disabled;
  }

  const getFiltrosParent = () => {
    let tempFiltros = [];
    let id = props.itemExpanded.id ? props.itemExpanded.id : props.itemExpanded.itemId;

    if (filtrosParent && filtrosParent.length > 0) {
      tempFiltros = tempFiltros.concat(filtrosParent);
    }

    const existItem = tempFiltros.find((filtro) => filtro.entidad === currentLevel.key);

    if (existItem) {
      tempFiltros.forEach((filtro) => {
        if (filtro.entidad === currentLevel.key)
          filtro.id = id;
      });
    } else {
      tempFiltros.push({ id: id, entidad: currentLevel.key });
    }

    return tempFiltros;
  }

  const expandRow = (rowData) => {
    return rowData.itemId !== null & rowData.itemId !== undefined;
  }

  const columns = [
    {
      title: getCurrentLevelTitle(),
      field: 'item',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto ${props.fechasComparar.length === 2
        ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
        : ''
        }`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.monto1)}` : `${formatNumberWithoutDecimals(rowData.monto1)}`)
    },
    {
      title: `Porc. Participación ${props.fechasComparar.length === 2
        ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
        : ''
        }`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto ${props.fechasComparar.length === 2
        ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
        : ''
        }`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.monto2)}` : `${formatNumberWithoutDecimals(rowData.monto2)}`)
    },
    {
      title: `Porc. Participación ${props.fechasComparar.length === 2
        ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
        : ''
        }`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos ${props.fechasComparar.length === 2
        ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
        : ''
        }`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (showMonto ? `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.difMontos)}` : `${formatNumberWithoutDecimals(rowData.difMontos)}`)
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];

    tableState[0].forEach((ventasCompleto) => {
      const verify = arrayAComparar.filter((venta) => venta.nombre === ventasCompleto.nombre);
      if (verify.length === 0) {
        arrayNuevo.push({ ...ventasCompleto, monto: 0, porcentajeDeParticipacion: 0, unidadesVendidas: 0, porcentajeDeParticipacionUnidades: 0 });
      }
    });

    arrayAComparar.map((venta) => {
      return arrayNuevo.push(venta);
    });

    arrayAComparar.forEach((ventasOrdenadas) => {
      const verify = tableState[0].filter((venta) => venta.nombre === ventasOrdenadas.nombre);

      if (verify.length === 0) {
        tableState[0].push({ ...ventasOrdenadas, monto: 0, porcentajeDeParticipacion: 0, unidadesVendidas: 0, porcentajeDeParticipacionUnidades: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));
    setTableState([
      { dateComplete: props.fechasComparar[0], ventasDetallada: orderTableState },
      { dateComplete: props.fechasComparar[1], ventasDetallada: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;

    const filterTableState =
      tableState.length === 2
        ? [
          {
            ...tableState[0],
            ventasDetallada: tableState[0].ventasDetallada.filter(
              (ventDet) =>
                ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
            )
          },
          {
            ...tableState[1],
            ventasDetallada: tableState[1].ventasDetallada.filter(
              (ventDet) =>
                ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
            )
          }
        ]
        : [];

    filterTableState.map((data, key) => {
      if (key === 0) {
        return data.ventasDetallada.map((ventaDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].ventasDetallada[index];

          const ventasDelIndex = ventasDetalldasDelIndex
            ? tableState[1].ventasDetallada[index]
            : {
              mes: 0, anio: 0, id: '0', nombre: 'undefined', monto: 0, porcentajeDeParticipacion: 0, unidadesVendidas: 0, porcentajeDeParticipacionUnidades: 0
            };

          totalMonto1 += showMonto ? ventaDetallada.monto : ventaDetallada.unidadesVendidas;
          totalMonto2 += showMonto ? ventasDelIndex.monto : ventasDelIndex.unidadesVendidas;

          return formatTableData.push({
            itemId: ventaDetallada.id,
            item: ventaDetallada.nombre,
            monto1: showMonto ? ventaDetallada.monto : ventaDetallada.unidadesVendidas,
            porcParticipacion1: showMonto ? ventaDetallada.porcentajeDeParticipacion : ventaDetallada.porcentajeDeParticipacionUnidades,
            monto2: showMonto ? ventasDelIndex.monto : ventasDelIndex.unidadesVendidas,
            porcParticipacion2: showMonto ? ventasDelIndex.porcentajeDeParticipacion : ventasDelIndex.porcentajeDeParticipacionUnidades,
            difMontos: showMonto ? calcularDif(ventaDetallada.monto, ventasDelIndex.monto) : calcularDif(ventaDetallada.unidadesVendidas, ventasDelIndex.unidadesVendidas),
            porcComparacion: showMonto ? calcularPorc(ventaDetallada.monto, ventasDelIndex.monto) : calcularPorc(ventaDetallada.unidadesVendidas, ventasDelIndex.unidadesVendidas)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        itemId: null,
        item: titleTotal,
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });

    return formatTableData;
  };

  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const renderSort = (sortKey) => {
    return sortConfig.key === sortKey
      ? sortConfig.direction === 'asc'
        ? (
          <span className="MuiButtonBase-root MuiTableSortLabel-root Mui-active css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root" tabIndex="0" role="button">
            <ArrowUpward />
          </span>
        ) : (
          <span className="MuiButtonBase-root MuiTableSortLabel-root Mui-active css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root" tabIndex="0" role="button">
            <ArrowDownward />
          </span>
        ) : (
        <Fragment></Fragment>
      )
  }

  const headersBackgroundColor = 'rgb(235, 235, 235)';

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);
      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  const maxBodyHeight = () => {
    if (tableState && tableState.length === 2) {
      if (tableState[0].ventasDetallada && tableState[1].ventasDetallada) {
        let items = tableState[0].ventasDetallada.length >= tableState[1].ventasDetallada.length
          ? tableState[0].ventasDetallada : tableState[1].ventasDetallada;

        return items && items.length > 10 ? 550 : items.length > 5 ? 500 : 450;
      }
    }

    return 600;
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
      </div>
      {fechasComparar.length !== 2 ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="" src={ClickSobreCategoriaDelGrafico} width={400} />
        </div>
      ) : (
        <Table
          style={{ paddingLeft: '20px' }}
          columns={columns}
          tableRef={tableRef}
          data={data()}
          noBoxShadow
          loading={loading}
          components={{
            Header: (props) => {
              return (
                <TableHead>
                  {fechasComparar.length === 2 && (
                    <TableRow>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                        align="left"
                      >
                      </TableCell>
                      <TableCell
                        style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                      >
                      </TableCell>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px'
                        }}
                        colSpan={2}
                        align="center"
                      ></TableCell>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px'
                        }}
                        colSpan={2}
                        align="center"
                      ></TableCell>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                        align="center"
                      ></TableCell>
                      <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                      align="left"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('item')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        width: window.screen.width < 1367 ? '265px' : '410px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="left"
                    >
                      {getCurrentLevelTitle()}{renderSort('item')}
                    </TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('monto1')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    >
                      {renderSort('monto1')}
                    </TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcParticipacion1')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    >
                      {renderSort('porcParticipacion1')}</TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('monto2')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    >
                      {renderSort('monto2')}</TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcParticipacion2')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    >
                      {renderSort('porcParticipacion2')}</TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('difMontos')}
                      style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                      align="right"
                    >
                      {renderSort('difMontos')}</TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcComparacion')}
                      style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                      align="right"
                    >
                      {renderSort('porcComparacion')}</TableCell>
                  </TableRow>
                </TableHead>
              );
            },
            Row: (props) => {
              return (
                <MTableBodyRow
                  {...props}
                  detailPanel={
                    !props.data.itemId
                      ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                      : props.detailPanel
                  }
                />
              );
            }
          }}
          detailPanel={[
            {
              disabled: getNextLevel() === '',
              render: (rowData) => {
                return mostrarComprobantes()
                  ? (
                    <div className={classes.TablasComparacionComprobantesContainer}>
                      {fechasComparar.map((fecha, index) => {
                        return (
                          <div style={{ width: '100%' }} key={index}>
                            <div style={{ marginRight: index === 0 ? '5px' : '10px', marginLeft: index === 1 ? '5px' : '10px' }} >
                              <TablaComparacionVentasPorPeriodosComprobantes
                                desde={fecha.desde}
                                hasta={fecha.hasta}
                                anio={fecha.anio}
                                itemExpanded={rowData}
                                incluirUnidades={props.incluirUnidades}
                                withoutDecimals={props.withoutDecimals}
                                tipoParent={getCurrentLevelKey()}
                                filtrosParent={getFiltrosParent()}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : expandRow(rowData) ? (
                    <TablaComparacionVentasPorPeriodosDeLaSeleccion
                      endRequest={() => autoScroll(rowData)}
                      onLoadDetallesComparacion={props.onLoadDetallesComparacion}
                      fechasComparar={fechasComparar}
                      titleCategory={rowData.nombre ? rowData.nombre : rowData.item}
                      dataSelectionId={getDataSelectedId()}
                      dataOptions={dataOptions}
                      dataSelectOptions={dataSelectOptions}
                      itemExpanded={rowData}
                      showMonto={showMonto}
                      incluirUnidades={props.incluirUnidades}
                      withoutDecimals={props.withoutDecimals}
                      currentLevel={getCurrentLevel()}
                      nextLevel={getNextLevel()}
                      filtrosParent={getFiltrosParent()}
                    />
                  ) : (
                    <Fragment></Fragment>
                  )
              }
            }
          ]}
          customOptions={{
            grouping: false,
            draggable: false,
            search: false,
            paging: false,
            minBodyHeight: 450,
            maxBodyHeight: maxBodyHeight(),
            showTitle: false,
            tableLayout: 'fixed',
            toolbar: false,
            rowStyle: (rowData, index) => {
              return {
                cursor: 'auto',
                backgroundColor: (rowData.item === titleTotal && '#A3CCE8') || (!isEven(index) && '#f2f2f2')
              };
            },
            cellStyle: (cellData) => ({
              fontWeight: cellData === titleTotal && 'bolder'
            })
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectOptions: state.ventas.tipoVentasDesagregadas.tipos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDetallesComparacion: (id, parent, child, desde, hasta, anio, filtrosParent, success, errorFail) =>
      dispatch(actions.loadDesagregadasPorPeriodosPorTipoPorId(id, parent, child, undefined, desde, hasta, anio, filtrosParent, success, errorFail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionVentasPorPeriodosDeLaSeleccion);
