import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment'
import { storageFechaFija, storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaNotasDePedidoToExcel = (props) => {
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const formatData = () => {
    var sumMonto = 0;
    var formatData = [];

    props.notasDePedido.map((notaDePedido) => {
        sumMonto = sumMonto += notaDePedido.total;
        const inTotal = !notaDePedido.id;
        return formatData.push([
            {
                value: notaDePedido.clienteId,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: notaDePedido.cliente,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: moment(notaDePedido.fecha).format('YYYY-MM-DD'),
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: moment(notaDePedido.fechaEntrega).format('YYYY-MM-DD'),
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: notaDePedido.nombreComprobante,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: notaDePedido.referencia,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: notaDePedido.nroExterno,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
            value: `${storageMonedaSigno()} ${formatNumber(notaDePedido.total)}`,
            style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }

            }
        ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
        columns: [
            { title: 'Cod.', width:{wpx: 65}, style: headersStyle }, 
            { title: 'Cliente', width:{wpx: 250}, style: headersStyle }, 
            { title: 'Fecha', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Fecha Entrega', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Comprobante', width:{wpx: 140}, style: headersStyle }, 
            { title: 'Ref', width:{wpx: 80}, style: headersStyle }, 
            { title: 'Num. Externo', width:{wpx: 150}, style: headersStyle }, 
            { title: 'total', width:{wpx: 150}, style: headersStyle }, 
        ],
        data: formatData()
    }
  ];

  return (
    <ExcelFile
        filename={`Notas de pedido ${storageFechaFija()}`}
        element={
            <Tooltip title="Descargar en Excel">
                <IconButton style={{ marginRight: '10px' }} size="small">
                    <img alt="" src={IconConvertToExcel} height={'28px'} />
                </IconButton>
            </Tooltip>
        }
    >
    <ExcelSheet dataSet={multiDataSet} name={`Notas de pedido`} />
    </ExcelFile>
  );
};

export default TablaNotasDePedidoToExcel;
