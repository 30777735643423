import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';
import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';

const TextoIvaDebitoFiscalComparativo = (props) => {
  return (
    <TextChart
      title={`IVA débito fiscal ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} ${
        props.empresa ? props.empresa.nombre : ''
      }`}
      bigAmount={Math.abs(props.debitoMesActual)}
      bigAmountTooltip="Debito fiscal"
      smallAmount={Math.abs(props.debitoMesAnterior)}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} a ${fechaFinPeriodoMesAnteriorFunc()} `}
      loading={props.loading}
      porcentaje
      bigCurrency
      comparacionInvertida
      smallCurrency
      border={props.border}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.impuestos.textoIvaFiscal.loading,
    debitoMesActual: state.impuestos.textoIvaFiscal.debitoMesActual,
    debitoMesAnterior: state.impuestos.textoIvaFiscal.debitoMesAnterior
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoIvaFiscal(idEmpresa))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoIvaDebitoFiscalComparativo);
