import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import { enqueueSnackbar } from './snackbar';
import { fechaActualFunc } from '../../shared/fechas';
import axiosConfig from 'axios';
import { storageEmpresaId, storageMonedaId, storageToken } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';
import { baseUrlDashboard } from '../../shared/urlApps';

export const loadTextoChequesPropios = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoChequesPropiosStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesPropiosPromedio?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.chequesEmitidos
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadTextoChequesPropiosSuccess(response.data.monto, response.data.diasPromedio));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoChequesPropiosFail(error));
          } else {
            dispatch(loadTextoChequesPropios(idEmpresa));
          }
        } else {
          dispatch(loadTextoChequesPropiosFail(error));
        }
      });
  };
};

export const clearTextoChequesPropios = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_CHEQUES_PROPIOS
  };
};

const loadTextoChequesPropiosStart = () => {
  return {
    type: actionTypes.TEXTO_CHEQUES_PROPIOS_START
  };
};

const loadTextoChequesPropiosSuccess = (monto, promedioDias) => {
  return {
    type: actionTypes.TEXTO_CHEQUES_PROPIOS_SUCCESS,
    monto: monto,
    promedioDias: promedioDias
  };
};

const loadTextoChequesPropiosFail = (error) => {
  return {
    type: actionTypes.TEXTO_CHEQUES_PROPIOS_FAIL,
    error
  };
};

export const loadTextoPlazoMedioAPagar = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoPlazoMedioAPagarStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/compras/DeudoresPorComprasPromedioDeDias?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.plazoMedioVencAPagar
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadTextoPlazoMedioAPagarSuccess(response.data.monto, response.data.diasPromedio));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoPlazoMedioAPagarFail(error));
          } else {
            dispatch(loadTextoPlazoMedioAPagar(idEmpresa));
          }
        } else {
          dispatch(loadTextoPlazoMedioAPagarFail(error));
        }
      });
  };
};

export const clearTextoPlazoMedioAPagar = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_PLAZO_MEDIO_A_PAGAR
  };
};

const loadTextoPlazoMedioAPagarStart = () => {
  return {
    type: actionTypes.TEXTO_PLAZO_MEDIO_A_PAGAR_START
  };
};

const loadTextoPlazoMedioAPagarSuccess = (deuda, promedioDias) => {
  return {
    type: actionTypes.TEXTO_PLAZO_MEDIO_A_PAGAR_SUCCESS,
    deuda: deuda,
    promedioDias: promedioDias
  };
};

const loadTextoPlazoMedioAPagarFail = (error) => {
  return {
    type: actionTypes.TEXTO_PLAZO_MEDIO_A_PAGAR_FAIL,
    error: error
  };
};

export const loadPieSaldoAcreedor = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadPieSaldoAcreedorStart());
    const authStr = 'Bearer '.concat(storageToken());

    setTimeout(() => {
      axios
        .get(
          `/compras/DeudoresPorCompras?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
            idIndicadores.saldoAcreedores
          }`,
          {
            headers: { Authorization: authStr }
          }
        )
        .then((response) => {
          let totalDeudas = 0;
          const deudasPorPeriodo = response.data.map((deuda) => deuda.value);
          const periodos = response.data.map((deuda) => deuda.name);
          response.data.map((deuda) => (totalDeudas += deuda.value));
          dispatch(loadPieSaldoAcreedorSuccess(response.data, deudasPorPeriodo, periodos, totalDeudas));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.statusCode === 500) {
              dispatch(loadPieSaldoAcreedorFail(error));
            } else {
              dispatch(loadPieSaldoAcreedor(idEmpresa));
            }
          } else {
            dispatch(loadPieSaldoAcreedorFail(error));
          }
        });
    }, 5000);
  };
};

export const clearPieSaldoAcreedor = () => {
  return {
    type: actionTypes.CLEAR_PIE_SALDO_ACREEDORES
  };
};

const loadPieSaldoAcreedorStart = () => {
  return {
    type: actionTypes.PIE_SALDO_ACREEDORES_START
  };
};

const loadPieSaldoAcreedorSuccess = (deudasCompleto, deudasPorPeriodo, periodos, totalDeudas) => {
  return {
    type: actionTypes.PIE_SALDO_ACREEDORES_SUCCESS,
    deudasPorPeriodo: deudasPorPeriodo,
    periodos: periodos,
    totalDeudas,
    deudasCompleto
  };
};

const loadPieSaldoAcreedorFail = (error) => {
  return {
    type: actionTypes.PIE_SALDO_ACREEDORES_FAIL,
    error: error
  };
};

export const loadTextoPromDiasPagos = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoPromDiasPagosStart());

    const fechaHasta = fechaActualFunc();
    const fechaDesde = new Date(fechaHasta.getFullYear(), fechaHasta.getMonth(), 1);

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/compras/PlazoMedioPagoProveedores?idMoneda=${storageMonedaId()}&fechaDesde=${fechaDesde.toLocaleDateString(
          'en-US'
        )}&fechaHasta=${fechaHasta.toLocaleDateString('en-US')}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.promDiasDePagos
        }`,

        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        dispatch(loadTextoPromDiasPagosSuccess(response.data.dias, response.data.total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoPromDiasPagosFail(error));
          } else {
            dispatch(loadTextoPromDiasPagos(idEmpresa));
          }
        } else {
          dispatch(loadTextoPromDiasPagosFail(error));
        }
      });
  };
};

export const clearTextoPromDiasPagos = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES
  };
};

const loadTextoPromDiasPagosStart = () => {
  return {
    type: actionTypes.TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_START
  };
};

const loadTextoPromDiasPagosSuccess = (dias, total) => {
  return {
    type: actionTypes.TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_SUCCESS,
    dias,
    total
  };
};

const loadTextoPromDiasPagosFail = (error) => {
  return {
    type: actionTypes.TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_FAIL,
    error: error
  };
};

export const loadTextoPagosDelMes = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoPagosDelMesStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/compras/PagosDelMesComparativo?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.pagosDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadTextoPagosDelMesSuccess(response.data.pagosMesActual, response.data.pagosMesAnterior));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoPagosDelMesFail(error));
          } else {
            dispatch(loadTextoPagosDelMes(idEmpresa));
          }
        } else {
          dispatch(loadTextoPagosDelMesFail(error));
        }
      });
  };
};

export const clearTextoPagosDelMes = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_PAGOS_DEL_MES
  };
};

const loadTextoPagosDelMesStart = () => {
  return {
    type: actionTypes.TEXTO_PAGOS_DEL_MES_START
  };
};

const loadTextoPagosDelMesSuccess = (mesActual, mesAnterior) => {
  return {
    type: actionTypes.TEXTO_PAGOS_DEL_MES_SUCCESS,
    mesActual: mesActual,
    mesAnterior: mesAnterior
  };
};

const loadTextoPagosDelMesFail = (error) => {
  return {
    type: actionTypes.TEXTO_PAGOS_DEL_MES_FAIL,
    error: error
  };
};

export const loadComprobantesPagos = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesPagosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `${baseUrlDashboard()}compras/ComprobantesPagos?idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprobantesPagos
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesPagosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesPagosFail(error));
          } else {
            dispatch(loadComprobantesPagos(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesPagosFail(error));
        }
      });
  };
};

const loadComprobantesPagosStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PAGOS_START
  };
};

const loadComprobantesPagosSuccess = (comprobantesPagos) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PAGOS_SUCCESS,
    comprobantesPagos
  };
};

const loadComprobantesPagosFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PAGOS_FAIL,
    error
  };
};

export const loadComprobantesCompras = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesComprasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `${baseUrlDashboard()}compras/ComprobantesCompras?idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprobantesCompras
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesComprasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesComprasFail(error));
          } else {
            dispatch(loadComprobantesCompras(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesComprasFail(error));
        }
      });
  };
};

const loadComprobantesComprasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COMPRAS_START
  };
};

const loadComprobantesComprasSuccess = (comprobantesCompras) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COMPRAS_SUCCESS,
    comprobantesCompras
  };
};

const loadComprobantesComprasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COMPRAS_FAIL,
    error
  };
};

export const postComprobantesPagos = (empresaId, comprobantes, success, fail) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true, false));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`${baseUrlDashboard()}compras/ComprobantesPagos?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Comprobantes actualizados correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        )
        dispatch(executePostComprobantes(false, true));
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false, false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
      });
  };
};

export const postComprobantesCompras = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true, false));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`${baseUrlDashboard()}compras/ComprobantesCompras?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Comprobantes actualizados correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        )
        dispatch(executePostComprobantes(false, true));
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false, false));

        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
      });
  };
};

const executePostComprobantes = (loading, modificado) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
    modificado
  };
};

export const loadDeudaPorCategoriaDeProveedor = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadDeudaPorCategoriaDeProveedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/compras/DeudaPorCategoriaDeProveedor?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.deudaPorCategoriaDeProveedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let totalDeudas = 0;
        const valoresNegativos = response.data.filter((rd) => rd.importe < 0);
        const valoresPositivos = response.data.filter((rd) => rd.importe >= 0);
        response.data.map((deuda) => (totalDeudas += deuda.importe));
        dispatch(loadDeudaPorCategoriaDeProveedorSuccess(valoresPositivos, totalDeudas, valoresNegativos));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadDeudaPorCategoriaDeProveedorFail(error));
          } else {
            dispatch(loadDeudaPorCategoriaDeProveedor(idEmpresa));
          }
        } else {
          dispatch(loadDeudaPorCategoriaDeProveedorFail(error));
        }
      });
  };
};

export const clearDeudaPorCategoriaDeProveedor = () => {
  return {
    type: actionTypes.CLEAR_DEUDA_POR_CATEGORIA_DE_PROVEEDOR
  };
};

const loadDeudaPorCategoriaDeProveedorStart = () => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_START
  };
};

const loadDeudaPorCategoriaDeProveedorSuccess = (deudas, totalDeudas, valoresNegativos) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_SUCCESS,
    deudas,
    valoresNegativos,
    totalDeudas
  };
};

const loadDeudaPorCategoriaDeProveedorFail = (error) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_FAIL,
    error: error
  };
};
