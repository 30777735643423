import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../../../shared/formatNumber';
import * as actions from '../../../../store/actions/index';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import PieTableChart from '../../../Charts/PieTableChart';

const PieGastosPorCategoria = (props) => {
  const { estaOculto, estaCargadoFotoDelDia, ejecucionDePagina, gastosFotoDelDia,
    totalFotoDelDia, fechaFotoDelDia, loadingFotoDelDia, categoriasFotoDelDia } = props;

  useEffect(() => {
    if (!estaOculto && !estaCargadoFotoDelDia && ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [estaOculto, estaCargadoFotoDelDia, ejecucionDePagina]);  

  const isDisabledChart = props.estaOculto;

  const titles = () => {
    const title = 'Gastos por categoría';
    const fecha = `${gastosFotoDelDia.length > 0
      ? `al ${moment(fechaFotoDelDia).format('MM/yyyy')}`
      : ''}`;
    return `${title} ${fecha}`;
  };

  const subtitles = () => {
    return `Total: ${storageMonedaSigno()} ${formatNumber(isDisabledChart ? 0.0 : totalFotoDelDia)}`;
  };

  const series = () => {
    return isDisabledChart ? [] : gastosFotoDelDia;
  };

  const labels = () => {
    return isDisabledChart ? [] : categoriasFotoDelDia;
  };

  const gastosNegativos = () => {
    const gastosNegativoKey = 'gastosNegativosFotoDelDia';
    return (
      !isDisabledChart &&
      props[gastosNegativoKey].map((gastoNegativo) => {
        const total = totalFotoDelDia !== 0 ? totalFotoDelDia : 1;
        const porcentajeDeParticipacion = (gastoNegativo.monto / total) * 100;
        return {
          nombre: gastoNegativo.categoria,
          monto: gastoNegativo.monto,
          porcentajeDeParticipacion,
        };
      })
    );
  };

const formatGastos = () => {
  const gastosPos = categoriasFotoDelDia.map((element, index) => {
    const monto = gastosFotoDelDia[index];
    const total = totalFotoDelDia !== 0 ? totalFotoDelDia : 1;
    const porcentajeDeParticipacion = (monto / total) * 100;
    return {
      nombre: element,
      monto,
      porcentajeDeParticipacion,
    }
  });
  const gastosNeg = gastosNegativos();
  const result = [].concat(gastosPos).concat(gastosNeg);
  return result;
}

return (
  <PieTableChart
    title={titles()}
    subTitle={subtitles()}
    series={series()}
    labels={labels()}
    valoresNegativos={gastosNegativos()}
    loading={loadingFotoDelDia}
    imgSinDatos={totalFotoDelDia <= 0}
    disabled={isDisabledChart}
    height={props.height}
    width={props.width}
    chartType={props.chartType}
    spaceBetweenChartTitle={props.spaceBetweenChartTitle}
    legendHeight={props.legendHeight}
    legendPosition={props.legendPosition}
    onFiltersClick={() => props.onShowFiltersModal()}
    minHeight={props.minHeight}
    items={formatGastos()}
    titleName='Categoría'
    titleField='nombre'
    valueName='Monto'
    valueField='monto'
  />
);
};

const mapStateToProps = (state) => {
  return {
    gastosFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.gastos,
    gastosNegativosFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.gastosNegativos,
    categoriasFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.categorias,
    loadingFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.loading,
    fechaFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.fecha,
    totalFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.total,
    estaCargadoFotoDelDia: state.gastos.pieGastosPorCategoriaSinDataTable.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosPorCategoria
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadPieGastosPorCategoriaSinDataTable()),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Gastos por categoría', ['gasto', 'categoriaItemsDeCompra']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieGastosPorCategoria);