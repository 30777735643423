import React from 'react';
import { formatNumber } from '../../../../../shared/formatNumber';
import { storageFechaFija, storageMonedaSigno } from '../../../../../shared/sessionData';
import Table from '../../../../UI/Table/Table';

const TableUtilidadesBase = (props) => {
  const cellStyle = {
    fontSize: '11px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '12px',
    paddingTop: '0px',
    paddingBottom: '0px'
  };

  const columns = [
    {
      title: props.titleUtilidadPor,
      field: props.fieldUtilidadPor,
      cellStyle: (cellData) => {
        return {
          ...cellStyle,
          fontWeight: cellData === 'Total' && 'bolder'
        };
      },
      headerStyle: headerStyle
    },
    {
      title: `Ventas totales ${storageMonedaSigno()}`,
      field: 'totalVentas',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          ...cellStyle,
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => formatNumber(rowData.totalVentas)
    },
    {
      title: 'Cantidad vendida',
      field: 'unidadesVendidas',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          ...cellStyle,
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle
    },
    {
      title: `Costo ${storageMonedaSigno()}`,
      field: 'totalCosto',
      type: 'numeric',
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => formatNumber(rowData.totalCosto)
    },
    {
      title: `Utilidad ${storageMonedaSigno()}`,
      field: 'utilidad',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          ...cellStyle,
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => formatNumber(rowData.utilidad)
    },
    {
      title: 'Utilidad según costo',
      field: 'utilidadSegunCosto',
      type: 'numeric',
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => {
        return rowData[props.fieldUtilidadPor] === 'Total' ? '-' : `${formatNumber(rowData.utilidadSegunCosto)} %`;
      }
    },
    {
      title: 'Utilidad según ventas',
      field: 'utilidadSegunVentas',
      type: 'numeric',
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => {
        return rowData[props.fieldUtilidadPor] === 'Total' ? '-' : `${formatNumber(rowData.utilidadSegunVentas)} %`;
      }
    },
    {
      title: 'Participacion sobre utilidad',
      field: 'porcentajeUtilidadSobreElTotal',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          ...cellStyle,
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => {
        return rowData[props.fieldUtilidadPor] === 'Total'
          ? '-'
          : `${formatNumber(rowData.porcentajeUtilidadSobreElTotal)} %`;
      }
    }
  ];

  const data = () => {
    var sumTotalVentas = 0;
    var sumUnidadesVendidas = 0;
    var sumTotalCosto = 0;
    var sumUtilidad = 0;

    var formatData = props.utilidades.map((utilidad) => ({
      [props.fieldUtilidadPor]: utilidad.nombre,
      totalVentas: utilidad.ventas,
      sumTotalVentas: (sumTotalVentas += utilidad.ventas),
      unidadesVendidas: utilidad.unidadesVendidas,
      sumUnidadesVendidas: (sumUnidadesVendidas += utilidad.unidadesVendidas),
      totalCosto: utilidad.costo,
      sumTotalCosto: (sumTotalCosto += utilidad.costo),
      utilidad: utilidad.monto,
      sumUtilidad: (sumUtilidad += utilidad.monto),
      utilidadSegunCosto: utilidad.utilidadPorcentualSegunCosto,
      utilidadSegunVentas: utilidad.utilidadPorcentualSegunVentas,
      porcentajeUtilidadSobreElTotal: utilidad.porcentajeUtilidadSobreElTotal,
      porcentajeCostoSobreElTotal: utilidad.porcentajeCostoSobreElTotal,
      porcentajeVentasSobreElTotal: utilidad.porcentajeVentasSobreElTotal
    }));

    if (formatData.length > 0) {
      formatData.push({
        [props.fieldUtilidadPor]: 'Total',
        totalVentas: sumTotalVentas,
        unidadesVendidas: sumUnidadesVendidas,
        totalCosto: sumTotalCosto,
        utilidad: sumUtilidad,
        utilidadSegunCosto: '-',
        utilidadSegunVentas: '-',
        porcentajeUtilidadSobreElTotal: '-',
        porcentajeCostoSobreElTotal: '-',
        porcentajeVentasSobreElTotal: '-'
      });
    }

    return formatData;
  };

  return (
    <Table
      columns={columns}
      data={data()}
      noBoxShadow
      loading={props.loading}
      customOptions={{
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        search: false,
        paging: false,
        showTitle: false,
        toolbar: true,
        exportAllData: true,
        exportButton: true,
        exportFileName: `Analisis por ${props.fieldUtilidadPor} al ${storageFechaFija()}`,
        maxBodyHeight: 460,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.tableData.id === props.utilidades.length && '#f3f3f3'
        })
      }}
      tableKey={"tabla-utilidad-base"}
    />
  );
};

export default TableUtilidadesBase;
