import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { storageUsuarioId } from '../../../../shared/sessionData';
import AdministrarFormato from './AdministrarFormato';
import AdministrarCubo from './AdministrarCubo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 600,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabPanel: {
    width: '750px', 
    height: '200px', 
    padding: '25px'
  }, 
  vertical: {
    width: '240px',
    "& .MuiButtonBase-root": {
      fontSize: "11px",
      maxWidth: '140px',
      maxHeigth: '30px',
      padding: '6px'
    },
    "& .MuiTab-wrapper": {
      alignItems: 'start'
    }
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Fragment>
          {children}
        </Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ConfigPivotGrid = (props) => {
  const classes = useStyles();
  const { title, pivotGridKey, pivotGridRef, onClose, onCargarFormato, onLoadConfiguraciones, onLoadInformaciones } = props;
  const [value, setValue] = useState(0);
  const [idUsuario] = useState(storageUsuarioId());

  useEffect(() => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
  }, [idUsuario, pivotGridKey, onLoadConfiguraciones]);

  useEffect(() => {
    onLoadInformaciones(pivotGridKey, idUsuario);
  }, [pivotGridKey, idUsuario, onLoadInformaciones]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label={title}
        className={classes.tabs}
        classes={{
          vertical: classes.vertical
        }}
      >
        <Tab label="Admin. formatos" {...a11yProps(0)} />
        <Tab label="Admin. cubos" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AdministrarFormato          
          pivotGridKey={pivotGridKey}
          onClose={onClose}
          pivotGridRef={pivotGridRef}
          onCargarFormato={onCargarFormato}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdministrarCubo         
          pivotGridKey={pivotGridKey}
          onClose={onClose}
          pivotGridRef={pivotGridRef}
          datos={props.datos}
          filtros={props.filtros}
          onLoadData={props.onLoadDataImport}
          onUpdateDataSource={props.onUpdateDataSource}
          onCargarFormato={onCargarFormato}
        />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pivotGridConfiguraciones: state.tenacta.pivotGridConfiguraciones.items,
    pivotGridInformaciones: state.tenacta.pivotGridInformaciones.items,
    aprobado: state.tenacta.informeUnoData.aprobado,
    budgetAnterior: state.tenacta.informeUnoData.esAnterior,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadConfiguraciones: (key, idUsuario) => dispatch(actions.loadPivotGridConfiguraciones(key, idUsuario)),
    onLoadInformaciones: (key, idUsuario) => dispatch(actions.loadPivotGridInformaciones(key, idUsuario)),
    onSaveConfiguracion: (configuracion, onSuccess, onError) => 
      dispatch(actions.guardarPivotGridConfiguracion(configuracion, onSuccess, onError))      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPivotGrid);