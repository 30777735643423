export const budgetFormat = (monedaSigno) => {
  return [
    {
      caption: '- Categoría',
      dataField: 'categoria',
      name: 'categoria',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Producto',
      dataField: 'producto',
      name: 'producto',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Canal',
      dataField: 'canal',
      name: 'canal',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Cliente',
      dataField: 'cliente',
      name: 'cliente',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Responsable',
      dataField: 'responsable',
      name: 'responsable',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Vendedor facturacion',
      dataField: 'vendedor',
      name: 'vendedor',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Vendedor vigente',
      dataField: 'vendedorVigente',
      name: 'vendedorVigente',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Marca',
      dataField: 'marca',
      name: 'marca',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Unidad de Negocio',
      dataField: 'unidadNegocio',
      name: 'unidadNegocio',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Grupo Compras',
      dataField: 'grupoCompras',
      name: 'grupoCompras',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Tipo Venta',
      dataField: 'tipoVenta',
      name: 'tipoVenta',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: "- Año",
      dataField: "anio",
      name: "anio",
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: ',##0'
    },
    {
      caption: "- Mes",
      dataField: "mes",
      name: "mes",
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
    },

    {
      caption: '| Unidades Fact.',
      dataField: 'unidadesFacturadas',
      name: 'unidadesFacturadas',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: ',##0'
    },
    {
      caption: '| Neto Fact.',
      dataField: 'netoFacturado',
      name: 'netoFacturado',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`
    },
    {
      caption: '| Bruto Fact.',
      dataField: 'brutoFacturado',
      name: 'brutoFacturado',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`
    },
    {
      caption: '| Descuento',
      dataField: 'descuento',
      name: 'descuento',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`
    },
    {
      caption: '| Unidades Pend.',
      dataField: 'unidadesPendientes',
      name: 'unidadesPendientes',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: ',##0'
    },
    {
      caption: '| Neto Pend.',
      dataField: 'netoPendiente',
      name: 'netoPendiente',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Bruto Pend.',
      dataField: 'brutoPendiente',
      name: 'brutoPendiente',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },

    {
      caption: '| Total Unidades',
      dataField: 'unidadesTotales',
      name: 'unidadesTotales',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: ',##0'
    },
    {
      caption: '| Total Neto',
      dataField: 'netoTotal',
      name: 'netoTotal',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Total Bruto',
      dataField: 'brutoTotal',
      name: 'brutoTotal',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },

    {
      caption: '| Unidades Presupuestadas',
      dataField: 'unidadesEstimadas',
      name: 'unidadesEstimadas',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: ',##0'
    },
    {
      caption: '| Neto Presupuestado',
      dataField: 'netoEstimado',
      name: 'netoEstimado',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Bruto Presupuestado',
      dataField: 'brutoEstimado',
      name: 'brutoEstimado',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },

    {
      caption: '| Dif. Unidades',
      dataField: 'unidadesDiferencia',
      name: 'unidadesDiferencia',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: ',##0'
    },
    {
      caption: '| Dif. Neto',
      dataField: 'netoDiferencia',
      name: 'netoDiferencia',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Dif. Bruto',
      dataField: 'brutoDiferencia',
      name: 'brutoDiferencia',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| % Unidades',
      dataType: 'number',
      name: 'porcUnidades',
      width: 50,
      area: 'data',
      format: {
        type: 'percent',
        precision: 2
      }
    },
    {
      caption: '| % Neto',
      dataType: 'number',
      name: 'porcNeto',
      width: 50,
      area: 'data',
      format: {
        type: 'percent',
        precision: 2
      }
    },
    {
      caption: '| % Bruto',
      dataType: 'number',
      name: 'porcBruto',
      width: 50,
      area: 'data',
      format: {
        type: 'percent',
        precision: 2
      }
    },

    {
      caption: '| Share Unidades',
      dataType: 'number',
      name: 'shareUnidades',
      width: 50,
      area: 'data',
      format: ',##0.00%'
    },
    {
      caption: '| Share Neto',
      dataType: 'number',
      name: 'shareNeto',
      width: 50,
      area: 'data',
      format: {
        type: 'percent',
        precision: 2
      }
    },

    {
      caption: '| Unidades año anterior',
      dataField: 'unidadesTotalComparacion',
      name: 'unidadesTotalComparacion',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: ',##0'
    },
    {
      caption: '| Dif. Unidades año anterior',
      name: 'unidadesDiferenciaComparacion',
      dataType: 'number',
      area: 'data',
      format: ',##0'
    },
    {
      caption: '| Evolución Unidades',
      name: 'evolucionUnidades',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: {
        type: 'percent',
        precision: 2
      }
    },

    {
      caption: '| Neto año anterior',
      dataField: 'netoTotalComparacion',
      name: 'netoTotalComparacion',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Dif. Neto año anterior',
      name: 'netoDiferenciaComparacion',
      dataType: 'number',
      area: 'data',
      format: `${monedaSigno} ,##0`
    },
    {
      caption: '| Evolución Neto',
      name: 'evolucionNeto',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      format: {
        type: 'percent',
        precision: 2
      }
    },

    {
      caption: '| Bruto año anterior',
      dataField: 'brutoTotalComparacion',
      name: 'brutoTotalComparacion',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      format: `${monedaSigno} ,##0`,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Dif. Bruto año anterior',
      name: 'brutoDiferenciaComparacion',
      dataType: 'number',
      area: 'data',
      format: `${monedaSigno} ,##0`
    }
  ];
};
