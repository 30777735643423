import React, { useState, useEffect } from "react";
import * as actions from './../../../../store/actions';
import { connect } from 'react-redux';
import PieChart from "../../../Charts/PieChart";
import Spinner from "../../Spinner/Spinner";
import { formatNumber } from "../../../../shared/formatNumber";
import CustomPieTablePivotGrid from "./CustomPieTablePivotGrid";

const CustomPieChartPivotGrid = (props) => {
  const { items, headerTitle, montoTitle, field, loading } = props;
  const [itemsLocales, setUtilidadesLocal] = useState([]);
  const [viewAsPie, setViewAsPie] = useState(true);

  useEffect(() => {
    if (items && items.length !== itemsLocales.length)
      setUtilidadesLocal(items);
  }, [itemsLocales, items]);

  const getPositiveItems = (field) => {
    return items ? items.filter((item) => item[field] >= 0) : [];
  }

  const getNegativeItems = (field) => {
    if (!items)
      return [];

    let negativos = items.filter((item) => item[field] < 0);

    if (negativos) {
      return negativos.map((ventaNegativo) => ({
        nombre: ventaNegativo['name'],
        monto: ventaNegativo[field]
      }));
    } else {
      return [];
    }
  }

  const handleChangeView = () => {
    setViewAsPie(!viewAsPie);
  }

  const dataLabels = {
    enabled: true,
    formatter: function (val, opts) {
      let item = opts.w.config.labels[opts.seriesIndex];

      if (item && item.toUpperCase() === 'OTROS')
        return `OTROS ${formatNumber(val)}%`;
      else
        return `${formatNumber(val)}%`;
    },
  }

  return loading ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : viewAsPie ? (
    <PieChart
      title={props.title}
      subTitle={props.subTitle}
      labels={getPositiveItems(field).map((item) => item.name)}
      series={getPositiveItems(field).map((item) => item[field])}
      valoresNegativos={getNegativeItems(field)}
      loading={props.loading}
      imgSinDatos={getPositiveItems(field).length === 0}
      chartType='pie'
      disabled={props.estaOculto}
      maxWidthImgSinResult={'275px'}
      onFiltersClick={() => props.onShowFiltersModal()}
      minHeight={props.minHeight}
      disabledCss
      noBorder
      height={props.height}
      width={'100%'}
      legendOffSetY={20}
      legendStyleFontSize={'10px'}
      legendPosition={props.legendPosition}
      legendHeight={props.legendHeight}
      showUnidades={!props.showMonto}
      dataLabels={dataLabels}
      onChangeViewClick={handleChangeView}
    />
  ) : (
    <CustomPieTablePivotGrid
      title={props.title}
      subTitle={props.subTitle}
      items={itemsLocales}
      headerTitle={headerTitle}
      headerField='name'
      montoTitle={montoTitle}
      montoField={field}
      showMonto={props.showMonto}
      onChangeViewClick={handleChangeView}
      height={(props.height - 50)}
      loading={loading}
      filename={props.filename}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    filtrosChart: state.tenacta.filtrosChartInformeUno,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta'])),
    onUpdateFiltros: (filtros) => dispatch(actions.updateFiltrosChartInformeUno(filtros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPieChartPivotGrid);