// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Novedades__NovedadContainer__3rJ5Y {\r\n  min-width: 50vh;\r\n}\r\n\r\n.Novedades__Titulo__-sWkj {\r\n  margin: 15px;\r\n  margin-top: 0;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.Novedades__TituloNovedades__VJOV- {\r\n  font-family: 'Montserrat', sans-serif;\r\n  font-weight: bold;\r\n  margin-left: 15px;\r\n  font-size: 29px;\r\n  letter-spacing: 0px;\r\n  color: #646464;\r\n}\r\n\r\n.Novedades__TituloVersion__9mJ19 {\r\n  font-family: 'Montserrat', sans-serif;\r\n  letter-spacing: 0px;\r\n  color: #646464;\r\n  margin-top: 10px;\r\n  margin-left: 10px;\r\n}\r\n\r\n.Novedades__Novedades__JmeCO {  \r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 200px;\r\n  width: 200px;\r\n}\r\n\r\n.Novedades__NovedadContainer__3rJ5Y {\r\n  display: flex;\r\n}\r\n\r\n.Novedades__NovedadVineta__8fTBh {\r\n  width: 95px;\r\n}\r\n\r\n.Novedades__NovedadDescripcion__vK7IY {\r\n  text-align: left;\r\n}\r\n\r\n.Novedades__NovedadTitulo__AoQ3t {\r\n  display: flex;\r\n  align-items: center;\r\n  color: #646464;\r\n  font-weight: bold;\r\n  font-size: 16px;\r\n}\r\n\r\n.Novedades__NovedadTexto__HRJGL {\r\n  max-width: 650px;\r\n  text-align: center;\r\n}\r\n\r\n.Novedades__Circulo__wxOIB {\r\n  width: 13px;\r\n  height: 13px;\r\n  border-radius: 50%;\r\n  margin-right: 10px;\r\n  margin-top: 6px;\r\n  background: transparent linear-gradient(180deg, #faaf40 0%, #f05a28 100%) 0% 0% no-repeat padding-box;\r\n}\r\n\r\n.Novedades__Footer__oyE6j {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin-left: 15px;\r\n  margin-right: 15px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NovedadContainer": "Novedades__NovedadContainer__3rJ5Y",
	"Titulo": "Novedades__Titulo__-sWkj",
	"TituloNovedades": "Novedades__TituloNovedades__VJOV-",
	"TituloVersion": "Novedades__TituloVersion__9mJ19",
	"Novedades": "Novedades__Novedades__JmeCO",
	"NovedadVineta": "Novedades__NovedadVineta__8fTBh",
	"NovedadDescripcion": "Novedades__NovedadDescripcion__vK7IY",
	"NovedadTitulo": "Novedades__NovedadTitulo__AoQ3t",
	"NovedadTexto": "Novedades__NovedadTexto__HRJGL",
	"Circulo": "Novedades__Circulo__wxOIB",
	"Footer": "Novedades__Footer__oyE6j"
};
export default ___CSS_LOADER_EXPORT___;
