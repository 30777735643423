import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compareFechaActualFechaFija, fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';

import SinResultadosPorFecha from '../../../../assets/images/SinResultadosFecha.png';

import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';

const TextoChequesRechazados = (props) => {
  const isDisabledChart = (props.estaOculto && !props.empresa) || !compareFechaActualFechaFija();

  useEffect(() => {
    if (
      !props.estaCargado &&
      !props.empresa &&
      !props.estaOculto &&
      compareFechaActualFechaFija() &&
      props.ejecucionDePagina
    ) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }

    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.ejecucionDePagina]);

  return (
    <TextChart
      title={`Cheques rechazados ${props.empresa ? props.empresa.nombre : ''} al ${fechaFinPeriodoMesActualFunc()}`}
      bigAmount={props.monto}
      bigAmountTooltip=""
      smallAmount={`${props.cantidadCheques}`}
      smallAmountTooltip="Cantidad cheques"
      disabled={isDisabledChart}
      customDisabledImage={!compareFechaActualFechaFija() && !props.estaOculto ? SinResultadosPorFecha : undefined}
      bigCurrency
      cursorPointer={!isDisabledChart}
      loading={props.loading}
      onBodyClick={() => props.onBodyClick()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.textoChequesRechazados.loading,
    monto: state.ventas.textoChequesRechazados.monto,
    cantidadCheques: state.ventas.textoChequesRechazados.cantidadCheques,
    estaCargado: state.ventas.textoChequesRechazados.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesRechazados
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoChequesRechazados(idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearTextoChequesRechazados()),
    onShowGlobalModal: (title, content) => dispatch(actions.showGlobalModal(true, title, content, ''))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoChequesRechazados);
