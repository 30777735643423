import axios from '../../axios-dashboard';
import { storageClienteId, storageEmpresaId, storageToken } from '../../shared/sessionData';
import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';

const executeLoadGruposGastos = (loading, estaCargado, items, error) => {
  return {
    type: actionTypes.EXECUTE_LOAD_GRUPO_GASTOS,
    loading,
    estaCargado,
    items,
    error
  };
};

export const loadGruposGastos = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(executeLoadGruposGastos(true, false, [], undefined));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/gruposgastos/list?idEmpresa=${idEmpresa}`, { headers: { Authorization: authStr }})
      .then((response) => {
        dispatch(executeLoadGruposGastos(false, true, response.data, undefined));
      })
      .catch((error) => {
        dispatch(executeLoadGruposGastos(false, true, [], error));
      });
  };
};

const executeCreateGruposGastos = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_GRUPO_GASTOS,
    loading
  };
};

export const createGruposGastos = (nuevoGruposGastos, success, onError = null) => {
  return (dispatch) => {
    dispatch(executeCreateGruposGastos(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post('/gruposgastos/create', nuevoGruposGastos, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(executeCreateGruposGastos(false));
        dispatch(
          enqueueSnackbar({
            message: `Grupo de Gastos creado con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(loadGruposGastos(storageEmpresaId()));
        return success && success();
      })
      .catch((error) => {
        const errorMessage = error && error.response && error.response.data
          ? error.response.data : `No se pudo crear el Grupo de Gasto. Por favor, intente nuevamente.`;
        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(executeCreateGruposGastos(false));
        return onError && onError();
      });
  };
};

const executeUpdateGruposGastos = (loading) => {
  return {
    type: actionTypes.EXECUTE_UPDATE_GRUPO_GASTOS,
    loading
  };
};

export const updateGruposGastos = (grupoGastoId, updatedGruposGastos, success = null, onError = null) => {
  return (dispatch) => {
    dispatch(executeUpdateGruposGastos(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .put(`/gruposgastos/update?grupoGastoId=${grupoGastoId}`, updatedGruposGastos, {
        headers: { Authorization: authStr }
      })
      .then((_response) => {
        dispatch(
          enqueueSnackbar({
            message: `Grupo de Gastos modificado con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(executeUpdateGruposGastos(false));
        dispatch(loadGruposGastos(storageEmpresaId()));
        return success && success();
      })
      .catch((error) => {
        const errorMessage = error && error.response && error.response.data
          ? error.response.data : `No se pudo actualizar el Grupo de Gasto. Por favor, intente nuevamente.`;
        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(executeUpdateGruposGastos(false));
        return onError && onError();
      });
  };
};

const executeUpdateGruposGastosSelected = (gruposGastosId) => {
  sessionStorage.setItem('grupoGastosId', gruposGastosId);
  return {
    type: actionTypes.EXECUTE_UPDATE_GRUPO_GASTOS_SELECTED,
    gruposGastosId
  };
};

const executeDeleteGruposGastos = (loading) => {
  return {
    type: actionTypes.EXECUTE_DELETE_GRUPO_GASTOS,
    loading
  };
};

export const deleteGruposGastos = (grupoGastoId, onSuccess = null, onError = null) => {
  return (dispatch) => {
    dispatch(executeDeleteGruposGastos(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .delete(`/gruposgastos/delete?grupoGastoId=${grupoGastoId}`, {
        headers: { Authorization: authStr }
      })
      .then((_response) => {
        dispatch(
          enqueueSnackbar({
            message: `Grupo de Gastos eliminado con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(executeDeleteGruposGastos(false));
        dispatch(loadGruposGastos(storageEmpresaId()));
        return onSuccess && onSuccess();
      })
      .catch((error) => {
        const errorMessage = error && error.response && error.response.data
          ? error.response.data : `No se pudo eliminar el Grupo de Gasto. Por favor, intente nuevamente.`;
        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(executeDeleteGruposGastos(false));
        return onError && onError();
      });
  };
};

export const cambiarGruposGastosDeUsuario = (gruposGastos, success) => {
  return (dispatch) => {    
    const authStr = 'Bearer '.concat(storageToken());  
    const gruposGastosId = gruposGastos && gruposGastos !== 'select' ? gruposGastos.id : 0;
    axios
      .put(`/gruposgastos/update/usuario?grupoGastoId=${gruposGastosId}&clienteId=${storageClienteId()}`, {}, {
        headers: { Authorization: authStr }
      })
      .then(() => {
        dispatch(executeUpdateGruposGastosSelected(gruposGastosId));

        if(success)
          success();
      })
      .catch((error) => {
        console.log(error);
      });
  };
};