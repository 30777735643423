import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import classes from './Novedades.css';
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
import Spinner from '../../UI/Spinner/Spinner';

const Novedades = (props) => {
  const { novedades, loading, onHiddenModal, onDescartarNovedades, onLoadNovedades, userOpen } = props;

  useEffect(() => {
    !novedades.estaCargado && onLoadNovedades();
  }, []);

  const onCerrarClick = () => {
    !userOpen && onDescartarNovedades();
    onHiddenModal();
  };

  return loading ? (        
      <div className={classes.Novedades}>
        <Spinner />
      </div>    
  ) : (
    <AutoRotatingCarousel
      label="Cerrar"
      autoplay={false}
      onClose={() => onCerrarClick()}
      onStart={() => onCerrarClick()}
      open
    >
      {novedades &&
        novedades.map((novedad, index) => {
          return (
            <Slide
              key={index}
              media={<img alt="" src={`data:image/png;base64,${novedad.image}`} style={{ maxHeight: '40ch' }} />}
              title={novedad.titulo}
              subtitle={novedad.descripcion}
              mediaBackgroundStyle={{backgroundColor: '#dddddd'}}
              // background: 'linear-gradient(0deg, #f15a29 10%, #dddddd 70%, #dddddd 20%)'
              style={{backgroundColor: '#f15a29'}}
            />
          );
        })}
    </AutoRotatingCarousel>
  );
};

const mapStateToProps = (state) => ({
  novedades: state.configuraciones.loadNovedades.novedades,
  loading: state.configuraciones.loadNovedades.loading
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadNovedades: () => dispatch(actions.loadNovedades(true)),
    onHiddenModal: () => dispatch(actions.hiddenGlobalModal()),
    onDescartarNovedades: (success, fail) => dispatch(actions.descartarNovedades(success, fail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Novedades);
