import React from 'react';
import { connect } from 'react-redux';
import PieChart from '../../../Charts/PieChart';
import * as actions from '../../../../store/actions/index';

const PieCobranzasMensualesPorEmpresa = (props) => {
 
  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <PieChart
      title={props.title}
      subTitle={props.subtitle}
      series={props.cobranzas.map((cobranza) => Math.abs(cobranza.total))}
      labels={props.cobranzas.map((cobranza) => cobranza.nombreEmpresa)}
      ids={props.cobranzas.map((cobranza) => cobranza.id)}
      imgSinDatos={props.totalCobranzas <= 0}
      loading={!props.estaOculto && props.loading && props.ejecucionDePagina}
      chartType={'pie'}
      height={props.height}
      disabled={props.estaOculto}
      width={'100%'}
      onPorcionClick={handlePorcionClick}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    cobranzas: state.multiempresa.cobranzasMensualesPorEmpresa.cobranzas,
    loading: state.multiempresa.cobranzasMensualesPorEmpresa.loading,
    estaCargado: state.multiempresa.cobranzasMensualesPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasMensualesMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadCobranzasMensualesPorEmpresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieCobranzasMensualesPorEmpresa);
