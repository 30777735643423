import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fechaActualFunc } from '../../../../shared/fechas';
import * as actions from '../../../../store/actions';
import TablaInteranualCubo from '../../../UI/Interanual/TablaInteranualCubo/TablaInteranualCubo';
import moment from 'moment';

const TablaInteranualCuboVentas = (props) => {
  const { ejecucionDePagina, dataSelectedId, fecha, evolucionVentas, periodoSeleccionado } = props;
  const [ventasDataTable, setventasDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalVentas, setTotalVentas] = useState(0);
  const [fechaFija] = useState(fechaActualFunc());

  const onLoadChartSuccess = (ventas, ventasDataTable, totalVentas) => {
    setventasDataTable(ventasDataTable);
    setTotalVentas(totalVentas);
    setLoading(false);
  };

  const onLoadChartFail = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (ejecucionDePagina) {
      let item = evolucionVentas.find((evo) => evo.anio === fecha.getFullYear());

      if (item !== undefined) {
        setventasDataTable(item.ventasDataTable);
        setTotalVentas(item.totalVentas);
      }
    }
  }, [ejecucionDePagina, evolucionVentas, fecha]);

  useEffect(() => {
    if (ejecucionDePagina) {
      let item = evolucionVentas.find((evo) => evo.anio === fecha.getFullYear());

      if (item === undefined) {
        setLoading(true);
        setventasDataTable([]);
        setTotalVentas(0);
        props.onLoadChart(fechaFija.getMonth() + 1, fecha.getFullYear(), onLoadChartSuccess, onLoadChartFail);
      }
    }
  }, [ejecucionDePagina, evolucionVentas, fecha]);

  const onVentasDelMesAnioClick = (mes, anio) => {
    props.onChangeFechaVentasDelMes(mes, anio);
    props.clearUtilidades();
    props.clearPivotGridVentas();
    props.onClearFiltro();
    dataSelectedId === 'categoriacliente' && props.onLoadVentasPorCategoriaDeCliente(mes, anio);
    dataSelectedId === 'marca' && props.onLoadVentasDelMesPorMarca(mes, anio);
    dataSelectedId === 'rubro' && props.onLoadVentasDelMesPorRubro(mes, anio);
    dataSelectedId === 'subrubro' && props.onLoadVentasDelMesPorSubrubro(mes, anio);
    dataSelectedId === 'vendedor' && props.onLoadVentasDelMesPorVendedor(mes, anio);
    dataSelectedId === 'provincia' && props.onLoadVentasDelMesPorProvincia(mes, anio);
    dataSelectedId === 'zona' && props.onLoadVentasDelMesPorZona(mes, anio);
    dataSelectedId === 'unidadenegocio' && props.onLoadVentasDelMesPorUnidadDeNegocio(mes, anio);
    dataSelectedId === 'canal' && props.onLoadVentasDelMesPorCanal(mes, anio);
    dataSelectedId === 'grupodecompra' && props.onLoadVentasDelMesPorGrupoDeCompra(mes, anio);
    dataSelectedId === 'responsable' && props.onLoadVentasDelMesPorResponsable(mes, anio);
    dataSelectedId === 'cliente' && props.onLoadVentasDelMesPorCliente(mes, anio);
    dataSelectedId === 'articulo' && props.onLoadVentasDelMesPorArticulo(mes, anio);
  };

  const getTitle = () => {
    const monthStart = fechaFija.getMonth() + 1;
    const start = `${moment(new Date(fecha.getFullYear() - 1, monthStart, 1)).format('MM/YY')}`;
    const end = `${moment(new Date(fecha.getFullYear(), fechaFija.getMonth(), 1)).format('MM/YY')}`;

    return `Ventas desglosadas por mes del ${start} a ${end}`;
  };

  return (
    <TablaInteranualCubo
      addMonth
      title={getTitle()}
      titleMontos={'Ventas'}
      datas={ventasDataTable}
      fecha={fecha}
      loading={loading}
      total={totalVentas}
      onSelectMesAnioClick={(mes, anio) => onVentasDelMesAnioClick(mes, anio)}
      onShowFiltersModal={() => props.onShowFiltersModal()}
      onEliminarPeriodo={props.onEliminarPeriodo}
      periodoSeleccionado={periodoSeleccionado}
      withoutDecimals={props.withoutDecimals}
      filtroSeleccionado={props.filtroSeleccionado}
      ventas
    />
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataVentasDelMesPorSelected,
    evolucionVentas: state.ventas.evolucionVentasMensualInteranual.items,
    periodoSeleccionado: state.ventas.evolucionVentasMensualInteranual.periodoSeleccionado,
    filtroSeleccionado: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas desglosadas por mes', ['venta'])),
    onLoadChart: (mes, anio, onSuccess, onFail) =>
      dispatch(actions.loadEvolucionDeVentasMensualInteranual(mes, anio, onSuccess, onFail)),
    onChangeFechaVentasDelMes: (mes, anio) => dispatch(actions.seleccionFechaVentasDelMesPor(mes, anio)),
    clearUtilidades: () => {
      dispatch(actions.clearUtilidadPorMarca());
      dispatch(actions.clearUtilidadPorRubro());
      dispatch(actions.clearUtilidadPorSubrubro());
    },
    clearPivotGridVentas: () => dispatch(actions.clearVentasDelMesPivotGrid()),
    onLoadVentasPorCategoriaDeCliente: (mes, anio) => {
      console.log("estoy dentro de onLoadVentasPorCategoriaDeCliente")
      dispatch(actions.loadVentasPorCategoriaDeCliente(mes, anio));
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasDelMesPorVendedor());
      dispatch(actions.clearVentasDelMesPorProvincia());
      dispatch(actions.clearVentasDelMesPorZona());

      dispatch(actions.loadVentasDesagregadasPorCategoriaDeCliente(mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorMarca: (mes, anio) => {
      dispatch(actions.loadVentasDelMesPorMarca(undefined, mes, anio));
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasDelMesPorVendedor());
      dispatch(actions.clearVentasDelMesPorZona());
      dispatch(actions.clearVentasDelMesPorProvincia());

      dispatch(actions.loadVentasDesagregadasDelMesPorMarca(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorProvincia: (mes, anio) => {
      dispatch(actions.loadVentasDelMesPorProvincia(undefined, mes, anio));
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasDelMesPorVendedor());
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorZona());

      dispatch(actions.loadVentasDesagregadasDelMesPorProvincia(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorRubro: (mes, anio) => {
      dispatch(actions.loadVentasDelMesPorRubro(undefined, mes, anio));
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDelMesPorVendedor());
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorZona());
      dispatch(actions.clearVentasDelMesPorProvincia());

      dispatch(actions.loadVentasDesagregadasDelMesPorRubro(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorSubrubro: (mes, anio) => {
      dispatch(actions.loadVentasDelMesPorSubrubro(undefined, mes, anio));
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorZona());
      dispatch(actions.clearVentasDelMesPorProvincia());
      dispatch(actions.clearVentasDelMesPorVendedor());

      dispatch(actions.loadVentasDesagregadasDelMesPorSubrubro(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorVendedor: (mes, anio) => {
      dispatch(actions.loadVentasDelMesPorVendedor(mes, anio));
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorProvincia());
      dispatch(actions.clearVentasDelMesPorZona());

      dispatch(actions.loadVentasDesagregadasDelMesPorVendedor(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorZona: (mes, anio) => {
      dispatch(actions.loadVentasDelMesPorZona(undefined, mes, anio));
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorProvincia());
      dispatch(actions.clearVentasDelMesPorVendedor());

      dispatch(actions.loadVentasDesagregadasDelMesPorZona(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorUnidadDeNegocio: (mes, anio) => {
      dispatch(actions.loadVentasDesagregadasDelMesPorUnidadDeNegocio(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorCanal: (mes, anio) => {
      dispatch(actions.loadVentasDesagregadasDelMesPorCanal(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorGrupoDeCompra: (mes, anio) => {
      dispatch(actions.loadVentasDesagregadasDelMesPorGrupoDeCompras(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorResponsable: (mes, anio) => {
      dispatch(actions.loadVentasDesagregadasDelMesPorResponsable(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorCliente: (mes, anio) => {
      dispatch(actions.loadVentasDesagregadasDelMesPorCliente(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    onLoadVentasDelMesPorArticulo: (mes, anio) => {
      dispatch(actions.loadVentasDesagregadasDelMesPorArticulo(undefined, mes, anio, mes, anio));
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
    },
    onClearFiltro: () => dispatch(actions.clearFiltroEvolucionesDeVentasMensualInteranual())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaInteranualCuboVentas);
