import React from 'react';
import ExportToExcelIcon from '../../../../../UI/Icons/ExportToExcelIcon';
import { getLineaDataSet, getLineaExpandedDataSet } from '../InformeTresHelpers';

const TablaPorLineaToExcel = (props) => {
  const { data, name, expanded, filename, disabled, tooltipTitle } = props;

  const lineaDataSet = expanded
    ? getLineaExpandedDataSet(name, data)
    : getLineaDataSet(name, data);
    
  const multiDataSet = [
    {
      columns: lineaDataSet.columns,
      data: lineaDataSet.data
    }
  ];

  return (
    <ExportToExcelIcon
      filename={filename}
      name={name}
      dataSet={multiDataSet}
      disabled={disabled}
      tooltipTitle={tooltipTitle}
    />
  )
}

export default TablaPorLineaToExcel;