import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber, formatTwoIntegerDigits } from '../../../../../shared/formatNumber';
import { Grid, IconButton, TextField, Select, MenuItem, Input, Typography } from '@material-ui/core';
import * as actions from '../../../../../store/actions/index';
import PieChart from '../../../../Charts/PieChart';
import { storageMonedaSigno } from '../../../../../shared/sessionData';
import classes from './PieUtilidadPorSeleccion.css';
import TableChart from '../../../../Charts/TableChart';
import { Close } from '@material-ui/icons';
import { compareByDesc } from '../../../../../shared/sorting';

const PieUtilidadPorSeleccion = (props) => {
  const { dataSelectedId, fechaUtilidadDelMes, ejecucionDePagina, initialLimit } = props;
  const isDisabledChart = props.estaOculto;
  const [items, setItems] = useState();

  const utilidadesNegativas = () => {
    return (
      !isDisabledChart &&
      props[dataOptions[dataSelectedId].stateKey].utilidadesNegativo.map((utilidadNegativo) => ({
        nombre: utilidadNegativo.nombre,
        monto: utilidadNegativo.monto
      }))
    );
  };

  const utilidadesPositivas = () => {
    const utilidades = props[dataOptions[dataSelectedId].stateKey].utilidades;
    const result = utilidades &&
      utilidades.map((utilidad) => ({
        ...utilidad,
        porcParticipacion: utilidad.porcentajeUtilidadSobreElTotal
      }));
    return result;
  };

  useEffect(() => {
    if (!props[dataOptions[dataSelectedId].stateKey].estaCargado && ejecucionDePagina) {
      props[dataOptions[dataSelectedId].onLoadKey](fechaUtilidadDelMes.mes, fechaUtilidadDelMes.anio);
      setLimiteAplicado(false);
    }
  }, [dataSelectedId, ejecucionDePagina]);

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      stateKey: 'utilidadPorCategoriaDeCliente',
      onLoadKey: 'onLoadUtilidadPorCategoriasDeCliente'
    },
    marca: {
      title: 'Marca',
      stateKey: 'utilidadPorMarca',
      onLoadKey: 'onLoadUtilidadPorMarca'
    },
    rubro: {
      title: 'Rubro',
      stateKey: 'utilidadPorRubro',
      onLoadKey: 'onLoadUtilidadPorRubro'
    },
    subrubro: {
      title: 'Subrubro',
      stateKey: 'utilidadPorSubrubro',
      onLoadKey: 'onLoadUtilidadPorSubrubro'
    },
    vendedor: {
      title: 'Vendedor',
      stateKey: 'utilidadPorVendedor',
      onLoadKey: 'onLoadUtilidadPorVendedor'
    },
    provincia: {
      title: 'Provincia',
      stateKey: 'utilidadPorProvincia',
      onLoadKey: 'onLoadUtilidadPorProvincia'
    },
    zona: {
      title: 'Zona',
      stateKey: 'utilidadPorZona',
      onLoadKey: 'onLoadUtilidadPorZona'
    }
  };

  const dataSelectOptions = [
    {
      id: 'categoriaDeCliente',
      title: 'Categoría de cliente'
    },
    {
      id: 'marca',
      title: 'Marca'
    },
    {
      id: 'rubro',
      title: 'Rubro'
    },
    {
      id: 'subrubro',
      title: 'Subrubro'
    },
    {
      id: 'vendedor',
      title: 'Vendedor'
    },
    {
      id: 'provincia',
      title: 'Provincia'
    },
    {
      id: 'zona',
      title: 'Zona'
    }
  ];

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    props.onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const loading = props[dataOptions[dataSelectedId].stateKey].loading;
    return (
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 400, marginRight: '15px' }}>
        <div className={classes.LabelVerPor}>
          Ver utilidad del{' '}
          {loading ? '--/----' : `${formatTwoIntegerDigits(fechaUtilidadDelMes.mes)}/${fechaUtilidadDelMes.anio}`} por:{' '}
        </div>
        <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: '5px' }}
          value={props.dataSelectedId}
          onChange={(e) => handleChangeOptionSelected(e)}
        >
          {dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id}>
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const subTitle = () => {
    return `Total: ${storageMonedaSigno()} ${formatNumber(props[dataOptions[dataSelectedId].stateKey].total)}`;
  }

  const series = () => {
    const utilidades = limit && limit > 0 ? items : utilidadesPositivas();
    const series = utilidades ? utilidades.map((utilidad) => utilidad.monto) : [];
    return isDisabledChart ? [] : series;
  };

  const labels = () => {
    const utilidades = limit && limit > 0 ? items : utilidadesPositivas();
    const labels = utilidades ? utilidades.map((utilidad) => utilidad.nombre) : [];
    return isDisabledChart ? [] : labels;
  };

  //#region LIMIT
  const [limiteAplicado, setLimiteAplicado] = useState(false);
  const [limit, setLimit] = useState(initialLimit);

  const onLimitChange = (value) => {
    let numValue = Number(value);
    setLimit(numValue);
    setLimiteAplicado(false);
  }

  const getLimit = () => {
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Límite
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={limit}
            onChange={(event) => onLimitChange(event.target.value)}
            type="number"
            size='small'
            InputProps={{
              inputProps: {
                max: utilidadesPositivas() && utilidadesPositivas().length,
                min: 0,
                style: { padding: '2px', textAlign: 'center' }
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {limit && limit > 0 ? (
            <IconButton size='small' onClick={() => setLimit(0)}>
              <Close fontSize='small' />
            </IconButton>
          ) : <Fragment></Fragment>}
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    if (limit && limit > 0 && !limiteAplicado && !props[dataOptions[dataSelectedId].stateKey].loading) {
      const utilidades = props[dataOptions[dataSelectedId].stateKey].utilidades;
      let ordered = utilidades.sort((a, b) => compareByDesc(a, b, 'monto'));
      let filtered = ordered.slice(0, limit);
      let others = ordered.slice(limit);

      if (others && others.length > 0) {
        let otherItem = {
          mes: others[0].mes,
          anio: others[0].anio,
          monto: 0,
        };
        otherItem['nombre'] = "OTROS";
        otherItem['id'] = "0000";
        others.forEach(element => {
          otherItem.monto += element.monto;
        });
        filtered.splice(0, 0, otherItem);
      }
      setItems(filtered);
      setLimiteAplicado(true);
    }
  }, [limit, limiteAplicado, props]);

  //#endregion

  //#region Change View
  const [viewAsPie, setViewAsPie] = useState(true);

  const handleChangeView = () => {
    setViewAsPie(!viewAsPie);
  }

  //#endregion

  return viewAsPie
    ? <PieChart
      disabledCss
      title={optionSelector()}
      endItems={utilidadesPositivas() !== 0 ? getLimit() : undefined}
      subTitle={subTitle()}
      series={series()}
      labels={labels()}
      valoresNegativos={utilidadesNegativas()}
      loading={props[dataOptions[dataSelectedId].stateKey].loading}
      imgSinDatos={utilidadesPositivas().length === 0}
      height={props.height}
      disabled={isDisabledChart}
      width={props.width}
      chartType={props.chartType}
      spaceBetweenChartTitle={props.spaceBetweenChartTitle}
      legendHeight={props.legendHeight}
      legendPosition={props.legendPosition}
      onChangeViewClick={handleChangeView}
    />
    : <TableChart
      title={`Utilidades del ${formatTwoIntegerDigits(fechaUtilidadDelMes.mes)}/${fechaUtilidadDelMes.anio} por ${dataOptions[dataSelectedId].title}`}
      subTitle={subTitle()}
      items={utilidadesPositivas()}
      onFiltersClick={() => props.onShowFiltersModal()}
      onChangeViewClick={handleChangeView}
      titleName='Utilidad'
      titleField='nombre'
      valueName='Monto'
      valueField='monto'
    />
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataUtilidadDelMesPorSelected,
    fechaUtilidadDelMes: state.ventas.fechaUtilidadDelMes,

    utilidadPorCategoriaDeCliente: state.ventas.utilidadDelMesPorCategoriaDeCliente,
    utilidadPorVendedor: state.ventas.utilidadDelMesPorVendedores,
    utilidadPorProvincia: state.ventas.utilidadDelMesPorProvincias,
    utilidadPorZona: state.ventas.utilidadDelMesPorZonas,

    utilidadPorMarca: state.ventas.utilidadDelMesPorMarcas,
    utilidadPorRubro: state.ventas.utilidadDelMesPorRubros,
    utilidadPorSubrubro: state.ventas.utilidadDelMesPorSubrubros
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => dispatch(actions.seleccionDataUtilidadDelMesPor(selecionId)),

    onLoadUtilidadPorCategoriasDeCliente: (mes, anio) => dispatch(actions.loadUtilidadPorCategoriaDeCliente(mes, anio)),
    onLoadUtilidadPorMarca: (mes, anio) => dispatch(actions.loadUtilidadDelMesPorMarca(mes, anio)),
    onLoadUtilidadPorRubro: (mes, anio) => dispatch(actions.loadUtilidadDelMesPorRubro(mes, anio)),
    onLoadUtilidadPorSubrubro: (mes, anio) => dispatch(actions.loadUtilidadDelMesPorSubrubro(mes, anio)),
    onLoadUtilidadPorVendedor: (mes, anio) => dispatch(actions.loadUtilidadDelMesPorVendedor(mes, anio)),
    onLoadUtilidadPorProvincia: (mes, anio) => dispatch(actions.loadUtilidadDelMesPorProvincia(mes, anio)),
    onLoadUtilidadPorZona: (mes, anio) => dispatch(actions.loadUtilidadDelMesPorZona(mes, anio))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieUtilidadPorSeleccion);
