import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const WrapperTablasPorSeleccionSinComparacionToExcel = (props) => {
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var sumMonto = 0;
    var formatData = [];

    props.ventas.map((venta) => {
      sumMonto = sumMonto += venta.monto;
      const inTotal = !venta.id;
      return formatData.push([
        {
          value: venta.name,
          style: { font: { sz: '10', bold: inTotal }, fill: inTotal && { fgColor: { rgb: 'FFD3D3D3' } } }
        },
        {
          value: inTotal ? '-' : `${formatNumber(venta.porcParticipacion)} %`,
          style: cellsStyleVerifNegative(venta.porcParticipacion, inTotal)
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(venta.monto)}`,
          style: cellsStyleVerifNegative(venta.monto, inTotal)
        }
      ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: props.ventasDe,
          width: { wch: 60 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        },
        { title: `Porc. Participación`, width: { wpx: 150 }, style: headersStyle },
        {
          title: `Monto`,
          width: { wpx: 150 },
          style: headersStyle
        }
      ],
      data: formatData()
    }
  ];

  return (
    <ExcelFile
      filename={`Analisis de ventas por ${props.ventasDe.toLowerCase()} al ${props.fecha}`}
      element={
        <Tooltip title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'28px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={`Ventas por ${props.ventasDe.toLowerCase()}`} />
    </ExcelFile>
  );
};

export default WrapperTablasPorSeleccionSinComparacionToExcel;
