// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomPivotGrid__TitleContainer__TNuw- {\r\n  margin-top: 15px !important;\r\n  margin-bottom: 5px !important;\r\n  width: 100%;\r\n  text-align: left !important;\r\n}\r\n\r\n.CustomPivotGrid__Title__SPE0Z {\r\n  font-weight: bold !important;\r\n  font-size: medium !important;\r\n}\r\n\r\n.CustomPivotGrid__SubTitleContainer__Zdaih {\r\n  margin-top: 5px !important;\r\n  margin-bottom: 5px !important;\r\n  width: 100%;\r\n  text-align: left !important;\r\n}\r\n\r\n.CustomPivotGrid__Subtitle__XYyQ4 {\r\n  font-size: small !important;\r\n}\r\n\r\n.CustomPivotGrid__ConfigIcon__vNkpi {\r\n  margin: 15px !important;\r\n  text-align: end !important;\r\n}\r\n\r\n.CustomPivotGrid__TabsContainer__H3JmR {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center !important;\r\n}\r\n\r\n.CustomPivotGrid__TabsScroller__6Hczr {\r\n  display: flex !important;\r\n  justify-content: center !important;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.CustomPivotGrid__TabsIndicator__CRVKS {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleContainer": "CustomPivotGrid__TitleContainer__TNuw-",
	"Title": "CustomPivotGrid__Title__SPE0Z",
	"SubTitleContainer": "CustomPivotGrid__SubTitleContainer__Zdaih",
	"Subtitle": "CustomPivotGrid__Subtitle__XYyQ4",
	"ConfigIcon": "CustomPivotGrid__ConfigIcon__vNkpi",
	"TabsContainer": "CustomPivotGrid__TabsContainer__H3JmR",
	"TabsScroller": "CustomPivotGrid__TabsScroller__6Hczr",
	"TabsIndicator": "CustomPivotGrid__TabsIndicator__CRVKS"
};
export default ___CSS_LOADER_EXPORT___;
