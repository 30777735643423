// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieVentasPorSeleccion__LabelVerPor__0W9Yh {\r\n  font-weight: bold;\r\n}\r\n\r\n.PieVentasPorSeleccion__SelectRoot__oG7Lt {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LabelVerPor": "PieVentasPorSeleccion__LabelVerPor__0W9Yh",
	"SelectRoot": "PieVentasPorSeleccion__SelectRoot__oG7Lt"
};
export default ___CSS_LOADER_EXPORT___;
