// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InteranualUtilidad__tablasInteranuales__4nCBq {\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  margin-top: 0px !important;\r\n  margin-left: 0px !important;\r\n  margin-right: 0px !important;\r\n}\r\n\r\n.InteranualUtilidad__linesInteranuales__M8489 {\r\n  margin: 0px !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablasInteranuales": "InteranualUtilidad__tablasInteranuales__4nCBq",
	"linesInteranuales": "InteranualUtilidad__linesInteranuales__M8489"
};
export default ___CSS_LOADER_EXPORT___;
