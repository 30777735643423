import React, { useEffect } from "react"
import * as actions from "../../../../store/actions/index"
import { connect } from "react-redux"
import { formatNumber } from "../../../../shared/formatNumber"
import { storageMonedaSigno } from "../../../../shared/sessionData"
import Table from "../../../UI/Table/Table"
import { MTableHeader } from 'material-table';

import classes from "./TablaStockPorDeposito.css"

const TablaStockPorDeposito = (props) => {

    useEffect(() => {
        props.onLoadStockPorDeposito(props.itemExpanded.id)
      }, []);
    const cellStyles = {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
    };
    
    const headerStyles = {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
    };
    
    const columns = [
        {
            title: 'Deposito',
            field: 'deposito',
            align: 'left',
            width: 10,
            cellStyle: cellStyles,
            headerStyle: headerStyles,
        },
        {
            title: 'Cantidad',
            field: 'cantidad',
            align: 'right',
            width: 10,
            cellStyle: cellStyles,
            headerStyle: headerStyles,
        },
        {
            title: 'Costo',
            field: 'costo',
            align: 'right',
            cellStyle: cellStyles,
            headerStyle: headerStyles,
            render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.costo)}`
        },
        {
            title: 'Costo total',
            field: 'costoTotal',
            align: 'right',
            cellStyle: cellStyles,
            headerStyle: headerStyles,
            render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.costoTotal)}`
        },
    ];

    const data = () => {
        return props.stockPorDeposito.stock.map((stock) => {
          return {
            deposito: stock.deposito,
            cantidad: stock.cantidad,
            costo: stock.costo,
            costoTotal: stock.costoTotal,
          };
        });
    };

    return (
        <div className={classes.tableStockPorDepositoContainer}>
          <Table
            noBoxShadow
            style={{ width: props.customWidth ? props.customWidth : '100%', marginTop: 0, marginBottom: '15px' }}
            loading={props.stockPorDeposito.loading}
            columns={columns}
            data={data()}
            components={{
              Header: (props) => (
                <MTableHeader {...props}>
                  {(props.columns[0].tableData.width = '55px')}
                  {(props.columns[1].tableData.width = '150px')}
                  {(props.columns[2].tableData.width = '245px')}
                  {(props.columns[3].tableData.width = '35px')}
                </MTableHeader>
              )
            }}
            customOptions={{
              actionsColumnIndex: -1,
              grouping: false,
              draggable: false,
              toolbar: false,
              showTitle: false,
              paging: false,
              maxBodyHeight: 235
            }}
            tableKey={"tabla-stock-por-deposito"}
          />
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        stockPorDeposito: state.stock.stockPorDeposito,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadStockPorDeposito: (idArticulo, idEmpresa) => dispatch(actions.stockPorDeposito(idArticulo, idEmpresa))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TablaStockPorDeposito);
