import React from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../../shared/formatNumber';
import locales from '../../../../../shared/locales';
import moment from 'moment';

import * as actions from '../../../../../store/actions/index';
import MultiLineChart from '../../../../Charts/MultiLineChart';

const LinesInteranualUtilidad = (props) => {
  const { evoluciones, periodos } = props;

  const getSeries = () => {
    let series = [];

    periodos.forEach(periodo => {
      let evolucion = evoluciones.find((evo) => evo.anio === periodo.getFullYear());

      if(evolucion !== undefined){
        let serie = {
          name: `${periodo.getFullYear()-1}-${periodo.getFullYear()}`,
          data: evolucion.utilidades.map((venta) => venta.monto)
        };
        series.push(serie);
      }
    });

    return series;
  }

  const formatMesesToStrings = () => {
    let evolucion = evoluciones[0];

    if(evolucion === undefined)
      return [];

    return evolucion.utilidades.map((x) => `${locales[1].options.months[x.mes - 1]}`);
  };

  const getCategories = () => {
    let items1 = [];

    evoluciones.forEach((evolucion) => {
      let items2 = evolucion.utilidades.map((item) => {
        return new Date(item.anio, item.mes - 1, 1);
      });
      
      items1.push(items2);
    });

    return items1;
  }

  return (
    <MultiLineChart
      title={'Evolución de utilidades por mes'}
      textoTooltip={'Monto'}
      series={getSeries()}
      categories={getCategories()}
      chartType={"area"}
      chartHeight={'300px'}
      width={'100%'}
      chartWidth={'99%'}
      chartDropShadow={{
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }}
      toolbarShow={true}
      zoomType={'xy'}
      dataLabelsEnabled={false}
      formatDataLabels
      strokeCurve={'smooth'}
      strokeWidth={5}
      fillType={'gradient'}
      fillGradient={{
        shadeIntensity: 0,
        inverseColors: false,
        opacityFrom: 5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }}
      gridBorderColor={'#e7e7e7'}
      gridRow={{
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }}
      markersSize={4}
      xaxisCategories={formatMesesToStrings()}
      xaxisLabels={{
        show: true,
        rotate: -45,
        rotateAlways: true,
        formatter: function (value, opts) {
          const formatDate = moment(new Date(value));
          return formatDate.isValid()
            ? `${locales[1].options.months[formatDate.month()]}` 
            : value;
        }
      }}
      xaxisType="category"
      yaxisLabels={{
        show: true,
        formatter: (value) => {
          return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
        },
        align: 'left',
        offsetX: -16
      }}
      legendFontSize={'14px'}
      legendOffsetY={-5}
      tooltipEnabled
      legendPosition={'bottom'}
      legendHorizontalAlign={'center'}
      legendShowForSingleSeries={true}
      legendShowForNullSeries={true}
      legendShowForZeroSeries={true}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    evoluciones: state.ventas.evolucionUtilidadesMensualInteranual.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Evolución de ventas por mes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinesInteranualUtilidad);