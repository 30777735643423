// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaRentabilidadPorSucursales__TableRentabilidadCell__R9z3r {\r\n  padding-inline: 0px !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableRentabilidadCell": "TablaRentabilidadPorSucursales__TableRentabilidadCell__R9z3r"
};
export default ___CSS_LOADER_EXPORT___;
