import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  textoIvaFiscal: {
    loading: false,
    error: null,
    debitoMesActual: 0,
    creditoMesActual: 0,
    debitoMesAnterior: 0,
    creditoMesAnterior: 0,
    saldo: 0,
    SaldoUltimoMesCargado: false,  
    estaCargado: false
  }
};

//#region load vendedores
const loadIvaFiscalStart = (state) => {
  return updateObject(state, {
    textoIvaFiscal: updateObject(state.textoIvaFiscal, {
      loading: true,
      error: null,
      debito: 0,
      credito: 0
    })
  });
};

const loadIvaFiscalSuccess = (state, debitoMesActual, creditoMesActual, debitoMesAnterior, creditoMesAnterior, saldo, saldoUltimoMesCargado) => {
  return updateObject(state, {
    textoIvaFiscal: updateObject(state.textoIvaFiscal, {
      loading: false,
      debitoMesActual,
      error: null,
      creditoMesActual,
      debitoMesAnterior,
      creditoMesAnterior,
      saldo, 
      saldoUltimoMesCargado, 
      estaCargado: true
    })
  });
};

const loadIvaFiscalFail = (state, error) => {
  return updateObject(state, {
    textoIvaFiscal: updateObject(state.textoIvaFiscal, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearIvaFiscal = (state) => {
  return updateObject(state, {
    textoIvaFiscal: updateObject(state.textoIvaFiscal, {
      loading: false,
      error: null,
      debitoMesActual: 0,
      creditoMesActual: 0,
      debitoMesAnterior: 0,
      creditoMesAnterior: 0,
      saldo: 0, 
      saldoUltimoMesCargado: false, 
      estaCargado: false
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEXTO_IVA_FISCAL_START:
      return loadIvaFiscalStart(state);

    case actionTypes.TEXTO_IVA_FISCAL_SUCCESS:
      return loadIvaFiscalSuccess(
        state,
        action.debitoMesActual,
        action.creditoMesActual,
        action.debitoMesAnterior,
        action.creditoMesAnterior, 
        action.saldo,
        action.saldoUltimoMesCargado
      );

    case actionTypes.TEXTO_IVA_FISCAL_FAIL:
      return loadIvaFiscalFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_IVA_FISCAL:
      return clearIvaFiscal(state);

    default:
      return state;
  }
};

export default reducer;
