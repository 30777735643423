// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BarVentasDeSucursalesPorSeleccion__TooltipContainer__gN-HT {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__Title__CdHEV {\r\n  align-self: center;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__Divider__XZvy- {\r\n  height: 1px;\r\n  background-color: black;\r\n  opacity: 0.5;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__Body__J1wCD {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__TextoContainer__rErkC {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__Circle__5ZE-V {\r\n  height: 12px;\r\n  width: 12px;\r\n  border-radius: 2px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__Texto__WeBxs {\r\n  display: flex;\r\n  width: 105%;\r\n  justify-content: space-between;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__TitleDialogTexto__01OKr {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.BarVentasDeSucursalesPorSeleccion__TitleCard__1ZpA5 {\r\n  text-align: right;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipContainer": "BarVentasDeSucursalesPorSeleccion__TooltipContainer__gN-HT",
	"Title": "BarVentasDeSucursalesPorSeleccion__Title__CdHEV",
	"Divider": "BarVentasDeSucursalesPorSeleccion__Divider__XZvy-",
	"Body": "BarVentasDeSucursalesPorSeleccion__Body__J1wCD",
	"TextoContainer": "BarVentasDeSucursalesPorSeleccion__TextoContainer__rErkC",
	"Circle": "BarVentasDeSucursalesPorSeleccion__Circle__5ZE-V",
	"Texto": "BarVentasDeSucursalesPorSeleccion__Texto__WeBxs",
	"TitleDialogTexto": "BarVentasDeSucursalesPorSeleccion__TitleDialogTexto__01OKr",
	"TitleCard": "BarVentasDeSucursalesPorSeleccion__TitleCard__1ZpA5"
};
export default ___CSS_LOADER_EXPORT___;
