import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import moment from 'moment';

import classes from './TablaComparacionUtilidadDelArticuloPorCliente.css';
import { formatNumber } from '../../../../../shared/formatNumber';
import Table from '../../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../../shared/sessionData';
import { compareByAsc, compareByDesc } from '../../../../../shared/sorting';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableComprobantesUtilidad from '../../../Comprobantes/TableComprobantesUtilidad/TableComprobantesUtilidad';

const TablaComparacionUtilidadDelArticuloPorCliente = (props) => {
  const { fechasComparar = [] } = props;
  const [tableState, setTableState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });

  useEffect(() => {
    tableState.splice(0, 2);
    setLoading(true);
    setTimeout(() => {
      fechasComparar.map((fecha) => {
        const separarFecha = moment(fecha).subtract(1, 'month').format('MM/YYYY').split('/');
        const mes = separarFecha[0];
        const anio = separarFecha[1];
        return props.onLoadUtilidadDelArticuloPorCliente(props.vendedorId, props.articuloId, mes, anio, (data) => {
          if (tableState.length === 1) {
            armarComparacion(data);
            setLoading(false);
          } else {
            tableState.push(data);
          }
          setTimeout(
            () =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: 'smooth'
              }),
            100
          );
        });
      });
    }, 500);
  }, [props.show, props.flag]);

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];
    tableState[0].forEach((utilidadesCompleto) => {
      const verify = arrayAComparar.filter((utilidad) => utilidad.nombre === utilidadesCompleto.nombre);
      if (verify.length === 0) {
        arrayNuevo.push({ ...utilidadesCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((utilidad) => {
      return arrayNuevo.push(utilidad);
    });

    arrayAComparar.forEach((utilidadesOrdenadas) => {
      const verify = tableState[0].filter((utilidad) => utilidad.nombre === utilidadesOrdenadas.nombre);

      if (verify.length === 0) {
        tableState[0].push({ ...utilidadesOrdenadas, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

    setTableState([
      { dateComplete: props.fechasComparar[0], utilidadesDetallada: orderTableState },
      { dateComplete: props.fechasComparar[1], utilidadesDetallada: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];

    tableState.map((data, key) => {
      if (key === 0) {
        return data.utilidadesDetallada.map((utilidadDetallada, index) => {
          var utilidadDetalladaDelIndex = tableState[1].utilidadesDetallada[index];

          const utilidadDelIndex = utilidadDetalladaDelIndex
            ? tableState[1].utilidadesDetallada[index]
            : { mes: 0, anio: 0, id: '0', nombre: 'undefined', monto: 0, porcentajeUtilidadSobreElTotal: 0 };
          return formatTableData.push({
            itemId: utilidadDetallada.id,
            item: utilidadDetallada.nombre,
            monto1: utilidadDetallada.monto,
            porcParticipacion1: utilidadDetallada.porcentajeUtilidadSobreElTotal,
            monto2: utilidadDelIndex.monto,
            porcParticipacion2: utilidadDelIndex.porcentajeUtilidadSobreElTotal,
            difMontos: calcularDif(utilidadDetallada.monto, utilidadDelIndex.monto),
            porcComparacion: calcularPorc(utilidadDetallada.monto, utilidadDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const columns = [
    {
      title: 'Cliente',
      field: 'item',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const headersBackgroundColor = 'rgb(220, 220, 220)';

  return (
    <Table
      style={{ padding: '10px', width: '100%', marginBottom: '10px' }}
      title=""
      noBoxShadow
      columns={columns}
      loading={loading}
      components={{
        Header: (props) => {
          return (
            <TableHead>
              {fechasComparar.length === 2 && (
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>

                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px'
                    }}
                    colSpan={2}
                    align="center"
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px'
                    }}
                    colSpan={2}
                    align="center"
                  ></TableCell>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                    align="center"
                  ></TableCell>
                  <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }} align="left"></TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('item')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    width: window.screen.width < 1367 ? '265px' : '410px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="left"
                >
                  Cliente
                </TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('monto1')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('porcParticipacion1')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('monto2')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('porcParticipacion2')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('difMontos')}
                  style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('porcComparacion')}
                  style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                  align="right"
                ></TableCell>
              </TableRow>
            </TableHead>
          );
        }
      }}
      detailPanel={[
        {
          disabled: false,
          tooltip: 'Mostrar Comprobantes',
          render: (rowData) => {
            return (
              <div className={classes.TablasComparacionComprobantesContainer}>
                {fechasComparar.map((fecha, index) => {
                  const formatFecha = moment(fecha).subtract(1, 'month').format('MM/YYYY');
                  const separarFecha = formatFecha.split('/');
                  return (
                    <div style={{ width: '100%' }} key={index}>
                      <div
                        style={{
                          marginRight: index === 0 ? '5px' : '10px',
                          marginLeft: index === 1 ? '5px' : '10px'
                        }}
                      >
                        <TableComprobantesUtilidad
                          minimalInfo
                          mes={separarFecha[0]}
                          anio={separarFecha[1]}
                          clienteId={rowData.itemId}
                          articuloId={props.articuloId}
                          vendedorId={props.vendedorId}
                          fecha={formatFecha}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
        }
      ]}
      data={data()}
      customOptions={{
        thirdSortClick: false,
        sorting: true,
        grouping: false,
        draggable: false,
        search: false,
        paging: false,
        toolbar: false,
        tableLayout: 'fixed',
        showTitle: false,
        maxBodyHeight: 270,
        rowStyle: (rowData) => ({
          color: rowData.tableData.monto < 0 && 'red'
        })
      }}
      tableKey={"tabla-comparacion-utilidad-articulo-cliente"}
    />
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadUtilidadDelArticuloPorCliente: (idVendedor, idArticulo, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelArticuloPorCliente(idVendedor, idArticulo, mes, anio, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionUtilidadDelArticuloPorCliente);
