import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaVentasDeLaSucursalPorClienteDelArticulo.css';
import TableComprobantesVentasDeLaSucursalDelArticuloAlCliente from '../../Comprobantes/TableComprobantesSucursales/TableComprobantesVentasDeLaSucursalDelArticuloAlCliente';

const TablaVentasDeLaSucursalPorClienteDelArticulo = (props) => {
  const { sucursalId, articuloId } = props;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes ? props.mes : props.itemExpanded.mes;
  const anio = props.anio ? props.anio : props.itemExpanded.anio;

  useEffect(() => {
    setLoading(true);
    props.onLoadVentasPorClientesDelArticulo(
      mes,
      anio,
      sucursalId,
      articuloId,
      (response) => {
        setVentasLocal(response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: 'Cliente',
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220, 220, 220)'
      }
    },
    {
      title: `Porc. participación`,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220, 220, 220)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: `Monto`,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220, 220, 220)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion
      };
    });
  };

  return (
    <div className={classes.tablaVentasPorClienteDelArticulo}>
      <Table
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        title=""
        noBoxShadow
        columns={columns}
        loading={loading}
        detailPanel={(rowData) => {
          return (
            <TableComprobantesVentasDeLaSucursalDelArticuloAlCliente
              customWidth={'90%'}
              sucursalId={sucursalId}
              clienteId={rowData.id}
              articuloId={articuloId}
              mes={mes}
              anio={anio}
            />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 300,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-ventas-sucursal-cliente-articulo"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorClientesDelArticulo: (mes, anio, sucursalId, articuloId, success, failError) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDelArticulo(mes, anio, sucursalId, articuloId, success, failError)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDeLaSucursalPorClienteDelArticulo);
