import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { formatNumberWithoutDecimals, formatNumber, range } from '../../../../shared/formatNumber';
import LineChart from '../../../Charts/CollapsableLineChart';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import CustomSelector from '../../../UI/CustomSelector/CustomSelector';
import classes from './LineEvolucionVentasPorPeriodo.css';
import CustomSwitch from '../../../UI/CustomSwitch/CustomSwitch';
import locales from '../../../../shared/locales';
import { compareByDesc } from '../../../../shared/sorting';
import FilterListIcon from '@material-ui/icons/FilterList';

import LineChartFiltros from './LineChartFiltros';

const LineChartVentasComparativas = (props) => {
  const { dataSelectedId, dataOptions, dataSelectOptions, dataSelectedPorPeriodosId, onShowFiltersModal } = props;
  const { inSelectRange, onSelectRangeChange, wandKey, clearData } = props;

  const [ventasSeleccionados, setVentasSeleccionados] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [itemsPeriodosAnual, setItemsPeriodosAnual] = useState([]);
  const [itemPeriodosAnual, setItemPeriodosAnual] = useState('');
  const [loading, setLoading] = useState(false);

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  //#region CHART

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const ventasActual = ventasSeleccionados;

    if (ventasActual[seriesIndex].data.length !== 0) {
      ventasActual[seriesIndex].data = [];
    } else {
      const ventasKey = dataOptions[dataSelectedPorPeriodosId].ventasKey;
      const vntAct = ventasSeleccionados[seriesIndex];
      const venta = props[ventasKey].ventas.find((vnt) => vnt.id === itemPeriodosAnual.id);

      if (venta) {
        const desdeAux = mesDesde.getMonth() + 1;
        const hastAux = mesHasta.getMonth() + 1;
        let mesesAux = obtenerMeses(desdeAux, hastAux);
        let ventasAux = showMonto ? venta.montos : venta.unidades;
        let fieldAux = showMonto ? 'monto' : 'unidadesVendidas';
        let data = [];

        mesesAux.forEach((mesAux) => {
          var anioTemp = hastAux < desdeAux && mesAux >= desdeAux ? vntAct.id - 1 : vntAct.id;
          var ventaTemp = ventasAux.find((vnt) => vnt.anio === anioTemp && vnt.mesDesde === mesAux);

          if (ventaTemp)
            data.push(ventaTemp[fieldAux]);
        });

        ventasActual[seriesIndex].data = data;
      }
    }

    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    const desdeAux = mesDesde.getMonth() + 1;
    const hastAux = mesHasta.getMonth() + 1;
    const mesesAux = obtenerMeses(desdeAux, hastAux);
    const mes = mesesAux[dataPointIndex];
    let anio = ventasSeleccionados[seriesIndex].id;
    anio = hastAux < desdeAux && mes >= desdeAux ? anio - 1 : anio;
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}/${anio}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${ventasSeleccionados
        .filter((venta) => venta.data.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((venta) => {
          let text = showMonto
            ? `${storageMonedaSigno()} ${getNumberWithDecimals(venta.data[dataPointIndex])}`
            : `${formatNumberWithoutDecimals(venta.data[dataPointIndex])}`;
          return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(venta.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${venta.name}: ${text} 
                      </span>
                    </div>
                `;
        })
        .join('')}
              </div>
      </div>
      `;
  };

  const onMarkerClick = (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
    const ventasKey = dataOptions[dataSelectedPorPeriodosId].ventasKey;
    const loadKey = dataOptions[dataSelectedPorPeriodosId].loadKey;
    const vntAct = ventasSeleccionados[seriesIndex];
    const desde = props[ventasKey].fechas[dataPointIndex].desde;
    const hasta = props[ventasKey].fechas[dataPointIndex].hasta;
    const desdeAux = mesDesde.getMonth() + 1;
    const hastAux = mesHasta.getMonth() + 1;
    let anio = vntAct ? vntAct.id : props[ventasKey].fechas[dataPointIndex].anio;
    anio = hastAux < desdeAux && desde >= desdeAux ? anio - 1 : anio;
    let anioHasta = vntAct ? vntAct.id : props[ventasKey].fechas[dataPointIndex].anio;
    anioHasta = hastAux < desdeAux && desde >= desdeAux ? anioHasta - 1 : anioHasta;
    const chartLoad = props[loadKey];

    props.onMarkerClick("ventasComparativas", chartLoad, desde, hasta, anio, anioHasta, showMonto, dataSelectedPorPeriodosId);
  };

  const formatMesesToStrings = (fechasProp) => {
    const formatFechas = [];

    let tempFechas = [];
    fechasProp.forEach((fechaProp) => {
      let noExist = tempFechas.find((fecha) => fecha.anio === fechaProp.anio && fecha.mes === fechaProp.desde) === undefined;

      if (noExist)
        tempFechas.push({ anio: fechaProp.anio, mes: fechaProp.desde });
    });

    tempFechas.map((fecha) => {
      let label = `${locales[1].options.months[fecha.mes - 1]}`;
      return formatFechas.push(label);
    });

    setFechas(formatFechas);
  };

  const obtenerMeses = (mesDesde, mesHasta) => {
    let meses = [];

    if (mesDesde <= mesHasta) {
      meses = range(mesDesde, mesHasta);
    } else {
      meses = [...range(mesDesde, 12), ...range(1, mesHasta)];
    }

    return meses;
  }

  const fillSeries = (value, ventasSeleccionados, showMonto) => {
    const ventasActual = ventasSeleccionados;
    const ventasKey = dataOptions[dataSelectedPorPeriodosId].ventasKey;
    const ventas = props[ventasKey].ventas;
    const venta = ventas.find((vnt) => vnt.id === value.id);

    if (venta) {
      const desdeAux = mesDesde.getMonth() + 1;
      const hastAux = mesHasta.getMonth() + 1;
      let mesesAux = obtenerMeses(desdeAux, hastAux);
      let ventasAux = showMonto ? venta.montos : venta.unidades;
      let fieldAux = showMonto ? 'monto' : 'unidadesVendidas';

      ventasActual.forEach((vntAct) => {
        let data = [];

        mesesAux.forEach((mesAux) => {
          var anioTemp = hastAux < desdeAux && mesAux >= desdeAux ? vntAct.id - 1 : vntAct.id;
          var ventaTemp = ventasAux.find((vnt) => vnt.anio === anioTemp && vnt.mesDesde === mesAux);

          if (ventaTemp)
            data.push(ventaTemp[fieldAux]);
        });

        vntAct.data = data;
      })
    }

    setItemPeriodosAnual(value);
    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  }

  const series = (ventas, anios) => {
    let formatSeries = [];
    let formatVentas = [];

    ventas.forEach((venta) => {
      formatSeries.push({ nombre: venta.nombre, id: venta.id });
    });

    anios.forEach((anio) => {
      formatVentas.push({ id: anio, name: `${anio}`, data: [] });
    })

    formatVentas = formatVentas.sort((a, b) => compareByDesc(a, b, 'name'));

    setItemsPeriodosAnual(formatSeries);
    return setVentasSeleccionados(formatVentas);
  };

  const middleControls = () => {
    return (
      <Tooltip
        title={
          verifyEmptyVentasSeleccioandos.length > 0
            ? !inSelectRange
              ? 'Al activar esta función, podrá seleccionar 2 períodos para compararlos.'
              : 'Haga click en dos períodos diferentes para poder compararlos. (Seleccione 2 puntos diferentes en el gáfico  y se mostrara la comparación en el gráfico de más abajo)'
            : 'Seleccione por lo menos una categoría para activar la selección de períodos'
        }
      >
        <Typography style={{ marginBottom: '1px', marginTop: '1%', marginRight: '1%' }}>
          {!inSelectRange ? `Active la selección de períodos → ` : `Seleccione 2 períodos para comparar `}
          {
            <IconButton
              disabled={verifyEmptyVentasSeleccioandos.length === 0}
              style={{
                backgroundColor: inSelectRange && verifyEmptyVentasSeleccioandos.length !== 0 && 'rgb(255 208 159)'
              }}
              size="small"
              onClick={(e) => handleChangeSelectRange(e)}
            >
              <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            </IconButton>
          }
        </Typography>
      </Tooltip>
    );
  };

  const chartTitle = () => {
    const description = dataOptions[dataSelectedPorPeriodosId]
      && dataOptions[dataSelectedPorPeriodosId].description
      && dataOptions[dataSelectedPorPeriodosId].description.toLowerCase();

    return (
      <Grid item xs={12} lg={5} className={classes.Title}>
        <Typography className={classes.TitleText}>
          Ventas comparativas mensuales por año por {description}
        </Typography>
      </Grid>
    )
  }

  const titles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} container direction='row' justifyContent='space-around' alignContent='center' spacing={1} className={classes.Header}>
          {chartTitle()}

          <Grid item xs={11} lg={4} className={classes.SpecialTool} style={{ textAlign: 'center' }}>
            {middleControls()}
          </Grid>

          <Grid item xs={1} lg={3} className={classes.Filters}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => onShowFiltersModal()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', marginLeft: '0.7%' }}>
          <CustomSwitch
            value={showMonto}
            onChange={() => onShowMontoChange(!showMonto)}
            firstLabel='Unidades'
            secondLabel='Importe Neto'
          />
        </Grid>
      </Grid>
    )
  }

  const verifyEmptyVentasSeleccioandos = ventasSeleccionados.filter((ventSelect) => ventSelect.data.length !== 0);

  const handleChangeSelectRange = (e) => {
    e.preventDefault();
    onSelectRangeChange(wandKey);
  };

  //#endregion

  //#region CAMBIAR TIPO DE VISUALIZACION
  const [showMonto, setShowMonto] = useState(true);

  const onShowMontoChange = (value) => {
    setShowMonto(value);
    fillSeries(itemPeriodosAnual, ventasSeleccionados, value);
  }

  //#endregion

  //#region FILTROS
  const [mesDesde, setMesDesde] = useState();
  const [mesHasta, setMesHasta] = useState();

  const aplicarFiltrosSuccess = (data, fechas, desde, hasta, anios) => {
    setMesDesde(desde);
    setMesHasta(hasta);
    series(data, anios);
    formatMesesToStrings(fechas);
    setLoading(false);
  }

  const aplicarFiltrosFail = () => {
    setLoading(false);
  }

  const aplicarFiltros = (selectionId, desdeDate, hastaDate, aniosSelected, userSelected) => {
    setLoading(true);
    setVentasSeleccionados([]);
    setFechas([]);
    const desde = desdeDate.getMonth() + 1;
    const hasta = hastaDate.getMonth() + 1;
    setMesDesde(desde);
    setMesHasta(hasta);
    const loadEvolucionKey = props[dataOptions[selectionId].loadEvolucionKey];
    loadEvolucionKey(desdeDate, hastaDate, aniosSelected, aplicarFiltrosSuccess, aplicarFiltrosFail, userSelected);
  }

  const onItemPeriodoAnualChange = (event) => {
    let value = event.target.value;
    fillSeries(value, ventasSeleccionados, showMonto);
  }

  const filtros = () => {
    return (
      <Grid container spacing={1}>
        <Grid item container xs={12} spacing={1} className={classes.Dropdown}>

          <Grid item xs={9} md={10} lg={8} xl={6}>
            <LineChartFiltros
              dataOptions={dataOptions}
              dataSelectOptions={dataSelectOptions}
              titleProps={props}
              limitDates={false}
              filtros={props.filtros}
              updateFiltros={props.updateFiltros}
              onAplicarFiltro={aplicarFiltros}
              filtrosDefault={{
                ...props.filtrosInteranual,
                dataSelectedId,
                dataSelectedPorPeriodosId
              }}
              actualizarData={props.actualizarData}
              updateData={props.updateData}
              multipleAnios
            />
          </Grid>

          <Grid item xs={3} md={3} lg={2} xl={2}>
            <CustomSelector
              id='serieId'
              label={`Seleccionar ${dataOptions[dataSelectedPorPeriodosId].description}`}
              value={itemPeriodosAnual}
              items={itemsPeriodosAnual}
              onChange={onItemPeriodoAnualChange}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Grid>

    )
  }

  //#endregion

  //#region CLEAR DATA
  const [clear, setClear] = useState(true);

  useEffect(() => {
    if (clear && clearData) {
      setVentasSeleccionados([]);
      setFechas([]);
      setSeriesBandera(false);
      setItemsPeriodosAnual([]);
      setItemPeriodosAnual('');
      setClear(false);
    }
  }, [clear, clearData]);

  //#endregion

  return (
    <LineChart
      title={titles()}
      collapseTitle={chartTitle()}
      series={ventasSeleccionados}
      subTitle={filtros()}
      seriesBandera={seriesBandera}
      loading={loading}
      chartTextAlign={'left'}
      eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
      eventsMarkerClick={(event, chartContext, { seriesIndex, dataPointIndex, config }) =>
        inSelectRange && onMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config })
      }
      middleItems={middleControls()}
      legendOnItemClick={false}
      chartHeight={'450px'}
      width={'100%'}
      chartWidth={'99%'}
      chartDropShadow={{
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }}
      toolbarShow={true}
      tooltipEnabled
      customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
        armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
      }
      zoomType={'xy'}
      dataLabelsEnabled={window.screen.width > 500}
      formatDataLabels
      strokeCurve={'smooth'}
      strokeWidth={5}
      fillType={'solid'}
      gridBorderColor={'#e7e7e7'}
      gridRow={{
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }}
      markersSize={4}
      xaxisCategories={fechas}
      xaxisType="category"
      yaxisLabels={{
        show: true,
        formatter: (value) => {
          return showMonto
            ? `${storageMonedaSigno()} ${getNumberWithDecimals(value)}`
            : `${formatNumberWithoutDecimals(value)}`;
        },
        align: 'left',
        offsetX: -16
      }}
      legendFontSize={'11px'}
      legendShowForSingleSeries={true}
      legendShowForNullSeries={true}
      legendShowForZeroSeries={true}
      disableLegendOnItemClickToggle
      disableLengendOnItemHover
      legendPosition={'right'}
      legendHorizontalAlign={'left'}
      legendOffsetY={10}
      onFiltersClick={() => onShowFiltersModal()}
      withoutDecimals={props.withoutDecimals}
      showMonto={showMonto}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataVentasDelMesPorSelected,
    dataSelectedPorPeriodosId: state.ventas.dataVentasPorPeriodosSelected,
    filtros: state.ventas.filtroVentasComparativasPorPeriodos,
    filtrosInteranual: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado,
    ventasPorMarca: state.ventas.ventasComparativasPorMarca,
    ventasPorRubro: state.ventas.ventasComparativasPorRubro,
    ventasPorArticulo: state.ventas.ventasComparativasPorArticulo,
    ventasPorCanal: state.ventas.ventasComparativasPorCanal,
    ventasPorCliente: state.ventas.ventasComparativasPorCliente,
    ventasPorGrupoCompra: state.ventas.ventasComparativasPorGrupoCompra,
    ventasPorProvincia: state.ventas.ventasComparativasPorProvincia,
    ventasPorResponsable: state.ventas.ventasComparativasPorResponsable,
    ventasPorUnidadNegocio: state.ventas.ventasComparativasPorUnidadNegocio,
    ventasPorVendedor: state.ventas.ventasComparativasPorVendedor,
    ventasPorZona: state.ventas.ventasComparativasPorZona,
    ventasPorSubrubro: state.ventas.ventasComparativasPorSubrubro,
    ventasPorCategoriaCliente: state.ventas.ventasComparativasPorCategoriaCliente,
    actualizarData: state.ventas.actualizarDataVentasComparativasPorPeriodos,
    clearData: state.ventas.clearDataVentasComparativasPorPeriodos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFiltros: (desde, hasta, anios, dataSelectedId, aplicado, userSelected) => {
      dispatch(actions.seleccionDataVentasPorPeriodosPor(dataSelectedId));
      dispatch(actions.updateFiltroVentasComparativasPorPeriodos(desde, hasta, anios, dataSelectedId, aplicado, userSelected));
    },
    updateData: () => dispatch(actions.updateDataComparativasPorPeriodos(false)),

    //#region Load Ventas Del Mes
    onLoadVentasDelMesPorMarca: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Marca', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorRubro: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Rubro', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorArticulo: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Articulo', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorCanal: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Canal', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorCliente: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Cliente', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorGrupoCompra: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('GrupoDeCompra', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorProvincia: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Provincia', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorResponsable: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Responsable', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorUnidadNegocio: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('UnidadDeNegocio', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorVendedor: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Vendedor', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorZona: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Zona', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorSubrubro: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('Subrubro', desde, hasta, anio, anioHasta, success, errorFail)),
    onLoadVentasDelMesPorCategoriaCliente: (desde, hasta, anio, anioHasta, success, errorFail) =>
      dispatch(actions.loadVentasPorPeriodosPorTipoVenta('CategoriaCliente', desde, hasta, anio, anioHasta, success, errorFail)),
    //#endregion

    //#region Load Evolucion Ventas
    onLoadEvolucionVentasPorMarca: (desde, hasta, anios, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Marca', desde, hasta, anios, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorRubro: (desde, hasta, anios, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Rubro', desde, hasta, anios, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorArticulo: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Articulo', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorCanal: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Canal', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorCliente: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Cliente', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorGrupoCompra: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('GrupoDeCompra', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorProvincia: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Provincia', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorResponsable: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Responsable', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorUnidadNegocio: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('UnidadDeNegocio', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorVendedor: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Vendedor', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorZona: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Zona', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorSubrubro: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('Subrubro', desde, hasta, anio, success, errorFail, userSelected)),
    onLoadEvolucionVentasPorCategoriaCliente: (desde, hasta, anio, success, errorFail, userSelected) =>
      dispatch(actions.loadVentasComparativasPorTipoVenta('CategoriaCliente', desde, hasta, anio, success, errorFail, userSelected)),
    //#endregion

    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineChartVentasComparativas);