import React, { useState } from 'react';
import CustomSwitch from '../../CustomSwitch/CustomSwitch';

const CambiarVistaIcon = (props) => {
  const { config, onChange } = props;
  const [showFields, setShowFields] = useState(config && config.showFields);

  const onVistaChange = (value) => {
    const config = {
      showFields: value,
      visible: value,
      showRows: value,
      showFilter: value,
      showData: value,
      showColums: value
    };

    if (onChange)
      onChange(config);
    
    setShowFields(value)
  }

  return (
    <CustomSwitch
      value={showFields}
      title='Cambiar modo vista'
      onChange={() => onVistaChange(!showFields)}
      firstLabel='Informe'
      secondLabel='Configuración'
    />
  )
}

export default CambiarVistaIcon;