import React, {} from "react";
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import ImportadorBudgetForecast from "../../../components/Indicadores/Personalizados/Tenacta/Importador/ImportadorBudgetForecast";

const Importacion = (props) => {
  const { ejecucionDePagina } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ImportadorBudgetForecast ejecucionDePagina={ejecucionDePagina} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Importacion);