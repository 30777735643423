// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaInteranualPlaceholder__Container__8ObH\\+ {\r\n    display:flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 95%;\r\n    align-items: center;\r\n    box-shadow: 0px 3px 6px #00000029;\r\n    box-sizing: border-box;\r\n    background-color: white;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 5px;\r\n    height: 424px;\r\n}\r\n\r\n.TablaInteranualPlaceholder__Placeholder__7FKa5{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    width: 80%;\r\n    align-items: center;\r\n    box-shadow: 0px 3px 6px #00000029;\r\n    box-sizing: border-box;\r\n    background-color: white;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 5px;\r\n}\r\n\r\n.TablaInteranualPlaceholder__Imagen__CiQ\\+A {\r\n    display: flex; \r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 10%;\r\n    height: 54%; \r\n    width: 50%; \r\n}\r\n\r\n.TablaInteranualPlaceholder__TextContainer__KcDuL{\r\n    width: 90%;\r\n    display: flex; \r\n    flex-wrap: nowrap;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border:none\r\n    \r\n}\r\n\r\n.TablaInteranualPlaceholder__Text__JAnOY{\r\n    font-size: 20px;\r\n    text-align: center;\r\n    flex-wrap: nowrap;\r\n    width: 100%;\r\n    color: #515151;\r\n    \r\n}\r\n\r\n.TablaInteranualPlaceholder__HighlightText__agc7p{\r\n    font-size: 20px;\r\n    color: #FF8800;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "TablaInteranualPlaceholder__Container__8ObH+",
	"Placeholder": "TablaInteranualPlaceholder__Placeholder__7FKa5",
	"Imagen": "TablaInteranualPlaceholder__Imagen__CiQ+A",
	"TextContainer": "TablaInteranualPlaceholder__TextContainer__KcDuL",
	"Text": "TablaInteranualPlaceholder__Text__JAnOY",
	"HighlightText": "TablaInteranualPlaceholder__HighlightText__agc7p"
};
export default ___CSS_LOADER_EXPORT___;
