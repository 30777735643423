import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../Charts/TextChart";

const TextoCantidadVentas = (props) => {
  const { cantidad, loading, error, title } = props;

  return (
    <TextChart
      title={`${title ? title : `Cantidad de ventas`}`}
      bigAmount={cantidad}
      loading={loading}
      imgSinDatos={error}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.ventas.ventasPorVendedor.error,
    estaCargado: state.ventas.ventasPorVendedor.estaCargado,
    loading: state.ventas.ventasPorVendedor.loading,
    cantidad: state.ventas.ventasPorVendedor.cantidad,
    unidadesVendidas: state.ventas.ventasPorVendedor.unidadesVendidas,
    total: state.ventas.ventasPorVendedor.ventas,
    clientSelected: state.clientes.clienteById.clientSelected,
    clienteId: state.clientes.clienteById.cliente.idCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoCantidadVentas);