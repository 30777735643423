import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import moment from 'moment';

import * as actions from '../../../../store/actions/index';

import LineChart from '../../../Charts/CustomLineChart';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Button from '../../../UI/Button/Button';

import classes from './LineComparacionVentasEntreVendedores.css';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import { Card, Grid, Typography, FormControl, IconButton, InputLabel, MenuItem, Popper, Select, Tooltip } from '@material-ui/core';
import BoxStepInfo from '../../../UI/BoxStepInfo/BoxStepInfo';
import TableComparacionVentasEntreVendedores from '../TableComparacionVentasEntreVendedores/TableComparacionVentasEntreVendedores';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import FilterListIcon from '@material-ui/icons/FilterList';

const LineEvolucionVentasPorSeleccion = (props) => {
  const { dataSelectionId } = props;
  const [ventasSeleccionados, setVentasSeleccionados] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [loadingComparacion, setLoadingComparacion] = useState(false);
  const [ventasComparar, setVentasComparar] = useState([]);
  const [formatVentasCompletos] = useState([]);
  const [totalLocal, setTotalLocal] = useState(0);
  const [showTooltipComparacion, setShowTooltipComparacion] = useState(false);
  const [fechaComparacion, setFechaComparacion] = useState('');
  const [vendedoresConSeleccion, setVendedoresConSeleccion] = useState([]);

  const dataOptions = {
    categoriaDeCliente: {
      stringId: 'categoriaDeCliente',
      description: 'Categorías de cliente',
      ventasKey: 'ventasPorCategoria',
      loadKey: 'onLoadVentasDelMesPorCategoriaDeCliente',
      loadDetallesKey: 'onLoadDetallesCategoriaDeCliente'
    },
    vendedor: {
      stringId: 'vendedor',
      description: 'Vendedores',
      ventasKey: 'ventasPorVendedores',
      loadKey: 'onLoadVentasDelMesPorCategoriaDeCliente',
      loadDetallesKey: 'onLoadDetallesVendedor'
    },
    provincia: {
      stringId: 'provincia',
      description: 'Provincias',
      ventasKey: 'ventasPorPrivincias',
      loadKey: 'onLoadVentasDelMesPorProvincias',
      loadDetallesKey: 'onLoadDetallesProvincia'
    },
    zona: {
      stringId: 'zona',
      description: 'Zonas',
      ventasKey: 'ventasPorZonas',
      loadKey: 'onLoadVentasDelMesPorZonas',
      loadDetallesKey: 'onLoadDetallesZona'
    },
    marca: {
      stringId: 'marca',
      description: 'Marcas',
      ventasKey: 'ventasPorMarca',
      loadKey: 'onLoadVentasDelMesPorMarca',
      loadDetallesKey: 'onLoadDetallesaMarca'
    },
    rubro: {
      stringId: 'rubro',
      description: 'Rubros',
      ventasKey: 'ventasPorRubro',
      loadKey: 'onLoadVentasDelMesPorRubro',
      loadDetallesKey: 'onLoadDetallesRubro'
    },
    subrubro: {
      stringId: 'subrubro',
      description: 'Subrubros',
      ventasKey: 'ventasPorSubrubro',
      loadKey: 'onLoadVentasDelMesPorSubrubro',
      loadDetallesKey: 'onLoadDetallesSubrubro'
    }
  };

  useEffect(() => {
    formatVentasCompletos.splice(0, 2);
    ventasComparar.splice(0, 2);
    setVentasComparar([]);
    if (props.ejecucionDePagina && !props.ventasPorVendedores.estaCargado) {
      setVentasComparar([]);
      setTotalLocal(0);
      props.onLoadEvolucionVentasPorVendedores();
    }
  }, [props.ejecucionDePagina, dataSelectionId]);

  useEffect(() => {
    if (ventasSeleccionados.length === 0) {
      series();
      formatMesesToStrings();
    }
  }, [props.ventasPorVendedores.ventas]);

  const series = () => {
    let formatVentas = [];
    const ventas = props.ventasPorVendedores.ventas;
    ventas.map((venta) => {
      vendedoresConSeleccion.push({ id: venta.id, nombre: venta.nombre, estaSeleccionado: false });
      return formatVentas.push({
        name: venta.nombre,
        data: []
      });
    });

    return setVentasSeleccionados(formatVentas);
  };

  const formatMesesToStrings = () => {
    const formatFechas = [];
    const fechasProp = props.ventasPorVendedores.fechas;
    fechasProp.map((fecha) => {
      return formatFechas.push(`${moment(new Date(fecha.anio, fecha.mes, 1)).subtract(1, 'month').format('MM/YY')}`);
    });
    setFechas(formatFechas);
  };

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const ventasActual = ventasSeleccionados;
    if (ventasActual[seriesIndex].data.length !== 0) {
      ventasActual[seriesIndex].data = [];
    } else {
      const montos = props.ventasPorVendedores.ventas[seriesIndex].montos.map((montoMesAnio) => montoMesAnio.monto);
      ventasActual[seriesIndex].data = montos;
    }

    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${ventasSeleccionados
        .filter((venta) => venta.data.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((venta) => {
          return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(venta.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${venta.name}: ${storageMonedaSigno()} ${formatNumber(venta.data[dataPointIndex])} 
                      </span>
                    </div>
                `;
        })
        .join('')}
              </div>
      </div>
      `;
  };

  const onCompararClick = () => {
    ventasComparar.splice(0, 2);
    formatVentasCompletos.splice(0, 2);
    const vendedoresAComparar = vendedoresConSeleccion.filter((vend) => vend.estaSeleccionado);
    const loadKey = dataOptions[dataSelectionId].loadKey;

    const dateComplete = new Date(fechaComparacion.anio, fechaComparacion.mes, 1);

    setLoadingComparacion(true);
    return vendedoresAComparar.map((vendedor) => {
      return props[loadKey](vendedor.id, fechaComparacion.mes, fechaComparacion.anio, (ventasCompleto) => {
        if (ventasComparar.length === 1 && formatVentasCompletos.length === 1) {
          var arrayNuevo = [];
          formatVentasCompletos[0].forEach((ventaCompleto) => {
            const verify = ventasCompleto.filter((venta) => venta.id === ventaCompleto.id);
            if (verify.length === 0) {
              arrayNuevo.push({ ...ventaCompleto, monto: 0, porcentajeDeParticipacion: 0 });
            }
          });

          ventasCompleto.map((venta) => {
            return arrayNuevo.push(venta);
          });
          ventasCompleto.forEach((ventaOrdenado) => {
            const verify = formatVentasCompletos[0].filter((venta) => venta.id === ventaOrdenado.id);

            if (verify.length === 0) {
              formatVentasCompletos[0].push({ ...ventaOrdenado, monto: 0, porcentajeDeParticipacion: 0 });
            }
          });

          var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
          var orderFormatVentasCompletos = formatVentasCompletos[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

          setVentasComparar([
            {
              vendedor: ventasComparar[0].vendedor,
              dateComplete: ventasComparar[0].dateComplete,
              ventas: orderFormatVentasCompletos
            },
            { vendedor: vendedor, dateComplete: dateComplete, ventas: orderArrayNuevo }
          ]);
          setLoadingComparacion(false);
        } else {
          formatVentasCompletos.push(ventasCompleto);
          ventasComparar.push({ vendedor, dateComplete: dateComplete, ventas: ventasCompleto });
        }
      });
    });
  };

  const anchorRef = useRef();

  const titles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} l={12} container spacing={1} className={classes.Header}>
          <Grid item xs={8} lg={5} className={classes.Title}>
            <Typography className={classes.TitleText}>
              {`Ventas mensuales por ${dataOptions[dataSelectionId].description.toLowerCase()}`}
            </Typography>
          </Grid>

          <Grid item xs={11} lg={4} className={classes.SpecialTool} style={{ textAlign: 'center' }}>
            {middleControls()}
          </Grid>

          <Grid item xs={1} lg={3} className={classes.Filters}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal(`Ventas mensuales por ${dataOptions[dataSelectionId].description.toLowerCase()}`)}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Title}>
          <Typography className={classes.SubtitleText} >
            (Haga clic sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información)
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const middleControls = () => {
    return (
      <div ref={anchorRef}>
        {`Cliquee para seleccionar un mes y dos vendedores para compararlos → `}
        {
          <IconButton
            disabled={props.ventasPorVendedores.loading}
            style={{
              backgroundColor: showTooltipComparacion && 'rgb(255 208 159)'
            }}
            size="small"
            onClick={() => setShowTooltipComparacion(!showTooltipComparacion)}
          >
            <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
          </IconButton>
        }
        {tooltipComparacionContent()}
      </div>
    );
  };

  const subTitle = () => {
    let total = props[dataOptions[dataSelectionId].ventasKey].total
      ? `Total: ${storageMonedaSigno()} ${formatNumber(props[dataOptions[dataSelectionId].ventasKey].total)}`
      : `Total: ${storageMonedaSigno()} ${formatNumber(totalLocal)}`;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} l={12} container spacing={1} className={classes.Header}>
          <Grid item xs={12} className={classes.Title}>
            <Typography className={classes.TotalNumberText}>
              {total}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const onVendedorComparacionClick = (e, index) => {
    const vendedoresActual = [...vendedoresConSeleccion];
    vendedoresActual[index].estaSeleccionado = !vendedoresConSeleccion[index].estaSeleccionado;
    return setVendedoresConSeleccion(vendedoresActual);
  };

  const onQuitarVendedorComparacionClick = (e, index) => {
    const vendedoresActual = [...vendedoresConSeleccion];
    vendedoresActual[index].estaSeleccionado = false;
    return setVendedoresConSeleccion(vendedoresActual);
  };

  const tooltipComparacionContent = () => {
    const verificarCountVendedoresComparacion = vendedoresConSeleccion.filter((vend) => vend.estaSeleccionado);

    return (
      <Popper open={showTooltipComparacion} anchorEl={anchorRef.current} role={undefined}>
        <Card>
          {selectFechas()}
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {vendedoresConSeleccion.map((venta, index) => {
              return (
                <div
                  key={index}
                  className={classes.selectorVendoresAComparar}
                  style={
                    vendedoresConSeleccion[index].estaSeleccionado
                      ? {
                        paddingLeft: '25px',
                        backgroundColor: 'rgb(255 208 159)'
                      }
                      : undefined
                  }
                  onClick={(e) =>
                    verificarCountVendedoresComparacion.length < 2
                      ? onVendedorComparacionClick(e, index)
                      : vendedoresConSeleccion[index].estaSeleccionado
                        ? onQuitarVendedorComparacionClick(e, index)
                        : alert('No puede seleccionar mas de dos vendedores')
                  }
                >
                  {venta.nombre}
                </div>
              );
            })}
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              loading={props.loading}
              disabled={fechaComparacion === '' || verificarCountVendedoresComparacion.length < 2}
              onClick={() => onCompararClick()}
            >
              Comparar
            </Button>
          </div>
        </Card>
      </Popper>
    );
  };

  const selectFechas = () => {
    const fechasProps = props.ventasPorVendedores.fechas;
    return (
      <FormControl style={{ margin: '0px 10px 0px 10px' }}>
        <InputLabel id="sucursalInputLabel">Seleccionar fecha</InputLabel>
        <Select
          style={{ minWidth: 155 }}
          value={fechaComparacion}
          onChange={(e) => {
            setFechaComparacion(e.target.value);
          }}
        >
          {fechasProps.map((fecha, index) => {
            const cutFormat = moment(new Date(fecha.anio, fecha.mes - 1, 1)).format('MM/YY');
            return (
              <MenuItem key={index} value={fecha}>
                {cutFormat}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const inVendedores = dataSelectionId === 'vendedor';

  const warningInVendedoresSelected = () => {
    return (
      <Tooltip title="Esta seleccionado la vista por vendedor, por este motivo, la tabla de comparacion se mostrará por categoria de cliente">
        <WarningRoundedIcon style={{ color: 'red', opacity: 0.6 }} fontSize="small" />
      </Tooltip>
    );
  };

  return (
    <div className={classes.LineEvolucionVentasMensualContainer}>
      <BoxStepInfo
        texto={
          '1° Paso - Haga click sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información.'
        }
      />
      <LineChart
        title={titles()}
        series={ventasSeleccionados}
        subTitle={subTitle()}
        rightItems={inVendedores && warningInVendedoresSelected()}
        seriesBandera={seriesBandera}
        loading={props.ventasPorVendedores.loading}
        chartTextAlign={'left'}
        eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
        middleItems={middleControls()}
        legendOnItemClick={false}
        chartHeight={'450px'}
        width={'100%'}
        chartWidth={'99%'}
        chartDropShadow={{
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }}
        toolbarShow={true}
        tooltipEnabled
        customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
          armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
        }
        zoomType={'xy'}
        dataLabelsEnabled={window.screen.width > 500}
        formatDataLabels
        strokeCurve={'smooth'}
        strokeWidth={5}
        fillType={'solid'}
        gridBorderColor={'#e7e7e7'}
        gridRow={{
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }}
        markersSize={4}
        xaxisCategories={fechas}
        xaxisType="category"
        yaxisLabels={{
          show: true,
          formatter: (value) => {
            return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
          },
          align: 'left',
          offsetX: -16
        }}
        legendFontSize={'11px'}
        legendShowForSingleSeries={true}
        legendShowForNullSeries={true}
        legendShowForZeroSeries={true}
        disableLegendOnItemClickToggle
        disableLengendOnItemHover
        legendPosition={'right'}
        legendHorizontalAlign={'left'}
        legendOffsetY={10}
        onFiltersClick={() => props.onShowFiltersModal()}
      />
      <div style={{ height: '10px' }} />
      {(ventasComparar.length !== 0 || loadingComparacion) && (
        <TableComparacionVentasEntreVendedores
          ventasComparar={ventasComparar}
          loading={loadingComparacion}
          loadDetallesKey={dataOptions[dataSelectionId].loadDetallesKey}
          ventasDe={dataOptions[dataSelectionId].description}
          dataSelectionId={props.dataSelectionId}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectionId: state.vendedores.dataVentasDelMesDeVendedoresPorSelected,
    ventasPorVendedores: state.vendedores.evolucionVentasDeVendedoresPorVendedor,
    ventasPorCategoria: state.vendedores.evolucionVentasDelVendedorPorCategoriaDeCliente,
    ventasPorProvincias: state.vendedores.evolucionVentasDelVendedorPorProvincia,
    ventasPorZonas: state.vendedores.evolucionVentasDelVendedorPorZonas,
    ventasPorMarca: state.vendedores.evolucionVentasDelVendedorPorMarca,
    ventasPorRubro: state.vendedores.evolucionVentasDelVendedorPorRubro,
    ventasPorSubrubro: state.vendedores.evolucionVentasDelVendedorPorSubrubro
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selectionId) => dispatch(actions.seleccionDataEvolucionVentas(selectionId)),

    //#region ventas por categoria de cliente
    onLoadEvolucionVentasPorCategoriaDeCliente: (success) =>
      dispatch(actions.loadEvolucionVentasPorCategoriaDeCliente(undefined, success)),
    //#endregion

    //#region ventas por vendedores
    onLoadEvolucionVentasPorVendedores: (success) =>
      dispatch(actions.loadEvolucionVentasDeVendedoresPorVendedor(undefined, success)),
    onLoadVentasDelMesPorVendedores: (mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorVendedor(mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por provincias
    onLoadEvolucionVentasPorProvincias: (success) =>
      dispatch(actions.loadEvolucionVentasPorProvincias(undefined, undefined, success)),
    onLoadVentasDelMesPorProvincias: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorProvincia(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por zonas
    onLoadEvolucionVentasPorZonas: (success) =>
      dispatch(actions.loadEvolucionVentasPorZonas(undefined, undefined, success)),
    onLoadVentasDelMesPorZonas: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorZona(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por marca
    onLoadEvolucionVentasPorMarca: (success) =>
      dispatch(actions.loadEvolucionVentasPorMarcas(undefined, undefined, success)),
    onLoadVentasDelMesPorMarca: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorMarca(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por rubro
    onLoadEvolucionVentasPorRubro: (success) =>
      dispatch(actions.loadEvolucionVentasPorRubros(undefined, undefined, success)),
    onLoadVentasDelMesPorRubro: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorRubro(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por subrubro
    onLoadEvolucionVentasPorSubrubro: (success) =>
      dispatch(actions.loadEvolucionVentasPorSubrubro(undefined, undefined, success)),
    onLoadVentasDelMesPorSubrubro: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorSubrubro(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas del vendedor por categoria de cliente
    onLoadVentasDelMesPorCategoriaDeCliente: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorCategoriaDeClienteDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionVentasPorSeleccion);
