import LineChequesEnCartera from '../../components/Dashboard/LineChequesEnCartera/LineChequesEnCartera';
import LineChequesPropios from '../../components/Dashboard/LineChequesPropios/LineChequesPropios';
import TablaCheques from '../../components/Dashboard/TablaCheques/TablaCheques';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const DetalleCheques = (props) => {
  const { onLoadDetail, cheques, tiposCheques, rechazados, delDia, loading, idCliente = '' } = props;

  const renderTipoCheque = (tiposCheques) => {
    switch (tiposCheques) {
      case 'EnCartera':
        return <LineChequesEnCartera />;
      case 'Propios':
        return <LineChequesPropios />;
      default:
        break;
    }
  };

  useEffect(() => {
    onLoadDetail(idCliente, (tiposCheques === 'Propios'), rechazados, delDia);
  }, [delDia, idCliente, onLoadDetail, rechazados, tiposCheques]);
  return (
    <div>
      {renderTipoCheque(tiposCheques)}
      <TablaCheques data={cheques} loading={loading} tiposCheques={tiposCheques} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.detalleCheques.loading,
    cheques: state.ventas.detalleCheques.cheques,
    estaCargando: state.ventas.detalleCheques.estaCargando
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDetail: (idCliente, propios, rechazados, delDia, idEmpresa) => dispatch(actions.loadDetalleCheques(idCliente, idEmpresa, propios, rechazados, delDia)),
    onClearDetail: () => dispatch(actions.clearDetalleCheques())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalleCheques);
