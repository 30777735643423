import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const BarVentasDeSucursalesPorSeleccionInTableToExcel = (props) => {
  const { datas, columns, ventasPor, fecha } = props;

  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var formatData = [];

    datas.map((ventas) => {
      const inTotal = false;

      return formatData.push(
        columns.map((column, columnIndex) => {
          return {
            value:
              columnIndex === 0
                ? ventas.name.join(' ')
                : `${storageMonedaSigno()} ${formatNumber(ventas[column.title])}`,
            style:
              columnIndex === 0
                ? { font: { sz: '10', bold: inTotal }, fill: inTotal && { fgColor: { rgb: 'FFD3D3D3' } } }
                : cellsStyleVerifNegative(ventas[column.title], inTotal)
          };
        })
      );
    });

    return formatData;
  };

  const multiDataSet = () => {
    const formatDataSet = [
      {
        columns: [],
        data: null
      }
    ];

    columns.map((column, index) => {
      const titleCount = column.title ? column.title.trim().length : ventasPor.trim().length;
      const calcPixel = titleCount * 8;

      return formatDataSet[0].columns.push({
        title: column.title ? column.title.trim() : ventasPor,
        width: { wpx: index === 0 ? 170 : calcPixel < 110 ? 110 : calcPixel + 5 },
        style:
          index === 0
            ? {
                font: { sz: '12', bold: true },
                fill: { fgColor: { rgb: 'FFD3D3D3' } }
              }
            : headersStyle
      });
    });

    formatDataSet[0].data = formatData();

    return formatDataSet;
  };

  return (
    <ExcelFile
      filename={`ventas por sucursal por ${ventasPor} del ${fecha.mes}/${fecha.anio}`}
      element={
        <Tooltip title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'28px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={multiDataSet()} name={`Ventas`} />
    </ExcelFile>
  );
};

export default BarVentasDeSucursalesPorSeleccionInTableToExcel;
