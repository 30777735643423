import axios from '../../axios-dashboard';

export const ActualizarPassword = (userId, userModificado, success) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .put(`/ActualizarPassword?id=${userId}`, userModificado, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        return success && success();
      })
      .catch((error) => {
        alert(error);
      });
  };
};
