// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaComparacionUtilidadPorSeleccion__SorteableHeaderTitle__IxyRU {\r\n  cursor: pointer;\r\n  transition: 0.5s;\r\n  -webkit-user-select: none;\r\n  user-select: none;\r\n}\r\n\r\n.TablaComparacionUtilidadPorSeleccion__SorteableHeaderTitle__IxyRU:hover {\r\n  transition: 0.5s;\r\n  color: rgba(0, 0, 0, 0.54);\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SorteableHeaderTitle": "TablaComparacionUtilidadPorSeleccion__SorteableHeaderTitle__IxyRU"
};
export default ___CSS_LOADER_EXPORT___;
