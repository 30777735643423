import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';
import TablaNotasDePedido from '../TablaNotasDePedido/TablaNotasDePedido';

const TextoNotasDePedido = (props) => {
  useEffect(() => {
    if (props.ejecucionDePagina && !props.estaCargado) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  const modalContent = () => {
    props.onOpenClienteModal(true, 'NOTAS DE PEDIDO DEL DÍA', <TablaNotasDePedido />);
  };

  return (
    <TextChart
      title={`Notas de pedido del día`}
      bigAmount={props.monto}
      bigAmountTooltip="Hoy"
      smallAmount={props.montoConvertido}
      smallAmountTooltip={`Total pedidos`}
      loading={props.loading}
      bigCurrency
      smallCurrency
      format
      cursorPointer={!props.estaOculto && !props.error}
      imgSinDatos={props.error}
      onFiltersClick={() => props.onShowFiltersModal()}
      onBodyClick={() => modalContent()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.totalNotasDePedido.loading,
    error: state.ventas.totalNotasDePedido.error,
    monto: state.ventas.totalNotasDePedido.total,
    montoConvertido: state.ventas.totalNotasDePedido.totalConvertido,
    monedaSecundaria: state.ventas.totalNotasDePedido.monedaSecundaria, 
    estaCargado: state.ventas.totalNotasDePedido.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.totalNotasDePedido(idEmpresa)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Notas de pedido', ['pedidos'])),
    onOpenClienteModal: (show, title, body) => dispatch(actions.showEstadoModal(show, title, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoNotasDePedido);
