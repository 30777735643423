import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/';
import moment from 'moment';
import classes from './Gastos.css';
import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import { storageEmpresaId, storageGrupoGastoId, storageMultiempresa } from '../../shared/sessionData';
import LineEvolucionGastosMensualPorCateg from '../../components/Indicadores/Gastos/LineEvolucionGastosMensualPorCateg/LineEvolucionGastosMensualPorCateg';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import { Grid } from '@material-ui/core';
import InteranualGastos from '../../components/Indicadores/Gastos/Interanual/InteranualGastos';
import { fechaActualFunc, fechaMinima } from '../../shared/fechas';
import PieTableGastosPorCategoria from '../../components/Indicadores/Gastos/PieTableGastosPorCategoria/PieTableGastosPorCategoria';
import CustomSelector from '../../components/UI/CustomSelector/CustomSelector';

const useStyles = makeStyles((theme) => ({
  lineEvolucion: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));
const Gastos = (props) => {
  sessionStorage.setItem('seccionNombre', 'Análisis de gastos');
  const { estaCargadoGruposGastos, loadingGruposGastos, onLoadGrupos, gruposGastos,
    gruposGastosSelected, onChangeGruposGastos, clearGastos, periodoSeleccionado } = props;
  const classes2 = useStyles();
  const isMultiemp = storageMultiempresa() === 'true';
  const inPhone = window.screen.width <= 750;
  const [fechaFija] = useState(fechaActualFunc());
  const { gastos, periodos, agregarPeriodo } = props;
  const [fechaPlaceholder] = useState(fechaMinima());

  useEffect(() => {
    if (!estaCargadoGruposGastos && !loadingGruposGastos) {
      onLoadGrupos(storageEmpresaId());
    }
  }, [estaCargadoGruposGastos, loadingGruposGastos]);

  useEffect(() => {
    if (periodos.length === 0) {
      agregarPeriodo(fechaFija);
      if (!inPhone) {
        agregarPeriodo(fechaPlaceholder);
        agregarPeriodo(fechaPlaceholder);
      }
    }
  }, [periodos, fechaFija, agregarPeriodo, inPhone, fechaPlaceholder]);

  const renderItems = props.ejecucionGastos && gastos && gastos.length !== 0 && gastos[0].estaCargado;

  const getGruposGastos = (grupos) => {
    return grupos && grupos.filter(x => x.categorias && x.categorias.length > 0);
  }

  const onChangeGruposGastosSuccess = () => {
    if (periodoSeleccionado)
      clearGastos(periodoSeleccionado.getMonth() + 1, periodoSeleccionado.getFullYear());
  }

  const getGruposGastosSelected = () => {
    const storageId = storageGrupoGastoId();
    const selected = storageId !== undefined && storageId !== "0"
      ? storageId
      : gruposGastosSelected;
    var optionSelected = gruposGastos
      ? selected !== 'select' && selected !== "0" && selected !== 0
        ? gruposGastos.find((g) => g.id === Number(selected))
        : 'select'
      : 'select';

    return optionSelected;
  }

  return (
    <Grid container className={classes.gastosContainer}>
      {isMultiemp &&
        <Grid item xs={12}>
          <EmpresaSelector setEmpresasessionStorage />
        </Grid>}
      <Grid item xs={12} md={6} lg={2} style={{ marginBottom: '10px' }}>
        <CustomSelector
          id="gruposGastosSelector"
          label="Grupos Gastos"
          value={getGruposGastosSelected()}
          items={getGruposGastos(gruposGastos)}
          onChange={(event) => onChangeGruposGastos(event.target.value, onChangeGruposGastosSuccess)}
          isLoading={loadingGruposGastos}
          seleccione
          seleccioneText="Todos"
        />
      </Grid>
      <Grid item xs={12} container>
        {!props.ejecucionGastos ? (
          <BoxEjecutarPaginaInfo />
        ) : (
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
              <InteranualGastos ejecucionDePagina={props.ejecucionGastos} type={'Gasto'} />
            </Grid>
            <Grid item container spacing={1} className={classes.CombinedChartsCard}>
              <PieTableGastosPorCategoria ejecucionDePagina={renderItems} />
            </Grid>
            <Grid item className={classes2.lineEvolucion}>
              <LineEvolucionGastosMensualPorCateg ejecucionDePagina={renderItems} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    periodos: state.gastos.evolucionMensualInteranual.periodos,
    gastos: state.gastos.evolucionMensualInteranual.items,
    periodoSeleccionado: state.gastos.evolucionMensualInteranual.periodoSeleccionado,
    ejecucionGastos: state.ejecucionPaginas.ejecucionGastos,
    loadingGruposGastos: state.gruposGastos.loadGruposGastos.loading,
    estaCargadoGruposGastos: state.gruposGastos.loadGruposGastos.estaCargado,
    gruposGastos: state.gruposGastos.loadGruposGastos.items,
    gruposGastosSelected: state.gruposGastos.gruposGastosSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    agregarPeriodo: (periodo) => dispatch(actions.agregarEvolucionDeGastosMensualInteranual(periodo)),
    updateUltimaActualizacion: () => dispatch(actions.updateUltimaActualizacionGastos(moment(Date.now()).format('DD/MM/YYYY HH:mm'))),
    onLoadGrupos: (empresaId) => dispatch(actions.loadGruposGastos(empresaId)),
    onChangeGruposGastos: (gruposGastos, success) => dispatch(actions.cambiarGruposGastosDeUsuario(gruposGastos, success)),
    clearGastos: (mes, anio) => {
      dispatch(actions.clearEvolucionesDeGastosMensualInteranual());
      dispatch(actions.clearEvolucionGastosPorCategoria());
      dispatch(actions.clearPieGastosPorCategoria());
      dispatch(actions.loadPieGastosPorCategoria(mes, anio));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gastos);