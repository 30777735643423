import * as actionTypes from './actionTypes'
import axios from '../../axios-dashboard'
import { storageEmpresaId, storageMonedaId, storageToken, storageUsuarioId } from '../../shared/sessionData'
import idIndicadores from '../../shared/idIndicadores'

//#region STOCK VALORIZADO
export const stockValorizado = (idEmpresa = storageEmpresaId(), idUsuario = storageUsuarioId()) => {
    return (dispatch) => {
      dispatch(stockValorizadoStart());
      const authStr = 'Bearer '.concat(storageToken());
      axios
        .get(
          `/stock/StockValorizado?idMoneda=${storageMonedaId()}&idUsuario=${idUsuario}&idEmpresa=${idEmpresa}&indicadorId=${
            idIndicadores.ventasDelMes
          }`,
          {
            headers: { Authorization: authStr }
          }
        )
        .then((response) => {
          dispatch(stockValorizadoSuccess(response.data.stock, response.data.orden));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.statusCode === 500) {
              dispatch(stockValorizadoFail(error));
            } else {
              dispatch(stockValorizado(idEmpresa, idUsuario));
            }
          } else {
            dispatch(stockValorizadoFail(error));
          }
        });
    };
};

export const clearStockValorizado = () => {
    return {
      type: actionTypes.CLEAR_STOCK_VALORIZADO
    };
};
  
const stockValorizadoStart = () => {
    return {
      type: actionTypes.STOCK_VALORIZADO_START
    };
};
  
const stockValorizadoSuccess = (stockValorizado, orden) => {
  const stock = stockValorizado.map((item) => {
    return {
      id: `${item.id}`.trimEnd().toUpperCase(),
      name: `${item.nombre}`.trimEnd().toUpperCase(), 
      marca: `${item.marca}`.trimEnd().toUpperCase(), 
      rubro: `${item.rubro}`.trimEnd().toUpperCase(), 
      subrubro: `${item.subrubro}`.trimEnd().toUpperCase(),
      cantidad: item.cantidad,
      costo: item.costo, 
      costoTotal: item.costoTotal,
      porcParticipacion: item.porcentajeDeParticipacion,
    }
  });

  return {
    type: actionTypes.STOCK_VALORIZADO_SUCCESS,
    stock,
    orden
  };
};
  
const stockValorizadoFail = (error) => {
    return {
      type: actionTypes.STOCK_VALORIZADO_FAIL,
      error
    };
};

export const ejecucionStockValorizado = (ejecucion) => {
  return {
    type: actionTypes.EJECUCION_STOCK_VALORIZADO,
    ejecucion,
  };
};
//#endregion

//#region STOCK POR DEPÓSITO
export const stockPorDeposito = (idArticulo) => {
  return (dispatch) => {
    dispatch(stockPorDepositoStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/stock/PorDeposito?idArticulo=${idArticulo}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(stockPorDepositoSuccess(response.data.stock));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(stockPorDepositoFail(error));
          } else {
            dispatch(stockPorDeposito(storageEmpresaId()));
          }
        } else {
          dispatch(stockPorDepositoFail(error));
        }
      });
  };
};

export const clearStockPorDeposito = () => {
  return {
    type: actionTypes.CLEAR_STOCK_POR_DEPOSITO
  };
};

const stockPorDepositoStart = () => {
  return {
    type: actionTypes.STOCK_POR_DEPOSITO_START
  };
};

const stockPorDepositoSuccess = (stock) => {
  return {
    type: actionTypes.STOCK_POR_DEPOSITO_SUCCESS,
    stock: stock
  };
};

const stockPorDepositoFail = (error) => {
  return {
    type: actionTypes.STOCK_POR_DEPOSITO_FAIL,
    error
  };
};
//#endregion