import React from 'react';
import { connect } from 'react-redux';
import TableUtilidadesBase from './TableUtilidadesBase';

const TableUtilidadesPorRubro = (props) => {
  return (
    <TableUtilidadesBase
      titleUtilidadPor="Rubro"
      fieldUtilidadPor="rubro"
      utilidades={props.utilidades}
      loading={props.loading}
      total={props.total}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    utilidades: state.ventas.utilidadPorRubro.utilidades,
    total: state.ventas.utilidadPorRubro.total,
    loading: state.ventas.utilidadPorRubro.loading,
    error: state.ventas.utilidadPorRubro.error,
    estaCargado: state.ventas.utilidadPorRubro.estaCargado
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableUtilidadesPorRubro);
