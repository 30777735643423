// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\r\n  margin: 0;\r\n  padding: 0;\r\n  font-family: 'Roboto';\r\n}\r\n\r\n/*apexcharts-legend apexcharts-align-left position-right*/\r\n.index__apexcharts-legend__o0zi9{\r\n  background-color: red !important;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  width: 8px;\r\n}\r\n\r\n/* Track */\r\n::-webkit-scrollbar-track {\r\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n  border-radius: 10px;\r\n}\r\n\r\n/* Handle */\r\n::-webkit-scrollbar-thumb {\r\n  border-radius: 10px;\r\n  background: rgba(78, 78, 78, 0.8);\r\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n::-webkit-scrollbar-thumb:window-inactive {\r\n  background: rgba(63, 63, 63, 0.4);\r\n}\r\n\r\n/* Pivot Grid */\r\n.index__dx-pivotgrid-vertical-headers__SbocM td span,\r\n.index__dx-pivotgrid-horizontal-headers__DjCjL td span{\r\n  color: red;\r\n}\r\n\r\n/*------------*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"apexcharts-legend": "index__apexcharts-legend__o0zi9",
	"dx-pivotgrid-vertical-headers": "index__dx-pivotgrid-vertical-headers__SbocM",
	"dx-pivotgrid-horizontal-headers": "index__dx-pivotgrid-horizontal-headers__DjCjL"
};
export default ___CSS_LOADER_EXPORT___;
