import React, { useState } from 'react';
import ExportToExcelIcon from '../../../../../UI/Icons/ExportToExcelIcon';
import { formatNumber, formatNumberWithoutDecimals, formatNumberWithoutDecimalsWithMonedaSigno, isEven } from '../../../../../../shared/formatNumber';

const TablasInformeDosToExcel = (props) => {
  const { data, name, filename, disabled, tooltipTitle, monto } = props;

  const [lineas] = useState(['ASPEN', 'IMETEC', 'ORTOPEDIA', 'TENACTA']);
  const headersStyle = {
    font: {
      sz: '12',
      bold: true,
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center'
    },
    fill: {
      patternType: 'solid',
      fgColor: { rgb: 'FFFFF0F6' }
    }
  };

  const columns = (linea) => {
    return [
      {
        title: `${linea}`,
        field: 'idNombreRubro',
        align: 'left'
      },
      {
        title: 'Total',
        field: 'total',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Mix',
        field: 'mix',
        align: 'right',
        type: 'numeric'
      },
      {
        title: 'Cumpl.',
        field: 'cumplimiento',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Superm.',
        field: 'supermercados',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Gr. Retails',
        field: 'grandesRetails',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Med.Retails',
        field: 'medianosRetails',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'E-commerce',
        field: 'ecommerce',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Web Propia',
        field: 'webPropia',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Peq. Retails',
        field: 'pequeniosRetails',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Droguer.',
        field: 'droguerias',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Farmac.',
        field: 'farmacias',
        align: 'right',
        type: 'numeric',
      },
      {
        title: 'Ortoped.',
        field: 'ortopedias',
        align: 'right',
        type: 'numeric',
      }
    ]
  };

  const renderRow = (column, rowData) => {
    if (!rowData.rubro)
      return '';

    if (!rowData[column.field])
      return `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(0) : formatNumberWithoutDecimals(0)}`;

    let rubro = rowData.rubro.replace(/\s+/g, '').toLowerCase();

    if (column.field === 'mix') {
      return rowData.rubro === 'Mix canal' || rowData.rubro.includes('Cumpl') || rowData.rubro.includes('Pendientes')
        ? ''
        : `${formatNumber(rowData[column.field] * 100)}%`;
    }

    if (column.field === 'cumplimiento') {
      return rowData.rubro === 'Mix canal' || rowData.rubro.includes('Cumpl') || rowData.rubro.includes('Pendientes') || rowData.rowType === 1
        ? ''
        : `${formatNumber(rowData[column.field] * 100)}%`;
    }

    return rubro === 'mixcanal' || rubro === 'cumpl.volumen' || rubro === 'cumpl.canal'
      ? `${formatNumber(rowData[column.field] * 100)}%`
      : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData[column.field]) : formatNumberWithoutDecimals(rowData[column.field])}`;
  }

  const renderTotalColumn = (rowData) => {
    if (!rowData.rubro)
      return '';

    let rubro = rowData.rubro.replace(/\s+/g, '').toLowerCase();
    return rubro === 'cumpl.volumen' || rubro === 'mixcanal'
      ? `${formatNumber(rowData.total * 100)}%`
      : rubro === 'cumpl.canal' ? '' : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData.total) : formatNumberWithoutDecimals(rowData.total)}`;
  }

  const cellsStyle = (column, row, value, index) => {
    const backgroundColor = row.rowType === 0
      ? !isEven(index) ? 'FFFBE4B5' : 'FFFFDD98'
      : row.rowType === 1
        ? !isEven(index) ? 'FFE4D8FA' : 'FFDBCAFE'
        : !isEven(index) ? 'FF9EF7B7' : 'FF8FE1A7';
    const style = {
      font: {
        sz: row.rowType === 2 ? '12' : '11',
        bold: row.calculo,
        color: {
          patternType: 'solid',
          fgColor: { rgb: value < 0 ? 'FFFF0000' : 'FF000000' }
        }
      },
      alignment: {
        horizontal: column.align,
        vertical: 'center'
      },
      fill: {
        patternType: 'solid',
        fgColor: { rgb: backgroundColor }
      }
    }

    return style;
  }

  const columnData = (linea) => {
    var headerData = [];
    var filterColumns = columns(linea);
    filterColumns.map((column) => {
      return headerData.push({
        title: column.title,
        width: { wpx: column.field === 'idNombreRubro' ? 250 : 100 },
        style: headersStyle
      });
    });
    return headerData;
  }

  const formatData = (linea) => {
    let tableData = [];
    var filterColumns = columns(linea);
    const datoLinea = data && data.find((item) => item.linea.trimEnd() === linea);
    datoLinea && datoLinea.items && datoLinea.items.map((item, index) => {
      const row = [];
      filterColumns.map((column) => {
        var value = column.field === 'idNombreRubro'
          ? `${item[column.field]}`.toUpperCase()
          : column.field === 'total'
            ? renderTotalColumn(item)
            : renderRow(column, item);
        return row.push({
          value,
          style: cellsStyle(column, item, item[column.field], index)
        });
      });
      return tableData.push(row);
    });

    return tableData;
  }

  const getDataSet = () => {
    let dataSet = [];

    lineas.forEach((linea, index) => {
      dataSet.push({
        ySteps: index !== 0 ? 5 : 0,
        columns: columnData(linea),
        data: formatData(linea)
      })
    });

    return dataSet;
  }

  return (
    <ExportToExcelIcon
      filename={filename}
      name={name}
      dataSet={getDataSet()}
      disabled={disabled}
      tooltipTitle={tooltipTitle}
    />
  )
}

export default TablasInformeDosToExcel;