import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaRentabilidadEvolutivoToExcel = (props) => {
  const { datas, columns } = props;
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var formatData = [];
    datas.map((data) => {
      var formatRow = [];
      columns.map((col) => {
        const inTotal = col.field && col.field === 'fecha' && data[col.field] === 'Total';
        const inPorcResult = col.field && col.field.indexOf('porc') > -1;
        let cell = {};

        if (col.field === 'fecha') {
          cell = {
            value: data.fecha,
            style: {
              font: { sz: '10', bold: inTotal },
              fill: inTotal && { fgColor: { rgb: 'FFD3D3D3' } }
            }
          };
        } else {
          const value = inPorcResult
            ? `${formatNumber(data[col.field])} %`
            : `${storageMonedaSigno()} ${formatNumber(data[col.field])}`;
          cell = {
            value,
            style: cellsStyleVerifNegative(data[col.field], inTotal)
          };
        }

        return formatRow.push(cell);
      });
      return formatData.push(formatRow);
    });

    return formatData;
  };

  const formatColumns = () => {
    var cols = [];

    columns.map((column) => {
      var col = {};

      if(column.field && column.field === 'fecha'){
        col = {
          title: column.title,
          width: { wch: 60 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        };
      }else{
        col = {
          title: column.title,
          width: { wpx: 150 },
          style: headersStyle
        };
      }

      return cols.push(col);
    });

    return cols;
  }

  const multiDataSet = [
    {
      columns: formatColumns(),
      data: formatData()
    }
  ];

  return (
    <ExcelFile
      filename={`Resultados`}
      element={
        <Tooltip title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'28px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={`Resultados`} />
    </ExcelFile>
  );
};

export default TablaRentabilidadEvolutivoToExcel;
