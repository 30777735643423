import React, { createRef } from 'react';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import Table from '../../../UI/Table/Table';
import { MTableHeader, MTableToolbar } from 'material-table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import ModalDetallesComprobante from '../../Comprobantes/ModalDetallesComprobante/ModalDetallesComprobante';
import TablaComprobantesToExcel from './TablaComprobantesToExcel';
import FilterIcon from '../../../UI/Icons/FilterIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',    
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  iconsHeader: {
    display: 'flex',    
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxHeight: '30px',
  },
  table:{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }
}));

const TablaComprobantes = (props) => {
  const {
    loading,
    data,   
    maxHeight,
    minHeight,
    onFiltersClick,
    tituloTabla,
  } = props;

  const classes = useStyles();

  const cellStyles = {
    fontSize: 'smaller',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '0px',
  };

  const headerStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '0px',
  };

  const columns = [
    {
      title: 'Cód.',
      field: 'clienteCodigo',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Cliente',
      field: 'clienteNombre',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`,
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Pendiente',
      field: 'montoPendiente',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.montoPendiente)}`,
    },
    {
      title: 'Total',
      field: 'monto',
      align: 'right',
      cellStyle: (cellData) => {
        return {
          ...cellStyles,
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`,
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante venta comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }} />;
      },
    }
  ];  

  const name = tituloTabla;
  const filename = props.fecha ? `${name} al ${props.fecha}` : name;
  const tableRef = createRef();

  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <Table
          tableRef={tableRef}
          columns={columns}
          style={{ width: props.customWidth ? props.customWidth : '100%', borderRadius: '0px', boxShadow: 'none' }}
          data={data}
          loading={loading}
          noBoxShadow
          components={{
            Header: (props) => (
              <MTableHeader {...props}>
                {(props.columns[0].tableData.width = '15px')}
                {(props.columns[1].tableData.width = '24%')}
                {(props.columns[2].tableData.width = '5%')}
                {(props.columns[3].tableData.width = '22%')}
                {(props.columns[4].tableData.width = '6%')}
                {(props.columns[5].tableData.width = '16%')}
                {(props.columns[6].tableData.width = '14%')}
                {(props.columns[7].tableData.width = '14%')}
                {(props.columns[8].tableData.width = '10px')}
              </MTableHeader>
            ),
            Toolbar: props => (
              <div className={classes.iconsHeader}>
                <div style={{ marginRight: '30px' }}>
                  <MTableToolbar style={{ height: '30px' }} {...props} />
                </div>                
                <TablaComprobantesToExcel
                  columns={columns}
                  data={data}
                  name={name}
                  filename={filename}
                />
                <FilterIcon onClick={onFiltersClick} />
              </div>
            ),
          }}
          customOptions={{
            actionsColumnIndex: -1,
            grouping: false,
            draggable: false,
            showTitle: false,
            detailPanelType: 'single',
            detailPanelColumnAlignment: 'right',
            paging: false,
            overflowY: "auto",
            rowStyle: (rowData, index) => ({
              backgroundColor: isEven(index) ? "#f2f2f2" : "white",
            }),
            maxBodyHeight: maxHeight,
            minBodyHeight: minHeight,
          }}
          tableKey={"tabla-comprobantes"}
        />
      </div>
    </div>
  );
};

export default TablaComprobantes;