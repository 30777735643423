import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

const BarChartIcon = (props) => {
  const { tooltip, iconSize, filterSize, rootClass, iconClass, showChart, onShowChartChange } = props;

  return (
    <Tooltip className={rootClass} title={ tooltip ? tooltip : showChart ? "Ocultar gráficos" : "Mostrar gráficos" }>
      <IconButton className={iconClass} size={iconSize ? iconSize : "small"}         
        onClick={() => onShowChartChange(!showChart)}>
        <AssessmentOutlinedIcon 
          fontSize={filterSize ? filterSize : "small"} 
          style={{ backgroundColor: showChart ? '#ffbf66' : undefined }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default BarChartIcon;