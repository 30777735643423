import { fechaActualFunc } from '../../shared/fechas';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  executeCreateSucursal: false,
  executeUpdateSucursal: false,
  loadingCambiarSucursal: false,
  sucursalAnalisisDeSucursal: {
    id: undefined,
    nombre: ''
  },
  dataVentasDeLaSucursalPorSeleccion: 'categoriaDeCliente',
  fechaVentasDelMesAnioDeLaSucursal: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear()
  },
  dataVentasDeSucursalesPorSeleccion: 'categoriaDeCliente',
  fechaVentasDelMesAnioDeSucursales: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear()
  },
  loadSucursales: {
    sucursales: [],
    loading: false,
    estaCargado: false,
    error: null
  },
  barVentasPorSucursalDelPeriodoComparativo: {
    loading: false,
    error: null,
    ventasPorSucursalPeriodoActual: [],
    ventasPorSucursalPeriodoAnterior: [],
    sucursales: []
  },
  ventasPorMesDeLaSucursal: {
    ventas: [],
    ventasDataTable: [],
    totalVentas: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorCategoriaDeCliente: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorMarca: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorRubro: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorSubrubro: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorProvincia: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorZona: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesDeLaSucursalPorVendedor: {
    ventas: [],
    ventasNegativo: [],
    totalVentas: 0,
    fecha: '',
    loading: false,
    estaCargado: false,
    error: null
  },
  evolucionVentasDeLaSucursalPorCategoriaDeCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDeLaSucursalPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDeLaSucursalPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDeLaSucursalPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDeLaSucursalPorProvincia: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDeLaSucursalPorZona: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDeLaSucursalPorVendedor: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasMensualDeSucursales: {
    ventas: [],
    ventasDataTable: [],
    totalVentas: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasPorSucursalPorCategoriaDeClienteDelMes: {
    total: 0,
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorSucursalPorMarcaDelMes: {
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorSucursalPorRubroDelMes: {
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorSucursalPorSubrubroDelMes: {
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorSucursalPorProvinciaDelMes: {
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorSucursalPorZonaDelMes: {
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false
  },
  ventasPorSucursalPorVendedorDelMes: {
    sucursales: [],
    labels: [],
    parents: [],
    values: [],
    fecha: '',
    loading: false,
    estaCargado: false,
    total: 0
  },
  ventasDeSucursalesDelMesPorCategoriaDeCliente: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDeSucursalesDelMesPorVendedores: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeSucursalesDelMesPorProvincias: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeSucursalesDelMesPorZonas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeSucursalesDelMesPorMarcas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeSucursalesDelMesPorRubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDeSucursalesDelMesPorSubrubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  evolucionVentasPorSucursal: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  }
};

//#region execute create sucursal
const executeCreateSucursal = (state, loading) => {
  return updateObject(state, {
    executeCreateSucursal: loading
  });
};
//#endregion

//#region execute update sucursal
const executeUpdateSucursal = (state, loading) => {
  return updateObject(state, {
    executeUpdateSucursal: loading
  });
};
//#endregion

//#region load sucursales
const loadSucursalesStart = (state) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales: [],
      estaCargado: false,
      loading: true,
      error: null
    })
  });
};

const loadSucursalesSuccess = (state, sucursales) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales,
      estaCargado: true,
      loading: false,
      error: null
    })
  });
};

const loadSucursalesFail = (state, error) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales: [],
      estaCargado: true,
      loading: false,
      error
    })
  });
};

const clearSucursales = (state) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales: [],
      estaCargado: false,
      loading: false,
      error: null
    })
  });
}
//#endregion

//#region bar ventas por sucursal del periodo comparativo
const barVentasPorSucursalDelPeriodoComparativoStart = (state) => {
  return updateObject(state, {
    barVentasPorSucursalDelPeriodoComparativo: updateObject(state.barVentasPorSucursalDelPeriodoComparativo, {
      loading: true,
      ventasPorSucursalPeriodoActual: [],
      ventasPorSucursalPeriodoAnterior: [],
      sucursales: [],
      error: null
    })
  });
};

const barVentasPorSucursalDelPeriodoComparativoSuccess = (
  state,
  ventasPorSucursalPeriodoActual,
  ventasPorSucursalPeriodoAnterior,
  sucursales
) => {
  return updateObject(state, {
    barVentasPorSucursalDelPeriodoComparativo: updateObject(state.barVentasPorSucursalDelPeriodoComparativo, {
      loading: false,
      ventasPorSucursalPeriodoActual: ventasPorSucursalPeriodoActual,
      ventasPorSucursalPeriodoAnterior: ventasPorSucursalPeriodoAnterior,
      sucursales: sucursales
    })
  });
};

const barVentasPorSucursalDelPeriodoComparativoFail = (state, error) => {
  return updateObject(state, {
    barVentasPorSucursalDelPeriodoComparativo: updateObject(state.barVentasPorSucursalDelPeriodoComparativo, {
      loading: false,
      error: error
    })
  });
};
//#endregion

//#region load ventas por mes de la sucursal
const loadVentasPorMesDeLaSucursalStart = (state) => {
  return updateObject(state, {
    ventasPorMesDeLaSucursal: updateObject(state.ventasPorMesDeLaSucursal, {
      ventas: [],
      ventasDataTable: [],
      ventasTotal: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasPorMesDeLaSucursalSuccess = (state, ventas, ventasDataTable, totalVentas) => {
  return updateObject(state, {
    ventasPorMesDeLaSucursal: updateObject(state.ventasPorMesDeLaSucursal, {
      ventas,
      ventasDataTable,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasPorMesDeLaSucursalFail = (state, error) => {
  return updateObject(state, {
    ventasPorMesDeLaSucursal: updateObject(state.ventasPorMesDeLaSucursal, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasPorMesDeLaSucursal = (state) => {
  return updateObject(state, {
    ventasPorMesDeLaSucursal: updateObject(state.ventasPorMesDeLaSucursal, {
      ventas: [],
      ventasDataTable: [],
      ventasTotal: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por categoria de cliente
const loadVentasDelMesDeLaSucursalPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorCategoriaDeCliente: updateObject(state.ventasDelMesDeLaSucursalPorCategoriaDeCliente, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorCategoriaDeClienteSuccess = (
  state,
  ventas,
  ventasNegativo,
  fecha,
  totalVentas
) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorCategoriaDeCliente: updateObject(state.ventasDelMesDeLaSucursalPorCategoriaDeCliente, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorCategoriaDeCliente: updateObject(state.ventasDelMesDeLaSucursalPorCategoriaDeCliente, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorCategoriaDeCliente: updateObject(state.ventasDelMesDeLaSucursalPorCategoriaDeCliente, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por Marca
const loadVentasDelMesDeLaSucursalPorMarcaStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorMarca: updateObject(state.ventasDelMesDeLaSucursalPorMarca, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorMarcaSuccess = (state, ventas, ventasNegativo, fecha, totalVentas) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorMarca: updateObject(state.ventasDelMesDeLaSucursalPorMarca, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorMarcaFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorMarca: updateObject(state.ventasDelMesDeLaSucursalPorMarca, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorMarca = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorMarca: updateObject(state.ventasDelMesDeLaSucursalPorMarca, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por Rubro
const loadVentasDelMesDeLaSucursalPorRubroStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorRubro: updateObject(state.ventasDelMesDeLaSucursalPorRubro, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorRubroSuccess = (state, ventas, ventasNegativo, fecha, totalVentas) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorRubro: updateObject(state.ventasDelMesDeLaSucursalPorRubro, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorRubroFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorRubro: updateObject(state.ventasDelMesDeLaSucursalPorRubro, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorRubro = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorRubro: updateObject(state.ventasDelMesDeLaSucursalPorRubro, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por Subrubro
const loadVentasDelMesDeLaSucursalPorSubrubroStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorSubrubro: updateObject(state.ventasDelMesDeLaSucursalPorSubrubro, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorSubrubroSuccess = (state, ventas, ventasNegativo, fecha, totalVentas) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorSubrubro: updateObject(state.ventasDelMesDeLaSucursalPorSubrubro, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorSubrubro: updateObject(state.ventasDelMesDeLaSucursalPorSubrubro, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorSubrubro = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorSubrubro: updateObject(state.ventasDelMesDeLaSucursalPorSubrubro, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por Provincia
const loadVentasDelMesDeLaSucursalPorProvinciaStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorProvincia: updateObject(state.ventasDelMesDeLaSucursalPorProvincia, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorProvinciaSuccess = (state, ventas, ventasNegativo, fecha, totalVentas) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorProvincia: updateObject(state.ventasDelMesDeLaSucursalPorProvincia, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorProvinciaFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorProvincia: updateObject(state.ventasDelMesDeLaSucursalPorProvincia, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorProvincia = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorProvincia: updateObject(state.ventasDelMesDeLaSucursalPorProvincia, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por Zona
const loadVentasDelMesDeLaSucursalPorZonaStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorZona: updateObject(state.ventasDelMesDeLaSucursalPorZona, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorZonaSuccess = (state, ventas, ventasNegativo, fecha, totalVentas) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorZona: updateObject(state.ventasDelMesDeLaSucursalPorZona, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorZonaFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorZona: updateObject(state.ventasDelMesDeLaSucursalPorZona, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorZona = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorZona: updateObject(state.ventasDelMesDeLaSucursalPorZona, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de la sucursal por Vendedor
const loadVentasDelMesDeLaSucursalPorVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorVendedor: updateObject(state.ventasDelMesDeLaSucursalPorVendedor, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorVendedorSuccess = (state, ventas, ventasNegativo, fecha, totalVentas) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorVendedor: updateObject(state.ventasDelMesDeLaSucursalPorVendedor, {
      ventas,
      ventasNegativo,
      fecha,
      totalVentas,
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const loadVentasDelMesDeLaSucursalPorVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorVendedor: updateObject(state.ventasDelMesDeLaSucursalPorVendedor, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};

const clearVentasDelMesDeLaSucursalPorVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesDeLaSucursalPorVendedor: updateObject(state.ventasDelMesDeLaSucursalPorVendedor, {
      ventas: [],
      ventasNegativo: [],
      totalVentas: 0,
      fecha: '',
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por categoria de cliente
const loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDeLaSucursalPorCategoriaDeCliente,
      {
        ventas: [],
        fechas: [],
        total: 0,
        loading: true,
        error: null,
        estaCargado: false
      }
    )
  });
};

const loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDeLaSucursalPorCategoriaDeCliente,
      {
        ventas,
        fechas,
        total,
        loading: false,
        estaCargado: true
      }
    )
  });
};

const loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDeLaSucursalPorCategoriaDeCliente,
      {
        error,
        loading: false,
        estaCargado: true
      }
    )
  });
};

const clearEvolucionVentasDeLaSucursalPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDeLaSucursalPorCategoriaDeCliente,
      {
        ventas: [],
        fechas: [],
        total: 0,
        loading: true,
        error: null,
        estaCargado: false
      }
    )
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por Marca
const loadEvolucionVentasDeLaSucursalPorMarcaStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorMarca: updateObject(state.evolucionVentasDeLaSucursalPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorMarcaSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorMarca: updateObject(state.evolucionVentasDeLaSucursalPorMarca, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorMarcaFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorMarca: updateObject(state.evolucionVentasDeLaSucursalPorMarca, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeLaSucursalPorMarca = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorMarca: updateObject(state.evolucionVentasDeLaSucursalPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por Rubro
const loadEvolucionVentasDeLaSucursalPorRubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorRubro: updateObject(state.evolucionVentasDeLaSucursalPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorRubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorRubro: updateObject(state.evolucionVentasDeLaSucursalPorRubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorRubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorRubro: updateObject(state.evolucionVentasDeLaSucursalPorRubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeLaSucursalPorRubro = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorRubro: updateObject(state.evolucionVentasDeLaSucursalPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por Subrubro
const loadEvolucionVentasDeLaSucursalPorSubrubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorSubrubro: updateObject(state.evolucionVentasDeLaSucursalPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorSubrubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorSubrubro: updateObject(state.evolucionVentasDeLaSucursalPorSubrubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorSubrubro: updateObject(state.evolucionVentasDeLaSucursalPorSubrubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeLaSucursalPorSubrubro = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorSubrubro: updateObject(state.evolucionVentasDeLaSucursalPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por Provincia
const loadEvolucionVentasDeLaSucursalPorProvinciaStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorProvincia: updateObject(state.evolucionVentasDeLaSucursalPorProvincia, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorProvinciaSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorProvincia: updateObject(state.evolucionVentasDeLaSucursalPorProvincia, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorProvinciaFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorProvincia: updateObject(state.evolucionVentasDeLaSucursalPorProvincia, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeLaSucursalPorProvincia = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorProvincia: updateObject(state.evolucionVentasDeLaSucursalPorProvincia, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por Zona
const loadEvolucionVentasDeLaSucursalPorZonaStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorZona: updateObject(state.evolucionVentasDeLaSucursalPorZona, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorZonaSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorZona: updateObject(state.evolucionVentasDeLaSucursalPorZona, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorZonaFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorZona: updateObject(state.evolucionVentasDeLaSucursalPorZona, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeLaSucursalPorZona = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorZona: updateObject(state.evolucionVentasDeLaSucursalPorZona, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas de la sucursal por Vendedor
const loadEvolucionVentasDeLaSucursalPorVendedorStart = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorVendedor: updateObject(state.evolucionVentasDeLaSucursalPorVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorVendedorSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorVendedor: updateObject(state.evolucionVentasDeLaSucursalPorVendedor, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDeLaSucursalPorVendedorFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorVendedor: updateObject(state.evolucionVentasDeLaSucursalPorVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDeLaSucursalPorVendedor = (state) => {
  return updateObject(state, {
    evolucionVentasDeLaSucursalPorVendedor: updateObject(state.evolucionVentasDeLaSucursalPorVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion de ventas de sucursales
const loadEvolucionDeVentasMensualDeSucursalesStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDeSucursales: updateObject(state.evolucionVentasMensualDeSucursales, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualDeSucursalesSuccess = (state, ventas, ventasDataTable, totalVentas) => {
  return updateObject(state, {
    evolucionVentasMensualDeSucursales: updateObject(state.evolucionVentasMensualDeSucursales, {
      ventas,
      ventasDataTable,
      totalVentas,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualDeSucursalesFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensualDeSucursales: updateObject(state.evolucionVentasMensualDeSucursales, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionDeVentasMensualDeSucursales = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDeSucursales: updateObject(state.evolucionVentasMensualDeSucursales, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas por sucursal por Categoria de cliente
const loadVentasPorSucursalPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorCategoriaDeClienteDelMes: updateObject(state.ventasPorSucursalPorCategoriaDeClienteDelMes, {
      total: 0,
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorCategoriaDeClienteSuccess = (
  state,
  sucursales,
  labels,
  parents,
  values,
  fecha,
  total
) => {
  return updateObject(state, {
    ventasPorSucursalPorCategoriaDeClienteDelMes: updateObject(state.ventasPorSucursalPorCategoriaDeClienteDelMes, {
      total,
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorCategoriaDeClienteDelMes: updateObject(state.ventasPorSucursalPorCategoriaDeClienteDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorCategoriaDeClienteDelMes: updateObject(
      state.ventasPorSucursalPorCategoriaDeClienteDelMes,
      initialState.ventasPorSucursalPorCategoriaDeClienteDelMes
    )
  });
};
//#endregion

//#region load ventas por sucursal por marca
const loadVentasPorSucursalPorMarcaStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorMarcaDelMes: updateObject(state.ventasPorSucursalPorMarcaDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorMarcaSuccess = (state, sucursales, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorSucursalPorMarcaDelMes: updateObject(state.ventasPorSucursalPorMarcaDelMes, {
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorMarcaFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorMarcaDelMes: updateObject(state.ventasPorSucursalPorMarcaDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorMarca = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorMarcaDelMes: updateObject(
      state.ventasPorSucursalPorMarcaDelMes,
      initialState.ventasPorSucursalPorMarcaDelMes
    )
  });
};
//#endregion

//#region load ventas por sucursal por rubro
const loadVentasPorSucursalPorRubroStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorRubroDelMes: updateObject(state.ventasPorSucursalPorRubroDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorRubroSuccess = (state, sucursales, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorSucursalPorRubroDelMes: updateObject(state.ventasPorSucursalPorRubroDelMes, {
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorRubroFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorRubroDelMes: updateObject(state.ventasPorSucursalPorRubroDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorRubro = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorRubroDelMes: updateObject(
      state.ventasPorSucursalPorRubroDelMes,
      initialState.ventasPorSucursalPorRubroDelMes
    )
  });
};
//#endregion

//#region load ventas por sucursal por Subrubro
const loadVentasPorSucursalPorSubrubroStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorSubrubroDelMes: updateObject(state.ventasPorSucursalPorSubrubroDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorSubrubroSuccess = (state, sucursales, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorSucursalPorSubrubroDelMes: updateObject(state.ventasPorSucursalPorSubrubroDelMes, {
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorSubrubroDelMes: updateObject(state.ventasPorSucursalPorSubrubroDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorSubrubro = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorSubrubroDelMes: updateObject(
      state.ventasPorSucursalPorSubrubroDelMes,
      initialState.ventasPorSucursalPorSubrubroDelMes
    )
  });
};
//#endregion

//#region load ventas por sucursal por Provincia
const loadVentasPorSucursalPorProvinciaStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorProvinciaDelMes: updateObject(state.ventasPorSucursalPorProvinciaDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorProvinciaSuccess = (state, sucursales, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorSucursalPorProvinciaDelMes: updateObject(state.ventasPorSucursalPorProvinciaDelMes, {
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorProvinciaFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorProvinciaDelMes: updateObject(state.ventasPorSucursalPorProvinciaDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorProvincia = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorProvinciaDelMes: updateObject(
      state.ventasPorSucursalPorProvinciaDelMes,
      initialState.ventasPorSucursalPorProvinciaDelMes
    )
  });
};
//#endregion

//#region load ventas por sucursal por Zona
const loadVentasPorSucursalPorZonaStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorZonaDelMes: updateObject(state.ventasPorSucursalPorZonaDelMes, {
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorZonaSuccess = (state, sucursales, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorSucursalPorZonaDelMes: updateObject(state.ventasPorSucursalPorZonaDelMes, {
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorZonaFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorZonaDelMes: updateObject(state.ventasPorSucursalPorZonaDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorZona = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorZonaDelMes: updateObject(
      state.ventasPorSucursalPorZonaDelMes,
      initialState.ventasPorSucursalPorZonaDelMes
    )
  });
};
//#endregion

//#region load ventas por sucursal por vendedor
const loadVentasPorSucursalPorVendedorStart = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorVendedorDelMes: updateObject(state.ventasPorSucursalPorVendedorDelMes, {
      total: 0,
      ventas: [],
      fecha: '',
      loading: true,
      estaCargado: false
    })
  });
};

const loadVentasPorSucursalPorVendedorSuccess = (state, sucursales, labels, parents, values, fecha) => {
  return updateObject(state, {
    ventasPorSucursalPorVendedorDelMes: updateObject(state.ventasPorSucursalPorVendedorDelMes, {
      sucursales,
      labels,
      parents,
      values,
      fecha,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSucursalPorVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasPorSucursalPorVendedorDelMes: updateObject(state.ventasPorSucursalPorVendedorDelMes, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearVentasPorSucursalPorVendedor = (state) => {
  return updateObject(state, {
    ventasPorSucursalPorVendedorDelMes: updateObject(
      state.ventasPorSucursalPorVendedorDelMes,
      initialState.ventasPorSucursalPorVendedorDelMes
    )
  });
};
//#endregion

//#region load ventas de sucursales del mes por categoria de cliente
const loadVentasDeSucursalesDelMesPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorCategoriaDeCliente: updateObject(state.ventasDeSucursalesDelMesPorCategoriaDeCliente, {
      ventas: [],
      ventasDataTable: [],
      ventasNegativo: [],
      fecha: '',
      TEXTO_CHEQUES_EN_CARTERA_FAIL: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasDeSucursalesDelMesPorCategoriaDeClienteSuccess = (
  state,
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  totalVentas
) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorCategoriaDeCliente: updateObject(state.ventasDeSucursalesDelMesPorCategoriaDeCliente, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total: totalVentas,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDeSucursalesDelMesPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorCategoriaDeCliente: updateObject(state.ventasDeSucursalesDelMesPorCategoriaDeCliente, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeSucursalesDelMesPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorCategoriaDeCliente: updateObject(state.ventasDeSucursalesDelMesPorCategoriaDeCliente, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas de sucursales del mes por vendedores
const loadVentasDeSucursalesDelMesPorVendedoresStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorVendedores: updateObject(state.ventasDeSucursalesDelMesPorVendedores, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeSucursalesDelMesPorVendedoresSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorVendedores: updateObject(state.ventasDeSucursalesDelMesPorVendedores, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeSucursalesDelMesPorVendedoresFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorVendedores: updateObject(state.ventasDeSucursalesDelMesPorVendedores, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDeSucursalesDelMesPorVendedores = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorVendedores: updateObject(state.ventasDeSucursalesDelMesPorVendedores, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de sucursales del mes por provincias
const loadVentasDeSucursalesDelMesPorProvinciasStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorProvincias: updateObject(state.ventasDeSucursalesDelMesPorProvincias, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeSucursalesDelMesPorProvinciasSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorProvincias: updateObject(state.ventasDeSucursalesDelMesPorProvincias, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeSucursalesDelMesPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorProvincias: updateObject(state.ventasDeSucursalesDelMesPorProvincias, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDeSucursalesDelMesPorProvincias = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorProvincias: updateObject(state.ventasDeSucursalesDelMesPorProvincias, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de sucursales del mes por zonas
const loadVentasDeSucursalesDelMesPorZonasStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorZonas: updateObject(state.ventasDeSucursalesDelMesPorZonas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeSucursalesDelMesPorZonasSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorZonas: updateObject(state.ventasDeSucursalesDelMesPorZonas, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeSucursalesDelMesPorZonasFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorZonas: updateObject(state.ventasDeSucursalesDelMesPorZonas, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDeSucursalesDelMesPorZonas = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorZonas: updateObject(state.ventasDeSucursalesDelMesPorZonas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de sucursales del mes por marcas
const loadVentasDeSucursalesDelMesPorMarcasStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorMarcas: updateObject(state.ventasDeSucursalesDelMesPorMarcas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeSucursalesDelMesPorMarcasSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorMarcas: updateObject(state.ventasDeSucursalesDelMesPorMarcas, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeSucursalesDelMesPorMarcasFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorMarcas: updateObject(state.ventasDeSucursalesDelMesPorMarcas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeSucursalesDelMesPorMarcas = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorMarcas: updateObject(state.ventasDeSucursalesDelMesPorMarcas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de sucursales del mes por rubros
const loadVentasDeSucursalesDelMesPorRubrosStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorRubros: updateObject(state.ventasDeSucursalesDelMesPorRubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeSucursalesDelMesPorRubrosSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorRubros: updateObject(state.ventasDeSucursalesDelMesPorRubros, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeSucursalesDelMesPorRubrosFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorRubros: updateObject(state.ventasDeSucursalesDelMesPorRubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeSucursalesDelMesPorRubros = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorRubros: updateObject(state.ventasDeSucursalesDelMesPorRubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas de sucursales del mes por subrubros
const loadVentasDeSucursalesDelMesPorSubrubrosStart = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorSubrubros: updateObject(state.ventasDeSucursalesDelMesPorSubrubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDeSucursalesDelMesPorSubrubrosSuccess = (state, ventas, ventasNegativo, total, fecha) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorSubrubros: updateObject(state.ventasDeSucursalesDelMesPorSubrubros, {
      ventas,
      ventasNegativo,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDeSucursalesDelMesPorSubrubrosFail = (state, error) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorSubrubros: updateObject(state.ventasDeSucursalesDelMesPorSubrubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDeSucursalesDelMesPorSubrubros = (state) => {
  return updateObject(state, {
    ventasDeSucursalesDelMesPorSubrubros: updateObject(state.ventasDeSucursalesDelMesPorSubrubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load evolucion ventas de vendedores por vendedores
const loadEvolucionVentasPorSucursalStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorSucursal: updateObject(state.evolucionVentasPorSucursal, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorSucursalSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorSucursal: updateObject(state.evolucionVentasPorSucursal, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorSucursalFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorSucursal: updateObject(state.evolucionVentasPorSucursal, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorSucursal = (state) => {
  return updateObject(state, {
    evolucionVentasPorSucursal: updateObject(state.evolucionVentasPorSucursal, initialState.evolucionVentasPorSucursal)
  });
};
//#endregion

//#region Cambiar Sucursal
const executeCambiarSucursal = (state, loadingCambiarSucursal) => {
  return updateObject(state, {
    loadingCambiarSucursal
  });
};

//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECCION_SUCURSAL_ANALISIS_DE_SUCURSAL:
      return { ...state, sucursalAnalisisDeSucursal: action.sucursal };

    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_LA_SUCURSAL:
      return { ...state, fechaVentasDelMesAnioDeLaSucursal: { mes: action.mes, anio: action.anio } };
    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR:
      return { ...state, dataVentasDeLaSucursalPorSeleccion: action.selectionId };

    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_SUCURSALES:
      return { ...state, fechaVentasDelMesAnioDeSucursales: { mes: action.mes, anio: action.anio } };
    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_SUCURSALES_POR:
      return { ...state, dataVentasDeSucursalesPorSeleccion: action.selectionId };

    case actionTypes.EXECUTE_CREATE_SUCURSAL:
      return executeCreateSucursal(state, action.loading);
    case actionTypes.EXECUTE_UPDATE_SUCURSAL:
      return executeUpdateSucursal(state, action.loading);

    case actionTypes.LOAD_SUCURSALES_START:
      return loadSucursalesStart(state);
    case actionTypes.LOAD_SUCURSALES_SUCCESS:
      return loadSucursalesSuccess(state, action.sucursales);
    case actionTypes.LOAD_SUCURSALES_FAIL:
      return loadSucursalesFail(state, action.error);
    case actionTypes.CLEAR_SUCURSALES:
      return clearSucursales(state);

    case actionTypes.BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_START:
      return barVentasPorSucursalDelPeriodoComparativoStart(state);

    case actionTypes.BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_SUCCESS:
      return barVentasPorSucursalDelPeriodoComparativoSuccess(
        state,
        action.ventasPorSucursalPeriodoActual,
        action.ventasPorSucursalPeriodoAnterior,
        action.sucursales
      );

    case actionTypes.BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_FAIL:
      return barVentasPorSucursalDelPeriodoComparativoFail(state, action.error);

    case actionTypes.LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_START:
      return loadVentasPorMesDeLaSucursalStart(state);
    case actionTypes.LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_SUCCESS:
      return loadVentasPorMesDeLaSucursalSuccess(state, action.ventas, action.ventasDataTable, action.totalVentas);
    case actionTypes.LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_FAIL:
      return loadVentasPorMesDeLaSucursalFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_MES_DE_LA_SUCURSAL:
      return clearVentasPorMesDeLaSucursal(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START:
      return loadVentasDelMesDeLaSucursalPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadVentasDelMesDeLaSucursalPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE:
      return clearVentasDelMesDeLaSucursalPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_START:
      return loadVentasDelMesDeLaSucursalPorMarcaStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorMarcaSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_FAIL:
      return loadVentasDelMesDeLaSucursalPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA:
      return clearVentasDelMesDeLaSucursalPorMarca(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_START:
      return loadVentasDelMesDeLaSucursalPorRubroStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorRubroSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_FAIL:
      return loadVentasDelMesDeLaSucursalPorRubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO:
      return clearVentasDelMesDeLaSucursalPorRubro(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_START:
      return loadVentasDelMesDeLaSucursalPorSubrubroStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorSubrubroSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL:
      return loadVentasDelMesDeLaSucursalPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO:
      return clearVentasDelMesDeLaSucursalPorSubrubro(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_START:
      return loadVentasDelMesDeLaSucursalPorProvinciaStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorProvinciaSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL:
      return loadVentasDelMesDeLaSucursalPorProvinciaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA:
      return clearVentasDelMesDeLaSucursalPorProvincia(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_START:
      return loadVentasDelMesDeLaSucursalPorZonaStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorZonaSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_FAIL:
      return loadVentasDelMesDeLaSucursalPorZonaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA:
      return clearVentasDelMesDeLaSucursalPorZona(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_START:
      return loadVentasDelMesDeLaSucursalPorVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS:
      return loadVentasDelMesDeLaSucursalPorVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL:
      return loadVentasDelMesDeLaSucursalPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR:
      return clearVentasDelMesDeLaSucursalPorVendedor(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START:
      return loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.fechas,
        action.total
      );
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE:
      return clearEvolucionVentasDeLaSucursalPorCategoriaDeCliente(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_START:
      return loadEvolucionVentasDeLaSucursalPorMarcaStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorMarcaSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_FAIL:
      return loadEvolucionVentasDeLaSucursalPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA:
      return clearEvolucionVentasDeLaSucursalPorMarca(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_START:
      return loadEvolucionVentasDeLaSucursalPorRubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorRubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_FAIL:
      return loadEvolucionVentasDeLaSucursalPorRubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO:
      return clearEvolucionVentasDeLaSucursalPorRubro(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_START:
      return loadEvolucionVentasDeLaSucursalPorSubrubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorSubrubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL:
      return loadEvolucionVentasDeLaSucursalPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO:
      return clearEvolucionVentasDeLaSucursalPorSubrubro(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_START:
      return loadEvolucionVentasDeLaSucursalPorProvinciaStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorProvinciaSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL:
      return loadEvolucionVentasDeLaSucursalPorProvinciaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA:
      return clearEvolucionVentasDeLaSucursalPorProvincia(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_START:
      return loadEvolucionVentasDeLaSucursalPorZonaStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorZonaSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_FAIL:
      return loadEvolucionVentasDeLaSucursalPorZonaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA:
      return clearEvolucionVentasDeLaSucursalPorZona(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_START:
      return loadEvolucionVentasDeLaSucursalPorVendedorStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS:
      return loadEvolucionVentasDeLaSucursalPorVendedorSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL:
      return loadEvolucionVentasDeLaSucursalPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR:
      return clearEvolucionVentasDeLaSucursalPorVendedor(state);

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_START:
      return loadEvolucionDeVentasMensualDeSucursalesStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_SUCCESS:
      return loadEvolucionDeVentasMensualDeSucursalesSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_FAIL:
      return loadEvolucionDeVentasMensualDeSucursalesFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DE_SUCURSALES:
      return clearEvolucionDeVentasMensualDeSucursales(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START:
      return loadVentasPorSucursalPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorCategoriaDeClienteSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL:
      return loadVentasPorSucursalPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES:
      return clearVentasPorSucursalPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_START:
      return loadVentasPorSucursalPorMarcaStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorMarcaSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_FAIL:
      return loadVentasPorSucursalPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES:
      return clearVentasPorSucursalPorMarca(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_START:
      return loadVentasPorSucursalPorRubroStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorRubroSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_FAIL:
      return loadVentasPorSucursalPorRubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES:
      return clearVentasPorSucursalPorRubro(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_START:
      return loadVentasPorSucursalPorSubrubroStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorSubrubroSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_FAIL:
      return loadVentasPorSucursalPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES:
      return clearVentasPorSucursalPorSubrubro(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_START:
      return loadVentasPorSucursalPorProvinciaStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorProvinciaSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_FAIL:
      return loadVentasPorSucursalPorProvinciaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES:
      return clearVentasPorSucursalPorProvincia(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_START:
      return loadVentasPorSucursalPorZonaStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorZonaSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_FAIL:
      return loadVentasPorSucursalPorZonaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES:
      return clearVentasPorSucursalPorZona(state);

    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_START:
      return loadVentasPorSucursalPorVendedorStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_SUCCESS:
      return loadVentasPorSucursalPorVendedorSuccess(
        state,
        action.sucursales,
        action.labels,
        action.parents,
        action.values,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_FAIL:
      return loadVentasPorSucursalPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES:
      return clearVentasPorSucursalPorVendedor(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_START:
      return loadVentasDeSucursalesDelMesPorVendedoresStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_SUCCESS:
      return loadVentasDeSucursalesDelMesPorVendedoresSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_FAIL:
      return loadVentasDeSucursalesDelMesPorVendedoresFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR:
      return clearVentasDeSucursalesDelMesPorVendedores(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_START:
      return loadVentasDeSucursalesDelMesPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadVentasDeSucursalesDelMesPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.totalVentas
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadVentasDeSucursalesDelMesPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE:
      return clearVentasDeSucursalesDelMesPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_START:
      return loadVentasDeSucursalesDelMesPorProvinciasStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_SUCCESS:
      return loadVentasDeSucursalesDelMesPorProvinciasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_FAIL:
      return loadVentasDeSucursalesDelMesPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA:
      return clearVentasDeSucursalesDelMesPorProvincias(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_START:
      return loadVentasDeSucursalesDelMesPorZonasStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_SUCCESS:
      return loadVentasDeSucursalesDelMesPorZonasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_FAIL:
      return loadVentasDeSucursalesDelMesPorZonasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA:
      return clearVentasDeSucursalesDelMesPorZonas(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_START:
      return loadVentasDeSucursalesDelMesPorMarcasStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_SUCCESS:
      return loadVentasDeSucursalesDelMesPorMarcasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_FAIL:
      return loadVentasDeSucursalesDelMesPorMarcasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA:
      return clearVentasDeSucursalesDelMesPorMarcas(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_START:
      return loadVentasDeSucursalesDelMesPorRubrosStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_SUCCESS:
      return loadVentasDeSucursalesDelMesPorRubrosSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_FAIL:
      return loadVentasDeSucursalesDelMesPorRubrosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO:
      return clearVentasDeSucursalesDelMesPorRubros(state);

    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_START:
      return loadVentasDeSucursalesDelMesPorSubrubrosStart(state);
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_SUCCESS:
      return loadVentasDeSucursalesDelMesPorSubrubrosSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_FAIL:
      return loadVentasDeSucursalesDelMesPorSubrubrosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO:
      return clearVentasDeSucursalesDelMesPorSubrubros(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_START:
      return loadEvolucionVentasPorSucursalStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_SUCCESS:
      return loadEvolucionVentasPorSucursalSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_FAIL:
      return loadEvolucionVentasPorSucursalFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_SUCURSAL:
      return clearEvolucionVentasPorSucursal(state);

    case actionTypes.EXECUTE_CAMBIAR_SUCURSAL:
      return executeCambiarSucursal(state, action.loading);

    default:
      return state;
  }
};

export default reducer;
