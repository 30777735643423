import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';

const TextoVentasMensualesCliente = (props) => {
  const { loading, mesActual, mesAnterior, error, } = props;
  const chartSinDatos = error;
  const fechaTitulo = `${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`;
  const isDisabledChart = props.estaOculto;
  
    return (
      <TextChart
        title={`Ventas del mes ${fechaTitulo}`}
        bigAmount={mesActual}
        bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} `}
        smallAmount={mesAnterior}
        smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} al ${fechaFinPeriodoMesAnteriorFunc()}`}
        loading={loading}
        porcentaje
        bigCurrency
        smallCurrency
        disabled={isDisabledChart}
        border={props.border && !props.error}
        imgSinDatos={chartSinDatos}
        onFiltersClick={() => props.onShowFiltersModal()}
      />
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      loading: state.ventas.ventasDelMesAUnCliente.loading,
      error: state.ventas.ventasDelMesAUnCliente.error,
      estaCargado: state.ventas.ventasDelMesAUnCliente.estaCargado,
      mesActual: state.ventas.ventasDelMesAUnCliente.mesActual,
      mesAnterior: state.ventas.ventasDelMesAUnCliente.mesAnterior,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas del mes', ['venta']))
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(TextoVentasMensualesCliente);
