import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { storageEmpresaId, storageUsuarioId, storageIsAdmin } from "../../../../shared/sessionData";
import { Grid, Typography } from "@material-ui/core";
import ButtonPivotGrid from "../ButtonPivotGrid/ButtonPivotGrid";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Spinner from "../../Spinner/Spinner";
import Table from '../../Table/Table';
import SaveIcon from '@material-ui/icons/Save';
import GetAppIcon from '@material-ui/icons/GetApp';
import ConfirmDialog from '../../../UI/Dialog/ConfirmDialog';

const AdministrarCubo = (props) => {
  const { pivotGridKey, pivotGridRef, onClose } = props;
  const { pivotGridInformaciones, onLoadInformaciones, onSaveInformacion, onLoadData, onCargarFormato } = props;
  const { datos, filtros } = props;
  const { onUpdateDataSource } = props;
  const [idUsuario] = useState(storageUsuarioId());
  const [isAdmin] = useState(false || storageIsAdmin());
  const [formatData, setFormatData] = useState({});

  const [informacion, setInformacion] = useState();
  const selectedRows = () => {
    let rows = props.selectedTotalRowsItems
      ? props.selectedTotalRowsItems.find((i) => i.key === pivotGridKey)
        ? props.selectedTotalRowsItems.find((i) => i.key === pivotGridKey).rows
        : []
      : [];
    return JSON.stringify(rows);
  }

  useEffect(() => {
    if (pivotGridInformaciones) {
      const item = pivotGridInformaciones.find((config) => config.key === pivotGridKey);

      if (!item || (!item.estaCargado && !item.loading))
        onLoadInformaciones(pivotGridKey, idUsuario);
    }
  }, [pivotGridInformaciones, pivotGridKey, idUsuario, onLoadInformaciones]);

  useEffect(() => {
    if (pivotGridInformaciones) {
      const item = pivotGridInformaciones.find((config) => config.key === pivotGridKey);

      if (item && item.estaCargado) {
        setInformacion(item);
      }
    }
  }, [pivotGridInformaciones, pivotGridKey, informacion]);

  // NEW
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220 220 220)'
  };
  const columns = [
    { title: 'ID', field: 'id', align: 'left', width: "5%", cellStyle: cellStyle, headerStyle: headerStyle, hidden: true },
    { title: 'Nombre', field: 'nombre', align: 'left', width: "70%", cellStyle: cellStyle, headerStyle: headerStyle, defaultSort: 'asc', validate: rowData => Boolean(rowData.nombre && rowData.nombre.trim()), },
    { title: 'Público', field: 'esPublica', align: 'center', width: "25%", cellStyle: cellStyle, headerStyle: headerStyle, type: 'boolean' }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = () => {
    onClose();
  }

  //#region ACTUALIZAR CUBO
  // Actualizar cubo confirm dialog
  const [actualizarCuboConfirmDialogOpen, setActualizarCuboConfirmDialogOpen] = useState(false);

  const handleActualizarCuboConfirmDialogOpen = () => {
    setActualizarCuboConfirmDialogOpen(true);
  };

  const handleActualizarCuboConfirmDialogClose = () => {
    setActualizarCuboConfirmDialogOpen(false);
  };
  
  const actualizarCuboConfirmDialog = () => (
    <ConfirmDialog
      show={actualizarCuboConfirmDialogOpen}
      title="Actualizar Cubo"
      body="Si confirma la acción, se pisarán los datos actuales con los nuevos."
      onConfirm={() => {
        handleActualizarCuboConfirmDialogClose();
        onActualizarCubo(formatData);
      }}
      onClose={handleActualizarCuboConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - confirm dialog

  const onActualizarSuccess = () => {
    setIsLoading(false);
    onLoadInformaciones(pivotGridKey, idUsuario);
    alert("La actualización de la estructura del cubo fue guardada correctamente");
  }

  const onActualizarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onActualizarCubo = (rowData) => {
    if (datos === null) {
      alert("No se puede actualizar el cubo debido a que no hay datos en el informe.");
      return;
    }

    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey) ?
          localStorage.getItem(pivotGridKey).fields : [];
      }

      let config = {
        id: rowData.id,
        nombre: rowData.nombre,
        key: pivotGridKey,
        informacion: JSON.stringify(datos),
        configuracion: JSON.stringify(fields),
        esPublica: rowData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: JSON.stringify(filtros),
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onActualizarSuccess, onActualizarFail);
    }
  }
  //#endregion ACTUALIZAR CUBO

  //#region USAR CUBO
  const onUsarCuboSuccess = (rowData) => {
    onUpdateDataSource();
    props.onUpdateSelectedTotalRows(pivotGridKey, rowData.filasSubtotales);
    onCargarFormato(rowData.configuracion, rowData.nombre, rowData.datos);
    setIsLoading(false);
    onClose();
  }

  const onUsarCuboFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onUsarCubo = (rowData) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      setIsLoading(true);
      localStorage.removeItem(pivotGridKey);
      let date = new Date();
      const request = {
        anioFiscal: `${date.getFullYear()}`,
        mesFiscal: `${date.getMonth()}`,
        acumulado: false,
        idEmpresa: storageEmpresaId(),
        idUsuario: idUsuario,
        importacion: true,
        idInformacion: rowData.id,
        nombre: rowData.nombre
      };

      onLoadData(request, onUsarCuboSuccess, onUsarCuboFail);
    }
  }
  //#endregion USAR CUBO

  //#region EDITAR CUBO
  const onEditarSuccess = () => {
    onLoadInformaciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onEditarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onEditarCubo = (newData, oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: newData.nombre,
        key: pivotGridKey,
        informacion: oldData.informacion,
        configuracion: oldData.configuracion,
        esPublica: newData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: oldData.filtro,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onEditarSuccess, onEditarFail);
    }
    resolve();
  }
  //#endregion EDITAR CUBO

  //#region CREAR CUBO
  const onCrearSuccess = () => {
    onLoadInformaciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onCrearFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onCrearCubo = useCallback((newData, resolve) => {
    if (datos === null) {
      alert("No se puede crear el cubo debido a que no hay datos en el informe.");
      resolve();
      return;
    }

    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey) ?
          localStorage.getItem(pivotGridKey).fields : [];
      }

      let config = {
        id: null,
        nombre: newData.nombre,
        key: pivotGridKey,
        informacion: JSON.stringify(datos),
        configuracion: JSON.stringify(fields),
        esPublica: newData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: JSON.stringify(filtros),
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onCrearSuccess, onCrearFail);
    }
    resolve();
  }, [datos, pivotGridRef, pivotGridKey, idUsuario, filtros, onCrearSuccess, onSaveInformacion, selectedRows]);
  //#endregion CREAR CUBO

  //#region BORRAR CUBO
  const onBorrarSuccess = () => {
    onLoadInformaciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onBorrarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onBorrarCubo = (oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: oldData.nombre,
        key: pivotGridKey,
        informacion: oldData.informacion,
        configuracion: oldData.configuracion,
        esPublica: oldData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: oldData.filtro,
        borrado: true,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onBorrarSuccess, onBorrarFail);
    }
    resolve();
  }
  //#endregion BORRAR CUBO

  return isLoading ? (
    <div style={{ textAlign: 'center' }}>
      <Spinner />
    </div>
  ) : (
    <form id="adminPivotGrid" autoComplete="off">
      <Grid container>
        <Grid item xs={12} container spacing={1}
          justifyContent='space-between'>
          <Grid item xs={12} container alignContent='flex-start'>
            <Grid item xs={12}>
              <Typography>
                <h3 style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }}>Administrar Cubos</h3>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table
                style={{ width: '100%' }}
                title=""
                noBoxShadow
                columns={columns}
                data={informacion && informacion.informaciones.filter(c => !c.borrado)}
                editable={{
                  isEditable: rowData => rowData.usuarioId === idUsuario || isAdmin,
                  isDeletable: rowData => rowData.usuarioId === idUsuario,
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      onEditarCubo(newData, oldData, resolve);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      onCrearCubo(newData, resolve);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      onBorrarCubo(oldData, resolve);
                    }),
                }}
                actions={[
                  rowData => {
                    const active = rowData.usuarioId === idUsuario || isAdmin;
                    return {
                      icon: () => <SaveIcon style={{ color: (active ? '#f88d37' : '#bdbdbd') }} />,
                      tooltip: "Actualizar cubo",
                      onClick: (event, rowData) => {
                        setFormatData(rowData)
                        handleActualizarCuboConfirmDialogOpen();
                      },
                      disabled: !active
                    }
                  },
                  {
                    icon: () => <GetAppIcon style={{ color: '#10c5ff' }} />,
                    tooltip: "Usar cubo",
                    onClick: (event, rowData) => onUsarCubo(rowData),
                  },
                ]}
                customOptions={{
                  thirdSortClick: false,
                  sorting: true,
                  grouping: false,
                  draggable: false,
                  search: false,
                  paging: true,
                  toolbar: true,
                  showTitle: false,
                  maxBodyHeight: 350,
                  addRowPosition: 'first',
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50]
                }}
                tableKey={"tabla-administrar-cubo"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}
            container justifyContent='space-between'>
            <Grid item xs={5}>
              <ButtonPivotGrid
                tooltipTitle="Cerrar ventana"
                buttonSize="small"
                text="Cerrar"
                disabled={false}
                onClick={onCancelClick}
                loading={false}
                icon={<CloseOutlinedIcon fontSize="small" />}
                backgroundImage="linear-gradient(45deg, #fbb040, #f15a29)"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {actualizarCuboConfirmDialog()}
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    pivotGridInformaciones: state.tenacta.pivotGridInformaciones.items,
    selectedTotalRowsItems: state.tenacta.selectedTotalRows.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadInformaciones: (key, idUsuario) =>
      dispatch(actions.loadPivotGridInformaciones(key, idUsuario)),
    onSaveInformacion: (informacion, onSuccess, onError) =>
      dispatch(actions.guardarPivotGridInformacion(informacion, onSuccess, onError)),
    onUpdateSelectedTotalRows: (key, rows) => dispatch(actions.updateSelectedTotalRows(key, rows)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrarCubo);