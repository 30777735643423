import React, { useEffect, useState } from 'react';

import classes from './TablaCubo.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import { ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core';
import Spinner from '../../UI/Spinner/Spinner';
import { formatNumber } from '../../../shared/formatNumber';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton } from '@material-ui/core';

import SinResultados from '../../../assets/images/SinResultados.png';
import { storageMonedaSigno } from '../../../shared/sessionData';
import TablaCuboToExcel from './TablaCuboToExcel';

const TablaCubo = (props) => {
  const [indexSelected, setIndexSelected] = useState();

  useEffect(() => {
    if (props.fecha) {
      setIndexSelected((props.fecha.getMonth() + 1) / props.fecha.getFullYear());
    }
  }, [props.datas.length, props.loading, props.fecha]);

  const onMesAnioClick = (e, mes, anio, index) => {
    e.preventDefault();
    setIndexSelected(index);
    props.onSelectMesAnioClick(mes, anio);
  };

  return (
    <div className={classes.tablaCuboContainer}>
      <TableContainer
        style={{ borderRadius: '3px', border: '1px solid #D9D9D9', overflowX: props.loading ? 'unset' : 'auto' }}
        component={Paper}
      >
        <div className={classes.TitleCard}>
          <p style={{ fontWeight: 'bold', marginLeft: '20px', marginRight: '20px', marginBottom: '1px' }}>
            {props.title}
          </p>
          <div>
            {!props.loading && <TablaCuboToExcel datasPorAnio={props.datas} montosDe={props.titleMontos} />}
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
                <FilterListIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <p
          style={{ marginTop: '1px', marginLeft: '20px', marginBottom: '2px', fontSize: '12px' }}
        >{`Total: ${storageMonedaSigno()} ${formatNumber(props.total)}`}</p>
        {!props.loading ? (
          props.datas.length > 0 ? (
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Año</TableCell>
                  <TableCell align="right">Mes</TableCell>
                  <TableCell align="right">{props.titleMontos}</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              {props.datas.map((data, key) => (
                <TableBody key={key}>
                  <TableRow key={key}>
                    <TableCell scope="rowgroup">{data.anio}</TableCell>
                    <TableCell headers={key} scope="row">
                      {data.meses.map((mes, key) => (
                        <ListItem key={key} classes={{ root: classes.listaItems }}>
                          <ListItemText classes={{ root: classes.listaItemsText }}>
                            <Typography classes={{ body1: classes.listaItemsTypography }}> {mes.mes}</Typography>
                            <Divider />
                          </ListItemText>
                        </ListItem>
                      ))}
                    </TableCell>
                    <TableCell>
                      {data.meses.map((mes, key) => (
                        <ListItem key={key} classes={{ root: classes.listaItems }}>
                          <ListItemText classes={{ root: classes.listaItemsText }}>
                            <Typography classes={{ body1: classes.listaItemsTypography }}>
                              {' '}
                              {`${storageMonedaSigno()} ${formatNumber(mes.monto)}`}
                            </Typography>
                            <Divider />
                          </ListItemText>
                        </ListItem>
                      ))}
                    </TableCell>
                    <TableCell>
                      {data.meses.map((mes, index) => (
                        <ListItem
                          key={index}
                          onClick={(event) =>
                            indexSelected !== mes.mes / data.anio &&
                            onMesAnioClick(event, mes.mes, data.anio, mes.mes / data.anio)
                          }
                          classes={{ root: classes.listaItems }}
                          style={{ color: indexSelected === mes.mes / data.anio && '#09D4FF' }}
                        >
                          <ListItemText classes={{ root: classes.listaItemsText }}>
                            <Typography
                              classes={{ root: classes.iconSeleccionarMes }}
                              style={{ textAlign: 'center', lineHeight: '1.37' }}
                            >
                              <SearchIcon
                                style={{ fontSize: '15px', margin: '0px' }}
                                classes={{ root: classes.iconSeleccionarMes }}
                              />
                            </Typography>
                            <Divider />
                          </ListItemText>
                        </ListItem>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          ) : (
            <img src={SinResultados} alt="Sin resultados" />
          )
        ) : (
          <div style={{ textAlign: 'center', minHeight: '220px' }}>
            <Spinner />
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default TablaCubo;
