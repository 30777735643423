import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaStockValorizadoToExcel = (props) => {
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const formatData = () => {
    var formatData = [];

    props.stockValorizado.map((stock) => {
        const inTotal = !stock.id;
        return formatData.push([
            {
                value: stock.id,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: stock.nombre,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: stock.marca,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: stock.rubro,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: stock.subrubro,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: stock.cantidad,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: `${storageMonedaSigno()} ${formatNumber(stock.costo)}`,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: `${storageMonedaSigno()} ${formatNumber(stock.costoTotal)}`,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
            value: `${formatNumber(stock.porcentajeDeParticipacion)}%`,
            style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }

            }
        ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
            { title: 'Cod.', width:{wpx: 100}, style: headersStyle }, 
            { title: 'Nombre', width:{wpx: 250}, style: headersStyle }, 
            { title: 'Marca', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Rubro', width:{wpx: 140}, style: headersStyle }, 
            { title: 'Subrubro', width:{wpx: 80}, style: headersStyle }, 
            { title: 'Cantidad', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Costo', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Costo total', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Porc. Participación', width:{wpx: 150}, style: headersStyle }, 
        ],
      data: formatData()
    }
  ];

  return (
    <ExcelFile
        filename={`Stock Valorizado`}
        element={
            <Tooltip title="Descargar en Excel">
                <IconButton style={{ marginRight: '10px' }} size="small">
                    <img alt="" src={IconConvertToExcel} height={'28px'} />
                </IconButton>
            </Tooltip>
        }
    >
    <ExcelSheet dataSet={multiDataSet} name={`Stock Valorizado`} />
    </ExcelFile>
  );
};

export default TablaStockValorizadoToExcel;
