import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  }
}));

const AddTablaInteranualButton = (props) => {
  const classes = useStyles();
  const { onClick, disabled } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ width: '100%', margin: '5px auto 5px auto', color: 'white' }}
      size="small"
      className={classes.button}
      // startIcon={<AddOutlinedIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Agregar período
    </Button>
  );
};

export default AddTablaInteranualButton;
