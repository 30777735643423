import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, formatTwoIntegerDigits } from '../../../../shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaInteranualContainerToExcel = (props) => {
  const { datasPorPeriodo, montosDe,  } = props;

  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' },
    textTransform: 'capitalize'
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var formatData = [];
    var tempData = [];

    datasPorPeriodo.forEach((dataPorPeriodo) => {
      var tempDataPeriodo = [];

      dataPorPeriodo.datasPorAnio.forEach((dataPorAnio) => {
        dataPorAnio.meses.forEach((mes, index) => {
          tempDataPeriodo.push([
          {
            value: dataPorAnio.anio,
            style: { font: { sz: '10' } }
          },
          {
            value: `${formatTwoIntegerDigits(mes.mes)}`,
            style: cellsStyleVerifNegative(mes.mes)
          },
          {
            value: `${storageMonedaSigno()} ${formatNumber(mes.monto)}`,
            style: cellsStyleVerifNegative(mes.monto)
          },
          {
            value: '',
            style: { font: { sz: '5' } }
          }]);
        });
      });

      tempData.push(tempDataPeriodo);
    });

    tempData.forEach((tempDataPeriodo) => {
      tempDataPeriodo.forEach((tempItem, index) => {
        var item = formatData[index];
  
        if(item === undefined)
          item = [];
        
        item = item.concat(tempItem);
  
        formatData[index] = item;

      })
    });
    
    return formatData;
  };

  const headerYear = { title: 'Año', width: { wpx: 55 }, style: { font: { sz: '12', bold: true }, fill: { fgColor: { rgb: 'FFD3D3D3' } } }};
  const headerMonth = { title: `Mes`, width: { wpx: 50 }, style: headersStyle };
  const headerMontos = { title: montosDe, width: { wpx: 150 }, style: headersStyle };
  const headerEmpty = { title: '', width: { wpx: 50 } };

  const formatHeader = () => {
    let headers = [];

    datasPorPeriodo.forEach(element => {
      headers.push(headerYear);
      headers.push(headerMonth);
      headers.push(headerMontos);
      headers.push(headerEmpty);
    });

    return headers;      
  }

  const getDataSet = () => { 
    return [ { columns: formatHeader(), data: formatData() } ];
  }

  return (
    <ExcelFile
      filename={`Analisis de ${montosDe.toLowerCase()} interanual`}
      element={
        <Tooltip title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'28px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={getDataSet()} name={`${montosDe.toLowerCase()}`} />
    </ExcelFile>
  );
};

export default TablaInteranualContainerToExcel;
