import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import DataGrid, { Column, MasterDetail, Editing, Form, Popup, Position } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
import moment from 'moment';
import DetailChangeLogs from '../DetailChangeLogs/DetailChangeLogs';

const TableChangeLogs = (props) => {
  const { onLoadChangeLogs, changeLogs, onCreateChange } = props;

  useEffect(() => {
    if (changeLogs.length === 0) {
      onLoadChangeLogs();
    }
  }, [changeLogs.length, onLoadChangeLogs]);

  const data = () => {
    return changeLogs.map((changeLog) => ({
      version: changeLog.version,
      dateTime: changeLog.dateTime,
      linkDoc: changeLog.linkDoc
    }));
  };

  const onSaveNewVersionChange = (e) => {
    onCreateChange(e.data);
  };

  const cellLinkDoc = (e) => {
    return (
      <div
        style={{
          display: 'block',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <a href={e.value} rel="noreferrer" target="_blank">
          {e.value}
        </a>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '20px'
      }}
    >
      <DataGrid
        id="grid-container"
        width="90%"
        dataSource={data()}
        keyExpr="version"
        showBorders={true}
        loadPanel={{ enabled: true }}
        onRowInserted={(e) => onSaveNewVersionChange(e)}
      >
        <Editing mode="popup" allowUpdating={false} allowDeleting={false} allowAdding={true}>
          <Popup title="Nuevos Cambios" showTitle={true} width={700} height={525}>
            <Position my="center" at="center" of={window} />
          </Popup>
          <Form>
            <Item dataField="version" />
            <Item dataField="dateTime" />
            <Item dataField="linkDoc" />
            <Item dataField="change" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 100 }} />
          </Form>
        </Editing>
        <Column dataField="version" />
        <Column
          dataField="dateTime"
          caption="Fecha"
          dataType="date"
          format={(data) => moment(data).format('DD/MM/YYYY')}
        />
        <Column dataField="linkDoc" caption="Documentacion" cellRender={(e) => cellLinkDoc(e)} />
        <Column dataField="change" caption="Cambio" visible={false} />

        <MasterDetail enabled={true} component={DetailChangeLogs} />
      </DataGrid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  changeLogs: state.changeLogs.loadChangeLogs.changeLogs,
  loading: state.changeLogs.loadChangeLogs.loading
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChangeLogs: () => dispatch(actions.loadChangeLogs()),
    onCreateChange: (newChange) => dispatch(actions.createChangeLog(newChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableChangeLogs);
