import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './TablaMultiempresa.css';
import { formatNumber } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaMultiempresaToExcel = (props) => {
  
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' }
    };
  };

  const formatData = () => {
    var formatData = [];
    props.montos.map(monto => {
        return formatData.push([
            {
                value: monto.nombreEmpresa,
                style: { font: { sz: '10', bold: true } }
            },
            {
                value: `${storageMonedaSigno()} ${formatNumber(monto.total)}`, 
                style: cellsStyleVerifNegative(monto.total)
            }, 
            {
                value: `${formatNumber(monto.porcentajeDeParticipacion)}%`, 
                style: cellsStyleVerifNegative(monto.porcentajeDeParticipacion)
            }
        ])
    })
    return formatData;
  };

  const formatDataSet = () => {
    const formatedDataSet = [
      {
        columns: [
          {
            title: `Empresa`,
            width: { wpx: 160 },
            style: headersStyle
          },
          {
            title: `Total`,
            width: { wpx: 100 },
            style: headersStyle
          },
          { 
            title: `Porc. Participación`, 
            width: { wpx: 150 }, 
            style: headersStyle 
          },
        ],
        data: formatData()
      }
    ];

    return formatedDataSet;
  };

  return (
    <ExcelFile
      filename={props.title}
      element={
        <Tooltip classes={{ popper: classes.TooltipPopper }} title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'33px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={formatDataSet()} name={props.title} />
    </ExcelFile>
  );
};

export default TablaMultiempresaToExcel;
