import React, { useCallback, useEffect, useState } from 'react';
import { range } from '../../../../shared/formatNumber';
import { Grid, Button, MenuItem, TextField, Tooltip } from '@material-ui/core';
import CustomSelector from '../../../UI/CustomSelector/CustomSelector';
import { makeStyles } from '@material-ui/core/styles';
import FiltroFechaSelector from '../../../UI/FiltroFechaSelector/FiltroFechaSelector';
import { fechaActualFunc } from '../../../../shared/fechas';
import Spinner from '../../../UI/Spinner/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  options: {
    fontSize: 'small',
  },
  checkbox: {
    fontSize: 'x-small',
    "& .MuiFormControlLabel-label": {
      fontSize: 'x-small',
    }
  }
}));

const LineChartFiltros = (props) => {
  const classes = useStyles();
  const { dataSelectOptions, loading, limitDates, onAplicarFiltro, filtros, filtrosDefault, updateFiltros, interanual, multipleAnios } = props;
  const { userSelected, mesDesde, mesHasta, aniosSelected, dataSelectedId } = filtros;
  const { dataSelectedPorPeriodosId } = filtrosDefault;
  const [selectionId, setSelectionId] = useState(filtrosDefault ? dataSelectedPorPeriodosId : null);
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [anio, setAnio] = useState([]);
  const [anios, setAnios] = useState([]);
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const toolTipMsg = interanual
    ? "Seleccione los años a incluir en la comparativa. En caso de seleccionar sólo un año, se incluirán en la comparación todos los años comprendidos entre el año seleccionado y el año actual. En caso de seleccionar sólo el año actual, se agregará a la comparación el año anterior."
    : "Seleccione los años a incluir en la comparativa.";
  const toolTipDesde = interanual
    ? "Seleccione el mes de inicio a incluir en la comparativa."
    : "Seleccione el mes de inicio a incluir en la comparativa. En caso de ser coincidir con el mes 'Hasta', se utilizará el mes anterior en la comparativa.";
  const toolTipHasta = "Seleccione el mes de finalización a incluir en la comparativa.";

  const isValid = useCallback(() => {
    const anioValido = multipleAnios ? anio && anio.length > 0 : anio;
    return selectionId !== '' && selectionId !== null
      && selectionId !== undefined && desde && hasta && anioValido;
  }, [selectionId, desde, hasta, anio]);
  const [filterChange, setFilterChange] = useState(false);

  useEffect(() => {
    const anioDesde = new Date().getFullYear() - 13;
    const anioHasta = new Date().getFullYear() + 3;
    const items = range(anioDesde, anioHasta)
      .map((anio) => {
        return { id: anio, nombre: `${anio}` };
      });
    setAnios(items);
  }, []);

  //#region Seteo propiedades con valores del filtro

  useEffect(() => {
    if (userSelected && selectionId === '') {
      let auxSelectionId = dataSelectedId && dataSelectedId !== ''
        ? dataSelectedId
        : dataSelectedPorPeriodosId
          ? dataSelectedPorPeriodosId : '';

      setSelectionId(auxSelectionId);
    }
  }, [userSelected, dataSelectedId, selectionId]);

  useEffect(() => {
    if (userSelected && mesDesde && !desde) {
      setFilterChange(true);
      setDesde(mesDesde);
      setTimeout(() => setFilterChange(false));
    }
  }, [userSelected, mesDesde, desde]);

  useEffect(() => {
    if (userSelected && mesHasta && !hasta) {
      setFilterChange(true);
      setHasta(mesHasta);
      setTimeout(() => setFilterChange(false));
    }
  }, [userSelected, mesHasta, hasta]);

  useEffect(() => {
    if (userSelected && aniosSelected && aniosSelected.length > 0 && anio && anio.length <= 0) {
      const aux = multipleAnios 
        ? aniosSelected.map((anio) => { return anio.id ? anio : { id: anio, nombre: anio }; })
        : { id: aniosSelected[0], nombre: aniosSelected[0] };
      setAnio(aux);
    }
  }, [userSelected, aniosSelected, anio]);

  //#endregion

  //#region Seteo propiedades con valores default

  useEffect(() => {
    if (filtrosDefault && !userSelected && selectionId === '') {
      setSelectionId(filtrosDefault.dataSelectedId);
    }
  }, [filtrosDefault, userSelected, selectionId]);

  useEffect(() => {
    if (filtrosDefault && !userSelected && !desde) {
      setFilterChange(true);
      const auxHasta = filtrosDefault.hasta ? new Date(filtrosDefault.hasta) : fechaActualFunc();
      let auxDesde = filtrosDefault.desde
        ? new Date(filtrosDefault.desde)
        : new Date(fechaActualFunc().getFullYear() - 1, fechaActualFunc().getMonth() + 1, 1);

      if (!interanual && auxDesde.getMonth() === auxHasta.getMonth())
        auxDesde = new Date(auxDesde.setMonth(auxDesde.getMonth() - 1));

      setDesde(auxDesde);
      setTimeout(() => setFilterChange(false));
    }
  }, [filtrosDefault, userSelected, interanual]);

  useEffect(() => {
    if (filtrosDefault && !userSelected && !hasta) {
      setFilterChange(true);
      const auxHasta = filtrosDefault.hasta ? new Date(filtrosDefault.hasta) : fechaActualFunc();
      setHasta(auxHasta);
      setTimeout(() => setFilterChange(false));
    }
  }, [filtrosDefault, userSelected]);

  useEffect(() => {
    if (filtrosDefault && !userSelected && anio && anio.length <= 0) {
      const fechaActual = fechaActualFunc();
      const hastaAux = filtrosDefault.hasta ? new Date(filtrosDefault.hasta) : fechaActual;

      if (multipleAnios) {
        const desdeAux = filtrosDefault.desde
          ? new Date(filtrosDefault.desde)
          : new Date(fechaActual.getFullYear() - 1, fechaActual.getMonth() + 1, 1);

        setAnio(range(desdeAux.getFullYear(), hastaAux.getFullYear()).map((anio) => {
          return { id: anio, nombre: anio };
        }));
      } else {
        setAnio({ id: hastaAux.getFullYear(), nombre: `${hastaAux.getFullYear()}` });
      }
    }
  }, [filtrosDefault, userSelected]);

  //#endregion

  //#region Seteo propiedades en UI

  const onClick = useCallback(() => {
    onAplicarFiltro(selectionId, desde, hasta, anio, true);
    updateFiltros(desde, hasta, anio, selectionId, true, true);
    setTimeout(() => setCambiosAplicados(true), 100);
  }, [selectionId, desde, hasta, anio]);

  const isValueValid = (item) => {
    if (item === null || item === undefined || (item && item.length === 0)) {
      return false;
    } else {
      if (typeof item === 'string' || item instanceof String) {
        const itemTrim = item.trim();

        if (itemTrim === "") {
          return false;
        }
      }
    }

    return true;
  }

  const onSelectionChange = useCallback((value) => {
    setSelectionId(value);
    setCambiosAplicados(false);
    updateFiltros(desde, hasta, anio, value, false, true);
  }, [desde, hasta, anio]);

  const onDesdeChange = useCallback((value) => {
    setDesde(value);
    setCambiosAplicados(false);
    updateFiltros(value, hasta, anio, selectionId, false, true);
  }, [selectionId, hasta, anio]);

  const onHastaChange = useCallback((value) => {
    setHasta(value);
    setCambiosAplicados(false);
    updateFiltros(desde, value, anio, selectionId, false, true);
  }, [selectionId, desde, anio]);

  const onAniosChange = useCallback((value) => {
    const seletedValue = multipleAnios ? value : value.target.value;
    setAnio(seletedValue);
    setCambiosAplicados(false);
    updateFiltros(desde, hasta, seletedValue, selectionId, false, true);
  }, [selectionId, desde, hasta, multipleAnios]);

  //#endregion

  return (
    <Grid container spacing={1} style={{ marginTop: '5px' }}>
      <Grid item xs={6} md={3}>
        <TextField
          select
          id="optionSelected"
          label="Seleccionar"
          disabled={loading}
          value={selectionId}
          onChange={(event) => onSelectionChange(event.target.value)}
          style={{ color: "black", width: "100%" }}
          className={classes.selector}
          defaultValue=""
          required={true}
          error={!isValueValid(selectionId)}
          helperText={isValueValid(selectionId) ? undefined : "Este campo es requerido"}
        >
          {dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id} className={classes.options}>
                {option.nombre && option.nombre.trimEnd().toUpperCase()}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={3} md={2}>
        {filterChange
          ? <Spinner size="small" />
          : (
            <Tooltip placement='top' title={toolTipDesde}>
              <div>
                <FiltroFechaSelector
                  label="Desde"
                  value={desde}
                  onDateChange={(value) => onDesdeChange(value)}
                  minDate={limitDates ? null : new Date(1990, 0, 1)}
                  maxDate={limitDates ? hasta : null}
                  format="MMMM"
                  views={["month"]}
                  disabled={loading}
                  required={true}
                  error={!isValueValid(desde)}
                  helperText={isValueValid(desde) ? undefined : "Este campo es requerido"}
                />
              </div>
            </Tooltip>
          )}
      </Grid>
      <Grid item xs={3} md={2}>
        {filterChange
          ? <Spinner size="small" />
          : (
            <Tooltip placement='top' title={toolTipHasta}>
              <div>
                <FiltroFechaSelector
                  label="Hasta"
                  value={hasta}
                  onDateChange={(value) => onHastaChange(value)}
                  minDate={limitDates ? desde : new Date(1990, 0, 1)}
                  format="MMMM"
                  views={["month"]}
                  disabled={loading}
                  required={true}
                  error={!isValueValid(hasta)}
                  helperText={isValueValid(hasta) ? undefined : "Este campo es requerido"}
                />
              </div>
            </Tooltip>
          )}
      </Grid>
      <Grid item xs={6} md={3}>
        <CustomSelector
          id={'rangeAnio'}
          label={multipleAnios ? "Incluir los años" : "Año"}
          value={anio}
          items={anios}
          onChange={(value) => onAniosChange(value)}
          disabled={loading}
          multiple={multipleAnios}
          selectAll
          toolTip={toolTipMsg}
          required={true}
          error={!isValueValid(anio)}
          helperText={isValueValid(anio) ? undefined : "Este campo es requerido"}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{
            width: '100%',
            margin: "5px auto 5px auto",
            color: 'white',
            backgroundImage: isValid() && !cambiosAplicados ? "linear-gradient(45deg, #fbb040, #f15a29)" : undefined
          }}
          className={classes.button}
          size="small"
          onClick={onClick}
          disabled={loading || !isValid()}
        >
          Aplicar
        </Button>
      </Grid>
    </Grid>
  )
}

export default LineChartFiltros;