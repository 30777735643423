import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';

import { IconButton, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import DetallesComprobanteVenta from '../DetallesComprobanteVenta/DetallesComprobanteVenta';
import DetallesComprobanteGasto from '../DetallesComprobanteGasto/DetallesComprobanteGasto';
import DetallesComprobanteCobranza from '../DetallesComprobanteCobranza/DetallesComprobanteCobranza';

const ModalDetallesComprobante = (props) => {
  const { onShowModal, comprobante, venta, gasto, cobranza } = props;

  const onShowModalClick = () => {
    venta && onShowModal(<DetallesComprobanteVenta comprobante={comprobante} />);
    gasto && onShowModal(<DetallesComprobanteGasto comprobante={comprobante} />);
    cobranza && onShowModal(<DetallesComprobanteCobranza comprobante={comprobante} />);
  };

  return (
    <div style={{ textAlign: 'right' }}>
      <Tooltip title="Ver detalles">
        <IconButton size="small" onClick={() => onShowModalClick()}>
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.modals.globalModal.show
});

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (body) => dispatch(actions.showGlobalModal(true, '', body, undefined, true, true, 'lg')),
    onChangeModalBody: (newBody) => dispatch(actions.changeGlobalModalBody(newBody)),
    onLoadDetallesComprobante: (idSucursal, idSistema, idComproba, nroReferencia, success, errorFail) =>
      dispatch(actions.loadDetalleComprobante(idSucursal, idSistema, idComproba, nroReferencia, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetallesComprobante);
