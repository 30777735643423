import { fechaActualFunc } from '../../shared/fechas';
import { storageFechaFija } from '../../shared/sessionData';
import * as actions from './index';
import moment from 'moment';

export const actualizarAnalisisVentas = () => {
  return (dispatch, getState) => {
    const { ejecucionAnalisisDeVenta } = getState().ejecucionPaginas;
    if (ejecucionAnalisisDeVenta) {
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
      dispatch(actions.seleccionDataVentasDelMesPor('marca'));
      dispatch(actions.seleccionDataEvolucionVentas(1));
      dispatch(actions.updateDataEvolucionVentasPorPeriodos(true));
      dispatch(actions.updateDataComparativasPorPeriodos(true));
      dispatch(actions.loadUtilidadPorMarca());
      dispatch(actions.loadUtilidadPorRubro());
      dispatch(actions.loadUtilidadPorSubrubro());
      dispatch(actions.loadEvolucionDeVentasMensual());
      dispatch(actions.loadVentasPorCategoriaDeCliente());
      dispatch(actions.clearEvolucionVentasPorRubros());
      dispatch(actions.clearEvolucionVentasPorSubrubro());
      dispatch(actions.clearEvolucionVentasPorProvincias());
      dispatch(actions.clearEvolucionVentasPorVendedores());
      dispatch(actions.clearEvolucionVentasPorZonas());
      dispatch(actions.clearEvolucionesDeVentasMensualInteranual());
      dispatch(actions.clearVentasDelMesPivotGrid());
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Marca'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Rubro'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Articulo'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Canal'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Cliente'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('GrupoDeCompra'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Provincia'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Responsable'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('UnidadDeNegocio'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Vendedor'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Zona'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('CategoriaCliente'));
      dispatch(actions.clearEvolucionVentasPorPeriodosPorTipoVenta('Subrubro'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Marca'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Rubro'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Articulo'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Canal'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Cliente'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('GrupoDeCompra'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Provincia'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Responsable'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('UnidadDeNegocio'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Vendedor'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Zona'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('CategoriaCliente'));
      dispatch(actions.clearVentasComparativasPorTipoVenta('Subrubro'));
    }
  };
};

export const clearAnalisisVentas = () => {
  return (dispatch) => {
    dispatch(actions.seleccionDataVentasDelMesPor('categoriaDeCliente'));
    dispatch(actions.clearVentasPorMarca());
    dispatch(actions.clearVentasPorRubro());
    dispatch(actions.clearVentasPorSubrubro());
    dispatch(actions.clearUtilidadPorMarca());
    dispatch(actions.clearUtilidadPorRubro());
    dispatch(actions.clearUtilidadPorSubrubro());
    dispatch(actions.clearEvolucionDeVentasMensual());
    dispatch(actions.clearEvolucionVentasPorCategoriaDeCliente());
    dispatch(actions.clearEvolucionVentasPorMarcas());
    dispatch(actions.clearEvolucionVentasPorRubros());
    dispatch(actions.clearEvolucionVentasPorSubrubro());
    dispatch(actions.clearEvolucionVentasPorProvincias());
    dispatch(actions.clearEvolucionVentasPorVendedores());
    dispatch(actions.clearEvolucionVentasPorZonas());
    dispatch(actions.clearVentasDelMesPorMarca());
    dispatch(actions.clearVentasDelMesPorRubro());
    dispatch(actions.clearVentasDelMesPorSubrubro());
    dispatch(actions.clearVentasDelMesPorProvincia());
    dispatch(actions.clearVentasDelMesPorVendedor());
    dispatch(actions.clearVentasDelMesPorZona());
    dispatch(actions.clearVentasPorCategoriaDeCliente());
    dispatch(actions.clearEvolucionesDeVentasMensualInteranual());
    dispatch(actions.clearEvolucionesDeUtilidadMensualInteranual());
    dispatch(actions.clearEvolucionesDeGastosMensualInteranual());
    dispatch(actions.clearVentasDelMesPivotGrid());
  };
};

export const actualizarAnalisisSucursales = () => {
  return (dispatch, getState) => {
    const { ejecucionAnalisisDeSucursal } = getState().ejecucionPaginas;
    const { id } = getState().sucursales.sucursalAnalisisDeSucursal;
    const separarFecha = moment(storageFechaFija()).format('MM/YYYY').split('/');
    if (ejecucionAnalisisDeSucursal) {
      dispatch(actions.seleccionFechaVentasDelMesAnioDeLaSucursalPor(separarFecha[0], separarFecha[1]));
      dispatch(actions.loadVentasPorMesDeLaSucursal(id));
      dispatch(actions.seleccionDataVentasDeLaSucursalPor('categoriaDeCliente'));
      dispatch(actions.seleccionDataVentasDeSucursalesPor('categoriaDeCliente'));
      dispatch(actions.loadVentasDelMesDeLaSucursalPorCategoriaDeCliente(separarFecha[0], separarFecha[1], id));
      dispatch(actions.loadEvolucionVentasDeLaSucursalPorCategoriaDeCliente(id));
    }
  };
};

export const clearAnalisisSucursales = () => {
  return (dispatch) => {
    dispatch(actions.seleccionDataVentasDeLaSucursalPor('categoriaDeCliente'));
    dispatch(actions.seleccionDataVentasDeSucursalesPor('categoriaDeCliente'));
    dispatch(actions.clearVentasPorMesDeLaSucursal());
    dispatch(actions.clearVentasPorMesDeLaSucursal());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorCategoriaDeCliente());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorMarca());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorProvincia());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorRubro());
    dispatch(actions.clearVentasDeLaSucursalPorMarca());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorSubrubro());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorVendedor());
    dispatch(actions.clearEvolucionVentasDeLaSucursalPorZona());
    dispatch(actions.clearVentasDeLaSucursalPorCategoriaDeCliente());
    dispatch(actions.clearVentasDeLaSucursalPorProvincia());
    dispatch(actions.clearVentasDeLaSucursalPorRubro());
    dispatch(actions.clearVentasDeLaSucursalPorSubrubro());
    dispatch(actions.clearVentasDeLaSucursalPorVendedor());
    dispatch(actions.clearVentasDeLaSucursalPorZona());
  };
};

export const clearComparacionAnalisisSucursales = () => {
  return (dispatch) => {
    dispatch(actions.clearEvolucionDeVentasMensualDeSucursales());
    dispatch(actions.clearVentasPorSucursalPorCategoriaDeClienteDelMes());
    dispatch(actions.clearVentasPorSucursalPorMarcaDelMes());
    dispatch(actions.clearVentasPorSucursalPorRubroDelMes());
    dispatch(actions.clearVentasPorSucursalPorSubrubroDelMes());
    dispatch(actions.clearVentasPorSucursalPorProvinciaDelMes());
    dispatch(actions.clearVentasPorSucursalPoZonaDelMes());
    dispatch(actions.clearVentasDeSucursalesPorCategoriaDeCliente());
    dispatch(actions.clearVentasDeSucursalesDelMesPorMarca());
    dispatch(actions.clearVentasDeSucursalesDelMesPorRubro());
    dispatch(actions.clearVentasDeSucursalesDelMesPorSubrubro());
    dispatch(actions.clearVentasDeSucursalesDelMesPorVendedor());
    dispatch(actions.clearVentassDeSucursalesDelMesPorProvincia());
    dispatch(actions.clearVentasDeSucursalesDelMesPorZona());
    dispatch(actions.clearEvolucionVentasPorSucursal());
  };
};

export const actualizarAnalisisVendedores = () => {
  return (dispatch, getState) => {
    const { ejecucionAnalisisDeVendedor } = getState().ejecucionPaginas;
    const { id_vendedor } = getState().vendedores.vendedorSelected;

    if (ejecucionAnalisisDeVendedor) {
      dispatch(
        actions.seleccionFechaVentasDelMesAnioDelVendedorPor({
          mes: fechaActualFunc().getMonth(),
          anio: fechaActualFunc().getFullYear()
        })
      );
      dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente'));
      dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor('categoriaDeCliente'));

      dispatch(actions.loadEvolucionDeVentasMensualDelVendedor(id_vendedor));
      dispatch(actions.loadVentasPorCategoriaDeClienteDelVendedor(id_vendedor));
      dispatch(actions.loadEvolucionVentasDelVendedorPorCategoriaDeCliente(id_vendedor));
      dispatch(actions.clearEvolucionVentasDelVendedorPorProvincias());
      dispatch(actions.clearEvolucionVentasDelVendedorPorMarcas());
      dispatch(actions.clearEvolucionVentasDelVendedorPorRubros());
      dispatch(actions.clearEvolucionVentasDelVendedorPorSubrubro());
      dispatch(actions.clearEvolucionVentasDelVendedorPorZonas());

      dispatch(actions.clearVentasDelMesPorMarcaDelVendedor());
      dispatch(actions.clearVentasDelMesPorRubroDelVendedor());
      dispatch(actions.clearVentasDelMesPorSubrubroDelVendedor());
      dispatch(actions.clearVentasDelMesPorProvinciaDelVendedor());
      dispatch(actions.clearVentasDelMesPorZonaDelVendedor());
    }
  };
};

export const clearAnalisisVendedores = () => {
  return (dispatch) => {
    dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor('categoriaDeCliente'));
    dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente'));

    dispatch(actions.clearEvolucionDeVentasMensualDelVendedor());
    dispatch(actions.clearVentasPorCategoriaDeClienteDelVendedor());
    dispatch(actions.clearVentasDelMesPorMarcaDelVendedor());
    dispatch(actions.clearVentasDelMesPorRubroDelVendedor());
    dispatch(actions.clearVentasDelMesPorSubrubroDelVendedor());
    dispatch(actions.clearVentasDelMesPorProvinciaDelVendedor());
    dispatch(actions.clearVentasDelMesPorZonaDelVendedor());
    dispatch(actions.clearVentasDeVendedoresDelMesPorMarca());
    dispatch(actions.clearVentasDeVendedoresDelMesPorRubro());
    dispatch(actions.clearVentasDeVendedoresDelMesPorSubrubro());
    dispatch(actions.clearVentassDeVendedoresDelMesPorProvincia());
    dispatch(actions.clearVentasDeVendedoresDelMesPorZona());
    dispatch(actions.clearVentasDeVendedoresDelMesPorVendedor());
    dispatch(actions.clearVentasPorVendedorPorCategoriaDeClienteDelMes());
    dispatch(actions.clearEvolucionDeVentasMensualDeVendedores());
    dispatch(actions.clearVentasPorVendedorPorMarcaDelMes());
    dispatch(actions.clearVentasPorVendedorPorRubroDelMes());
    dispatch(actions.clearVentasPorVendedorPorSubrubroDelMes());
    dispatch(actions.clearVentasPorVendedorPorProvinciaDelMes());
    dispatch(actions.clearVentasPorVendedorPoZonaDelMes());
    dispatch(actions.clearEvolucionVentasPorCategoriaDeCliente());
  };
};
export const clearComparacionAnalisisVendedores = () => {
  return (dispatch) => {
    dispatch(actions.clearVentasDeVendedoresPorCategoriaDeCliente());
    dispatch(actions.clearVentasDeVendedoresDelMesPorMarca());
    dispatch(actions.clearVentasDeVendedoresDelMesPorRubro());
    dispatch(actions.clearVentasDeVendedoresDelMesPorSubrubro());
    dispatch(actions.clearVentassDeVendedoresDelMesPorProvincia());
    dispatch(actions.clearVentasDeVendedoresDelMesPorZona());
    dispatch(actions.clearVentasDeVendedoresDelMesPorVendedor());
    dispatch(actions.clearVentasPorVendedorPorCategoriaDeClienteDelMes());
    dispatch(actions.clearEvolucionVentasPorCategoriaDeCliente());
    dispatch(actions.clearEvolucionDeVentasMensualDeVendedores());
    dispatch(actions.clearVentasPorVendedorPorMarcaDelMes());
    dispatch(actions.clearVentasPorVendedorPorRubroDelMes());
    dispatch(actions.clearVentasPorVendedorPorSubrubroDelMes());
    dispatch(actions.clearVentasPorVendedorPorProvinciaDelMes());
    dispatch(actions.clearVentasPorVendedorPoZonaDelMes());
    dispatch(actions.clearEvolucionVentasDeVendedoresPorVendedor());
  };
};

export const actualizarIndicadoresFotoDelDia = (indicadoresOcultos, updateUltimaActualizacion) => {
  return (dispatch, getState) => {
    const { ejecucionFotoDelDia } = getState().ejecucionPaginas;
    if (ejecucionFotoDelDia) {
      !indicadoresOcultos.ventasDelMes && dispatch(actions.loadPieVentasComparativo());
      !indicadoresOcultos.ventasDelDia && dispatch(actions.loadVentasDiariasComparativo());
      !indicadoresOcultos.ivaFiscal && dispatch(actions.loadTextoIvaFiscal());
      !indicadoresOcultos.cobranzasDelMes && dispatch(actions.loadTextoCobranzasMensual());
      !indicadoresOcultos.gastosPorCategoria && dispatch(actions.loadPieGastosPorCategoriaSinDataTable());
      !indicadoresOcultos.plazoMedioVencACobrar && dispatch(actions.loadTextoPlazoMedioVencimientos());
      !indicadoresOcultos.plazoMedioVencAPagar && dispatch(actions.loadTextoPlazoMedioAPagar());
      !indicadoresOcultos.promDiasDeCobranzas && dispatch(actions.loadTextoPromDiasCobranzas());
      !indicadoresOcultos.promDiasDePagos && dispatch(actions.loadTextoPromDiasPagos());
      !indicadoresOcultos.chequesEnCartera && dispatch(actions.loadTextoChequesEnCartera());
      !indicadoresOcultos.chequesEnCartera && dispatch(actions.clearLineChequesEnCartera());
      !indicadoresOcultos.chequesRechazados && dispatch(actions.loadTextoChequesRechazados());
      !indicadoresOcultos.chequesRechazados && dispatch(actions.clearLineChequesRechazados());
      !indicadoresOcultos.chequesEmitidos && dispatch(actions.loadTextoChequesPropios());
      !indicadoresOcultos.chequesEmitidos && dispatch(actions.clearLineChequesPropios());
      !indicadoresOcultos.gastosDelMes && dispatch(actions.loadTextoGastosDelMes());
      !indicadoresOcultos.pagosDelMes && dispatch(actions.loadTextoPagosDelMes());
      !indicadoresOcultos.deudaPorCategoriaDeCliente && dispatch(actions.loadDeudaPorCategoriaDeCliente());
      !indicadoresOcultos.deudaPorCategoriaDeProveedor && dispatch(actions.loadDeudaPorCategoriaDeProveedor());
    }
  };
};

export const clearIndicadoresFotoDelDia = () => {
  return (dispatch) => {
    dispatch(actions.clearPieVentasComparativo());
    dispatch(actions.clearVentasDiariasComparativo());
    dispatch(actions.clearTextoIvaFiscal());
    dispatch(actions.clearTextoCobranzaMensual());
    dispatch(actions.clearPieGastosPorCategoriaSinDataTable());
    dispatch(actions.clearPieSaldoAcreedor());
    dispatch(actions.clearTextoPlazoMedioVencimientos());
    dispatch(actions.clearTextoPlazoMedioAPagar());
    dispatch(actions.clearTextoPromDiasCobranzas());
    dispatch(actions.clearTextoPromDiasPagos());
    dispatch(actions.clearTextoChequesEnCartera());
    dispatch(actions.clearLineChequesEnCartera());
    dispatch(actions.clearTextoChequesRechazados());
    dispatch(actions.clearLineChequesRechazados());
    dispatch(actions.clearTextoChequesPropios());
    dispatch(actions.clearLineChequesPropios());
    dispatch(actions.clearTextoGastosDelMes());
    dispatch(actions.clearTextoPagosDelMes());
    dispatch(actions.clearDeudaPorCategoriaDeCliente());
    dispatch(actions.clearDeudaPorCategoriaDeProveedor());
  };
};

export const actualizarIndicadoresGastos = () => {
  return (dispatch, getState) => {
    const { ejecucionGastos } = getState().ejecucionPaginas;
    if (ejecucionGastos) {
      dispatch(actions.loadPieGastosPorCategoria());
      dispatch(actions.loadEvolucionGastosMensual());
      dispatch(actions.unmountGastosPorItemDeCategoriaSelec());
      dispatch(actions.loadEvolucionGastosPorCategoria());
      dispatch(actions.clearEvolucionesDeGastosMensualInteranual());
    }
  };
};

export const clearIndicadoresGastos = () => {
  return (dispatch) => {
    dispatch(actions.clearPieGastosPorCategoria());
    dispatch(actions.clearEvolucionGastosMensual());
    dispatch(actions.unmountGastosPorItemDeCategoriaSelec());
    dispatch(actions.clearEvolucionGastosPorCategoria());
    dispatch(actions.clearEvolucionesDeGastosMensualInteranual());
  };
};

export const actualizarIndicadoresMultiempresa = (indicadoresOcultosMultiempresa) => {
  return (dispatch, getState) => {
    const { ejecucionMultiempresa } = getState().ejecucionPaginas;
    if (ejecucionMultiempresa) {
      !indicadoresOcultosMultiempresa.ivaFiscalMultiempresa && dispatch(actions.loadIvaFiscalPorEmpresa());
      !indicadoresOcultosMultiempresa.chequesEnCarteraMultiempresa &&
        dispatch(actions.loadChequesEnCarteraPorEmpresa());
      !indicadoresOcultosMultiempresa.chequesRechazadosMultiempresa &&
        dispatch(actions.loadChequesRechazadosPorEmpresa());
      !indicadoresOcultosMultiempresa.chequesEmitidosPendientesMultiempresa &&
        dispatch(actions.loadChequesPropiosPorEmpresa());
      !indicadoresOcultosMultiempresa.cobranzasMensualesMultiempresa &&
        dispatch(actions.loadCobranzasMensualesPorEmpresa());
      !indicadoresOcultosMultiempresa.gastosDelMesMultiempresa && dispatch(actions.loadGastosDelMesPorEmpresa());
      !indicadoresOcultosMultiempresa.pagosDelMesMultiempresa && dispatch(actions.loadPagosDelMesPorEmpresa());
      !indicadoresOcultosMultiempresa.saldoAcreedoresMultiempresa && dispatch(actions.loadSaldoAcreedoresPorEmpresa());
      !indicadoresOcultosMultiempresa.saldoDeudoresMultiempresa && dispatch(actions.loadSaldoDeudoresPorEmpresa());
      !indicadoresOcultosMultiempresa.ventasDiariasMultiempresa && dispatch(actions.loadVentasDiariasMultiempresa());
      !indicadoresOcultosMultiempresa.ventasMensualesMultiempresa &&
        dispatch(actions.loadVentasMensualesMultiempresa());
      !indicadoresOcultosMultiempresa.rentabilidadMultiempresa && dispatch(actions.loadRentabilidadPorEmpresa());
    }
  };
};

export const actualizarTiemposReq = (clienteId) => {
  return (dispatch, getState) => {
    const { ejecucionTiemposReq } = getState().ejecucionPaginas;
    if (ejecucionTiemposReq) {
      dispatch(actions.loadTiemposRequest(clienteId));
    }
  };
};

export const clearTiemposReq = () => {
  return (dispatch) => {
    dispatch(actions.clearTiemposReq());
  };
};

export const actualizarRentabilidad = () => {
  return (dispatch, getState) => {
    const { ejecucionResultado } = getState().ejecucionPaginas;
    if (ejecucionResultado) {
      dispatch(actions.loadLineRentabilidadEvolutivo());
    }
  };
};

export const clearRentabilidad = () => {
  return (dispatch) => {
    dispatch(actions.clearLineRentabilidadEvolutivo());
  };
};

export const actualizarAnalisisUtilidad = () => {
  const mes = fechaActualFunc().getMonth() + 1;
  const anio = fechaActualFunc().getFullYear();
  return (dispatch, getState) => {
    const { ejecucionAnalisisDeUtilidad } = getState().ejecucionPaginas;
    if (ejecucionAnalisisDeUtilidad) {
      dispatch(actions.loadEvolucionDeUtilidadMensual());
      dispatch(actions.seleccionDataUtilidadDelMesPor('categoriaDeCliente'));
      dispatch(actions.seleccionFechaUtilidadDelMesPor(mes, anio));
      dispatch(actions.loadUtilidadPorCategoriaDeCliente(mes, anio));
      dispatch(actions.clearUtilidadDelMesPorMarca());
      dispatch(actions.clearUtilidadDelMesPorRubro());
      dispatch(actions.clearUtilidadDelMesPorSubrubro());
      dispatch(actions.clearUtilidadDelMesPorProvincia());
      dispatch(actions.clearUtilidadDelMesPorVendedor());
      dispatch(actions.clearUtilidadDelMesPorZona());
      dispatch(actions.loadEvolucionUtilidadPorCategoriaDeCliente());
      dispatch(actions.clearEvolucionUtilidadPorMarcas());
      dispatch(actions.clearEvolucionUtilidadPorRubros());
      dispatch(actions.clearEvolucionUtilidadPorSubrubro());
      dispatch(actions.clearEvolucionUtilidadPorProvincias());
      dispatch(actions.clearEvolucionUtilidadPorVendedores());
      dispatch(actions.clearEvolucionUtilidadPorZonas());
      dispatch(actions.clearEvolucionesDeUtilidadMensualInteranual());
    }
  };
};

export const clearAnalisisUtilidad = () => {
  return (dispatch) => {
    dispatch(actions.seleccionDataUtilidadDelMesPor('categoriaDeCliente'));
    dispatch(actions.clearEvolucionDeUtilidadMensual());
    dispatch(actions.clearEvolucionDeUtilidadMensual());
    dispatch(actions.clearUtilidadPorCategoriaDeCliente());
    dispatch(actions.clearUtilidadDelMesPorMarca());
    dispatch(actions.clearUtilidadDelMesPorRubro());
    dispatch(actions.clearUtilidadDelMesPorSubrubro());
    dispatch(actions.clearUtilidadDelMesPorProvincia());
    dispatch(actions.clearUtilidadDelMesPorVendedor());
    dispatch(actions.clearUtilidadDelMesPorZona());
    dispatch(actions.clearEvolucionUtilidadPorCategoriaDeCliente());
    dispatch(actions.clearEvolucionUtilidadPorMarcas());
    dispatch(actions.clearEvolucionUtilidadPorRubros());
    dispatch(actions.clearEvolucionUtilidadPorSubrubro());
    dispatch(actions.clearEvolucionUtilidadPorProvincias());
    dispatch(actions.clearEvolucionUtilidadPorVendedores());
    dispatch(actions.clearEvolucionUtilidadPorZonas());
  };
};

export const actualizarNoganetHome = () => {
  const mes = fechaActualFunc().getMonth() + 1;
  const anio = fechaActualFunc().getFullYear();
  return (dispatch, getState) => {
    const { ejecucionPanelNoganet } = getState().ejecucionPaginas;
    if (ejecucionPanelNoganet) {
      dispatch(actions.loadPieVentasComparativo())
      dispatch(actions.loadVentasDiariasComparativo())
      dispatch(actions.cobranzasDelDia())
      dispatch(actions.totalNotasDePedido())

      dispatch(actions.loadTextoIvaFiscal())
      dispatch(actions.chequesDepositadosDelDia())
      dispatch(actions.loadTextoChequesEnCartera())
      dispatch(actions.loadTextoChequesRechazados())

      dispatch(actions.loadDeudaDelMesPorOrdenamiento(2, mes, anio))
    }
  };
};

export const clearNoganetHome = () => {
  return (dispatch) => {
    dispatch(actions.clearPieVentasComparativo());
    dispatch(actions.clearVentasDiariasComparativo());
    dispatch(actions.clearCobranzasDelDia());
    dispatch(actions.clearTotalNotasDePedido());

    dispatch(actions.clearTextoIvaFiscal());
    dispatch(actions.clearChequesDepositadosDelDia());
    dispatch(actions.clearTextoChequesEnCartera());
    dispatch(actions.clearTextoChequesRechazados());

    dispatch(actions.clearDeudaDelMesPorOrdenamiento());
  };
};

export const clearIndicadoresCliente = () => {
  return (dispatch) => {
    dispatch(actions.unmountClienteById());
  };
};

export const actualizarInformes = () => {
  let informeUnoKey = "ventas-informe-uno-pivot-grid";
  let config = localStorage.getItem(informeUnoKey);
  localStorage.removeItem(informeUnoKey);

  return (dispatch) => {
    dispatch(actions.loadAniosFiscales());
    dispatch(actions.updateInformeUnoFiltros(null, null, null, false, null, null));
    dispatch(actions.updateConfiguracionFields(informeUnoKey, config));
  }
}

export const actualizarInformeDos = () => {
  return (dispatch) => {
    dispatch(actions.loadAniosFiscales());
    dispatch(actions.updateInformeDosFiltros(null, null, null, false, null, null));
  }
}

export const clearInformes = () => {
  let informeUnoKey = "ventas-informe-uno-pivot-grid";

  return (dispatch) => {
    dispatch(actions.clearAniosFiscales());
    dispatch(actions.clearInformeUnoData());
    dispatch(actions.clearPivotGridConfiguraciones(informeUnoKey));
    dispatch(actions.clearPivotGridInformaciones(informeUnoKey));
    dispatch(actions.clearBudgetsForecasts());
  };
};

export const clearInformeDos = () => {
  return (dispatch) => {
    dispatch(actions.clearAniosFiscales());
    dispatch(actions.clearInformeDosUnidades());
    dispatch(actions.clearInformeDosBudgetsForecasts());
  };
}

export const actualizarTenactaAnalisisDeVentas = () => {
  return (dispatch, getState) => {
    const { ejecucionPanelTenacta } = getState().ejecucionPaginas;
    const { fechaVentasDelMes } = getState().ventas;

    if (ejecucionPanelTenacta) {
      dispatch(actions.seleccionTipoDeVentasDesagregadas('marca'));

      if (fechaVentasDelMes)
        dispatch(actions.loadVentasDesagregadasDelMesPorMarca(undefined, fechaVentasDelMes.mes, fechaVentasDelMes.anio));

      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
    }
  };
}

export const clearTenactaAnalisisDeVentas = () => {
  return (dispatch) => {
    dispatch(actions.seleccionTipoDeVentasDesagregadas('marca'));
    dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
    dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
    dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
    dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
    dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
    dispatch(actions.clearVentasDesagregadasDelMesPorZona());
    dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
    dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
    dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
    dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
    dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
    dispatch(actions.clearVentasDesagregadasPorCategoriaDeCliente());
  };
}