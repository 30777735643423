import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../../store/actions';
import Table from '../../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClickSobreCategoriaDelGrafico from '../../../../../assets/images/ClickSobreCategoriaDelGrafico.png';

import classes from './TablaComparacionUtilidadPorSeleccion.css';
import TablaComparacionUtilidadDelClientePorArticulo from '../TablaComparacionUtilidadDelClientePorArticulo/TablaComparacionUtilidadDelClientePorArticulo';
import TablaComparacionUtilidadDelArticuloPorCliente from '../TablaComparacionUtilidadDelArticuloPorCliente/TablaComparacionUtilidadDelArticuloPorCliente';

const TablaComparacionUtilidadDeLaSeleccion = (props) => {
  const { fechasComparar = [], dataSelectionId } = props;
  const [tableState, setTableState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });
  const [searchState] = useState('');

  const inCategoriaDeCliente = dataSelectionId === 'categoriaDeCliente';
  const inZona = dataSelectionId === 'zona';
  const inProvincia = dataSelectionId === 'provincia';
  const inVendedor = dataSelectionId === 'vendedor';

  const verPorClientes = inCategoriaDeCliente || inZona || inProvincia || inVendedor;

  const registerType = () => (verPorClientes ? 'Cliente' : 'Artículo');

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      fechasComparar.map((fecha) => {
        const separarFecha = moment(fecha).subtract(1, 'month').format('MM/YYYY').split('/');
        const mes = separarFecha[0];
        const anio = separarFecha[1];
        return props[props.loadDetallesKey](props.categoria.id, mes, anio, (data) => {
          if (tableState.length === 1) {
            armarComparacion(data);
            setLoading(false);
          } else {
            tableState.push(data);
          }
          setTimeout(
            () =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: 'smooth'
              }),
            100
          );
        });
      });
    }, 500);
  }, []);

  const columns = [
    {
      title: registerType(),
      field: 'item',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];

    tableState[0].forEach((utilidadesCompleto) => {
      const verify = arrayAComparar.filter((utilidad) => utilidad.nombre === utilidadesCompleto.nombre);
      if (verify.length === 0) {
        arrayNuevo.push({ ...utilidadesCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((utilidad) => {
      return arrayNuevo.push(utilidad);
    });

    arrayAComparar.forEach((utilidadesOrdenadas) => {
      const verify = tableState[0].filter((utilidad) => utilidad.nombre === utilidadesOrdenadas.nombre);

      if (verify.length === 0) {
        tableState[0].push({ ...utilidadesOrdenadas, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

    setTableState([
      { dateComplete: props.fechasComparar[0], utilidadesDetallada: orderTableState },
      { dateComplete: props.fechasComparar[1], utilidadesDetallada: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];
    /*let totalMonto1 = 0;
    let totalMonto2 = 0;*/

    const filterTableState =
      tableState.length === 2
        ? [
            {
              ...tableState[0],
              utilidadesDetallada: tableState[0].utilidadesDetallada.filter(
                (utilidadDetallada) =>
                  utilidadDetallada.nombre.toLowerCase().includes(searchState) ||
                  utilidadDetallada.id.toLowerCase().includes(searchState)
              )
            },
            {
              ...tableState[1],
              utilidadesDetallada: tableState[1].utilidadesDetallada.filter(
                (utilidadDetallada) =>
                  utilidadDetallada.nombre.toLowerCase().includes(searchState) ||
                  utilidadDetallada.id.toLowerCase().includes(searchState)
              )
            }
          ]
        : [];

    filterTableState.map((data, key) => {
      if (key === 0) {
        return data.utilidadesDetallada.map((utilidadDetallada, index) => {
          var utilidadDetalladaDelIndex = tableState[1].utilidadesDetallada[index];

          const utilidadDelIndex = utilidadDetalladaDelIndex
            ? tableState[1].utilidadesDetallada[index]
            : { mes: 0, anio: 0, id: '0', nombre: 'undefined', monto: 0, porcentajeUtilidadSobreElTotal: 0 };

          /*totalMonto1 += utilidadDetallada.monto;
          totalMonto2 += utilidadDelIndex.monto;*/

          return formatTableData.push({
            itemId: utilidadDetallada.id,
            item: utilidadDetallada.nombre,
            monto1: utilidadDetallada.monto,
            porcParticipacion1: utilidadDetallada.porcentajeUtilidadSobreElTotal,
            monto2: utilidadDelIndex.monto,
            porcParticipacion2: utilidadDelIndex.porcentajeUtilidadSobreElTotal,
            difMontos: calcularDif(utilidadDetallada.monto, utilidadDelIndex.monto),
            porcComparacion: calcularPorc(utilidadDetallada.monto, utilidadDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    /*tableState.length > 0 &&
      formatTableData.push({
        itemId: null,
        item: 'Total',
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });*/

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const compareByAsc = (a, b, key) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
  };

  const compareByDesc = (a, b, key) => {
    if (a[key] < b[key]) return 1;
    if (a[key] > b[key]) return -1;
  };

  const headersBackgroundColor = 'rgb(235, 235, 235)';

  const tableRef = useRef();

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        {/*<TextField
          id="input-with-icon-textfield"
          placeholder="Buscar"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />*/}
        {/*!loading ? (
          <TablaComparacionVentasToExcel
            ventasPor={registerType()}
            comprobantes={data()}
            fechas={[
              moment(fechasComparar[0]).subtract(1, 'month').format('MM/YYYY'),
              moment(fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')
            ]}
          />
        ) : (
          <div></div>
        )*/}
      </div>
      {fechasComparar.length !== 2 ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="" src={ClickSobreCategoriaDelGrafico} width={400} />
        </div>
      ) : (
        <Table
          style={{ paddingLeft: '20px' }}
          columns={columns}
          tableRef={tableRef}
          data={data()}
          noBoxShadow
          loading={loading}
          components={{
            Header: (props) => {
              return (
                <TableHead>
                  {fechasComparar.length === 2 && (
                    <TableRow>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                        align="left"
                      ></TableCell>

                      <TableCell
                        style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                      ></TableCell>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px'
                        }}
                        colSpan={2}
                        align="center"
                      ></TableCell>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px'
                        }}
                        colSpan={2}
                        align="center"
                      ></TableCell>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                        align="center"
                      ></TableCell>
                      <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                      align="left"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('item')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        width: window.screen.width < 1367 ? '265px' : '410px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="left"
                    >
                      {registerType()}
                    </TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('monto1')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcParticipacion1')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('monto2')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcParticipacion2')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('difMontos')}
                      style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={fechasComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcComparacion')}
                      style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>
              );
            }
            /*Row: (props) => {
              return (
                <MTableBodyRow
                  {...props}
                  detailPanel={
                    !props.data.itemId
                      ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                      : props.detailPanel
                  }
                />
              );
            }*/
          }}
          detailPanel={[
            {
              disabled: false,
              tooltip: verPorClientes ? 'Mostrar Comprobantes' : 'Mostrar utilidad por cliente',
              render: (rowData) => {
                return (
                  <div className={classes.TablasComparacionComprobantesContainer}>
                    {verPorClientes ? (
                      <TablaComparacionUtilidadDelClientePorArticulo
                        fechasComparar={fechasComparar}
                        clienteId={rowData.itemId}
                      />
                    ) : (
                      <TablaComparacionUtilidadDelArticuloPorCliente
                        fechasComparar={fechasComparar}
                        articuloId={rowData.itemId}
                      />
                    )}
                  </div>
                );
              }
            }
          ]}
          customOptions={{
            grouping: false,
            draggable: false,
            search: false,
            paging: false,
            maxBodyHeight: 400,
            showTitle: false,
            tableLayout: 'fixed',
            toolbar: false,
            rowStyle: (rowData, config) => {
              return {
                cursor: 'auto',
                backgroundColor: (rowData.item === 'Total' && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
              };
            },
            cellStyle: (cellData) => ({
              fontWeight: cellData === 'Total' && 'bolder'
            })
          }}
          tableKey={"tabla-comparacion-utilidad-seleccion"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDetallesVendedor: (vendedorid, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDelVendedor(mes, anio, vendedorid, success, errorFail)),

    onLoadDetallesProvincia: (provinciaId, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDeLaProvincia(undefined, provinciaId, mes, anio, success, errorFail)),

    onLoadDetallesZona: (zonaId, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDeLaZona(undefined, mes, anio, zonaId, success, errorFail)),

    onLoadDetallesaMarca: (marcaId, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorArticuloDeLaMarca(undefined, marcaId, mes, anio, success, errorFail)),

    onLoadDetallesRubro: (rubroId, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorArticuloDelRubro(undefined, rubroId, mes, anio, success, errorFail)),

    onLoadDetallesSubrubro: (subrubroId, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorArticuloDelSubrubro(undefined, subrubroId, mes, anio, success, errorFail)),

    onLoadDetallesCategoriaDeCliente: (categoriaDeClienteId, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadUtilidadPorClienteDeLaCategoria(undefined, categoriaDeClienteId, mes, anio, success, errorFail)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionUtilidadDeLaSeleccion);
