import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExportToExcelIcon = (props) => {
  const { filename, dataSet, name, tooltipTitle, disabled } = props;

  return (
    <ExcelFile
      filename={filename}
      element={
        <Tooltip title={ tooltipTitle ? tooltipTitle : "Descargar en excel" }>
          <IconButton style={{ marginRight: '10px' }} size="small" disabled={disabled}>
            <img alt="" src={IconConvertToExcel} height={'33px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={dataSet} name={name} />
    </ExcelFile>
  );
}

export default ExportToExcelIcon;