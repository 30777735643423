// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinesInteranualVentas__Container__KKtSA{\r\n    margin-left: 1%;\r\n    margin-right: 1%;\r\n    box-shadow: 0px 3px 6px #00000029;\r\n    box-sizing: border-box;\r\n    background-color: white;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 5px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "LinesInteranualVentas__Container__KKtSA"
};
export default ___CSS_LOADER_EXPORT___;
