import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  ejecucionFotoDelDia: false,
  ejecucionGastos: false,
  ejecucionMultiempresa: false,
  ejecucionResultado: false,
  ejecucionAnalisisDeVenta: false,
  ejecucionAnalisisDeVendedor: false,
  ejecucionAnalisisDeSucursal: false,
  ejecucionAnalisisDeUtilidad: false,
  ejecucionTiemposReq: false,
  ejecucionPanelNoganet: false,
  ejecucionPanelTenacta: false,
  ejecucionInformesTenacta: false,
  ejecucionInformeDosTenacta: false,
  ejecucionInformeTresTenacta: false,
  paginaRender: '/'
};

const resetEjecucionPaginas = (state) => {
  return updateObject(state, {
    ejecucionFotoDelDia: false,
    ejecucionGastos: false,
    ejecucionMultiempresa: false,
    ejecucionResultado: false,
    ejecucionAnalisisDeVenta: false,
    ejecucionAnalisisDeVendedor: false,
    ejecucionAnalisisDeSucursal: false,
    ejecucionAnalisisDeUtilidad: false,
    ejecucionTiemposReq: false,
    ejecucionPanelNoganet: false,
    ejecucionPanelTenacta: false,
    ejecucionInformesTenacta: false,
    ejecucionInformeDosTenacta: false,
    ejecucionInformeTresTenacta: false,
    paginaRender: '/'
  });
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.EJECUTAR_FOTO_DEL_DIA:
      return { ...state, ejecucionFotoDelDia: true };

    case actionTypes.EJECUTAR_GASTOS:
      return { ...state, ejecucionGastos: true };

    case actionTypes.EJECUTAR_MULTIEMPRESA:
      return { ...state, ejecucionMultiempresa: true };

    case actionTypes.EJECUTAR_RESULTADO:
      return { ...state, ejecucionResultado: true };

    case actionTypes.EJECUTAR_ANALISIS_DE_VENTAS:
      return { ...state, ejecucionAnalisisDeVenta: true };

    case actionTypes.EJECUTAR_ANALISIS_DE_VENDEDOR:
      return { ...state, ejecucionAnalisisDeVendedor: true };

    case actionTypes.EJECUTAR_ANALISIS_DE_SUCURSAL:
      return { ...state, ejecucionAnalisisDeSucursal: true };

    case actionTypes.EJECUTAR_ANALISIS_DE_UTILIDAD:
      return { ...state, ejecucionAnalisisDeUtilidad: true };

    case actionTypes.EJECUTAR_TIEMPOS_REQ:
      return { ...state, ejecucionTiemposReq: true };

    case actionTypes.CAMBIO_PAGINA_RENDER:
      return { ...state, paginaRender: action.page };

    case actionTypes.EJECUTAR_NOGANET_HOME:
      return { ...state, ejecucionPanelNoganet: true };

    case actionTypes.EJECUTAR_HOME_TENACTA:
      return { ...state, ejecucionPanelTenacta: true };

    case actionTypes.EJECUTAR_INFORMES_TENACTA:
      return { ...state, ejecucionInformesTenacta: true };

    case actionTypes.EJECUTAR_INFORME_DOS_TENACTA:
      return { ...state, ejecucionInformeDosTenacta: true };

    case actionTypes.EJECUTAR_INFORME_TRES_TENACTA:
      return { ...state, ejecucionInformeTresTenacta: true };

    case actionTypes.RESET_EJECUCION_PAGINAS:
      return resetEjecucionPaginas(state);

    default:
      return state;
  }
};

export default reducer;
