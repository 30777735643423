// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaCobranzasDelDia__infoComprobanteContainer__fVGtp {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    flex-direction: column;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .TablaCobranzasDelDia__infoComprobanteRow__8uVbC {\r\n    display: flex;\r\n  }\r\n  \r\n  .TablaCobranzasDelDia__titleTotales__KOQx- {\r\n    font-size: small;\r\n    font-weight: 500;\r\n    margin-bottom: 10px !important;\r\n    text-align: right;\r\n  }\r\n  \r\n  .TablaCobranzasDelDia__Spinner__0Uvmp {\r\n    position: absolute;\r\n    top: 25%;\r\n    right: 44%;\r\n    z-index: 1500;\r\n  }\r\n  \r\n  .TablaCobranzasDelDia__contenedorTotales__Ozrjh {\r\n    overflow-x: hidden;\r\n    padding: 16px;\r\n    margin-top: -8px;\r\n    margin-bottom: -8px;\r\n    text-align: right;\r\n  }\r\n  \r\n  .TablaCobranzasDelDia__TitleCard__uRyPo {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-content: center;\r\n    background: rgb(251, 175, 64);\r\n    background: linear-gradient(90deg, rgba(251, 175, 64, 1) 0%, rgba(241, 91, 41, 1) 100%);\r\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#fbaf40\",endColorstr=\"#f15b29\",GradientType=1);\r\n    margin: -15px -15px 0px -15px;\r\n    padding-left: 15px;\r\n  }\r\n  \r\n  .TablaCobranzasDelDia__TotalsHeader__nkcrg{\r\n    display: flex;\r\n    align-content: center;\r\n    justify-content: space-between;\r\n    margin: 5px 0px 5px 0px;\r\n  }", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoComprobanteContainer": "TablaCobranzasDelDia__infoComprobanteContainer__fVGtp",
	"infoComprobanteRow": "TablaCobranzasDelDia__infoComprobanteRow__8uVbC",
	"titleTotales": "TablaCobranzasDelDia__titleTotales__KOQx-",
	"Spinner": "TablaCobranzasDelDia__Spinner__0Uvmp",
	"contenedorTotales": "TablaCobranzasDelDia__contenedorTotales__Ozrjh",
	"TitleCard": "TablaCobranzasDelDia__TitleCard__uRyPo",
	"TotalsHeader": "TablaCobranzasDelDia__TotalsHeader__nkcrg"
};
export default ___CSS_LOADER_EXPORT___;
