import React from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../shared/formatNumber";
import { storageMonedaSigno } from "../../../../shared/sessionData";
import PieChart from "../../../Charts/PieChart";

const PiePedidosPendientes = (props) => {
  const { loading, pedidos, total, noBorder, minHeight } = props;
  const chartSinDatos = pedidos.length === 0 || (pedidos.length === 1 && pedidos[0].monto <= 0);

  const labels = () => {
    return pedidos.map((pedido) => pedido.nombre);
  };

  const series = () => {
    return pedidos.map((pedido) => pedido.monto);
  };

  return (
    <PieChart
      title={`Pedidos pendientes`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(total)}`}
      labels={labels()}
      series={series()}
      imgSinDatos={chartSinDatos}
      loading={loading}
      legendPosition={'bottom'}
      chartType="donut"
      legendHorizontalAlign={"left"}
      height={'90%'}
      width={'100%'}
      maxWidthImgSinResult={330}
      noBorder={noBorder}
      minHeight={minHeight}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    pedidos: state.ventas.estadosPedidosPendientes.pedidos,
    loading: state.ventas.estadosPedidosPendientes.loading,
    total: state.ventas.estadosPedidosPendientes.total,
    estaCargado: state.ventas.estadosPedidosPendientes.estaCargado,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PiePedidosPendientes);