import Close from '@material-ui/icons/Close';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { compareByDesc } from '../../../../shared/sorting';
import PieChart from '../../../Charts/PieChart';
import Table from '../../../UI/Table/Table';
import ViewInTable from '../../../../assets/images/ViewInTable.svg';
import { Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import classes from './PieTableGastosPorCategoria.css';
import Spinner from '../../../UI/Spinner/Spinner';
import ExportToExcelIcon from '../../../UI/Icons/ExportToExcelIcon';
import { capitalizeName } from '../../../../shared/utility';

const PieGastosPorCategoria = (props) => {
  const { loading, gastos, title, subTitle, onPorcionClick, headerTitle, initialLimit } = props;
  const { idField, nameField, valueField } = props;
  const isDisabledChart = props.estaOculto;
  const [items, setItems] = useState();
  const [limit, setLimit] = useState(initialLimit);

  const gastosPositivas = useCallback(() => {
    let gastosPos = gastos ? gastos.filter((vnt) => vnt[valueField] >= 0) : [];
    return gastosPos;
  }, [gastos]);

  useEffect(() => {
    if (limit && limit > 0) {
      let gastos = gastosPositivas();
      let ordered = gastos.sort((a, b) => compareByDesc(a, b, valueField));
      let filtered = ordered.slice(0, limit);
      let others = ordered.slice(limit);

      if (others && others.length > 0) {
        let otherItem = {
          mes: others[0].mes,
          anio: others[0].anio,
        };
        otherItem[valueField] = 0;
        otherItem[nameField] = "OTROS";
        otherItem[idField] = "0000";
        others.forEach(element => {
          otherItem[valueField] += element[valueField];
        });
        filtered.splice(0, 0, otherItem);
      }
      setItems(filtered);
    }
  }, [limit, gastos, gastosPositivas]);

  const gastosNegativas = () => {
    let gastosNegativo = gastos ? gastos.filter((vnt) => vnt[valueField] < 0) : [];

    return (
      !isDisabledChart && gastosNegativo &&
      gastosNegativo.map((ventaNegativo) => {
        let item = {};
        item[nameField] = ventaNegativo[nameField];
        item[valueField] = ventaNegativo[valueField];

        return item;
      })
    );
  };

  const tooltip = {
    y: {
      formatter: function (val) {
        return `${storageMonedaSigno()} ${formatNumber(val)}`;
      }
    }
  };

  const getSeries = () => {
    let tempItems = limit && limit > 0 ? items : gastosPositivas();
    let series = tempItems ? tempItems.map((item) => item[valueField]) : [];

    return series;
  }

  const getLabels = () => {
    let tempItems = limit && limit > 0 ? items : gastosPositivas();
    let labels = tempItems ? tempItems.map((item) => item[nameField]) : [];

    return labels;
  }

  const onLimitChange = (value) => {
    let numValue = Number(value);
    setLimit(numValue);
  }

  const getLimit = () => {
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Límite
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={limit}
            onChange={(event) => onLimitChange(event.target.value)}
            type="number"
            size='small'
            InputProps={{
              inputProps: {
                max: gastos && gastos.length,
                min: 0,
                style: { padding: '2px', textAlign: 'center' }
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {limit && limit > 0 ? (
            <IconButton size='small' onClick={() => setLimit(0)}>
              <Close fontSize='small' />
            </IconButton>
          ) : <Fragment></Fragment>}
        </Grid>
      </Grid>
    )
  }

  //#region Change View
  const [viewAsPie, setViewAsPie] = useState(true);

  const handleChangeView = () => {
    setViewAsPie(!viewAsPie);
  }

  const dataLabels = {
    enabled: true,
    formatter: function (val, opts) {
      let item = opts.w.config.labels[opts.seriesIndex];

      if (item && item.toUpperCase() === 'OTROS')
        return `OTROS ${formatNumber(val)}%`;
      else
        return `${formatNumber(val)}%`;
    },
  }

  const renderPie = () => {
    return (
      <PieChart
        disabledCss
        title={title}
        endItems={gastos && gastos.length !== 0 ? getLimit() : undefined}
        subTitle={subTitle}
        series={getSeries()}
        labels={getLabels()}
        valoresNegativos={gastosNegativas()}
        loading={loading}
        imgSinDatos={gastos.length === 0}
        height={props.height}
        disabled={isDisabledChart}
        width={props.width}
        chartType={props.chartType}
        spaceBetweenChartTitle={props.spaceBetweenChartTitle}
        legendHeight={props.legendHeight}
        legendPosition={props.legendPosition}
        tooltip={tooltip}
        onPorcionClick={onPorcionClick}
        onChangeViewClick={handleChangeView}
        dataLabels={dataLabels}
      />
    )
  }

  const renderTable = () => {
    return (
      <TablaMontoUnidad
        title={title}
        subTitle={subTitle}
        gastos={gastos}
        headerTitle={headerTitle}
        onChangeViewClick={handleChangeView}
        height={(props.height - 50)}
        loading={loading}
        filename={props.filename}
        idField={idField}
        nameField={nameField}
        valueField={valueField}
      />
    )
  }

  //#endregion

  return loading ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : viewAsPie ? (
    <Fragment>
      {renderPie()}
    </Fragment>
  ) : (
    <Fragment>
      {renderTable()}
    </Fragment>
  );
};

const TablaMontoUnidad = (props) => {
  const { gastos, headerTitle, loading } = props;
  const { idField, nameField, valueField } = props;

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#c9c9c9'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '12px',
      paddingBottom: '5px',
      paddingTop: '5px',
      width: width,
      color: value < 0 && 'red'
    }
  };

  const columns = () => {
    const arrColumns = [
      {
        title: capitalizeName(headerTitle),
        field: nameField,
        align: 'left',
        cellStyle: (cellData) => cellStyles(cellData, '35%'),
        headerStyle: { ...headerStyle, width: '35%' }
      },
      {
        title: capitalizeName(valueField),
        field: valueField,
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '30%'),
        headerStyle: { ...headerStyle, width: '30%' },
        render: (rowData) => `${storageMonedaSigno()}${formatNumber(rowData[valueField])}`

      },
      {
        title: 'Porc. Participacion',
        field: 'porcParticipacion',
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '30%'),
        headerStyle: { ...headerStyle, width: '30%' },
        render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
      }
    ];

    return arrColumns;
  };

  const data = () => {
    return gastos.map((gasto) => {
      let item = {
        porcParticipacion: gasto.porcentajeDeParticipacion
      };
      item[nameField] = gasto[nameField];
      item[valueField] = gasto[valueField];

      return item;
    });
  }

  //#region Export to Excell
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
    };
  };

  const columnsForExcel = () => {
    const arrColumns = [
      {
        title: headerTitle,
        width: { wpx: 150 },
        align: 'left',
        style: headersStyle
      },
      {
        title: valueField,
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      },
      {
        title: 'Porc. Participacion',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      }
    ];

    return arrColumns;
  }

  const formatData = () => {
    let tableData = [];

    gastos && gastos.map((venta) => {
      const row = [
        {
          value: venta[nameField],
          style: { font: { sz: '10' } }
        },
        {
          value: `${storageMonedaSigno()}${formatNumber(venta[valueField])}`,
          style: cellsStyleVerifNegative(venta[valueField])
        },
        {
          value: `${formatNumber(venta.porcentajeDeParticipacion)}%`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacion)
        }
      ];

      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnsForExcel(),
      data: formatData()
    }
  ];
  //#endregion
  return (
    <div style={{ height: '100%', width: props.width, minHeight: props.minHeight }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginInline: '10px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title}
          </div>
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como torta">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.TitleCard}>
          {props.subTitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subTitle}</p>}
          {gastos && gastos.length > 0 && (<ExportToExcelIcon
            filename={props.filename}
            name={headerTitle}
            dataSet={multiDataSet}
          />)}
        </div>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <div className={classes.TablaMultiempresaContainer}>
            <Table
              style={{ width: '100%', marginBottom: '20px' }}
              columns={columns()}
              data={data()}
              loading={props.loading}
              customOptions={{
                draggable: false,
                search: false,
                paging: false,
                toolbar: false,
                showTitle: false,
                maxBodyHeight: props.height,
                rowStyle: (rowData, index) => ({
                  backgroundColor: isEven(index) ? "#f2f2f2" : "white",
                }),
              }}
              tableKey={"tabla-utilidad-por-cliente-seleccion"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PieGastosPorCategoria;