import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../../shared/formatNumber';
import moment from 'moment';

import * as actions from '../../../../../store/actions/index';

import LineChart from '../../../../Charts/CustomLineChart';
import { storageMonedaSigno } from '../../../../../shared/sessionData';

import classes from './LineComparacionDeUtilidadPorSeleccion.css';
import RangeSelect from '../../../../../assets/images/RangeSelect.svg';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import BoxStepInfo from '../../../../UI/BoxStepInfo/BoxStepInfo';
import BarComparacionPeriodosUtilidad from '../BarComparacionPeriodosUtilidad/BarComparacionPeriodosUtilidad';
import { fechaActualFunc } from '../../../../../shared/fechas';
import FilterListIcon from '@material-ui/icons/FilterList';


const LineComparacionDeUtilidadPorSeleccion = (props) => {
  const { dataSelectionId, periodoSeleccionado } = props;

  const [utilidadesSeleccionadas, setUtilidadesSeleccionadas] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [banderaUtilidadesComparacion] = useState(false);
  const [loadingComparacion, setLoadingComparacion] = useState(false);
  const [utilidadesComparar, setUtilidadesComparar] = useState([]);
  const [formatUtilidadesCompletos, setFormatUtilidadesCompletos] = useState([]);
  const [inSelectRange, setInSelectRange] = useState(false);
  const [totalLocal, setTotalLocal] = useState(0);
  const [periodo, setPeriodo] = useState(periodoSeleccionado);

  const dataOptions = {
    categoriaDeCliente: {
      id: 0,
      stringId: 'categoriaDeCliente',
      description: 'Categorías de cliente',
      utilidadKey: 'utilidadPorCategoria',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorCategoriaDeCliente',
      loadKey: 'onLoadUtilidadDelMesPorCategoriaDeCliente',
      loadDetallesKey: 'onLoadDetallesCategoriaDeCliente'
    },
    vendedor: {
      id: 1,
      stringId: 'vendedor',
      description: 'Vendedores',
      utilidadKey: 'utilidadPorVendedores',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorVendedores',
      loadKey: 'onLoadUtilidadDelMesPorVendedores',
      loadDetallesKey: 'onLoadDetallesVendedor'
    },
    provincia: {
      id: 2,
      stringId: 'provincia',
      description: 'Provincias',
      utilidadKey: 'utilidadPorPrivincias',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorProvincias',
      loadKey: 'onLoadUtilidadDelMesPorProvincias',
      loadDetallesKey: 'onLoadDetallesProvincia'
    },
    zona: {
      id: 3,
      stringId: 'zona',
      description: 'Zonas',
      utilidadKey: 'utilidadPorZonas',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorZonas',
      loadKey: 'onLoadUtilidadDelMesPorZonas',
      loadDetallesKey: 'onLoadDetallesZona'
    },
    marca: {
      id: 4,
      stringId: 'marca',
      description: 'Marcas',
      utilidadKey: 'utilidadPorMarca',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorMarca',
      loadKey: 'onLoadUtilidadDelMesPorMarca',
      loadDetallesKey: 'onLoadDetallesaMarca'
    },
    rubro: {
      id: 5,
      stringId: 'rubro',
      description: 'Rubros',
      utilidadKey: 'utilidadPorRubro',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorRubro',
      loadKey: 'onLoadUtilidadDelMesPorRubro',
      loadDetallesKey: 'onLoadDetallesRubro'
    },
    subrubro: {
      id: 6,
      stringId: 'subrubro',
      description: 'Subrubros',
      utilidadKey: 'utilidadPorSubrubro',
      loadEvolucionKey: 'onLoadEvolucionUtilidadPorSubrubro',
      loadKey: 'onLoadUtilidadDelMesPorSubrubro',
      loadDetallesKey: 'onLoadDetallesSubrubro'
    }
  };

  useEffect(() => {
    formatUtilidadesCompletos.slice(0, formatUtilidadesCompletos.length - 1);
    const utilidadKey = dataOptions[dataSelectionId].utilidadKey;
    const mes = fechaActualFunc().getMonth() + 1;
    const anio = periodoSeleccionado.getFullYear();
    const estaCargado = props[utilidadKey].estaCargado;
    const loadEvolucionKey = props[dataOptions[dataSelectionId].loadEvolucionKey];

    if (props.ejecucionDePagina && (!estaCargado || periodo.getFullYear() !== periodoSeleccionado.getFullYear())) {
      setUtilidadesComparar([]);
      setTotalLocal(0);
      setPeriodo(periodoSeleccionado);
      loadEvolucionKey(mes, anio);
    }
  }, [props.ejecucionDePagina, dataSelectionId, periodo, periodoSeleccionado]);

  useEffect(() => {
    series();
    formatMesesToStrings();
  }, [dataSelectionId, props[dataOptions[dataSelectionId].utilidadKey].utilidades]);

  const series = () => {
    let formatUtilidades = [];
    const utilidades = props[dataOptions[dataSelectionId].utilidadKey].utilidades;
    utilidades.map((venta) => {
      return formatUtilidades.push({
        name: venta.nombre,
        data: []
      });
    });
    return setUtilidadesSeleccionadas(formatUtilidades);
  };

  const formatMesesToStrings = () => {
    const formatFechas = [];
    const fechasProp = props[dataOptions[dataSelectionId].utilidadKey].fechas;
    fechasProp.map((fecha) => {
      return formatFechas.push(`${moment(new Date(fecha.anio, fecha.mes, 1)).subtract(1, 'month').format('MM/YY')}`);
    });
    setFechas(formatFechas);
  };

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const utilidadesActual = utilidadesSeleccionadas;
    if (utilidadesActual[seriesIndex].data.length !== 0) {
      utilidadesActual[seriesIndex].data = [];
    } else {
      const utilidadKey = dataOptions[dataSelectionId].utilidadKey;
      const montos = props[utilidadKey].utilidades[seriesIndex].montos.map((montoMesAnio) => montoMesAnio.monto);
      utilidadesActual[seriesIndex].data = montos;
    }
    setUtilidadesSeleccionadas(utilidadesActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${utilidadesSeleccionadas
        .filter((venta) => venta.data.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((venta) => {
          return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(venta.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${venta.name}: ${storageMonedaSigno()} ${formatNumber(venta.data[dataPointIndex])} 
                      </span>
                    </div>
                `;
        })
        .join('')}
              </div>
      </div>
      `;
  };

  const verifyEqualDates = (date1, date2) => {
    const mesDateAnterior = date1.getMonth();
    const anioDateAnterior = date1.getFullYear();

    const mesDateActual = date2.getMonth();
    const anioDateActual = date2.getFullYear();

    return mesDateAnterior === mesDateActual && anioDateAnterior === anioDateActual;
  };

  const onMarkerClick = (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
    const utilidadKey = dataOptions[dataSelectionId].utilidadKey;
    const loadKey = dataOptions[dataSelectionId].loadKey;
    const mes = props[utilidadKey].fechas[dataPointIndex].mes;
    const anio = props[utilidadKey].fechas[dataPointIndex].anio;
    const dateComplete = new Date(anio, mes, 1);
    let tempUtilidadesComparar = utilidadesComparar;

    if (tempUtilidadesComparar.length === 2) {
      tempUtilidadesComparar = [];
      setUtilidadesComparar([]);
    }

    if (tempUtilidadesComparar.length < 2) {
      if (tempUtilidadesComparar.length === 1) {
        if (verifyEqualDates(tempUtilidadesComparar[0].dateComplete, dateComplete)) {
          return;
        }
      }

      setLoadingComparacion(true);
      props[loadKey](mes, anio, (utilidadesCompleto) => {
        if (tempUtilidadesComparar.length === 1) {
          var arrayNuevo = [];
          formatUtilidadesCompletos[0].forEach((utilidadCompleto) => {
            const verify = utilidadesCompleto.filter((utilidad) => utilidad.id === utilidadCompleto.id);
            if (verify.length === 0) {
              arrayNuevo.push({ ...utilidadCompleto, monto: 0, porcentajeDeParticipacion: 0 });
            }
          });

          utilidadesCompleto.map((utilidad) => {
            return arrayNuevo.push(utilidad);
          });
          utilidadesCompleto.forEach((utilidadOrdenada) => {
            const verify = formatUtilidadesCompletos[0].filter((utilidad) => utilidad.id === utilidadOrdenada.id);

            if (verify.length === 0) {
              formatUtilidadesCompletos[0].push({ ...utilidadOrdenada, monto: 0, porcentajeDeParticipacion: 0 });
            }
          });

          var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
          var orderFormatUtilidadesCompletos = formatUtilidadesCompletos[0].sort((a, b) =>
            a.nombre.localeCompare(b.nombre)
          );

          setUtilidadesComparar([
            { dateComplete: utilidadesComparar[0].dateComplete, utilidades: orderFormatUtilidadesCompletos },
            { dateComplete: dateComplete, utilidades: orderArrayNuevo }
          ]);
        } else {
          formatUtilidadesCompletos.push(utilidadesCompleto);
          utilidadesComparar.push({ dateComplete: dateComplete, utilidades: utilidadesCompleto });
        }

        setTimeout(() => setLoadingComparacion(false), 1000);
        setTimeout(
          () =>
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: 'smooth'
            }),
          1050
        );
      });
    }
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onLimpiarComparacion = () => {
    setUtilidadesComparar([]);
    setFormatUtilidadesCompletos([]);
  };

  const verifyEmptyUtilidadesSeleccioandos = utilidadesSeleccionadas.filter(
    (ventSelect) => ventSelect.data.length !== 0
  );

  const handleChangeSelectRange = (e) => {
    e.preventDefault();
    setInSelectRange(!inSelectRange);
  };

  const middleControls = () => {
    return (
      <Tooltip
        title={
          verifyEmptyUtilidadesSeleccioandos.length > 0
            ? !inSelectRange
              ? 'Al activar esta función, podrá seleccionar 2 períodos para compararlos.'
              : 'Haga click en dos períodos diferentes para poder compararlos. (Seleccione 2 puntos diferentes en el gáfico  y se mostrara la comparación en el gráfico de más abajo)'
            : 'Seleccione por lo menos una categoría para activar la selección de períodos'
        }
      >
        <Typography style={{ marginBottom: '1px', marginTop: '3%', marginRight: '1%' }}>
          {!inSelectRange ? `Active la selección de períodos → ` : `Seleccione 2 períodos para comparar `}
          {
            <IconButton
              disabled={verifyEmptyUtilidadesSeleccioandos.length === 0}
              style={{
                backgroundColor: inSelectRange && verifyEmptyUtilidadesSeleccioandos.length !== 0 && 'rgb(255 208 159)'
              }}
              size="small"
              onClick={(e) => handleChangeSelectRange(e)}
            >
              <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            </IconButton>
          }
        </Typography>
      </Tooltip>
    );
  };

  const titles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12} container spacing={1} className={classes.Header}>

          <Grid item xs={8} lg={5} className={classes.Title}>
            <Typography className={classes.TitleText}>
              Utilidades mensuales por {dataOptions[dataSelectionId].description.toLowerCase()}
            </Typography>
          </Grid>

          <Grid item xs={11} lg={4} className={classes.SpecialTool}style={{ textAlign: 'center' }}>
            {middleControls()}
          </Grid>

          <Grid item xs={1} lg={3} className={classes.Filters}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={12} className={classes.Title}>
            <Typography className={classes.SubtitleText} >
              (Haga clic sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.LineEvolucionUtilidadesMensualContainer}>
      <BoxStepInfo
        texto={
          '1° Paso - Haga click sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información.'
        }
      />
      <LineChart
        title={titles()}
        series={utilidadesSeleccionadas}
        subTitle={<Typography className={classes.Subtitle}>
          Total: {storageMonedaSigno()}{formatNumber(totalLocal)}
        </Typography>}
        seriesBandera={seriesBandera}
        loading={props[dataOptions[dataSelectionId].utilidadKey].loading}
        chartTextAlign={'left'}
        eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
        eventsMarkerClick={(event, chartContext, { seriesIndex, dataPointIndex, config }) =>
          inSelectRange && onMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config })
        }
        middleItems={middleControls()}
        legendOnItemClick={false}
        chartHeight={'450px'}
        width={'100%'}
        chartWidth={'99%'}
        chartDropShadow={{
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }}
        toolbarShow={true}
        tooltipEnabled
        customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
          armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
        }
        zoomType={'xy'}
        dataLabelsEnabled={window.screen.width > 500}
        formatDataLabels
        strokeCurve={'smooth'}
        strokeWidth={5}
        fillType={'solid'}
        gridBorderColor={'#e7e7e7'}
        gridRow={{
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }}
        markersSize={4}
        xaxisCategories={fechas}
        xaxisType="category"
        yaxisLabels={{
          show: true,
          formatter: (value) => {
            return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
          },
          align: 'left',
          offsetX: -16
        }}
        legendFontSize={'11px'}
        legendShowForSingleSeries={true}
        legendShowForNullSeries={true}
        legendShowForZeroSeries={true}
        disableLegendOnItemClickToggle
        disableLengendOnItemHover
        legendPosition={'right'}
        legendHorizontalAlign={'left'}
        legendOffsetY={10}
        onFiltersClick={() => props.onShowFiltersModal()}
      />
      <div style={{ height: '10px' }} />
      <BoxStepInfo
        texto={
          <div>
            {`2° Paso - Seleccione dos períodos en el gráfico "Utilidades mensuales por ${dataOptions[
              dataSelectionId
            ].description.toLowerCase()}" utilizando la herramienta `}
            <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            {` para visualizar la información`}
          </div>
        }
      />
      <BarComparacionPeriodosUtilidad
        inComparacion
        utilidadesDe={dataOptions[dataSelectionId].description}
        banderaComparacion={banderaUtilidadesComparacion}
        loading={loadingComparacion}
        utilidadesComparar={utilidadesComparar}
        onLimpiarComparacionClick={() => onLimpiarComparacion()}
        loadDetallesKey={dataOptions[dataSelectionId].loadDetallesKey}
        dataSelectionId={dataOptions[dataSelectionId].stringId}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectionId: state.ventas.dataUtilidadDelMesPorSelected,
    periodoSeleccionado: state.ventas.evolucionUtilidadesMensualInteranual.periodoSeleccionado,

    //#region ventas por categoria de cliente
    utilidadPorCategoria: state.ventas.evolucionUtilidadPorCategoriaDeCliente,
    //#endregion

    //#region ventas por Vendedores
    utilidadPorVendedores: state.ventas.evolucionUtilidadPorVendedores,
    //#endregion

    //#region ventas por Provincias
    utilidadPorPrivincias: state.ventas.evolucionUtilidadPorProvincias,
    //#endregion

    //#region ventas por Zonas
    utilidadPorZonas: state.ventas.evolucionUtilidadPorZonas,
    //#endregion

    //#region ventas por Marca
    utilidadPorMarca: state.ventas.evolucionUtilidadPorMarca,
    //#endregion

    //#region ventas por Rubro
    utilidadPorRubro: state.ventas.evolucionUtilidadPorRubro,
    //#endregion

    //#region ventas por Subrubro
    utilidadPorSubrubro: state.ventas.evolucionUtilidadPorSubrubro
    //#endregion
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //#region utilidad por categoria de cliente
    onLoadEvolucionUtilidadPorCategoriaDeCliente: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorCategoriaDeCliente(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorCategoriaDeCliente: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorCategoriaDeCliente(mes, anio, undefined, success, errorFail)),
    //#endregion

    //#region utilidad por vendedores
    onLoadEvolucionUtilidadPorVendedores: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorVendedores(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorVendedores: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelMesPorVendedor(mes, anio, success, errorFail)),
    //#endregion

    //#region utilidad por provincias
    onLoadEvolucionUtilidadPorProvincias: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorProvincias(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorProvincias: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelMesPorProvincia(mes, anio, success, errorFail)),
    //#endregion

    //#region utilidad por zonas
    onLoadEvolucionUtilidadPorZonas: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorZonas(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorZonas: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelMesPorZona(mes, anio, success, errorFail)),
    //#endregion

    //#region utilidad por marca
    onLoadEvolucionUtilidadPorMarca: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorMarcas(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorMarca: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelMesPorMarca(mes, anio, success, errorFail)),
    //#endregion

    //#region utilidad por rubro
    onLoadEvolucionUtilidadPorRubro: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorRubros(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorRubro: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelMesPorRubro(mes, anio, success, errorFail)),
    //#endregion

    //#region utilidad por subrubro
    onLoadEvolucionUtilidadPorSubrubro: (mes, anio, success) =>
      dispatch(actions.loadEvolucionUtilidadPorSubrubro(mes, anio, undefined, success)),
    onLoadUtilidadDelMesPorSubrubro: (mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelMesPorSubrubro(mes, anio, success, errorFail)),
    //#endregion

    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineComparacionDeUtilidadPorSeleccion);
