// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BarVentasDeVendedoresPorSeleccion__TooltipContainer__aDyqT {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__Title__PToxQ {\r\n  align-self: center;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__Divider__L4kvq {\r\n  height: 1px;\r\n  background-color: black;\r\n  opacity: 0.5;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__Body__gPXfb {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__TextoContainer__2XbsA {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__Circle__v1FlJ {\r\n  height: 12px;\r\n  width: 12px;\r\n  border-radius: 2px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__Texto__Fwr2Y {\r\n  display: flex;\r\n  width: 105%;\r\n  justify-content: space-between;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__TitleDialogTexto__XtyHc {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.BarVentasDeVendedoresPorSeleccion__TitleCard__qcXkH {\r\n  text-align: right;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipContainer": "BarVentasDeVendedoresPorSeleccion__TooltipContainer__aDyqT",
	"Title": "BarVentasDeVendedoresPorSeleccion__Title__PToxQ",
	"Divider": "BarVentasDeVendedoresPorSeleccion__Divider__L4kvq",
	"Body": "BarVentasDeVendedoresPorSeleccion__Body__gPXfb",
	"TextoContainer": "BarVentasDeVendedoresPorSeleccion__TextoContainer__2XbsA",
	"Circle": "BarVentasDeVendedoresPorSeleccion__Circle__v1FlJ",
	"Texto": "BarVentasDeVendedoresPorSeleccion__Texto__Fwr2Y",
	"TitleDialogTexto": "BarVentasDeVendedoresPorSeleccion__TitleDialogTexto__XtyHc",
	"TitleCard": "BarVentasDeVendedoresPorSeleccion__TitleCard__qcXkH"
};
export default ___CSS_LOADER_EXPORT___;
