import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import TablaCubo from '../../../UI/TablaCuboWrapper/TablaCubo';

const TablaCuboVentasSucursales = (props) => {
  const { fecha } = props;

  useEffect(() => {
    if (!props.estaCargado && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  const onVentasDelMesAnioClick = (mes, anio, index) => {
    props.onChangeFechaVentasDelMes(mes, anio);
    props.onChangeOptionSelected();
    props.clearIndicadores();
    props.onLoadVentasPorCategoriasDeCliente(mes, anio);
  };

  return (
    <TablaCubo
      addMonth
      title={'Ventas desglosadas por mes'}
      titleMontos={'Ventas'}
      datas={props.ventasDataTable}
      fecha={new Date(fecha.anio, fecha.mes - 1, 1)}
      loading={props.loading}
      total={props.totalVentas}
      onSelectMesAnioClick={(mes, anio) => onVentasDelMesAnioClick(mes, anio)}
      onShowFiltersModal={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventasDataTable: state.sucursales.evolucionVentasMensualDeSucursales.ventasDataTable,
    loading: state.sucursales.evolucionVentasMensualDeSucursales.loading,
    fecha: state.sucursales.fechaVentasDelMesAnioDeSucursales,
    totalVentas: state.sucursales.evolucionVentasMensualDeSucursales.totalVentas,
    estaCargado: state.sucursales.evolucionVentasMensualDeSucursales.estaCargado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas desglosadas por mes', ['venta'])),

    onLoadChart: () => dispatch(actions.loadEvolucionDeVentasMensualDeSucursales()),

    onChangeFechaVentasDelMes: (mes, anio) =>
      dispatch(actions.seleccionFechaVentasDelMesAnioDeSucursalesPor(mes, anio)),

    onChangeOptionSelected: () => dispatch(actions.seleccionDataVentasDeSucursalesPor('categoriaDeCliente')),

    onLoadVentasPorCategoriasDeCliente: (mes, anio) => {
      dispatch(actions.loadVentasDeSucursalesPorCategoriaDeCliente(mes, anio));
      dispatch(actions.loadVentasPorSucursalPorCategoriaDeClienteDelMes(mes, anio));
    },

    clearIndicadores: () => {
      dispatch(actions.clearVentasDeSucursalesDelMesPorMarca());
      dispatch(actions.clearVentasDeSucursalesDelMesPorRubro());
      dispatch(actions.clearVentasDeSucursalesDelMesPorSubrubro());
      dispatch(actions.clearVentasDeSucursalesDelMesPorVendedor());
      dispatch(actions.clearVentassDeSucursalesDelMesPorProvincia());
      dispatch(actions.clearVentasDeSucursalesDelMesPorZona());

      dispatch(actions.clearVentasPorSucursalPorMarcaDelMes());
      dispatch(actions.clearVentasPorSucursalPorRubroDelMes());
      dispatch(actions.clearVentasPorSucursalPorSubrubroDelMes());
      dispatch(actions.clearVentasPorSucursalPorVendedorDelMes());
      dispatch(actions.clearVentasPorSucursalPorProvinciaDelMes());
      dispatch(actions.clearVentasPorSucursalPoZonaDelMes());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaCuboVentasSucursales);
