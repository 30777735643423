import React, { useCallback } from 'react';
import PivotGrid, { FieldChooser, FieldPanel, StateStoring, Export, HeaderFilter, Scrolling }
  from 'devextreme-react/pivot-grid';
import { Grid } from '@material-ui/core';
import ExportPivotGridToExcelIcon from '../Icons/ExportPivotGridToExcelIcon';
import FilterIcon from '../../Icons/FilterIcon';
import esMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import CambiarVistaIcon from '../Icons/CambiarVistaIcon';
import ExportToPdfIcon from '../../Icons/ExportToPdfIcon';
import CustomSwitch from '../../CustomSwitch/CustomSwitch';
import { compareByAsc } from '../../../../shared/sorting';
import SubtotalSelector from '../SubtotalSelector/SubtotalSelector';

const TablePivotGrid = (props) => {
  const { storageKey, height, width, minHeight, onFiltersClick,
    onCellClick, exportToExcel, sheetName, fileName, pivotGridRef,
    exportRef, exportToPdf, exportPdfRef, dataSource, configFields,
    onCellPrepared, onViewChange
  } = props;

  const { showColumnTotals, showColumnGrandTotals, showRowTotals, showRowGrandTotals,
    selectedTotalRows, onSelectedTotalRowsChange
  } = props;

  loadMessages(esMessages);
  locale('es');

  const setSummaryType = (args, sourceField) => {
    dataSource.field(sourceField.index, {
      summaryType: args.itemData.value
    });

    dataSource.load();
  };

  const onContextMenuPreparing = (e) => {
    const sourceField = e.field;

    if (sourceField) {
      if (!sourceField.groupName || sourceField.groupIndex === 0) {
        e.items.push({
          text: 'Ocultar campo',
          onItemClick() {
            let fieldIndex;
            if (sourceField.groupName) {
              fieldIndex = dataSource
                .getAreaFields(sourceField.area, true)[sourceField.areaIndex]
                .index;
            } else {
              fieldIndex = sourceField.index;
            }

            dataSource.field(fieldIndex, {
              area: null,
            });
            dataSource.load();
          },
        });
      }

      if (sourceField.dataType === 'number') {
        const menuItems = [];

        e.items.push({ text: 'Tipo de cálculo', items: menuItems });
        ['Sum', 'Avg', 'Min', 'Max'].forEach((summaryType) => {
          const summaryTypeValue = summaryType.toLowerCase();

          menuItems.push({
            text: summaryType,
            value: summaryType.toLowerCase(),
            onItemClick(args) {
              setSummaryType(args, sourceField);
            },
            selected: e.field.summaryType === summaryTypeValue,
          });
        });
      }
    }
  };

  //#region Mostrart Total por row
  const getSelectItems = useCallback(() => {
    if (props.totalRowsOptions && props.totalRowsOptions.length > 0) {
      const items = props.totalRowsOptions
        .sort((a, b) => compareByAsc(a, b, 'dataField'))
        .map((item) => {
          return {
            id: item.dataField,
            nombre: item.caption ? item.caption.replace('- ', '') : item.dataField,
            index: item.areaIndex
          }
        });
      return items;
    }

    return [];
  }, [props.totalRowsOptions]);
  //#endregion

  return (
    <Grid container style={{ minHeight: minHeight, alignContent: 'flex-start' }}>
      <Grid className="iconsPivotGrid" item xs={12}
        container justifyContent="space-between" alignItems="center"
        style={{ maxWidth: '95vw', maxHeight: '100px' }}
      >
        <Grid item xs={12} md={9} lg={6} container spacing={1}>
          <Grid item xs={4}>
            <CambiarVistaIcon
              onChange={onViewChange}
              config={configFields}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSwitch
              value={props.rowsColumnsFixed}
              title='Fijar filas y columnas'
              onChange={() => props.onFixRowsColumns()}
              firstLabel='No'
              secondLabel='Si'
            />
          </Grid>
          {onSelectedTotalRowsChange && (
            <Grid item xs={4}>
              <SubtotalSelector
                selectedTotalRows={selectedTotalRows}
                selectItems={getSelectItems()}
                onSelectedTotalRowsChange={onSelectedTotalRowsChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={1} container justifyContent='flex-end' >
          {onFiltersClick && (
            <Grid item xs={2} md={4} style={{ textAlign: 'end' }}>
              <FilterIcon onClick={() => onFiltersClick()} />
            </Grid>
          )}
          {exportToPdf && (
            <Grid item xs={2} md={4} style={{ textAlign: 'end' }}>
              <ExportToPdfIcon
                element={exportPdfRef && exportPdfRef.current}
                fileName={fileName}
              />
            </Grid>
          )}
          {exportToExcel && (
            <Grid item xs={2} md={4} style={{ textAlign: 'end' }}>
              <ExportPivotGridToExcelIcon
                pivotGridRef={exportRef}
                sheetName={sheetName}
                fileName={fileName}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PivotGrid
          ref={pivotGridRef}
          height={height}
          width={width}
          dataSource={dataSource}
          retrieveFields={false}
          allowSortingBySummary={true}
          allowSorting={true}
          allowFiltering={true}
          allowExpandAll={true}
          showBorders={true}
          showColumnTotals={showColumnTotals}
          showColumnGrandTotals={showColumnGrandTotals}
          showRowTotals={showRowTotals}
          showRowGrandTotals={showRowGrandTotals}
          wordWrapEnabled={true}
          onContextMenuPreparing={(e) => onContextMenuPreparing(e)}
          onCellClick={(e) => {
            if (onCellClick) {
              onCellClick(e);
            }
          }}
          onCellPrepared={onCellPrepared}
          onContentReady={(e) => props.onContentReady(e)}
          hideEmptySummaryCells={true}          
          loadPanel={{
            enabled: true,
            height: 150,
            width: 300,
            shading: true,
            text: "Cargando..."
          }}
        >
          <StateStoring enabled={true} type="localStorage" storageKey={storageKey} />
          <FieldPanel
            visible={configFields && configFields.visible}
            showRowFields={configFields && configFields.showRows}
            showFilterFields={configFields && configFields.showFilter}
            showDataFields={configFields && configFields.showData}
            showColumnFields={false}
          />
          <FieldChooser
            enabled={true}
            allowSearch={true}
            applyChangesMode='onDemand'
            layout={1}
          />
          <Export enabled={false} />
          <HeaderFilter
            allowSearch={true}
            showRelevantValues={true}
            width={300}
            height={400}
          />          
          <Scrolling mode={props.scrollingMode ? props.scrollingMode : "standard"} />
        </PivotGrid>
      </Grid>
    </Grid>
  )
}

export default TablePivotGrid;