import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import moment from 'moment';

import LineRentabilidadEvolutivo from '../../components/Indicadores/Integradores/LineRentabilidadEvolutivo/LineRentabilidadEvolutivo';
import TablaRentabilidadEvolutivo from '../../components/Indicadores/Integradores/TablaRentabilidadEvolutivo/TablaRentabilidadEvolutivo';
import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import { resultadoSinDecimales, storageMultiempresa } from '../../shared/sessionData';
import classes from './Rentabilidad.css';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import { Grid } from '@material-ui/core';
import AnioSelector from '../../components/UI/AnioSelector/AnioSelector';
import { fechaActualFunc } from '../../shared/fechas';
import TablaRentabilidadPorSucursales from '../../components/Indicadores/Integradores/TablaRentabilidadPorSucursales/TablaRentabilidadPorSucursales';

const Rentabilidad = (props) => {
  sessionStorage.setItem('seccionNombre', 'Resultado');
  const { sucursales, seleccionFechaPeriodoRentabilidad, periodoRentabilidadSeleccionado, onReloadLoadChart } = props;
  const [periodoSeleccionado, setPeriodoSeleccionado] = useState();
  const isMultiemp = storageMultiempresa() === 'true';

  const getPeriodoSeleccionado = () => {
    if (periodoRentabilidadSeleccionado) {
      let dateString = `${fechaActualFunc().getMonth() + 1}-01-${periodoRentabilidadSeleccionado.anio}`;
      let date = moment(dateString, "MM-DD-YYYY");
      return date;
    }

    return periodoSeleccionado;
  }

  const onSetPeriodoSeleccionado = (periodo) => {
    setPeriodoSeleccionado(periodo);
    seleccionFechaPeriodoRentabilidad(fechaActualFunc().getMonth() + 1, periodo._d.getFullYear());
    onReloadLoadChart(fechaActualFunc().getMonth() + 1, periodo._d.getFullYear());
  }

  const tableWidth = window.innerWidth < 1350 ? '92.5vw' : window.innerWidth < 1900 ? '93.2vw' : '95.5vw';

  return (
    <div className={classes.RentabilidadContainer}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: !isMultiemp && '10px'
        }}
      >
        {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
        <AnioSelector
          periodoSeleccionado={getPeriodoSeleccionado()}
          setPeriodoSeleccionado={(periodo) => onSetPeriodoSeleccionado(periodo)}
        />
      </div>
      {!props.ejecucionResultado ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <Grid container spacing={1}>
          <Grid item className={classes.lineRentabilidadEvolutivo}>
            <LineRentabilidadEvolutivo ejecucionDePagina={props.ejecucionResultado} />
          </Grid>
          <Grid item className={classes.tableRentabilidadEvolutivo}
            style={{ width: tableWidth }}>
            <TablaRentabilidadEvolutivo
              ejecucionDePagina={props.ejecucionResultado}
              withoutDecimals={resultadoSinDecimales()}
            />
          </Grid>
          {sucursales && sucursales.length > 0 &&
            <Grid item className={classes.tableRentabilidadEvolutivo}
              style={{ width: tableWidth }}>
              <TablaRentabilidadPorSucursales 
                ejecucionDePagina={props.ejecucionResultado}
                withoutDecimals={resultadoSinDecimales()}
              />
            </Grid>
          }
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.filtros.sucursales.sucursales,
    periodoRentabilidadSeleccionado: state.integradores.fechaPeriodoRentabilidad,
    ejecucionResultado: state.ejecucionPaginas.ejecucionResultado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarResultado: () => dispatch(actions.ejecutarResultado()),
    seleccionFechaPeriodoRentabilidad: (mes, anio) =>
      dispatch(actions.seleccionFechaPeriodoRentabilidad(mes, anio)),
    onReloadLoadChart: (mes, anio) => {
      dispatch(actions.clearLineRentabilidadEvolutivo());
      dispatch(actions.loadLineRentabilidadEvolutivo(mes, anio));
    },
    updateUltimaActualizacion: () =>
      dispatch(actions.updateUltimaActualizacionRentabilidad(moment(Date.now()).format('DD/MM/YYYY HH:mm')))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rentabilidad);
