import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import { storageEmpresaId, storageMonedaId, storageToken } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';

export const loadLineRentabilidadEvolutivo = (mes, anio, vendedor) => {
  return (dispatch) => {
    dispatch(loadLineRentabilidadEvolutivoStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/Integradores/EvolucionRentabilidad?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.rentabilidadEvolutivo
        }`,

        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let columns = [];
        let data = { ...response.data };

        if (response.data && response.data.gruposGastos) {
          data['utilidades'] = response.data.utilidad;
          data['ganancias'] = response.data.ganancia;

          if (response.data.gruposGastos) {
            response.data.gruposGastos.forEach((grupo) => {
              var column = {
                gruposGastosId: grupo.gruposGastosId,
                nombre: grupo.nombre,
                field: grupo.field
              };
              columns.push(column);
              data[grupo.field] = grupo.gastos;
            });
          }
        }

        dispatch(loadLineRentabilidadEvolutivoSuccess(data, columns));
      })
      .catch((error) => {
        dispatch(loadLineRentabilidadEvolutivoFail(error));
      });
  };
};

export const clearLineRentabilidadEvolutivo = () => {
  return {
    type: actionTypes.CLEAR_LINE_RENTABILIDAD_EVOLUTIVO
  };
};

export const seleccionFechaPeriodoRentabilidad = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_PERIODO_RENTABILIDAD,
    mes,
    anio
  };
};

const loadLineRentabilidadEvolutivoStart = () => {
  return {
    type: actionTypes.LINE_RENTABILIDAD_EVOLUTIVO_START
  };
};

const loadLineRentabilidadEvolutivoSuccess = (data, columns) => {
  return {
    type: actionTypes.LINE_RENTABILIDAD_EVOLUTIVO_SUCCESS,
    data,
    columns
  };
};

const loadLineRentabilidadEvolutivoFail = (error) => {
  return {
    type: actionTypes.LINE_RENTABILIDAD_EVOLUTIVO_FAIL,
    error
  };
};

export const loadTextoSaldoEfectivo = () => {
  return (dispatch) => {
    dispatch(loadTextoSaldoEfectivoStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get('/Integradores/SaldoEfectivo', { headers: { Authorization: authStr } })
      .then((response) => {
        dispatch(
          loadTextoSaldoEfectivoSuccess(response.data.map((estados) => estados.value).reduce((a, b) => a + b, 0))
        );
      })
      .catch((error) => {
        dispatch(loadTextoSaldoEfectivoFail(error));
      });
  };
};

const loadTextoSaldoEfectivoStart = () => {
  return {
    type: actionTypes.TEXTO_SALDO_EFECTIVO_START
  };
};

const loadTextoSaldoEfectivoSuccess = (monto) => {
  return {
    type: actionTypes.TEXTO_SALDO_EFECTIVO_SUCCESS,
    monto: monto
  };
};

const loadTextoSaldoEfectivoFail = (error) => {
  return {
    type: actionTypes.TEXTO_SALDO_EFECTIVO_FAIL,
    error: error
  };
};

//#region Rentabilidad por sucursales

export const loadRentabilidadEvolutivoPorSucursales = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadRentabilidadEvolutivoPorSucursalesStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/Integradores/EvolucionRentabilidadPorSucursales?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.rentabilidadEvolutivo
        }`,

        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let columns = [];
        if (response.data && response.data[0] && response.data[0].gruposGastos) {
          response.data[0].gruposGastos.forEach((grupo) => {
            var column = {
              gruposGastosId: grupo.gruposGastosId,
              nombre: grupo.nombre,
              field: grupo.field
            };
            columns.push(column);
          });
        }

        dispatch(loadRentabilidadEvolutivoPorSucursalesSuccess(response.data, columns));
      })
      .catch((error) => {
        dispatch(loadRentabilidadEvolutivoPorSucursalesFail(error));
      });
  };
};

export const clearRentabilidadEvolutivoPorSucursales = () => {
  return {
    type: actionTypes.CLEAR_RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES
  };
};

export const seleccionFiltroRentabilidadPorSucursales = (mes, anio, seleccion) => {
  return {
    type: actionTypes.SELECCION_FILTRO_RENTABILIDAD_POR_SUCURSALES,
    mes,
    anio,
    seleccion
  };
};

const loadRentabilidadEvolutivoPorSucursalesStart = () => {
  return {
    type: actionTypes.RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_START
  };
};

const loadRentabilidadEvolutivoPorSucursalesSuccess = (data, columns) => {
  return {
    type: actionTypes.RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_SUCCESS,
    data,
    columns
  };
};

const loadRentabilidadEvolutivoPorSucursalesFail = (error) => {
  return {
    type: actionTypes.RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_FAIL,
    error
  };
};

//#endregion