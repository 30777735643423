import React from 'react';
import { connect } from 'react-redux';
import PieChart from '../../../Charts/PieChart';
import * as actions from '../../../../store/actions/index';

const PieVentasDiariasPorEmpresa = (props) => {

  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <PieChart
      title={props.title}
      subTitle={props.subtitle}
      series={props.ventas.map((venta) => venta.total)}
      labels={props.ventas.map((venta) => venta.nombreEmpresa)}
      ids={props.ventas.map((venta) => venta.id)}
      imgSinDatos={props.totalVentas <= 0}
      loading={props.loading}
      chartType={'pie'}
      height={props.height}
      width={'100%'}
      disabled={props.estaOculto}
      onPorcionClick={handlePorcionClick}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.multiempresa.ventasDiariasPorEmpresa.ventas,
    loading: state.multiempresa.ventasDiariasPorEmpresa.loading,
    estaCargado: state.multiempresa.ventasDiariasPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDiariasMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadVentasDiariasMultiempresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieVentasDiariasPorEmpresa);
