import React from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Table from '../../../UI/Table/Table';
import TablaIndicadoresMultiempresaToExcel from './TablaIndicadoresMultiempresaToExcel';
import classes from './TablaIndicadoresMultiempresa.css';

const TablaIndicadoresMultiempresa = (props) => {
  const { empresas, indicadoresOcultos } = props;

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fafafa'
  };
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };

 
  const columns = () => {
    const arrColumns = [
      {
        title: 'Indicador',
        field: 'indicador',
        align: 'left',
        cellStyle: cellStyle,
        headerStyle: headerStyle
      }
    ];

    empresas.map((empresa) => {
      return arrColumns.push({
        title: empresa.nombre,
        field: empresa.nombre,
        align: 'right',
        cellStyle: cellStyle,
        headerStyle: headerStyle,
        render: (rowData) => {
          return `${storageMonedaSigno()} ${
            formatNumber(rowData[empresa.nombre]) === 'NaN' ? 0 : formatNumber(rowData[empresa.nombre])
          }`;
        }
      });
    });

    return arrColumns;
  };

  const data = () => {
    const formatReturnArr = [];

    const formatVentasDelMes = {
      indicador: 'Ventas del mes'
    };
    const formatCosto = {
      indicador: 'Costos'
    };
    const formatGastos = {
      indicador: 'Gastos'
    };
    const formatResultado = {
      indicador: 'Resultado'
    };
    const formatSaldoDeudores = {
      indicador: 'Saldo deudores'
    };
    const formatSaldoAcreedores = {
      indicador: 'Saldo acreedores'
    };
    const formatCobranzasDelMes = {
      indicador: 'Cobranzas del mes'
    };
    const formatPagosDelMes = {
      indicador: 'Pagos del mes'
    };
    const formatChequesEnCartera = {
      indicador: 'Cheques en cartera'
    };
    const formatChequesEmitidos = {
      indicador: 'Cheques emitidos'
    };

    props.ventasDelMes.map((ventaDelMes) => (formatVentasDelMes[ventaDelMes.nombreEmpresa] = ventaDelMes.total));
    props.gastos.map((ventaDelMes) => (formatGastos[ventaDelMes.nombreEmpresa] = ventaDelMes.total));
    props.rentabilidades.map((ventaDelMes) => (formatResultado[ventaDelMes.nombreEmpresa] = ventaDelMes.total));
    props.saldoDeudoresPorEmpresa.map(
      (ventaDelMes) => (formatSaldoDeudores[ventaDelMes.nombreEmpresa] = ventaDelMes.total)
    );
    props.saldoAcreedoresPorEmpresa.map(
      (ventaDelMes) => (formatSaldoAcreedores[ventaDelMes.nombreEmpresa] = ventaDelMes.total)
    );
    props.cobranzas.map((ventaDelMes) => (formatCobranzasDelMes[ventaDelMes.nombreEmpresa] = ventaDelMes.total));
    props.pagos.map((ventaDelMes) => (formatPagosDelMes[ventaDelMes.nombreEmpresa] = ventaDelMes.total));
    props.chequesEnCartera.map(
      (ventaDelMes) => (formatChequesEnCartera[ventaDelMes.nombreEmpresa] = ventaDelMes.total)
    );
    props.chequesEmitidos.map((ventaDelMes) => (formatChequesEmitidos[ventaDelMes.nombreEmpresa] = ventaDelMes.total));

    props.rentabilidades.map(
      (rentabilidad, index) =>
        (formatCosto[rentabilidad.nombreEmpresa] =
          props.ventasDelMes[index].total - props.gastos[index].total - props.rentabilidades[index].total)
    );

    !indicadoresOcultos.ventasDelMes && formatReturnArr.push(formatVentasDelMes);
    !indicadoresOcultos.gastosDelMes && formatReturnArr.push(formatGastos);
    !indicadoresOcultos.ventasDelMes &&
      !indicadoresOcultos.gastosDelMes &&
      !indicadoresOcultos.resultado &&
      formatReturnArr.push(formatCosto);
    !indicadoresOcultos.resultado && formatReturnArr.push(formatResultado);
    !indicadoresOcultos.saldoDeudores && formatReturnArr.push(formatSaldoDeudores);
    !indicadoresOcultos.saldoAcreedores && formatReturnArr.push(formatSaldoAcreedores);
    !indicadoresOcultos.cobranzasMensuales && formatReturnArr.push(formatCobranzasDelMes);
    !indicadoresOcultos.pagosDelMes && formatReturnArr.push(formatPagosDelMes);
    !indicadoresOcultos.chequesEnCartera && formatReturnArr.push(formatChequesEnCartera);
    !indicadoresOcultos.chequesEmitidosPendientes && formatReturnArr.push(formatChequesEmitidos);

    return formatReturnArr;
  };

  return (
    <div className={classes.TablaIndicadoresMultiempresaContainer}>
      {!props.loading && <TablaIndicadoresMultiempresaToExcel empresas={empresas} datas={data()} />}
      <Table
        style={{ width: '100%', marginTop: '10px' }}
        columns={columns()}
        data={data()}
        loading={props.loading}
        customOptions={{
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false
        }}
        tableKey={"tabla-indicadores-multiempresa"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    empresas: state.multiempresa.empresas.empresas,

    ventasDelMes: state.multiempresa.ventasMensualesPorEmpresa.ventas,
    loading:
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasMensualesMultiempresa &&
        state.multiempresa.ventasMensualesPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMesMultiempresa &&
        state.multiempresa.gastosDelMesPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.rentabilidadMultiempresa &&
        state.multiempresa.rentabilidadPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudoresMultiempresa &&
        state.multiempresa.saldoDeudoresPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoAcreedoresMultiempresa &&
        state.multiempresa.saldoAcreedoresPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasMensualesMultiempresa &&
        state.multiempresa.cobranzasMensualesPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMesMultiempresa &&
        state.multiempresa.pagosDelMesPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCarteraMultiempresa &&
        state.multiempresa.chequesEnCarteraPorEmpresa.loading) ||
      (!state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidosPendientesMultiempresa &&
        state.multiempresa.chequesPropiosPorEmpresa.loading),

    indicadoresOcultos: {
      ivaFiscal: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscalMultiempresa,
      chequesEnCartera: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCarteraMultiempresa,
      chequesEmitidosPendientes:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidosPendientesMultiempresa,
      cobranzasMensuales: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasMensualesMultiempresa,
      gastosDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMesMultiempresa,
      pagosDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMesMultiempresa,
      saldoAcreedores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoAcreedoresMultiempresa,
      saldoDeudores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudoresMultiempresa,
      ventasDiarias: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDiariasMultiempresa,
      ventasDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasMensualesMultiempresa,
      resultado: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.rentabilidadMultiempresa
    },

    gastos: state.multiempresa.gastosDelMesPorEmpresa.gastos,

    rentabilidades: state.multiempresa.rentabilidadPorEmpresa.rentabilidades,

    saldoDeudoresPorEmpresa: state.multiempresa.saldoDeudoresPorEmpresa.saldos,

    saldoAcreedoresPorEmpresa: state.multiempresa.saldoAcreedoresPorEmpresa.saldos,

    cobranzas: state.multiempresa.cobranzasMensualesPorEmpresa.cobranzas,

    pagos: state.multiempresa.pagosDelMesPorEmpresa.pagos,

    chequesEnCartera: state.multiempresa.chequesEnCarteraPorEmpresa.cheques,

    chequesEmitidos: state.multiempresa.chequesPropiosPorEmpresa.cheques
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaIndicadoresMultiempresa);
