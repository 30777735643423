import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import moment from 'moment';

import classes from './TablaComparacionVentasDeSucursalesDelArticuloPorCliente.css';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { compareByAsc, compareByDesc } from '../../../../shared/sorting';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableComprobantesVentasDeLaSucursalDelArticuloAlCliente from '../../Comprobantes/TableComprobantesSucursales/TableComprobantesVentasDeLaSucursalDelArticuloAlCliente';

const TablaComparacionVentasDeSucursalesDelArticuloPorCliente = (props) => {
  const { fechaComparar, sucursalesComparar } = props;
  const [tableState, setTableState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });

  useEffect(() => {
    tableState.splice(0, 2);
    setLoading(true);
    setTimeout(() => {
      sucursalesComparar.map((sucursal) => {
        const separarFecha = moment(fechaComparar).subtract(1, 'month').format('MM/YYYY').split('/');
        const mes = separarFecha[0];
        const anio = separarFecha[1];
        return props.onLoadVentasDelArticuloPorCliente(sucursal.id, props.articuloId, mes, anio, (data) => {
          if (tableState.length === 1) {
            armarComparacion(data);
            setLoading(false);
          } else {
            tableState.push(data);
          }
          setTimeout(
            () =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: 'smooth'
              }),
            100
          );
        });
      });
    }, 500);
  }, [props.show, props.flag]);

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];

    tableState[0].forEach((ventasCompleto) => {
      const verify = arrayAComparar.filter((gasto) => gasto.nombre === ventasCompleto.nombre);
      if (verify.length === 0) {
        arrayNuevo.push({ ...ventasCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((gasto) => {
      return arrayNuevo.push(gasto);
    });

    arrayAComparar.forEach((ventasOrdenadas) => {
      const verify = tableState[0].filter((venta) => venta.nombre === ventasOrdenadas.nombre);

      if (verify.length === 0) {
        tableState[0].push({ ...ventasOrdenadas, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

    setTableState([
      { dateComplete: fechaComparar, ventasDetallada: orderTableState },
      { dateComplete: fechaComparar, ventasDetallada: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];

    tableState.map((data, key) => {
      if (key === 0) {
        return data.ventasDetallada.map((ventaDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].ventasDetallada[index];

          const ventasDelIndex = ventasDetalldasDelIndex
            ? tableState[1].ventasDetallada[index]
            : { mes: 0, anio: 0, id: '0', nombre: 'undefined', monto: 0, porcentajeDeParticipacion: 0 };

          return formatTableData.push({
            itemId: ventaDetallada.id,
            item: ventaDetallada.nombre,
            monto1: ventaDetallada.monto,
            porcParticipacion1: ventaDetallada.porcentajeDeParticipacion,
            monto2: ventasDelIndex.monto,
            porcParticipacion2: ventasDelIndex.porcentajeDeParticipacion,
            difMontos: calcularDif(ventaDetallada.monto, ventasDelIndex.monto),
            porcComparacion: calcularPorc(ventaDetallada.monto, ventasDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sucursalesComparar.length === 2) {
      if (sortConfig.key === sortKey) {
        setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
      } else {
        setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
      }
    }
  };

  const columns = [
    {
      title: 'Artículo',
      field: 'item',      
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const headersBackgroundColor = 'rgb(220, 220, 220)';

  return (
    <Table
      style={{ width: '100%', marginBottom: '10px', marginTop: '-10px', paddingLeft: '20px' }}
      title=""
      noBoxShadow
      columns={columns}
      loading={loading}
      components={{
        Header: (props) => {
          return (
            <TableHead>
              {sucursalesComparar.length === 2 && (
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>

                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px'
                    }}
                    colSpan={2}
                    align="center"
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px'
                    }}
                    colSpan={2}
                    align="center"
                  ></TableCell>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                    align="center"
                  ></TableCell>
                  <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }} align="left"></TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('item')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    width: window.screen.width < 1367 ? '265px' : '410px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="left"
                >
                  Cliente
                </TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('monto1')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('porcParticipacion1')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('monto2')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('porcParticipacion2')}
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    backgroundColor: headersBackgroundColor
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('difMontos')}
                  style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                  align="right"
                ></TableCell>
                <TableCell
                  className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                  onClick={() => onSort('porcComparacion')}
                  style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                  align="right"
                ></TableCell>
              </TableRow>
            </TableHead>
          );
        }
      }}
      detailPanel={[
        {
          disabled: false,
          tooltip: 'Mostrar Comprobantes',
          render: (rowData) => {
            return (
              <div className={classes.TablasComparacionComprobantesContainer}>
                {sucursalesComparar.map((sucursal, index) => {
                  const formatFecha = moment(fechaComparar).subtract(1, 'month').format('MM/YYYY').split('/');
                  return (
                    <div style={{ width: '100%' }} key={index}>
                      <div
                        style={{
                          marginRight: index === 0 ? '5px' : '10px',
                          marginLeft: index === 1 ? '5px' : '10px'
                        }}
                      >
                        <TableComprobantesVentasDeLaSucursalDelArticuloAlCliente
                          mes={formatFecha[0]}
                          anio={formatFecha[1]}
                          articuloId={props.articuloId}
                          clienteId={rowData.itemId}
                          sucursalId={sucursal.id}
                          fecha={formatFecha}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
        }
      ]}
      data={data()}
      customOptions={{
        thirdSortClick: false,
        sorting: true,
        grouping: false,
        draggable: false,
        search: false,
        paging: false,
        toolbar: false,
        tableLayout: 'fixed',
        showTitle: false,
        maxBodyHeight: 270,
        rowStyle: (rowData) => ({
          color: rowData.tableData.monto < 0 && 'red',
          backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
        })
      }}
      tableKey={"tabla-comparacion-ventas-sucursales-articulo-cliente"}
    />
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasDelArticuloPorCliente: (sucursalId, idArticulo, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDelArticulo(mes, anio, sucursalId, idArticulo, success, errorFail)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionVentasDeSucursalesDelArticuloPorCliente);
