import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import { groupBy, months, days } from '../../shared/utility';
import { enqueueSnackbar } from './snackbar';
import { fechaActualFunc } from '../../shared/fechas';
import axiosConfig from 'axios';
import { storageEmpresaId, storageMonedaId, storageToken, storageUsuarioId } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';
import { baseUrlDashboard } from '../../shared/urlApps';
import { compareByDesc } from '../../shared/sorting';

export const loadPieVentasComparativo = (vendedor, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadPieVentasComparativoStart());
    const idVendedor = vendedor && !vendedor.todos ? vendedor.Id : '';
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesEnteroComparativo?idMoneda=${storageMonedaId()}&idGrupoCmp=13&idVendedor=${idVendedor}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadPieVentasComparativoSuccess(response.data.montoMesActual, response.data.montoMesAnterior));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadPieVentasComparativoFail(error));
          } else {
            dispatch(loadPieVentasComparativo(vendedor, idEmpresa));
          }
        } else {
          dispatch(loadPieVentasComparativoFail(error));
        }
      });
  };
};

export const clearPieVentasComparativo = () => {
  return {
    type: actionTypes.CLEAR_PIE_VENTAS_COMPARATIVO
  };
};

const loadPieVentasComparativoStart = () => {
  return {
    type: actionTypes.PIE_VENTAS_COMPARATIVAS_START
  };
};

const loadPieVentasComparativoSuccess = (mesActual, mesAnterior) => {
  return {
    type: actionTypes.PIE_VENTAS_COMPARATIVAS_SUCCESS,
    mesActual: mesActual,
    mesAnterior: mesAnterior
  };
};

const loadPieVentasComparativoFail = (error) => {
  return {
    type: actionTypes.PIE_VENTAS_COMPARATIVAS_FAIL,
    error
  };
};

export const loadBarVentasPorVendedor = () => {
  return (dispatch) => {
    dispatch(loadBarVentasPorVendedorStart());

    setTimeout(function () {
      const ventasPorVendedores = [
        {
          Vendedor: {
            Id: '001',
            Nombre: 'Jorge Nuñez'
          },
          Monto: 10000,
          FechaDesde: new Date('2020-01-01'),
          FechaHasta: new Date('2020-01-31')
        },
        {
          Vendedor: {
            Id: '001',
            Nombre: 'Jorge Nuñez'
          },
          Monto: 9000,
          FechaDesde: new Date('2020-02-01'),
          FechaHasta: new Date('2020-02-28')
        },
        {
          Vendedor: {
            Id: '001',
            Nombre: 'Jorge Nuñez'
          },
          Monto: 11000,
          FechaDesde: new Date('2020-03-01'),
          FechaHasta: new Date('2020-03-31')
        },
        {
          Vendedor: {
            Id: '002',
            Nombre: 'Ana Sanchez'
          },
          Monto: 7500,
          FechaDesde: new Date('2020-01-01'),
          FechaHasta: new Date('2020-01-31')
        },
        {
          Vendedor: {
            Id: '002',
            Nombre: 'Ana Sanchez'
          },
          Monto: 14000,
          FechaDesde: new Date('2020-02-01'),
          FechaHasta: new Date('2020-02-28')
        },
        {
          Vendedor: {
            Id: '002',
            Nombre: 'Ana Sanchez'
          },
          Monto: 11500,
          FechaDesde: new Date('2020-03-01'),
          FechaHasta: new Date('2020-03-31')
        },
        {
          Vendedor: {
            Id: '003',
            Nombre: 'Alberto Rodriguez'
          },
          Monto: 8000,
          FechaDesde: new Date('2020-01-01'),
          FechaHasta: new Date('2020-01-31')
        },
        {
          Vendedor: {
            Id: '003',
            Nombre: 'Alberto Rodriguez'
          },
          Monto: 11500,
          FechaDesde: new Date('2020-02-01'),
          FechaHasta: new Date('2020-02-28')
        },
        {
          Vendedor: {
            Id: '003',
            Nombre: 'Alberto Rodriguez'
          },
          Monto: 10800,
          FechaDesde: new Date('2020-03-01'),
          FechaHasta: new Date('2020-03-31')
        },
        {
          Vendedor: {
            Id: '004',
            Nombre: 'Emilia Rodriguez'
          },
          Monto: 8500,
          FechaDesde: new Date('2020-01-01'),
          FechaHasta: new Date('2020-01-31')
        },
        {
          Vendedor: {
            Id: '004',
            Nombre: 'Emilia Rodriguez'
          },
          Monto: 9500,
          FechaDesde: new Date('2020-02-01'),
          FechaHasta: new Date('2020-02-28')
        },
        {
          Vendedor: {
            Id: '004',
            Nombre: 'Emilia Rodriguez'
          },
          Monto: 13800,
          FechaDesde: new Date('2020-03-01'),
          FechaHasta: new Date('2020-03-31')
        }
      ];

      const meses = [
        ...new Set(
          ventasPorVendedores
            .filter((venta) => venta.Vendedor.Id === ventasPorVendedores[0].Vendedor.Id)
            .map((venta) => months[venta.FechaDesde.getMonth()])
        )
      ];

      const ventasDelVendedor = groupBy(ventasPorVendedores, (venta) => venta.Vendedor.Id);
      const idsVendedores = [...new Set(ventasPorVendedores.map((vnt) => vnt.Vendedor.Id))];
      const montosPorVendedor = idsVendedores.map((id) => ({
        name: ventasDelVendedor[id][0].Vendedor.Nombre,
        data: ventasDelVendedor[id].map((valor) => valor.Monto)
      }));

      dispatch(loadBarVentasPorVendedorSuccess(montosPorVendedor, meses));
    }, 3500);
  };
};

const loadBarVentasPorVendedorStart = () => {
  return {
    type: actionTypes.BAR_VENTAS_VENDEDOR_MENSUAL_START
  };
};

const loadBarVentasPorVendedorSuccess = (ventasPorVendedor, meses) => {
  return {
    type: actionTypes.BAR_VENTAS_VENDEDOR_MENSUAL_SUCCESS,
    ventasPorVendedor: ventasPorVendedor,
    meses: meses
  };
};

export const loadTextoChequesEnCartera = (idCliente = '', idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoChequesEnCarteraStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesEnCarteraPromedio?idCliente=${idCliente}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.chequesEnCartera}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadTextoChequesEnCarteraSuccess(response.data.monto, response.data.diasPromedio));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoChequesEnCarteraFail(error));
          } else {
            dispatch(loadTextoChequesEnCartera(idEmpresa));
          }
        } else {
          dispatch(loadTextoChequesEnCarteraFail(error));
        }
      });
  };
};
export const clearTextoChequesEnCartera = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_CHEQUES_EN_CARTERA
  };
};

const loadTextoChequesEnCarteraStart = () => {
  return {
    type: actionTypes.TEXTO_CHEQUES_EN_CARTERA_START
  };
};

const loadTextoChequesEnCarteraSuccess = (monto, promedioDias) => {
  return {
    type: actionTypes.TEXTO_CHEQUES_EN_CARTERA_SUCCESS,
    monto: monto,
    promedioDias: promedioDias
  };
};

const loadTextoChequesEnCarteraFail = (error) => {
  return {
    type: actionTypes.TEXTO_CHEQUES_EN_CARTERA_FAIL,
    error: error
  };
};

export const loadTextoChequesRechazados = (idCliente = '', idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoChequesRechazadosStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesRechazados?idCliente=${idCliente}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.chequesRechazados}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { monto, cantidadCheques } = response.data;
        if (response.status === 204) {
          dispatch(loadTextoChequesRechazadosSuccess(0, 0));
        } else {
          dispatch(loadTextoChequesRechazadosSuccess(monto, cantidadCheques));
        }
      })
      .catch((error) => {
        // if (error.response) {
        //   if (error.response.statusCode === 500) {
        //     dispatch(loadTextoChequesRechazadosFail(error));
        //   } else {
        //     dispatch(loadTextoChequesRechazados(idEmpresa));
        //   }
        // } else {
        dispatch(loadTextoChequesRechazadosFail(error));
        // }
      });
  };
};
export const clearTextoChequesRechazados = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_CHEQUES_RECHAZADOS
  };
};

const loadTextoChequesRechazadosStart = () => {
  return {
    type: actionTypes.TEXTO_CHEQUES_RECHAZADOS_START
  };
};

const loadTextoChequesRechazadosSuccess = (monto, cantidadCheques) => {
  return {
    type: actionTypes.TEXTO_CHEQUES_RECHAZADOS_SUCCESS,
    monto,
    cantidadCheques
  };
};

const loadTextoChequesRechazadosFail = (error) => {
  return {
    type: actionTypes.TEXTO_CHEQUES_RECHAZADOS_FAIL,
    error: error
  };
};

function generateData(count, yrange) {
  var i = 0,
    series = [];
  while (i < count) {
    var x = (i + 1).toString(),
      y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y
    });
    i++;
  }
  return series;
}

export const loadHeatmapVentasPorDiaAnual = () => {
  return (dispatch) => {
    dispatch(loadHeatmapVentasPorDiaAnualStart());

    setTimeout(function () {
      const ventas = [
        {
          name: 'Enero',
          data: generateData(31, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Febrero',
          data: generateData(28, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Marzo',
          data: generateData(30, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Abril',
          data: generateData(31, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Mayo',
          data: generateData(30, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Junio',
          data: generateData(30, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Julio',
          data: generateData(31, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Agosto',
          data: generateData(30, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Septiembre',
          data: generateData(31, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Octubre',
          data: generateData(30, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Noviembre',
          data: generateData(31, {
            min: 700,
            max: 1500
          })
        },
        {
          name: 'Diciembre',
          data: generateData(31, {
            min: 700,
            max: 1500
          })
        }
      ];
      dispatch(loadHeatmapVentasPorDiaAnualSuccess(ventas));
    }, 3000);
  };
};

const loadHeatmapVentasPorDiaAnualStart = () => {
  return {
    type: actionTypes.HEATMAP_VENTAS_DIARIAS_DEL_ANIO_START
  };
};

const loadHeatmapVentasPorDiaAnualSuccess = (ventas) => {
  return {
    type: actionTypes.HEATMAP_VENTAS_DIARIAS_DEL_ANIO_SUCCESS,
    ventas: ventas
  };
};

export const loadLineChequesEnCartera = (idCliente = '', idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadLineChequesEnCarteraStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesEnCarteraEvolutivo?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.lineChequesEnCartera
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatChequesEnCartera = [];
        let totalCheques = 0.0;
        response.data.map(
          (chequeVencido) =>
            chequeVencido.estado === 'Vencido' &&
            chequeVencido.chequesMensuales.length !== 0 &&
            formatChequesEnCartera.push({
              valor: chequeVencido.chequesMensuales[0].monto,
              mes: chequeVencido.estado
            })
        );

        response.data.map(
          (chequeAVencer) =>
            chequeAVencer.estado === 'A vencer' &&
            chequeAVencer.chequesMensuales.map((chequeMensual) =>
              formatChequesEnCartera.push({
                valor: chequeMensual.monto,
                mes: chequeMensual.mes,
                anio: chequeMensual.anio
              })
            )
        );

        formatChequesEnCartera.map((cheque) => {
          return (totalCheques += cheque.valor);
        });
        dispatch(loadLineChequesEnCarteraSuccess(formatChequesEnCartera, totalCheques));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadLineChequesEnCarteraFail(error));
          } else {
            dispatch(loadLineChequesEnCartera(idEmpresa));
          }
        } else {
          dispatch(loadLineChequesEnCarteraFail(error));
        }
      });
  };
};

export const clearLineChequesEnCartera = () => {
  return {
    type: actionTypes.CLEAR_LINE_CHEQUES_EN_CARTERA
  };
};

const loadLineChequesEnCarteraStart = () => {
  return {
    type: actionTypes.LINE_CHEQUES_EN_CARTERA_START
  };
};

const loadLineChequesEnCarteraSuccess = (cheques, totalCheques) => {
  return {
    type: actionTypes.LINE_CHEQUES_EN_CARTERA_SUCCESS,
    cheques,
    totalCheques
  };
};

const loadLineChequesEnCarteraFail = (error) => {
  return {
    type: actionTypes.LINE_CHEQUES_EN_CARTERA_FAIL,
    error
  };
};

export const loadLineChequesRechazados = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadLineChequesRechazadosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesRechazadosEvolutivo?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.lineChequesRechazados
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatChequesRechazados = [];
        let totalCheques = 0.0;
        response.data.map(
          (chequeVencido) =>
            chequeVencido.estado === 'Vencido' &&
            chequeVencido.chequesMensuales.length !== 0 &&
            formatChequesRechazados.push({
              valor: chequeVencido.chequesMensuales[0].monto,
              mes: chequeVencido.estado
            })
        );

        response.data.map(
          (chequeAVencer) =>
            chequeAVencer.estado === 'A vencer' &&
            chequeAVencer.chequesMensuales.map((chequeMensual) =>
              formatChequesRechazados.push({
                valor: chequeMensual.monto,
                mes: chequeMensual.mes,
                anio: chequeMensual.anio
              })
            )
        );

        formatChequesRechazados.map((cheque) => {
          return (totalCheques += cheque.valor);
        });
        dispatch(loadLineChequesRechazadosSuccess(formatChequesRechazados, totalCheques));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadLineChequesRechazadosFail(error));
          } else {
            dispatch(loadLineChequesRechazados(idEmpresa));
          }
        } else {
          dispatch(loadLineChequesRechazadosFail(error));
        }
      });
  };
};

export const clearLineChequesRechazados = () => {
  return {
    type: actionTypes.CLEAR_LINE_CHEQUES_RECHAZADOS
  };
};

const loadLineChequesRechazadosStart = () => {
  return {
    type: actionTypes.LINE_CHEQUES_RECHAZADOS_START
  };
};

const loadLineChequesRechazadosSuccess = (cheques, totalCheques) => {
  return {
    type: actionTypes.LINE_CHEQUES_RECHAZADOS_SUCCESS,
    cheques,
    totalCheques
  };
};

const loadLineChequesRechazadosFail = (error) => {
  return {
    type: actionTypes.LINE_CHEQUES_RECHAZADOS_FAIL,
    error
  };
};

export const loadLineChequesPropios = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadLineChequesPropiosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesPropiosEvolutivo?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.lineChequesEmitidosPendientes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatChequesPropios = [];

        let totalCheques = 0.0;
        response.data.map(
          (chequeVencido) =>
            chequeVencido.estado === 'Vencido' &&
            chequeVencido.chequesMensuales.length !== 0 &&
            formatChequesPropios.push({
              valor: chequeVencido.chequesMensuales[0].monto,
              mes: chequeVencido.estado
            })
        );

        response.data.map(
          (chequeAVencer) =>
            chequeAVencer.estado === 'A vencer' &&
            chequeAVencer.chequesMensuales.map((chequeMensual) =>
              formatChequesPropios.push({
                valor: chequeMensual.monto,
                mes: chequeMensual.mes,
                anio: chequeMensual.anio
              })
            )
        );

        formatChequesPropios.map((cheque) => {
          return (totalCheques += cheque.valor);
        });

        dispatch(loadLineChequesPropiosSuccess(formatChequesPropios, totalCheques));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadLineChequesPropiosFail(error));
          } else {
            dispatch(loadLineChequesPropios(idEmpresa));
          }
        } else {
          dispatch(loadLineChequesPropiosFail(error));
        }
      });
  };
};

export const clearLineChequesPropios = () => {
  return {
    type: actionTypes.CLEAR_LINE_CHEQUES_PROPIOS
  };
};

const loadLineChequesPropiosStart = () => {
  return {
    type: actionTypes.LINE_CHEQUES_PROPIOS_START
  };
};

const loadLineChequesPropiosSuccess = (cheques, totalCheques) => {
  return {
    type: actionTypes.LINE_CHEQUES_PROPIOS_SUCCESS,
    cheques,
    totalCheques
  };
};

const loadLineChequesPropiosFail = (error) => {
  return {
    type: actionTypes.LINE_CHEQUES_PROPIOS_FAIL,
    error
  };
};

export const loadDetalleCheques = (
  idCliente = '',
  idEmpresa = storageEmpresaId(),
  propios = false,
  rechazados = false,
  delDia = false
) => {
  return (dispatch) => {
    dispatch(loadDetalleChequesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/Detalle?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&propios=${propios}&rechazados=${rechazados}&delDia=${delDia}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.detalleCheques
        }`,
        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        dispatch(loadDetalleChequesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.statusCode !== 500)
          dispatch(loadDetalleCheques(idEmpresa, propios, rechazados));
        else dispatch(loadDetalleChequesFail(error));
      });
  };
};

export const clearDetalleCheques = () => {
  return {
    type: actionTypes.CLEAR_DETALLE_CHEQUES
  };
};

const loadDetalleChequesStart = () => {
  return {
    type: actionTypes.DETALLE_CHEQUES_START
  };
};

const loadDetalleChequesSuccess = (cheques) => {
  return {
    type: actionTypes.DETALLE_CHEQUES_SUCCESS,
    cheques
  };
};

const loadDetalleChequesFail = (error) => {
  return {
    type: actionTypes.DETALLE_CHEQUES_FAIL,
    error
  };
};

export const loadPieVentasComparativoVendedor = (vendedorId) => {
  return (dispatch) => {
    dispatch(loadPieVentasComparativoVendedorStart());

    setTimeout(function () {
      dispatch(loadPieVentasComparativoVendedorSuccess(120, 90));
    }, 2500);
  };
};

const loadPieVentasComparativoVendedorStart = () => {
  return {
    type: actionTypes.PIE_VENTAS_COMPARATIVO_VENDEDOR_START
  };
};

const loadPieVentasComparativoVendedorSuccess = (mesActual, mesAnterior) => {
  return {
    type: actionTypes.PIE_VENTAS_COMPARATIVO_VENDEDOR_SUCCESS,
    mesActual: mesActual,
    mesAnterior: mesAnterior
  };
};

export const loadVentasDiariasComparativo = (vendedor, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadVentasDiariasComparativoStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelDia?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDelDia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadVentasDiariasComparativoSuccess(response.data.montoDia, response.data.promedioUltimoPeriodo));
        dispatch(loadVentasDiariasComparativoRequest(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasDiariasComparativoFail(error));
          } else {
            dispatch(loadVentasDiariasComparativo(vendedor, idEmpresa));
          }
        } else {
          dispatch(loadVentasDiariasComparativoFail(error));
        }
      });
  };
};

export const clearVentasDiariasComparativo = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DIARIAS_COMPARATIVO
  };
};

const loadVentasDiariasComparativoRequest = (loadingRequest) => {
  return {
    type: actionTypes.VENTAS_DIARIAS_COMPARATIVO_REQUEST,
    loadingRequest
  };
};

const loadVentasDiariasComparativoStart = () => {
  return {
    type: actionTypes.VENTAS_DIARIAS_COMPARATIVO_START
  };
};

const loadVentasDiariasComparativoSuccess = (diaActual, promedio30Dias) => {
  return {
    type: actionTypes.VENTAS_DIARIAS_COMPARATIVO_SUCCESS,
    diaActual: diaActual,
    promedio30Dias: promedio30Dias
  };
};

const loadVentasDiariasComparativoFail = (error) => {
  return {
    type: actionTypes.VENTAS_DIARIAS_COMPARATIVO_FAIL,
    error: error
  };
};

export const loadLineVentasComparativo = (fechaDesde, fechaHasta, vendedor) => {
  return (dispatch) => {
    dispatch(loadLineVentasComparativoStart());

    const diffTime = Math.abs(fechaHasta - fechaDesde);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setTimeout(function () {
      const periodoActual = [
        {
          valor: 10000,
          unidades: 94,
          dia: 1,
          mes: 5,
          anio: 2019
        },
        {
          valor: 11000,
          unidades: 85,
          dia: 1,
          mes: 6,
          anio: 2019
        },
        {
          valor: 12000,
          unidades: 97,
          dia: 1,
          mes: 7,
          anio: 2019
        },
        {
          valor: 10500,
          unidades: 75,
          dia: 1,
          mes: 8,
          anio: 2019
        },
        {
          valor: 9500,
          unidades: 64,
          dia: 1,
          mes: 9,
          anio: 2019
        },
        {
          valor: 13000,
          unidades: 98,
          dia: 1,
          mes: 10,
          anio: 2019
        },
        {
          valor: 12000,
          unidades: 85,
          dia: 1,
          mes: 11,
          anio: 2019
        },
        {
          valor: 11800,
          unidades: 87,
          dia: 1,
          mes: 12,
          anio: 2019
        },
        {
          valor: 8500,
          unidades: 70,
          dia: 1,
          mes: 1,
          anio: 2020
        },
        {
          valor: 9700,
          unidades: 80,
          dia: 1,
          mes: 2,
          anio: 2020
        },
        {
          valor: 11250,
          unidades: 87,
          dia: 1,
          mes: 3,
          anio: 2020
        },
        {
          valor: 12500,
          unidades: 101,
          dia: 1,
          mes: 4,
          anio: 2020
        }
      ];

      const periodoAnterior = [
        {
          valor: 8000,
          unidades: 80,
          dia: 1,
          mes: 5,
          anio: 2018
        },
        {
          valor: 8500,
          unidades: 82,
          dia: 1,
          mes: 6,
          anio: 2018
        },
        {
          valor: 7500,
          unidades: 77,
          dia: 1,
          mes: 7,
          anio: 2018
        },
        {
          valor: 11000,
          unidades: 105,
          dia: 1,
          mes: 8,
          anio: 2018
        },
        {
          valor: 10650,
          unidades: 101,
          dia: 1,
          mes: 9,
          anio: 2018
        },
        {
          valor: 7820,
          unidades: 64,
          dia: 1,
          mes: 10,
          anio: 2018
        },
        {
          valor: 9450,
          unidades: 78,
          dia: 1,
          mes: 11,
          anio: 2018
        },
        {
          valor: 6480,
          unidades: 70,
          dia: 1,
          mes: 12,
          anio: 2018
        },
        {
          valor: 11250,
          unidades: 94,
          dia: 1,
          mes: 1,
          anio: 2019
        },
        {
          valor: 10750,
          unidades: 85,
          dia: 1,
          mes: 2,
          anio: 2019
        },
        {
          valor: 8750,
          unidades: 75,
          dia: 1,
          mes: 3,
          anio: 2019
        },
        {
          valor: 7980,
          unidades: 73,
          dia: 1,
          mes: 4,
          anio: 2019
        }
      ];

      let referenciasEjeX = [];
      if (diffDays > 90) {
        referenciasEjeX = periodoActual
          .sort((a, b) => a.anio - b.anio || a.mes - b.mes)
          .map((venta) => venta.mes + '/' + venta.anio);
      }

      if (diffDays === 7) {
        referenciasEjeX = periodoActual
          .sort((a, b) => a.anio - b.anio || a.mes - b.mes || a.dia - b.dia)
          .map((venta) => days[venta.dia]);
      }

      if (referenciasEjeX && referenciasEjeX.length === 0) {
        referenciasEjeX = periodoActual
          .sort((a, b) => a.anio - b.anio || a.mes - b.mes || a.dia - b.dia)
          .map((venta) => venta.dia + '/' + venta.mes + '/' + venta.anio);
      }

      const seriePeriodoActualDinero = { name: 'Periodo actual', data: periodoActual.map((venta) => venta.valor) };
      const seriePeriodoAnteriorDinero = {
        name: 'Periodo anterior',
        data: periodoAnterior.map((venta) => venta.valor)
      };
      const seriePeriodoActualUnidades = { name: 'Periodo actual', data: periodoActual.map((venta) => venta.unidades) };
      const seriePeriodoAnteriorUnidades = {
        name: 'Periodo anterior',
        data: periodoAnterior.map((venta) => venta.unidades)
      };

      dispatch(
        loadLineVentasComparativoSuccess(
          seriePeriodoActualDinero,
          seriePeriodoAnteriorDinero,
          seriePeriodoActualUnidades,
          seriePeriodoAnteriorUnidades,
          referenciasEjeX
        )
      );
    }, 2500);
  };
};

const loadLineVentasComparativoStart = () => {
  return {
    type: actionTypes.LINE_VENTAS_COMPARATIVO_START
  };
};

const loadLineVentasComparativoSuccess = (
  periodoActualDinero,
  periodoAnteriorDinero,
  periodoActualUnidades,
  periodoAnteriorUnidades,
  referenciasEjeX
) => {
  return {
    type: actionTypes.LINE_VENTAS_COMPARATIVO_SUCCESS,
    periodoActualDinero: periodoActualDinero,
    periodoAnteriorDinero: periodoAnteriorDinero,
    periodoActualUnidades: periodoActualUnidades,
    periodoAnteriorUnidades: periodoAnteriorUnidades,
    referenciasEjeX: referenciasEjeX
  };
};

export const loadBarVentasPorVendedorDelPeriodoComparativo = (
  fechaDesdeActual,
  fechaHastaActual,
  fechaDesdeAnterior,
  fechaHastaAnterior
) => {
  return (dispatch) => {
    dispatch(loadBarVentasPorVendedorDelPeriodoComparativoStart());

    if (!fechaDesdeActual) {
      fechaDesdeActual = new Date();
      fechaDesdeActual.setMonth(fechaDesdeActual.getMonth() - 1);
    }

    if (!fechaHastaActual) {
      fechaHastaActual = new Date();
    }

    if (!fechaDesdeAnterior) {
      fechaDesdeAnterior = new Date();
      fechaDesdeAnterior.setMonth(fechaDesdeAnterior.getMonth() - 2);
    }

    if (!fechaHastaAnterior) {
      fechaHastaAnterior = new Date();
      fechaHastaAnterior.setMonth(fechaHastaAnterior.getMonth() - 1);
      fechaHastaAnterior.setDate(fechaHastaAnterior.getDate() - 1);
    }

    let ventasPorVendedorPeriodoActual = [];
    let ventasPorVendedorPeriodoAnterior = [];

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        '/Vendedores/VentasPorVendedorDelPeriodo?idMoneda=PES&idGrupoCmp=13&fechaDesde=' +
          fechaDesdeActual.toLocaleDateString('en-US') +
          '&fechaHasta=' +
          fechaHastaActual.toLocaleDateString('en-US'),
        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        ventasPorVendedorPeriodoActual = response.data;

        axios
          .get(
            '/Vendedores/VentasPorVendedorDelPeriodo?idMoneda=PES&idGrupoCmp=13&fechaDesde=' +
              fechaDesdeAnterior.toLocaleDateString('en-US') +
              '&fechaHasta=' +
              fechaHastaAnterior.toLocaleDateString('en-US'),
            { headers: { Authorization: authStr } }
          )
          .then((response) => {
            ventasPorVendedorPeriodoAnterior = response.data;

            const vendedores = ventasPorVendedorPeriodoActual.map((ventasVendedor) => ventasVendedor.vendedor.nombre);

            const seriePeriodoActual = {
              name: 'Periodo actual',
              data: ventasPorVendedorPeriodoActual.map((ventasVendedor) => ventasVendedor.monto)
            };

            const seriePeriodoAnterior = {
              name: 'Periodo anterior',
              data: ventasPorVendedorPeriodoAnterior.map((ventasVendedor) => ventasVendedor.monto)
            };

            dispatch(
              loadBarVentasPorVendedorDelPeriodoComparativoSuccess(seriePeriodoActual, seriePeriodoAnterior, vendedores)
            );
          })
          .catch((error) => {
            dispatch(
              enqueueSnackbar({
                message: `Ventas por vendedor: ${error.message}`,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'error'
                }
              })
            );
          });
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: `Ventas por vendedor: ${error.message}`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const loadBarVentasPorVendedorDelPeriodoComparativoStart = () => {
  return {
    type: actionTypes.BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_START
  };
};

const loadBarVentasPorVendedorDelPeriodoComparativoSuccess = (
  ventasPorVendedorPeriodoActual,
  ventasPorVendedorPeriodoAnterior,
  vendedores
) => {
  return {
    type: actionTypes.BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_SUCCESS,
    ventasPorVendedorPeriodoActual: ventasPorVendedorPeriodoActual,
    ventasPorVendedorPeriodoAnterior: ventasPorVendedorPeriodoAnterior,
    vendedores: vendedores
  };
};

export const loadDeudoresPorVentas = (idCliente = '', idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadDeudoresPorVentasStart());
    const authStr = 'Bearer '.concat(storageToken());
    setTimeout(() => {
      axios
        .get(
          `/ventas/DeudoresPorVentas?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
            idIndicadores.saldoDeudores
          }`,
          {
            headers: { Authorization: authStr }
          }
        )
        .then((response) => {
          const deudas = response.data;
          let totalDeuda = 0;
          response.data.map((deuda) => (totalDeuda += deuda.value));
          dispatch(loadDeudoresPorVentasSuccess(deudas, totalDeuda));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.statusCode === 500) {
              dispatch(loadDeudoresPorVentasFail(error));
            } else {
              dispatch(loadDeudoresPorVentas(idEmpresa));
            }
          } else {
            dispatch(loadDeudoresPorVentasFail(error));
          }
        });
    }, 5000);
  };
};

export const clearDeudoresPorVentas = () => {
  return {
    type: actionTypes.CLEAR_DEUDORES_POR_VENTAS
  };
};

const loadDeudoresPorVentasStart = () => {
  return {
    type: actionTypes.LOAD_DEUDORES_POR_VENTAS_START
  };
};

const loadDeudoresPorVentasSuccess = (deudas, totalDeudas) => {
  return {
    type: actionTypes.LOAD_DEUDORES_POR_VENTAS_SUCCESS,
    deudas,
    totalDeudas
  };
};

const loadDeudoresPorVentasFail = (error) => {
  return {
    type: actionTypes.LOAD_DEUDORES_POR_VENTAS_FAIL,
    error
  };
};

export const loadTextoCobranzasMensual = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoCobranzasMensualStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/CobranzasDelMesComparativo?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.cobranzasDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(
          loadTextoCobranzasMensualSuccess(
            Math.abs(response.data.cobranzasMesActual),
            Math.abs(response.data.cobranzasMesAnterior)
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoCobranzasMensualesError(error));
          } else {
            dispatch(loadTextoCobranzasMensualSuccess(idEmpresa));
          }
        } else {
          dispatch(loadTextoCobranzasMensualesError(error));
        }
      });
  };
};

export const clearTextoCobranzaMensual = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_COBRANZAS_MENSUAL
  };
};

const loadTextoCobranzasMensualStart = () => {
  return {
    type: actionTypes.TEXTO_COBRANZAS_MENSUAL_START
  };
};

const loadTextoCobranzasMensualSuccess = (mesActual, mesAnterior) => {
  return {
    type: actionTypes.TEXTO_COBRANZAS_MENSUAL_SUCCESS,
    mesActual: mesActual,
    mesAnterior: mesAnterior
  };
};

const loadTextoCobranzasMensualesError = (error) => {
  return {
    type: actionTypes.TEXTO_COBRANZAS_MENSUAL_FAIL,
    error
  };
};

export const loadTextoPlazoMedioVencimientos = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoPlazoMedioVencimientosStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/DeudoresPorVentasPromedioDeDias?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.plazoMedioVencACobrar
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(
          loadTextoPlazoMedioVencimientosSuccess(
            response.data.monto,
            response.data.diasPromedio,
            response.data.cobranzasSinImputar
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoPlazoMedioVencimientosFail(error));
          } else {
            dispatch(loadTextoPlazoMedioVencimientos(idEmpresa));
          }
        } else {
          dispatch(loadTextoPlazoMedioVencimientosFail(error));
        }
      });
  };
};

export const clearTextoPlazoMedioVencimientos = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_DEUDORES_POR_VENTAS
  };
};

const loadTextoPlazoMedioVencimientosStart = () => {
  return {
    type: actionTypes.TEXTO_DEUDORES_POR_VENTAS_START
  };
};

const loadTextoPlazoMedioVencimientosSuccess = (deuda, promedioDias, cobranzasSinImputar) => {
  return {
    type: actionTypes.TEXTO_DEUDORES_POR_VENTAS_SUCCESS,
    deuda: deuda,
    promedioDias: promedioDias,
    cobranzasSinImputar: cobranzasSinImputar
  };
};

const loadTextoPlazoMedioVencimientosFail = (error) => {
  return {
    type: actionTypes.TEXTO_DEUDORES_POR_VENTAS_FAIL,
    error: error
  };
};

export const loadTextoPromDiasCobranzas = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoPromDiasCobranzasStart());
    const fechaHasta = fechaActualFunc();
    const fechaDesde = new Date(fechaHasta.getFullYear(), fechaHasta.getMonth(), 1);

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/PlazoMedioCobroClientes?idMoneda=${storageMonedaId()}&fechaDesde=${fechaDesde.toLocaleDateString(
          'en-US'
        )}&fechaHasta=${fechaHasta.toLocaleDateString('en-US')}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.promDiasDeCobranzas
        }`,
        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        dispatch(loadTextoPromDiasCobranzasSuccess(response.data.dias, response.data.total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoPromDiasCobranzasFail(error));
          } else {
            dispatch(loadTextoPromDiasCobranzas(idEmpresa));
          }
        } else {
          dispatch(loadTextoPromDiasCobranzasFail(error));
        }
      });
  };
};

export const clearTextoPromDiasCobranzas = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_PROMEDIO_DIAS_COBRANZAS
  };
};

const loadTextoPromDiasCobranzasStart = () => {
  return {
    type: actionTypes.TEXTO_PROMEDIO_DIAS_COBRANZAS_START
  };
};

const loadTextoPromDiasCobranzasSuccess = (dias, total) => {
  return {
    type: actionTypes.TEXTO_PROMEDIO_DIAS_COBRANZAS_SUCCESS,
    dias,
    total
  };
};

const loadTextoPromDiasCobranzasFail = (error) => {
  return {
    type: actionTypes.TEXTO_PROMEDIO_DIAS_COBRANZAS_FAIL,
    error: error
  };
};

export const loadTextoPromedioCobranzas = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoPromedioCobranzasStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/ventas/CobranzasPromedio?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadTextoPromedioCobranzasSuccess(response.data.monto, response.data.cantidad));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoPromedioCobranzasFail(error));
          } else {
            dispatch(loadTextoPromedioCobranzas(idEmpresa));
          }
        } else {
          dispatch(loadTextoPromedioCobranzasFail(error));
        }
      });
  };
};

const loadTextoPromedioCobranzasStart = () => {
  return {
    type: actionTypes.TEXTO_PROMEDIO_COBRANZAS_START
  };
};

const loadTextoPromedioCobranzasSuccess = (promedio, cantidad) => {
  return {
    type: actionTypes.TEXTO_PROMEDIO_COBRANZAS_SUCCESS,
    promedio: promedio,
    cantidad: cantidad
  };
};

const loadTextoPromedioCobranzasFail = (error) => {
  return {
    type: actionTypes.TEXTO_PROMEDIO_COBRANZAS_FAIL,
    error: error
  };
};

export const loadComprobantesVentas = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesVentasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `${baseUrlDashboard()}Ventas/ComprobantesVentas?idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprobantesVentas
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesVentasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesVentasFail(error));
          } else {
            dispatch(loadComprobantesVentas(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesVentasFail(error));
        }
      });
  };
};

const loadComprobantesVentasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_START
  };
};

const loadComprobantesVentasSuccess = (comprobantesVentas) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_SUCCESS,
    comprobantesVentas
  };
};

const loadComprobantesVentasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_FAIL,
    error
  };
};

export const loadComprobantesCobranzas = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesCobranzasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/ComprobantesCobranzas?empresaId=${idEmpresa}&indicadorId=${
          idIndicadores.comprobantesCobranzas
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesCobranzasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesCobranzasFail(error));
          } else {
            dispatch(loadComprobantesCobranzas(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesCobranzasFail(error));
        }
      });
  };
};

const loadComprobantesCobranzasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COBRANZAS_START
  };
};

const loadComprobantesCobranzasSuccess = (comprobantesCobranzas) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COBRANZAS_SUCCESS,
    comprobantesCobranzas
  };
};

const loadComprobantesCobranzasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COBRANZAS_FAIL,
    error
  };
};

export const postComprobantesVentas = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true, false));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`${baseUrlDashboard()}ventas/ComprobantesVentas?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Comprobantes actualizados correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        )
        dispatch(executePostComprobantes(false, true));
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false, false));

        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
      });
  };
};

export const postComprobantesCobranzas = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true, false));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`${baseUrlDashboard()}ventas/ComprobantesCobranzas?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Comprobantes actualizados correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        )
        dispatch(executePostComprobantes(false, true));
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false, false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
      });
  };
};

const executePostComprobantes = (loading, modificado) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
    modificado
  };
};

export const loadCategoriasDelCliente = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadCategoriasDelClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/CategoriasDeCliente?empresaId=${idEmpresa}&indicadorId=${
          idIndicadores.categoriasDelCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadCategoriasDelClienteSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadCategoriasDelClienteFail(error));
          } else {
            dispatch(loadCategoriasDelCliente(idEmpresa));
          }
        } else {
          dispatch(loadCategoriasDelClienteFail(error));
        }
      });
  };
};

const loadCategoriasDelClienteStart = () => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_START
  };
};

const loadCategoriasDelClienteSuccess = (categorias) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS,
    categorias
  };
};

const loadCategoriasDelClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_FAIL,
    error
  };
};

export const postCategoriasDelCliente = (empresaId, categorias) => {
  return (dispatch) => {
    dispatch(executePostCategoriasDelCliente(true, false));

    const authStr = 'Bearer '.concat(storageToken());

    axiosConfig
      .post(`${baseUrlDashboard()}ventas/CategoriasDeCliente?idEmpresa=${empresaId}`, categorias, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(enqueueSnackbar({
          message: `Categorías actualizadas correctamente`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        }))
        dispatch(executePostCategoriasDelCliente(false, true));
      })
      .catch((error) => {
        dispatch(executePostCategoriasDelCliente(false, false));

        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
      });
  };
};

const executePostCategoriasDelCliente = (loading, modificado) => {
  return {
    type: actionTypes.EXECUTE_POST_CATEGORIAS_DEL_CLIENTE,
    loading,
    modificado
  };
};

export const loadDeudaPorCategoriaDeCliente = () => {
  return (dispatch) => {
    dispatch(loadDeudaPorCategoriaDeClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/DeudaPorCategoriaDeCliente?idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.deudaPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        const valoresNegativos = response.data.filter((rd) => rd.importe < 0);
        const valoresPositivos = response.data.filter((rd) => rd.importe >= 0);
        response.data.map((deuda) => (total += deuda.importe));
        dispatch(loadDeudaPorCategoriaDeClienteSuccess(valoresPositivos, total, valoresNegativos));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadDeudaPorCategoriaDeClienteFail(error));
          } else {
            dispatch(loadDeudaPorCategoriaDeCliente(idEmpresa));
          }
        } else {
          dispatch(loadDeudaPorCategoriaDeClienteFail(error));
        }
      });
  };
};

export const clearDeudaPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_DEUDA_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadDeudaPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadDeudaPorCategoriaDeClienteSuccess = (categorias, total, valoresNegativos) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    categorias,
    total,
    valoresNegativos
  };
};

const loadDeudaPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadVentasPorMarca = () => {
  return (dispatch) => {
    dispatch(loadVentasPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/VentasPorMarca?idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((venta) => (total += Math.abs(venta.valor)));
        dispatch(loadVentasPorMarcaSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasPorMarcaFail(error));
          } else {
            dispatch(loadVentasPorMarca());
          }
        } else {
          dispatch(loadVentasPorMarcaFail(error));
        }
      });
  };
};

export const clearVentasPorMarca = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_MARCA
  };
};

const loadVentasPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_MARCA_START
  };
};

const loadVentasPorMarcaSuccess = (ventas, total) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_MARCA_SUCCESS,
    ventas,
    total
  };
};

const loadVentasPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_MARCA_FAIL,
    error
  };
};

export const loadVentasPorRubro = () => {
  return (dispatch) => {
    dispatch(loadVentasPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/VentasPorRubro?idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((venta) => (total += Math.abs(venta.valor)));
        dispatch(loadVentasPorRubroSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasPorRubroFail(error));
          } else {
            dispatch(loadVentasPorRubro());
          }
        } else {
          dispatch(loadVentasPorRubroFail(error));
        }
      });
  };
};

export const clearVentasPorRubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_RUBRO
  };
};

const loadVentasPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_RUBRO_START
  };
};

const loadVentasPorRubroSuccess = (ventas, total) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_RUBRO_SUCCESS,
    ventas,
    total
  };
};

const loadVentasPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_RUBRO_FAIL,
    error
  };
};

export const loadVentasPorSubRubro = () => {
  return (dispatch) => {
    dispatch(loadVentasPorSubRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/VentasPorSubrubro?idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((venta) => (total += Math.abs(venta.valor)));
        dispatch(loadVentasPorSubRubroSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasPorSubRubroFail(error));
          } else {
            dispatch(loadVentasPorSubRubro());
          }
        } else {
          dispatch(loadVentasPorSubRubroFail(error));
        }
      });
  };
};

export const clearVentasPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUBRUBRO
  };
};

const loadVentasPorSubRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUBRUBRO_START
  };
};

const loadVentasPorSubRubroSuccess = (ventas, total) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUBRUBRO_SUCCESS,
    ventas,
    total
  };
};

const loadVentasPorSubRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadUtilidadPorMarca = (mes = 0, anio = 0) => {
  return (dispatch) => {
    dispatch(loadUtilidadPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/UtilidadPorMarca?mes=${mes}&anio=${anio}&idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.utilidadPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((utilidad) => (total += utilidad.ventas));
        const utilidades = response.data.map((utilidad) => {
          return { ...utilidad, estaOculto: false };
        });
        dispatch(loadUtilidadPorMarcaSuccess(utilidades, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadUtilidadPorMarcaFail(error));
          } else {
            dispatch(loadUtilidadPorMarca(mes, anio));
          }
        } else {
          dispatch(loadUtilidadPorMarcaFail(error));
        }
      });
  };
};

export const clearUtilidadPorMarca = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_POR_MARCA
  };
};

export const updateUtilidadesPorMarcaOcultos = (utilidades) => {
  return {
    type: actionTypes.UPDATE_UTILIDADES_POR_MARCA_OCULTOS,
    utilidades
  };
};

const loadUtilidadPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_MARCA_START
  };
};

const loadUtilidadPorMarcaSuccess = (utilidades, total) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_MARCA_SUCCESS,
    utilidades,
    total
  };
};

const loadUtilidadPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_MARCA_FAIL,
    error
  };
};

export const loadUtilidadPorRubro = (mes = 0, anio = 0) => {
  return (dispatch) => {
    dispatch(loadUtilidadPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/UtilidadPorRubro?mes=${mes}&anio=${anio}&idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.utilidadPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((utilidad) => (total += utilidad.ventas));

        const utilidades = response.data.map((utilidad) => {
          return { ...utilidad, estaOculto: false };
        });

        dispatch(loadUtilidadPorRubroSuccess(utilidades, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadUtilidadPorRubroFail(error));
          } else {
            dispatch(loadUtilidadPorRubro(mes, anio));
          }
        } else {
          dispatch(loadUtilidadPorRubroFail(error));
        }
      });
  };
};

export const clearUtilidadPorRubro = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_POR_RUBRO
  };
};

export const updateUtilidadesPorRubroOcultos = (utilidades) => {
  return {
    type: actionTypes.UPDATE_UTILIDADES_POR_RUBRO_OCULTOS,
    utilidades
  };
};

const loadUtilidadPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_RUBRO_START
  };
};

const loadUtilidadPorRubroSuccess = (utilidades, total) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_RUBRO_SUCCESS,
    utilidades,
    total
  };
};

const loadUtilidadPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_RUBRO_FAIL,
    error
  };
};

export const loadUtilidadPorSubrubro = (mes = 0, anio = 0) => {
  return (dispatch) => {
    dispatch(loadUtilidadPorSubrubroStart(0));
    const authStr = 'Bearer '.concat(storageToken());
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();

    axiosConfig
      .get(
        `${baseUrlDashboard()}ventas/UtilidadPorSubrubro?mes=${mes}&anio=${anio}&idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.utilidadPorSubrubro
        }`,
        {
          headers: { Authorization: authStr },
          onDownloadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch(loadUtilidadPorSubrubroStart(percent));
          },
          onUploadProgress: (progressEvent) => {}
        }
      )
      .then((response) => {
        let total = 0;
        response.data.map((utilidad) => (total += utilidad.ventas));
        const utilidades = response.data.map((utilidad) => {
          return { ...utilidad, estaOculto: false };
        });
        dispatch(loadUtilidadPorSubrubroSuccess(utilidades, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadUtilidadPorSubrubroFail(error));
          } else {
            dispatch(loadUtilidadPorSubrubro(mes, anio));
          }
        } else {
          dispatch(loadUtilidadPorSubrubroFail(error));
        }
      });
  };
};

export const clearUtilidadPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_POR_SUBRUBRO
  };
};

export const updateUtilidadesPorSubrubroOcultos = (utilidades) => {
  return {
    type: actionTypes.UPDATE_UTILIDADES_POR_SUBRUBRO_OCULTOS,
    utilidades
  };
};

const loadUtilidadPorSubrubroStart = (progress) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_SUBRUBRO_START,
    progress
  };
};

const loadUtilidadPorSubrubroSuccess = (utilidades, total) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_SUBRUBRO_SUCCESS,
    utilidades,
    total
  };
};

const loadUtilidadPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadEvolucionDeVentasMensual = (mes = 0, anio = 0, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeVentasMensualStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMes?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDesglosadasPorMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalVentas += venta.monto);
        });
        dispatch(loadEvolucionDeVentasMensualSuccess(response.data, formatVentasParaTabla, totalVentas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeVentasMensualFail(error));
          } else {
            dispatch(loadEvolucionDeVentasMensual(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeVentasMensualFail(error));
        }
      });
  };
};

export const clearEvolucionDeVentasMensual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_VENTAS
  };
};

const loadEvolucionDeVentasMensualStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_START
  };
};

const loadEvolucionDeVentasMensualSuccess = (ventas, ventasDataTable, totalVentas) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_SUCCESS,
    ventas,
    ventasDataTable,
    totalVentas
  };
};

const loadEvolucionDeVentasMensualFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_FAIL,
    error
  };
};

export const loadVentasPorCategoriaDeCliente = (mes = 0, anio = 0, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadVentasPorCategoriaDeClienteStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorCategoriaDeCliente?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);
        const formatVentasDataTable = [];

        let totalVentas = 0;
        let totalUnidades = 0;
        response.data.forEach((venta) => {
          totalVentas += venta.monto;
          totalUnidades += venta.unidadesVendidas;
        });
        let fecha = fechaActualFunc();
        fecha = response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();
        dispatch(
          loadVentasPorCategoriaDeClienteSuccess(
            response.data,
            ventasNegativo,
            formatVentasDataTable,
            fecha,
            totalVentas,
            totalUnidades
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasPorCategoriaDeClienteFail(error));
          } else {
            dispatch(loadVentasPorCategoriaDeCliente(mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadVentasPorCategoriaDeClienteFail(error));
        }
      });
  };
};

export const clearVentasPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadVentasPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadVentasPorCategoriaDeClienteSuccess = (
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  totalVentas,
  totalUnidades
) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    ventasNegativo,
    ventasDataTable,
    fecha,
    totalVentas,
    totalUnidades
  };
};

const loadVentasPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadVentasPorClienteDeLaCategoria = (idVendedor, idCateg, mes, anio, success, failError) => {
  return (dispatch) => {
    !success && dispatch(loadVentasPorClienteDeLaCategoriaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorClienteDeLaCategoria?idCategoria=${idCateg}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          dispatch(loadVentasPorClienteDeLaCategoriaSuccess(response.data));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        if (!failError) {
          dispatch(loadVentasPorClienteDeLaCategoriaFail(error));
        } else {
          return failError(error);
        }
      });
  };
};

export const clearVentasPorClienteDeLaCategoria = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA
  };
};

const loadVentasPorClienteDeLaCategoriaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START
  };
};

const loadVentasPorClienteDeLaCategoriaSuccess = (ventas) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS,
    ventas
  };
};

const loadVentasPorClienteDeLaCategoriaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL,
    error
  };
};

export const loadVentasAlCliente = (idVendedor, idCliente, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasAlCliente?idCliente=${idCliente}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasAlCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadEvolucionVentasPorCategoriaDeCliente = (
  idVendedor,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success,
  errorFail
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorCategoriaDeClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorCategoriaDeCliente?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorCategoriaDeClienteSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail && errorFail(error);
        } else {
          if (error.response) {
            if (error.response.statusCode === 500) {
              dispatch(loadEvolucionVentasPorCategoriaDeClienteFail(error));
            } else {
              dispatch(loadEvolucionVentasPorCategoriaDeCliente());
            }
          } else {
            dispatch(loadEvolucionVentasPorCategoriaDeClienteFail(error));
          }
        }
      });
  };
};

export const clearEvolucionVentasPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadEvolucionVentasPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadEvolucionVentasPorCategoriaDeClienteSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadEvolucionVentasPorVendedores = (mes = 0, anio = 0, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorVendedoresStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorVendedor?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorVendedoresSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasPorVendedoresFail(error));
          } else {
            dispatch(loadEvolucionVentasPorVendedores());
          }
        } else {
          dispatch(loadEvolucionVentasPorVendedoresFail(error));
        }
      });
  };
};

export const clearEvolucionVentasPorVendedores = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_VENDEDORES
  };
};

const loadEvolucionVentasPorVendedoresStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_START
  };
};

const loadEvolucionVentasPorVendedoresSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorVendedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_FAIL,
    error
  };
};

export const loadEvolucionVentasPorProvincias = (
  idVendedor,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorProvinciasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorProvincia?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasPorMesPorProvincia}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorProvinciasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasPorProvinciasFail(error));
          } else {
            dispatch(loadEvolucionVentasPorProvincias());
          }
        } else {
          dispatch(loadEvolucionVentasPorProvinciasFail(error));
        }
      });
  };
};

export const clearEvolucionVentasPorProvincias = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_PROVINCIAS
  };
};

const loadEvolucionVentasPorProvinciasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_START
  };
};

const loadEvolucionVentasPorProvinciasSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorProvinciasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_FAIL,
    error
  };
};

export const loadEvolucionVentasPorZonas = (idVendedor, mes = 0, anio = 0, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorZonasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorZona?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasPorMesPorZona}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorZonasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasPorZonasFail(error));
          } else {
            dispatch(loadEvolucionVentasPorZonas());
          }
        } else {
          dispatch(loadEvolucionVentasPorZonasFail(error));
        }
      });
  };
};

export const clearEvolucionVentasPorZonas = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_ZONAS
  };
};

const loadEvolucionVentasPorZonasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_ZONAS_START
  };
};

const loadEvolucionVentasPorZonasSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_ZONAS_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorZonasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_ZONAS_FAIL,
    error
  };
};

export const loadEvolucionVentasPorMarcas = (
  idVendedor,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorMarcasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorMarca?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasPorMesPorMarca}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorMarcasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasPorMarcasFail(error));
          } else {
            dispatch(loadEvolucionVentasPorMarcas());
          }
        } else {
          dispatch(loadEvolucionVentasPorMarcasFail(error));
        }
      });
  };
};

export const clearEvolucionVentasPorMarcas = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_MARCA
  };
};

const loadEvolucionVentasPorMarcasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_MARCA_START
  };
};

const loadEvolucionVentasPorMarcasSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_MARCA_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorMarcasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_MARCA_FAIL,
    error
  };
};

export const loadEvolucionVentasPorRubros = (
  idVendedor,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorRubrosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorRubro?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasPorMesPorRubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorRubrosSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasPorRubrosFail(error));
          } else {
            dispatch(loadEvolucionVentasPorRubros());
          }
        } else {
          dispatch(loadEvolucionVentasPorRubrosFail(error));
        }
      });
  };
};

export const clearEvolucionVentasPorRubros = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_RUBRO
  };
};

const loadEvolucionVentasPorRubrosStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_RUBRO_START
  };
};

const loadEvolucionVentasPorRubrosSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_RUBRO_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorRubrosFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_RUBRO_FAIL,
    error
  };
};

export const loadEvolucionVentasPorSubrubro = (
  idVendedor,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMesPorSubrubro?idMoneda=${storageMonedaId()}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasPorMesPorSubrubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorSubrubroSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionVentasPorSubrubroFail(error));
          } else {
            dispatch(loadEvolucionVentasPorSubrubro());
          }
        } else {
          dispatch(loadEvolucionVentasPorSubrubroFail(error));
        }
      });
  };
};

export const clearEvolucionVentasPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_SUBRUBRO
  };
};

const loadEvolucionVentasPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_START
  };
};

const loadEvolucionVentasPorSubrubroSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadVentasDelMesPorVendedor = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorVendedor?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });

          const fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes);
          dispatch(
            loadVentasDelMesPorVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_VENDEDOR
  };
};

const loadVentasDelMesPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_START
  };
};

const loadVentasDelMesPorVendedorSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDelMesPorProvincia = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorProvinciaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorProvincia?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

          dispatch(
            loadVentasDelMesPorProvinciaSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorProvinciaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_PROVINCIA
  };
};

const loadVentasDelMesPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_START
  };
};

const loadVentasDelMesPorProvinciaSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_FAIL,
    error
  };
};

export const loadVentasDelMesPorZona = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorZonaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorZona?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          const fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes);

          dispatch(loadVentasDelMesPorZonaSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorZonaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorZona = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_ZONA
  };
};

const loadVentasDelMesPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_START
  };
};

const loadVentasDelMesPorZonaSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_FAIL,
    error
  };
};

export const loadVentasDelMesPorArticulo = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorArticuloStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorArticulo?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          const fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes);

          dispatch(
            loadVentasDelMesPorArticuloSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorArticuloFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorArticulo = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_ARTICULO
  };
};

const loadVentasDelMesPorArticuloStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_START
  };
};

const loadVentasDelMesPorArticuloSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorArticuloFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_FAIL,
    error
  };
};

export const loadVentasDelMesPivotGrid = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPivotGridStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPivotGrid?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let data = [];

          response &&
            response.data &&
            response.data.articulos &&
            response.data.articulos.forEach((articulo) => {
              var item = {
                zona: articulo.zona && articulo.zona.trimEnd(),
                provincia: articulo.provincia && articulo.provincia.trimEnd(),
                categoria: articulo.categoria && articulo.categoria.trimEnd(),
                cliente: articulo.cliente && articulo.cliente.trimEnd(),
                articulo: articulo.articulo && articulo.articulo.trimEnd(),
                marca: articulo.marca && articulo.marca.trimEnd(),
                rubro: articulo.rubro && articulo.rubro.trimEnd(),
                subrubro: articulo.subrubro && articulo.subrubro.trimEnd(),
                vendedor: articulo.vendedor && articulo.vendedor.trimEnd(),
                unidades: articulo.unidades,
                porcentajeUnidadesTotal: articulo.porcentajeUnidadesTotal,
                facturacion: articulo.facturacion,
                porcentajeFacturacionTotal: articulo.porcentajeFacturacionTotal,
                comprobante: {
                  numeroExterno: articulo.numeroExterno && articulo.numeroExterno.trimEnd(),
                  comprobante: articulo.comprobante && articulo.comprobante.trimEnd(),
                  referencia: articulo.referencia,
                  fecha: articulo.fecha,
                  idSistema: articulo.idSistema,
                  idSucursal: articulo.idSucursal,
                  idComproba: articulo.idComproba,
                  cobranza: articulo.cobranza
                }
              };

              data.push(item);
            });

          let totalUnidades = response.data.totalUnidades;
          let totalFacturacion = response.data.totalFacturacion;
          const fecha =
            data.length > 0
              ? new Date(response.data.articulos[0].anio, response.data.articulos[0].mes - 1, 1)
              : new Date(anio, mes);

          dispatch(loadVentasDelMesPivotGridSuccess(data, totalUnidades, totalFacturacion, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPivotGridFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPivotGrid = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_PIVOT_GRID
  };
};

const loadVentasDelMesPivotGridStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_PIVOT_GRID_START
  };
};

const loadVentasDelMesPivotGridSuccess = (articulos, totalUnidades, totalFacturacion, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_PIVOT_GRID_SUCCESS,
    articulos,
    totalUnidades,
    totalFacturacion,
    fecha
  };
};

const loadVentasDelMesPivotGridFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_PIVOT_GRID_FAIL,
    error
  };
};

export const loadVentasDelMesPorMarca = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorMarca?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorMarca}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          let fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes - 1, 1);

          dispatch(loadVentasDelMesPorMarcaSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorMarcaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorMarca = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_MARCA
  };
};

const loadVentasDelMesPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_START
  };
};

const loadVentasDelMesPorMarcaSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_FAIL,
    error
  };
};

export const loadVentasDelMesPorRubro = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorRubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorRubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

          dispatch(loadVentasDelMesPorRubroSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorRubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorRubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_RUBRO
  };
};

const loadVentasDelMesPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_START
  };
};

const loadVentasDelMesPorRubroSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_FAIL,
    error
  };
};

export const loadVentasDelMesPorSubrubro = (idVendedor, mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorSubrubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorSubrubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

          dispatch(
            loadVentasDelMesPorSubrubroSuccess(response.data, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDelMesPorSubrubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDelMesPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO
  };
};

const loadVentasDelMesPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_START
  };
};

const loadVentasDelMesPorSubrubroSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDelMesPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadVentasDelMesPorCategoriaDeCliente = (idVendedor, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorCategoriaDeCliente?mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelVendedor = (vendedorId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(seleccionVendedorVentasDelMesSelected(vendedorId));
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaPrivincia = (idVendedor, provinciaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaProvincia?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaZona = (idVendedor, zonaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaZona?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaMarca = (idVendedor, marcaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDeLaMarca?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMarca=${marcaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelRubro = (idVendedor, rubroId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelRubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idRubro=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelSubrubro = (idVendedor, subrubroId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelSubrubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idSubrubro=${subrubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorCategoriaDelCliente = (idVendedor, categoriaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorClienteDeLaCategoria?idCategoria=${categoriaId}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const seleccionDataEvolucionVentas = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_EVOLUCION_VENTAS,
    selectionId
  };
};

export const seleccionDataVentasDelMesPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_DEL_MES_POR,
    selectionId
  };
};

export const seleccionFechaVentasDelMesPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO,
    mes,
    anio
  };
};

export const seleccionVendedorVentasDelMesSelected = (vendedorId) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_VENDEDOR_ANALISIS_DE_VENTAS,
    vendedorId
  };
};

export const ventasDelMesPorArticuloDeLaMarca = (marcaId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDeLaMarca?mes=${mes}&anio=${anio}&idMarca=${marcaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const ventasDelMesPorArticuloDelRubro = (rubroId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelRubro?mes=${mes}&anio=${anio}&idRubro=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const ventasDelMesPorArticuloDelSubrubro = (subrubroId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelSubrubro?mes=${mes}&anio=${anio}&idSubrubro=${subrubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const ventasDelMesPorArticuloDeLaProvincia = (provinciaId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaProvincia?mes=${mes}&anio=${anio}&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const ventasDelMesPorArticuloDeLaZona = (zonaId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaZona?mes=${mes}&anio=${anio}&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const ventasDelMesPorArticuloDelVendedor = (vendedorId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelArticuloPorCliente = (idVendedor, idArticlo, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelArticuloPorCliente?mes=${mes}&anio=${anio}&idArticulo=${idArticlo}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelArticuloPorCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelArticuloAlCliente = (idVendedor, idArticlo, idCliente, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    const formatMes = parseInt(mes, 10);

    axios
      .get(
        `/ventas/VentasDelArticuloAlCliente?mes=${formatMes}&anio=${anio}&idArticulo=${idArticlo}&idCliente=${idCliente}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelArticuloAlCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelCliente = (idVendedor, idCliente, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorArticuloDelCliente?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idCliente=${idCliente.trim()}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorClienteEnLaZona = (idVendedor, zonaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorClienteEnLaZona?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorClienteEnLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorClienteEnLaProvincia = (idVendedor, provinciaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorClienteEnLaProvincia?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorClienteDeLaProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadDetalleComprobante = (
  idSucursal,
  idSistema,
  idComproba,
  nroReferencia,
  success,
  errorFail,
  cobranza = false
) => {
  return (dispatch) => {
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/DetalleComprobante?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&idMoneda=${idMoneda}
        &idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasAlCliente}&cobranza=${cobranza}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

//#region DEUDAS
export const loadDeudaDelMesPorOrdenamiento = (
  ordenamiento,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  idUsuario = storageUsuarioId()
) => {
  return (dispatch) => {
    dispatch(loadDeudaDelMesPorOrdenamientoStart(ordenamiento));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/DeudaDelMes?idMoneda=${storageMonedaId()}&idUsuario=${idUsuario}&ordenamiento=${ordenamiento}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const deudasNegativo = response.data.deudasReducidas.filter((rd) => rd.monto < 0);

        var formatDeudasDataTable = [];

        if (response.data.deudasCompletas !== null && response.data.deudasCompletas !== 'undefined')
          formatDeudasDataTable = response.data.deudasCompletas;

        let totalDeudas = 0;

        response.data.deudasReducidas.map((venta) => (totalDeudas += venta.monto));
        let fecha = fechaActualFunc();

        fecha =
          response.data.deudasReducidas.length > 0
            ? new Date(response.data.deudasReducidas[0].anio, response.data.deudasReducidas[0].mes - 1)
            : new Date();

        dispatch(
          loadDeudaDelMesPorOrdenamientoSuccess(
            response.data.deudasReducidas,
            deudasNegativo,
            formatDeudasDataTable,
            fecha,
            totalDeudas,
            ordenamiento,
            response.data.orden
          )
        );
      })
      .catch((error) => {
        dispatch(loadDeudaDelMesPorOrdenamientoFail(error, ordenamiento));
      });
  };
};

export const clearDeudaDelMesPorOrdenamiento = (ordenamiento) => {
  return {
    type: actionTypes.CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO,
    ordenamiento
  };
};

export const ejecucionDeudaDelMesPorOrdenamiento = (ejecucion) => {
  return {
    type: actionTypes.EJECUCION_DEUDA_DEL_MES_POR_ORDENAMIENTO,
    ejecucion
  };
};

const loadDeudaDelMesPorOrdenamientoStart = (ordenamiento) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START,
    ordenamiento
  };
};

const loadDeudaDelMesPorOrdenamientoSuccess = (
  deudas,
  deudasNegativo,
  deudasDataTable,
  fecha,
  totalDeudas,
  ordenamiento,
  orden
) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS,
    deudas,
    deudasNegativo,
    deudasDataTable,
    fecha,
    totalDeudas,
    ordenamiento,
    orden
  };
};

const loadDeudaDelMesPorOrdenamientoFail = (error, ordenamiento) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL,
    error,
    ordenamiento
  };
};
//#endregion

//#region COBRANZAS DEL DÍA
export const cobranzasDelDia = (idEmpresa = storageEmpresaId(), evolucion) => {
  return (dispatch) => {
    dispatch(cobranzasDelDiaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/CobranzasDelDia?idMoneda=${storageMonedaId()}&evolucion=${evolucion}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.cobranzasDelDia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(
          cobranzasDelDiaSuccess(Math.abs(response.data.montoDia), Math.abs(response.data.promedioUltimoPeriodo))
        );
        dispatch(cobranzasDelDiaRequest(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(cobranzasDelDiaError(error));
          } else {
            dispatch(cobranzasDelDiaSuccess(idEmpresa));
          }
        } else {
          dispatch(cobranzasDelDiaError(error));
        }
      });
  };
};

export const clearCobranzasDelDia = () => {
  return {
    type: actionTypes.CLEAR_COBRANZAS_DEL_DIA
  };
};

const cobranzasDelDiaStart = () => {
  return {
    type: actionTypes.COBRANZAS_DEL_DIA_START
  };
};

const cobranzasDelDiaSuccess = (diaActual, promedio30Dias) => {
  return {
    type: actionTypes.COBRANZAS_DEL_DIA_SUCCESS,
    diaActual: diaActual,
    promedio30Dias: promedio30Dias
  };
};

const cobranzasDelDiaError = (error) => {
  return {
    type: actionTypes.COBRANZAS_DEL_DIA_FAIL,
    error
  };
};

const cobranzasDelDiaRequest = (loadingRequest) => {
  return {
    type: actionTypes.COBRANZAS_DEL_DIA_REQUEST,
    loadingRequest
  };
};

//#endregion

//#region DETALLE COBRANZAS DEL DÍA
export const detalleCobranzasDelDia = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(detalleCobranzasDelDiaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/detalleCobranzasDelDia?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.cobranzasDelDia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(detalleCobranzasDelDiaSuccess(response.data.cobranzas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(detalleCobranzasDelDiaError(error));
          } else {
            dispatch(detalleCobranzasDelDiaSuccess(idEmpresa));
          }
        } else {
          dispatch(detalleCobranzasDelDiaError(error));
        }
      });
  };
};

export const clearDetalleCobranzasDelDia = () => {
  return {
    type: actionTypes.CLEAR_DETALLE_COBRANZAS_DEL_DIA
  };
};

const detalleCobranzasDelDiaStart = () => {
  return {
    type: actionTypes.DETALLE_COBRANZAS_DEL_DIA_START
  };
};

const detalleCobranzasDelDiaSuccess = (cobranzas) => {
  return {
    type: actionTypes.DETALLE_COBRANZAS_DEL_DIA_SUCCESS,
    cobranzas: cobranzas
  };
};

const detalleCobranzasDelDiaError = (error) => {
  return {
    type: actionTypes.DETALLE_COBRANZAS_DEL_DIA_FAIL,
    error
  };
};
//#endregion

//#region CHEQUES DEPOSITADOS DEL DÍA
export const chequesDepositadosDelDia = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(chequesDepositadosDelDiaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesDepositadosDelDia?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.chequesRechazados
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(chequesDepositadosDelDiaSuccess(response.data.monto, response.data.cantidadCheques));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(chequesDepositadosDelDiaError(error));
          } else {
            dispatch(chequesDepositadosDelDiaSuccess(idEmpresa));
          }
        } else {
          dispatch(chequesDepositadosDelDiaError(error));
        }
      });
  };
};

export const clearChequesDepositadosDelDia = () => {
  return {
    type: actionTypes.CLEAR_CHEQUES_DEPOSITADOS_DEL_DIA
  };
};

const chequesDepositadosDelDiaStart = () => {
  return {
    type: actionTypes.CHEQUES_DEPOSITADOS_DEL_DIA_START
  };
};

const chequesDepositadosDelDiaSuccess = (monto, cantidadCheques) => {
  return {
    type: actionTypes.CHEQUES_DEPOSITADOS_DEL_DIA_SUCCESS,
    monto: monto,
    cantidadCheques: cantidadCheques
  };
};

const chequesDepositadosDelDiaError = (error) => {
  return {
    type: actionTypes.CHEQUES_DEPOSITADOS_DEL_DIA_FAIL,
    error
  };
};
//#endregion

//#region NOTAS DE PEDIDO
export const totalNotasDePedido = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(totalNotasDePedidoStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/PedidosPendientesConConversion?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.chequesRechazados
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(
          totalNotasDePedidoSuccess(response.data.total, response.data.totalConvertido, response.data.monedaSecundaria)
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(totalNotasDePedidoError(error));
          } else {
            dispatch(totalNotasDePedidoSuccess(idEmpresa));
          }
        } else {
          dispatch(totalNotasDePedidoError(error));
        }
      });
  };
};

export const clearTotalNotasDePedido = () => {
  return {
    type: actionTypes.CLEAR_TOTAL_NOTAS_DE_PEDIDO
  };
};

const totalNotasDePedidoStart = () => {
  return {
    type: actionTypes.TOTAL_NOTAS_DE_PEDIDO_START
  };
};

const totalNotasDePedidoSuccess = (total, totalConvertido, monedaSecundaria) => {
  return {
    type: actionTypes.TOTAL_NOTAS_DE_PEDIDO_SUCCESS,
    total: total,
    totalConvertido: totalConvertido,
    monedaSecundaria: monedaSecundaria
  };
};

const totalNotasDePedidoError = (error) => {
  return {
    type: actionTypes.TOTAL_NOTAS_DE_PEDIDO_FAIL,
    error
  };
};
//#endregion

//#region DETALLE NOTAS DE PEDIDO
export const detalleNotasDePedido = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(detalleNotasDePedidoStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/DetalleNotasDePedido?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.chequesRechazados
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(detalleNotasDePedidoSuccess(response.data.notasDePedido));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(detalleNotasDePedidoError(error));
          } else {
            dispatch(detalleNotasDePedidoSuccess(idEmpresa));
          }
        } else {
          dispatch(detalleNotasDePedidoError(error));
        }
      });
  };
};

export const clearDetalleNotasDePedido = () => {
  return {
    type: actionTypes.CLEAR_DETALLE_NOTAS_DE_PEDIDO
  };
};

const detalleNotasDePedidoStart = () => {
  return {
    type: actionTypes.DETALLE_NOTAS_DE_PEDIDO_START
  };
};

const detalleNotasDePedidoSuccess = (notasDePedido) => {
  return {
    type: actionTypes.DETALLE_NOTAS_DE_PEDIDO_SUCCESS,
    notasDePedido: notasDePedido
  };
};

const detalleNotasDePedidoError = (error) => {
  return {
    type: actionTypes.DETALLE_NOTAS_DE_PEDIDO_FAIL,
    error
  };
};
//#endregion

//#region NOTAS DE PEDIDO PENDIENTES
export const loadPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadPedidosPendientesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/PedidosPendientes?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.cantidadPedidosPendientes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { cantidad, total, promedio30Dias } = response.data;
        dispatch(loadPedidosPendientesSuccess(cantidad, total, promedio30Dias));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosPendientesFail(error));
          } else {
            dispatch(loadPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadPedidosPendientesFail(error));
        }
      });
  };
};

export const clearPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_PENDIENTES
  };
};

const loadPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_START
  };
};

const loadPedidosPendientesSuccess = (cantidad, total, prom30Dias) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_SUCCESS,
    cantidad,
    total,
    prom30Dias
  };
};

const loadPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_FAIL,
    error
  };
};
//#endregion

//#region PEDIDOS DEL MES
export const loadPedidosMesComparativo = (idCliente) => {
  return (dispatch) => {
    dispatch(loadPedidosMesComparativoStart());
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .get(
        `/ventas/PedidosDelMesComparativo?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.pedidosDelMesComparativo
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const {
          transaccionesMesAnterior,
          totalMesAnterior,
          transaccionesMesActual,
          totalMesActual,
          porcentajeMesActual,
          porcentajeTotal
        } = response.data;
        dispatch(
          loadPedidosMesComparativoSuccess(
            transaccionesMesAnterior,
            totalMesAnterior,
            transaccionesMesActual,
            totalMesActual,
            porcentajeMesActual,
            porcentajeTotal
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosMesComparativoFail(error));
          } else {
            dispatch(loadPedidosMesComparativo(idCliente));
          }
        } else {
          dispatch(loadPedidosMesComparativoFail(error));
        }
      });
  };
};

export const clearPedidosMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO
  };
};

const loadPedidosMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_START
  };
};

const loadPedidosMesComparativoSuccess = (transMesAnt, totalMesAnt, transMesAct, totalMesAct, porcTrans, porcTotal) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS,
    transMesAnt,
    totalMesAnt,
    transMesAct,
    totalMesAct,
    porcTrans,
    porcTotal
  };
};

const loadPedidosMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_FAIL,
    error
  };
};
//#endregion

//#region ESTADO PEDIDOS PENDIENTES
export const loadEstadosPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadEstadosPedidosPendientesStart());
    let total = 0;

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/EstadosPedidosPendientes?&idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.estadosPedidosPendientes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        response.data.map((pedido) => {
          return (total += pedido.monto);
        });
        dispatch(loadEstadosPedidosPendientesSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadEstadosPedidosPendientesFail(error));
          } else {
            dispatch(loadEstadosPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadEstadosPedidosPendientesFail(error));
        }
      });
  };
};

export const clearEstadosPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES
  };
};

const loadEstadosPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_START
  };
};

const loadEstadosPedidosPendientesSuccess = (estadosPedidosPendientes, total) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS,
    estadosPedidosPendientes,
    total
  };
};

const loadEstadosPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL,
    error
  };
};
//#endregion

//#region COMPROBANTES PEDIDOS PENDIENTES
export const loadComprobantesPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadComprobantesPedidosPendientesStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/comprobantesPedidosPendientes?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.comprobantesPedidosPendientes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesPedidosPendientesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesPedidosPendientesFail(error));
          } else {
            dispatch(loadComprobantesPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadComprobantesPedidosPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES
  };
};

const loadComprobantesPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START
  };
};

const loadComprobantesPedidosPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS,
    comprobantes
  };
};

const loadComprobantesPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL,
    error
  };
};
//#endregion

//#region VENTAS POR VENDEDOR
export const loadVentasPorVendedor = (idCliente) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/ventasPorVendedor?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.cantidadPedidosPendientes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { unidadesVendidas, ventas, cantidad } = response.data;

        dispatch(loadVentasPorVendedorSuccess(unidadesVendidas, ventas, cantidad));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVentasPorVendedorFail(error));
          } else {
            dispatch(loadVentasPorVendedor(idCliente));
          }
        } else {
          dispatch(loadVentasPorVendedorFail(error));
        }
      });
  };
};

export const clearVentasPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR
  };
};

const loadVentasPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_START
  };
};

const loadVentasPorVendedorSuccess = (unidadesVendidas, ventas, cantidad) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_SUCCESS,
    unidadesVendidas,
    ventas,
    cantidad
  };
};

const loadVentasPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_FAIL,
    error
  };
};
//#endregion

//#region COMPROBANTES FACTURAS PENDIENTES
export const loadComprobantesFacturasPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadComprobantesFacturasPendientesStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/comprobantesFacturasPendientes?idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.comprobantesFacturasPendientes
        }&idCliente=${idCliente}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesFacturasPendientesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesFacturasPendientesFail(error));
          } else {
            dispatch(loadComprobantesFacturasPendientes(idCliente));
          }
        } else {
          dispatch(loadComprobantesFacturasPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES
  };
};

const loadComprobantesFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START
  };
};

const loadComprobantesFacturasPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS,
    comprobantes
  };
};

const loadComprobantesFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL,
    error
  };
};
//#endregion

//#region VENTAS DEL MES A UN CLIENTE
export const ventasDelMesAUnCliente = (idCliente, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(ventasDelMesAUnClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesEnteroComparativo?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(ventasDelMesAUnClienteSuccess(response.data.montoMesActual, response.data.montoMesAnterior));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(ventasDelMesAUnClienteFail(error));
          } else {
            dispatch(ventasDelMesAUnCliente(idCliente, idEmpresa));
          }
        } else {
          dispatch(ventasDelMesAUnClienteFail(error));
        }
      });
  };
};

export const clearVentasDelMesAUnCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_A_UN_CLIENTE
  };
};

const ventasDelMesAUnClienteStart = () => {
  return {
    type: actionTypes.VENTAS_DEL_MES_A_UN_CLIENTE_START
  };
};

const ventasDelMesAUnClienteSuccess = (mesActual, mesAnterior) => {
  return {
    type: actionTypes.VENTAS_DEL_MES_A_UN_CLIENTE_SUCCESS,
    mesActual: mesActual,
    mesAnterior: mesAnterior
  };
};

const ventasDelMesAUnClienteFail = (error) => {
  return {
    type: actionTypes.VENTAS_DEL_MES_A_UN_CLIENTE_FAIL,
    error
  };
};
//#endregion

//#region UTILIDAD
export const seleccionDataUtilidadDelMesPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_UTILIDAD_DEL_MES_POR,
    selectionId
  };
};

export const seleccionFechaUtilidadDelMesPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_UTILIDAD_DEL_MES_ANIO,
    mes,
    anio
  };
};

export const loadEvolucionDeUtilidadMensual = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeUtilidadMensualStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMes?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.utilidadPorMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatUtilidadParaTabla = [];
        let totalUtilidad = 0;
        response.data.map((venta) => {
          if (!formatUtilidadParaTabla.hasOwnProperty(venta.anio)) {
            formatUtilidadParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatUtilidadParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalUtilidad += venta.monto);
        });
        dispatch(loadEvolucionDeUtilidadMensualSuccess(response.data, formatUtilidadParaTabla, totalUtilidad));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeUtilidadMensualFail(error));
          } else {
            dispatch(loadEvolucionDeUtilidadMensual(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeUtilidadMensualFail(error));
        }
      });
  };
};

export const clearEvolucionDeUtilidadMensual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_UTILIDAD
  };
};

const loadEvolucionDeUtilidadMensualStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_UTILIDAD_START
  };
};

const loadEvolucionDeUtilidadMensualSuccess = (utilidades, utilidadesDataTable, totalUtilidad) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_UTILIDAD_SUCCESS,
    utilidades,
    utilidadesDataTable,
    totalUtilidad
  };
};

const loadEvolucionDeUtilidadMensualFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_UTILIDAD_FAIL,
    error
  };
};

export const loadUtilidadPorCategoriaDeCliente = (
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadPorCategoriaDeClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorCategoriaDeCliente?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.utilidadDelMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const utilidadesNegativo = response.data.filter((rd) => rd.monto < 0);
        const formatUtilidadDataTable = [];

        let totalUtilidad = 0;
        response.data.map((venta) => (totalUtilidad += venta.monto));
        let fecha = fechaActualFunc();
        fecha = response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

        !success &&
          dispatch(
            loadUtilidadPorCategoriaDeClienteSuccess(
              response.data,
              utilidadesNegativo,
              formatUtilidadDataTable,
              fecha,
              totalUtilidad
            )
          );
        return success && success(response.data);
      })
      .catch((error) => {
        dispatch(loadUtilidadPorCategoriaDeClienteFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadUtilidadPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadUtilidadPorCategoriaDeClienteSuccess = (
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  fecha,
  totalUtilidad
) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    utilidades,
    utilidadesNegativo,
    utilidadesDataTable,
    fecha,
    totalUtilidad
  };
};

const loadUtilidadPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadUtilidadDelMesPorMarca = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadDelMesPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMarca?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.utilidadDelMesPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalUtilidad = 0;
          let utilidadesNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalUtilidad += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes);

          dispatch(loadUtilidadDelMesPorMarcaSuccess(response.data, utilidadesNegativo, totalUtilidad, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadUtilidadDelMesPorMarcaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadDelMesPorMarca = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_MARCA
  };
};

const loadUtilidadDelMesPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_MARCA_START
  };
};

const loadUtilidadDelMesPorMarcaSuccess = (utilidades, utilidadesNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_MARCA_SUCCESS,
    utilidades,
    utilidadesNegativo,
    total,
    fecha
  };
};

const loadUtilidadDelMesPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_MARCA_FAIL,
    error
  };
};

export const loadUtilidadDelMesPorRubro = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadDelMesPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorRubro?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.utilidadDelMesPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalUtilidad = 0;
          let utilidadesNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalUtilidad += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

          dispatch(loadUtilidadDelMesPorRubroSuccess(response.data, utilidadesNegativo, totalUtilidad, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadUtilidadDelMesPorRubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadDelMesPorRubro = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_RUBRO
  };
};

const loadUtilidadDelMesPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_RUBRO_START
  };
};

const loadUtilidadDelMesPorRubroSuccess = (utilidades, utilidadesNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_RUBRO_SUCCESS,
    utilidades,
    utilidadesNegativo,
    total,
    fecha
  };
};

const loadUtilidadDelMesPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_RUBRO_FAIL,
    error
  };
};

export const loadUtilidadDelMesPorSubrubro = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadDelMesPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorSubrubro?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.utilidadDelMesPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalUtilidad = 0;
          let utilidadesNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalUtilidad += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

          dispatch(loadUtilidadDelMesPorSubrubroSuccess(response.data, utilidadesNegativo, totalUtilidad, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadUtilidadDelMesPorSubrubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadDelMesPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_SUBRUBRO
  };
};

const loadUtilidadDelMesPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_START
  };
};

const loadUtilidadDelMesPorSubrubroSuccess = (utilidades, utilidadesNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_SUCCESS,
    utilidades,
    utilidadesNegativo,
    total,
    fecha
  };
};

const loadUtilidadDelMesPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadUtilidadDelMesPorVendedor = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadDelMesPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorVendedor?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.utilidadDelMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalUtilidad = 0;
          let utilidadesNegativo = response.data.filter((utilidad) => utilidad.monto < 0);
          response.data.map((utilidad) => (totalUtilidad += utilidad.monto));

          const fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes);
          dispatch(loadUtilidadDelMesPorVendedorSuccess(response.data, utilidadesNegativo, totalUtilidad, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadUtilidadDelMesPorVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadDelMesPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_VENDEDOR
  };
};

const loadUtilidadDelMesPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_START
  };
};

const loadUtilidadDelMesPorVendedorSuccess = (utilidades, utilidadesNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_SUCCESS,
    utilidades,
    utilidadesNegativo,
    total,
    fecha
  };
};

const loadUtilidadDelMesPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_FAIL,
    error
  };
};

export const loadUtilidadDelMesPorProvincia = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadDelMesPorProvinciaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorProvincia?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.utilidadDelMesPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalUtilidad = 0;
          let utilidadesNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalUtilidad += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes - 1, 1) : new Date();

          dispatch(loadUtilidadDelMesPorProvinciaSuccess(response.data, utilidadesNegativo, totalUtilidad, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadUtilidadDelMesPorProvinciaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadDelMesPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_PROVINCIA
  };
};

const loadUtilidadDelMesPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_START
  };
};

const loadUtilidadDelMesPorProvinciaSuccess = (utilidades, utilidadesNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_SUCCESS,
    utilidades,
    utilidadesNegativo,
    total,
    fecha
  };
};

const loadUtilidadDelMesPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_FAIL,
    error
  };
};

export const loadUtilidadDelMesPorZona = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadUtilidadDelMesPorZonaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorZona?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.utilidadDelMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalUtilidad = 0;
          let utilidadesNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalUtilidad += venta.monto));
          const fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, mes);

          dispatch(loadUtilidadDelMesPorZonaSuccess(response.data, utilidadesNegativo, totalUtilidad, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadUtilidadDelMesPorZonaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearUtilidadDelMesPorZona = () => {
  return {
    type: actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_ZONA
  };
};

const loadUtilidadDelMesPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_ZONA_START
  };
};

const loadUtilidadDelMesPorZonaSuccess = (utilidades, utilidadesNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_ZONA_SUCCESS,
    utilidades,
    utilidadesNegativo,
    total,
    fecha
  };
};

const loadUtilidadDelMesPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_UTILIDAD_DEL_MES_POR_ZONA_FAIL,
    error
  };
};

export const loadUtilidadPorClienteDeLaCategoria = (idVendedor, idCategoria, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorClienteDeLaCategoriaDeCliente?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idCategoria=${idCategoria}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadPorClienteDelVendedor = (mes, anio, idVendedor, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorClienteDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadPorClienteDeLaZona = (idVendedor, mes, anio, idZona, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorClienteDeLaZona?mes=${mes}&anio=${anio}&${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idZona=${idZona}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadPorClienteDeLaProvincia = (idVendedor, idProvincia, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorClienteDeLaProvincia?mes=${mes}&anio=${anio}&${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idProvincia=${idProvincia}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadPorArticuloDeLaMarca = (idVendedor, idMarca, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadDelMesPorArticuloDeLaMarca?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMarca=${idMarca}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadPorArticuloDelRubro = (idVendedor, idRubro, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadDelMesPorArticuloDelRubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idRubro=${idRubro}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadPorArticuloDelSubrubro = (idVendedor, idSubrubro, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadDelMesPorArticuloDelSubrubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idSubrubro=${idSubrubro}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadDelClientePorArticulo = (idVendedor, idCliente, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadDelClientePorArticulo?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadDelArticuloPorCliente = (idVendedor, idArticulo, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadDelArticuloPorCliente?&mes=${mes}&anio=${anio}&${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idArticulo=${idArticulo}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadUtilidadDelArticuloAlCliente = (idVendedor, idArticulo, idCliente, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadDelArticuloAlCliente?mes=${mes}&anio=${anio}&idArticulo=${idArticulo}&idCliente=${idCliente}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasAlCliente}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadEvolucionUtilidadPorCategoriaDeCliente = (
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  success,
  errorFail
) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorCategoriaDeClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorCategoriaDeCliente?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionGastosPorCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorCategoriaDeClienteSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail && errorFail(error);
        } else {
          if (error.response) {
            if (error.response.statusCode === 500) {
              dispatch(loadEvolucionUtilidadPorCategoriaDeClienteFail(error));
            } else {
              dispatch(loadEvolucionUtilidadPorCategoriaDeCliente(mes, anio, idEmpresa, success));
            }
          } else {
            dispatch(loadEvolucionUtilidadPorCategoriaDeClienteFail(error));
          }
        }
      });
  };
};

export const clearEvolucionUtilidadPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadEvolucionUtilidadPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadEvolucionUtilidadPorCategoriaDeClienteSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadEvolucionUtilidadPorVendedores = (mes, anio, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorVendedoresStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorVendedor?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorVendedoresSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionUtilidadPorVendedoresFail(error));
          } else {
            dispatch(loadEvolucionUtilidadPorVendedores(mes, anio, idEmpresa, success));
          }
        } else {
          dispatch(loadEvolucionUtilidadPorVendedoresFail(error));
        }
      });
  };
};

export const clearEvolucionUtilidadPorVendedores = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_VENDEDORES
  };
};

const loadEvolucionUtilidadPorVendedoresStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_START
  };
};

const loadEvolucionUtilidadPorVendedoresSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorVendedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_FAIL,
    error
  };
};

export const loadEvolucionUtilidadPorProvincias = (mes, anio, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorProvinciasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorProvincia?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorProvinciasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionUtilidadPorProvinciasFail(error));
          } else {
            dispatch(loadEvolucionUtilidadPorProvincias(mes, anio, idEmpresa, success));
          }
        } else {
          dispatch(loadEvolucionUtilidadPorProvinciasFail(error));
        }
      });
  };
};

export const clearEvolucionUtilidadPorProvincias = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_PROVINCIAS
  };
};

const loadEvolucionUtilidadPorProvinciasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_START
  };
};

const loadEvolucionUtilidadPorProvinciasSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorProvinciasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_FAIL,
    error
  };
};

export const loadEvolucionUtilidadPorZonas = (mes, anio, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorZonasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorZona?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorZonasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionUtilidadPorZonasFail(error));
          } else {
            dispatch(loadEvolucionUtilidadPorZonas(mes, anio, idEmpresa, success));
          }
        } else {
          dispatch(loadEvolucionUtilidadPorZonasFail(error));
        }
      });
  };
};

export const clearEvolucionUtilidadPorZonas = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_ZONAS
  };
};

const loadEvolucionUtilidadPorZonasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_START
  };
};

const loadEvolucionUtilidadPorZonasSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorZonasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_FAIL,
    error
  };
};

export const loadEvolucionUtilidadPorMarcas = (mes, anio, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorMarcasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorMarca?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorMarcasSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionUtilidadPorMarcasFail(error));
          } else {
            dispatch(loadEvolucionUtilidadPorMarcas(mes, anio, idEmpresa, success));
          }
        } else {
          dispatch(loadEvolucionUtilidadPorMarcasFail(error));
        }
      });
  };
};

export const clearEvolucionUtilidadPorMarcas = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_MARCA
  };
};

const loadEvolucionUtilidadPorMarcasStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_MARCA_START
  };
};

const loadEvolucionUtilidadPorMarcasSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_MARCA_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorMarcasFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_MARCA_FAIL,
    error
  };
};

export const loadEvolucionUtilidadPorRubros = (mes, anio, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorRubrosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorRubro?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorRubrosSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionUtilidadPorRubrosFail(error));
          } else {
            dispatch(loadEvolucionUtilidadPorRubros(mes, anio, idEmpresa, success));
          }
        } else {
          dispatch(loadEvolucionUtilidadPorRubrosFail(error));
        }
      });
  };
};

export const clearEvolucionUtilidadPorRubros = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_RUBRO
  };
};

const loadEvolucionUtilidadPorRubrosStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_START
  };
};

const loadEvolucionUtilidadPorRubrosSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorRubrosFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_FAIL,
    error
  };
};

export const loadEvolucionUtilidadPorSubrubro = (mes, anio, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionUtilidadPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMesPorSubrubro?idMoneda=${storageMonedaId()}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((utilidad, index) =>
          utilidad.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionUtilidadPorSubrubroSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionUtilidadPorSubrubroFail(error));
          } else {
            dispatch(loadEvolucionUtilidadPorSubrubro(mes, anio, idEmpresa, success));
          }
        } else {
          dispatch(loadEvolucionUtilidadPorSubrubroFail(error));
        }
      });
  };
};

export const clearEvolucionUtilidadPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_SUBRUBRO
  };
};

const loadEvolucionUtilidadPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_START
  };
};

const loadEvolucionUtilidadPorSubrubroSuccess = (utilidades, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_SUCCESS,
    utilidades,
    fechas,
    total
  };
};

const loadEvolucionUtilidadPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_FAIL,
    error
  };
};
//#endregion UTILIDAD

//#region ENVIAR GRAFICOS

export const enviarGraficosPorMail = (data, success) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: authStr
    };

    axios
      .post(`/notificaciones/EnviarGraficosPorMail`, data, {
        headers: headers
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Gráficos enviados por mail con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );

        return success && success();
      })
      .catch((error) => {
        let errorMessage = `Error al enviar los gráficos por mail, intente nuevamente.`;
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

//#endregion

//#region CHEQUES EN CARTERA
export const loadChequesEnCartera = (idCliente) => {
  return (dispatch) => {
    dispatch(loadChequesEnCarteraStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/cheques/ChequesEnCarteraTotal?idCliente=${idCliente}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.chequesEnCartera
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let monto = 0;
        let cantidadCheques = 0;
        response.data.forEach((cheque) => {
          monto += cheque.monto;
          cantidadCheques += cheque.cantidadCheques;
        });
        if (response.status === 204) {
          dispatch(loadChequesEnCarteraSuccess(0, 0));
        } else {
          dispatch(loadChequesEnCarteraSuccess(monto, cantidadCheques));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadChequesEnCarteraFail(error));
          } else {
            dispatch(loadChequesEnCartera(idCliente));
          }
        } else {
          dispatch(loadChequesEnCarteraFail(error));
        }
      });
  };
};

export const clearChequesEnCartera = () => {
  return {
    type: actionTypes.CLEAR_CHEQUES_EN_CARTERA
  };
};

const loadChequesEnCarteraStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_START
  };
};

const loadChequesEnCarteraSuccess = (monto, cantidadCheques) => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_SUCCESS,
    monto,
    cantidadCheques
  };
};

const loadChequesEnCarteraFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_FAIL,
    error
  };
};
//#endregion

//#region FACTURAS PENDIENTES
export const loadFacturasPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadFacturasPendientesStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/DeudoresPorVentas?idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.facturasPendientes
        }&idCliente=${idCliente}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const deudas = response.data;
        let totalDeuda = 0;
        response.data.map((deuda) => (totalDeuda += deuda.value));
        dispatch(loadFacturasPendientesSuccess(deudas, totalDeuda));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadFacturasPendientesFail(error));
          } else {
            dispatch(loadFacturasPendientes(idCliente));
          }
        } else {
          dispatch(loadFacturasPendientesFail(error));
        }
      });
  };
};

export const clearFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_PENDIENTES
  };
};

const loadFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_START
  };
};

const loadFacturasPendientesSuccess = (facturas, totalFacturas) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_SUCCESS,
    facturas,
    totalFacturas
  };
};

const loadFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_FAIL,
    error
  };
};
//#endregion

//#region VENTAS INTERANUAL

export const loadEvolucionDeVentasMensualInteranual = (
  mes,
  anio,
  onSuccess,
  onFail,
  idEmpresa = storageEmpresaId()
) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeVentasMensualInteranualStart(anio));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorMes?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDesglosadasPorMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalVentas += venta.monto);
        });
        dispatch(
          loadEvolucionDeVentasMensualInteranualSuccess(anio, response.data, formatVentasParaTabla, totalVentas)
        );
        onSuccess(response.data, formatVentasParaTabla, totalVentas);
      })
      .catch((error) => {
        onFail();
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeVentasMensualInteranualFail(anio, error));
          } else {
            dispatch(loadEvolucionDeVentasMensualInteranual(mes, anio, onSuccess, onFail, idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeVentasMensualInteranualFail(anio, error));
        }
      });
  };
};

export const agregarEvolucionDeVentasMensualInteranual = (periodo) => {
  return {
    type: actionTypes.AGREGAR_EVOLUCION_DE_VENTAS_INTERANUAL,
    periodo
  };
};

export const clearEvolucionDeVentasMensualInteranual = (periodo) => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_VENTAS_INTERANUAL,
    periodo
  };
};

export const clearEvolucionesDeVentasMensualInteranual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCIONES_DE_VENTAS_INTERANUAL
  };
};

export const updateFiltroEvolucionesDeVentasMensualInteranual = (desde, hasta, aplicado = true) => {
  return {
    type: actionTypes.UPDATE_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL,
    desde,
    hasta,
    aplicado
  };
};

export const clearFiltroEvolucionesDeVentasMensualInteranual = () => {
  return {
    type: actionTypes.CLEAR_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL
  };
};

const loadEvolucionDeVentasMensualInteranualStart = (anio) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_START,
    anio
  };
};

const loadEvolucionDeVentasMensualInteranualSuccess = (anio, ventas, ventasDataTable, totalVentas, totalUnidades) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_SUCCESS,
    anio,
    ventas,
    ventasDataTable,
    totalVentas,
    totalUnidades
  };
};

const loadEvolucionDeVentasMensualInteranualFail = (anio, error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_FAIL,
    anio,
    error
  };
};

//#endregion

//#region UTILIDADES INTERANUAL
export const loadEvolucionDeUtilidadMensualInteranual = (
  mes,
  anio,
  onSuccess,
  onFail,
  idEmpresa = storageEmpresaId()
) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeUtilidadMensualInteranualStart(anio));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/UtilidadPorMes?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.utilidadPorMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatUtilidadParaTabla = [];
        let totalUtilidad = 0;
        response.data.map((venta) => {
          if (!formatUtilidadParaTabla.hasOwnProperty(venta.anio)) {
            formatUtilidadParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatUtilidadParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalUtilidad += venta.monto);
        });
        dispatch(
          loadEvolucionDeUtilidadMensualInteranualSuccess(anio, response.data, formatUtilidadParaTabla, totalUtilidad)
        );
        onSuccess(response.data, formatUtilidadParaTabla, totalUtilidad);
      })
      .catch((error) => {
        onFail();
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeUtilidadMensualInteranualFail(anio, error));
          } else {
            dispatch(loadEvolucionDeUtilidadMensualInteranual(mes, anio, onSuccess, onFail, idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeUtilidadMensualInteranualFail(anio, error));
        }
      });
  };
};

export const clearEvolucionDeUtilidadMensualInteranual = (periodo) => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_UTILIDADES_INTERANUAL,
    periodo
  };
};

export const clearEvolucionesDeUtilidadMensualInteranual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCIONES_DE_UTILIDADES_INTERANUAL
  };
};

export const agregarEvolucionDeUtilidadMensualInteranual = (periodo) => {
  return {
    type: actionTypes.AGREGAR_EVOLUCION_DE_UTILIDADES_INTERANUAL,
    periodo
  };
};

const loadEvolucionDeUtilidadMensualInteranualStart = (anio) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_START,
    anio
  };
};

const loadEvolucionDeUtilidadMensualInteranualSuccess = (anio, utilidades, utilidadesDataTable, totalUtilidad) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_SUCCESS,
    anio,
    utilidades,
    utilidadesDataTable,
    totalUtilidad
  };
};

const loadEvolucionDeUtilidadMensualInteranualFail = (anio, error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_FAIL,
    anio,
    error
  };
};

//#endregion

//#region VENTAS POR PERIODOS
export const seleccionDataEvolucionVentasPorPeriodos = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_EVOLUCION_VENTAS_POR_PERIODOS,
    selectionId
  };
};

export const seleccionDataVentasPorPeriodosPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_POR_PERIODOS_POR,
    selectionId
  };
};

export const seleccionDataVentasPorMesPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_POR_MES_POR,
    selectionId
  };
};

//#region Ventas Desagregadas del Periodo por Tipo de Venta

export const loadDesagregadasPorPeriodosPorTipoPorId = (
  id,
  tipoParent,
  tipoChild,
  idVendedor,
  desde,
  hasta,
  anio,
  filtrosParent,
  success,
  errorFail
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `/ventas/VentasDesagregadasPorPeriodosPorTipoPorId?tipoParent=${tipoParent}&tipoChild=${tipoChild}&idParent=${id}&mesDesde=${desde}&mesHasta=${hasta}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDeLaMarca
        }`,
        filtrosParent,
        { headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadDesagregadasPorPeriodosPorComprobantesPorTipoDeVenta = (
  id,
  tipoParent,
  filtrosParent,
  desde,
  hasta,
  anio,
  success,
  errorFail
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `/ventas/VentasDesagregadasPorPeriodosPorComprobantesPorTipoVenta?tipoParent=${tipoParent}&idParent=${id}&desde=${desde}&hasta=${hasta}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasAlCliente
        }`,
        filtrosParent,
        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

//#endregion

//#region Tipo de Ventas Desagregadas
export const seleccionTipoDeVentasDesagregadas = (selectionId) => {
  return {
    type: actionTypes.SELECCION_TIPO_VENTAS_DESAGREGADAS,
    selectionId
  };
};

export const loadTipoDeVentasDesagregadas = () => {
  return (dispatch) => {
    dispatch(loadTipoDeVentasDesagregadasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/ventas/TipoDeVentasDesagregadas?idEmpresa=${storageEmpresaId()}`, { headers: { Authorization: authStr } })
      .then((response) => {
        let items = response.data;
        dispatch(loadTipoDeVentasDesagregadasSuccess(items));
      })
      .catch((error) => {
        dispatch(loadTipoDeVentasDesagregadasFail(error));
      });
  };
};

export const clearTipoDeVentasDesagregadas = () => {
  return {
    type: actionTypes.CLEAR_TIPO_VENTAS_DESAGREGADAS
  };
};

const loadTipoDeVentasDesagregadasStart = () => {
  return {
    type: actionTypes.LOAD_TIPO_VENTAS_DESAGREGADAS_START
  };
};

const loadTipoDeVentasDesagregadasSuccess = (items) => {
  return {
    type: actionTypes.LOAD_TIPO_VENTAS_DESAGREGADAS_SUCCESS,
    items
  };
};

const loadTipoDeVentasDesagregadasFail = (error) => {
  return {
    type: actionTypes.LOAD_TIPO_VENTAS_DESAGREGADAS_FAIL,
    error
  };
};

//#endregion

//#region Ventas Desagregadas por Tipo

//#region Ventas Desagregadas por Tipo de Venta
export const loadVentasDesagregadasDelMesPorTipoDeVenta = (
  id,
  tipoParent,
  tipoChild,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  filtrosParent,
  success,
  errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=${tipoParent}&tipoChild=${tipoChild}&idParent=${id}&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorVendedor
        }`,
        filtrosParent,
        { headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDesagregadasPorComprobantesPorTipoDeVenta = (
  id,
  tipoParent,
  filtrosParent,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `/ventas/VentasDesagregadasPorComprobantesPorTipoVenta?tipoParent=${tipoParent}&idParent=${id}&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasAlCliente
        }`,
        filtrosParent,
        { headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

//#endregion

//#region Por Categoria de Cliente
export const loadVentasDesagregadasPorCategoriaDeCliente = (mesDesde, anioDesde, mesHasta, anioHasta) => {
  return (dispatch) => {
    dispatch(loadVentasDesagregadasPorCategoriaDeClienteStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=CategoriaCliente&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const formatVentasDataTable = [];
        let fecha = fechaActualFunc();
        fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();
        dispatch(
          loadVentasDesagregadasPorCategoriaDeClienteSuccess(
            ventas,
            ventasNegativo,
            formatVentasDataTable,
            fecha,
            totalVentas,
            totalUnidades
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasDesagregadasPorCategoriaDeClienteFail(error));
          } else {
            dispatch(loadVentasDesagregadasPorCategoriaDeCliente(mesDesde, anioDesde, mesHasta, anioHasta));
          }
        } else {
          dispatch(loadVentasDesagregadasPorCategoriaDeClienteFail(error));
        }
      });
  };
};

export const clearVentasDesagregadasPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadVentasDesagregadasPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadVentasDesagregadasPorCategoriaDeClienteSuccess = (
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  totalVentas,
  totalUnidades
) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    ventasNegativo,
    ventasDataTable,
    fecha,
    totalVentas,
    totalUnidades
  };
};

const loadVentasDesagregadasPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

//#endregion

//#region Por Marca

export const loadVentasDesagregadasDelMesPorMarca = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Marca&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorMarca}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorMarcaSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorMarcaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorMarca = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_MARCA
  };
};

const loadVentasDesagregadasDelMesPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_START
  };
};

const loadVentasDesagregadasDelMesPorMarcaSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_FAIL,
    error
  };
};

//#endregion

//#region Por Rubro

export const loadVentasDesagregadasDelMesPorRubro = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Rubro&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorRubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          const ventas = response.data.ventas;
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();

          dispatch(
            loadVentasDesagregadasDelMesPorRubroSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorRubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorRubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_RUBRO
  };
};

export const loadVentasDesagregadasDelMesPorRubroPorIdMarca = (id, idVendedor, mes, anio, success, errorFail) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Marca&tipoChild=Rubro&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorRubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_START
  };
};

const loadVentasDesagregadasDelMesPorRubroSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_FAIL,
    error
  };
};

//#endregion

//#region Por Subrubro

export const loadVentasDesagregadasDelMesPorSubrubro = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Subrubro&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorSubrubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();

          dispatch(
            loadVentasDesagregadasDelMesPorSubrubroSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorSubrubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO
  };
};

const loadVentasDesagregadasDelMesPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_START
  };
};

const loadVentasDesagregadasDelMesPorSubrubroSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_FAIL,
    error
  };
};

//#endregion

//#region Por Vendedor

export const loadVentasDesagregadasDelMesPorVendedor = (
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Vendedor&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();
          dispatch(
            loadVentasDesagregadasDelMesPorVendedorSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_VENDEDOR
  };
};

export const loadVentasDesagregadasDelMesPorVendedorPorResponsable = (
  id,
  idVendedor,
  mes,
  anio,
  success,
  errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Responsable&tipoChild=Vendedor&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_START
  };
};

const loadVentasDesagregadasDelMesPorVendedorSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_FAIL,
    error
  };
};

//#endregion

//#region Por Provincia

export const loadVentasDesagregadasDelMesPorProvincia = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorProvinciaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Provincia&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();

          dispatch(
            loadVentasDesagregadasDelMesPorProvinciaSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorProvinciaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_PROVINCIA
  };
};

const loadVentasDesagregadasDelMesPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_START
  };
};

const loadVentasDesagregadasDelMesPorProvinciaSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_FAIL,
    error
  };
};

//#endregion

//#region Por Zona

export const loadVentasDesagregadasDelMesPorZona = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorZonaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Zona&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();

          dispatch(
            loadVentasDesagregadasDelMesPorZonaSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorZonaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorZona = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_ZONA
  };
};

const loadVentasDesagregadasDelMesPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_START
  };
};

const loadVentasDesagregadasDelMesPorZonaSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_FAIL,
    error
  };
};

//#endregion

//#region Por Unidad de Negocio
export const loadVentasDesagregadasDelMesPorUnidadDeNegocio = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorUnidadDeNegocioStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=UnidadDeNegocio&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorUnidadesDeNegocios
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorUnidadDeNegocioSuccess(
              ventas,
              ventasNegativo,
              totalVentas,
              fecha,
              totalUnidades
            )
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorUnidadDeNegocioFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorUnidadDeNegocio = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO
  };
};

const loadVentasDesagregadasDelMesPorUnidadDeNegocioStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_START
  };
};

const loadVentasDesagregadasDelMesPorUnidadDeNegocioSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorUnidadDeNegocioFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_FAIL,
    error
  };
};

//#endregion

//#region Por Canal
export const loadVentasDesagregadasDelMesPorCanal = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorCanalStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Canal&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorCanales}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorCanalSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorCanalFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorCanal = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_CANAL
  };
};

export const loadVentasDesagregadasDelMesPorCanalPorIdUnidadDeNegocio = (
  id,
  idVendedor,
  mes,
  anio,
  success,
  errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=UnidadDeNegocio&tipoChild=Canal&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorCanales}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorCanalStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_CANAL_START
  };
};

const loadVentasDesagregadasDelMesPorCanalSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_CANAL_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorCanalFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_CANAL_FAIL,
    error
  };
};

//#endregion

//#region Por Grupo de Compras
export const loadVentasDesagregadasDelMesPorGrupoDeCompras = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorGrupoDeComprasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=GrupoDeCompra&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorGruposDeCompras
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorGrupoDeComprasSuccess(
              ventas,
              ventasNegativo,
              totalVentas,
              fecha,
              totalUnidades
            )
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorGrupoDeComprasFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorGrupoDeCompras = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS
  };
};

export const loadVentasDesagregadasDelMesPorGrupoDeComprasPorCanal = (
  id,
  idVendedor,
  mes,
  anio,
  success,
  errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Canal&tipoChild=GrupoDeCompra&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorRubro}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorGrupoDeComprasStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_START
  };
};

const loadVentasDesagregadasDelMesPorGrupoDeComprasSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorGrupoDeComprasFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_FAIL,
    error
  };
};

//#endregion

//#region Por Responsable
export const loadVentasDesagregadasDelMesPorResponsable = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorResponsableStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Responsable&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorResponsables
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorResponsableSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorResponsableFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorResponsable = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_RESPONSABLE
  };
};

export const loadVentasDesagregadasDelMesPorResponsablePorIdGrupoDeCompras = (
  id,
  idVendedor,
  mes,
  anio,
  success,
  errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=GrupoDeCompra&tipoChild=Responsable&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorResponsables
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDesagregadasDelMesPorResponsablePorIdProvincia = (
  id,
  idVendedor,
  mes,
  anio,
  success,
  errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Provincia&tipoChild=Responsable&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorResponsables
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDesagregadasDelMesPorResponsablePorIdZona = (id, idVendedor, mes, anio, success, errorFail) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Zona&tipoChild=Responsable&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorResponsables
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorResponsableStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_START
  };
};

const loadVentasDesagregadasDelMesPorResponsableSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorResponsableFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_FAIL,
    error
  };
};

//#endregion

//#region Por Cliente
export const loadVentasDesagregadasDelMesPorCliente = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Cliente&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasPorClientes}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorClienteSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorClienteFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_CLIENTE
  };
};

export const loadVentasDesagregadasDelMesPorClientePorVendedor = (id, idVendedor, mes, anio, success, errorFail) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Vendedor&tipoChild=Cliente&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_CLIENTE_START
  };
};

const loadVentasDesagregadasDelMesPorClienteSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_CLIENTE_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_CLIENTE_FAIL,
    error
  };
};

//#endregion

//#region Por Artículo

export const loadVentasDesagregadasDelMesPorArticulo = (
  idVendedor,
  mesDesde,
  anioDesde,
  mesHasta,
  anioHasta,
  success,
  errorFail
) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDesagregadasDelMesPorArticuloStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipo?tipoVentas=Articulo&mesDesde=${mesDesde}&anioDesde=${anioDesde}&mesHasta=${mesHasta}&anioHasta=${anioHasta}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorArticulos
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        if (!success) {
          const totalVentas = response.data.totalMonto;
          const totalUnidades = response.data.totalUnidades;
          const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
          let fecha =
            ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anioDesde, mesDesde - 1, 1);

          dispatch(
            loadVentasDesagregadasDelMesPorArticuloSuccess(ventas, ventasNegativo, totalVentas, fecha, totalUnidades)
          );
        } else {
          return success(ventas);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDesagregadasDelMesPorArticuloFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDesagregadasDelMesPorArticulo = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_POR_ARTICULO
  };
};

export const loadVentasDesagregadasDelMesPorArticuloPorRubro = (id, idVendedor, mes, anio, success, errorFail) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDesagregadasDelMesPorTipoPorId?tipoParent=Rubro&tipoChild=Articulo&idParent=${id}&mes=${mes}&anio=${anio}${
          idVendedor ? `&idvendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorArticulos
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date(anio, mes - 1, 1);
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

const loadVentasDesagregadasDelMesPorArticuloStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_START
  };
};

const loadVentasDesagregadasDelMesPorArticuloSuccess = (ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasDesagregadasDelMesPorArticuloFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_FAIL,
    error
  };
};

//#endregion

//#endregion

//#region Evolución de Ventas por Periodos por Tipo de Venta

export const loadEvolucionVentasPorPeriodosPorTipoVenta = (
  tipoVenta,
  desdeDate,
  hastaDate,
  aniosSelected,
  success,
  errorFail,
  userSelected
) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorPeriodosPorTipoVentaStart(tipoVenta));
    const authStr = 'Bearer '.concat(storageToken());
    const desde = desdeDate.getMonth() + 1;
    const hasta = hastaDate.getMonth() + 1;
    const anios = aniosSelected.map((anio) => anio.id);
    const body = {
      idMoneda: storageMonedaId(),
      mesDesde: desde,
      mesHasta: hasta,
      anios: anios,
      idEmpresa: storageEmpresaId(),
      indicadorId: idIndicadores.ventasPorMesPorRubro,
      interanual: true,
      tipoVenta: tipoVenta
    };
    axios
      .post(`/ventas/EvolucionVentasPorPeriodosPorTipoVenta`, body, { headers: { Authorization: authStr } })
      .then((response) => {
        let total = 0;
        let totalUnidades = 0;
        let fechas = [];
        let ventas = response.data;
        ventas.forEach((venta, index) => {
          venta.montos.forEach((montoPeriodo) => {
            if (index === 0) {
              fechas.push({
                desde: montoPeriodo.mesDesde,
                hasta: montoPeriodo.mesHasta,
                anio: montoPeriodo.anio,
                anioHasta: montoPeriodo.anioHasta
              });
            }
            total += montoPeriodo.monto;
          });
          venta.unidades.forEach((unidadPeriodo) => (totalUnidades += unidadPeriodo.unidadesVendidas));
        });

        let tempAnios = [
          ...new Set(
            ventas &&
              ventas[0] &&
              ventas[0].montos &&
              ventas[0].montos
                .sort((a, b) => compareByDesc(a, b, 'anio'))
                .map((monto) => {
                  return monto.anio;
                })
          )
        ];

        if (hasta < desde) tempAnios.splice(-1);

        dispatch(
          updateFiltroEvolucionesDeVentasPorPeriodos(
            desdeDate,
            hastaDate,
            tempAnios,
            tipoVenta.toLowerCase(),
            true,
            userSelected
          )
        );
        dispatch(loadEvolucionVentasPorPeriodosPorTipoVentaSuccess(tipoVenta, ventas, fechas, total, totalUnidades));

        return success(ventas, fechas, total, totalUnidades);
      })
      .catch((error) => {
        dispatch(loadEvolucionVentasPorPeriodosPorTipoVentaFail(tipoVenta, error));
        return errorFail && errorFail(error);
      });
  };
};

export const updateFiltroEvolucionesDeVentasPorPeriodos = (
  desde,
  hasta,
  anios,
  dataSelectedId,
  aplicado,
  userSelected = false
) => {
  return {
    type: actionTypes.FILTROS_EVOLUCION_VENTAS_POR_PERIODOS,
    desde,
    hasta,
    anios,
    dataSelectedId,
    aplicado,
    userSelected
  };
};

export const updateFiltroVentasComparativasPorPeriodos = (
  desde,
  hasta,
  anios,
  dataSelectedId,
  aplicado,
  userSelected = false
) => {
  return {
    type: actionTypes.FILTROS_VENTAS_COMPARATIVAS_POR_PERIODOS_POR,
    desde,
    hasta,
    anios,
    dataSelectedId,
    aplicado,
    userSelected
  };
};

export const updateDataEvolucionVentasPorPeriodos = (actualizar) => {
  return {
    type: actionTypes.ACTUALIZAR_DATA_EVOLUCION_VENTAS_POR_PERIODOS,
    actualizar
  };
};

export const updateFiltroVentasComparativasPorMes = (
  desde,
  hasta,
  anios,
  dataSelectedId,
  aplicado,
  userSelected = false
) => {
  return {
    type: actionTypes.FILTROS_VENTAS_COMPARATIVAS_POR_MES_POR,
    desde,
    hasta,
    anios,
    dataSelectedId,
    aplicado,
    userSelected
  };
};

export const updateDataComparativasPorPeriodos = (actualizar) => {
  return {
    type: actionTypes.ACTUALIZAR_DATA_COMPARATIVAS_POR_PERIODOS,
    actualizar
  };
};

export const updateDataComparativasPorMes = (actualizar) => {
  return {
    type: actionTypes.ACTUALIZAR_DATA_COMPARATIVAS_POR_MES,
    actualizar
  };
};

export const clearEvolucionVentasPorPeriodosPorTipoVenta = (tipoVenta) => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA,
    tipoVenta
  };
};

const loadEvolucionVentasPorPeriodosPorTipoVentaStart = (tipoVenta) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START,
    tipoVenta
  };
};

const loadEvolucionVentasPorPeriodosPorTipoVentaSuccess = (tipoVenta, ventas, fechas, total, totlaUnidades) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS,
    tipoVenta,
    ventas,
    fechas,
    total,
    totlaUnidades
  };
};

const loadEvolucionVentasPorPeriodosPorTipoVentaFail = (tipoVenta, error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL,
    tipoVenta,
    error
  };
};

//#endregion

//#region Ventas Comparativas por Periodos por Tipo de Venta

export const loadVentasComparativasPorTipoVenta = (
  tipoVenta,
  desdeDate,
  hastaDate,
  aniosSelected,
  success,
  errorFail,
  userSelected
) => {
  return (dispatch) => {
    dispatch(loadVentasComparativasPorTipoVentaStart(tipoVenta));
    const authStr = 'Bearer '.concat(storageToken());
    const desde = desdeDate.getMonth() + 1;
    const hasta = hastaDate.getMonth() + 1;
    const anios = aniosSelected.map((anio) => anio.id);
    const body = {
      idMoneda: storageMonedaId(),
      mesDesde: desde,
      mesHasta: hasta,
      anios: anios,
      idEmpresa: storageEmpresaId(),
      indicadorId: idIndicadores.ventasPorMesPorRubro,
      interanual: false,
      tipoVenta: tipoVenta
    };
    axios
      .post(`/ventas/EvolucionVentasPorPeriodosPorTipoVenta`, body, { headers: { Authorization: authStr } })
      .then((response) => {
        let total = 0;
        let totalUnidades = 0;
        let fechas = [];
        let ventas = response.data;
        ventas.forEach((venta, index) => {
          venta.montos.forEach((montoPeriodo) => {
            if (index === 0) {
              fechas.push({
                desde: montoPeriodo.mesDesde,
                hasta: montoPeriodo.mesHasta,
                anio: montoPeriodo.anio,
                anioHasta: montoPeriodo.anioHasta
              });
            }
            total += montoPeriodo.monto;
          });
          venta.unidades.forEach((unidadPeriodo) => (totalUnidades += unidadPeriodo.unidadesVendidas));
        });

        let tempAnios = [
          ...new Set(
            ventas &&
              ventas[0] &&
              ventas[0].montos &&
              ventas[0].montos
                .sort((a, b) => compareByDesc(a, b, 'anio'))
                .map((monto) => {
                  return monto.anio;
                })
          )
        ];

        if (hasta < desde) tempAnios.splice(-1);

        dispatch(
          updateFiltroVentasComparativasPorPeriodos(
            desdeDate,
            hastaDate,
            tempAnios,
            tipoVenta.toLowerCase(),
            true,
            userSelected
          )
        );
        dispatch(loadVentasComparativasPorTipoVentaSuccess(tipoVenta, ventas, fechas, total, totalUnidades));

        return success(ventas, fechas, desdeDate, hastaDate, tempAnios);
      })
      .catch((error) => {
        dispatch(loadVentasComparativasPorTipoVentaFail(tipoVenta, error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasComparativasPorTipoVenta = (tipoVenta) => {
  return {
    type: actionTypes.CLEAR_VENTAS_COMPARATIVAS_POR_TIPO_VENTA,
    tipoVenta
  };
};

const loadVentasComparativasPorTipoVentaStart = (tipoVenta) => {
  return {
    type: actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_START,
    tipoVenta
  };
};

const loadVentasComparativasPorTipoVentaSuccess = (tipoVenta, ventas, fechas, total, totlaUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_SUCCESS,
    tipoVenta,
    ventas,
    fechas,
    total,
    totlaUnidades
  };
};

const loadVentasComparativasPorTipoVentaFail = (tipoVenta, error) => {
  return {
    type: actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_FAIL,
    tipoVenta,
    error
  };
};

//#endregion

//#region Ventas Comparativas por Mes por Tipo de Venta

export const loadVentasComparativasPorMesPorTipoVenta = (
  tipoVenta,
  desdeDate,
  hastaDate,
  aniosSelected,
  success,
  errorFail,
  userSelected
) => {
  return (dispatch) => {
    dispatch(loadVentasComparativasPorMesPorTipoVentaStart(tipoVenta));
    const authStr = 'Bearer '.concat(storageToken());
    const desde = desdeDate.getMonth() + 1;
    const hasta = hastaDate.getMonth() + 1;
    const anios = [aniosSelected.id];
    const body = {
      idMoneda: storageMonedaId(),
      mesDesde: desde,
      mesHasta: hasta,
      anios: anios,
      idEmpresa: storageEmpresaId(),
      indicadorId: idIndicadores.ventasPorMesPorRubro,
      interanual: false,
      tipoVenta: tipoVenta
    };
    axios
      .post(`/ventas/EvolucionVentasPorPeriodosPorTipoVenta`, body, { headers: { Authorization: authStr } })
      .then((response) => {
        let total = 0;
        let totalUnidades = 0;
        let fechas = [];
        let responseData = [...response.data];
        let ventas = [].concat(response.data);
        ventas.forEach((venta, index) => {
          venta.montos.forEach((montoPeriodo) => {
            if (index === 0) {
              fechas.push({
                mes: montoPeriodo.mesDesde,
                anio: montoPeriodo.anio,
              });
            }
            total += montoPeriodo.monto;
          });
          venta.unidades.forEach((unidadPeriodo) => (totalUnidades += unidadPeriodo.unidadesVendidas));
        });

        let montos = [...ventas && ventas[0] && ventas[0].montos && ventas[0].montos];
        let tempAnios = [...new Set(montos.sort((a, b) => compareByDesc(a, b, 'anio')).map((monto) => { return monto.anio; }))];

        if (hasta < desde)
          tempAnios.splice(-1);

        dispatch(
          updateFiltroVentasComparativasPorMes(
            desdeDate,
            hastaDate,
            tempAnios,
            tipoVenta.toLowerCase(),
            true,
            userSelected
          )
        );
        dispatch(loadVentasComparativasPorMesPorTipoVentaSuccess(tipoVenta, responseData, fechas, total, totalUnidades));

        return success(responseData, fechas, desdeDate, hastaDate, tempAnios);
      })
      .catch((error) => {
        dispatch(loadVentasComparativasPorMesPorTipoVentaFail(tipoVenta, error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasComparativasPorMesPorTipoVenta = (tipoVenta) => {
  return {
    type: actionTypes.CLEAR_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA,
    tipoVenta
  };
};

const loadVentasComparativasPorMesPorTipoVentaStart = (tipoVenta) => {
  return {
    type: actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_START,
    tipoVenta
  };
};

const loadVentasComparativasPorMesPorTipoVentaSuccess = (tipoVenta, ventas, fechas, total, totlaUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_SUCCESS,
    tipoVenta,
    ventas,
    fechas,
    total,
    totlaUnidades
  };
};

const loadVentasComparativasPorMesPorTipoVentaFail = (tipoVenta, error) => {
  return {
    type: actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_FAIL,
    tipoVenta,
    error
  };
};

//#endregion

//#region Ventas por Periodos por Tipo de Venta

export const loadVentasPorPeriodosPorTipoVenta = (tipoVenta, desde, hasta, anio, anioHasta, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasPorPeriodosPorTipoVentaStart(tipoVenta));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasPorPeriodosPorTipoVenta?tipoVenta=${tipoVenta}&desde=${desde}&hasta=${hasta}&anio=${anio}&anioHasta=${anioHasta}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let totalUnidades = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.forEach((venta) => {
            totalVentas += venta.monto;
            totalUnidades += venta.unidadesVendidas;
          });
          const fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1, 1)
              : new Date(anio, desde - 1, 1);

          dispatch(
            loadVentasPorPeriodosPorTipoVentaSuccess(
              tipoVenta,
              response.data,
              ventasNegativo,
              totalVentas,
              fecha,
              totalUnidades
            )
          );
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasPorPeriodosPorTipoVentaFail(tipoVenta, error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasPorPeriodosPorTipoVenta = (tipoVenta) => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_PERIODOS_POR_TIPO_VENTA,
    tipoVenta
  };
};

const loadVentasPorPeriodosPorTipoVentaStart = (tipoVenta) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START,
    tipoVenta
  };
};

const loadVentasPorPeriodosPorTipoVentaSuccess = (tipoVenta, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS,
    tipoVenta,
    ventas,
    ventasNegativo,
    total,
    fecha,
    totalUnidades
  };
};

const loadVentasPorPeriodosPorTipoVentaFail = (tipoVenta, error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL,
    tipoVenta,
    error
  };
};

//#endregion

//#endregion

//#region CUBO VENTAS
export const loadCuboVentasData = (request, onSuccess = undefined, onFail = undefined, config = undefined) => {
  return (dispatch) => {
    let filtro = {
      desde: request.desde,
      hasta: request.hasta,
      anioComparacion: request.anioComparacion
    };
    dispatch(loadCuboVentasDataStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}ventas/GenerarCuboVentas`, request, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        let datos = response.data && response.data.datos ? response.data.datos : [];
        let configuracion = response.data && response.data.config ? JSON.parse(response.data.config) : config;
        let filtros = response.data && response.data.filtros ? response.data.filtros : filtro;
        let selectedRows = response.data.filasSubtotales ? response.data.filasSubtotales : [];

        dispatch(loadCuboVentasDataSuccess(datos, configuracion, filtros));
        dispatch(updateCuboVentasFiltros(filtros.desde, filtros.hasta, filtros.anioComparacion, true));

        if (onSuccess) {
          const rowData = {
            configuracion,
            nombre: response.data.nombreFormato,
            datos,
            filasSubtotales: selectedRows
          };
          onSuccess(rowData);
        }
      })
      .catch((error) => {
        dispatch(loadCuboVentasDataFail(error));
        dispatch(updateCuboVentasFiltros(filtro.desde, filtro.hasta, filtro.anioComparacion, true));

        if (onFail) onFail(error);
      });
  };
};

export const clearCuboVentasData = () => {
  return {
    type: actionTypes.CLEAR_CUBO_VENTAS_DATA
  };
};

export const updateCuboVentasData = (data, config, filtros = {}) => {
  return (dispatch) => {
    dispatch(loadCuboVentasDataSuccess(data, config, filtros));
  };
};

const loadCuboVentasDataStart = () => {
  return {
    type: actionTypes.LOAD_CUBO_VENTAS_DATA_START
  };
};

const loadCuboVentasDataSuccess = (data, config, filtros) => {
  return {
    type: actionTypes.LOAD_CUBO_VENTAS_DATA_SUCCESS,
    data,
    config,
    filtros
  };
};

const loadCuboVentasDataFail = (error) => {
  return {
    type: actionTypes.LOAD_CUBO_VENTAS_DATA_FAIL,
    error
  };
};

export const updateCuboVentasFiltros = (desde, hasta, anioComparacion, filtroAplicado) => {
  return {
    type: actionTypes.UPDATE_CUBO_VENTAS_FILTROS,
    desde,
    hasta,
    anioComparacion,
    filtroAplicado
  };
};
//#endregion
