const validateIsEmpty = (value) => {
  return (!value || value === "");
};

const validateIsNotANumber = (value) => {
  return isNaN(value);
};

const __FILE_NAME__ = "Unidades";

export const validate = (json, anioFiscal = "") => {
  var result = [];

  const header = json[0];
  var headerErrors = [];

  if (!header.hasOwnProperty('A') || header.A.toString().toLowerCase().trim() !== "codigo")
    headerErrors.push("La celda A1 debe decir CODIGO.");

  if (!header.hasOwnProperty('P') || header.P.toString().toLowerCase().trim() !== "total")
    headerErrors.push("La celda P1 debe decir TOTAL.");

  if (!header.hasOwnProperty('Q') || header.Q.toString().toLowerCase().trim() !== anioFiscal)
    headerErrors.push(`La celda Q1 debe contener el año fiscal seleccionado (${anioFiscal}).`);

  if (headerErrors.length > 0) {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: headerErrors
    });
    return result;
  }

  json.slice(1).forEach(row => {
    var errors = [];

    // Column "A"
    if (!row.hasOwnProperty('A') || validateIsEmpty(row.A)) {
      errors.push("La columna A no puede estar vacía.");
    }

    // Columna "D" a "O"
    ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"].forEach(col => {
      if (!row.hasOwnProperty(col) || validateIsNotANumber(row[col])) {
        errors.push(`La columna ${col} debe contener números.`);
      }
    });

    // Fila duplicada
    const rowsDuplicated = json.filter(e => (
      e.hasOwnProperty('A') && row.hasOwnProperty('A')
      && e.A.toString().trim() === row.A.toString().trim()
      && e.__rowNum__ !== row.__rowNum__
    ));

    if (rowsDuplicated.length > 0) {
      const message = `La fila ${row.__rowNum__} se repite en `
        + `${rowsDuplicated.length === 1 ? 'la fila ' : 'las filas '}`
        + `${rowsDuplicated.map(e => e.__rowNum__).join(', ')}`;
      errors.push(message);
    }

    if (errors.length > 0) {
      result.push({
        rowNumber: row.__rowNum__,
        fileName: __FILE_NAME__,
        product: row.A,
        errors
      });
    }
  });

  return result;
}