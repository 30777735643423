import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../store/actions';
import TableTiemposReq from '../../components/TiemposReq/TableTiemposReq/TableTiemposReq';
import ClienteSelector from '../../components/UI/ClienteSelector/ClienteSelector';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

const TiemposReq = (props) => {  
  sessionStorage.setItem('seccionNombre', 'Tiempos request');
  
  return !props.ejecucionTiemposReq ? (
    <BoxEjecutarPaginaInfo />
  ) : (
    <div>
      <ClienteSelector reloadTiemposRequest />
      <div style={{ height: '10px' }} />
      <TableTiemposReq ejecucionDePagina={props.ejecucionTiemposReq} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionTiemposReq: state.ejecucionPaginas.ejecucionTiemposReq
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarTiemposReq: () => dispatch(actions.ejecutarTiemposReq()),
    updateUltimaActualizacion: () =>
      dispatch(actions.updateUltimaActualizacionTiemposRequest(moment(Date.now()).format('DD/MM/YYYY HH:mm')))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TiemposReq);
