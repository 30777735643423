import React from 'react';
import classes from './TablaInteranualPlaceholder.css';
import AdsClick from '../../../../assets/images/AdsClick.svg';

const TablaInteranualPlaceholder = (props) => {
  return (
    <div className={classes.Container}>
      
        <img alt="" src={AdsClick} className={classes.Imagen} />
        <div className={classes.TextContainer}>
          <p className={classes.Text}>
            Haga clic en el botón "<span className={classes.HighlightText}>Agregar período</span>" para añadir y comparar
            la información de distintos años
          </p>
        </div>
      
    </div>
  );
};

export default TablaInteranualPlaceholder;
