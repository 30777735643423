import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { MTableHeader } from 'material-table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';
import ModalDetallesComprobante from '../ModalDetallesComprobante/ModalDetallesComprobante';

import classes from './TableComprobantesGastos.css';

export const TableComprobantes = (props) => {
  const { mes, anio } = props;
  const [comprobantesState, setComprobantesState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    props.onLoadComprobantes(props.itemId, mes, anio, (comprobantes) => {
      setComprobantesState(comprobantes);
      setLoading(false);
      props.endRequest && props.endRequest(comprobantes.length);
    });
  }, []);

  const cellStyles = {
    fontSize: 'smaller',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyles = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220, 220, 220)',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const columns = [
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Proveedor',
      field: 'proveedor',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Importe',
      field: 'monto',
      align: 'right',
      cellStyle: (cellData) => {
        return {
          fontSize: 'smaller',
          paddingBottom: '0px',
          paddingTop: '0px',
          paddingLeft: '5px',
          paddingRight: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: 'small',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: 'rgb(220, 220, 220)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => {
        return <ModalDetallesComprobante gasto comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }} />;
      }
    }
  ];

  const data = () => {
    return comprobantesState.map((comprobante) => {
      return {
        idComproba: comprobante.idComproba,
        idSistema: comprobante.idSistema,
        idSucursal: comprobante.idSucursal,
        fecha: comprobante.fecha,
        comprobante: comprobante.comprobante,
        proveedor: comprobante.proveedor,
        referencia: comprobante.referencia,
        numeroExterno: comprobante.numeroExterno,
        monto: comprobante.monto
      };
    });
  };

  return (
    <div className={classes.tableComprobantesGastosContainer}>
      <Table
        noBoxShadow
        style={{ width: props.customWidth ? props.customWidth : '100%', marginTop: 0, marginBottom: '15px' }}
        loading={loading}
        columns={columns}
        data={data()}
        components={{
          Header: (props) => (
            <MTableHeader {...props}>
              {(props.columns[0].tableData.width = '55px')}
              {(props.columns[1].tableData.width = '150px')}
              {(props.columns[2].tableData.width = '245px')}
              {(props.columns[3].tableData.width = '35px')}
              {(props.columns[4].tableData.width = '120px')}
              {(props.columns[5].tableData.width = '80px')}
              {(props.columns[6].tableData.width = '40px')}
            </MTableHeader>
          )
        }}
        customOptions={{
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          toolbar: false,
          showTitle: false,
          paging: false,
          maxBodyHeight: 235
        }}
        tableKey={"tabla-comprobantes-gastos"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadComprobantes: (idItem, mes, anio, success) =>
      dispatch(actions.loadDetalleGastosDelItemDeCompra(idItem, mes, anio, undefined, true, success))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComprobantes);
