import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import locales from '../../../../shared/locales';

import * as actions from '../../../../store/actions/index';

import LineChart from '../../../Charts/CustomLineChart';

const LineRentabilidadEvolutivo = (props) => {
  const { ejecucionDePagina, onLoadChart } = props;
  const { loading, estaCargado, fechaPeriodo, gastosColums, itemData } = props;
  const { costos = [], ganancias = [], utilidades = [], ventas = [] } = itemData;

  useEffect(() => {
    if (!estaCargado && ejecucionDePagina) {
      onLoadChart(fechaPeriodo.mes, fechaPeriodo.anio);
    }
  }, [ejecucionDePagina, estaCargado, fechaPeriodo]);

  const series = () => {
    let items = [
      {
        name: `Ventas`,
        data: ventas.map((venta) => venta.monto)
      },
      {
        name: `Costos`,
        data: costos.map((costo) => costo.monto)
      },
      {
        name: `Utilidad`,
        data: utilidades.map((utilidad) => utilidad.monto)
      }
    ];

    if(gastosColums) {
      gastosColums.forEach((col) => {
        const montos = itemData[col.field];
        var item = {
          name: col.nombre,
          data: montos ? montos.map((monto) => monto.monto) : []
        };
        items.push(item);
      });
    }

    items.push({
      name: `Resultado`,
      data: ganancias.map((ganancia) => ganancia.monto)
    });

    return items;
  };

  const formatMesesToStrings = () => {
    return utilidades.map((cobranza) => `${locales[1].options.months[cobranza.mes - 1]}/${cobranza.anio}`);
  };

  return (
    <LineChart
      title={`Resultado evolutivo`}
      series={series()}
      loading={loading}
      chartHeight={'300px'}
      width={'100%'}
      chartWidth={'99%'}
      chartDropShadow={{
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }}
      toolbarShow={true}
      zoomType={'xy'}
      dataLabelsEnabled={window.screen.width > 500}
      formatDataLabels
      strokeCurve={'smooth'}
      strokeWidth={5}
      fillType={'solid'}
      gridBorderColor={'#e7e7e7'}
      gridRow={{
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }}
      markersSize={4}
      xaxisCategories={formatMesesToStrings()}
      xaxisType="category"
      yaxisLabels={{
        show: true,
        formatter: (value) => {
          return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
        },
        align: 'left',
        offsetX: -16
      }}
      legendFontSize={'14px'}
      legendOffsetY={-5}
      tooltipEnabled
      legendPosition={'bottom'}
      legendHorizontalAlign={'center'}
      legendShowForSingleSeries={true}
      legendShowForNullSeries={true}
      legendShowForZeroSeries={true}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    itemData: state.integradores.lineRentabilidadEvolutivo,
    fechaPeriodo: state.integradores.fechaPeriodoRentabilidad,
    loading: state.integradores.lineRentabilidadEvolutivo.loading,
    progressLoading: state.integradores.lineRentabilidadEvolutivo.progress,
    estaCargado: state.integradores.lineRentabilidadEvolutivo.estaCargado,
    gastosColums: state.integradores.lineRentabilidadEvolutivo.columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio) => dispatch(actions.loadLineRentabilidadEvolutivo(mes, anio)),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Resultado evolutivo', ['gasto', 'venta', 'categoriaItemsDeCompra']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineRentabilidadEvolutivo);
