import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaVentasPorClienteDelVendedor.css';
import TablaVentasPorArticuloDelClienteDelVendedor from '../TablaVentasPorArticuloDelClienteDelVendedor/TablaVentasPorArticuloDelClienteDelVendedor';

const TablaVentasPorClienteDelVendedor = (props) => {
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes;
  const anio = props.anio;
  useEffect(() => {
    setLoading(true);
    props.onLoadVentasPorClienteDelVendedor(
      mes,
      anio,
      props.vendedorId,
      (response) => {
        setVentasLocal(response);
        setLoading(false);
        props.endRequest && props.endRequest(response.length);
      },
      () => {
        setVentasLocal([]);
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: 'Cliente',
      field: 'cliente',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        cliente: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        mes: venta.mes,
        anio: venta.anio
      };
    });
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.tablaVentasPorClienteDelVendedor}>
      <Table
        tableRef={tableRef}
        style={{ width: '95%', marginTop: 0, marginBottom: '10px' }}
        title=""
        columns={columns}
        loading={loading}
        data={data()}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return (
            <TablaVentasPorArticuloDelClienteDelVendedor
              vendedorId={props.vendedorId}
              clienteId={rowData.id}
              mes={rowData.mes}
              anio={rowData.anio}
            />
          );
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 345,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red'
          })
        }}
        tableKey={"tabla-ventas-cliente-vendedor"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorClienteDelVendedor: (mes, anio, idVendedor, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorClienteDelVendedor(mes, anio, idVendedor, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasPorClienteDelVendedor);
