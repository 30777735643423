import React, { Fragment, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { storageMonedaSigno } from '../../../../shared/sessionData'
import { formatNumber, isEven } from '../../../../shared/formatNumber'
import { IconButton, Tooltip } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import * as actions from '../../../../store/actions/index'
import Table from '../../../UI/Table/Table'
import moment from 'moment'
import ModalDetallesComprobante from '../../Comprobantes/ModalDetallesComprobante/ModalDetallesComprobante'

import TablaNotasDePedidoToExcel from './TablaNotasDePedidoToExcel'
import classes from './TablaNotasDePedido.css'

const TablaNotasDePedido = (props) => {

    useEffect(() => {
        props.onLoadChart()
      }, []);

    const columns = [
        {
          title: 'Cod.',
          field: 'clienteId',
          align: 'left',
          cellStyle: {
            fontSize: '12px',
            paddingBottom: '5px',
            paddingTop: '5px'
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
          }
        },
        {
          title: `Cliente`,
          field: 'cliente',
          align: 'left',
          cellStyle: {
            fontSize: '12px',
            paddingBottom: '5px',
            paddingTop: '5px',
            width: '400px'
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
            width: '250px'
          }
        },
        {
          title: `Fecha`,
          field: 'fecha',
          align: 'right',
          type: 'numeric',
          cellStyle: {
            fontSize: '12px',
            paddingBottom: '5px',
            paddingTop: '5px',
            width: '125px'
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
            width: '125px'
          },
          render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
        },
        {
            title: `Fecha entrega`,
            field: 'fechaEntrega',
            align: 'right',
            type: 'numeric',
            cellStyle: {
                fontSize: '12px',
                paddingBottom: '5px',
                paddingTop: '5px',
                width: '350px'
            },
            headerStyle: {
                fontSize: '14px',
                paddingTop: '0px',
                paddingBottom: '0px',
                width: '350px'
            },
            render: (rowData) => `${moment(rowData.fechaEntrega).format('DD/MM/YY')}`
        },
        {
            title: `Comprobante`,
            field: 'nombreComprobante',
            align: 'left',
            cellStyle: {
                fontSize: '12px',
                paddingLeft: '40px',
                paddingBottom: '5px',
                paddingTop: '5px'
            },
            headerStyle: {
                fontSize: '14px',
                paddingLeft: '40px',
                paddingTop: '0px',
                paddingBottom: '0px',
            }
        },
        {
            title: `Ref.`,
            field: 'referencia',
            align: 'right',
            type: 'numeric',
            cellStyle: {
              fontSize: '12px',
              paddingLeft: '40px',
              paddingBottom: '5px',
              paddingTop: '5px'
          },
            headerStyle: {
              fontSize: '14px',
              paddingLeft: '40px', 
              paddingTop: '0px',
              paddingBottom: '0px',
            },
        },
        {
            title: `Núm. Externo`,
            field: 'nroExterno',
            align: 'left',
            cellStyle: {
                fontSize: '12px',
                paddingLeft: '40px', 
                paddingBottom: '5px',
                paddingTop: '5px',
                width: '300px'
            },
            headerStyle: {
                fontSize: '14px',
                paddingLeft: '40px', 
                paddingTop: '0px',
                paddingBottom: '0px',
                width: '300px'
            }
        },
        {
          title: `Total`,
          field: 'total',
          align: 'right',
          type: 'numeric',
          cellStyle: (cellData) => {
            return {
              fontSize: '12px',
              paddingBottom: '5px',
              paddingTop: '5px',
              color: cellData < 0 && 'red'
            };
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
          },
          render: (rowData) => `${storageMonedaSigno()}${formatNumber(rowData.total)}`
        },
        {
          title: 'Ver',
          align: 'right',
          cellStyle: {
            fontSize: '12px',
            paddingBottom: '5px',
            paddingTop: '5px',
            paddingRight: '21px', 
            paddingLeft: '0px', 
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingRight: '20px', 
          },
          render: (rowData) => {
            return <ModalDetallesComprobante venta comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }} />;
          }
        },
    ];

    const data = () => {
        return props.notasDePedido.map((notaDePedido) => {
          return {
            idSucursal: notaDePedido.sucursalId,
            idSistema: notaDePedido.sistemaId, 
            idComproba: notaDePedido.comprobanteId,
            referencia: notaDePedido.referencia, 
            clienteId: notaDePedido.clienteId,
            cliente: notaDePedido.cliente,
            fecha: notaDePedido.fecha,
            fechaEntrega: notaDePedido.fechaEntrega,
            nombreComprobante: notaDePedido.nombreComprobante, 
            nroExterno: notaDePedido.nroExterno,
            total: notaDePedido.total,
          };
        });
    };
    
    const buildTotalsAndExports = () =>{
      const totalesComprobantes = props.notasDePedido.map((notaDePedido) => {
        return {
          total: notaDePedido.total
        }
      })
      let total = 0
      if(totalesComprobantes !== 'undefined' && totalesComprobantes.length !== 0){
        totalesComprobantes.forEach(notaDePedido => total+= notaDePedido.total)
      }

      return(
        <div className={classes.TotalsHeader}>
          <span style={{alignSelf: 'center'}}>Total: {storageMonedaSigno()}{formatNumber(total)}</span>
          <div>
            {!props.loading && (
              <TablaNotasDePedidoToExcel
                notasDePedido={data()}
              />
            )}
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
                <FilterListIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) 
    }

    const tableRef = useRef();
    const buildTable = () => {
      return(
          <Table
            tableRef={tableRef}
            style={{ width: '100%', marginTop: 0, marginBottom: '10px' }}
            title=""
            columns={columns}
            loading={props.loading}
            data={data()}
            customOptions={{
              thirdSortClick: false,
              sorting: true,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 345,
              rowStyle: (rowData) => ({
                color: rowData.tableData.monto < 0 && 'red',
                backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
              })
            }}
            tableKey={"tabla-notas-de-pedido"}
          />
      )
    }

    return (
        <Fragment>
          <div>
            {buildTotalsAndExports()}
            {buildTable()}
          </div>
        </Fragment>
      );
}

const mapStateToProps = (state) => {
    return {
        loading: state.ventas.detalleNotasDePedido.loading,
        error: state.ventas.detalleNotasDePedido.error,
        notasDePedido: state.ventas.detalleNotasDePedido.notasDePedido,
        estaCargado: state.ventas.detalleNotasDePedido.estaCargado,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      onLoadChart: (idEmpresa) => dispatch(actions.detalleNotasDePedido(idEmpresa)),
      onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Notas de pedido', ['pedido'])),
      onCloseShowModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true , 'lg')),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TablaNotasDePedido);