import React from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import * as actions from '../../../../store/actions';

import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './SunburstVentasPorSucursalPorSeleccion.css';
import PieChart from '../../../Charts/PieChart';
import { formatNumber, formatTwoIntegerDigits } from '../../../../shared/formatNumber';
import { Grid } from '@material-ui/core';

const SunburstVentasPorSucursalPorSeleccion = (props) => {
  const { dataSelectedId } = props;

  const dataSelectOptions = [
    {
      id: 'categoriaDeCliente',
      title: 'Categoría de cliente'
    },
    {
      id: 'marca',
      title: 'Marca'
    },
    {
      id: 'rubro',
      title: 'Rubro'
    },
    {
      id: 'subrubro',
      title: 'Subrubro'
    },
    {
      id: 'vendedor',
      title: 'Vendedor'
    },
    {
      id: 'provincia',
      title: 'Provincia'
    },
    {
      id: 'zona',
      title: 'Zona'
    }
  ];

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    props.onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const loading = props.ventasPorCategoriaDeCliente.loading;
    return (
      <Grid container spacing={1} style={{ fontWeight: 400 }} >
        <Grid item xs={8}>
          <div className={classes.LabelVerPor}>
            Ventas por sucursal del {' '}
            {loading ? '--/----' : `${formatTwoIntegerDigits(props.fechaVentasDelMes.mes)}/${props.fechaVentasDelMes.anio}`} por 
          </div>
        </Grid>
        <Grid item xs={4}>
          <Select
            input={<Input classes={{ input: classes.SelectRoot }} />}
            style={{ marginLeft: '5px' }}
            value={dataSelectedId}
            onChange={(e) => handleChangeOptionSelected(e)}
          >
            {dataSelectOptions.map((option, index) => {
              return (
                <MenuItem dense key={index} value={option.id}>
                  {option.title}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
    );
  };

  const data = () => {
    const formatData = [];

    props.ventasPorCategoriaDeCliente.sucursales.map((sucursal) => {
      let sumVentasDelVendedor = 0;

      sucursal.ventas.map((venta) => {
        return (sumVentasDelVendedor += venta.monto);
      });
      return formatData.push(sumVentasDelVendedor);
    });

    return formatData;
  };

  const labels = () => {
    const formatLabels = [];
    props.ventasPorCategoriaDeCliente.sucursales.map((sucursal) => {
      return formatLabels.push(sucursal.nombre);
    });

    return formatLabels;
  };

  const valoresNegativos = () => {
    const formatGastosNegativos = [];

    props.ventasPorCategoriaDeCliente.sucursales.map((sucursal) => {
      return sucursal.ventas
        .filter((vent) => vent.monto < 0)
        .map((venta) => {
          return formatGastosNegativos.push({
            nombre: venta.nombre,
            monto: venta.monto
          });
        });
    });
  };

  return (
    <PieChart
      title={optionSelector()}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.ventasPorCategoriaDeCliente.total)}`}
      series={data()}
      labels={labels()}
      valoresNegativos={valoresNegativos()}
      chartType={'pie'}
      disabledCss
      loading={props.ventasPorCategoriaDeCliente.loading}
      imgSinDatos={props.ventasPorCategoriaDeCliente.values.filter((val) => val > 0).length === 0}
      height={436}
      width={'100%'}
      legendHeight={50}
      legendPosition={'bottom'}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fechaVentasDelMes: state.sucursales.fechaVentasDelMesAnioDeSucursales,
    dataSelectedId: state.sucursales.dataVentasDeSucursalesPorSeleccion,

    ventasPorCategoriaDeCliente: state.sucursales.ventasPorSucursalPorCategoriaDeClienteDelMes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => dispatch(actions.seleccionDataVentasDeSucursalesPor(selecionId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SunburstVentasPorSucursalPorSeleccion);
