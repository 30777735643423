// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaStockPorDeposito__tableStockPorDepositoContainer__f4Lq9 {\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n  }\r\n  ", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableStockPorDepositoContainer": "TablaStockPorDeposito__tableStockPorDepositoContainer__f4Lq9"
};
export default ___CSS_LOADER_EXPORT___;
