import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  textoSaldoBancos: {
    loading: false,
    error: null,
    saldo: 0
  }
};

//#region load texto saldo bancos
const loadTextoSaldoBancosStart = (state) => {
  return updateObject(state, {
    textoSaldoBancos: updateObject(state.textoSaldoBancos, {
      loading: true,
      error: null,
      saldo: 0
    })
  });
};

const loadTextoSaldoBancosSuccess = (state, saldo) => {
  return updateObject(state, {
    textoSaldoBancos: updateObject(state.textoSaldoBancos, {
      loading: false,
      saldo: saldo
    })
  });
};

const loadTextoSaldoBancosFail = (state, error) => {
  return updateObject(state, {
    textoSaldoBancos: updateObject(state.textoSaldoBancos, {
      loading: false,
      error: error
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEXTO_SALDO_BANCOS_START:
      return loadTextoSaldoBancosStart(state);

    case actionTypes.TEXTO_SALDO_BANCOS_SUCCESS:
      return loadTextoSaldoBancosSuccess(state, action.saldo);

    case actionTypes.TEXTO_SALDO_BANCOS_FAIL:
      return loadTextoSaldoBancosFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;
