import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import locales from '../../../../shared/locales';
import { formatNumber, formatNumberWithMonedaSigno, formatNumberWithoutDecimals, formatNumberWithoutDecimalsWithMonedaSigno, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Table from '../../../UI/Table/Table';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { ChevronRight } from "@material-ui/icons";
import { Grid, TableCell } from '@material-ui/core';
import classes from './TablaRentabilidadPorSucursales.css';
import ExportToExcelIcon from '../../../UI/Icons/ExportToExcelIcon';
import CustomSelector from '../../../UI/CustomSelector/CustomSelector';
import { capitalizeName } from '../../../../shared/utility';

const TablaRentabilidadPorSucursales = (props) => {
  const { ejecucionDePagina, loading, estaCargado, gastosColums, itemData, dataSucursales,
    onLoadChart, withoutDecimals, fechaPeriodo, sucursales } = props;
  const { utilidades = [] } = itemData;

  //#region Selectores
  const [verOptions] = useState([
    { id: 0, nombre: "Ver montos" },
    { id: 1, nombre: "Ver porcentajes" }
  ]);
  const [verOptionSelected, setVerOptionSelected] = useState(verOptions[0]);

  const onOptionVerChanged = (value) => {
    setVerOptionSelected(value);
  }

  const formatMesesToStrings = () => {
    return utilidades.map((item) => ({
      id: `${item.mes}-${item.anio}`,
      nombre: `${locales[1].options.months[item.mes - 1]} ${item.anio}`,
      anio: item.anio,
      mes: item.mes
    }));
  };

  const [mesOptions, setMesOptions] = useState([]);
  const [mesOptionSelected, setMesOptionSelected] = useState(0);

  const onOptionMesChange = (value) => {
    setMesOptionSelected(value);
  }

  useEffect(() => {
    if (utilidades.length > 0 && mesOptions.length === 0) {
      const months = formatMesesToStrings();
      setMesOptions(months);
      setMesOptionSelected(months[0]);
    }

  }, [utilidades, mesOptions]);
  //#endregion

  //#region Table Data

  const tableRef = createRef();

  const cellStyles = {
    paddingBottom: '2px',
    paddingTop: '2px',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderBottom: '0px'
  };

  const headerStyles = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderBottom: '0px',
  };

  const inPorc = verOptionSelected && verOptionSelected.id === 1;

  const data = () => {
    let formatData = [];
    if (sucursales && sucursales.length > 0 && mesOptionSelected && mesOptionSelected.anio) {
      let endText = inPorc ? '%' : storageMonedaSigno();
      let rowVentas = { fecha: `Ventas $`, id: 1 };
      let rowCostos = { fecha: `Costos ${endText}`, id: 2 };
      let rowUtilidad = { fecha: `Utilidad ${endText}`, id: 3 };
      let rowResultado = { fecha: `Resultado ${endText}`, id: 4 };
      let totalVentas = 0;
      let totalCostos = 0;
      let totalUtilidad = 0;
      let totalGanancia = 0;

      sucursales.map((sucursal) => {
        let dataSucursal = dataSucursales && dataSucursales.find((item) => item.sucursalId === sucursal.id);
        if (dataSucursal) {
          var venta = dataSucursal.ventas && dataSucursal.ventas.find((vnt) => vnt.mes === mesOptionSelected.mes
            && vnt.anio === mesOptionSelected.anio);
          const vntMonto = venta ? venta.monto : 0;
          rowVentas[`${sucursal.id}`] = inPorc ? 100 : vntMonto;
          totalVentas += vntMonto;

          var costo = dataSucursal.costos && dataSucursal.costos.find((item) => item.mes === mesOptionSelected.mes
            && item.anio === mesOptionSelected.anio);
          const montoCosto = costo ? costo.monto : 0;
          const porcCosto = costo ? (costo.monto / vntMonto) * 100 : 0;
          rowCostos[`${sucursal.id}`] = inPorc ? porcCosto : montoCosto;
          totalCostos += montoCosto;

          var utilidad = dataSucursal.utilidad && dataSucursal.utilidad.find((item) => item.mes === mesOptionSelected.mes
            && item.anio === mesOptionSelected.anio);
          const montoUtilidad = utilidad ? utilidad.monto : 0;
          const porcUtilidad = utilidad ? (utilidad.monto / vntMonto) * 100 : 0;
          rowUtilidad[`${sucursal.id}`] = inPorc ? porcUtilidad : montoUtilidad;
          totalUtilidad += montoUtilidad;

          var ganancia = dataSucursal.ganancia && dataSucursal.ganancia.find((item) => item.mes === mesOptionSelected.mes
            && item.anio === mesOptionSelected.anio);
          const montoResultado = ganancia ? ganancia.monto : 0;
          const porcResultado = ganancia ? (ganancia.monto / vntMonto) * 100 : 0;
          rowResultado[`${sucursal.id}`] = inPorc ? porcResultado : montoResultado;
          totalGanancia += montoResultado;
        }
      });

      rowVentas['total'] = inPorc ? 100 : totalVentas;
      formatData.push(rowVentas);

      const totalPorcCostos = (totalCostos / totalVentas) * 100;
      rowCostos['total'] = inPorc ? totalPorcCostos : totalCostos;
      formatData.push(rowCostos);

      const totalPorcUtilidad = (totalUtilidad / totalVentas) * 100;
      rowUtilidad['total'] = inPorc ? totalPorcUtilidad : totalUtilidad;
      formatData.push(rowUtilidad);

      if (gastosColums && gastosColums.length > 0) {
        gastosColums.forEach((gastoCol) => {
          let rowGasto = { fecha: `${gastoCol.nombre} ${endText}`, id: gastoCol.gruposGastosId, rgb: '50bbed', total: 0 };
          let totalGasto = 0;
          let rowsCategorias = [];
          let rowsItemsDeCompra = [];

          sucursales.map((sucursal) => {
            let monto = 0;
            let porc = 0;
            let dataSucursal = dataSucursales && dataSucursales.find((item) => item.sucursalId === sucursal.id);

            if (dataSucursal) {
              var venta = dataSucursal.ventas && dataSucursal.ventas.find((vnt) => vnt.mes === mesOptionSelected.mes
                && vnt.anio === mesOptionSelected.anio);
              const totalVentasSuc = venta ? venta.monto : 0;

              if (dataSucursal.gruposGastos && dataSucursal.gruposGastos.length > 0) {
                let grupoGastos = dataSucursal.gruposGastos.find((gg) => gg.gruposGastosId === gastoCol.gruposGastosId);

                if (grupoGastos) {
                  let gasto = grupoGastos && grupoGastos.gastos && grupoGastos.gastos.find((item) => item.mes === mesOptionSelected.mes
                    && item.anio === mesOptionSelected.anio);
                  monto = gasto ? gasto.monto : 0;
                  porc = gasto ? (gasto.monto / totalVentasSuc) * 100 : 0;

                  if (grupoGastos.categorias && grupoGastos.categorias.length > 0) {
                    grupoGastos.categorias.forEach((categoria) => {
                      let gastoCateg = categoria.gastos && categoria.gastos.find((item) => item.mes === mesOptionSelected.mes && item.anio === mesOptionSelected.anio);
                      let montoCateg = gastoCateg ? gastoCateg.monto : 0;
                      let porcCateg = gastoCateg ? (gastoCateg.monto / totalVentasSuc) * 100 : 0;
                      let rowCategoria = rowsCategorias.find((rowCateg) => rowCateg.id === categoria.categoriaId && rowCateg.parentId === gastoCol.gruposGastosId);

                      if (rowCategoria) {
                        rowCategoria[`${sucursal.id}`] = inPorc
                          ? porcCateg === 0 || isNaN(porcCateg) ? 0 : porcCateg
                          : montoCateg === 0 || isNaN(montoCateg) ? 0 : montoCateg;
                        let tempTotal = rowCategoria.tempTotal + montoCateg;
                        const tempTotalPorc = (tempTotal / totalVentas) * 100;
                        rowCategoria.tempTotal = tempTotal;
                        rowCategoria.total = inPorc
                          ? tempTotalPorc === 0 || isNaN(tempTotalPorc) ? 0 : tempTotalPorc
                          : tempTotal === 0 || isNaN(tempTotal) ? 0 : tempTotal;
                      } else {
                        rowCategoria = {
                          fecha: categoria.categoria,
                          id: categoria.categoriaId,
                          parentId: gastoCol.gruposGastosId,
                          rgb: '61cc7e',
                          total: inPorc
                            ? porcCateg === 0 || isNaN(porcCateg) ? 0 : porcCateg
                            : montoCateg === 0 || isNaN(montoCateg) ? 0 : montoCateg,
                          tempTotal: montoCateg
                        };

                        sucursales.forEach((suc) => {
                          rowCategoria[`${suc.id}`] = 0;
                        });

                        rowCategoria[`${sucursal.id}`] = inPorc
                          ? porcCateg === 0 || isNaN(porcCateg) ? 0 : porcCateg
                          : montoCateg === 0 || isNaN(montoCateg) ? 0 : montoCateg;
                        rowsCategorias.push(rowCategoria);
                      }

                      if (categoria.itemsDeCompra && categoria.itemsDeCompra.length > 0) {
                        categoria.itemsDeCompra.forEach((itemDeCompra) => {
                          let gastoCompra = itemDeCompra.gastos && itemDeCompra.gastos.find((item) => item.mes === mesOptionSelected.mes && item.anio === mesOptionSelected.anio);
                          let montoCompra = gastoCompra ? gastoCompra.monto : 0;
                          let porcCompra = gastoCompra ? (gastoCompra.monto / totalVentasSuc) * 100 : 0;
                          let rowCompra = rowsItemsDeCompra.find((row) => row.id === itemDeCompra.itemDeCompraId && row.parentId === categoria.categoriaId);

                          if (rowCompra) {
                            rowCompra[`${sucursal.id}`] = inPorc
                              ? porcCompra === 0 || isNaN(porcCompra) ? 0 : porcCompra
                              : montoCompra === 0 || isNaN(montoCompra) ? 0 : montoCompra;
                            const tempTotalCompra = rowCompra.tempTotal + montoCompra;
                            const tempTotalCompraPorc = (tempTotalCompra / totalVentas) * 100;
                            rowCompra.tempTotal = tempTotalCompra;
                            rowCompra.total = inPorc
                              ? tempTotalCompraPorc === 0 || isNaN(tempTotalCompraPorc) ? 0 : tempTotalCompraPorc
                              : tempTotalCompra === 0 || isNaN(tempTotalCompra) ? 0 : tempTotalCompra;
                          } else {
                            rowCompra = {
                              id: itemDeCompra.itemDeCompraId,
                              fecha: itemDeCompra.itemDeCompra,
                              parentId: categoria.categoriaId,
                              total: inPorc
                                ? porcCompra === 0 || isNaN(porcCompra) ? 0 : porcCompra
                                : montoCompra === 0 || isNaN(montoCompra) ? 0 : montoCompra,
                              tempTotal: montoCompra
                            };

                            sucursales.forEach((suc) => {
                              rowCompra[`${suc.id}`] = 0;
                            });

                            rowCompra[`${sucursal.id}`] = inPorc
                              ? porcCompra === 0 || isNaN(porcCompra) ? 0 : porcCompra
                              : montoCompra === 0 || isNaN(montoCompra) ? 0 : montoCompra;
                            rowsItemsDeCompra.push(rowCompra);
                          }
                        });
                      }
                    });
                  }
                }
              }
              totalGasto += monto;
              rowGasto[`${sucursal.id}`] = inPorc
                ? porc === 0 ? 0 : porc
                : monto === 0 ? 0 : monto;
            }
          });
          const totalPorcGasto = (totalGasto / totalVentas) * 100;
          rowGasto.total = inPorc ? totalPorcGasto : totalGasto;
          formatData.push(rowGasto);

          formatData = formatData.concat(rowsCategorias);
          formatData = formatData.concat(rowsItemsDeCompra);
        });
      }

      const totalPorcGanancia = (totalGanancia / totalVentas) * 100;
      rowResultado['total'] = inPorc ? totalPorcGanancia : totalGanancia;
      formatData.push(rowResultado);
    }

    return formatData;
  }

  const columns = () => {
    let cols = [
      {
        title: 'Concepto',
        field: 'fecha',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyles
      }];

    if (sucursales && sucursales.length > 0) {
      sucursales.forEach((suc) => {
        cols.push({
          title: suc.nombre,
          field: `${suc.id}`,
          align: 'right',
          type: 'numeric',
          width: 20,
          cellStyle: cellStyles,
          headerStyle: headerStyles
        });
      });
    }

    cols.push({
      title: 'Total',
      field: 'total',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    });

    return cols;
  }

  const buildTableCellWithValidations = (cellProps) => {
    const field = cellProps.columnDef.field;
    const data = cellProps.rowData[field];
    const inFecha = field === 'fecha';

    return (
      <TableCell
        className={classes.TableRentabilidadCell}
        style={{ textAlign: !inFecha && 'right', minWidth: inFecha ? '140px' : '110px' }}
      >
        {inFecha
          ? data && data.indexOf('IVA') > -1 ? data : capitalizeName(data)
          : inPorc
            ? buildPorcResultadoWithTrendingIcon(data)
            : withoutDecimals ? formatNumberWithoutDecimals(data) : formatNumber(data)
        }
      </TableCell>
    );
  };

  const buildPorcResultadoWithTrendingIcon = (value) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
        {`${formatNumber(Math.abs(value))} % `}
        {value === 0 || value === 'NaN' ? (
          <TrendingFlatIcon style={{ color: 'black', width: '25px' }} />
        ) : value > 0 ? (
          <TrendingUpIcon style={{ color: 'green', width: '25px' }} />
        ) : (
          <TrendingDownIcon style={{ color: 'red', width: '25px' }} />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (ejecucionDePagina && !loading && !estaCargado)
      onLoadChart(fechaPeriodo.mes, fechaPeriodo.anio);
  }, [ejecucionDePagina, loading, estaCargado, fechaPeriodo]);

  //#endregion

  //#region Excel Data
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isParent) => {
    return {
      font: { sz: isParent ? '12' : '10', color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
    };
  };

  const formatData = () => {
    const rows = data();
    const cols = columns();
    var formatData = [];

    rows.map((row) => {
      const isParent = row && !row.parentId;
      var formatRow = [];
      cols.map((col) => {
        let cell = {};

        if (col.field === 'fecha') {
          cell = {
            value: capitalizeName(row.fecha),
            style: {
              font: { sz: isParent ? '11' : '10', bold: isParent },
              fill: row && row.rgb && { fgColor: { rgb: row.rgb } }
            }
          };
        } else {
          const value = inPorc
            ? `${formatNumber(row[col.field])} %`
            : withoutDecimals
              ? `${formatNumberWithoutDecimalsWithMonedaSigno(row[col.field])}`
              : `${formatNumberWithMonedaSigno(row[col.field])}`;
          cell = {
            value,
            style: cellsStyleVerifNegative(row[col.field], isParent)
          };
        }

        return formatRow.push(cell);
      });
      return formatData.push(formatRow);
    });

    return formatData;
  };

  const formatColumns = () => {
    var cols = [];

    columns().map((column) => {
      var col = {};

      if (column.field && column.field === 'fecha') {
        col = {
          title: 'Concepto',
          width: { wch: 60 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        };
      } else {
        col = {
          title: column.title,
          width: { wpx: 150 },
          style: headersStyle
        };
      }

      return cols.push(col);
    });

    return cols;
  }

  const multiDataSet = [
    {
      columns: formatColumns(),
      data: formatData()
    }
  ];

  //#endregion 

  return (
    <Table
      tableRef={tableRef}
      title=""
      style={{ paddingRight: '5px', paddingTop: '5px' }}
      loading={loading && !estaCargado}
      columns={columns()}
      data={data()}
      parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
      icons={{
        DetailPanel: ChevronRight
      }}
      components={{
        Toolbar: (props) => {
          return (
            <Grid container direction='row' justifyContent='space-between' style={{ paddingInline: '10px', marginBottom: '10px' }}>
              <Grid item xs={10} lg={3} container direction='row' justifyContent='space-between'>
                <Grid item xs={6} style={{ paddingRight: '10px' }}>
                  <CustomSelector
                    id="selectVer"
                    label=""
                    value={verOptionSelected}
                    items={verOptions}
                    onChange={(event) => onOptionVerChanged(event.target.value)}
                    isLoading={loading}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                  <CustomSelector
                    id="selectMes"
                    label=""
                    value={mesOptionSelected}
                    items={mesOptions}
                    onChange={(event) => onOptionMesChange(event.target.value)}
                    isLoading={loading}
                  />
                </Grid>
              </Grid>
              <Grid item xs={2} lg={1} style={{ textAlign: 'end' }}>
                <ExportToExcelIcon
                  filename={"Resultado por Sucursales"}
                  name={`${mesOptionSelected ? mesOptionSelected.nombre : ''} ${inPorc ? ' - Por porcentaje' : ' - Por monto'}`}
                  dataSet={multiDataSet}
                />
              </Grid>
            </Grid>
          )
        },
        Cell: (props) => {
          return buildTableCellWithValidations(props);
        }
      }}
      customOptions={{
        thirdSortClick: false,
        sorting: true,
        grouping: false,
        draggable: false,
        search: false,
        pageSize: 13,
        paging: false,
        rowStyle: (rowData, index) => ({
          backgroundColor: !isEven(index) && '#f2f2f2'
        }),
        headerStyle: { borderBottomColor: 'white' }
      }}
      tableKey={"tabla-rentabilidad-por-sucursales"}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    sucursales: state.filtros.sucursales.sucursales,
    loading: state.integradores.rentabilidadEvolutivoPorSucursales.loading,
    estaCargado: state.integradores.rentabilidadEvolutivoPorSucursales.estaCargado,
    dataSucursales: state.integradores.rentabilidadEvolutivoPorSucursales.data,
    gastosColums: state.integradores.rentabilidadEvolutivoPorSucursales.columns,
    itemData: state.integradores.lineRentabilidadEvolutivo,
    filtro: state.integradores.filtroRentabilidadPorSucuersales,
    fechaPeriodo: state.integradores.fechaPeriodoRentabilidad,
    loadingSucursales: state.filtros.sucursales.loading,
    estaCargadoSucursales: state.filtros.sucursales.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio) => dispatch(actions.loadRentabilidadEvolutivoPorSucursales(mes, anio)),
    onFilterChange: (mes, anio, seleccion) => dispatch(actions.seleccionFiltroRentabilidadPorSucursales(mes, anio, seleccion)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Gastos mensuales por categoria', ['gasto'])),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaRentabilidadPorSucursales);