// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SunburstVentasPorSucursalPorSeleccion__LabelVerPor__d5hCf {\r\n  font-weight: bold;\r\n}\r\n\r\n.SunburstVentasPorSucursalPorSeleccion__SelectRoot__Sh9jg {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LabelVerPor": "SunburstVentasPorSucursalPorSeleccion__LabelVerPor__d5hCf",
	"SelectRoot": "SunburstVentasPorSucursalPorSeleccion__SelectRoot__Sh9jg"
};
export default ___CSS_LOADER_EXPORT___;
