// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaComparacionVentasDeSucursalesDelClientePorArticulo__SorteableHeaderTitle__LzT55:hover {\r\n  color: rgba(0, 0, 0, 0.54);\r\n  cursor: pointer;\r\n}\r\n\r\n.TablaComparacionVentasDeSucursalesDelClientePorArticulo__TablasComparacionComprobantesContainer__wiKPt {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SorteableHeaderTitle": "TablaComparacionVentasDeSucursalesDelClientePorArticulo__SorteableHeaderTitle__LzT55",
	"TablasComparacionComprobantesContainer": "TablaComparacionVentasDeSucursalesDelClientePorArticulo__TablasComparacionComprobantesContainer__wiKPt"
};
export default ___CSS_LOADER_EXPORT___;
