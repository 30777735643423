import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fechaActualFunc } from '../../../../../shared/fechas';
import * as actions from '../../../../../store/actions';
import TablaInteranualCubo from '../../../../UI/Interanual/TablaInteranualCubo/TablaInteranualCubo';
import moment from "moment";

const TablaInteranualCuboUtilidad = (props) => {
  const { fecha, evoluciones, periodoSeleccionado, ejecucionDePagina } = props;
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [fechaFija] = useState(fechaActualFunc());

  const onLoadChartSuccess = (utilidades, dataTable, total) => {
    setDataTable(dataTable);
    setTotal(total);
    setLoading(false);
  }

  const onLoadChartFail = () => {
    setLoading(false);
  }

  useEffect(() => {
    if (ejecucionDePagina) {
      let item = evoluciones.find((evo) => evo.anio === fecha.getFullYear());
      
      if(item !== undefined){
        setDataTable(item.utilidadesDataTable);
        setTotal(item.totalUtilidades);
      }
    }
  }, [ejecucionDePagina, evoluciones, fecha]);

  useEffect(() => {
    if (ejecucionDePagina) {
      let item = evoluciones.find((evo) => evo.anio === fecha.getFullYear());
      
      if(item === undefined){
        setLoading(true);
        setDataTable([]);
        setTotal(0);
        props.onLoadChart(fechaFija.getMonth() + 1, fecha.getFullYear(), onLoadChartSuccess, onLoadChartFail);
      }
    }
  }, [ejecucionDePagina, evoluciones, fecha]);

  const onCategoriaClick = (mes, anio, index) => {
    props.onChangeFechaUtilidadDelMes(mes, anio);
    props.onLoadUtilidadPorCategoriasDeCliente(mes, anio);
    props.onChangeOptionSelected();
  };

  const getTitle = () => {
    const start = `${moment(new Date(fecha.getFullYear() - 1, fechaFija.getMonth(), 1)).format('MM/YY')}`;
    const end = `${moment(new Date(fecha.getFullYear(), fechaFija.getMonth(), 1)).format('MM/YY')}`;

    return `Utilidad desglosada por mes del ${start} a ${end}`;
  }

  return (
    <TablaInteranualCubo
      addMonth
      title={getTitle()}
      titleMontos={'Utilidades'}
      datas={dataTable}
      fecha={fecha}
      loading={loading}
      total={total}
      onSelectMesAnioClick={(mes, anio) => onCategoriaClick(mes, anio)}
      onShowFiltersModal={() => props.onShowFiltersModal()}
      onEliminarPeriodo={props.onEliminarPeriodo}
      periodoSeleccionado={periodoSeleccionado}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataVentasDelMesPorSelected,
    evoluciones: state.ventas.evolucionUtilidadesMensualInteranual.items,
    periodoSeleccionado: state.ventas.evolucionUtilidadesMensualInteranual.periodoSeleccionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Utilidad desglosada por mes', ['venta'])),
    onLoadChart: (mes, anio, onSuccess, onFail) => 
      dispatch(actions.loadEvolucionDeUtilidadMensualInteranual(mes, anio, onSuccess, onFail)),
    onChangeFechaUtilidadDelMes: (mes, anio) => dispatch(actions.seleccionFechaUtilidadDelMesPor(mes, anio)),
    onLoadUtilidadPorCategoriasDeCliente: (mes, anio) => {
      dispatch(actions.loadUtilidadPorCategoriaDeCliente(mes, anio));
      dispatch(actions.clearUtilidadDelMesPorMarca());
      dispatch(actions.clearUtilidadDelMesPorRubro());
      dispatch(actions.clearUtilidadDelMesPorSubrubro());
      dispatch(actions.clearUtilidadDelMesPorProvincia());
      dispatch(actions.clearUtilidadDelMesPorVendedor());
      dispatch(actions.clearUtilidadDelMesPorZona());
    },
    onChangeOptionSelected: () => dispatch(actions.seleccionDataUtilidadDelMesPor('categoriaDeCliente'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaInteranualCuboUtilidad);
