// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaDeudaClientes__TablaDeudaClientesContainer__8c9KA {\r\n    background-color: white;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 3px;\r\n    box-shadow: 0px 3px 6px #00000029;\r\n    width: 100% !important;\r\n    height: 100% !important;\r\n    /* height: 485px !important; */\r\n  }\r\n  \r\n  .TablaDeudaClientes__TitleCard__29V9b {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    padding-right: 5px;\r\n    align-content: center;\r\n    padding-right: 0;\r\n  }\r\n  \r\n  .TablaDeudaClientes__titleContent__fTNdT {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-right: 5px;\r\n  }\r\n  \r\n  .TablaDeudaClientes__titleTexto__i9Hn5 {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  @media (max-width: 750px) { \r\n    .TablaDeudaClientes__TablaDeudaClientesContainer__8c9KA {\r\n      max-width: 92vw !important;\r\n    }\r\n  }", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablaDeudaClientesContainer": "TablaDeudaClientes__TablaDeudaClientesContainer__8c9KA",
	"TitleCard": "TablaDeudaClientes__TitleCard__29V9b",
	"titleContent": "TablaDeudaClientes__titleContent__fTNdT",
	"titleTexto": "TablaDeudaClientes__titleTexto__i9Hn5"
};
export default ___CSS_LOADER_EXPORT___;
