import axios from '../../axios-dashboard';
import { storageToken } from '../../shared/sessionData';
import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';

const executeCreateGrupoEmpresas = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_GRUPO_EMPRESAS,
    loading
  };
};

export const createGrupoEmpresas = (nuevoGrupoEmpresas, success) => {
  return (dispatch) => {
    dispatch(executeCreateGrupoEmpresas(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post('/gruposempresas/grupoempresas', nuevoGrupoEmpresas, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(executeCreateGrupoEmpresas(false));
        dispatch(
          enqueueSnackbar({
            message: `Grupo de Empresas creado con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        return success();
      })
      .catch((error) => {
        dispatch(executeCreateGrupoEmpresas(false));
      });
  };
};

const executeUpdateGrupoEmpresas = (loading) => {
  return {
    type: actionTypes.EXECUTE_UPDATE_GRUPO_EMPRESAS,
    loading
  };
};

export const updateGrupoEmpresas = (grupoEmpresasId, updatedGrupoEmpresas) => {
  return (dispatch) => {
    dispatch(executeUpdateGrupoEmpresas(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .put(`/gruposempresas/grupoempresas?grupoEmpresasId=${grupoEmpresasId}`, updatedGrupoEmpresas, {
        headers: { Authorization: authStr }
      })
      .then((_response) => {
        dispatch(
          enqueueSnackbar({
            message: `Grupo de Empresas modificado con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(executeUpdateGrupoEmpresas(false));
      })
      .catch((error) => {
        dispatch(executeUpdateGrupoEmpresas(false));
      });
  };
};
