import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './PieTableVentasMontoUnidadPorSeleccionTenacta.css';
import TablaVentasDesagregadasComprobantes from './TablaVentasDesagregadasComprobantesTenacta';
import { TableHead, TableCell, TableRow } from '@material-ui/core';
import { compareByAsc, compareByDesc } from '../../../../shared/sorting';

const TablaVentasDesagregadasMontoUnidad = (props) => {
  const { dataSelectedId, dataOptions, dataSelectOptions, onLoadVentasDesagregadasDelMes,
    fechaVentasDelMes, vendedorId, currentLevel, nextLevel, filtrosParent, filtroSeleccionado } = props;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalMonto, setTotalMonto] = useState(0);
  const [totalUnidades, setTotalUnidades] = useState(0);
  const [titleTotal] = useState(`TOTAL ${props.itemExpanded ? props.itemExpanded.nombre : ''}`);
  const tableRef = useRef();

  const getFechasForLoad = () => {
    let result = {};

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      result = {
        mesDesde: filtroSeleccionado.desde.getMonth() + 1,
        anioDesde: filtroSeleccionado.desde.getFullYear(), 
        mesHasta: filtroSeleccionado.hasta.getMonth() + 1, 
        anioHasta: filtroSeleccionado.hasta.getFullYear()
      }
    }else{
      result = {
        mesDesde: props.mes ? props.mes : props.itemExpanded.mesDesde ? props.itemExpanded.mesDesde : fechaVentasDelMes.mes, 
        anioDesde: props.anio ? props.anio : props.itemExpanded.anioDesde ? props.itemExpanded.anioDesde : fechaVentasDelMes.anio,  
        mesHasta: props.mes ? props.mes : props.itemExpanded.mesHasta ? props.itemExpanded.mesHasta : fechaVentasDelMes.mes,  
        anioHasta: props.anio ? props.anio : props.itemExpanded.anioHasta ? props.itemExpanded.anioHasta : fechaVentasDelMes.anio, 
      }
    }

    return result;
  }

  const onLoadSuccess = (ventas, ventasNegativo, totalVentas, fecha, totUnidades) => {
    setLoading(false);
    setVentasLocal(ventas);
    setTotalMonto(totalVentas);
    setTotalUnidades(totUnidades);
    props.endRequest && props.endRequest(ventas.length);
  }

  const onLoadFail = () => {
    setLoading(false);
    setVentasLocal([]);
    setTotalMonto(0);
    setTotalUnidades(0);
  }

  useEffect(() => {
    setLoading(true);
    const fecha = getFechasForLoad();
    onLoadVentasDesagregadasDelMes(props.itemExpanded.id, currentLevel.key, nextLevel.key, fecha.mesDesde, fecha.anioDesde, fecha.mesHasta, fecha.anioHasta, filtrosParent, onLoadSuccess, onLoadFail);
  }, []);

  const getCurrentLevel = () => {
    return nextLevel;
  }

  const getCurrentLevelKey = () => {
    return nextLevel ? nextLevel.key : "";
  }

  const getCurrentLevelTitle = () => {
    let title = nextLevel && nextLevel.key
      ? dataOptions[nextLevel.key.toLowerCase()].title
      : dataOptions[dataSelectedId].title;

    return title;
  }

  const getNextLevel = () => {
    return nextLevel ? nextLevel.subNivel : undefined;
  }

  const getDataSelectedId = () => {
    return nextLevel ? nextLevel.key ? nextLevel.key.toLowerCase() : "" : "";
  }

  const mostrarComprobantes = () => {
    const disabled = getNextLevel() === undefined || getNextLevel() === null;
    return disabled;
  }

  const getFiltrosParent = () => {
    let tempFiltros = [];
    let id = props.itemExpanded.id;

    if (filtrosParent && filtrosParent.length > 0) {
      tempFiltros = tempFiltros.concat(filtrosParent);
    }

    const existItem = tempFiltros.find((filtro) => filtro.entidad === currentLevel.key);

    if(existItem){
      tempFiltros.forEach((filtro) => {
        if(filtro.entidad === currentLevel.key)
          filtro.id = id;
      });
    }else{
      tempFiltros.push({ id: id, entidad: currentLevel.key });
    }   

    return tempFiltros;
  }

  const columns = [
    {
      title: getCurrentLevelTitle(),
      field: 'nombre',
      render: (rowData) => rowData && rowData.nombre ? rowData.id ? rowData.id.trim() + ' - ' + rowData.nombre.trim() : rowData.nombre.trim() : '',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '10px',
        paddingTop: '10px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => props.withoutDecimals ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(rowData.monto)}` : `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    },
    {
      title: ``,
      field: 'porcParticipacionUnidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacionUnidades)}%`
    },
    {
      title: ``,
      field: 'unidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumberWithoutDecimals(rowData.unidades)}`
    }
  ];

  const data = () => {
    const tableData = [];    
    const fecha = getFechasForLoad();

    ventasLocal && ventasLocal.map((venta) => {
      return tableData.push({
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        unidades: venta.unidadesVendidas,
        porcParticipacionUnidades: venta.porcentajeDeParticipacionUnidades,        
        mesDesde: fecha.mesDesde, 
        anioDesde: fecha.anioDesde, 
        mesHasta: fecha.mesHasta, 
        anioHasta: fecha.anioHasta
      });
    });

    tableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    if (ventasLocal && ventasLocal.length > 0) {
      tableData.push({
        monto: totalMonto,
        porcentajeDeParticipacion: 100,
        unidades: totalUnidades,
        porcParticipacionUnidades: 100,
        id: null,
        nombre: titleTotal,     
        mesDesde: fecha.mesDesde, 
        anioDesde: fecha.anioDesde, 
        mesHasta: fecha.mesHasta, 
        anioHasta: fecha.anioHasta
      });
    }

    return tableData;
  };

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);
      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  const getMaxBodyHeight = () => {
    return ventasLocal && ventasLocal.length < 5 ? 250 : ventasLocal.length < 10 ? 350 : 450;
  }

  const [sortConfig, setSortConfig] = useState({ key: 'nombre', direction: 'asc' });
  const onSort = useCallback((sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  }, [sortConfig]);

  return (
    <div className={classes.TablaVentasDesagregadasMontoUnidad}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '98%', marginTop: 0, marginBottom: '10px' }}
        columns={columns}
        loading={loading}
        data={data()}
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>                  
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  />
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('nombre')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      width: window.screen.width < 1367 ? '265px' : '450px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="left"
                  >
                    {getCurrentLevelTitle()}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcentajeDeParticipacion')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  />
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  />
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacionUnidades')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  />
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('unidades')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  />
                </TableRow>
              </TableHead>
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            render: (rowData) => {
              return (
                <div className={classes.TablaVentasDesagregadasMontoUnidad}>
                  <div style={{ width: '100%' }}>
                    {mostrarComprobantes()
                      ? (
                        <TablaVentasDesagregadasComprobantes
                          fechaVentasDelMes={fechaVentasDelMes}
                          itemExpanded={rowData}
                          tipoParent={getCurrentLevelKey()}
                          filtrosParent={getFiltrosParent()}
                          withoutDecimals={props.withoutDecimals}
                          filtroSeleccionado={filtroSeleccionado}
                        />
                      ) : (rowData.id !== null & rowData.id !== undefined) ? (
                        <TablaVentasDesagregadasMontoUnidad
                          endRequest={() => autoScroll(rowData)}
                          dataSelectedId={getDataSelectedId()}
                          dataOptions={dataOptions}
                          dataSelectOptions={dataSelectOptions}
                          vendedorId={vendedorId}
                          fechaVentasDelMes={fechaVentasDelMes}
                          itemExpanded={rowData}
                          incluirUnidades={props.incluirUnidades}
                          withoutDecimals={props.withoutDecimals}
                          onLoadVentasDesagregadasDelMes={onLoadVentasDesagregadasDelMes}
                          currentLevel={getCurrentLevel()}
                          nextLevel={getNextLevel()}
                          filtrosParent={getFiltrosParent()}
                          filtroSeleccionado={filtroSeleccionado}
                        />
                      ) : (
                        <Fragment></Fragment>
                      )
                    }
                  </div>
                </div>
              );
            }
          }
        ]}
        customOptions={{
          thirdSortClick: false,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          minBodyHeight: 250,
          maxBodyHeight: getMaxBodyHeight(),
          rowStyle: (rowData, index) => ({
            cursor: 'auto',
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: (rowData.nombre === titleTotal && '#A3CCE8') || (!isEven(index) && '#f2f2f2')
          }),
          cellStyle: (cellData) => ({
            fontWeight: cellData === titleTotal && 'bolder'
          })
        }}
        tableKey={"tabla-ventas-desagregadas-monto-unidad"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filtroSeleccionado: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasDesagregadasDelMes: (id, parent, child, mesDesde, anioDesde, mesHasta, anioHasta, filtrosParent, success, errorFail) =>
      dispatch(actions.loadVentasDesagregadasDelMesPorTipoDeVenta(id, parent, child, mesDesde, anioDesde, mesHasta, anioHasta, filtrosParent, success, errorFail)),

    onClearVendedorId: () => dispatch(actions.seleccionVendedorVentasDelMesSelected(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDesagregadasMontoUnidad);