import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from "@material-ui/core";
import Spinner from "../../Spinner/Spinner";

const useStylesSelector = makeStyles(() => ({
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  selectorNoAprobado: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
      backgroundColor: '#ffafaf'
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  options: {
    fontSize: 'small',
  },
  optionsNoAprobado: {
    fontSize: 'small',
    backgroundColor: '#ffafaf'
  },
}));

const BudgetForecastSelector = (props) => {
  const classes = useStylesSelector();
  const { id, label, value, items, onChange, seleccione, isLoading,
    disabled, required, error, helperText } = props;

  const idSelect = `${id}Select`;

  return isLoading ? (
    <Spinner size="small" />
  ) : (
    <TextField
      id={idSelect}
      select
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      placeholder={label}
      style={{ color: "black", width: "100%" }}
      disabled={disabled}
      required={required}
      className={value ? value.aprobado ? classes.selector : classes.selectorNoAprobado : classes.selector}      
      fullWidth={true}
    >
      {seleccione &&
        <MenuItem
          className={classes.options}
          key="select"
          value="select">
          SELECCIONE...
        </MenuItem>
      }
      {items && items.map((item, index) => (
        <MenuItem
          className={item.aprobado ? classes.options : classes.optionsNoAprobado}
          key={index}
          value={item}
        >
          {item.nombre && item.nombre.trimEnd().toUpperCase()}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default BudgetForecastSelector;