// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaNotasDePedido__infoComprobanteContainer__HJ1GA {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    flex-direction: column;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .TablaNotasDePedido__infoComprobanteRow__vdbcv {\r\n    display: flex;\r\n  }\r\n  \r\n  .TablaNotasDePedido__titleTotales__jo9iG {\r\n    font-size: small;\r\n    font-weight: 500;\r\n    margin-bottom: 10px !important;\r\n    text-align: right;\r\n  }\r\n  \r\n  .TablaNotasDePedido__Spinner__Bq7I5 {\r\n    position: absolute;\r\n    top: 25%;\r\n    right: 44%;\r\n    z-index: 1500;\r\n  }\r\n  \r\n  .TablaNotasDePedido__contenedorTotales__8RaVr {\r\n    overflow-x: hidden;\r\n    padding: 16px;\r\n    margin-top: -8px;\r\n    margin-bottom: -8px;\r\n    text-align: right;\r\n  }\r\n  \r\n  .TablaNotasDePedido__TitleCard__MESmZ {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-content: center;\r\n    background: rgb(251, 175, 64);\r\n    background: linear-gradient(90deg, rgba(251, 175, 64, 1) 0%, rgba(241, 91, 41, 1) 100%);\r\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#fbaf40\",endColorstr=\"#f15b29\",GradientType=1);\r\n    margin: -15px -15px 0px -15px;\r\n    padding-left: 15px;\r\n  }\r\n  \r\n  .TablaNotasDePedido__TotalsHeader__t52QW{\r\n    display: flex;\r\n    align-content: center;\r\n    justify-content: space-between;\r\n    margin: 5px 0px 5px 0px;\r\n  }", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoComprobanteContainer": "TablaNotasDePedido__infoComprobanteContainer__HJ1GA",
	"infoComprobanteRow": "TablaNotasDePedido__infoComprobanteRow__vdbcv",
	"titleTotales": "TablaNotasDePedido__titleTotales__jo9iG",
	"Spinner": "TablaNotasDePedido__Spinner__Bq7I5",
	"contenedorTotales": "TablaNotasDePedido__contenedorTotales__8RaVr",
	"TitleCard": "TablaNotasDePedido__TitleCard__MESmZ",
	"TotalsHeader": "TablaNotasDePedido__TotalsHeader__t52QW"
};
export default ___CSS_LOADER_EXPORT___;
