import React, { Fragment, useCallback, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions';
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CustomSelector from "../../../../../UI/CustomSelector/CustomSelector";
import ButtonPivotGrid from "../../../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid";
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import Spinner from "../../../../../UI/Spinner/Spinner";
import moment from "moment";
import "moment/locale/es";

import FiltroFechaSelector from "../../../../../UI/FiltroFechaSelector/FiltroFechaSelector";
import BudgetForecastSelector from "../../../../../UI/Informes/BudgetForecastSelector/BudgetForecastSelector";
import LeyendaFiltros from "../../../../../UI/Informes/LeyendaFiltros/LeyendaFiltros";
import TablasInformeTresExpandidoToExcel from "../TablasInformeTres/TablasInformeTresExpandidoToExcel";

moment.locale("es");

function getMonths() {
  return [
    { id: 4, nombre: 'Abril' },
    { id: 5, nombre: 'Mayo' },
    { id: 6, nombre: 'Junio' },
    { id: 7, nombre: 'Julio' },
    { id: 8, nombre: 'Agosto' },
    { id: 9, nombre: 'Septiembre' },
    { id: 10, nombre: 'Octubre' },
    { id: 11, nombre: 'Noviembre' },
    { id: 12, nombre: 'Diciembre' },
    { id: 1, nombre: 'Enero' },
    { id: 2, nombre: 'Febrero' },
    { id: 3, nombre: 'Marzo' },
  ]
}

const useStyles = makeStyles(() => ({
  checkBoxLabel: {
    fontSize: 'x-small',
    "& .MuiFormControlLabel-label": {
      fontSize: "x-small",
    }
  },
}));

const FiltroInformeTres = (props) => {
  const classes = useStyles();
  const { aplicarFiltros } = props;
  const { loadingInforme, estaCargadoInforme } = props;
  const { loading, estaCargado, aniosFiscales, onLoadData, filtros, filtrosInforme,
    onUpdateFiltros, onLoadBudgets, budgetsForecasts, loadingBudgets, anioBudgets,
    unidadesDeNegocio, onLoadUnidades, } = props;
  const [anioFiscal, setAnioFiscal] = useState();
  const [anioFiscalDesde, setAnioFiscalDesde] = useState();
  const [anioFiscalHasta, setAnioFiscalHasta] = useState();
  const [mesFiscal, setMesFiscal] = useState('');
  const [meses] = useState(getMonths());
  const [acumulado, setAcumulado] = useState(filtros ? filtros.acumulado : false);
  const [idArchivo, setIdArchivo] = useState('');
  const [desde, setDesde] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [loadingDesde, setLoadingDesde] = useState(false);
  const [hasta, setHasta] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loadingHasta, setLoadingHasta] = useState(false);
  const [unidadDeNegocio, setUnidadDeNegocio] = useState('');
  const [canal, setCanal] = useState();
  const [vendedor, setVendedor] = useState('');
  const [canales, setCanales] = useState();
  const [vendedores, setVendedores] = useState();
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const anioFiscalSelected = anioFiscal !== '';
  const idArchivoSelected = idArchivo !== '';
  const mesSelected = mesFiscal !== '';

  useEffect(() => {
    onLoadUnidades();
  }, []);

  const habilitar = useCallback(() => {
    const dateValid = (desde !== null && desde !== undefined && hasta !== null && hasta !== undefined)
      && desde < hasta;
    const habilitarAplicar = anioFiscal !== '' && idArchivo !== ''
      && (mesFiscal !== '' || dateValid);

    const selectValid = unidadDeNegocio !== '' && !errorVendedor && canal && canal.length > 0;

    return !habilitarAplicar || loadingInforme || !selectValid;
  }, [anioFiscal, mesFiscal, idArchivo, desde, hasta, unidadDeNegocio, canal, vendedor]);

  const habilitarExportacion = useCallback(() => {
    if (!cambiosAplicados || !estaCargadoInforme)
      return true;

    const dateValid = (desde !== null && desde !== undefined && hasta !== null && hasta !== undefined)
      && desde < hasta;
    const habilitarAplicar = anioFiscal !== '' && idArchivo !== ''
      && (mesFiscal !== '' || dateValid);

    return !habilitarAplicar || loadingInforme;
  }, [anioFiscal, mesFiscal, idArchivo, desde, hasta, cambiosAplicados, estaCargadoInforme]);

  useEffect(() => {
    if (!estaCargado && !loading)
      onLoadData();
  }, [estaCargado, loading]);

  useEffect(() => {
    if (!anioFiscalDesde && estaCargado && aniosFiscales && aniosFiscales.length > 0) {
      const anio = Number(aniosFiscales[aniosFiscales.length - 1].id);
      setAnioFiscalDesde(new Date(anio, 0, 1));
    }
  }, [anioFiscalDesde, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (!anioFiscalHasta && estaCargado && aniosFiscales && aniosFiscales.length > 0) {
      const anio = Number(aniosFiscales[0].id);
      setAnioFiscalHasta(new Date(anio, 11, 31));
    }
  }, [anioFiscalHasta, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (filtros && estaCargado && !anioFiscal && aniosFiscales && aniosFiscales.length > 0) {
      let item = aniosFiscales.find((i) => i.id === `${filtros.anioFiscal}`);

      if (item)
        setAnioFiscal(new Date(filtros.anioFiscal, 0, 1).getFullYear());
      else if (anioFiscal !== '')
        setAnioFiscal(new Date().getFullYear());
    }
  }, [filtros, estaCargado, anioFiscal, aniosFiscales]);

  useEffect(() => {
    if (filtros) {
      let item = meses.find((i) => i.id === filtros.mesFiscal);

      if (item)
        setMesFiscal(item);
      else if (mesFiscal !== '')
        setMesFiscal('');
    }
  }, [filtros, meses]);

  useEffect(() => {
    if (filtros && filtros.idArchivo && filtros.anioFiscal === anioBudgets && budgetsForecasts) {
      let item = budgetsForecasts.find((i) => i.id === `${filtros.idArchivo}`);

      if (item)
        setIdArchivo(item);
      else if (idArchivo !== '')
        setIdArchivo('');
    }
  }, [filtros, filtros.idArchivo, anioBudgets, budgetsForecasts]);

  useEffect(() => {
    if (filtros && filtros.acumulado !== undefined) {
      setAcumulado(filtros.acumulado);
    }
  }, [filtros, filtros.acumulado]);

  useEffect(() => {
    if (filtros && !desde && filtros.desde !== desde) {
      setLoadingDesde(true);
      setDesde(filtros.desde);
      setTimeout(() => setLoadingDesde(false), 500);
    }
  }, [filtros, filtros.desde, desde]);

  useEffect(() => {
    if (filtros && !hasta && filtros.hasta !== hasta) {
      setLoadingHasta(true);
      setHasta(filtros.hasta);
      setTimeout(() => setLoadingHasta(false), 500);
    }
  }, [filtros, filtros.hasta, hasta]);

  useEffect(() => {
    if (!minDate && !maxDate && filtros && filtros.anioFiscal && filtros.mesFiscal) {
      let anio = filtros.anioFiscal;
      let mes = filtros.mesFiscal;
      let daysInMonth = 0;
      let min = null;
      let max = null;

      if (acumulado) {
        daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

        min = new Date(anio, 3, 1);
        max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      } else {
        if (mes < 4)
          anio = anio + 1;

        daysInMonth = new Date(anio, mes, 0).getDate();

        min = new Date(anio, mes - 1, 1);
        max = new Date(anio, mes - 1, daysInMonth);
      }

      setMinDate(min);
      setMaxDate(max);
    }

  }, [filtros, minDate, maxDate, acumulado]);

  const [loadingUnidadDeNegocio, setLoadingUnidadDeNegocio] = useState(false);
  const [errorVendedor, setErrorVendedor] = useState(false);
  const getTodosLosCanales = useCallback(() => {
    var todosCanales = [];
    if (unidadesDeNegocio && unidadesDeNegocio.length) {
      unidadesDeNegocio.forEach((unid) => {
        todosCanales = todosCanales.concat(unid.canales);
      });
    }
    return todosCanales;
  }, [unidadesDeNegocio]);

  const getTodosLosVendedores = useCallback(() => {
    var todosVendedores = [];
    if (unidadesDeNegocio && unidadesDeNegocio.length) {
      unidadesDeNegocio.forEach((unid) => {
        todosVendedores = todosVendedores.concat(unid.vendedores);
      });
    }
    return todosVendedores;
  }, [unidadesDeNegocio]);

  const updateUnidadDeNegocio = useCallback((nuevaUnidad) => {
    setLoadingUnidadDeNegocio(true);
    var todosCanales = getTodosLosCanales();
    var listadoCanales = nuevaUnidad && nuevaUnidad !== 'select'
      ? nuevaUnidad.canales ? nuevaUnidad.canales : []
      : todosCanales && todosCanales.length > 0
        ? todosCanales : [];

    var todosVendedores = getTodosLosVendedores();
    var listadoVendedores = nuevaUnidad && nuevaUnidad !== 'select'
      ? nuevaUnidad.vendedores ? nuevaUnidad.vendedores : []
      : todosVendedores && todosVendedores.length > 0
        ? todosVendedores : [];

    setUnidadDeNegocio(nuevaUnidad);
    setCanales(listadoCanales);
    setCanal(listadoCanales);
    setVendedores(listadoVendedores);
    const optionVend = listadoVendedores && listadoVendedores.length > 0 ? 'select' : '';
    setVendedor(optionVend);
    setErrorVendedor(optionVend === '');
    setTimeout(() => {
      setLoadingUnidadDeNegocio(false);
    }, 500);
  }, [unidadesDeNegocio, getTodosLosCanales, getTodosLosVendedores]);

  useEffect(() => {
    if (unidadDeNegocio === '' && filtros && unidadesDeNegocio && unidadesDeNegocio.length !== 0) {
      var unidad = filtros && filtros.unidadDeNegocio
        ? filtros.unidadDeNegocio
        : 'select';
      updateUnidadDeNegocio(unidad);
    }
  }, [unidadDeNegocio, filtros, unidadesDeNegocio, updateUnidadDeNegocio]);

  useEffect(() => {
    if (!canal && filtros && filtros.canal)
      setCanal(filtros.canal);
  }, [canal, filtros]);

  useEffect(() => {
    if (!canal && filtros && !filtros.canal && canales)
      setCanal(canales);
  }, [canal, filtros, canales]);

  useEffect(() => {
    if (vendedor === '' && filtros && filtros.vendedor)
      setVendedor(filtros.vendedor);
  }, [vendedor, filtros]);

  useEffect(() => {
    if (vendedor === '' && filtros && (!filtros.vendedor || filtros.vendedor === ''))
      setVendedor('select');
  }, [vendedor, filtros]);

  const onAplicarFiltroClick = () => {
    let archivo = idArchivo ? idArchivo.id : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    let desdeDate = desde ? new Date(desde) : null;
    let hastaDate = hasta ? new Date(hasta) : null;
    onUpdateFiltros(anioFiscal, archivo, mes, acumulado, desdeDate, hastaDate, unidadDeNegocio, canal, vendedor);
    aplicarFiltros(anioFiscal, archivo, mes, acumulado, desde, hasta, unidadDeNegocio, canal, vendedor, vendedores);
    setCambiosAplicados(true);
  }

  const onAnioFiscalChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);
    let anio = value ? value.getFullYear() : new Date().getFullYear();
    setAnioFiscal(anio);
    onLoadBudgets(anio);
    setIdArchivo('');
    setMesFiscal('');

    setDesde(new Date(anio, 3, 1));
    setHasta(new Date(anio + 1, 2, 31));
    setMinDate(new Date(anio, 3, 1));
    setMaxDate(new Date(anio + 1, 2, 31));

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);

    setCambiosAplicados(false);
  };

  const onIdArchivoChange = (value) => {
    setIdArchivo(value);
    setMesFiscal('');
    setCambiosAplicados(false);
  };

  const onMesFiscalChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);

    setMesFiscal(value);
    let anio = anioFiscal ? Number(anioFiscal) : undefined;
    let mes = value ? value.id : undefined;
    let daysInMonth = 0;
    let desdeDate = null;
    let hastaDate = null;
    let min = null;
    let max = null;

    if (acumulado) {
      daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

      desdeDate = new Date(anio, 3, 1);
      hastaDate = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      min = new Date(anio, 3, 1);
      max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
    } else {
      if (mes < 4)
        anio = anio + 1;

      daysInMonth = new Date(anio, mes, 0).getDate();

      desdeDate = new Date(anio, mes - 1, 1);
      hastaDate = new Date(anio, mes - 1, daysInMonth);
      min = new Date(anio, mes - 1, 1);
      max = new Date(anio, mes - 1, daysInMonth);
    }

    setDesde(desdeDate);
    setHasta(hastaDate);
    setMinDate(min);
    setMaxDate(max);

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);

    setCambiosAplicados(false);
  };

  const onAcumuladoChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);

    setAcumulado(value);
    let anio = anioFiscal ? Number(anioFiscal) : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    let daysInMonth = 0;
    let desdeDate = null;
    let hastaDate = null;
    let min = null;
    let max = null;

    if (value) {
      daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

      desdeDate = new Date(anio, 3, 1);
      hastaDate = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      min = new Date(anio, 3, 1);
      max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
    } else {
      if (mes < 4)
        anio = anio + 1;

      daysInMonth = new Date(anio, mes, 0).getDate();

      desdeDate = new Date(anio, mes - 1, 1);
      hastaDate = new Date(anio, mes - 1, daysInMonth);
      min = new Date(anio, mes - 1, 1);
      max = new Date(anio, mes - 1, daysInMonth);
    }

    setDesde(desdeDate);
    setHasta(hastaDate);
    setMinDate(min);
    setMaxDate(max);

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);
  };

  const onDesdeChange = (value) => {
    setDesde(value)
    setCambiosAplicados(false);
  };

  const onHastaChange = (value) => {
    setHasta(value);
    setCambiosAplicados(false);
  };

  const onUnidadDeNegocioChange = useCallback((value) => {
    updateUnidadDeNegocio(value);
    setCambiosAplicados(false);
  }, [updateUnidadDeNegocio, canales]);

  const onCanalChange = useCallback((value) => {
    setCanal(value);
    setCambiosAplicados(false);
  }, []);

  const onVendedorChange = (value) => {
    setVendedor(value);
    setCambiosAplicados(false);
  }

  // #endregion

  return (
    <Fragment>
      <Grid item xs={12} md={11} xl={9} container spacing={1} justifyContent="flex-start">
        <Grid item xs={12} container spacing={1}>
          <Grid container spacing={1} item xs={12} md={6} xl={5}>
            <Grid item xs={4}>
              {loading ? (
                <Spinner size="small" />
              ) : (
                <FiltroFechaSelector
                  id="anioFiscal"
                  label="Año Fiscal"
                  value={anioFiscal}
                  onDateChange={(value) => onAnioFiscalChange(value)}
                  format="YYYY"
                  views={["year"]}
                  disabled={loadingInforme}
                  minDate={anioFiscalDesde}
                  maxDate={anioFiscalHasta}
                  required={true}
                  helperText={anioFiscal ? null : "Este campo es requerido"}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <BudgetForecastSelector
                id="idArchivo"
                label="Budget / Forecast"
                value={idArchivo}
                items={budgetsForecasts}
                onChange={(event) => onIdArchivoChange(event.target.value)}
                isLoading={loadingBudgets}
                disabled={!anioFiscalSelected || loadingInforme}
                required={true}
                helperText={idArchivo ? null : "Este campo es requerido"}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelector
                id="mesFiscal"
                label="Mes Fiscal"
                value={mesFiscal}
                items={meses}
                onChange={(event) => onMesFiscalChange(event.target.value)}
                disabled={!idArchivoSelected || loadingInforme}
                upperCase
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={12} md={6}>
            <Grid item xs={4} md={2} style={{ textAlign: 'center' }}>
              <FormControlLabel
                label="Acumulado"
                className={classes.checkBoxLabel}
                labelPlacement="top"
                control={
                  <Checkbox
                    checked={acumulado}
                    onChange={(event) => onAcumuladoChange(event.target.checked)}
                    name="acumulado"
                    color="primary"
                  />
                }
                disabled={!mesSelected || loadingInforme}
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              {loadingDesde ? (
                <Spinner size="small" />
              ) : (
                <FiltroFechaSelector
                  label="Fecha desde"
                  disabled={loadingInforme}
                  value={desde}
                  onDateChange={(value) => onDesdeChange(value)}
                  format="DD/MM/YYYY"
                  minDate={minDate}
                  maxDate={maxDate}
                  required={true}
                  helperText={desde ? null : "Este campo es requerido"}
                />
              )}
            </Grid>
            <Grid item xs={4} md={3}>
              {loadingHasta ? (
                <Spinner size="small" />
              ) : (
                <FiltroFechaSelector
                  label="Fecha hasta"
                  disabled={loadingInforme}
                  value={hasta}
                  onDateChange={(value) => onHastaChange(value)}
                  format="DD/MM/YYYY"
                  minDate={minDate}
                  maxDate={maxDate}
                  required={true}
                  helperText={hasta ? null : "Este campo es requerido"}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <ButtonPivotGrid
                tooltipTitle="Aplicar filtros sobre el informe 3"
                buttonSize="small"
                text="Aplicar"
                onClick={onAplicarFiltroClick}
                loading={loadingInforme}
                icon={<PlayCircleFilledWhiteOutlinedIcon fontSize="small" />}
                disabled={habilitar()}
                backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}

              />
              <TablasInformeTresExpandidoToExcel
                name='Objetivos'
                filename='Informe 3 - Objetivos por rubros y articulos'
                disabled={habilitarExportacion()}
                backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12} md={6} xl={5} container spacing={1}>
            <Grid item xs={4}>
              <CustomSelector
                id="selectorUnidadDeNegocio"
                label="Unidad de Negocio"
                value={unidadDeNegocio}
                items={unidadesDeNegocio}
                onChange={(event) => onUnidadDeNegocioChange(event.target.value)}
                disabled={loadingInforme}
                seleccione
                seleccioneText="TODAS"
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelector
                id="selectorCanal"
                label="Canal"
                isLoading={loadingUnidadDeNegocio}
                value={canal ? canal : []}
                items={canales}
                onChange={(event) => onCanalChange(event)}
                disabled={loadingInforme}
                multiple
                selectAll
                required={true}
                error={!canal || canal.length === 0}
                helperText="Seleccione al menos un canal"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelector
                id="selectorVendedor"
                label="Vendedor"
                isLoading={loadingUnidadDeNegocio}
                value={vendedor}
                items={vendedores}
                onChange={(event) => onVendedorChange(event.target.value)}
                disabled={loadingInforme || !vendedores || vendedores.length === 0}
                seleccione
                seleccioneText="TODOS"
                required={true}
                error={errorVendedor}
                helperText={errorVendedor ? "Seleccione al menos un vendedor" : ''}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
        filtrosInforme && (<Grid item xs={12}>
          <LeyendaFiltros
            desde={filtrosInforme.desde}
            hasta={filtrosInforme.hasta}
          />
        </Grid>)
      }
    </Fragment >
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.aniosFiscales.loading,
    error: state.tenacta.aniosFiscales.error,
    aniosFiscales: state.tenacta.aniosFiscales.items,
    estaCargado: state.tenacta.aniosFiscales.estaCargado,
    loadingInforme: state.tenacta.informeTresData.loading,
    estaCargadoInforme: state.tenacta.informeTresData.estaCargado,
    budgetsForecasts: state.tenacta.budgetsForecastsInformeTres.items,
    loadingBudgets: state.tenacta.budgetsForecastsInformeTres.loading,
    anioBudgets: state.tenacta.budgetsForecastsInformeTres.anioFiscal,
    unidadesDeNegocio: state.tenacta.unidadesDeNegocio.items,
    filtros: state.tenacta.informeTresFiltros,
    filtrosInforme: state.tenacta.informeTresData.filtros,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(actions.loadAniosFiscales()),
    onUpdateFiltros: (anio, idArchivo, mes, acumulado, desde, hasta, unidadDeNegocio, canal, vendedor) =>
      dispatch(actions.updateInformeTresFiltros(anio, idArchivo, mes, acumulado, desde, hasta, unidadDeNegocio, canal, vendedor)),
    onLoadUnidades: () => dispatch(actions.loadUnidadesDeNegocio()),
    onLoadBudgets: (anioFiscal) => dispatch(actions.loadInformeTresBudgetsForecasts(anioFiscal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroInformeTres);