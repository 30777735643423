import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';
import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';

const TextoPagosDelMes = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }

    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.ejecucionDePagina]);
  const isDisabledChart = props.estaOculto && !props.empresa;

  return (
    <TextChart
      title={`Pagos del mes ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} ${
        props.empresa ? props.empresa.nombre : ''
      }`}
      bigAmount={props.mesActual}
      smallAmount={props.mesAnterior}
      smallCurrency
      comparacionInvertida
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} a ${fechaFinPeriodoMesActualFunc()} `}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} a ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={props.loading}
      disabled={isDisabledChart}
      bigCurrency
      porcentaje
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.compras.textoPagosDelMes.loading,
    mesActual: state.compras.textoPagosDelMes.mesActual,
    mesAnterior: state.compras.textoPagosDelMes.mesAnterior,
    estaCargado: state.compras.textoPagosDelMes.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoPagosDelMes(idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearTextoPagosDelMes()),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Pagos del mes', ['pago']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPagosDelMes);
