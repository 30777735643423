import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { storageEmpresaId } from '../../../../../shared/sessionData';
import * as actions from '../../../../../store/actions';
import { Grid } from "@material-ui/core";
import CustomSelector from "../../../../UI/CustomSelector/CustomSelector";
import Spinner from '../../../../UI/Spinner/Spinner';
import Table from '../../../../UI/Table/Table';
import IconConvertToExcel from '../../../../../assets/images/IconConvertToExcel.png';
import { Tooltip, IconButton } from '@material-ui/core';
import ApproveIcon from '@material-ui/icons/HowToReg';
import ConfirmDialog from '../../../../UI/Dialog/ConfirmDialog';
import OKDialog from '../../../../UI/Dialog/OKDialog';

const SelectorAnioFiscal = (props) => {
  const { loading, aniosFiscales, estaCargado, onLoadData } = props;
  const { onLoadBudgets, loadingBudgets, budgetsForecasts } = props;
  const { loadingUploadBudgets } = props;
  const { onApproveFile, approveLoading } = props;
  const { anioFiscalChangedCallback } = props;
  
  const [anioFiscal, setAnioFiscal] = useState();
  const [data, setData] = useState([]);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const [idArchivo, setIdArchivo] = useState(0);
  const [approveButtonText, setApproveButtonText] = useState("");

  const openInNewTab = fileName => {
    props.onDownloadFile(``, `${fileName}.xlsx`, 'budgets-forecasts')
    // window.open(`https://jalisco.s3.amazonaws.com/budgets_forecasts/${idEmpresa}/${fileName}.xlsx`, '_blank', 'noopener,noreferrer');
  };

  const descargarExcelRowButton = fileName => (
    <Tooltip title="Descargar Excel">
      <IconButton size="small" style={{marginLeft: 20}} onClick={() => openInNewTab(fileName)}>
        <img alt="" src={IconConvertToExcel} height={'33px'} />
      </IconButton>
    </Tooltip>
  );

  const aprobarArchivoRowButton = () => (
    <Tooltip title="Aprobar">
      <IconButton size="small" style={{marginLeft: 20}}
        onClick={handleApproveFileConfirmDialogOpen}
        disabled={loadingUploadBudgets || approveLoading}>
        <ApproveIcon height={'33px'} style={{ color: '#10c5ff' }} />
      </IconButton>
    </Tooltip>
  );

  // budget-forecast-table columns
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#f99b3a'
  };
  const columns = [
    { title: 'ID', width: "5%", field: 'id', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Archivo', width: "15%", field: 'fileName', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Estado', width: "10%", field: 'status', align: 'center', cellStyle: cellStyle, headerStyle: headerStyle },
    {
      title: 'Acciones',
      width: "15%",
      field: null,
      align: 'center',
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => {
        return (
          <Grid container xs={6} md={8}
            direction="row" justifyContent="flex-start">
            <Grid item xs={6}>
              {descargarExcelRowButton(rowData.id)}
            </Grid>
            <Grid item xs={6}>
              {!rowData.approved ? aprobarArchivoRowButton() : (<span></span>)}
            </Grid>
          </Grid>
        );
      }
    }
  ];

  const onLoadBudgetsFail = (error) => {
    if (error.status !== 404)
      alert(error.data);
  }

  const onAnioFiscalChange = (value) => {
    setAnioFiscal(value);
    onLoadBudgets(value.id, onLoadBudgetsFail);
    anioFiscalChangedCallback(value);
  }

  const onApproveFileSuccess = () => {
    // alert('Archivo aprobado');
    handleApproveFileOKDialogOpen();
    onLoadBudgets(anioFiscal.id, onLoadBudgetsFail);
  }

  const onApproveFileFail = (error) => {
    alert(error);
  }

  const onAprobarArchivo = () => {
    handleApproveFileConfirmDialogClose();
    onApproveFile(idArchivo, onApproveFileSuccess, onApproveFileFail);
  }

  useEffect(() => {
    if (!anioFiscal || anioFiscal.id === undefined) {
      setData([]);
      setShowApproveButton(false);
    }
  }, [anioFiscal]);

  useEffect(() => {
    if (!estaCargado && !loading)
      onLoadData();
  }, [estaCargado, loading]);

  useEffect(() => {
    var data = [];
    setShowApproveButton(false);
    setIdArchivo(0);

    if (loadingBudgets)
      return;

    if (anioFiscal
      && !(Object.keys(anioFiscal).length === 0
      && Object.getPrototypeOf(anioFiscal) === Object.prototype)) {
      data = budgetsForecasts.map(item => ({
        id: item.id,
        fileName: item.nombre,
        status: item.aprobado ? "Aprobado" : "No aprobado",
        approved: item.aprobado
      }));
      data = data.sort((a, b) => {
        if (parseInt(a.id) >= parseInt(b.id))
          return 1;
        return -1;
      });
      const file = budgetsForecasts.find(i => !i.aprobado);
      if (file) {
        setShowApproveButton(true);
        setIdArchivo(file.id);
        const approveButtonText = `Aprobar ${file.tipo === "B" ? "Budget" : "Forecast"}`;
        setApproveButtonText(approveButtonText);
      }
    }
    setData(data);
  }, [loadingBudgets, budgetsForecasts]);

  // Approve confirm dialog
  const [approveFileConfirmDialogOpen, setApproveFileConfirmDialogOpen] = useState(false);

  const handleApproveFileConfirmDialogOpen = () => {
    setApproveFileConfirmDialogOpen(true);
  };

  const handleApproveFileConfirmDialogClose = () => {
    setApproveFileConfirmDialogOpen(false);
  };
  
  const approveFileConfirmDialog = () => (
    <ConfirmDialog
      show={approveFileConfirmDialogOpen}
      title="Aprobar Budget/Forecast"
      body="Para continuar, por favor confirme la acción."
      onConfirm={onAprobarArchivo}
      onClose={handleApproveFileConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - Approve confirm dialog

  // Approve OK dialog
  const [approveFileOKDialogOpen, setApproveFileOKDialogOpen] = useState(false);

  const handleApproveFileOKDialogOpen = () => {
    setApproveFileOKDialogOpen(true);
  };

  const handleApproveFileOKDialogClose = () => {
    setApproveFileOKDialogOpen(false);
  };
  
  const approveFileOKDialog = () => (
    <OKDialog
      show={approveFileOKDialogOpen}
      title="Aprobar Budget/Forecast"
      body="Archivo aprobado exitosamente."
      onConfirm={handleApproveFileOKDialogClose}
      onClose={handleApproveFileOKDialogClose}
    >
    </OKDialog>
  );
  // - Approve OK dialog

  return (
    <Grid container spacing={1} 
      direction="column" justifyContent="flex-start"
      style={{ marginTop: '10px' }}
    >
      <Grid container xs={6} md={8}
        direction="row" justifyContent="flex-start">
        <Grid item xs={12} md={5}>
          <CustomSelector 
            id="anioFiscal"
            label="Año Fiscal"
            value={anioFiscal}
            items={aniosFiscales}
            onChange={(event) => onAnioFiscalChange(event.target.value)}
            isLoading={loading}
            disabled={loadingBudgets || loadingUploadBudgets || approveLoading}
          />
        </Grid>
      </Grid>
      
      <Grid item xs={12} md={12}>
        {loadingBudgets && <Spinner size="small" />}
        {(!loadingBudgets && data.length > 0) && (
          <Table
            style={{ width: '100%', marginTop: 0, marginBottom: '15px' }}
            title=""
            noBoxShadow
            columns={columns}
            data={data}
            customOptions={{
              thirdSortClick: false,
              sorting: false,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 250
            }}
            tableKey={"tabla-selector-anio-fiscal"}
          />
        )}
      </Grid>
      {approveFileConfirmDialog()}
      {approveFileOKDialog()}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.aniosFiscales.loading,
    aniosFiscales: state.tenacta.aniosFiscales.items,
    estaCargado: state.tenacta.aniosFiscales.estaCargado,
    loadingBudgets: state.tenacta.budgetsForecasts.loading,
    budgetsForecasts: state.tenacta.budgetsForecasts.items,
    loadingUploadBudgets: state.tenacta.importadorBudgetForecast.loading,
    approveLoading: state.tenacta.aprobarBudgetForecast.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(actions.loadAniosFiscales()),
    onLoadBudgets: (anioFiscal, onFail) => dispatch(actions.loadBudgetsForecasts(anioFiscal, onFail)),
    onApproveFile: (idArchivo, onSuccess, onFail) => 
      dispatch(actions.approveBudgetForecast(idArchivo, onSuccess, onFail)),
    onDownloadFile: (folder, fileName, containerName) => 
      dispatch(actions.downloadFile(folder, fileName, containerName))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorAnioFiscal);