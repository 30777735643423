import React from 'react';
import { formatNumber, formatTwoIntegerDigits } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import ExportToExcelIcon from '../../Icons/ExportToExcelIcon';

const TablaInteranualCuboToExcel = (props) => {
  const { title, datasPorAnio, montosDe } = props;

  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var formatData = [];
    datasPorAnio.map((dataPorAnio) => {
      return dataPorAnio.meses.map((mes, index) => {
        return formatData.push([
          {
            value: index === 0 ? dataPorAnio.anio : '',
            style: { font: { sz: '10' } }
          },
          {
            value: `${formatTwoIntegerDigits(mes.mes)}`,
            style: cellsStyleVerifNegative(mes.mes)
          },
          {
            value: `${storageMonedaSigno()} ${formatNumber(mes.monto)}`,
            style: cellsStyleVerifNegative(mes.monto)
          }
        ]);
      });
    });

    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Año',
          width: { wpx: 55 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        },
        { title: `Mes`, width: { wpx: 50 }, style: headersStyle },
        {
          title: montosDe,
          width: { wpx: 150 },
          style: headersStyle
        }
      ],
      data: formatData()
    }
  ];

  return (
    <ExportToExcelIcon 
      filename={`Analisis de ${title.toLowerCase()}`}
      name={`${montosDe.toLowerCase()}`}
      dataSet={multiDataSet}
    />
  );
};

export default TablaInteranualCuboToExcel;