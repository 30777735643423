import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../../UI/Button/Button';

const ModalUtilidadesConfigBody = (props) => {
  const { utilidades, changeUtilidadesConfig } = props;
  const [utilidadesLocal, setUtilidadesLocal] = useState(utilidades);

  const onChangeValue = (e, key) => {
    var modifUtilidadesConfig = utilidadesLocal;

    modifUtilidadesConfig[key].estaOculto = !e.target.checked;

    return setUtilidadesLocal(modifUtilidadesConfig);
  };

  const formatUtilidades = () => {
    const formateoUtilidades = [];
    for (var i = 0; i <= utilidades.length / 12; i++) {
      formateoUtilidades.push(utilidades.slice(i * 12, i * 12 + 12));
    }

    return (
      <div style={{ display: 'flex' }}>
        {formateoUtilidades.map((utilidades, key) => (
          <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            {utilidades.map((utilidad, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch key={key} defaultChecked={!utilidad.estaOculto} onChange={(e) => onChangeValue(e, key)} />
                }
                label={utilidad.nombre}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ minHeight: props.loading ? '205px' : '0px' }}>
      <FormControl component="fieldset">
        <FormGroup>{formatUtilidades()}</FormGroup>
      </FormControl>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={() => changeUtilidadesConfig(utilidadesLocal)}>Guardar cambios</Button>
      </div>
    </div>
  );
};

export default ModalUtilidadesConfigBody;
