import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { Tooltip, IconButton } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ConfigPivotGrid from '../DialogPivotGrid/ConfigPivodGrid';

const ConfigIcon = (props) => {
  const { tooltip, iconSize, filterSize, rootClass, iconClass,
    onShowModal, onCloseModal, pivotGridKey, onUpdateDataSource,
    onCargarFormato, pivotGridRef, datos, filtros
  } = props;

  const onClick = () => {
    onShowModal(
      <ConfigPivotGrid
        title={'Configuración'}
        pivotGridKey={pivotGridKey}
        pivotGridRef={pivotGridRef}
        showChart={props.showChart}
        chartType={props.chartType}
        onShowChartChange={props.onShowChartChange}
        onChartTypeChange={props.onChartTypeChange}
        onClose={onCloseModal}
        onUpdateDataSource={onUpdateDataSource}
        onCargarFormato={onCargarFormato}
        datos={datos}
        filtros={filtros}
        onLoadDataImport={props.onLoadDataImport}
      />
    )
  }

  return (
    <Tooltip className={rootClass} title={ tooltip ? tooltip : "Configuración" }>
      <IconButton className={iconClass} size={iconSize ? iconSize : "small"} 
        onClick={onClick}>
        <SettingsOutlinedIcon fontSize={filterSize ? filterSize : "small"} />
      </IconButton>
    </Tooltip>
  )
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (body) => dispatch(actions.showGlobalModal(true, 'Configuración', body, undefined, false, true, 'md')), 
    onCloseModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true , 'sm')),     
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigIcon);