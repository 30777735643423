import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import classes from './VendedorSelector.css';

import Spinner from '../Spinner/Spinner';

const VendedorSelector = (props) => {
  const { estaCargado, onLoadVendedores, endLoadVendedores } = props;

  useEffect(() => {
    if (!estaCargado) {
      onLoadVendedores((response) => {
        return response.data.length > 0 && endLoadVendedores(response.data[0]);
      });
    }
  }, [estaCargado, onLoadVendedores, endLoadVendedores]);

  return props.loadingVendedores ? (
    <Spinner personalSize="15px" />
  ) : (
    <FormControl>
      <Select
        input={props.compactInput && <Input classes={{ input: classes.SelectRoot }} />}
        style={{ minWidth: 120 }}
        value={props.vendedorSelectedId}
        onChange={(event) => {
          props.onChangeVendedorSeleccionado(event);
        }}
      >
        {props.vendedores.map((vendedor, key) => (
          <MenuItem key={key} value={vendedor.id_vendedor}>
            {vendedor.nombre && vendedor.nombre.toUpperCase().trimEnd()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingVendedores: state.filtros.vendedores.loading,
    vendedores: state.filtros.vendedores.vendedores,
    estaCargado: state.filtros.vendedores.estaCargado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVendedores: (success) => dispatch(actions.loadVendedores(success))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendedorSelector);
