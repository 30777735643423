import React, { useEffect, useState } from 'react';
import * as actions from '../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classes from './AnalisisDeUtilidad.css';
import { storageMultiempresa } from '../../shared/sessionData';

import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import PieUtilidadPorSeleccion from '../../components/Indicadores/Ventas/Utilidades/PieUtilidadPorSeleccion/PieUtilidadPorSeleccion';
import BarUtilidadPorSeleccion from '../../components/Indicadores/Ventas/Utilidades/BarUtilidadPorSeleccion/BarUtilidadPorSeleccion';
import TablaUtilidadPorSeleccion from '../../components/Indicadores/Ventas/Utilidades/TablaUtilidadPorSeleccion/TablaUtilidadPorSeleccion';
import LineComparacionDeUtilidadPorSeleccion from '../../components/Indicadores/Ventas/Utilidades/LineComparacionDeUtilidadPorSeleccion/LineComparacionDeUtilidadPorSeleccion';
import { Grid } from '@material-ui/core';
import InteranualUtilidad from '../../components/Indicadores/Ventas/Utilidades/Interanual/InteranualUtilidad';
import { fechaActualFunc, fechaMinima } from '../../shared/fechas';


const useStyles = makeStyles((theme) => ({
  lineComparacion: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));

const AnalisisDeUtilidad = (props) => {
  sessionStorage.setItem('seccionNombre', 'Análisis de utilidad');

  const classes2 = useStyles();
  const { ejecucionAnalisisDeUtilidad, periodos, evoluciones, agregarPeriodo } = props;
  const isMultiemp = storageMultiempresa() === 'true';
  const inPhone = window.screen.width <= 750;
  const [fechaFija] = useState(fechaActualFunc());
  const [fechaPlaceholder] = useState(fechaMinima());


  useEffect(() => {
    if(periodos.length === 0){
      agregarPeriodo(fechaFija);
      if(!inPhone)
      {
        agregarPeriodo(fechaPlaceholder);
        agregarPeriodo(fechaPlaceholder);
      }
    }
  }, [periodos, fechaFija, agregarPeriodo, fechaPlaceholder, inPhone]);

  const renderItems = ejecucionAnalisisDeUtilidad && evoluciones && evoluciones.length !== 0 && evoluciones[0].estaCargado;

  return (
    <div className={classes.AnalisisUtilidadContainer}>
      {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {!ejecucionAnalisisDeUtilidad ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
            <InteranualUtilidad ejecucionDePagina={ejecucionAnalisisDeUtilidad} />
          </Grid>
          <Grid item 
            container spacing={1} 
            className={classes.CombinedChartsCard}>
            <Grid item xs={12} lg={4}>
              <PieUtilidadPorSeleccion
                ejecucionDePagina={ejecucionAnalisisDeUtilidad}
                inGastos
                legendHeight={50}
                legendPosition={'bottom'}
                height={436}
                width={'100%'}
                chartType={'pie'}
                initialLimit={20}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <BarUtilidadPorSeleccion ejecucionDePagina={ejecucionAnalisisDeUtilidad} />
            </Grid>
          </Grid>
          <Grid item className={classes2.lineComparacion}>
            <TablaUtilidadPorSeleccion />
          </Grid>
          <Grid item className={classes2.lineComparacion}>
            <LineComparacionDeUtilidadPorSeleccion ejecucionDePagina={renderItems} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ejecucionAnalisisDeUtilidad: state.ejecucionPaginas.ejecucionAnalisisDeUtilidad,
  periodos: state.ventas.evolucionUtilidadesMensualInteranual.periodos,
  evoluciones: state.ventas.evolucionUtilidadesMensualInteranual.items,
});

const mapDispatchToProps = (dispatch) => {
  return {    
    agregarPeriodo: (periodo) => dispatch(actions.agregarEvolucionDeUtilidadMensualInteranual(periodo)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalisisDeUtilidad);
