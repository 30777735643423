// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InformesTabs__PanelContainer__Hq5K8 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.InformesTabs__TabsContainer__E-bPS {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}\r\n\r\n.InformesTabs__TabsScroller__5324D {\r\n  display: flex !important;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.InformesTabs__TabsScrollerCentered__RpwWe {\r\n  display: flex !important;\r\n  margin-bottom: 10px;\r\n  justify-content: center;\r\n}\r\n\r\n.InformesTabs__TabsIndicator__U2iLi {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PanelContainer": "InformesTabs__PanelContainer__Hq5K8",
	"TabsContainer": "InformesTabs__TabsContainer__E-bPS",
	"TabsScroller": "InformesTabs__TabsScroller__5324D",
	"TabsScrollerCentered": "InformesTabs__TabsScrollerCentered__RpwWe",
	"TabsIndicator": "InformesTabs__TabsIndicator__U2iLi"
};
export default ___CSS_LOADER_EXPORT___;
