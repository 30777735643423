import React from 'react';
import ExportToExcelIcon from '../../../../../UI/Icons/ExportToExcelIcon';
import { formatNumber, formatNumberWithoutDecimals, formatNumberWithoutDecimalsWithMonedaSigno, isEven } from '../../../../../../shared/formatNumber';

const TablaPorLineaToExcel = (props) => {
  const { data, columns, name, filename, disabled, tooltipTitle, monto } = props;

  const headersStyle = {
    font: {
      sz: '12',
      bold: true,
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center'
    },
    fill: {
      patternType: 'solid',
      fgColor: { rgb: 'FFFFF0F6' }
    }
  };

  const renderRow = (column, rowData) => {
    if (!rowData.rubro)
      return '';

    if (!rowData[column.field])
      return `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(0) : formatNumberWithoutDecimals(0)}`;

    let rubro = rowData.rubro.replace(/\s+/g, '').toLowerCase();

    if (column.field === 'mix') {
      return  name === "TENACTA" || rowData.rubro === 'Mix canal' || rowData.rubro.includes('Cumpl') || rowData.rubro.includes('Pendientes')
        ? ''
        : `${formatNumber(rowData[column.field] * 100)}%`;
    }

    if (column.field === 'cumplimiento') {
      return name === "TENACTA" || rowData.rubro === 'Mix canal' || rowData.rubro.includes('Cumpl') || rowData.rubro.includes('Pendientes') || rowData.rowType === 1
        ? ''
        : `${formatNumber(rowData[column.field] * 100)}%`;
    }

    return rubro === 'mixcanal' || rubro === 'cumpl.volumen' || rubro === 'cumpl.canal' || rubro === 'base' || rubro === 'cumplimiento'
      ? `${formatNumber(rowData[column.field] * 100)}%`
      : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData[column.field]) : formatNumberWithoutDecimals(rowData[column.field])}`;
  }

  const renderTotalColumn = (rowData) => {
    if (!rowData.rubro)
      return '';

    let rubro = rowData.rubro.replace(/\s+/g, '').toLowerCase();
    return rubro === 'cumpl.volumen' || rubro === 'mixcanal' || rubro === 'base' || rubro === "cumplimiento"
      ? `${formatNumber(rowData.total * 100)}%`
      : rubro === 'cumpl.canal'
        ? ''
        : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData.total) : formatNumberWithoutDecimals(rowData.total)}`;
  }

  const cellsStyle = (column, row, value, index) => {
    const backgroundColor = row.rowType === 0
      ? !isEven(index) ? 'FFFBE4B5' : 'FFFFDD98'
      : row.rowType === 1
        ? !isEven(index) ? 'FFE4D8FA' : 'FFDBCAFE'
        : !isEven(index) ? 'FF9EF7B7' : 'FF8FE1A7';
    const style = {
      font: {
        sz: row.rowType === 2 ? '12' : '11',
        bold: row.calculo,
        color: {
          patternType: 'solid',
          fgColor: { rgb: value < 0 ? 'FFFF0000' : 'FF000000' }
        }
      },
      alignment: {
        horizontal: column.align,
        vertical: 'center'
      },
      fill: {
        patternType: 'solid',
        fgColor: { rgb: backgroundColor }
      }
    }

    return style;
  }

  const filterColumns = columns.filter((column) => column.title !== 'Ver');

  const columnData = () => {
    var headerData = [];
    filterColumns.map((column) => {
      return headerData.push({
        title: column.title,
        width: { wpx: column.field === 'rubro' ? 250 : 100 },
        style: headersStyle
      });
    });
    return headerData;
  }

  const formatData = () => {
    let tableData = [];
    data && data.map((item, index) => {
      const row = [];
      filterColumns.map((column) => {
        var value = column.field === 'rubro'
          ? `${item[column.field]}`.toUpperCase()
          : column.field === 'total'
            ? renderTotalColumn(item)
            : renderRow(column, item);
        return row.push({
          value,
          style: cellsStyle(column, item, item[column.field], index)
        });
      });
      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnData(),
      data: formatData()
    }
  ];

  return (
    <ExportToExcelIcon
      filename={filename}
      name={name}
      dataSet={multiDataSet}
      disabled={disabled}
      tooltipTitle={tooltipTitle}
    />
  )
}

export default TablaPorLineaToExcel;