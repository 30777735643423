import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import SinResultados from '../../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../../assets/images/IndicadorDeshabilitado.png';
import { Grid, Tabs, Tab } from '@material-ui/core';
import classes from './CustomPivotGrid.css';
import PropTypes from 'prop-types';
import TablePivotGrid from './TablePivotGrid/TablePivotGrid';
import ConfigIcon from './Icons/ConfigIcon';
import BarChartPivotGrid from './ChartPivotGrid/BarChartPivotGrid';
import BarChartIcon from './Icons/BarChartIcon';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fragment>
          {children}
        </Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomPivotGrid = (props) => {
  const {
    storageKey, loading, dataSource, rawDatos, rawFiltros, height,
    containerWidth, gridWidth, title, subTitle, minHeight, onFiltersClick, disabled,
    imgSinDatos, onCellClick, exportToExcel, sheetName, fileName, showConfig,
    chartConfig, configFields, onCargarFormato, onCellPrepared, exportToPdf,
    showChartIcon, onContentReady, onViewChange, selectedTotalRows, totalRowsOptions } = props;

  const [indexSelected, setIndexSelected] = useState(0);
  const [showChart, setShowChart] = useState(props.showChart);
  const [chartType, setChartType] = useState(props.chartType);
  const [customLoading, setCustomLoading] = useState(false);
  const pivotGridRef2 = useRef(null);
  const customPivotGridRef = useRef(null);
  const pivotGridRef = useCallback(node => {
    if (node)
      pivotGridRef2.current = node;
  }, []);

  const onUpdateDataSource = () => {
    if (pivotGridRef2 && pivotGridRef2.current && pivotGridRef2.current.props) {
      if (pivotGridRef2.current.props["getDataSource"])
        pivotGridRef2.current.props.getDataSource().reload();

      if (pivotGridRef2.current.props["dataSource"])
        pivotGridRef2.current.props.dataSource.reload();
    }

    props.onUpdateDataSource();
  }

  //#region Mostrar Totals  
  const [showColumnTotals, setShowColumnTotals] = useState(true);
  const [showColumnGrandTotals, setShowColumnGrandTotals] = useState(true);
  const [showRowTotals, setShowRowTotals] = useState(false);
  const [showRowGrandTotals, setShowRowGrandTotals] = useState(true);

  const onShowColumnTotalsChange = useCallback(() => {
    let value = !showColumnTotals;

    setCustomLoading(true);
    setShowColumnTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showColumnTotals]);

  const onShowColumnGrandTotalsChange = useCallback(() => {
    let value = !showColumnGrandTotals;

    setCustomLoading(true);
    setShowColumnGrandTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showColumnGrandTotals]);

  const onShowTotalRowsChange = useCallback(() => {
    let value = !showRowTotals;

    setCustomLoading(true);
    setShowRowTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showRowTotals]);

  const onShowRowGrandTotalsChange = useCallback(() => {
    let value = !showRowGrandTotals;

    setCustomLoading(true);
    setShowRowGrandTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showRowGrandTotals]);

  const onSelectedTotalRowsChange = useCallback((items) => {
    const show = items && items.length > 0;
    setShowRowTotals(show);
    props.onSelectedTotalRowsChange(items);
  }, []);

  useEffect(() => {
    if (selectedTotalRows && selectedTotalRows.length > 0 && !showRowTotals)
      setShowRowTotals(true);    
  }, [selectedTotalRows, showRowTotals]);

  useEffect(() => {
    if (showRowTotals && (!selectedTotalRows || selectedTotalRows.length <= 0))
      setShowRowTotals(false);    
  }, [selectedTotalRows, showRowTotals]);

  //#endregion

  const renderGrid = () => {
    return (
      <TablePivotGrid
        storageKey={storageKey}
        height={height}
        width={gridWidth}
        minHeight={minHeight}
        onFiltersClick={onFiltersClick}
        onCellClick={onCellClick}
        exportToExcel={exportToExcel}
        sheetName={sheetName}
        fileName={fileName}
        pivotGridRef={pivotGridRef}
        exportRef={pivotGridRef2}
        exportToPdf={exportToPdf}
        exportPdfRef={customPivotGridRef}
        dataSource={dataSource}
        configFields={configFields}
        onCellPrepared={onCellPrepared}
        onContentReady={onContentReady}
        onViewChange={onViewChange}
        rowsColumnsFixed={props.rowsColumnsFixed}
        onFixRowsColumns={props.onFixRowsColumns}
        showColumnTotals={showColumnTotals}
        onShowColumnTotalsChange={onShowColumnTotalsChange}
        showColumnGrandTotals={showColumnGrandTotals}
        onShowColumnGrandTotalsChange={onShowColumnGrandTotalsChange}
        showRowTotals={showRowTotals}
        onShowTotalRowsChange={onShowTotalRowsChange}
        showRowGrandTotals={showRowGrandTotals}
        onShowRowGrandTotalsChange={onShowRowGrandTotalsChange}
        selectedTotalRows={selectedTotalRows}
        onSelectedTotalRowsChange={onSelectedTotalRowsChange}
        totalRowsOptions={totalRowsOptions}
        scrollingMode={props.scrollingMode}
      />
    )
  }

  const renderChart = () => {
    return (
      <BarChartPivotGrid
        chartConfig={chartConfig}
      />
    )
  }

  const renderTabs = () => {
    return (
      <Grid item container xs={12} style={{ minHeight: minHeight }}>
        <Grid item xs={12}>
          <Tabs
            variant={'fullWidth'}
            classes={{
              scroller: classes.TabsScroller,
              flexContainer: classes.TabsContainer,
              indicator: classes.TabsIndicator
            }}
            value={indexSelected}
            onChange={(e, value) => setIndexSelected(value)}
          >
            <Tab label={'Tabla'} style={{ width: '100%', minHeight: 0 }} {...a11yProps(0)} />
            <Tab label={'Gráfico'} style={{ width: '100%', minHeight: 0 }} {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={indexSelected} index={0}>
            {renderGrid()}
          </TabPanel>
          <TabPanel value={indexSelected} index={1} style={{ overflow: 'hidden' }}>
            {renderChart()}
          </TabPanel>
        </Grid>
      </Grid>
    );
  }

  const onShowChartChange = (value) => {
    setShowChart(value);
    localStorage.setItem(`${storageKey}-show-chart`, value);
  }

  const onChartTypeChange = (value) => {
    setChartType(value.type);
  }

  return (
    <Grid container
      ref={customPivotGridRef}
      disabled={disabled}
      style={{
        height: "100%", width: containerWidth, minHeight: minHeight + 50,
        border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
        backgroundColor: "white", borderRadius: "3px", overflowX: props.gridContainerOverflowX, overflowY: 'auto'
      }}
    >
      <Grid item xs={12}
        container spacing={1}
        direction='row' justifyContent='space-between' alignContent='flex-start'
        style={{ maxWidth: '94.5vw', margin: 'auto', marginLeft: '20px' }}
      >
        <Grid item xs={12} container className={classes.TitleContainer}
          justifyContent="space-between" alignItems="center">
          <Grid item xs={9} md={10} className={classes.Title}>
            {title()}
          </Grid>
          <Grid item xs={3} md={1} className="iconsPivotGrid" style={{ textAlign: 'end' }}
            container spacing={1}>
            <Grid item xs={6}>
              {showChartIcon && (<BarChartIcon
                iconSize='medium'
                filterSize='medium'
                showChart={showChart}
                onShowChartChange={onShowChartChange}
              />)}
            </Grid>
            <Grid item xs={6}>
              {showConfig && (<ConfigIcon
                iconSize='medium'
                filterSize='medium'
                pivotGridKey={storageKey}
                pivotGridRef={pivotGridRef2}
                showChart={showChart}
                onShowChartChange={onShowChartChange}
                chartType={chartType}
                onChartTypeChange={onChartTypeChange}
                onUpdateDataSource={onUpdateDataSource}
                datos={rawDatos}
                filtros={rawFiltros}
                onCargarFormato={onCargarFormato}
                onLoadDataImport={props.onLoadDataImport}
              />)}
            </Grid>
          </Grid>
        </Grid>
        {subTitle && (<Grid item xs={12} container className={classes.SubTitleContainer}>
          {subTitle()}
        </Grid>)}
      </Grid>
      {disabled ? (
        <Grid item xs={12} style={{ textAlign: 'center', minHeight: '220px' }}>
          <img alt="Indicador inhablitado" src={IndicadorDeshabilitado} />
        </Grid>
      ) : (loading || customLoading) ? (
        <Grid item xs={12} style={{ textAlign: 'center', minHeight: '220px' }}>
          <Spinner />
        </Grid>
      ) : imgSinDatos ? (
        <Grid item xs={12} style={{ textAlign: 'center', minHeight: '220px' }}>
          <img alt="Sin resultados" src={SinResultados} />
        </Grid>
      ) : (
        <Fragment>
          {showChart ? renderTabs() : renderGrid()}
        </Fragment>
      )}
    </Grid>
  )
};

export default CustomPivotGrid;