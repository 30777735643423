import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';
import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';

const TextoIvaCreditoFiscalComparativo = (props) => {
  return (
    <TextChart
      title={`IVA crédito fiscal ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} ${
        props.empresa ? props.empresa.nombre : ''
      }`}
      bigAmount={Math.abs(props.creditoMesActual)}
      bigAmountTooltip="Credito fiscal"
      smallAmount={Math.abs(props.creditoMesAnterior)}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} a ${fechaFinPeriodoMesAnteriorFunc()} `}
      loading={props.loading}
      porcentaje
      border={props.border}
      bigCurrency
      smallCurrency
      onTitleClick={props.onTitleClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.impuestos.textoIvaFiscal.loading,
    creditoMesActual: state.impuestos.textoIvaFiscal.creditoMesActual,
    creditoMesAnterior: state.impuestos.textoIvaFiscal.creditoMesAnterior
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoIvaFiscal(idEmpresa))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoIvaCreditoFiscalComparativo);
