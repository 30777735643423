import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../../shared/formatNumber';
import Table from '../../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../../shared/sessionData';

import classes from './TablaUtilidadPorClienteDeLaSeleccion.css';
import TablaUtilidadPorArticuloDeLaSeleccion from '../TablaUtilidadPorArticuloDeLaSeleccion/TablaUtilidadPorArticuloDeLaSeleccion';
import TableComprobantesUtilidad from '../../../Comprobantes/TableComprobantesUtilidad/TableComprobantesUtilidad';

const TablaUtilidadPorClienteDeLaSeleccion = (props) => {
  const { dataSelectedId } = props;
  const [utilidadesLocal, setUtilidadesLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.itemExpanded.mes;
  const anio = props.itemExpanded.anio;

  useEffect(() => {
    setLoading(true);
    props[dataOptions[props.dataSelectedId].onLoadKey](
      props.itemExpanded && props.itemExpanded.id,
      mes,
      anio,
      (response) => {
        setUtilidadesLocal(response);
        setLoading(false);
      },
      () => {
        setUtilidadesLocal([]);
        setLoading(false);
      }
    );
  }, []);

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoria de cliente',
      onLoadKey: 'onLoadUtilidadPorClienteDeLaCategoria'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadUtilidadPorArticulosDeLaProvincia'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadUtilidadPorClientesEnLaZona'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadUtilidadPorClienteDelVendedor'
    },
    marca: {
      title: 'Marca',
      onLoadKey: 'loadUtilidadDelArticuloPorCliente'
    },
    rubro: {
      title: 'Rubro',
      onLoadKey: 'loadUtilidadDelArticuloPorCliente'
    },
    subrubro: {
      title: 'Subrubro',
      onLoadKey: 'loadUtilidadDelArticuloPorCliente'
    }
  };

  const headersStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: props.clientesPrimero ? 'rgb(235, 235, 235)' : 'rgb(220, 220, 220)'
  };

  const columns = [
    {
      title: 'Cliente',
      field: 'name',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: headersStyle
    },
    {
      title: ``,
      field: 'venta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.venta)}`
    },
    {
      title: ``,
      field: 'costo',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.costo)}`
    },
    {
      title: ``,
      field: 'cantidadVendida',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => rowData.cantidadVendida
    },
    {
      title: ``,
      field: 'utilidadPorcSegunCosto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunCosto)}%`
    },
    {
      title: ``,
      field: 'utilidadPorcSegunVenta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunVenta)}%`
    },
    {
      title: ``,
      field: 'porcUtilidadSobreElTotal',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.porcUtilidadSobreElTotal)}%`
    },
    {
      title: ``,
      field: 'utilidad',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.utilidad)}`
    }
  ];

  const data = () => {
    return utilidadesLocal.map((utilidad) => {
      return {
        id: utilidad.id,
        mes: utilidad.mes,
        anio: utilidad.anio,
        name: utilidad.nombre,
        utilidad: utilidad.monto,
        costo: utilidad.costo,
        venta: utilidad.ventas,
        cantidadVendida: utilidad.unidadesVendidas,
        utilidadPorcSegunCosto: utilidad.utilidadPorcentualSegunCosto,
        utilidadPorcSegunVenta: utilidad.utilidadPorcentualSegunVentas,
        porcUtilidadSobreElTotal: utilidad.porcentajeUtilidadSobreElTotal
      };
    });
  };

  const tableRef = useRef();

  const inMarca = dataSelectedId === 'marca';
  const inRubro = dataSelectedId === 'rubro';
  const inSubrubro = dataSelectedId === 'subrubro';

  const verComprobantes = inMarca || inRubro || inSubrubro;

  return (
    <div className={classes.tablaUtilidadPorClienteDeLaCategoria}>
      <Table
        tableRef={tableRef}
        style={{ width: '95%', marginTop: 0, marginBottom: '10px' }}
        title=""
        columns={columns}
        loading={loading}
        data={data()}
        detailPanel={(rowData) => {
          return verComprobantes ? (
            <TableComprobantesUtilidad
              articuloId={props.itemExpanded.id}
              clienteId={rowData.id}
              mes={mes}
              anio={anio}
              customWidth={'95%'}
            />
          ) : (
            <TablaUtilidadPorArticuloDeLaSeleccion
              clientesPrimero
              dataSelectedId={props.dataSelectedId}
              itemExpanded={rowData}
              mes={mes}
              anio={anio}
            />
          );
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 345,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-utilidad-por-cliente-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadUtilidadPorClienteDeLaCategoria: (idCategoria, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDeLaCategoria(undefined, idCategoria, mes, anio, success, errorFail)),

    onLoadUtilidadPorArticulosDeLaProvincia: (idProvincia, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDeLaProvincia(undefined, idProvincia, mes, anio, success, errorFail)),

    onLoadUtilidadPorClientesEnLaZona: (idZona, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDeLaZona(undefined, mes, anio, idZona, success, errorFail)),

    onLoadUtilidadPorClienteDelVendedor: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorClienteDelVendedor(mes, anio, idVendedor, success, errorFail)),

    loadUtilidadDelArticuloPorCliente: (idArticulo, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelArticuloPorCliente(undefined, idArticulo, mes, anio, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaUtilidadPorClienteDeLaSeleccion);
