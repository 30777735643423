import React from 'react';
import { Grid } from '@material-ui/core';

import classes from './LabelGridTitleDescription.css';

const LabelGridTitleDescription = (props) => {
  const { xs, title, description, descriptionSmall, style, styleDescription, styleDescriptionSmall } = props;

  return (
    <Grid style={style} item xs={xs} className={classes.titleDescriptionContainer}>
      <Grid item className={classes.title}>
        {title}
      </Grid>
      <Grid item className={classes.description}>
        <span style={styleDescription}>{description}</span>
      </Grid>
      <Grid item className={classes.descriptionSmall}>
        <span style={styleDescriptionSmall}>{descriptionSmall}</span>
      </Grid>
    </Grid>
  );
};

export default LabelGridTitleDescription;
