import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import classes from './PanelTenacta.css';
import { fechaActualFunc } from '../../../shared/fechas';
import BoxEjecutarPaginaInfo from '../../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import LineEvolucionVentasPorPeriodo from '../../../components/Indicadores/Ventas/LineEvolucionVentasPorPeriodo/LineEvolucionVentasPorPeriodo';
import PieTableVentasMontoUnidadPorSeleccion from '../../../components/Indicadores/Ventas/PieTableVentasMontoUnidadPorSeleccionTenacta/PieTableVentasMontoUnidadPorSeleccionTenacta';
import { storageMultiempresa } from '../../../shared/sessionData';
import EmpresaSelector from '../../../components/UI/SelectorEmpresa/EmpresaSelector';
import InteranualVentasConFiltro from '../../../components/Indicadores/Ventas/Interanual/InteranualVentasConFiltro';

const useStyles = makeStyles((theme) => ({
  lineEvolucionVentas: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));

const PanelTenacta = (props) => {
  sessionStorage.setItem('seccionNombre', 'Análisis de ventas');

  const classes2 = useStyles();
  const { periodos, ventas, agregarPeriodoVenta, onLoadTipoVentas } = props;
  const inPhone = window.screen.width <= 750;
  const [fechaFija] = useState(fechaActualFunc());
  const isMultiemp = storageMultiempresa() === 'true';

  useEffect(() => {
    if (periodos.length === 0)
      agregarPeriodoVenta(fechaFija);
  }, [periodos, fechaFija, agregarPeriodoVenta]);

  useEffect(() => {
    onLoadTipoVentas();
  }, [onLoadTipoVentas]);

  const renderItems = props.ejecucionDePagina && ventas && ventas.length !== 0 && ventas[0].estaCargado;

  const dataOptions = {
    marca: {
      id: 0,
      stringId: 'marca',
      description: 'Marcas',
      ventasKey: 'ventasPorMarca',
      loadEvolucionKey: 'onLoadEvolucionVentasPorMarca',
      loadKey: 'onLoadVentasDelMesPorMarca',
      loadDetallesKey: 'onLoadDetallesaMarca',
      nextId: 'rubro'
    },
    rubro: {
      id: 1,
      stringId: 'rubro',
      description: 'Rubros',
      ventasKey: 'ventasPorRubro',
      loadEvolucionKey: 'onLoadEvolucionVentasPorRubro',
      loadKey: 'onLoadVentasDelMesPorRubro',
      loadDetallesKey: 'onLoadDetallesRubro',
      nextId: 'articulo'
    },
    articulo: {
      id: 2,
      stringId: 'articulo',
      description: 'Artículos',
      ventasKey: 'ventasPorArticulo',
      loadEvolucionKey: 'onLoadEvolucionVentasPorArticulo',
      loadKey: 'onLoadVentasDelMesPorArticulo',
      loadDetallesKey: '',
      nextId: ''
    },
    canal: {
      id: 3,
      stringId: 'canal',
      description: 'Canales',
      ventasKey: 'ventasPorCanal',
      loadEvolucionKey: 'onLoadEvolucionVentasPorCanal',
      loadKey: 'onLoadVentasDelMesPorCanal',
      loadDetallesKey: 'onLoadDetallesCanal',
      nextId: 'grupodecompra'
    },
    cliente: {
      id: 4,
      stringId: 'cliente',
      description: 'Clientes',
      ventasKey: 'ventasPorCliente',
      loadEvolucionKey: 'onLoadEvolucionVentasPorCliente',
      loadKey: 'onLoadVentasDelMesPorCliente',
      loadDetallesKey: '',
      nextId: ''
    },
    grupodecompra: {
      id: 5,
      stringId: 'GrupoCompra',
      description: 'Grupos de Compras',
      ventasKey: 'ventasPorGrupoCompra',
      loadEvolucionKey: 'onLoadEvolucionVentasPorGrupoCompra',
      loadKey: 'onLoadVentasDelMesPorGrupoCompra',
      loadDetallesKey: 'onLoadDetallesGrupoCompra',
      nextId: 'responsable'
    },
    provincia: {
      id: 6,
      stringId: 'provincia',
      description: 'Provincias',
      ventasKey: 'ventasPorProvincia',
      loadEvolucionKey: 'onLoadEvolucionVentasPorProvincia',
      loadKey: 'onLoadVentasDelMesPorProvincia',
      loadDetallesKey: 'onLoadDetallesProvincia',
      nextId: 'responsable'
    },
    responsable: {
      id: 7,
      stringId: 'responsable',
      description: 'Responsables',
      ventasKey: 'ventasPorResponsable',
      loadEvolucionKey: 'onLoadEvolucionVentasPorResponsable',
      loadKey: 'onLoadVentasDelMesPorResponsable',
      loadDetallesKey: 'onLoadDetallesResponsable',
      nextId: 'vendedor'
    },
    unidaddenegocio: {
      id: 8,
      stringId: 'unidadNegocio',
      description: 'Unidades de Negocios',
      ventasKey: 'ventasPorUnidadNegocio',
      loadEvolucionKey: 'onLoadEvolucionVentasPorUnidadNegocio',
      loadKey: 'onLoadVentasDelMesPorUnidadNegocio',
      loadDetallesKey: 'onLoadDetallesUnidadNegocio',
      nextId: 'canal'
    },
    vendedor: {
      id: 9,
      stringId: 'vendedor',
      description: 'Vendedores',
      ventasKey: 'ventasPorVendedor',
      loadEvolucionKey: 'onLoadEvolucionVentasPorVendedor',
      loadKey: 'onLoadVentasDelMesPorVendedor',
      loadDetallesKey: 'onLoadDetallesVendedor',
      nextId: 'cliente'
    },
    zona: {
      id: 9,
      stringId: 'zona',
      description: 'Zonas',
      ventasKey: 'ventasPorZona',
      loadEvolucionKey: 'onLoadEvolucionVentasPorZona',
      loadKey: 'onLoadVentasDelMesPorZona',
      loadDetallesKey: 'onLoadDetallesZona',
      nextId: 'responsable'
    },
  };

  return (
    <div className={classes.PanelContainer}>
      {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {!props.ejecucionDePagina ?
        (<BoxEjecutarPaginaInfo />) :
        (<Grid container spacing={1}>
          <Grid item xs={12} style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
            <InteranualVentasConFiltro
              ejecucionDePagina={props.ejecucionDePagina}
              withoutDecimals={true}
            />
          </Grid>
          <Grid item xs={12} container
            style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
            <PieTableVentasMontoUnidadPorSeleccion
              ejecucionDePagina={renderItems}
              withoutDecimals={true}
              dataSelectOptions={props.dataSelectOptions}
            />
          </Grid>
          <Grid item className={classes2.lineEvolucionVentas}>
            <LineEvolucionVentasPorPeriodo
              ejecucionDePagina={renderItems}
              withoutDecimals={true}
              dataOptions={dataOptions}
            />
          </Grid>
        </Grid>)}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ejecucionDePagina: state.ejecucionPaginas.ejecucionPanelTenacta,
    periodos: state.ventas.evolucionVentasMensualInteranual.periodos,
    ventas: state.ventas.evolucionVentasMensualInteranual.items,
    dataSelectOptions: state.ventas.tipoVentasDesagregadas.tipos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    agregarPeriodoVenta: (periodo) => dispatch(actions.agregarEvolucionDeVentasMensualInteranual(periodo)),
    onLoadTipoVentas: () => dispatch(actions.loadTipoDeVentasDesagregadas()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelTenacta);