// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportadorBudgetForecast__TitleContainer__oG0oF {\r\n  margin-block: 15px !important;\r\n  width: 100%;\r\n  text-align: left !important;\r\n}\r\n\r\n.ImportadorBudgetForecast__Title__Y--lE {\r\n  font-weight: bold !important;\r\n  font-size: medium !important;\r\n}\r\n\r\n.ImportadorBudgetForecast__Subtitle__lX1vn {\r\n  font-size: small !important;\r\n}\r\n\r\n.ImportadorBudgetForecast__ConfigIcon__fgK8m {\r\n  margin: 15px !important;\r\n  text-align: end !important;\r\n}\r\n\r\n.ImportadorBudgetForecast__TabsContainer__Yre9a {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}\r\n\r\n.ImportadorBudgetForecast__TabsScroller__9aIif {\r\n  display: flex !important;\r\n  justify-content: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.ImportadorBudgetForecast__TabsIndicator__9Dx9c {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleContainer": "ImportadorBudgetForecast__TitleContainer__oG0oF",
	"Title": "ImportadorBudgetForecast__Title__Y--lE",
	"Subtitle": "ImportadorBudgetForecast__Subtitle__lX1vn",
	"ConfigIcon": "ImportadorBudgetForecast__ConfigIcon__fgK8m",
	"TabsContainer": "ImportadorBudgetForecast__TabsContainer__Yre9a",
	"TabsScroller": "ImportadorBudgetForecast__TabsScroller__9aIif",
	"TabsIndicator": "ImportadorBudgetForecast__TabsIndicator__9Dx9c"
};
export default ___CSS_LOADER_EXPORT___;
