import React, { useState, useRef, useEffect } from "react";
import * as actions from './../../../../store/actions';
import { connect } from 'react-redux';
import { formatNumberWithoutDecimals } from "../../../../shared/formatNumber";
import { storageMonedaSigno } from "../../../../shared/sessionData";
import CustomMixedChart from "../../../Charts/CustomMixedChart";
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { enviarPorMailOption } from "../../EnviarChart/EnviarChartOptions";

const CustomBarChartPivotGrid = (props) => {
  const { inHorizontalView } = props;
  const [itemsLocales, setUtilidadesLocal] = useState([]);
  let chartRef = useRef(null);

  useEffect(() => {
    if (props.items)
      setUtilidadesLocal(props.items);
  }, [props.items]);

  const armarTitleConFiltros = () => {
    const title = props.disabledTitle ? '' : `Análisis por ${props.itemPor}`;
    return (
      <Grid container
        direction='row'
        alignItems='center'
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item xs={12} sm={12} md={6} lg={3}>{title}</Grid>
        {!props.disabled && props.items !== 0 && !props.loading && (
          <Grid item xs={12} sm={12} md={6} lg={4}
            container
            direction='row'
            alignItems='center'
            style={{ justifyContent: 'flex-end' }}
          >
            <Grid item md={3}
              container
              direction='row'
              alignItems='center'
              style={{ justifyContent: 'flex-end', width: 'auto' }}
            >
              <Grid item md={6}>
                <Tooltip title='Ver filtros del indicador'>
                  <IconButton size="small" style={{ marginRight: '10px' }}
                    onClick={() => props.onShowFiltersModal(`Análisis por ${props.itemPor}`)}>
                    <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const options = {
    series: props.itemSeries,
    options: {
      chart: {
        type: 'bar',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 5,
          left: 3,
          blur: 7,
          opacity: 0.2
        },
        height: props.customHeight ? props.customHeight : 350,
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            customIcons: [
              enviarPorMailOption(chartRef, `Análisis por ${props.itemPor}`),
            ]
          }
        },
        zoom: {
          enabled: true,
          type: 'xy',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: inHorizontalView
        }
      },
      xaxis: {
        categories: itemsLocales.map((item) => item.name),
        tooltip: {
          enabled: false
        },
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          trim: true,
          maxHeight: 120,
          formatter: inHorizontalView
            ? function (val, opts) {
              return inHorizontalView && opts !== undefined && opts.dataPointIndex >= 0
                ? itemsLocales[opts.dataPointIndex].name
                : props.showMonto ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(val)}` : `${formatNumberWithoutDecimals(val)}`;
            }
            : undefined
        },
        tickPlacement: !inHorizontalView ? 'on' : undefined
      },
      yaxis: {
        categories: inHorizontalView
          ? props.categories.filter((util) => util.estaOculto === false)
          : itemsLocales.filter((util) => util.estaOculto === false),
        showAlways: false,
        forceNiceScale: true,
        labels: {
          minWidth: !inHorizontalView ? undefined : 130,
          formatter: !inHorizontalView
            ? function (val, opts) {
              return inHorizontalView && opts !== undefined && opts.dataPointIndex >= 0
                ? itemsLocales[opts.dataPointIndex].name
                : props.showMonto ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(val)}` : `${formatNumberWithoutDecimals(val)}`;
            }
            : function (val, opts) {
              return !inHorizontalView && opts !== undefined && opts.dataPointIndex >= 0
                ? undefined
                : typeof val === 'number' ? props.showMonto ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(val)}` : `${formatNumberWithoutDecimals(val)}` : val;
            }
        }
      },
      legend: {
        position: 'bottom'
      },
      fill: {
        opacity: 1
      }
    }
  }

  return (
    <CustomMixedChart
      title={armarTitleConFiltros()}
      subTitle={props.total && `Total: ${storageMonedaSigno()} ${formatNumberWithoutDecimals(props.total)}`}
      customOptions={options.options}
      chartType="bar"
      disableBoxShadow={props.disableBoxShadow}
      series={options.series}
      imgSinDatos={props.chartSinDatos || (props.items && props.items.length === 0)}
      loading={props.loading}
      chartHeight={props.customHeight ? props.customHeight : 300}
      chartWidth={'99%'}
      chartRef={chartRef}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    filtrosChart: state.tenacta.filtrosChartInformeUno,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta'])),
    onUpdateFiltros: (filtros) => dispatch(actions.updateFiltrosChartInformeUno(filtros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomBarChartPivotGrid);