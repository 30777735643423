import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import moment from 'moment';

import * as actions from '../../../../store/actions/index';

import LineChart from '../../../Charts/CustomLineChart';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './LineEvolucionGastosMensualPorCateg.css';
import BarGastosPorCategoriaPorMes from '../BarGastosPorCategoriaPorMesComparacion/BarGastosPorCategoriaPorMesComparacion';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import BoxStepInfo from '../../../UI/BoxStepInfo/BoxStepInfo';
import { fechaActualFunc } from '../../../../shared/fechas';
import FilterListIcon from '@material-ui/icons/FilterList';

const LineEvolucionGastosMensualPorCateg = (props) => {
  const { gastos, total, loading, estaCargado, periodoSeleccionado } = props;
  const [gastosSeleccionados, setGastosSeleccionados] = useState([]);
  const [loadingAddCateg, setLoadingAddCateg] = useState(false);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [banderaGastosComparacion, setBanderaGastosComparacion] = useState(false);
  const [loadingComparacion, setLoadingComparacion] = useState(false);
  const [gastosComparar, setGastosComparar] = useState([]);
  const [formatGastosCompletos, setFormatGastosCompletos] = useState([]);
  const [inSelectRange, setInSelectRange] = useState(false);
  const [periodo, setPeriodo] = useState(periodoSeleccionado);

  useEffect(() => {
    setLoadingAddCateg(true);
    if (props.ejecucionDePagina && (!estaCargado || periodo.getFullYear() !== periodoSeleccionado.getFullYear())) {
      setInSelectRange(false);
      gastosSeleccionados.splice(0, gastosSeleccionados.length);
      const mes = fechaActualFunc().getMonth() + 1;
      const anio = periodoSeleccionado.getFullYear();
      props.onLoadChart(mes, anio, (data, fechas) => {
        series(data);
        formatMesesToStrings(fechas);
        setLoadingAddCateg(false);
      });
      setPeriodo(periodoSeleccionado);
    } else {
      series(gastos);
      formatMesesToStrings(props.fechas);
      setLoadingAddCateg(false);
    }
  }, [estaCargado, props.ejecucionDePagina, periodoSeleccionado, periodo]);

  useEffect(() => {
    if (props.gastos.length === 0) {
      setGastosComparar([]);
      setBanderaGastosComparacion(!banderaGastosComparacion);
    }
  }, [props.gastos, props.estaCargado]);

  const series = (data) => {
    let formatGastos = [];
    data.map((gasto) => {
      return formatGastos.push({
        name: gasto.categoria,
        data: []
      });
    });

    return setGastosSeleccionados(formatGastos);
  };

  const formatMesesToStrings = (fechas) => {
    const formatFechas = [];
    fechas.map((fecha) => {
      return formatFechas.push(`${moment(new Date(fecha.anio, fecha.mes, 1)).subtract(1, 'month').format('MM/YY')}`);
    });
    setFechas(formatFechas);
  };

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const gastosActual = gastosSeleccionados;
    if (gastosActual[seriesIndex].data.length !== 0) {
      gastosActual[seriesIndex].data = [];
    } else {
      gastosActual[seriesIndex].data = props.gastos[seriesIndex].montosMesAnio.map(
        (montoMesAnio) => montoMesAnio.monto
      );
    }
    setGastosSeleccionados(gastosActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${gastosSeleccionados
        .filter((gast) => gast.data.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((gasto) => {
          return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(gasto.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${gasto.name}: ${storageMonedaSigno()} ${formatNumber(gasto.data[dataPointIndex])} 
                      </span>
                    </div>
                `;
        })
        .join('')}
              </div>
      </div>
      `;
  };

  const verifyEqualDates = (date1, date2) => {
    const mesDateAnterior = date1.getMonth();
    const anioDateAnterior = date1.getFullYear();

    const mesDateActual = date2.getMonth();
    const anioDateActual = date2.getFullYear();

    return mesDateAnterior === mesDateActual && anioDateAnterior === anioDateActual;
  };

  const onMarkerClick = (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
    const mes = props.fechas[dataPointIndex].mes;
    const anio = props.fechas[dataPointIndex].anio;
    const dateComplete = new Date(anio, mes, 1);
    let tempGastosComparar = gastosComparar;
    let tempFormatGastosCompletos = formatGastosCompletos;

    if (gastosComparar && gastosComparar.length === 0
      && formatGastosCompletos && formatGastosCompletos.length > 0) {
      tempFormatGastosCompletos = [];
      setFormatGastosCompletos([]);
    }

    if (tempGastosComparar.length === 2) {
      tempGastosComparar = [];
      tempFormatGastosCompletos = [];
      setGastosComparar([]);
      setFormatGastosCompletos([]);
    }

    if (tempGastosComparar.length < 2) {
      if (tempGastosComparar.length === 1) {
        if (verifyEqualDates(tempGastosComparar[0].dateComplete, dateComplete)) {
          return;
        }
      }

      setLoadingComparacion(true);
      props.onLoadChartComparacion(mes, anio, true, (gastosCompleto) => {
        if (tempGastosComparar.length === 1) {
          var arrayNuevo = [];

          tempFormatGastosCompletos[0].forEach((gastoCompleto) => {
            const verify = gastosCompleto.filter((gasto) => gasto.id_CategoriaItem === gastoCompleto.id_CategoriaItem);
            if (verify.length === 0) {
              arrayNuevo.push({ ...gastoCompleto, monto: 0 });
            }
          });

          gastosCompleto.map((gasto) => {
            return arrayNuevo.push(gasto);
          });
          gastosCompleto.forEach((gastoOrdenado) => {
            const verify = tempFormatGastosCompletos[0].filter(
              (gasto) => gasto.id_CategoriaItem === gastoOrdenado.id_CategoriaItem
            );

            if (verify.length === 0) {
              tempFormatGastosCompletos[0].push({ ...gastoOrdenado, monto: 0 });
            }
          });

          var orderArrayNuevo = arrayNuevo.sort((a, b) => a.categoria.localeCompare(b.categoria));
          var orderFormatGastosCompletos = tempFormatGastosCompletos[0].sort((a, b) =>
            a.categoria.localeCompare(b.categoria)
          );

          setGastosComparar([
            { dateComplete: tempGastosComparar[0].dateComplete, gastos: orderFormatGastosCompletos },
            { dateComplete: dateComplete, gastos: orderArrayNuevo }
          ]);
        } else {
          tempFormatGastosCompletos.push(gastosCompleto);
          gastosComparar.push({ dateComplete: dateComplete, gastos: gastosCompleto });
        }

        setFormatGastosCompletos(tempFormatGastosCompletos);
        setTimeout(() => setLoadingComparacion(false), 1000);
        setTimeout(
          () =>
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: 'smooth'
            }),
          1050
        );
      });
    }
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const verifyEmptyGastosSeleccioandos = gastosSeleccionados.filter((gastSelect) => gastSelect.data.length !== 0);

  const middleControls = () => {
    return (
      <Tooltip
        title={
          verifyEmptyGastosSeleccioandos.length > 0
            ? !inSelectRange
              ? 'Al activar esta función, podrá seleccionar 2 períodos para compararlos.'
              : 'Haga click en dos períodos diferentes para poder compararlos. (Seleccione 2 puntos diferentes en el gáfico  y se mostrara la comparación en el gráfico de más abajo)'
            : 'Seleccione por lo menos una categoría para activar la selección de períodos'
        }
      >
        <Typography style={{ marginBottom: '1px', marginTop: '3%', marginRight: '1%' }}>
          {!inSelectRange ? `Active la selección de períodos → ` : `Seleccione 2 períodos para comparar `}
          {
            <IconButton
              disabled={verifyEmptyGastosSeleccioandos.length === 0}
              style={{
                backgroundColor: inSelectRange && verifyEmptyGastosSeleccioandos.length !== 0 && 'rgb(255 208 159)'
              }}
              size="small"
              onClick={() => setInSelectRange(!inSelectRange)}
            >
              <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            </IconButton>
          }
        </Typography>
      </Tooltip>
    );
  };

  const titles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} l={12} container spacing={1} className={classes.Header}>
          <Grid item xs={8} lg={5} className={classes.Title}>
            <Typography className={classes.TitleText}>
              Gastos mensuales por categoría
            </Typography>
          </Grid>

          <Grid item xs={11} lg={4} className={classes.SpecialTool} style={{ textAlign: 'center' }}>
            {middleControls()}
          </Grid>

          <Grid item xs={1} lg={3} className={classes.Filters}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Title}>
          <Typography className={classes.SubtitleText} >
            (Haga clic sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información)
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const subtitles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} l={12} container spacing={1} className={classes.Header}>

          <Grid item xs={12} className={classes.Title}>
            <Typography className={classes.TotalNumberText}>
              Total: {storageMonedaSigno()}{formatNumber(total)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.LineEvolucionGastosMensualContainer}>
      <BoxStepInfo
        texto={
          '1° Paso - Haga click sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información.'
        }
      />
      <LineChart
        title={titles()}
        series={gastosSeleccionados}
        subTitle={subtitles()}
        seriesBandera={seriesBandera}
        loading={loading || loadingAddCateg}
        chartTextAlign={'left'}
        eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
        eventsMarkerClick={(event, chartContext, { seriesIndex, dataPointIndex, config }) =>
          inSelectRange && onMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config })
        }
        middleItems={middleControls()}
        legendOnItemClick={false}
        chartHeight={'450px'}
        width={'100%'}
        chartWidth={'99%'}
        chartDropShadow={{
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }}
        toolbarShow={true}
        tooltipEnabled
        customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
          armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
        }
        zoomType={'xy'}
        dataLabelsEnabled={window.screen.width > 500}
        formatDataLabels
        strokeCurve={'smooth'}
        strokeWidth={5}
        fillType={'solid'}
        gridBorderColor={'#e7e7e7'}
        gridRow={{
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }}
        markersSize={4}
        xaxisCategories={fechas}
        xaxisType="category"
        yaxisLabels={{
          show: true,
          formatter: (value) => {
            return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
          },
          align: 'left',
          offsetX: -16
        }}
        legendFontSize={'11px'}
        legendShowForSingleSeries={true}
        legendShowForNullSeries={true}
        legendShowForZeroSeries={true}
        disableLegendOnItemClickToggle
        disableLengendOnItemHover
        legendPosition={'right'}
        legendHorizontalAlign={'left'}
        legendOffsetY={10}
        onFiltersClick={() => props.onShowFiltersModal()}
      />
      <div style={{ height: '10px' }} />
      <BoxStepInfo
        texto={
          <div>
            {`2° Paso - Seleccione dos períodos en el gráfico "Gastos mensuales por categoría" utilizando la herramienta `}
            <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            {` para visualizar la información`}
          </div>
        }
      />
      <BarGastosPorCategoriaPorMes
        inComparacion
        banderaComparacion={banderaGastosComparacion}
        loading={loadingComparacion}
        gastosComparar={gastosComparar}
        onLimpiarComparacionClick={() => {
          setGastosComparar([]);
          setFormatGastosCompletos([]);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.gastos.evolucionGastosPorCategoria.loading,
    estaCargado: state.gastos.evolucionGastosPorCategoria.estaCargado,
    gastos: state.gastos.evolucionGastosPorCategoria.gastos,
    total: state.gastos.evolucionGastosPorCategoria.total,
    fechas: state.gastos.evolucionGastosPorCategoria.fechas,
    periodoSeleccionado: state.gastos.evolucionMensualInteranual.periodoSeleccionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio, success) => dispatch(actions.loadEvolucionGastosPorCategoria(mes, anio, undefined, success)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Gastos mensuales por categoria', ['gasto'])),
    onLoadChartComparacion: (mes, anio, returnData, success) =>
      dispatch(actions.loadPieGastosPorCategoriaSinDataTable(mes, anio, undefined, returnData, success))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionGastosMensualPorCateg);
