// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelTenacta__PanelContainer__A8KOb {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.PanelTenacta__AnalisisVentasContainer__IvtbQ {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.PanelTenacta__FlexHorizontal__Bj9\\+q {\r\n  display: flex;\r\n  flex-flow: row;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.PanelTenacta__FlexVertical__SXDR4 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-flow: column;\r\n}\r\n\r\n.PanelTenacta__IndicadorBarra__t1o-e {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  margin-right: 5px;\r\n}\r\n\r\n.PanelTenacta__IndicadorTabla__3Bb5i {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  margin-left: 5px;\r\n}\r\n\r\n.PanelTenacta__separador__yT46M {\r\n  width: 10px;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .PanelTenacta__IndicadorBarra__t1o-e {\r\n    max-width: 682px;\r\n  }\r\n  .PanelTenacta__IndicadorTabla__3Bb5i {\r\n    max-width: 682px;\r\n  }\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PanelContainer": "PanelTenacta__PanelContainer__A8KOb",
	"AnalisisVentasContainer": "PanelTenacta__AnalisisVentasContainer__IvtbQ",
	"FlexHorizontal": "PanelTenacta__FlexHorizontal__Bj9+q",
	"FlexVertical": "PanelTenacta__FlexVertical__SXDR4",
	"IndicadorBarra": "PanelTenacta__IndicadorBarra__t1o-e",
	"IndicadorTabla": "PanelTenacta__IndicadorTabla__3Bb5i",
	"separador": "PanelTenacta__separador__yT46M"
};
export default ___CSS_LOADER_EXPORT___;
