// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaPorLinea__tablaVentasPorRubro__928EY {\r\n  border: \"0px solid #ccc\";\r\n  box-shadow: \"0px 3px 6px #00000029\";\r\n  border-radius: \"3px\"\r\n}\r\n\r\n.TablaPorLinea__TablaVentasDesagregadasMontoUnidad__nmshb {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n  height: 100%;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablaVentasPorRubro": "TablaPorLinea__tablaVentasPorRubro__928EY",
	"TablaVentasDesagregadasMontoUnidad": "TablaPorLinea__TablaVentasDesagregadasMontoUnidad__nmshb"
};
export default ___CSS_LOADER_EXPORT___;
