/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import * as actions from '../../../store/actions/index';

import Spinner from '../Spinner/Spinner';
import { storageMonedaId, storageMonedaSigno } from '../../../shared/sessionData';
import { Tooltip, Typography, Select, MenuItem, FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '3px',
  },
  tooltipTitle: {
    textAlign: 'left', 
    font: 'normal normal normal 14px/19px Roboto', 
    color: '8D8C8C'
  }
}));

const MonedaSelector = (props) => {
  const classes = useStyles();

  const [monedaId, setMonedaId] = useState(storageMonedaId() !== null ? storageMonedaId() : '');
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleTooltip = bool => {
      setTooltipOpen(bool)
  }

  useEffect(() => {
    if (props.monedas.length === 0 && props.token !== null) props.onLoadMonedas(props.token);
  }, []);

  return props.loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontSize: '20px' }}>{storageMonedaSigno()}</div>
      <FormControl style={{ margin: '0px 10px 0px 10px' }}>
      <Tooltip
          open={tooltipOpen}
          arrow
          title={
            <Typography className={classes.tooltipTitle}>
              Seleccione una moneda
            </Typography>
          }
          classes={{
            tooltip:classes.tooltip,
          }}
        >
          <Select
            style={{ minWidth: 120, color: props.letraColor }}
            labelId="monedaLabel"
            id="monedaSelect"
            value={props.monedas.length !== 0 ? monedaId : ''}
            onChange={(event) => {
              setMonedaId(event.target.value);
              props.onSeleccionarMoneda(event.target.value);
            }}
            onMouseEnter={() => {handleTooltip(true)}}
            onMouseLeave={() => {handleTooltip(false)}}
            onOpen={() => {handleTooltip(false)}}
            disabled={props.disabled}
          >
            {props.monedas.map((moneda) => (
              <MenuItem key={moneda.idMoneda} value={moneda.idMoneda}>
                {moneda.nombre}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.moneda.loading,
    monedas: state.moneda.monedas,
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadMonedas: (token) => dispatch(actions.loadMonedas(token)),
    onSeleccionarMoneda: (monedaId) => dispatch(actions.seleccionarMoneda(monedaId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonedaSelector);