import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { S3Config } from '../../../../../shared/awsConfigs';
import { storageEmpresaId } from '../../../../../shared/sessionData';
import * as actions from '../../../../../store/actions';
import { Grid } from "@material-ui/core";
import { useFilePicker } from 'use-file-picker';
import Button from '../../../../UI/Button/Button';
import Spinner from '../../../../UI/Spinner/Spinner';
import Table from '../../../../UI/Table/Table';
import xlsx from 'xlsx'
import * as FileValidatorService from "../../../../../services/tenacta/BudgetForecastFileValidatorService";
import * as FileFormatterService from "../../../../../services/tenacta/BudgetForecastFileFormatterService";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import ConfirmDialog from '../../../../UI/Dialog/ConfirmDialog';
import OKDialog from '../../../../UI/Dialog/OKDialog';

const SelectorArchivo = (props) => {
  const { onUploadFileDataToServer } = props;
  const { loadingBudgets, budgetsForecasts, onLoadBudgets } = props;
  const { approveLoading } = props;
  const { anioFiscal } = props;
  const [openFileSelector, { filesContent, fileLoading, clear }] = useFilePicker({
    accept: ['.xlsx'],
    readAs: "ArrayBuffer"
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorsTableVisible, setIsErrorsTableVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [uploadButtonText, setUploadButtonText] = useState(false);
  const [importDialogText, setImportDialogText] = useState(false);
  const [idArchivo, setIdArchivo] = useState(0);
  const [tipoArchivo, setTipoArchivo] = useState(null);
  const [idEmpresa] = useState(storageEmpresaId());

  // budget-forecast-table columns
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220 220 220)'
  };
  const columns = [
    { title: 'Fila', field: 'rowNumber', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Artículo', field: 'product', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Errores', field: 'errors', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle, render: rowData => rowData.errors ? (<ul>{rowData.errors.map(err => <li>{err}</li>)}</ul>) : "" }
  ];

  const onAbrirSelectorDeArchivos = () => {
    handleImportFileConfirmDialogClose();
    openFileSelector()
  };

  const excelToJson = (data) => {
    const workbook = xlsx.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = xlsx.utils.sheet_to_json(worksheet, {header: "A", range: 0});
    return json;
  };


  const handleUploadFileToS3 = async (file, idArchivo) => {
    const extention = file.name.split('.').pop();
    const fileName = `${idArchivo.toString().trim()}.${extention}`
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    
    const newArrayBuffer = file.content;
    const newBlob = new Blob([newArrayBuffer], { type: contentType });
    const newFile = new File([newBlob], fileName, { type: contentType });

    var formData = new FormData()
    formData.append("ContainerName", "budgets-forecasts")
    formData.append("FileName", `${fileName}`)
    formData.append("ContentType", `${contentType}`)
    formData.append("File", newFile)

    props.onUploadFileToStorageAccount(formData)
    // const client = new S3Client({
    //   region: S3Config.region,
    //   credentials: {
    //     accessKeyId: S3Config.accessKeyId,
    //     secretAccessKey: S3Config.secretAccessKey,
    //   }
    // });
    // const extention = file.name.split('.').pop();
    // const params = {
    //   Bucket: S3Config.buckeName,
    //   Key: `budgets_forecasts/${idEmpresa}/${idArchivo.toString().trim()}.${extention}`,
    //   Body: new Blob([file.content]),
    //   ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // };

    // try {
    //   const parallelUploadS3 = new Upload({
    //     client,
    //     leavePartsOnError: false,
    //     params
    //   });

    //   parallelUploadS3.on("httpUploadProgress", (progress) => {
    //     console.log(progress);
    //     callback();
    //   });

    //   await parallelUploadS3.done();
    // } catch (error) {
    //   console.log(error);
    // }
  }

  const onUploadFileDataToServerSuccess = (data, file) => {
    if (data.hasErrors) {
      const errors = data.errors.map(error => (
        {
          rowNumber: error.affectedRows,
          product: error.idArticulo,
          errors: error.errors
        }
      ));
      setValidationErrors(errors);
      setIsLoading(false);
    }
    else {
      handleUploadFileToS3(file, data.idArchivo);
    }
  }

  const onUploadFileDataToServerFail = (error) => {
    setIsLoading(false);
    alert(error);
  }

  useEffect(() => {
    const isErrorsTableVisible = !fileLoading
      && !isLoading
      && !loadingBudgets
      && validationErrors.length > 0;
    setIsErrorsTableVisible(isErrorsTableVisible);

  }, [fileLoading, isLoading, validationErrors]);

  useEffect(() => {
    clear();
    setValidationErrors([]);
    var idArchivo = 0;
    var tipoArchivo = null;
    var buttonText = "Cargando...";
    var importDialogText = "";
    if (!loadingBudgets) {
      var forecastExists = budgetsForecasts.filter(i => i.tipo === "F").length > 0;
      var notApprovedForecastExists = budgetsForecasts.filter(i => i.tipo === "F" && !i.aprobado).length > 0;
      var budgetExists = budgetsForecasts.filter(i => i.tipo === "B").length > 0;
      var notApprovedBudgetExists = budgetsForecasts.filter(i => i.tipo === "B" && !i.aprobado).length > 0;

      if (forecastExists) {
        tipoArchivo = "F";
        if (notApprovedForecastExists) {
          const forecast = budgetsForecasts.find(i => i.tipo === "F" && !i.aprobado);
          idArchivo = forecast.id;
          buttonText = "Actualizar Forecast";
          importDialogText = (
            <Fragment>
              <b>Esta acción actualizará el último Forecast importado.</b><br />
              A continuación se le solicitará que elija un archivo Excel (xlsx) para importar.<br />
              <br />
              <u>NOTA:</u> En caso de querer importar un nuevo Forecast y mantener el anterior,<br />
              previamente deberá aprobar el Forecast no aprobado.<br />
              <br />
              Para continuar, por favor confirme la acción.
            </Fragment>);
        }
        else {
          buttonText = "Importar Forecast";
          importDialogText = (
            <Fragment>
              <b>Esta acción importará un Forecast adicional.</b><br />
              A continuación se le solicitará que elija un archivo Excel (xlsx) para importar.<br />
              <br />
              Para continuar, por favor confirme la acción.
            </Fragment>);
        }
      }
      else {
        if (budgetExists) {
          if (notApprovedBudgetExists) {
            const budget = budgetsForecasts.find(i => i.tipo === "B" && !i.aprobado);
            idArchivo = budget.id;
            tipoArchivo = "B";
            buttonText = "Actualizar Budget";
            importDialogText = (
              <Fragment>
                <b>Esta acción actualizará el Budget previamente importado.</b><br />
                A continuación se le solicitará que elija un archivo Excel (xlsx) para importar.<br />
                <br />
                <u>NOTA:</u> En caso de querer importar un Forecast, previamente deberá aprobar el Budget.<br />
                <br />
                Para continuar, por favor confirme la acción.
              </Fragment>);
          }
          else {
            tipoArchivo = "F";
            buttonText = "Importar Forecast";
            importDialogText = (
              <Fragment>
                <b>Esta acción importará un Forecast.</b><br />
                A continuación se le solicitará que elija un archivo Excel (xlsx) para importar.<br />
                <br />
                Para continuar, por favor confirme la acción.
              </Fragment>);
          }
        }
        else {
          tipoArchivo = "B";
          buttonText = "Importar Budget";
          importDialogText = (
            <Fragment>
              <b>Esta acción importará un Budget.</b><br />
              A continuación se le solicitará que elija un archivo Excel (xlsx) para importar.<br />
              <br />
              Para continuar, por favor confirme la acción.
            </Fragment>);
        }
      }
      buttonText += ` ${anioFiscal}`;
    }
    setIdArchivo(idArchivo);
    setTipoArchivo(tipoArchivo);
    setUploadButtonText(buttonText);
    setImportDialogText(importDialogText);
  }, [loadingBudgets, budgetsForecasts]);

  useEffect(() => {
    if (filesContent && filesContent.length) {
      const file = filesContent[0];
      if (file) {
        setIsLoading(true);
        setValidationErrors([]);
        
        // convert excel to json
        const json = excelToJson(file.content);
        // validate budget/forecast uploaded locally
        const errors = FileValidatorService.validate(json, anioFiscal);

        if (errors.length > 0) {
          setValidationErrors(errors);
          setIsLoading(false);
        }
        else {
          // slice(1) es para sacar el header
          const detail = FileFormatterService.squash(json.slice(1));
          const data = {
            idArchivo,
            anioFiscal,
            tipoArchivo,
            items: detail
          }
          
          // upload data to server
          onUploadFileDataToServer(data, file, onUploadFileDataToServerSuccess, onUploadFileDataToServerFail);
        }
      }
    }
  }, [filesContent]);

  useEffect(() => {
    if(!props.loadingExportador &&  props.estaCargadoExportador)
    {
      setIsLoading(false);
        clear(); // reset file uploaded
        onLoadBudgets(anioFiscal);
        handleImportFileOKDialogOpen();
    }

    if(props.errorExportador)
      console.log(props.errorExportador)
  }, [props.loadingExportador])
  
  // Import confirm dialog
  const [importFileConfirmDialogOpen, setImportFileConfirmDialogOpen] = useState(false);

  const handleImportFileConfirmDialogOpen = () => {
    setImportFileConfirmDialogOpen(true);
  };

  const handleImportFileConfirmDialogClose = () => {
    setImportFileConfirmDialogOpen(false);
  };
  
  const importFileConfirmDialog = () => (
    <ConfirmDialog
      show={importFileConfirmDialogOpen}
      title="Importar Budget/Forecast"
      body={importDialogText}
      onConfirm={onAbrirSelectorDeArchivos}
      onClose={handleImportFileConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - Import confirm dialog

  // Import OK dialog at the end
  const [importFileOKDialogOpen, setImportFileOKDialogOpen] = useState(false);

  const handleImportFileOKDialogOpen = () => {
    setImportFileOKDialogOpen(true);
  };

  const handleImportFileOKDialogClose = () => {
    setImportFileOKDialogOpen(false);
  };
  
  const importFileOKDialog = () => (
    <OKDialog
      show={importFileOKDialogOpen}
      title="Importar Budget/Forecast"
      body="Archivo importador exitosamente."
      onConfirm={handleImportFileOKDialogClose}
      onClose={handleImportFileOKDialogClose}
    >
    </OKDialog>
  );
  // - Import OK dialog

  return (
    <Grid container spacing={1} 
      direction="column" justifyContent="flex-start"
      style={{ marginTop: '10px' }}
    >
      <Grid item xs={6} md={12}>
        <Button
          onClick={handleImportFileConfirmDialogOpen}
          disabled={ fileLoading || isLoading || loadingBudgets || approveLoading }>
            {uploadButtonText}
        </Button>
        {filesContent.map(file => (
          <h4>{file.name}</h4>
        ))}
        {isLoading && <Spinner size="small" />}
      </Grid>

      <Grid item xs={12} md={12}>
        {isErrorsTableVisible && 
          <Table
            style={{ width: '100%', marginTop: 0, marginBottom: '15px' }}
            title=""
            noBoxShadow
            columns={columns}
            data={validationErrors}
            customOptions={{
              thirdSortClick: false,
              sorting: false,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 450
            }}
            tableKey={"tabla-selector-archivo"}
          />
        }
      </Grid>
      {importFileConfirmDialog()}
      {importFileOKDialog()}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    uploadingResult: state.tenacta.importadorBudgetForecast.items,
    loadingBudgets: state.tenacta.budgetsForecasts.loading,
    budgetsForecasts: state.tenacta.budgetsForecasts.items,
    approveLoading: state.tenacta.aprobarBudgetForecast.loading,
    loadingExportador: state.tenacta.exportadorBudgetForecast.loading, 
    errorExportador: state.tenacta.exportadorBudgetForecast.error,
    estaCargadoExportador: state.tenacta.exportadorBudgetForecast.estaCargado, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadFileDataToServer: (data, file, onSuccess, onFail) => 
      dispatch(actions.uploadFileDataToServer(data, file, onSuccess, onFail)),
    onLoadBudgets: (anioFiscal) => dispatch(actions.loadBudgetsForecasts(anioFiscal)),
    onUploadFileToStorageAccount: (data) => 
      dispatch(actions.uploadFileToStorageAccount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorArchivo);