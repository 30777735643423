import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classes from './InformesTabs.css';

const InformesTabs = (props) => {
  const { customFirstPage, flagResetIndex } = props;
  const [indexSelected, setIndexSelected] = useState(customFirstPage ? customFirstPage : 0);

  useEffect(() => {
    setIndexSelected(0);
  }, [flagResetIndex]);

  return (
    <div>
      <Tabs
        variant={'fullWidth'}
        centered
        classes={{
          scroller: classes.TabsScroller,
          flexContainer: classes.TabsContainer,
          indicator: classes.TabsIndicator,
        }}
        value={indexSelected}
        onChange={(e, value) => setIndexSelected(value)}
      >
        {props.tabs.map((tab, key) => {
          return <Tab key={key} style={{ width: '100%', minHeight: 0, fontSize: 'x-small' }} label={tab} />;
        })}
      </Tabs>
      {props.views[indexSelected]}
    </div>
  );
};

export default InformesTabs;