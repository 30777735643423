import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';

const TextoPlazoMedioVencimientosACobrar = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);
  const isDisabledChart = props.estaOculto && !props.empresa;

  console.log("Valor de cobranzasSinImputar: ", props.cobranzasSinImputar)
  return (
    <TextChart
      title="Plazo medio vencimientos a cobrar"
      bigAmount={`Días: ${Intl.NumberFormat('de-DE').format(props.diasPromedio)}`}
      bigAmountTooltip="Prom. de días"
      smallAmount={props.deuda}
      smallAmountTooltip="Total"
      smallCurrency
      // rightAmountTop={props.deuda}
      // rightAmountTopTooltip="Vencimientos"
      // rightAmountMiddle={props.cobranzasSinImputar}
      // rightAmountMiddleTooltip="Cobranzas no imputadas"
      disabled={isDisabledChart}
      loading={props.loading}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.textoPlazoMedioVencimientos.loading,
    diasPromedio: state.ventas.textoPlazoMedioVencimientos.promedioDias,
    cobranzasSinImputar: state.ventas.textoPlazoMedioVencimientos.cobranzasSinImputar,
    deuda: state.ventas.textoPlazoMedioVencimientos.deuda,
    error: state.ventas.textoPlazoMedioVencimientos.error,
    estaCargado: state.ventas.textoPlazoMedioVencimientos.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.plazoMedioVencACobrar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadTextoPlazoMedioVencimientos()),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Plazo medio vencimientos a cobrar', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPlazoMedioVencimientosACobrar);
