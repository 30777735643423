import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageFechaFija, storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaCobranzasDelDiaToExcel = (props) => {
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const formatData = () => {
    var sumMonto = 0;
    var formatData = [];

    props.cobranzas.map((cobranza) => {
        sumMonto = sumMonto += cobranza.monto;
        const inTotal = !cobranza.id;
        return formatData.push([
            {
                value: cobranza.clienteId,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: cobranza.cliente,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: moment(cobranza.fecha).format('YYYY-MM-DD'),
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: cobranza.nombreComprobante,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: cobranza.referencia,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
                value: cobranza.nroExterno,
                style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }
            },
            {
            value: `${storageMonedaSigno()} ${formatNumber(cobranza.total)}`,
            style: { font: { sz: '10', bold: inTotal }, fill: inTotal && {fgColor: {rgb: 'FFD3D3D3' } } }

            }
        ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
            { title: 'Cod.', width:{wpx: 65}, style: headersStyle }, 
            { title: 'Cliente', width:{wpx: 250}, style: headersStyle }, 
            { title: 'Fecha', width:{wpx: 150}, style: headersStyle }, 
            { title: 'Comprobante', width:{wpx: 140}, style: headersStyle }, 
            { title: 'Ref', width:{wpx: 80}, style: headersStyle }, 
            { title: 'Num. Externo', width:{wpx: 150}, style: headersStyle }, 
            { title: 'total', width:{wpx: 150}, style: headersStyle }, 
        ],
      data: formatData()
    }
  ];

  return (
    <ExcelFile
        filename={`Cobranzas del día ${storageFechaFija()}`}
        element={
            <Tooltip title="Descargar en excel">
                <IconButton style={{ marginRight: '10px' }} size="small">
                    <img alt="" src={IconConvertToExcel} height={'28px'} />
                </IconButton>
            </Tooltip>
        }
    >
    <ExcelSheet dataSet={multiDataSet} name={`Cobranzas del día`} />
    </ExcelFile>
  );
};

export default TablaCobranzasDelDiaToExcel;
