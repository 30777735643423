import React, { useRef } from 'react';
import classes from './PieTableVentasMontoUnidadPorSeleccion.css';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import Table from '../../../UI/Table/Table';

import ExportToExcelIcon from '../../../UI/Icons/ExportToExcelIcon';
import { compareByDesc } from '../../../../shared/sorting';
import TablaVentasDesagregadasMontoUnidad from './TablaVentasDesagregadasMontoUnidad';

const TablaVentasMontoUnidadPorSeleccion = (props) => {
  const { dataSelectedId, dataOptions, dataSelectOptions, fechaVentasDelMes, vendedorId,
    loading, ventas, title } = props;
  const tableRef = useRef();

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  const columns = [
    {
      title: title,
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '0px',
        paddingTop: '0px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      }
    },
    {
      title: `Porc. participación (Monto)`,
      field: 'porcParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '0px',
          paddingTop: '0px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
    },
    {
      title: `Monto`,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '0px',
          paddingTop: '0px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${storageMonedaSigno()} ${getNumberWithDecimals(rowData.monto)}`
    },
    {
      title: `Porc. participación (Unid.)`,
      field: 'porcParticipacionUnidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '0px',
          paddingTop: '0px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacionUnidades)}%`
    },
    {
      title: `Unidades`,
      field: 'unidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '0px',
          paddingTop: '0px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumberWithoutDecimals(rowData.unidades)}`
    },
  ];

  const data = () => {
    return ventas.sort((a, b) => compareByDesc(a, b, 'porcentajeDeParticipacion')).map((venta) => {
      return {
        id: venta.id,
        mesDesde: venta.mes,
        anioDesde: venta.anio,
        mesHasta: venta.mes,
        anioHasta: venta.anio,
        nombre: venta.nombre,
        porcParticipacion: venta.porcentajeDeParticipacion,
        monto: venta.monto,
        unidades: venta.unidadesVendidas,
        porcParticipacionUnidades: venta.porcentajeDeParticipacionUnidades,
      };
    });
  };

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  const getCurrentLevel = () => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    return item;
  }

  const getNextLevel = () => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    return item ? item.subNivel : undefined;
  }

  //#region Export to Excel
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var formatData = [];

    ventas && ventas.map((venta) => {
      return formatData.push([
        {
          value: venta.nombre,
          style: {
            font: { sz: '10', bold: false },
          }
        },
        {
          value: `${formatNumber(venta.porcentajeDeParticipacion)} %`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacion, false)
        },
        {
          value: `${storageMonedaSigno()} ${getNumberWithDecimals(venta.monto)}`,
          style: cellsStyleVerifNegative(venta.monto, false)
        },
        {
          value: `${formatNumber(venta.porcentajeDeParticipacionUnidades)} %`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacionUnidades, false)
        },
        {
          value: `${formatNumberWithoutDecimals(venta.unidadesVendidas)}`,
          style: cellsStyleVerifNegative(venta.unidadesVendidas, false)
        }
      ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: title,
          width: { wch: 60 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        },
        {
          title: `Porc. participación (Monto)`,
          width: { wpx: 150 },
          style: headersStyle
        },
        {
          title: `Monto`,
          width: { wpx: 150 },
          style: headersStyle
        },
        {
          title: `Porc. participación (Unid.)`,
          width: { wpx: 150 },
          style: headersStyle
        },
        {
          title: `Unidades`,
          width: { wpx: 150 },
          style: headersStyle
        }
      ],
      data: formatData()
    }
  ];
  //#endregion

  return (
    <Grid container spacing={1} className={classes.TablaVentasMontosUnidad}>
      <Grid item xs={12} container spacing={1} justifyContent="flex-end"
        className={classes.TablaVentasMontosActions}>
        <Grid item xs={3} md={2} style={{ textAlign: 'end' }}>
          {!loading && (
            <ExportToExcelIcon
              filename={`Analisis de ${title.toLowerCase()}`}
              name={title}
              dataSet={multiDataSet}
            />
          )}
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => props.onShowFiltersModal(dataOptions[dataSelectedId].title)}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.TablaVentasMontosUnidadTable}>
        <Table
          tableRef={tableRef}
          title=""
          loading={loading}
          columns={columns}
          data={data()}
          detailPanel={[
            {
              disabled: getNextLevel() === '',
              render: (rowData) => {
                return (
                  <div className={classes.TablaVentasDesagregadasMontoUnidad}>
                    <div style={{ width: '100%' }}>
                      <TablaVentasDesagregadasMontoUnidad
                        endRequest={() => autoScroll(rowData)}
                        dataSelectedId={dataSelectedId}
                        dataOptions={dataOptions}
                        dataSelectOptions={dataSelectOptions}
                        vendedorId={vendedorId}
                        fechaVentasDelMes={fechaVentasDelMes}
                        itemExpanded={rowData}
                        incluirUnidades={true}
                        withoutDecimals={props.withoutDecimals}
                        currentLevel={getCurrentLevel()}
                        nextLevel={getNextLevel()}
                        filtrosParent={[]}
                      />
                    </div>
                  </div>
                );
              }
            }
          ]}
          noBoxShadow
          customOptions={{
            thirdSortClick: false,
            sorting: true,
            grouping: false,
            draggable: false,
            search: false,
            paging: false,
            toolbar: false,
            showTitle: false,
            maxBodyHeight: 450,
            rowStyle: (rowData, index) => ({
              color: rowData.tableData.monto < 0 && 'red',
              backgroundColor: !isEven(index) && '#f2f2f2'
            })
          }}
          tableKey={"tabla-ventas-monto-unidad-seleccion"}
        />
      </Grid>
    </Grid>
  );
};

export default TablaVentasMontoUnidadPorSeleccion;