import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import { enqueueSnackbar } from './snackbar';
import { fechaActualFunc } from '../../shared/fechas';
import axiosConfig from 'axios';
import { storageEmpresaId, storageMonedaId, storageToken } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';
import { baseUrlDashboard } from '../../shared/urlApps';

export const loadPieGastosPorCategoria = (mes = 0, anio = 0, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadPieGastosPorCategoriaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/GastosPorCategoriaPorMes?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.gastosPorCategoria
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const gastos = response.data
          .filter((rd) => rd.id_CategoriaItem.trim() !== '' && rd.monto >= 0)
          .map((gasto) => gasto.monto);
        const gastosNegativos = response.data.filter((rd) => rd.monto < 0);
        const categorias = response.data
          .filter((rd) => rd.id_CategoriaItem.trim() !== '' && rd.monto >= 0)
          .map((gasto) => gasto.categoria.trim());
        const dataTable = response.data.filter((rd) => rd.id_CategoriaItem.trim() !== '');

        let totalGastos = 0;
        response.data.map((gasto) => (totalGastos += gasto.monto));
        let fecha = fechaActualFunc();
        if (mes !== 0) {
          fecha =
            response.data.length > 0
              ? new Date(response.data[0].anio, response.data[0].mes - 1)
              : new Date(anio, mes - 1);
        }
        dispatch(loadPieGastosPorCategoriaSuccess(gastos, gastosNegativos, categorias, dataTable, fecha, totalGastos));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadPieGastosPorCategoriaFail(error));
          } else {
            dispatch(loadPieGastosPorCategoria(mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadPieGastosPorCategoriaFail(error));
        }
      });
  };
};

export const clearPieGastosPorCategoria = () => {
  return {
    type: actionTypes.CLEAR_PIE_GASTOS_POR_CATEGORIA
  };
};

const loadPieGastosPorCategoriaStart = () => {
  return {
    type: actionTypes.PIE_GASTOS_POR_CATEGORIA_START
  };
};

const loadPieGastosPorCategoriaSuccess = (gastos, gastosNegativos, categorias, dataTable, fecha, totalGastos) => {
  return {
    type: actionTypes.PIE_GASTOS_POR_CATEGORIA_SUCCESS,
    gastos,
    gastosNegativos,
    categorias,
    gastosPorCategoriaDataTable: dataTable,
    fecha,
    totalGastos
  };
};

const loadPieGastosPorCategoriaFail = (error) => {
  return {
    type: actionTypes.PIE_GASTOS_POR_CATEGORIA_FAIL,
    error: error
  };
};

export const loadPieGastosPorCategoriaSinDataTable = (
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId(),
  returnData,
  success
) => {
  return (dispatch) => {
    if (!returnData) dispatch(loadPieGastosPorCategoriaSinDataTableStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/GastosPorCategoriaPorMes?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.gastosPorCategoria
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const gastos = response.data
          .filter((rd) => rd.id_CategoriaItem.trim() !== '' && rd.monto >= 0)
          .map((gasto) => gasto.monto);
        const gastosNegativos = response.data.filter((rd) => rd.monto < 0);
        const categorias = response.data
          .filter((rd) => rd.id_CategoriaItem.trim() !== '' && rd.monto >= 0)
          .map((gasto) => gasto.categoria.trim());
        let totalGastos = 0;
        response.data.map((gasto) => (totalGastos += gasto.monto));
        let fecha = fechaActualFunc();

        if (!returnData) {
          dispatch(
            loadPieGastosPorCategoriaSinDataTableSuccess(gastos, categorias, fecha, totalGastos, gastosNegativos)
          );
        } else {
          return success(response.data, gastos, categorias, fecha, totalGastos, gastosNegativos);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadPieGastosPorCategoriaSinDataTableFail(error));
          } else {
            dispatch(loadPieGastosPorCategoriaSinDataTable(mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadPieGastosPorCategoriaSinDataTableFail(error));
        }
      });
  };
};

export const clearPieGastosPorCategoriaSinDataTable = () => {
  return {
    type: actionTypes.CLEAR_PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE
  };
};

const loadPieGastosPorCategoriaSinDataTableStart = () => {
  return {
    type: actionTypes.PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_START
  };
};
const loadPieGastosPorCategoriaSinDataTableSuccess = (gastos, categorias, fecha, total, gastosNegativos) => {
  return {
    type: actionTypes.PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_SUCCESS,
    gastos,
    gastosNegativos,
    categorias,
    fecha,
    total
  };
};

const loadPieGastosPorCategoriaSinDataTableFail = (error) => {
  return {
    type: actionTypes.PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_FAIL,
    error: error
  };
};

export const loadTextoGastosDelMes = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoGastosDelMesStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/GastosDelMesComparativo?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.gastosDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadTextoGastosDelMesSuccess(response.data.gastosMesActual, response.data.gastosMesAnterior));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoGastosDelMesFail(error));
          } else {
            dispatch(loadTextoGastosDelMes(idEmpresa));
          }
        } else {
          dispatch(loadTextoGastosDelMesFail(error));
        }
      });
  };
};

export const clearTextoGastosDelMes = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_GASTOS_DEL_MES
  };
};

const loadTextoGastosDelMesStart = () => {
  return {
    type: actionTypes.TEXTO_GASTOS_DEL_MES_START
  };
};

const loadTextoGastosDelMesSuccess = (mesActual, mesAnterior) => {
  return {
    type: actionTypes.TEXTO_GASTOS_DEL_MES_SUCCESS,
    mesActual: mesActual,
    mesAnterior: mesAnterior
  };
};

const loadTextoGastosDelMesFail = (error) => {
  return {
    type: actionTypes.TEXTO_GASTOS_DEL_MES_FAIL,
    error: error
  };
};

export const loadEvolucionGastosMensual = (mes = 0, anio = 0, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionGastosMensualStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/EvolucionGastosMensualTabla?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionGastosMesual
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatGastosParaTabla = [];
        let totalGastos = 0;
        response.data.map((gasto) => {
          if (!formatGastosParaTabla.hasOwnProperty(gasto.anio)) {
            formatGastosParaTabla[gasto.anio] = {
              anio: gasto.anio,
              meses: []
            };
          }
          formatGastosParaTabla[gasto.anio].meses.push({
            mes: gasto.mes,
            monto: gasto.monto
          });
          return (totalGastos += gasto.monto);
        });
        dispatch(loadEvolucionGastosMensualSuccess(response.data, formatGastosParaTabla, totalGastos));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionGastosMensualFail(error));
          } else {
            dispatch(loadEvolucionGastosMensual(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionGastosMensualFail(error));
        }
      });
  };
};

export const clearEvolucionGastosMensual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_GASTOS_MENSUAL
  };
};

const loadEvolucionGastosMensualStart = () => {
  return {
    type: actionTypes.EVOLUCION_GASTOS_MENSUAL_START
  };
};

const loadEvolucionGastosMensualSuccess = (evolucionGastosMensual, evolucionGastosMensualDataTable, totalGastos) => {
  return {
    type: actionTypes.EVOLUCION_GASTOS_MENSUAL_SUCCESS,
    evolucionGastosMensual,
    evolucionGastosMensualDataTable,
    totalGastos
  };
};

const loadEvolucionGastosMensualFail = (error) => {
  return {
    type: actionTypes.EVOLUCION_GASTOS_MENSUAL_FAIL,
    error
  };
};

export const loadEvolucionGastosMensualPorCategoria = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionGastosMensualPorCategoriaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/gastos/EvolucionGastosPorCategoriaDelMes?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        let total = 0;
        dispatch(loadEvolucionGastosMensualPorCategoriaSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionGastosMensualPorCategoriaError(error));
          } else {
            dispatch(loadEvolucionGastosMensualPorCategoria(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionGastosMensualPorCategoriaError(error));
        }
      });
  };
};

const loadEvolucionGastosMensualPorCategoriaStart = () => {
  return {
    type: actionTypes.EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_START
  };
};

const loadEvolucionGastosMensualPorCategoriaSuccess = (evolucionGastosMensualPorCategoria, total) => {
  return {
    type: actionTypes.EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_SUCCESS,
    evolucionGastosMensualPorCategoria,
    total
  };
};

const loadEvolucionGastosMensualPorCategoriaError = (error) => {
  return {
    type: actionTypes.EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_ERROR,
    error
  };
};

export const loadGastosPorItemDeCategoriaSelec = (
  idCategoria,
  nombreCategoria,
  mes,
  anio,
  idEmpresa = storageEmpresaId(),
  returnValue,
  success
) => {
  return (dispatch) => {
    !returnValue && dispatch(loadGastosPorItemDeCategoriaSelecStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/GastosPorItemDeLaCategoriaDelMes?idCategoria=${idCategoria}&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.GastosPorItemDeCategoriaDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const fecha = `${mes}/${anio}`;
        const gastosPorItem = response.data.length > 0 ? response.data : [];
        if (!returnValue) {
          dispatch(loadGastosPorItemDeCategoriaSelecSuccess(gastosPorItem, nombreCategoria, fecha));
        } else {
          return success(response.data, gastosPorItem, nombreCategoria, fecha);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadGastosPorItemDeCategoriaSelecError(error));
          } else {
            dispatch(loadGastosPorItemDeCategoriaSelec(idCategoria, nombreCategoria, mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadGastosPorItemDeCategoriaSelecError(error));
        }
      });
  };
};

const loadGastosPorItemDeCategoriaSelecStart = () => {
  return {
    type: actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_START
  };
};

const loadGastosPorItemDeCategoriaSelecSuccess = (gastosPorItemDeCategoria, nombreCategoria, fecha) => {
  return {
    type: actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_SUCCESS,
    gastosPorItemDeCategoria,
    nombreCategoria,
    fecha
  };
};

const loadGastosPorItemDeCategoriaSelecError = (error) => {
  return {
    type: actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_ERROR,
    error
  };
};

export const unmountGastosPorItemDeCategoriaSelec = () => {
  return {
    type: actionTypes.GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_SUCCESS,
    gastosPorItemDeCategoria: [],
    fecha: ''
  };
};

export const loadComprobantesGastos = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesGastosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `${baseUrlDashboard()}gastos/ComprobantesGastos?idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprobantesGastos
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesGastosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesGastosFail(error));
          } else {
            dispatch(loadComprobantesGastos(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesGastosFail(error));
        }
      });
  };
};

const loadComprobantesGastosStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_GASTOS_START
  };
};

const loadComprobantesGastosSuccess = (comprobantesGastos) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_GASTOS_SUCCESS,
    comprobantesGastos
  };
};

const loadComprobantesGastosFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_GASTOS_FAIL,
    error
  };
};

export const postComprobantesGastos = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantesGastos(true, false));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`${baseUrlDashboard()}gastos/ComprobantesGastos?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Comprobantes actualizados correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        )
        dispatch(executePostComprobantesGastos(false, true));
      })
      .catch((error) => {
        dispatch(executePostComprobantesGastos(false, false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostComprobantesGastos = (loading, modificado) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
    modificado
  };
};

export const loadEvolucionGastosPorCategoria = (mes = 0, anio = 0, idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionGastosPorCategoriaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/EvolucionGastosPorCategoria?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionGastosPorCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((gastoPorCateg, index) =>
          gastoPorCateg.montosMesAnio.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionGastosPorCategoriaSuccess(response.data, fechas, total));
        return success(response.data, fechas);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionGastosPorCategoriaFail(error));
          } else {
            dispatch(loadEvolucionGastosPorCategoria());
          }
        } else {
          dispatch(loadEvolucionGastosPorCategoriaFail(error));
        }
      });
  };
};

export const clearEvolucionGastosPorCategoria = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_GASTOS_POR_CATEGORIA
  };
};

const loadEvolucionGastosPorCategoriaStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_START
  };
};

const loadEvolucionGastosPorCategoriaSuccess = (gastos, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_SUCCESS,
    gastos,
    fechas,
    total
  };
};

const loadEvolucionGastosPorCategoriaFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_FAIL,
    error
  };
};

export const loadDetalleGastosDelItemDeCompra = (
  idItem,
  mes,
  anio,
  idEmpresa = sessionStorage.getItem('empresaId'),
  returnData,
  success
) => {
  return (dispatch) => {
    !returnData && dispatch(loadDetalleGastosDelItemDeCompraStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/DetalleGastosDelItemDeCompra?idItem=${idItem}&mes=${mes}&anio=${anio}&idMoneda=${sessionStorage.getItem(
          'monedaId'
        )}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.detalleGastosDelItemDeCompra}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (returnData) {
          return success(response.data);
        } else {
          dispatch(loadDetallesGastosDelItemDeCompraSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadDetallesGastosDelItemDeCompraFail(error));
          } else {
            dispatch(loadDetalleGastosDelItemDeCompra());
          }
        } else {
          dispatch(loadDetallesGastosDelItemDeCompraFail(error));
        }
      });
  };
};

export const clearDetalleGastosDelItemDeCompra = () => {
  return {
    type: actionTypes.CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA
  };
};

const loadDetalleGastosDelItemDeCompraStart = () => {
  return {
    type: actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START
  };
};

const loadDetallesGastosDelItemDeCompraSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS,
    comprobantes
  };
};

const loadDetallesGastosDelItemDeCompraFail = (error) => {
  return {
    type: actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL,
    error
  };
};

export const loadDetalleComprobanteGasto = (idSucursal, idSistema, idComproba, nroReferencia, success, errorFail) => {
  return (dispatch) => {
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/DetalleComprobanteGastos?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&idMoneda=${idMoneda}
        &idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasAlCliente}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

//#region Ventas Interanual

export const loadEvolucionDeGastosMensualInteranual = (
  mes,
  anio,
  onSuccess,
  onFail,
  idEmpresa = storageEmpresaId()
) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeGastosMensualInteranualStart(anio));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/EvolucionGastosMensualTabla?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionGastosMesual
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatGastosParaTabla = [];
        let totalGastos = 0;
        response.data.map((gasto) => {
          if (!formatGastosParaTabla.hasOwnProperty(gasto.anio)) {
            formatGastosParaTabla[gasto.anio] = {
              anio: gasto.anio,
              meses: []
            };
          }
          formatGastosParaTabla[gasto.anio].meses.push({
            mes: gasto.mes,
            monto: gasto.monto
          });
          return (totalGastos += gasto.monto);
        });
        dispatch(
          loadEvolucionDeGastosMensualInteranualSuccess(anio, response.data, formatGastosParaTabla, totalGastos)
        );
        onSuccess(response.data, formatGastosParaTabla, totalGastos);
      })
      .catch((error) => {
        onFail();
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeGastosMensualInteranualFail(anio, error));
          } else {
            dispatch(loadEvolucionDeGastosMensualInteranual(mes, anio, onSuccess, onFail, idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeGastosMensualInteranualFail(anio, error));
        }
      });
  };
};

export const agregarEvolucionDeGastosMensualInteranual = (periodo) => {
  return {
    type: actionTypes.AGREGAR_EVOLUCION_DE_GASTOS_INTERANUAL,
    periodo
  };
};

export const clearEvolucionDeGastosMensualInteranual = (periodo) => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_GASTOS_INTERANUAL,
    periodo
  };
};

export const clearEvolucionesDeGastosMensualInteranual = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCIONES_DE_GASTOS_INTERANUAL
  };
};

const loadEvolucionDeGastosMensualInteranualStart = (anio) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_START,
    anio
  };
};

const loadEvolucionDeGastosMensualInteranualSuccess = (anio, ventas, ventasDataTable, totalVentas) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_SUCCESS,
    anio,
    ventas,
    ventasDataTable,
    totalVentas
  };
};

const loadEvolucionDeGastosMensualInteranualFail = (anio, error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_FAIL,
    anio,
    error
  };
};

export const seleccionFechaDeGastosMensualInteranual = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_DE_GASTOS_INTERANUAL,
    mes,
    anio
  };
};

//#endregion
