import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { fechaActualFunc } from '../../shared/fechas';
import InteranualVentasConFiltro from '../../components/Indicadores/Ventas/Interanual/InteranualVentasConFiltro';
import LineEvolucionVentasPorPeriodo from '../../components/Indicadores/Ventas/LineEvolucionVentasPorPeriodo/LineEvolucionVentasPorPeriodo';
import PieTableVentasMontoUnidadPorSeleccion from '../../components/Indicadores/Ventas/PieTableVentasMontoUnidadPorSeleccion/PieTableVentasMontoUnidadPorSeleccion';

const useStyles = makeStyles((theme) => ({
  lineEvolucionVentas: {
    display: 'none',
    maxWidth: '95vw',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));

const PanelVentas = (props) => {
  const classes = useStyles();
  const { periodos, ventas, agregarPeriodoVenta, onLoadTipoVentas } = props;
  const inPhone = window.screen.width <= 750;
  const [fechaFija] = useState(fechaActualFunc());

  useEffect(() => {
    if (periodos.length === 0) agregarPeriodoVenta(fechaFija);
  }, [periodos, fechaFija, agregarPeriodoVenta]);

  useEffect(() => {
    onLoadTipoVentas();
  }, [onLoadTipoVentas]);

  const renderItems = props.ejecucionDePagina && ventas && ventas.length !== 0 && ventas[0].estaCargado;

  const dataOptions = {
    marca: {
      id: 0,
      stringId: 'marca',
      description: 'Marcas',
      ventasKey: 'ventasPorMarca',
      loadEvolucionKey: 'onLoadEvolucionVentasPorMarca',
      loadKey: 'onLoadVentasDelMesPorMarca',
      loadDetallesKey: 'onLoadDetallesaMarca',
      nextId: 'rubro'
    },
    rubro: {
      id: 1,
      stringId: 'rubro',
      description: 'Rubros',
      ventasKey: 'ventasPorRubro',
      loadEvolucionKey: 'onLoadEvolucionVentasPorRubro',
      loadKey: 'onLoadVentasDelMesPorRubro',
      loadDetallesKey: 'onLoadDetallesRubro',
      nextId: 'articulo'
    },
    subrubro: {
      id: 2,
      stringId: 'subrubro',
      description: 'Subrubros',
      ventasKey: 'ventasPorSubrubro',
      loadEvolucionKey: 'onLoadEvolucionVentasPorSubrubro',
      loadKey: 'onLoadVentasDelMesPorSubrubro',
      loadDetallesKey: 'onLoadDetallesSubrubro',
      nextId: 'articulo'
    },
    articulo: {
      id: 3,
      stringId: 'articulo',
      description: 'Artículos',
      ventasKey: 'ventasPorArticulo',
      loadEvolucionKey: 'onLoadEvolucionVentasPorArticulo',
      loadKey: 'onLoadVentasDelMesPorArticulo',
      loadDetallesKey: '',
      nextId: ''
    },
    cliente: {
      id: 4,
      stringId: 'cliente',
      description: 'Clientes',
      ventasKey: 'ventasPorCliente',
      loadEvolucionKey: 'onLoadEvolucionVentasPorCliente',
      loadKey: 'onLoadVentasDelMesPorCliente',
      loadDetallesKey: '',
      nextId: ''
    },
    provincia: {
      id: 5,
      stringId: 'provincia',
      description: 'Provincias',
      ventasKey: 'ventasPorProvincia',
      loadEvolucionKey: 'onLoadEvolucionVentasPorProvincia',
      loadKey: 'onLoadVentasDelMesPorProvincia',
      loadDetallesKey: 'onLoadDetallesProvincia',
      nextId: 'responsable'
    },
    vendedor: {
      id: 6,
      stringId: 'vendedor',
      description: 'Vendedores',
      ventasKey: 'ventasPorVendedor',
      loadEvolucionKey: 'onLoadEvolucionVentasPorVendedor',
      loadKey: 'onLoadVentasDelMesPorVendedor',
      loadDetallesKey: 'onLoadDetallesVendedor',
      nextId: 'cliente'
    },
    zona: {
      id: 7,
      stringId: 'zona',
      description: 'Zonas',
      ventasKey: 'ventasPorZona',
      loadEvolucionKey: 'onLoadEvolucionVentasPorZona',
      loadKey: 'onLoadVentasDelMesPorZona',
      loadDetallesKey: 'onLoadDetallesZona',
      nextId: 'responsable'
    },
    categoriacliente: {
      id: 7,
      stringId: 'categoriaCliente',
      description: 'Categoria de Clientes',
      ventasKey: 'ventasPorCategoriaCliente',
      loadEvolucionKey: 'onLoadEvolucionVentasPorCategoriaCliente',
      loadKey: 'onLoadVentasDelMesPorCategoriaCliente',
      loadDetallesKey: 'onLoadDetallesCategoriaCliente',
      nextId: 'cliente'
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
        <InteranualVentasConFiltro ejecucionDePagina={props.ejecucionDePagina} withoutDecimals={false} />
      </Grid>
      <Grid item xs={12} container
        style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
        <PieTableVentasMontoUnidadPorSeleccion
          ejecucionDePagina={renderItems}
          withoutDecimals={false}
          dataSelectOptions={props.dataSelectOptions}
          key={"pieTablaVentaMontoUnidadPorSeleccion"}
        />
      </Grid>
      <Grid item xs={12} className={classes.lineEvolucionVentas}>
        <LineEvolucionVentasPorPeriodo
          ejecucionDePagina={renderItems}
          withoutDecimals={false}
          dataOptions={dataOptions}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    periodos: state.ventas.evolucionVentasMensualInteranual.periodos,
    ventas: state.ventas.evolucionVentasMensualInteranual.items,
    dataSelectOptions: state.ventas.tipoVentasDesagregadas.tipos
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    agregarPeriodoVenta: (periodo) => dispatch(actions.agregarEvolucionDeVentasMensualInteranual(periodo)),
    onLoadTipoVentas: () => dispatch(actions.loadTipoDeVentasDesagregadas())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelVentas);
