import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import TableComprobantesVentasDelArticuloAlCliente from '../../Comprobantes/TableComprobantesVentas/TableComprobantesVentasDelArticuloAlCliente';
import classes from './TablaVentasDelArticuloPorCliente.css';

const TablaVentasDelArticuloPorCliente = (props) => {
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes;
  const anio = props.anio;

  useEffect(() => {
    setLoading(true);
    props.onLoadVentasDelArticuloPorCliente(
      props.vendedorId,
      props.articleId,
      mes,
      anio,
      (response) => {
        setVentasLocal(response);
        setLoading(false);
        props.endRequest && props.endRequest(response.length);
      },
      () => {
        setVentasLocal([]);
        setLoading(false);
        props.endRequest && props.endRequest();
      }
    );
  }, []);

  const columns = [
    {
      title: 'Cliente',
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220 220 220)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220 220 220)'
      },
      render: (rowData) => props.withoutDecimals ? `${formatNumberWithoutDecimals(rowData.porcentajeDeParticipacion)}%` : `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220 220 220)'
      },
      render: (rowData) => props.withoutDecimals ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(rowData.monto)}` : `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const getColumns = () => {
    let result = columns;

    if(props.incluirUnidades) {
      result.push(
        {
          title: ``,
          field: 'porcParticipacionUnidades',
          align: 'right',
          type: 'numeric',   
          cellStyle: (cellData) => {
            return {
              fontSize: '12px',
              paddingBottom: '5px',
              paddingTop: '5px',
              color: cellData < 0 && 'red'
            };
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
            backgroundColor: 'rgb(220 220 220)'
          },
          render: (rowData) => props.withoutDecimals ? `${formatNumberWithoutDecimals(rowData.porcParticipacionUnidades)}%` : `${formatNumber(rowData.porcParticipacionUnidades)}%`
        }
      );
      result.push(        
        {
          title: ``,
          field: 'unidades',
          align: 'right',
          type: 'numeric',  
          cellStyle: (cellData) => {
            return {
              fontSize: '12px',
              paddingBottom: '5px',
              paddingTop: '5px',
              color: cellData < 0 && 'red'
            };
          },
          headerStyle: {
            fontSize: '14px',
            paddingTop: '0px',
            paddingBottom: '0px',
            backgroundColor: 'rgb(220 220 220)'
          },
          render: (rowData) => `${formatNumberWithoutDecimals(rowData.unidades)}`
        }
      )
    }

    return result;
  }

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        unidades: venta.unidadesVendidas,
        porcParticipacionUnidades: venta.porcentajeDeParticipacionUnidades,
      };
    });
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.tablaVentasDelArticuloPorCliente}>
      <Table
        tableRef={tableRef}
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        title=""
        noBoxShadow
        columns={getColumns()}
        loading={loading}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return (
            <TableComprobantesVentasDelArticuloAlCliente
              customWidth={'90%'}
              successRequest={() => {
                autoScroll(rowData);
              }}
              vendedorId={props.vendedorId}
              clienteId={rowData.id}
              articleId={props.articleId}
              mes={mes}
              anio={anio}
            />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 200,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-ventas-articulo-cliente"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasDelArticuloPorCliente: (idVendedor, idArticulo, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelArticuloPorCliente(idVendedor, idArticulo, mes, anio, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDelArticuloPorCliente);
