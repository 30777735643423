// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelNoganet__PanelNoganetContainer__uXomT {\r\n    display: flex;\r\n    flex: 1 1;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .PanelNoganet__FlexHorizontal__0dTXR {\r\n    display: flex;\r\n    flex-flow: row;\r\n    margin-bottom: 10px;\r\n    /* border: 1px solid red; */\r\n  }\r\n  \r\n  .PanelNoganet__FlexVertical__U4niz {\r\n    display: flex;\r\n    flex: 1 1;\r\n    flex-flow: column;\r\n    /* border: 1px solid red; */\r\n  }\r\n  \r\n  .PanelNoganet__CombinedChartsCard__rROpI {\r\n    display: flex;\r\n    flex: 1 1;\r\n    flex-direction: row;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 3px;\r\n    background-color: white;\r\n    box-shadow: 0px 3px 6px #00000029;\r\n    margin-top: 5px !important;\r\n    padding: 4px;\r\n  }\r\n  \r\n  .PanelNoganet__IndicadorBarra__6bbew {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex: 1 1;\r\n    margin-right: 5px;\r\n  }\r\n  \r\n  .PanelNoganet__IndicadorTabla__DB3vf {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex: 1 1;\r\n    margin-left: 5px;\r\n  }\r\n  \r\n  .PanelNoganet__separador__9uzV5 {\r\n    width: 10px;\r\n  }\r\n\r\n  .PanelNoganet__TablaDeuda__qqCNb{\r\n    padding-left: 8px;\r\n    height: 100%; \r\n  }\r\n  \r\n  @media (max-width: 1367px) {\r\n    .PanelNoganet__IndicadorBarra__6bbew {\r\n      max-width: 682px;\r\n    }\r\n    .PanelNoganet__IndicadorTabla__DB3vf {\r\n      max-width: 682px;\r\n    }\r\n  }\r\n\r\n  @media(max-width: 720px){\r\n    .PanelNoganet__TablaDeuda__qqCNb{\r\n      padding-left: 0px;\r\n    }\r\n  }\r\n  ", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PanelNoganetContainer": "PanelNoganet__PanelNoganetContainer__uXomT",
	"FlexHorizontal": "PanelNoganet__FlexHorizontal__0dTXR",
	"FlexVertical": "PanelNoganet__FlexVertical__U4niz",
	"CombinedChartsCard": "PanelNoganet__CombinedChartsCard__rROpI",
	"IndicadorBarra": "PanelNoganet__IndicadorBarra__6bbew",
	"IndicadorTabla": "PanelNoganet__IndicadorTabla__DB3vf",
	"separador": "PanelNoganet__separador__9uzV5",
	"TablaDeuda": "PanelNoganet__TablaDeuda__qqCNb"
};
export default ___CSS_LOADER_EXPORT___;
