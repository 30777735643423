import React, { useEffect, useRef, useCallback } from 'react';
import * as actions from '../../../store/actions';
import { DataGrid, Column, Editing } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { connect } from 'react-redux';

const DetailChangeLogs = (props) => {
  const { changeLogs, onCreateChange, onUpdateChange, onDeleteChange } = props;

  const getRequest = useCallback(() => {
    const key = props.data.key;
    const formatChangeLogs = changeLogs
      .filter((changeLog) => changeLog.version === key)
      .map((changeLog) =>
        changeLog.changes.map((detailChange) => ({
          id: detailChange.id,
          change: detailChange.change,
          dateTime: detailChange.dateTime,
          linkDoc: detailChange.linkDoc,
          version: detailChange.version
        }))
      );

    return new DataSource({
      store: new ArrayStore({
        data: formatChangeLogs[0],
        key: 'id'
      }),
      filter: ['version', '=', key]
    });
  }, [props.data.key, changeLogs]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const onSaveNewChange = (e) => {
    const tableRef = detailTableRef.current.props.dataSource._items[0];

    onCreateChange({
      dateTime: tableRef.dateTime,
      change: e.data.change,
      linkDoc: tableRef.linkDoc,
      version: props.data.key
    });
  };

  const onSaveUpdateChange = (e) => {
    onUpdateChange({
      id: e.data.id,
      dateTime: e.data.dateTime,
      change: e.data.change,
      linkDoc: e.data.linkDoc,
      version: props.data.key
    });
  };

  const onSaveDeleteChange = (e) => {
    onDeleteChange(e.data.id);
  };

  const detailTableRef = useRef();

  return (
    <React.Fragment>
      <DataGrid
        ref={detailTableRef}
        onRowInserting={(e) => onSaveNewChange(e)}
        dataSource={getRequest(props.data.key)}
        showBorders={true}
        columnAutoWidth={true}
        onRowUpdated={(e) => onSaveUpdateChange(e)}
        onRowRemoved={(e) => onSaveDeleteChange(e)}
        loadPanel={{ enabled: true }}
      >
        <Editing mode="row" allowUpdating={true} allowDeleting={true} allowAdding={true} />
        <Column dataField="change" caption="Cambios" />
      </DataGrid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    changeLogs: state.changeLogs.loadChangeLogs.changeLogs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateChange: (newChange) => dispatch(actions.createChangeLog(newChange)),
    onUpdateChange: (changeUpdate) => dispatch(actions.updateChangeLog(changeUpdate)),
    onDeleteChange: (changeLogId) => dispatch(actions.deleteChangeLog(changeLogId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailChangeLogs);
