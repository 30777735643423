// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaComparacionVentasDelClientePorArticulo__SorteableHeaderTitle__b1Y9H:hover {\r\n  color: rgba(0, 0, 0, 0.54);\r\n  cursor: pointer;\r\n}\r\n\r\n.TablaComparacionVentasDelClientePorArticulo__TablasComparacionComprobantesContainer__t9-rq {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 95%;\r\n  float: right;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .TablaComparacionVentasDelClientePorArticulo__TablasComparacionComprobantesContainer__t9-rq {\r\n    width: 100%;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SorteableHeaderTitle": "TablaComparacionVentasDelClientePorArticulo__SorteableHeaderTitle__b1Y9H",
	"TablasComparacionComprobantesContainer": "TablaComparacionVentasDelClientePorArticulo__TablasComparacionComprobantesContainer__t9-rq"
};
export default ___CSS_LOADER_EXPORT___;
