import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import idIndicadores from '../../shared/idIndicadores';
import { storageToken } from '../../shared/sessionData';

//#region Periodo
export const seleccionarPeriodo = (fechaDesde, fechaHasta) => {
  return {
    type: actionTypes.SELECCIONAR_PERIODO,
    fechaDesde: fechaDesde,
    fechaHasta: fechaHasta
  };
};
//#endregion

//#region Vendedores
export const seleccionarVendedor = (vendedor) => {
  return {
    type: actionTypes.SELECCIONAR_VENDEDOR,
    vendedor: vendedor
  };
};

export const loadVendedores = (success) => {
  return (dispatch) => {
    dispatch(loadVendedoresStart());
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    const idEmpresa = parseInt(sessionStorage.getItem('empresaId'), 10);

    axios
      .get(`/vendedores/vendedoresDeLaEmpresa?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.vendedores}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadVendedoresSuccess(response.data));
        return success(response);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVendedoresFail(error));
          } else {
            dispatch(loadVendedores());
          }
        } else {
          dispatch(loadVendedoresFail(error));
        }
      });
  };
};

const loadVendedoresStart = () => {
  return {
    type: actionTypes.LOAD_VENDEDORES_START
  };
};

const loadVendedoresSuccess = (vendedores) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_SUCCESS,
    vendedores
  };
};

const loadVendedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_FAIL,
    error
  };
};
//#endregion

//#region Sucursales
export const seleccionarSucursalFiltro = (sucursal) => {
  return {
    type: actionTypes.SELECCIONAR_SUCURSAL,
    sucursal: sucursal
  };
};

export const loadSucursalesFiltro = (empresaId, incluirInactivas, success) => {
  return (dispatch) => {
    dispatch(loadSucursalesFiltroStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/sucursales/sucursales?empresaId=${empresaId}&incluirInactivas=${incluirInactivas}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadSucursalesFiltroSuccess(response.data));

        return success && success(response.data);
      })
      .catch((error) => {
        dispatch(loadSucursalesFiltroFail(error));
      });
  };
};

const loadSucursalesFiltroStart = () => {
  return {
    type: actionTypes.LOAD_SUCURSALES_FILTRO_START
  };
};

const loadSucursalesFiltroSuccess = (sucursales) => {
  return {
    type: actionTypes.LOAD_SUCURSALES_FILTRO_SUCCESS,
    sucursales: sucursales
  };
};

const loadSucursalesFiltroFail = (error) => {
  return {
    type: actionTypes.LOAD_SUCURSALES_FILTRO_FAIL,
    error
  };
};

export const clearSucursalesFiltro = () => {
  return {
    type: actionTypes.CLEAR_SUCURSALES_FILTRO,
  };
}
//#endregion
