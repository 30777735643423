import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton } from '@material-ui/core';
import FiltroFechaSelector from '../../FiltroFechaSelector/FiltroFechaSelector';
import { fechaActualFunc, fechaMinima } from '../../../../shared/fechas';
import { range } from '../../../../shared/formatNumber';
import { Close } from '@material-ui/icons';
import AddTablaInteranualButton from './AddTablaInteranualButton';
import { limiteInteranual } from '../../../../shared/sessionData';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1)
  },
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    '& .MuiInputBase-input': {
      fontSize: 'small'
    },
    '& .MuiInputLabel-root': {
      fontSize: 'small'
    }
  },
  options: {
    fontSize: 'small'
  },
  checkbox: {
    fontSize: 'x-small',
    '& .MuiFormControlLabel-label': {
      fontSize: 'x-small'
    }
  }
}));

const FiltroInteranual = (props) => {
  const classes = useStyles();
  const { loading, periodosVentas, filtroSeleccionado, onUpdateFiltro, onClearVentas, onAgregarClick, showFilter } = props;
  const [desde, setDesde] = useState(filtroSeleccionado && filtroSeleccionado.desde);
  const [hasta, setHasta] = useState(filtroSeleccionado && filtroSeleccionado.hasta);
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const isValid = desde && hasta;
  const [limite] = useState(limiteInteranual());

  const onPropertyChange = (value, onChange) => {
    onChange(value);
    setCambiosAplicados(false);
  };

  const onClick = () => {
    props.onAplicarFiltroStart();

    let anioDesde = desde.getFullYear();
    let anioHasta = hasta.getFullYear();

    if (desde.getMonth() > fechaActualFunc().getMonth()) anioDesde = desde.getFullYear() + 1;

    let anios = range(anioDesde, anioHasta);

    if (anios && anios.length > 0) {
      if (periodosVentas && periodosVentas.length > 0) {
        let tempAnios = [].concat(anios);
        periodosVentas.forEach((per) => {
          if(per.getFullYear() === fechaMinima().getFullYear())
          {
            props.clearPeriodo(per);  
          }
          else{
            let existPeriodo = tempAnios.find((anio) => per.getFullYear() === anio) !== undefined;
            if (!existPeriodo) anios.push(per.getFullYear());
            props.clearPeriodo(per);
          }
        });
        props.clearDataPeriodos();
      }

      anios = anios.sort((a, b) => b - a);

      anios.forEach((anio) => {
        props.agregarPeriodoVenta(new Date(anio, fechaActualFunc().getMonth(), 1));
      });
    }

    onUpdateFiltro(desde, hasta);
    setCambiosAplicados(true);
    onClearVentas();
    setTimeout(() => {
      props.onAplicarFiltroEnd();
    }, 500);
  };

  const isValueValid = (item) => {
    if (item === null || item === undefined || (item && item.length === 0)) {
      return false;
    } else {
      if (typeof item === 'string' || item instanceof String) {
        const itemTrim = item.trim();

        if (itemTrim === '') {
          return false;
        }
      }
    }

    return true;
  };

  const onClearFiltro = () => {
    setDesde(new Date(fechaActualFunc().getFullYear() - 1, fechaActualFunc().getMonth() + 1, 1));
    setHasta(new Date(fechaActualFunc().getFullYear(), fechaActualFunc().getMonth(), 1));
    setCambiosAplicados(true);
    props.onClearFiltro();
  };

  useEffect(() => {
    if (!desde && filtroSeleccionado && filtroSeleccionado.desde) setDesde(filtroSeleccionado.desde);
  }, [filtroSeleccionado, desde]);

  useEffect(() => {
    if (!hasta && filtroSeleccionado && filtroSeleccionado.hasta) setHasta(filtroSeleccionado.hasta);
  }, [filtroSeleccionado, hasta]);

  useEffect(() => {
    if (!desde && (!filtroSeleccionado || !filtroSeleccionado.desde))
      setDesde(new Date(fechaActualFunc().getFullYear() - 1, fechaActualFunc().getMonth() + 1, 1));
  }, [filtroSeleccionado, desde]);

  useEffect(() => {
    if (!hasta && (!filtroSeleccionado || !filtroSeleccionado.hasta))
      setHasta(new Date(fechaActualFunc().getFullYear(), fechaActualFunc().getMonth(), 1));
  }, [filtroSeleccionado, hasta]);

  return (
    <Grid item xs={12} container spacing={2} alignContent="center" justifyContent="flex-start">
      {showFilter ? (
        <Fragment>
          <Grid item xs={5} lg={2}>
            <FiltroFechaSelector
              label="Desde"
              value={desde}
              minDate={new Date(1990, 0, 1)}
              maxDate={hasta ? hasta : fechaActualFunc()}
              onDateChange={(value) => onPropertyChange(value, setDesde)}
              format="MM/YYYY"
              views={['year', 'month']}
              disabled={loading}
              required={true}
              error={!isValueValid(desde)}
              helperText={isValueValid(desde) ? undefined : 'Campo requerido'}
            />
          </Grid>
          <Grid item xs={5} lg={2}>
            <FiltroFechaSelector
              label="Hasta"
              value={hasta}
              minDate={desde ? desde : new Date(1990, 0, 1)}
              maxDate={fechaActualFunc()}
              onDateChange={(value) => onPropertyChange(value, setHasta)}
              format="MM/YYYY"
              views={['year', 'month']}
              disabled={loading}
              required={true}
              error={!isValueValid(hasta)}
              helperText={isValueValid(hasta) ? undefined : 'Campo requerido'}
            />
          </Grid>
          <Grid item xs={5} lg={2} style={{ marginTop: '11px' }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                margin: '5px auto 5px auto',
                color: 'white',
                backgroundImage: isValid && !cambiosAplicados ? 'linear-gradient(45deg, #fbb040, #f15a29)' : undefined
              }}
              className={classes.button}
              size="small"
              onClick={onClick}
              disabled={loading || !isValid}
            >
              Aplicar
            </Button>
          </Grid>
        </Fragment>
      ): null
      }
      <Grid item xs={2} lg={3} style={{ marginTop: '11px' }}>
        <AddTablaInteranualButton onClick={() => onAgregarClick()} disabled={props.periodos.length >= limite} />
      </Grid>
      <Grid item xs={2} lg={3} style={{ marginTop: '15px' }}>
        {filtroSeleccionado && filtroSeleccionado.aplicado ? (
          <IconButton size="small" onClick={() => onClearFiltro()}>
            <Close fontSize="small" />
          </IconButton>
        ) : (
          <Fragment></Fragment>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    periodosVentas: state.ventas.evolucionVentasMensualInteranual.periodos,
    filtroSeleccionado: state.ventas.evolucionVentasMensualInteranual.filtroPeriodoSeleccionado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateFiltro: (desde, hasta) => {
      dispatch(actions.updateFiltroEvolucionesDeVentasMensualInteranual(desde, hasta));
      dispatch(actions.updateDataEvolucionVentasPorPeriodos(true));
      dispatch(actions.updateDataComparativasPorPeriodos(true));
    },
    onClearFiltro: () => {
      dispatch(actions.clearFiltroEvolucionesDeVentasMensualInteranual());
      dispatch(actions.clearVentasPorCategoriaDeCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorSubrubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
    },
    agregarPeriodoVenta: (periodo) => dispatch(actions.agregarEvolucionDeVentasMensualInteranual(periodo)),
    clearDataPeriodos: () => dispatch(actions.clearEvolucionesDeVentasMensualInteranual()),
    clearPeriodo: (periodo) => dispatch(actions.clearEvolucionDeVentasMensualInteranual(periodo)),

    onClearVentas: () => {
      dispatch(actions.clearVentasDesagregadasDelMesPorMarca());
      dispatch(actions.clearVentasDesagregadasDelMesPorRubro());
      dispatch(actions.clearVentasDesagregadasDelMesPorProvincia());
      dispatch(actions.clearVentasDesagregadasDelMesPorZona());
      dispatch(actions.clearVentasDesagregadasDelMesPorUnidadDeNegocio());
      dispatch(actions.clearVentasDesagregadasDelMesPorCanal());
      dispatch(actions.clearVentasDesagregadasDelMesPorGrupoDeCompras());
      dispatch(actions.clearVentasDesagregadasDelMesPorResponsable());
      dispatch(actions.clearVentasDesagregadasDelMesPorCliente());
      dispatch(actions.clearVentasDesagregadasDelMesPorArticulo());
      dispatch(actions.clearVentasDesagregadasDelMesPorVendedor());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroInteranual);
