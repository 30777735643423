import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaVentasDeLaSucursalPorArticuloDeLaSeleccion.css';
import TablaVentasDeLaSucursalPorClienteDelArticulo from '../TablaVentasDeLaSucursalPorClienteDelArticulo/TablaVentasDeLaSucursalPorClienteDelArticulo';

const TablaVentasDeLaSucursalPorArticuloDeLaSeleccion = (props) => {
  const { dataSelectedId, clientesPrimero } = props;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes ? props.mes : props.itemExpanded.mes;
  const anio = props.anio ? props.anio : props.itemExpanded.anio;

  const dataOptions = {
    marca: {
      title: 'Marca',
      onLoadKey: 'onLoadVentasPorArticulosDeLaMarca'
    },
    rubro: {
      title: 'Rubro',
      onLoadKey: 'onLoadVentasPorArticulosDelRubro'
    },
    subrubro: {
      title: 'Subrubro',
      onLoadKey: 'onLoadVentasPorArticulosDelSubrubro'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadVentasPorArticulosDelVendedor'
    }
  };

  useEffect(() => {
    setLoading(true);
    props[dataOptions[dataSelectedId].onLoadKey](mes, anio, props.sucursalId, props.itemExpanded.id, (response) => {
      setVentasLocal(response);
      setLoading(false);
      props.endRequest && props.endRequest(response.length);
    });
  }, []);

  const columns = [
    {
      title: 'Artículo',
      field: 'nombre',
      align: 'left',
      render: (rowData) => rowData.id.trim() + " - " + rowData.nombre.trim(),
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion
      };
    });
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.tablaVentasPorArticuloDeLaSeleccion}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        columns={columns}
        loading={loading}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return (
            <TablaVentasDeLaSucursalPorClienteDelArticulo
              customWidth={'90%'}
              sucursalId={props.sucursalId}
              articuloId={rowData.id}
              mes={mes}
              anio={anio}
              successRequest={() => autoScroll(rowData)}
            />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-ventas-sucursal-articulo-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorArticulosDeLaMarca: (mes, anio, sucursalId, marcaId, success, failError) =>
      dispatch(actions.loadVentasDeLaSucursalPorArticuloDeLaMarca(mes, anio, sucursalId, marcaId, success, failError)),

    onLoadVentasPorArticulosDelRubro: (mes, anio, sucursalId, rubroId, success, failError) =>
      dispatch(actions.loadVentasDeLaSucursalPorArticuloDelRubro(mes, anio, sucursalId, rubroId, success, failError)),

    onLoadVentasPorArticulosDelSubrubro: (mes, anio, sucursalId, subrubroId, success, failError) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorArticuloDelSubrubro(mes, anio, sucursalId, subrubroId, success, failError)
      ),

    onLoadVentasPorArticulosDelVendedor: (mes, anio, sucursalId, vendedorId, success, failError) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDelVendedor(mes, anio, sucursalId, vendedorId, success, failError)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDeLaSucursalPorArticuloDeLaSeleccion);
