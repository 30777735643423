import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import classes from './TablaUtilidadPorSeleccion.css';

import { formatNumber, isEven } from '../../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../../shared/sessionData';
import Table from '../../../../UI/Table/Table';
import TablaUtilidadPorClienteDeLaSeleccion from '../TablaUtilidadPorClienteDeLaSeleccion/TablaUtilidadPorClienteDeLaSeleccion';
import TablaUtilidadPorArticuloDeLaSeleccion from '../TablaUtilidadPorArticuloDeLaSeleccion/TablaUtilidadPorArticuloDeLaSeleccion';
import TableUtilidadesToExcel from '../TablesUtilidades/TableUtilidadesToExcel';

const TablaUtilidadPorSeleccion = (props) => {
  const { dataSelectedId, fechaUtilidadDelMes } = props;

  useEffect(() => {
    if (!props[dataOptions()[dataSelectedId].stateKey].estaCargado && props.ejecucionDePagina) {
      props[dataOptions()[dataSelectedId].onLoadKey](fechaUtilidadDelMes.mes, fechaUtilidadDelMes.anio);
    }
  }, [dataSelectedId, props.ejecucionDePagina]);

  const dataOptions = (item) => {
    return {
      categoriaDeCliente: {
        title: 'Categoría de cliente',
        stateKey: 'utilidadPorCategoriaDeCliente',
        onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
      },
      marca: {
        title: 'Marca',
        stateKey: 'utilidadPorMarca',
        onLoadKey: 'onLoadVentasPorMarca'
      },
      rubro: {
        title: 'Rubro',
        stateKey: 'utilidadPorRubro',
        onLoadKey: 'onLoadVentasPorRubro'
      },
      subrubro: {
        title: 'Subrubro',
        stateKey: 'utilidadPorSubrubro',
        onLoadKey: 'onLoadVentasPorSubrubro'
      },
      vendedor: {
        title: 'Vendedor',
        stateKey: 'utilidadPorVendedor',
        onLoadKey: 'onLoadVentasPorVendedor'
      },
      provincia: {
        title: 'Provincia',
        stateKey: 'utilidadPorProvincia',
        onLoadKey: 'onLoadVentasPorProvincia'
      },
      zona: {
        title: 'Zona',
        stateKey: 'utilidadPorZona',
        onLoadKey: 'onLoadVentasPorZona'
      }
    };
  };

  const columns = [
    {
      title: dataOptions()[dataSelectedId].title,
      field: 'name',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      }
    },
    {
      title: `Ventas`,
      field: 'venta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.venta)}`
    },
    {
      title: `Costos`,
      field: 'costo',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.costo)}`
    },
    {
      title: `Cant. vendida`,
      field: 'cantidadVendida',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => rowData.cantidadVendida
    },
    {
      title: `Porc. Útil. S/Costo`,
      field: 'utilidadPorcSegunCosto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunCosto)}%`
    },
    {
      title: `Porc. Útil. S/Venta`,
      field: 'utilidadPorcSegunVenta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunVenta)}%`
    },
    {
      title: `Porc. Participación`,
      field: 'porcUtilidadSobreElTotal',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.porcUtilidadSobreElTotal)}%`
    },
    {
      title: `Utilidad`,
      field: 'utilidad',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.utilidad)}`
    }
  ];

  const data = () => {
    return props[dataOptions()[dataSelectedId].stateKey].utilidades.map((utilidad) => {
      return {
        id: utilidad.id,
        mes: utilidad.mes,
        anio: utilidad.anio,
        name: utilidad.nombre,
        utilidad: utilidad.monto,
        costo: utilidad.costo,
        venta: utilidad.ventas,
        cantidadVendida: utilidad.unidadesVendidas,
        utilidadPorcSegunCosto: utilidad.utilidadPorcentualSegunCosto,
        utilidadPorcSegunVenta: utilidad.utilidadPorcentualSegunVentas,
        porcUtilidadSobreElTotal: utilidad.porcentajeUtilidadSobreElTotal
      };
    });
  };

  const inCategoriaDeCliente = dataSelectedId === 'categoriaDeCliente';
  const inZona = dataSelectedId === 'zona';
  const inProvincia = dataSelectedId === 'provincia';
  const inVendedor = dataSelectedId === 'vendedor';

  const verPorClientes = inCategoriaDeCliente || inZona || inProvincia || inVendedor;

  const tableRef = useRef();

  return (
    <div className={classes.TablaUtilidadPorCategoriaDeClienteContainer}>
      <Table
        tableRef={tableRef}
        title=""
        loading={props[dataOptions()[dataSelectedId].stateKey].loading}
        columns={columns}
        data={data()}
        components={{
          Toolbar: () => {
            return (
              <div style={{ textAlign: 'right' }}>
                <TableUtilidadesToExcel
                  utilidades={props[dataOptions()[dataSelectedId].stateKey].utilidades}
                  utilidadPor={dataSelectedId}
                />
              </div>
            );
          }
        }}
        detailPanel={(rowData) => {
          return verPorClientes ? (
            <TablaUtilidadPorClienteDeLaSeleccion
              clientesPrimero={verPorClientes}
              dataSelectedId={dataSelectedId}
              itemExpanded={rowData}
            />
          ) : (
            <TablaUtilidadPorArticuloDeLaSeleccion
              clientesPrimero={verPorClientes}
              dataSelectedId={dataSelectedId}
              itemExpanded={rowData}
            />
          );
        }}
        noBoxShadow
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          showTitle: false,
          maxBodyHeight: 430,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-utilidad-por-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataUtilidadDelMesPorSelected,

    fechaUtilidadDelMes: state.ventas.fechaUtilidadDelMes,
    utilidadPorCategoriaDeCliente: state.ventas.utilidadDelMesPorCategoriaDeCliente,
    utilidadPorMarca: state.ventas.utilidadDelMesPorMarcas,
    utilidadPorProvincia: state.ventas.utilidadDelMesPorProvincias,
    utilidadPorRubro: state.ventas.utilidadDelMesPorRubros,
    utilidadPorSubrubro: state.ventas.utilidadDelMesPorSubrubros,
    utilidadPorVendedor: state.ventas.utilidadDelMesPorVendedores,
    utilidadPorZona: state.ventas.utilidadDelMesPorZonas
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaUtilidadPorSeleccion);
