import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';

const TextoVentasDiarias = (props) => {
  useEffect(() => {
    if ((!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina)) {
      props.onLoadChart(props.vendedorSeleccionado);
    }

    if (props.empresa) {
      props.onLoadChart(props.vendedorSeleccionado, props.empresa.id);
    }

    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.vendedorSeleccionado, props.ejecucionDePagina]);

  const isDisabledChart = props.estaOculto && !props.empresa;

  return (
    <TextChart
      title={`Ventas del día ${props.empresa ? `${props.empresa.nombre}` : ``}`}
      bigAmount={props.diaActual}
      bigAmountTooltip="Hoy"
      smallAmount={props.promedio30Dias}
      smallAmountTooltip={`Prom. últimos 30 dias`}
      loading={props.loading}
      disabled={isDisabledChart}
      porcentaje
      bigCurrency
      smallCurrency
      border={props.border && !props.error}
      imgSinDatos={props.error}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.ventasDiariasComparativo.loading,
    error: state.ventas.ventasDiariasComparativo.error,
    diaActual: state.ventas.ventasDiariasComparativo.diaActual,
    promedio30Dias: state.ventas.ventasDiariasComparativo.promedio30Dias,
    vendedorSeleccionado: state.filtros.vendedores.seleccionado,
    estaCargado: state.ventas.ventasDiariasComparativo.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDelDia
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (vendedor, idEmpresa) => dispatch(actions.loadVentasDiariasComparativo(vendedor, idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearVentasDiariasComparativo()),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas del día', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoVentasDiarias);
