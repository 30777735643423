import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import moment from 'moment';

import * as actions from '../../../../store/actions/index';

import LineChart from '../../../Charts/CustomLineChart';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './LineEvolucionVentasDelVendedorPorSeleccion.css';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import BoxStepInfo from '../../../UI/BoxStepInfo/BoxStepInfo';
import FilterListIcon from '@material-ui/icons/FilterList';
import BarComparacionPeriodosDeVentaDelVendedor from '../BarComparacionPeriodosDeVentaDelVendedor/BarComparacionPeriodosDeVentaDelVendedor';

const LineEvolucionVentasDelVendedorPorSeleccion = (props) => {
  const { dataSelectionId, ejecucionDePagina, vendedorSelected } = props;

  const [ventasSeleccionados, setVentasSeleccionados] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [seriesBandera, setSeriesBandera] = useState(false);
  const [banderaVentasComparacion] = useState(false);
  const [loadingComparacion, setLoadingComparacion] = useState(false);
  const [ventasComparar, setVentasComparar] = useState([]);
  const [formatVentasCompletos, setFormatVentasCompletos] = useState([]);
  const [inSelectRange, setInSelectRange] = useState(false);
  const [totalLocal, setTotalLocal] = useState(0);

  const dataOptions = {
    categoriaDeCliente: {
      id: 0,
      stringId: 'categoriaDeCliente',
      description: 'Categorías de cliente',
      ventasKey: 'ventasPorCategoria',
      loadEvolucionKey: 'onLoadEvolucionVentasPorCategoriaDeCliente',
      loadKey: 'onLoadVentasDelMesPorCategoriaDeCliente',
      loadDetallesKey: 'onLoadDetallesCategoriaDeCliente'
    },
    provincia: {
      id: 1,
      stringId: 'provincia',
      description: 'Provincias',
      ventasKey: 'ventasPorProvincias',
      loadEvolucionKey: 'onLoadEvolucionVentasPorProvincias',
      loadKey: 'onLoadVentasDelMesPorProvincias',
      loadDetallesKey: 'onLoadDetallesProvincia'
    },
    zona: {
      id: 2,
      stringId: 'zona',
      description: 'Zonas',
      ventasKey: 'ventasPorZonas',
      loadEvolucionKey: 'onLoadEvolucionVentasPorZonas',
      loadKey: 'onLoadVentasDelMesPorZonas',
      loadDetallesKey: 'onLoadDetallesZona'
    },
    marca: {
      id: 3,
      stringId: 'marca',
      description: 'Marcas',
      ventasKey: 'ventasPorMarca',
      loadEvolucionKey: 'onLoadEvolucionVentasPorMarca',
      loadKey: 'onLoadVentasDelMesPorMarca',
      loadDetallesKey: 'onLoadDetallesaMarca'
    },
    rubro: {
      id: 4,
      stringId: 'rubro',
      description: 'Rubros',
      ventasKey: 'ventasPorRubro',
      loadEvolucionKey: 'onLoadEvolucionVentasPorRubro',
      loadKey: 'onLoadVentasDelMesPorRubro',
      loadDetallesKey: 'onLoadDetallesRubro'
    },
    subrubro: {
      id: 5,
      stringId: 'subrubro',
      description: 'Subrubros',
      ventasKey: 'ventasPorSubrubro',
      loadEvolucionKey: 'onLoadEvolucionVentasPorSubrubro',
      loadKey: 'onLoadVentasDelMesPorSubrubro',
      loadDetallesKey: 'onLoadDetallesSubrubro'
    }
  };

  useEffect(() => {
    if (ejecucionDePagina && !props[dataOptions[dataSelectionId].ventasKey].estaCargado) {
      setVentasComparar([]);
      setTotalLocal(0);
      props[dataOptions[dataSelectionId].loadEvolucionKey](vendedorSelected.id_vendedor);
    }
  }, [ejecucionDePagina, dataSelectionId]);

  useEffect(() => {
    series();
    formatMesesToStrings();
  }, [dataSelectionId, props[dataOptions[dataSelectionId].ventasKey].ventas]);

  const series = () => {
    let formatVentas = [];
    const ventas = props[dataOptions[dataSelectionId].ventasKey].ventas;
    ventas.map((venta) => {
      return formatVentas.push({
        name: venta.nombre,
        data: []
      });
    });
    return setVentasSeleccionados(formatVentas);
  };

  const formatMesesToStrings = () => {
    const formatFechas = [];
    const fechasProp = props[dataOptions[dataSelectionId].ventasKey].fechas;
    fechasProp.map((fecha) => {
      return formatFechas.push(`${moment(new Date(fecha.anio, fecha.mes, 1)).subtract(1, 'month').format('MM/YY')}`);
    });
    setFechas(formatFechas);
  };

  const onLegendClick = (chartContext, seriesIndex, config) => {
    const ventasActual = ventasSeleccionados;
    if (ventasActual[seriesIndex].data.length !== 0) {
      ventasActual[seriesIndex].data = [];
    } else {
      const ventasKey = dataOptions[dataSelectionId].ventasKey;
      const montos = props[ventasKey].ventas[seriesIndex].montos.map((montoMesAnio) => montoMesAnio.monto);
      ventasActual[seriesIndex].data = montos;
    }
    setVentasSeleccionados(ventasActual);
    setSeriesBandera(!seriesBandera);
  };

  const armarTooltip = (series, seriesIndex, dataPointIndex, w) => {
    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${fechas[dataPointIndex]}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${ventasSeleccionados
        .filter((venta) => venta.data.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((venta) => {
          return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(venta.name)]};"
                    ></div>
                      <span class=${classes.Texto}>
                        ${venta.name}: ${storageMonedaSigno()} ${formatNumber(venta.data[dataPointIndex])} 
                      </span>
                    </div>
                `;
        })
        .join('')}
              </div>
      </div>
      `;
  };

  const verifyEqualDates = (date1, date2) => {
    const mesDateAnterior = date1.getMonth();
    const anioDateAnterior = date1.getFullYear();

    const mesDateActual = date2.getMonth();
    const anioDateActual = date2.getFullYear();

    return mesDateAnterior === mesDateActual && anioDateAnterior === anioDateActual;
  };

  const onMarkerClick = (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
    const ventasKey = dataOptions[dataSelectionId].ventasKey;
    const loadKey = dataOptions[dataSelectionId].loadKey;
    const mes = props[ventasKey].fechas[dataPointIndex].mes;
    const anio = props[ventasKey].fechas[dataPointIndex].anio;
    const dateComplete = new Date(anio, mes, 1);
    if (ventasComparar.length < 2) {
      if (ventasComparar.length === 1) {
        if (verifyEqualDates(ventasComparar[0].dateComplete, dateComplete)) {
          return;
        }
      }

      setLoadingComparacion(true);
      setTimeout(
        () =>
          window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth'
          }),
        500
      );

      props[loadKey](
        props.vendedorSelected.id_vendedor,
        mes,
        anio,
        (ventasCompleto) => {
          if (ventasComparar.length === 1) {
            var arrayNuevo = [];
            formatVentasCompletos[0].forEach((ventaCompleto) => {
              const verify = ventasCompleto.filter((venta) => venta.id === ventaCompleto.id);
              if (verify.length === 0) {
                arrayNuevo.push({ ...ventaCompleto, monto: 0, porcentajeDeParticipacion: 0 });
              }
            });

            ventasCompleto.map((venta) => {
              return arrayNuevo.push(venta);
            });
            ventasCompleto.forEach((ventaOrdenado) => {
              const verify = formatVentasCompletos[0].filter((venta) => venta.id === ventaOrdenado.id);

              if (verify.length === 0) {
                formatVentasCompletos[0].push({ ...ventaOrdenado, monto: 0, porcentajeDeParticipacion: 0 });
              }
            });

            var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
            var orderFormatVentasCompletos = formatVentasCompletos[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

            setVentasComparar([
              { dateComplete: ventasComparar[0].dateComplete, ventas: orderFormatVentasCompletos },
              { dateComplete: dateComplete, ventas: orderArrayNuevo }
            ]);
          } else {
            formatVentasCompletos.push(ventasCompleto);
            ventasComparar.push({ dateComplete: dateComplete, ventas: ventasCompleto });
          }
          setTimeout(() => setLoadingComparacion(false), 500);
          setTimeout(
            () =>
              window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: 'smooth'
              }),
            1050
          );
        },
        (error) => {
          setTimeout(() => setLoadingComparacion(false), 500);
        }
      );
    }
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onLimpiarComparacion = () => {
    setVentasComparar([]);
    setFormatVentasCompletos([]);
  };

  const verifyEmptyVentasSeleccioandos = ventasSeleccionados.filter((ventSelect) => ventSelect.data.length !== 0);

  const handleChangeSelectRange = (e) => {
    e.preventDefault();
    setInSelectRange(!inSelectRange);
  };

  const titles = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} l={12} container spacing={1} className={classes.Header}>
          <Grid item xs={8} lg={5} className={classes.Title}>
            <Typography className={classes.TitleText}>
              {`Ventas mensuales por ${dataOptions[dataSelectionId].description.toLowerCase()}`}
            </Typography>
          </Grid>

          <Grid item xs={11} lg={4} className={classes.SpecialTool} style={{ textAlign: 'center' }}>
            {middleControls()}
          </Grid>

          <Grid item xs={1} lg={3} className={classes.Filters}>
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onShowFiltersModal(`Ventas mensuales por ${dataOptions[dataSelectionId].description.toLowerCase()}`)}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Title}>
          <Typography className={classes.SubtitleText} >
            (Haga clic sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información)
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const middleControls = () => {
    return (
      <Tooltip
        title={
          verifyEmptyVentasSeleccioandos.length > 0
            ? !inSelectRange
              ? 'Al activar esta función, podrá seleccionar 2 períodos para compararlos.'
              : 'Haga click en dos períodos diferentes para poder compararlos. (Seleccione 2 puntos diferentes en el gáfico  y se mostrara la comparación en el gráfico de más abajo)'
            : 'Seleccione por lo menos una categoría para activar la selección de períodos'
        }
      >
        <div>
          {!inSelectRange ? `Active la selección de períodos → ` : `Seleccione 2 períodos para comparar `}
          {
            <IconButton
              disabled={verifyEmptyVentasSeleccioandos.length === 0}
              style={{
                backgroundColor: inSelectRange && verifyEmptyVentasSeleccioandos.length !== 0 && 'rgb(255 208 159)'
              }}
              size="small"
              onClick={(e) => handleChangeSelectRange(e)}
            >
              <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            </IconButton>
          }
        </div>
      </Tooltip>
    );
  };

  const subTitle = () => {
    let total = props[dataOptions[dataSelectionId].ventasKey].total
      ? `Total: ${storageMonedaSigno()} ${formatNumber(props[dataOptions[dataSelectionId].ventasKey].total)}`
      : `Total: ${storageMonedaSigno()} ${formatNumber(totalLocal)}`;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} l={12} container spacing={1} className={classes.Header}>
          <Grid item xs={12} className={classes.Title}>
            <Typography className={classes.TotalNumberText}>
              {total}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.LineEvolucionVentasMensualContainer}>
      <BoxStepInfo
        texto={
          '1° Paso - Haga click sobre las categorías que se encuentran a la derecha del siguiente gráfico para visualizar la información.'
        }
      />
      <LineChart
        title={titles()}
        series={ventasSeleccionados}
        subTitle={subTitle()}
        seriesBandera={seriesBandera}
        loading={props[dataOptions[dataSelectionId].ventasKey].loading}
        chartTextAlign={'left'}
        eventsLegendClick={(chartContext, seriesIndex, config) => onLegendClick(chartContext, seriesIndex, config)}
        eventsMarkerClick={(event, chartContext, { seriesIndex, dataPointIndex, config }) =>
          inSelectRange && onMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config })
        }
        middleItems={middleControls()}
        legendOnItemClick={false}
        chartHeight={'450px'}
        width={'100%'}
        chartWidth={'99%'}
        chartDropShadow={{
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }}
        toolbarShow={true}
        tooltipEnabled
        customTooltip={(seriesTooltip, seriesIndex, dataPointIndex, w) =>
          armarTooltip(seriesTooltip, seriesIndex, dataPointIndex, w)
        }
        zoomType={'xy'}
        dataLabelsEnabled={window.screen.width > 500}
        formatDataLabels
        strokeCurve={'smooth'}
        strokeWidth={5}
        fillType={'solid'}
        gridBorderColor={'#e7e7e7'}
        gridRow={{
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }}
        markersSize={4}
        xaxisCategories={fechas}
        xaxisType="category"
        yaxisLabels={{
          show: true,
          formatter: (value) => {
            return `${sessionStorage.getItem('monedaSigno')} ${formatNumber(value)}`;
          },
          align: 'left',
          offsetX: -16
        }}
        legendFontSize={'11px'}
        legendShowForSingleSeries={true}
        legendShowForNullSeries={true}
        legendShowForZeroSeries={true}
        disableLegendOnItemClickToggle
        disableLengendOnItemHover
        legendPosition={'right'}
        legendHorizontalAlign={'left'}
        legendOffsetY={10}
        onFiltersClick={() => props.onShowFiltersModal(`Ventas mensuales por ${dataOptions[dataSelectionId].description.toLowerCase()}`)}
      />
      <div style={{ height: '10px' }} />
      <BoxStepInfo
        texto={
          <div>
            {`2° Paso - Seleccione dos períodos en el gráfico "Ventas mensuales por ${dataOptions[
              dataSelectionId
            ].description.toLowerCase()}" utilizando la herramienta `}
            <img className={classes.IconSelectRange} alt="" src={RangeSelect} />
            {` para visualizar la información`}
          </div>
        }
      />
      <BarComparacionPeriodosDeVentaDelVendedor
        inComparacion
        ventasDe={dataOptions[dataSelectionId].description}
        banderaComparacion={banderaVentasComparacion}
        loading={loadingComparacion}
        ventasComparar={ventasComparar}
        onLimpiarComparacionClick={() => onLimpiarComparacion()}
        loadDetallesKey={dataOptions[dataSelectionId].loadDetallesKey}
        dataSelectionId={dataOptions[dataSelectionId].stringId}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectionId: state.vendedores.dataVentasDelMesDelVendedorPorSelected,

    vendedorSelected: state.vendedores.vendedorSelected,

    //#region ventas por categoria de cliente
    ventasPorCategoria: state.vendedores.evolucionVentasDelVendedorPorCategoriaDeCliente,
    //#endregion

    //#region ventas por Provincias
    ventasPorProvincias: state.vendedores.evolucionVentasDelVendedorPorProvincia,
    //#endregion

    //#region ventas por Zonas
    ventasPorZonas: state.vendedores.evolucionVentasDelVendedorPorZonas,
    //#endregion

    //#region ventas por Marca
    ventasPorMarca: state.vendedores.evolucionVentasDelVendedorPorMarca,
    //#endregion

    //#region ventas por Rubro
    ventasPorRubro: state.vendedores.evolucionVentasDelVendedorPorRubro,
    //#endregion

    //#region ventas por Subrubro
    ventasPorSubrubro: state.vendedores.evolucionVentasDelVendedorPorSubrubro
    //#endregion
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selectionId) => dispatch(actions.seleccionDataEvolucionVentasDelvendedor(selectionId)),

    //#region ventas por categoria de cliente
    onLoadEvolucionVentasPorCategoriaDeCliente: (idVendedor, success) =>
      dispatch(actions.loadEvolucionVentasDelVendedorPorCategoriaDeCliente(idVendedor, undefined, success)),
    onLoadVentasDelMesPorCategoriaDeCliente: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorCategoriaDeClienteDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por provincias
    onLoadEvolucionVentasPorProvincias: (idVendedor, success) =>
      dispatch(actions.loadEvolucionVentasDelVendedorPorProvincias(idVendedor, undefined, success)),
    onLoadVentasDelMesPorProvincias: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorProvinciaDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por zonas
    onLoadEvolucionVentasPorZonas: (idVendedor, success) =>
      dispatch(actions.loadEvolucionVentasDelVendedorPorZonas(idVendedor, undefined, success)),
    onLoadVentasDelMesPorZonas: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorZonaDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por marca
    onLoadEvolucionVentasPorMarca: (idVendedor, success) =>
      dispatch(actions.loadEvolucionVentasDelVendedorPorMarcas(idVendedor, undefined, success)),
    onLoadVentasDelMesPorMarca: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorMarcaDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por rubro
    onLoadEvolucionVentasPorRubro: (idVendedor, success) =>
      dispatch(actions.loadEvolucionVentasDelVendedorPorRubros(idVendedor, undefined, success)),
    onLoadVentasDelMesPorRubro: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorRubroDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    //#region ventas por subrubro
    onLoadEvolucionVentasPorSubrubro: (idVendedor, success) =>
      dispatch(actions.loadEvolucionVentasDelVendedorPorSubrubro(idVendedor, undefined, success)),
    onLoadVentasDelMesPorSubrubro: (idVendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorSubrubroDelVendedor(idVendedor, mes, anio, success, errorFail)),
    //#endregion

    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionVentasDelVendedorPorSeleccion);
