// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaComparacionVentasDeVendedoresDelArticuloPorCliente__SorteableHeaderTitle__C-LHG:hover {\r\n  color: rgba(0, 0, 0, 0.54);\r\n  cursor: pointer;\r\n}\r\n\r\n.TablaComparacionVentasDeVendedoresDelArticuloPorCliente__TablasComparacionComprobantesContainer__e6bpD {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SorteableHeaderTitle": "TablaComparacionVentasDeVendedoresDelArticuloPorCliente__SorteableHeaderTitle__C-LHG",
	"TablasComparacionComprobantesContainer": "TablaComparacionVentasDeVendedoresDelArticuloPorCliente__TablasComparacionComprobantesContainer__e6bpD"
};
export default ___CSS_LOADER_EXPORT___;
