// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaGastosPorItemDeCateogoria__TitleCard__5M22h {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.TablaGastosPorItemDeCateogoria__tablaGastosPorItemDeCat__WOWmC {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n}\r\n\r\n.TablaGastosPorItemDeCateogoria__TablaGastosMensualesPorCatContainer__Al\\+Ib {\r\n  max-height: 460px;\r\n  flex: 1 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.TablaGastosPorItemDeCateogoria__tablaGastosPorItemDeCat__WOWmC button {\r\n  padding: 7px;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .TablaGastosPorItemDeCateogoria__tablaGastosPorItemCatContainer__Bqcul {\r\n    max-height: 743px;\r\n  }\r\n}\r\n\r\n@media (max-width: 752px) {\r\n  .TablaGastosPorItemDeCateogoria__tablaGastosPorItemCatContainer__Bqcul {\r\n    max-height: 400px;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleCard": "TablaGastosPorItemDeCateogoria__TitleCard__5M22h",
	"tablaGastosPorItemDeCat": "TablaGastosPorItemDeCateogoria__tablaGastosPorItemDeCat__WOWmC",
	"TablaGastosMensualesPorCatContainer": "TablaGastosPorItemDeCateogoria__TablaGastosMensualesPorCatContainer__Al+Ib",
	"tablaGastosPorItemCatContainer": "TablaGastosPorItemDeCateogoria__tablaGastosPorItemCatContainer__Bqcul"
};
export default ___CSS_LOADER_EXPORT___;
