import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';

import classes from './Styles/BigChartCard.css';
import { formatNumber, formatNumberWithoutDecimals } from '../../shared/formatNumber';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import { storageMonedaSigno } from '../../shared/sessionData';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';
import { colorsChart } from '../../shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const MultiLineChart = (props) => {
  let chartRef = useRef(null);

  const [series, setSeries] = useState(props.series);

  useEffect(() => {
    setSeries(props.series);
  }, [props.series, props.seriesBandera]);
  
  const options = {
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 10,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: true,
        tools: {
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        }
      },
      zoom: props.disableZoom
        ? undefined
        : {
            enabled: true,
            type: props.zoomType ? props.zoomType : 'xy',
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1
              }
            }
          }
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (index, config) {
          let category = props.categories[config.seriesIndex];
          const formatDate = moment(category[index - 1]).format('MM/YYYY');
          return `${props.titleTooltip ? props.titleTooltip : ''} ${
            formatDate === 'Invalid date' || formatDate === 'Fecha inválida'
              ? `${category[index - 1]}`
              : formatDate
          }`;
        }
      },
      y: {
        formatter: function (val) {
          return props.withoutDecimals ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(val)}` : `${storageMonedaSigno()} ${formatNumber(val)}`;
        }
      }
    },
    colors: props.colors ? props.colors : colorsChart,
    dataLabels: {
      enabled: props.dataLabelsEnabled,
      formatter: function (val, opts) {
        return props.formatDataLabels ? props.withoutDecimals ? formatNumberWithoutDecimals(val) : formatNumber(val) : val;
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        inverseColors: false,
        opacityFrom: 5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    grid: {
      show: !props.disableGrid,
      borderColor: props.gridBorderColor ? props.gridBorderColor : '#e7e7e7',
      row: props.gridRow
        ? props.gridRow
        : {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
    },
    markers: {
      size: props.markersSize,
      colors: props.markersColors,
      strokeColors: props.markersStrokeColors,
      strokeWidth: props.markersStrokeWidth,
      hover: {
        size: props.markersHoverSize
      }
    },
    xaxis: {
      labels: props.xaxisLabels,
      categories: props.xaxisCategories,
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      min: props.yaxysMin,
      max: props.yaxysMax,
      labels: props.yaxisLabels ? props.yaxisLabels : {}
    },
    legend: {
      enabled: props.disableLegend ? false : true,
      fontSize: props.legendFontSize,
      height: props.legendHeight,
      offsetY: props.legendOffsetY,
      position: props.legendPosition,
      horizontalAlign: props.legendHorizontalAlign,
      showForSingleSeries: props.legendShowForSingleSeries,
      showForNullSeries: props.legendShowForNullSeries,
      showForZeroSeries: props.legendShowForZeroSeries,
      onItemClick: {
        toggleDataSeries: props.disableLegendOnItemClickToggle ? false : true
      },
      onItemHover: {
        highlightDataSeries: props.disableLengendOnItemHover ? false : true
      },
      formatter: function (seriesName, opts) {
        return props.legendFormatter ? props.legendFormater : seriesName;
      }
    },
    responsive: [
      {
        breakpoint: 750,
        options: {
          chart: {
            width: '100%'
          },
          yaxis: {
            labels: {
              show: false
            }
          }
        }
      }
    ]
  };

  return (
    <div ref={chartRef} className={classes.BigChartCard} style={{ width: props.width, minHeight: props.loading && '240px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>{props.title}</p>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.subTitle && <p style={{ marginTop: '1px', fontSize: '12px' }}>{props.subTitle}</p>}
        {props.children}
      </div>
      {props.disabled ? (
        <img alt="Indicador Inhabilitado" src={IndicadorDeshabilitado} />
      ) : props.loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      ) : props.imgSinDatos ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="Sin resultados" src={SinResultados} style={{ maxWidth: '400px' }} />
        </div>
      ) : (
        <Chart 
          className={classes.Chart}
          options={props.personalizeOptions ? props.personalizeOptions : options}
          style={{ textAlign: props.chartTextAlign ? props.chartTextAlign : 'center' }}
          series={series}
          height={props.chartHeight}
          type={props.chartType}
          width={props.chartWidth}
        />
      )}
    </div>
  );
};

export default MultiLineChart;
