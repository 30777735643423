import React from "react"
import classes from "./PiePlaceholder.css"
import AdsClick from '../../../../assets/images/AdsClick.svg';

const PiePlaceholderSegundoNivel = (props) => {
    return(
        <div className={classes.Container}>
            <img alt="" src={AdsClick} className={classes.Imagen} />
            <div className={classes.TextContainer}>
                <p className={classes.Text}>
                    Haga clic en una porción del gráfico de la izquierda para cargar este gráfico desglosado
                </p>
            </div>
        </div>
    )
}

export default PiePlaceholderSegundoNivel; 