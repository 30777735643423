import { useEffect } from 'react';
import { connect } from 'react-redux';
import { urlPortalLogout } from '../../../shared/urlApps';
import * as actions from '../../../store/actions/index';

const Logout = (props) => {
  const { onLogout } = props;

  useEffect(() => {
    onLogout();
  }, [onLogout]);

  return setTimeout(() => urlPortalLogout(), 500);
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(actions.logout());
    }
  };
};

export default connect(null, mapDispatchToProps)(Logout);
