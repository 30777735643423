import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PiePagosDelMesPorEmpresa from '../PiePagosDelMesPorEmpresa/PiePagosDelMesPorEmpresa';
import TablaMultiempresa from '../TablaMultiempresa/TablaMultiempresa';
import { fechaInicioPeriodoMesActualFunc, fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

import * as actions from '../../../../store/actions/index';

const MultiChartPagosDelMesPorEmpresa = (props) => {
    const [viewAsPie, setViewAsPie] = useState(true);

    useEffect(() => {
        if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) 
          props.onLoadChart();
        
    }, [props.ejecucionDePagina]);
    
    const handleChangeView = () => {
        setViewAsPie(!viewAsPie)
    }

    return(
        viewAsPie 
        ? 
            <PiePagosDelMesPorEmpresa 
                ejecucionDePagina={props.ejecucionDePagina}
                onPorcionClick={props.onPorcionClick}
                onChangeViewClick={handleChangeView}
                title={`Pagos del mes por empresa ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`}
                subtitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.totalPagos)}`}
                height={300}
                width={'100%'}
            />
        : 
            <TablaMultiempresa 
                empresas={props.pagos}
                onChangeViewClick={handleChangeView}
                title={`Pagos del mes por empresa ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`}
                subtitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.totalPagos)}`}
                height={245}
                width={'100%'}
            />
    )
}

const mapStateToProps = (state) => {
    return {
      pagos: state.multiempresa.pagosDelMesPorEmpresa.pagos,
      totalPagos: state.multiempresa.pagosDelMesPorEmpresa.totalPagos,
      loading: state.multiempresa.pagosDelMesPorEmpresa.loading,
      estaCargado: state.multiempresa.pagosDelMesPorEmpresa.estaCargado,
      estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMesMultiempresa
    };
};

const mapDispatchToProps = (dispatch) => {
return {
    onLoadChart: () => dispatch(actions.loadPagosDelMesPorEmpresa())
};
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiChartPagosDelMesPorEmpresa);