// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VendedorSelector__SelectRoot__j0JeX {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectRoot": "VendedorSelector__SelectRoot__j0JeX"
};
export default ___CSS_LOADER_EXPORT___;
