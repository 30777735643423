import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classes from './TablaComparacionVentasEntreSucursales.css';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MTableBodyRow } from 'material-table';
import TablaComparacionVentasEntreSucursalesPorSeleccion from '../TablaComparacionVentasEntreSucursalesPorSeleccion/TablaComparacionVentasEntreSucursalesPorSeleccion';
import TablaComparacionVentasEntreSucursalesToExcel from './TablaComparacionVentasEntreSucursalesToExcel';
import { compareByAsc, compareByDesc } from '../../../../shared/sorting';

const TableComparacionVentasEntreSucursales = (props) => {
  const { fechasComparar = [], ventasComparar, loading, dataSelectionId, ventasDe } = props;
  const [tableState, setTableState] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });
  const [searchState, setSearchState] = useState('');

  useEffect(() => {
    if (ventasComparar.length !== 0) {
      if (ventasComparar.length === 1) {
        setTableState([{ dateComplete: ventasComparar[0].dateComplete, ventasDetallada: ventasComparar[0].ventas }]);
      } else {
        setTableState([
          { dateComplete: ventasComparar[0].dateComplete, ventasDetallada: ventasComparar[0].ventas },
          { dateComplete: ventasComparar[1].dateComplete, ventasDetallada: ventasComparar[1].ventas }
        ]);
      }
    } else {
      setTableState([]);
    }
  }, [ventasComparar, dataSelectionId]);

  const columns = [
    {
      title: '',
      field: 'nombre',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;

    const filterTableState =
      tableState.length === 2
        ? [
            {
              ...tableState[0],
              ventasDetallada: tableState[0].ventasDetallada.filter(
                (ventDet) =>
                  ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
              )
            },
            {
              ...tableState[1],
              ventasDetallada: tableState[1].ventasDetallada.filter(
                (ventDet) =>
                  ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
              )
            }
          ]
        : [];

    filterTableState.map((data, key) => {
      if (key === 0) {
        return data.ventasDetallada.map((ventaDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].ventasDetallada[index];

          const ventasDelIndex = ventasDetalldasDelIndex
            ? ventasDetalldasDelIndex
            : { ...ventaDetallada, monto: 0, porcentajeDeParticipacion: 0 };

          totalMonto1 += ventaDetallada.monto;
          totalMonto2 += ventasDelIndex.monto;

          return formatTableData.push({
            id: ventaDetallada.id,
            nombre: ventaDetallada.nombre,
            monto1: ventaDetallada.monto,
            porcParticipacion1: ventaDetallada.porcentajeDeParticipacion,
            monto2: ventasDelIndex.monto,
            porcParticipacion2: ventasDelIndex.porcentajeDeParticipacion,
            difMontos: calcularDif(ventaDetallada.monto, ventasDelIndex.monto),
            porcComparacion: calcularPorc(ventaDetallada.monto, ventasDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        nombre: 'Total',
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });
    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const handleSearchChange = (e) => {
    setSearchState(e.target.value.toLowerCase());
  };

  const headersBackgroundColor = 'rgb(250, 250, 250)';

  const tableRef = useRef();

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <div
          style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '10px', marginLeft: '20px' }}
        >{`Comparativa de ventas entre sucursales del ${
          ventasComparar.length !== 0
            ? moment(ventasComparar[0].dateComplete).subtract(1, 'month').format('MM/YYYY')
            : ''
        }`}</div>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Buscar"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        {ventasComparar.length === 2 ? (
          <TablaComparacionVentasEntreSucursalesToExcel
            ventasPor={`${ventasDe}`}
            ventas={data()}
            sucursales={[ventasComparar[0].sucursal, ventasComparar[1].sucursal]}
          />
        ) : (
          <div />
        )}
      </div>
      <Table
        columns={columns}
        tableRef={tableRef}
        data={data()}
        noBoxShadow
        loading={loading}
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>
                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {ventasComparar.length !== 0 && ventasComparar[0].sucursal.nombre}
                  </TableCell>
                  {ventasComparar.length === 2 && (
                    <Fragment>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px',
                          border: 'solid 1px #e0e0e0',
                          backgroundColor: headersBackgroundColor
                        }}
                        colSpan={2}
                        align="center"
                      >
                        {ventasComparar[1].sucursal.nombre}
                      </TableCell>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                        align="center"
                      ></TableCell>
                      <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                    </Fragment>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('nombre')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      width: window.screen.width < 1367 ? '265px' : '410px',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="left"
                  >
                    {ventasDe}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  {ventasComparar.length === 2 && (
                    <Fragment>
                      <TableCell
                        className={classes.SorteableHeaderTitle}
                        onClick={() => onSort('monto2')}
                        style={{
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                          backgroundColor: headersBackgroundColor
                        }}
                        align="right"
                      >
                        Monto
                      </TableCell>
                      <TableCell
                        className={classes.SorteableHeaderTitle}
                        onClick={() => onSort('porcParticipacion2')}
                        style={{
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                          backgroundColor: headersBackgroundColor
                        }}
                        align="right"
                      >
                        Porc. Participación
                      </TableCell>
                      <TableCell
                        className={classes.SorteableHeaderTitle}
                        onClick={() => onSort('difMontos')}
                        style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                        align="right"
                      >
                        Dif. Montos
                      </TableCell>
                      <TableCell
                        className={classes.SorteableHeaderTitle}
                        onClick={() => onSort('porcComparacion')}
                        style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                        align="right"
                      >
                        Porc. Comparación
                      </TableCell>
                    </Fragment>
                  )}
                </TableRow>
              </TableHead>
            );
          },
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
                detailPanel={
                  !props.data.id
                    ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                    : props.detailPanel
                }
              />
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            render: (rowData) => {
              return (
                <TablaComparacionVentasEntreSucursalesPorSeleccion
                  categoria={rowData}
                  fechaComparar={ventasComparar[0].dateComplete}
                  sucursalesComparar={[ventasComparar[0].sucursal, ventasComparar[1].sucursal]}
                  titleCategory={rowData.nombre}
                  loadDetallesKey={props.loadDetallesKey}
                  ventasDe={ventasDe}
                  dataSelectionId={dataSelectionId}
                />
              );
            }
          }
        ]}
        customOptions={{
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          maxBodyHeight: 630,
          showTitle: false,
          tableLayout: 'fixed',
          toolbar: false,
          rowStyle: (rowData, config) => {
            return {
              cursor: 'auto',
              backgroundColor: (!rowData.id && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
            };
          },
          cellStyle: (cellData) => ({
            fontWeight: cellData === 'Total' && 'bolder'
          })
        }}
        tableKey={"tabla-comparacion-ventas-entre-sucursales"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComparacionVentasEntreSucursales);
