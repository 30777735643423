import React, { Fragment, useEffect, useRef, useState } from 'react';
import classes from '../InformeTres.css';
import { formatNumber, formatNumberWithMonedaSigno, formatNumberWithoutDecimals } from '../../../../../../shared/formatNumber';
import Table from '../../../../../UI/Table/Table';
import TablaPorArticulo from '../TablaPorArticulo/TablaPorArticulo';
import { ChevronRight, KeyboardArrowUp } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Grid, TableCell, TableFooter, TableRow } from '@material-ui/core';
import { MTableBody } from 'material-table';
import TablaPorLineaToExcel from './TablaPorLineaToExcel';
import Spinner from '../../../../../UI/Spinner/Spinner';
import ButtonPivotGrid from '../../../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid';
import ExportToPdfIcon from '../../../../../UI/Icons/ExportToPdfIcon';

const TablaPorLinea = (props) => {
  const { nombreLinea, loading, datos, onExport } = props;
  const [expandAll, setExpandAll] = useState(false);
  const [loadingExpand, setLoadinExpand] = useState(false);

  const empresaHeadersStyle = {
    fontSize: '12x',
    paddingBlock: '5px',
    paddingInline: '2px',
    backgroundColor: '#fff0f6',
    position: 'sticky',
    top: '0px',
    zIndex: 20
  };

  const headersStyle = {
    fontSize: '12x',
    padding: '2px',
    textAlign: 'center',
    backgroundColor: '#fff0f6',
    position: 'sticky',
    top: '0px',
    zIndex: 20
  };

  const cellStyles = (value, width, upper = false) => {
    return {
      fontSize: '12px',
      padding: '5px',
      color: value < 0 && 'red',
      textTransform: upper && 'uppercase',
      width: width
    };
  };

  const totalCellStyles = (value, width) => {
    return {
      fontSize: '12px',
      padding: '5px',
      color: value < 0 ? 'red' : 'black',
      textTransform: 'uppercase',
      width: width,
      textAlign: 'right',
      backgroundColor: '#C9E6EF',
      fontWeight: 'bold'
    };
  };

  const columns = [
    {
      title: `${nombreLinea}`,
      field: 'rubro',
      align: 'left',
      cellStyle: (cellData) => cellStyles(cellData, '14%', true),
      headerStyle: empresaHeadersStyle,
      width: '14%'
    },
    {
      title: 'Venta Real (Unidades)',
      field: 'unidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidades),
      width: '10%'
    },
    {
      title: 'Presupuesto (Unidades)',
      field: 'unidadesPresupuesto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidadesPresupuesto),
      width: '11%'
    },
    {
      title: 'Diferencia (Unidades)',
      field: 'unidadesDiferencia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidadesDiferencia),
      width: '10%'
    },
    {
      title: 'Cumplimiento (Unidades)',
      field: 'unidadesCumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.unidadesCumplimiento * 100)}%`,
      width: '11%'
    },
    {
      title: 'Venta Real (Imp. Neto)',
      field: 'importeNeto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNeto),
      width: '10%'
    },
    {
      title: 'Presupuesto (Imp. Neto)',
      field: 'importeNetoPresupuesto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNetoPresupuesto),
      headerStyle: headersStyle,
      width: '11%'
    },
    {
      title: 'Diferencia (Imp. Neto)',
      field: 'importeNetoDiferencia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNetoDiferencia),
      headerStyle: headersStyle,
      width: '11%'
    },
    {
      title: 'Cumplimiento (Imp. Neto)',
      field: 'importeNetoCumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '13%'),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.importeNetoCumplimiento * 100)}%`,
      width: '13%'
    },
  ];

  const onExpandClick = (expand) => {
    setLoadinExpand(true);
    setExpandAll(expand);
    setTimeout(() => setLoadinExpand(false), 500);
  }

  const renderToolbar = () => {
    return (
      <Grid container spacing={1} direction='row' justifyContent='flex-end'>
        <Grid item xs={12} md={6} lg={3} container direction='row' justifyContent='flex-end'>
          <Grid item xs={4}>
            {nombreLinea === "TENACTA" ?
              <Fragment></Fragment>
              : <ButtonPivotGrid
                tooltipTitle={expandAll ? "Colapsar rubros" : "Expandir rubros"}
                buttonSize="small"
                text={expandAll ? "Colapsar" : "Expandir"}
                onClick={() => onExpandClick(!expandAll)}
                icon={expandAll ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                disabled={loadingExpand || !datos || datos.length <= 0}
              />
            }
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'end' }}>
            <ExportToPdfIcon
              element={customPivotGridRef && customPivotGridRef.current}
              fileName={`Informe 3 - Objetivos por rubros - ${nombreLinea}`}
              tooltipTitle={"Descargar tabla en Pdf"}
              beforeExport={() => onExportStart()}
              afterExport={() => onExportFinish()}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'end' }}>
            <TablaPorLineaToExcel
              data={datos}
              name={nombreLinea}
              expanded={expandAll}
              filename={`Informe 3 - Objetivos por rubros - ${nombreLinea}`}
              tooltipTitle={"Descargar tabla en Excel"}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const detailPanel = [
    {
      disabled: false,
      tooltip: 'Ver artículos',
      icon: () => <ChevronRight fontSize='small' />,
      openIcon: () => <KeyboardArrowDownIcon fontSize='small' />,
      render: (rowData) => {
        return (
          <div className={classes.tablaVentasPorArticulo}>
            <div style={{ width: '100%', paddingLeft: '50px' }}>
              <TablaPorArticulo
                idLinea={rowData.idLinea}
                idRubro={rowData.idRubro}
                articulos={rowData.articulos}
                expandAll={expandAll}
                maxHeight={maxHeight}
              />
            </div>
          </div>
        )
      }
    }
  ];

  const data = (linea) => {
    if (!datos)
      return [];

    const result = [];
    const datoLinea = datos.find((item) => item.linea.trimEnd() === linea);
    const items = datoLinea ? datoLinea.items : [];

    items.forEach((item, index) => {
      result.push({
        ...item,
        tableData: {
          id: index,
          showDetailPanel: expandAll ? detailPanel[0].render : undefined,
        }
      })
    });

    return result;
  };

  const renderTotalRow = (linea) => {
    if (!datos)
      return (
        <Fragment></Fragment>
      )

    const datoLinea = datos.find((item) => item.linea.trimEnd() === linea);
    const totalLinea = datoLinea ? datoLinea.total : undefined;

    if (!totalLinea)
      return (
        <Fragment></Fragment>
      )

    return (
      <TableFooter>
        <TableRow style={{ backgroundColor: '#C9E6EF' }}>
          <TableCell colSpan={2} width='14%' align='left' style={{ fontWeight: 'bold', color: 'black' }}>{totalLinea.rubro}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.unidades, '10%', true)}}>{formatNumberWithoutDecimals(totalLinea.unidades)}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.unidadesPresupuesto, '11%')}}>{formatNumberWithoutDecimals(totalLinea.unidadesPresupuesto)}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.unidadesDiferencia, '10%')}}>{formatNumberWithoutDecimals(totalLinea.unidadesDiferencia)}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.unidadesCumplimiento, '11%')}}>{formatNumber(totalLinea.unidadesCumplimiento * 100)}%</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.importeNeto, '10%')}}>{formatNumberWithMonedaSigno(totalLinea.importeNeto)}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.importeNetoPresupuesto, '11%')}}>{formatNumberWithMonedaSigno(totalLinea.importeNetoPresupuesto)}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.importeNetoDiferencia, '11%')}}>{formatNumberWithMonedaSigno(totalLinea.importeNetoDiferencia)}</TableCell>
          <TableCell style={{...totalCellStyles(totalLinea.importeNetoCumplimiento, '13%')}}>{formatNumberWithMonedaSigno(totalLinea.importeNetoCumplimiento * 100)}%</TableCell>
        </TableRow>
      </TableFooter>
    )
  }

  const tableRef = useRef();

  //#region Export a PDF
  const customPivotGridRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('550px');
  const [onLocalExport, setOnLocalExport] = useState(false);

  const onExportStart = () => {
    setOnLocalExport(true);
    setMaxHeight('100%');
  }

  const onExportFinish = () => {
    setOnLocalExport(false);
    setMaxHeight('550px');
  }

  useEffect(() => {
    if (!onLocalExport && onExport && maxHeight !== '100%')
      setMaxHeight('100%');
  }, [onLocalExport, onExport, maxHeight]);

  useEffect(() => {
    if (!onLocalExport && !onExport && maxHeight !== '550px')
      setMaxHeight('550px');
  }, [onLocalExport, onExport, maxHeight]);

  //#endregion

  return (
    <div ref={customPivotGridRef} className={classes.tablaVentasPorRubro} style={{ boxShadow: "0px 3px 6px #00000029" }}>
      {loadingExpand
        ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <Fragment>
            <div style={{ backgroundColor: '#fff0f6' }}>
              {renderToolbar()}
            </div>
            <Table
              noBoxShadow
              tableRef={tableRef}
              style={{
                marginTop: 0, marginBottom: '10px', border: "0px solid #ccc", backgroundColor: "white", borderRadius: "3px"
              }}
              title=""
              columns={columns}
              loading={loading}
              data={data(nombreLinea)}
              detailPanel={detailPanel}
              customOptions={{
                thirdSortClick: false,
                sorting: true,
                grouping: false,
                draggable: false,
                search: false,
                paging: false,
                toolbar: false,
                showTitle: false,
                maxBodyHeight: maxHeight,
                defaultExpanded: true,
                headerStyle: { backgroundColor: '#fff0f6' }
              }}
              components={{
                Body: (props) => {
                  return <Fragment>
                    {nombreLinea !== 'TENACTA' && <MTableBody {...props} />}
                    {renderTotalRow(nombreLinea)}
                  </Fragment>
                }
              }}
            />
          </Fragment>
        )}
    </div>
  );
};

export default TablaPorLinea;