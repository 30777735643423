import React, { Fragment, useRef, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions';
import { Grid, Typography } from "@material-ui/core";
import FiltroInformeDos from "../FiltroInformeDos/FiltroInformeDos";
import classes from "./TablasInformeDos.css";
import { storageEmpresaId, storageUsuarioId } from "../../../../../../shared/sessionData";
import TablaPorLinea from "../TablaPorLinea/TablaPorLinea";
import TablasInformeDosToExcel from "./TablasInformeDosToExcel";
import ExportToPdfIcon from "../../../../../UI/Icons/ExportToPdfIcon";

const dataOptions = [
  {
    id: 0,
    nombre: 'Unidades',
    key: 'unidades',
    monto: false,
  },
  {
    id: 1,
    nombre: 'Precio por Unidad',
    key: 'precioUnidad',
    monto: true,
  },
  {
    id: 2,
    nombre: 'Facturación',
    key: 'facturacion',
    monto: true,
  }
];

const TablasInformeDos = (props) => {
  const { anioInforme, aprobado, budgetAnterior, datos, onLoadData, loading } = props;
  const { onExportarExpandido } = props;

  //#region Tipo de Objetivo
  const [optionSelected, setOptionSelected] = useState(dataOptions[0]);

  const onOptionSelectedChange = (value) => {
    let item = dataOptions.find((i) => i.id === value.id);
    if (item) {
      setOptionSelected(item);
    }
  }

  //#endregion

  //#region Filtro
  const onAplicarFiltroClick = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta) => {
    const request = {
      anioFiscal: typeof anioFiscal === 'object' ? anioFiscal.getFullYear() : anioFiscal,
      idArchivo,
      mesFiscal,
      acumulado,
      idEmpresa: storageEmpresaId(),
      idUsuario: storageUsuarioId(),
      desde,
      hasta
    };

    onLoadData(request);
  }

  //#endregion

  //#region Datos
  const datoPorLinea = (linea) => {
    if (!datos || !optionSelected || !datos[optionSelected.key])
      return [];

    const datoLinea = datos[optionSelected.key].find((item) => item.linea.trimEnd() === linea);
    return datoLinea ? datoLinea.items : [];
  }

  const getExcelData = () => {
    if (!datos || !optionSelected || !datos[optionSelected.key])
      return [];

    const datoObjetivo = datos[optionSelected.key];
    return datoObjetivo ? datoObjetivo : [];
  }

  //#endregion

  //#region Export a PDF
  const informeDosRef = useRef(null);
  const [onExport, setOnExport] = useState(false);

  const onExportStart = () => {
    setOnExport(true);
  }

  const onExportFinish = () => {
    setOnExport(false);
  }
  //#endregion

  //#region Exportar Expandido
  const onExportarExpandidoClick = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta) => {
    const request = {
      anioFiscal: typeof anioFiscal === 'object' ? anioFiscal.getFullYear() : anioFiscal,
      idArchivo,
      mesFiscal,
      acumulado,
      idEmpresa: storageEmpresaId(),
      idUsuario: storageUsuarioId(),
      desde,
      hasta
    };

    onExportarExpandido(request);
  }

  return (
    <div ref={informeDosRef}>
      <Grid container style={{ height: "100%", minHeight: props.minHeight + 50 }}>
        <Grid item xs={12}
          container spacing={1}
          direction='row' justifyContent='space-between' alignContent='flex-start'
          style={{
            margin: 'auto', border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "white", borderRadius: "3px", padding: '10px'
          }}
        >
          <Grid item xs={12} container className={classes.TitleContainer} direction="row" justifyContent='space-between'>
            <Grid item xs={8} lg={2}>
              <Typography variant="h5">
                INFORME 2
              </Typography>
            </Grid>
            <Grid container spacing={1} item xs={4} lg={1} direction='row' justifyContent='flex-end'>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <ExportToPdfIcon
                  element={informeDosRef && informeDosRef.current}
                  fileName={`Informe 2 - ${optionSelected && optionSelected.nombre}`}
                  tooltipTitle={"Descargar tablas en Pdf"}
                  beforeExport={() => onExportStart()}
                  afterExport={() => onExportFinish()}
                />
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <TablasInformeDosToExcel
                  data={getExcelData()}
                  name={optionSelected && optionSelected.nombre}
                  filename={`Informe 2 - ${optionSelected && optionSelected.nombre}`}
                  tooltipTitle={"Descargar tablas en Excel"}
                  monto={optionSelected && optionSelected.monto}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} justifyContent="flex-start">
            <FiltroInformeDos
              aplicarFiltros={onAplicarFiltroClick}
              exportarExpandido={onExportarExpandidoClick}
              optionSelected={optionSelected}
              dataOptions={dataOptions}
              onOptionSelectedChange={onOptionSelectedChange}
            />
          </Grid>
          <Grid item xs={12}>
            {!aprobado && (
              <Typography variant="subtitle1" color="error">
                El budget/forecast asociado no está aprobado.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {budgetAnterior && anioInforme && (
              <Typography variant="subtitle1" color="error">
                El budget/forecast asociado es del año: {anioInforme}.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} style={{ marginTop: '20px' }} direction="row" justifyContent="center">

          <Fragment>
            <Grid item xs={12}>
              <TablaPorLinea
                nombreEmpresa={"ASPEN"}
                loading={loading}
                datos={datoPorLinea("ASPEN")}
                monto={optionSelected && optionSelected.monto}
                objetivo={optionSelected}
                onExport={onExport}
              />
            </Grid>
            <Grid item xs={12}>
              <TablaPorLinea
                nombreEmpresa={"ORTOPEDIA"}
                loading={loading}
                datos={datoPorLinea("ORTOPEDIA")}
                monto={optionSelected && optionSelected.monto}
                objetivo={optionSelected}
                onExport={onExport}
              />
            </Grid>
            <Grid item xs={12}>
              <TablaPorLinea
                nombreEmpresa={"IMETEC"}
                loading={loading}
                datos={datoPorLinea("IMETEC")}
                monto={optionSelected && optionSelected.monto}
                objetivo={optionSelected}
                onExport={onExport}
              />
            </Grid>
            <Grid item xs={12}>
              <TablaPorLinea
                nombreEmpresa={"TENACTA"}
                loading={loading}
                datos={datoPorLinea("TENACTA")}
                monto={optionSelected && optionSelected.monto}
                objetivo={optionSelected}
                onExport={onExport}
              />
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.informeDosData.loading,
    error: state.tenacta.informeDosData.error,
    estaCargado: state.tenacta.informeDosData.estaCargado,
    datos: state.tenacta.informeDosData.datos,
    aprobado: state.tenacta.informeDosData.aprobado,
    budgetAnterior: state.tenacta.informeDosData.esAnterior,
    filtros: state.tenacta.informeDosFiltros,
    anioInforme: state.tenacta.informeDosData.anioInforme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (request) => dispatch(actions.loadInformeDosUnidades(request)),
    onExportarExpandido: (request) => dispatch(actions.exportarInformeDosExpandido(request)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablasInformeDos);