import { MTableHeader } from 'material-table';
import React from 'react';
import { formatNumber, formatNumberWithMonedaSigno } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';

const TableDetalleItemsCompras = (props) => {
  const { detalleItemsCompras, simbolo, loading, customWidth } = props;

  const headerStyles = {
    fontSize: '12px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(245 245 245)',
    border: 'none'
  };

  const cellStyles = {
    fontSize: '11px',
    paddingBottom: '0',
    paddingTop: '0',
    border: 'none'
  };

  const columns = [
    {
      title: 'Item',
      field: 'item',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Abreviatura',
      field: 'abreviatura',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Monto',
      field: 'monto',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.monto, simbolo)
    },
    {
      title: 'Porcentaje',
      field: 'porcentaje',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumber(rowData.porcentaje)
    },
    {
      title: 'Alicuota',
      field: 'alicuota',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    }
  ];

  const data = () => {
    return detalleItemsCompras.map((articulo) => ({
      item: articulo.itemCompra,
      abreviatura: articulo.abreviatura,
      monto: articulo.monto,
      porcentaje: articulo.porcentaje,
      alicuota: articulo.alicuotaIVA
    }));
  };

  return (
    <Table
      title=""
      loading={loading}
      noBoxShadow
      style={{
        backgroundColor: 'rgb(245, 245, 245)',
        border: '1px solid #acacac99',
        marginBottom: '5px',
        width: customWidth || undefined
      }}
      columns={columns}
      data={data()}
      components={{
        Header: (props) => (
          <MTableHeader {...props}>
            {(props.columns[0].tableData.width = '250px')}
            {(props.columns[1].tableData.width = '60px')}
            {(props.columns[2].tableData.width = '75px')}
            {(props.columns[3].tableData.width = '50px')}
            {(props.columns[4].tableData.width = '55px')}
          </MTableHeader>
        )
      }}
      customOptions={{
        grouping: false,
        draggable: false,
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        layout: 'fixed',
        maxBodyHeight: 200,
        rowStyle: (rowData) => ({
          height:'25px',
          borderBottom: '1px solid #ddd'
        })
      }}
      tableKey={"tabla-detalle-items-compras"}
    />
  );
};

export default TableDetalleItemsCompras;
