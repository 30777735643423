import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import ventasReducer from './store/reducers/ventas';
import filtrosReducer from './store/reducers/filtros';
import impuestosReducer from './store/reducers/impuestos';
import sucursalesReducer from './store/reducers/sucursales';
import gruposEmpresasReducer from './store/reducers/gruposEmpresas';
import comprasReducer from './store/reducers/compras';
import integradoresReducer from './store/reducers/integradores';
import gastosReducer from './store/reducers/gastos';
import bancosReducer from './store/reducers/bancos';
import authReducer from './store/reducers/auth';
import snackbarReducer from './store/reducers/snackbar';
import monedaReducer from './store/reducers/moneda';
import multiempresaReducer from './store/reducers/multiempresa';
import configuracionesReducer from './store/reducers/configuraciones';
import changeLogsReducer from './store/reducers/changeLogs';
import ultimasActualizacionesReducer from './store/reducers/ultimasActualizaciones';
import clientesReducer from './store/reducers/clientes';
import modalsReducer from './store/reducers/modals';
import ejecucionPaginasReducer from './store/reducers/ejecucionPaginas';
import vendedoresReducer from './store/reducers/vendedores';
import stockReducer from './store/reducers/stock';
import tenactaReducer from './store/reducers/tenacta';
import gruposGastosReducer from './store/reducers/gruposGastos';

const appReducer = combineReducers({
  ventas: ventasReducer,
  filtros: filtrosReducer,
  impuestos: impuestosReducer,
  sucursales: sucursalesReducer,
  gruposEmpresas: gruposEmpresasReducer,
  compras: comprasReducer,
  integradores: integradoresReducer,
  gastos: gastosReducer,
  bancos: bancosReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
  moneda: monedaReducer,
  multiempresa: multiempresaReducer,
  configuraciones: configuracionesReducer,
  changeLogs: changeLogsReducer,
  ultimasActualizaciones: ultimasActualizacionesReducer,
  clientes: clientesReducer,
  modals: modalsReducer,
  ejecucionPaginas: ejecucionPaginasReducer,
  vendedores: vendedoresReducer,
  stock: stockReducer,
  tenacta: tenactaReducer,
  gruposGastos: gruposGastosReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;