import React from 'react';
import { connect } from 'react-redux';

import BarUtilidadesChartBase from '../BarUtilidadesCharts/BarUtilidadesChartBase';
import TableUtilidadesBase from '../TablesUtilidades/TableUtilidadesBase';

const BarUtilidadPorSeleccion = (props) => {
  const { dataSelectedId } = props;

  const dataOptions = (item) => {
    return {
      categoriaDeCliente: {
        title: 'Categoría de cliente',
        stateKey: 'utilidadPorCategoriaDeCliente',
        onLoadKey: 'onLoadUtilidadPorCategoriasDeCliente'
      },
      marca: {
        title: 'Marca',
        stateKey: 'utilidadPorMarca',
        onLoadKey: 'onLoadUtilidadPorMarca'
      },
      rubro: {
        title: 'Rubro',
        stateKey: 'utilidadPorRubro',
        onLoadKey: 'onLoadUtilidadPorRubro'
      },
      subrubro: {
        title: 'Subrubro',
        stateKey: 'utilidadPorSubrubro',
        onLoadKey: 'onLoadUtilidadPorSubrubro'
      },
      vendedor: {
        title: 'Vendedor',
        stateKey: 'utilidadPorVendedor',
        onLoadKey: 'onLoadUtilidadPorVendedor'
      },
      provincia: {
        title: 'Provincia',
        stateKey: 'utilidadPorProvincia',
        onLoadKey: 'onLoadUtilidadPorProvincia'
      },
      zona: {
        title: 'Zona',
        stateKey: 'utilidadPorZona',
        onLoadKey: 'onLoadUtilidadPorZona'
      }
    };
  };

  return (
    <BarUtilidadesChartBase
      disabledTitle
      chartStacked
      utilidadPor={dataOptions()[dataSelectedId].title}
      utilidades={props[dataOptions()[dataSelectedId].stateKey].utilidades}
      categories={props[dataOptions()[dataSelectedId].stateKey].utilidades}
      loading={props[dataOptions()[dataSelectedId].stateKey].loading}
      chartSinDatos={props[dataOptions()[dataSelectedId].stateKey].utilidades.lenght === 0}
      customHeight={395}
      disableBoxShadow
      utilidadTable={
        <TableUtilidadesBase
          titleUtilidadPor={dataOptions()[dataSelectedId].title}
          utilidades={props[dataOptions()[dataSelectedId].stateKey].utilidades}
          loading={false}
          total={0}
        />
      }
      disabledOcultar
    />
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataUtilidadDelMesPorSelected,

    fechaUtilidadDelMes: state.ventas.fechaUtilidadDelMes,

    utilidadPorCategoriaDeCliente: state.ventas.utilidadDelMesPorCategoriaDeCliente,

    utilidadPorMarca: state.ventas.utilidadDelMesPorMarcas,
    utilidadPorRubro: state.ventas.utilidadDelMesPorRubros,
    utilidadPorSubrubro: state.ventas.utilidadDelMesPorSubrubros,

    utilidadPorProvincia: state.ventas.utilidadDelMesPorProvincias,
    utilidadPorZona: state.ventas.utilidadDelMesPorZonas,

    utilidadPorVendedor: state.ventas.utilidadDelMesPorVendedores
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BarUtilidadPorSeleccion);
