import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import moment from 'moment';

import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import { storageMultiempresa } from '../../shared/sessionData';
import Tabs from '../../components/UI/Tabs/Tabs';
import Utilidad from './Utilidad';

import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

import classes from './AnalisisDeVentas.css';
import CuboVentas from './CuboVentas';
import PanelVentas from './PanelVentas';

const AnalisisDeVentas = (props) => {
  sessionStorage.setItem('seccionNombre', 'Análisis de ventas');

  const isMultiemp = storageMultiempresa() === 'true';

  return (
    <div className={classes.AnalisisVentasContainer}>
      {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {!props.ejecucionAnalisisDeVenta ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <div>
          <Tabs
            tabs={['Ventas', 'Utilidad', 'Cubo Ventas']}
            views={[
              <PanelVentas ejecucionDePagina={props.ejecucionAnalisisDeVenta} />,
              <Utilidad ejecucionDePagina={props.ejecucionAnalisisDeVenta} />,
              <CuboVentas ejecucionDePagina={props.ejecucionAnalisisDeVenta} />
            ]}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionAnalisisDeVenta: state.ejecucionPaginas.ejecucionAnalisisDeVenta
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarAnalisisDeVentas: () => dispatch(actions.ejecutarAnalisisDeVentas()),
    updateUltimaActualizacion: () =>
      dispatch(actions.updateUltimaActualizacionAnalisisVentas(moment(Date.now()).format('DD/MM/YYYY HH:mm')))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalisisDeVentas);
