import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';
import TablaCobranzasDelDia from '../TablaCobranzasDelDia/TablaCobranzasDelDia';
import { fechaFinPeriodoMesActualFunc, fechaInicioPeriodoMesActualFunc, } from '../../../../shared/fechas';


const TextoCobranzasDelDia = (props) => {
  useEffect(() => {
    if (props.ejecucionDePagina && !props.estaCargado) {
      props.onLoadChart(undefined, props.evolucion);
    }
  }, [props.ejecucionDePagina]);

  const modalContent = () => {
    props.onOpenClienteModal(true, 'COBRANZAS DEL DÍA', <TablaCobranzasDelDia />);
  };

  return (
    <TextChart
      title={`cobranzas del día ${props.empresa ? `${props.empresa.nombre}` : ``}`}
      bigAmount={props.diaActual}
      bigAmountTooltip="Hoy"
      smallAmount={props.promedio30Dias}
      smallAmountTooltip={props.evolucion ? `Período ${fechaInicioPeriodoMesActualFunc()} a ${fechaFinPeriodoMesActualFunc()}`: `Prom. últimos 30 dias`}
      loading={props.loading}
      bigCurrency
      cursorPointer={!props.estaOculto && !props.error}
      smallCurrency
      border={props.border && !props.error}
      imgSinDatos={props.error}
      onFiltersClick={() => props.onShowFiltersModal()}
      onBodyClick={() => modalContent()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.cobranzasDelDia.loading,
    error: state.ventas.cobranzasDelDia.error,
    diaActual: state.ventas.cobranzasDelDia.diaActual,
    promedio30Dias: state.ventas.cobranzasDelDia.promedio30Dias,
    estaCargado: state.ventas.cobranzasDelDia.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa, evolucion) => dispatch(actions.cobranzasDelDia(idEmpresa, evolucion)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Cobranzas del día', ['cobranza'])),
    onOpenClienteModal: (show, title, body) => dispatch(actions.showEstadoModal(show, title, body)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoCobranzasDelDia);
