import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  periodo: {
    fechaDesde: null,
    fechaHasta: null
  },
  vendedores: {
    vendedores: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  sucursales: {
    loading: false,
    error: null,
    sucursales: [],
    seleccionada: null,
    estaCargado: false,
  }
};

//#region loadVendedores
const loadVendedoresStart = (state) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: true
    })
  });
};

const loadVendedoresSuccess = (state, vendedores) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      vendedores,
      estaCargado: true,
      error: null
    })
  });
};

const loadVendedoresFail = (state, error) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region periodo
const seleccionarPeriodo = (state, fechaDesde, fechaHasta) => {
  return updateObject(state, {
    periodo: updateObject(state.periodo, {
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta
    })
  });
};
//#endregion

//#region sucursales
const loadSucursalesStart = (state) => {
  return updateObject(state, {
    sucursales: updateObject(state.sucursales, {
      loading: true,
      error: null,
    })
  });
};

const loadSucursalesSuccess = (state, sucursales) => {
  return updateObject(state, {
    sucursales: updateObject(state.sucursales, {
      loading: false,
      sucursales,
      estaCargado: true,
    })
  });
};

const loadSucursalesFail = (state, error) => {
  return updateObject(state, {
    sucursales: updateObject(state.sucursales, {
      sucursales: [],
      loading: false,
      error: error,
      estaCargado: true,
    })
  });
};

const seleccionarSucursal = (state, sucursal) => {
  return updateObject(state, {
    sucursales: updateObject(state.sucursales, {
      seleccionada: sucursal
    })
  });
};

const clearSucursales = (state) => {
  return updateObject(state, {
    sucursales: updateObject(state.sucursales, {
      sucursales: [],
      estaCargado: false,
      loading: false,
      error: null,
      seleccionada: null
    })
  });
}
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECCIONAR_PERIODO:
      return seleccionarPeriodo(state, action.fechaDesde, action.fechaHasta);

    case actionTypes.LOAD_VENDEDORES_START:
      return loadVendedoresStart(state);
    case actionTypes.LOAD_VENDEDORES_SUCCESS:
      return loadVendedoresSuccess(state, action.vendedores);
    case actionTypes.LOAD_VENDEDORES_FAIL:
      return loadVendedoresFail(state, action.error);

    case actionTypes.LOAD_SUCURSALES_FILTRO_START:
      return loadSucursalesStart(state);

    case actionTypes.LOAD_SUCURSALES_FILTRO_SUCCESS:
      return loadSucursalesSuccess(state, action.sucursales);

    case actionTypes.LOAD_SUCURSALES_FILTRO_FAIL:
      return loadSucursalesFail(state, action.error);

    case actionTypes.SELECCIONAR_SUCURSAL_FILTRO:
      return seleccionarSucursal(state, action.sucursal);
    
    case actionTypes.CLEAR_SUCURSALES_FILTRO:
      return clearSucursales(state);  

    default:
      return state;
  }
};

export default reducer;
