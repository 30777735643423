import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import ColumnChart from '../../../Charts/ColumnChart';

const BarRentabilidadPorEmpresa = (props) => {
  
  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <ColumnChart
      title={props.title}
      labelTooltipSeries={'Monto'}
      subTitle={props.subtitle}
      series={props.rentabilidades.map((rentabilidad) => {
        return { name: rentabilidad.nombreEmpresa, data: [rentabilidad.total] };
      })}
      categories={props.rentabilidades.map((rentabilidad) => rentabilidad.nombreEmpresa)}
      ids={props.rentabilidades.map((rentabilidad) => rentabilidad.id)}
      loading={props.loading}
      imgSinDatos={
        props.rentabilidades.length === 0 || (props.rentabilidades.length === 1 && props.rentabilidades[0].total === 0)
      }
      onPorcionClick={handlePorcionClick}
      disabled={props.estaOculto}
      height={props.height}
      width={'100%'}
      legendFontSize={'14px'}
      legendOffsetY={-5}
      legendShowForSingleSeries
      legendShowForNullSeries
      legendShowForZeroSeries
      legendPosition={'bottom'}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    rentabilidades: state.multiempresa.rentabilidadPorEmpresa.rentabilidades,
    loading: state.multiempresa.rentabilidadPorEmpresa.loading,
    totalRentabilidad: state.multiempresa.rentabilidadPorEmpresa.totalRentabilidad,
    estaCargado: state.multiempresa.rentabilidadPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.rentabilidadMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadRentabilidadPorEmpresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarRentabilidadPorEmpresa);
