// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SunburstVentasPorVendedorPorSeleccion__LabelVerPor__eS-Q1 {\r\n  font-weight: bold;\r\n}\r\n\r\n.SunburstVentasPorVendedorPorSeleccion__SelectRoot__Sa2vj {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LabelVerPor": "SunburstVentasPorVendedorPorSeleccion__LabelVerPor__eS-Q1",
	"SelectRoot": "SunburstVentasPorVendedorPorSeleccion__SelectRoot__Sa2vj"
};
export default ___CSS_LOADER_EXPORT___;
