import React, { useCallback, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import { createTheme } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from '@material-ui/icons/Event';

const useStyles = makeStyles(() => ({
  formControl: {
    fontSize: 'small',
    "& input": {
      fontSize: "small",
      textTransform: 'uppercase'
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
}));

const materialTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
  },
});

const FiltroFechaSelector = (props) => {
  const classes = useStyles();
  const { label, disabled, value, onDateChange, minDate, maxDate, format, views, variant, required, error, helperText } = props;
  const min = minDate ? new moment(minDate) : new moment(new Date(new Date().getFullYear(), 0, 1));
  const max = maxDate ? new moment(new Date(maxDate)) : new moment(new Date(new Date().getFullYear(), 11, 31));
  const [selectedValue, setSelectedValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const onChange = useCallback((value) => {
    setSelectedValue(value);
    let newDate = null;

    if (value && value._d) {
      const date = new Date(value._d);
      newDate = new Date(date.setHours(0, 0, 0, 0));
    }

    onDateChange(newDate);
  }, [onDateChange]);

  const onClearClick = (e) => {
    e.stopPropagation();
    setSelectedValue(null);
    onDateChange(null);
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={"es"}>
        <KeyboardDatePicker
          disableToolbar
          views={views}
          fullWidth={true}
          variant={variant ? variant : "inline"}
          format={format}
          margin="normal"
          id="date-picker-inline"
          label={label}
          value={selectedValue ? new Date(selectedValue) : null}
          onChange={(value) => onChange(value)}
          minDate={min}
          maxDate={max}
          autoOk={true}
          disabled={disabled}
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
          minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          InputProps={{
            style: { fontSize: 'small' },
            className: classes.formControl,
            endAdornment: (selectedValue
              ? (<div onClick={(e) => onClearClick(e)} style={{ marginRight: 20, cursor: "pointer" }}>
                <ClearIcon />
              </div>) : (
                <div onClick={() => setIsOpen(true)} style={{ marginRight: 20, cursor: "pointer" }}>
                  <EventIcon />
                </div>
              )
            )
          }}
          InputLabelProps={{ style: { fontSize: 'small' } }}          
          style={{ marginTop: '4px' }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          autoComplete={'false'}
          required={required}
          error={error}
          helperText={helperText}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default FiltroFechaSelector;