import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  textoChequesPropios: {
    loading: false,
    error: null,
    monto: 0,
    promedioDias: 0,
    estaCargado: false
  },
  textoDeudasPorCompras: {
    loading: false,
    error: null,
    deuda: 0,
    promedioDias: 0,
    estaCargado: false
  },
  pieSaldoAcreedores: {
    loading: false,
    error: null,
    deudaPorPeriodo: [],
    periodos: [],
    total: 0,
    estaCargado: false,
    deudasCompleto: []
  },
  textoPromDiasPagos: {
    loading: false,
    error: null,
    dias: 0,
    total: 0,
    estaCargado: false
  },
  textoPagosDelMes: {
    loading: false,
    error: null,
    mesActual: 0,
    mesAnterior: 0,
    estaCargado: false
  },
  deudasPorCategoriaDeProveedor: {
    loading: false,
    error: null,
    deudas: [],
    totalDeudas: 0,
    estaCargado: false,
    valoresNegativos: []
  },
  comprobantesPagos: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false
  },
  comprobantesCompras: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false
  }
};

//#region texto cheques emitidos
const textoChequesPropiosStart = (state) => {
  return updateObject(state, {
    textoChequesPropios: updateObject(state.textoChequesPropios, {
      loading: true,
      monto: 0,
      promedioDias: 0,
      error: null
    })
  });
};

const textoChequesPropiosSuccess = (state, monto, promedioDias) => {
  return updateObject(state, {
    textoChequesPropios: updateObject(state.textoChequesPropios, {
      loading: false,
      monto: monto,
      promedioDias: promedioDias,
      estaCargado: true
    })
  });
};

const textoChequesPropiosFail = (state, error) => {
  return updateObject(state, {
    textoChequesPropios: updateObject(state.textoChequesPropios, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoChequesPropios = (state, monto, promedioDias) => {
  return updateObject(state, {
    textoChequesPropios: updateObject(state.textoChequesPropios, {
      loading: false,
      error: null,
      monto: 0,
      promedioDias: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region texto deudores por compras
const textoDeudasPorComprasStart = (state) => {
  return updateObject(state, {
    textoDeudasPorCompras: updateObject(state.textoDeudasPorCompras, {
      loading: true,
      error: null,
      deuda: 0,
      promedioDias: 0
    })
  });
};

const textoDeudasPorComprasSuccess = (state, deuda, promedioDias) => {
  return updateObject(state, {
    textoDeudasPorCompras: updateObject(state.textoDeudasPorCompras, {
      loading: false,
      deuda: deuda,
      promedioDias: promedioDias,
      estaCargado: true
    })
  });
};

const textoDeudasPorComprasFail = (state, error) => {
  return updateObject(state, {
    textoDeudasPorCompras: updateObject(state.textoDeudasPorCompras, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoDeudasPorCompras = (state) => {
  return updateObject(state, {
    textoDeudasPorCompras: updateObject(state.textoDeudasPorCompras, {
      loading: false,
      error: null,
      deuda: 0,
      promedioDias: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region pie deudas por compras
const pieSaldoAcreedoresStart = (state) => {
  return updateObject(state, {
    pieSaldoAcreedores: updateObject(state.pieSaldoAcreedores, {
      loading: true,
      error: null,
      deudaPorPeriodo: [],
      periodos: [],
      total: 0
    })
  });
};

const pieSaldoAcreedoresSuccess = (state, deudasCompleto, deudaPorPeriodo, periodos, totalDeudas) => {
  return updateObject(state, {
    pieSaldoAcreedores: updateObject(state.pieSaldoAcreedores, {
      loading: false,
      deudaPorPeriodo: deudaPorPeriodo,
      periodos: periodos,
      total: totalDeudas,
      estaCargado: true,
      deudasCompleto
    })
  });
};

const pieSaldoAcreedoresFail = (state, error) => {
  return updateObject(state, {
    pieSaldoAcreedores: updateObject(state.pieSaldoAcreedores, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearPieSaldoAcreedores = (state) => {
  return updateObject(state, {
    pieSaldoAcreedores: updateObject(state.pieSaldoAcreedores, {
      loading: false,
      error: null,
      deudaPorPeriodo: [],
      periodos: [],
      total: 0,
      estaCargado: false,
      deudasCompleto: []
    })
  });
};
//#endregion

//#region texto Plazo Medio Pago Proveedores
const textoPromDiasPagosStart = (state) => {
  return updateObject(state, {
    textoPromDiasPagos: updateObject(state.textoPromDiasPagos, {
      loading: true,
      error: null,
      dias: 0,
      total: 0
    })
  });
};

const textoPromDiasPagosSuccess = (state, dias, total) => {
  return updateObject(state, {
    textoPromDiasPagos: updateObject(state.textoPromDiasPagos, {
      loading: false,
      dias,
      total,
      estaCargado: true
    })
  });
};

const textoPromDiasPagosFail = (state, error) => {
  return updateObject(state, {
    textoPromDiasPagos: updateObject(state.textoPromDiasPagos, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoPromDiasPagos = (state) => {
  return updateObject(state, {
    textoPromDiasPagos: updateObject(state.textoPromDiasPagos, {
      loading: false,
      error: null,
      dias: 0,
      total: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region texto pagos del mes
const textoPagosDelMesStart = (state) => {
  return updateObject(state, {
    textoPagosDelMes: updateObject(state.textoPagosDelMes, {
      loading: true,
      error: null,
      mesActual: 0,
      mesAnterior: 0
    })
  });
};

const textoPagosDelMesSuccess = (state, mesActual, mesAnterior) => {
  return updateObject(state, {
    textoPagosDelMes: updateObject(state.textoPagosDelMes, {
      loading: false,
      mesActual: mesActual,
      mesAnterior: mesAnterior,
      estaCargado: true
    })
  });
};

const textoPagosDelMesFail = (state, error) => {
  return updateObject(state, {
    textoPagosDelMes: updateObject(state.textoPagosDelMes, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoPagosDelMes = (state) => {
  return updateObject(state, {
    textoPagosDelMes: updateObject(state.textoPagosDelMes, {
      loading: false,
      error: null,
      mesActual: 0,
      mesAnterior: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region load comprobantes de Pagos
const loadComprobantesPagosStart = (state) => {
  return updateObject(state, {
    comprobantesPagos: updateObject(state.comprobantesPagos, {
      loading: true,
      comprobantes: [],
      error: null
    })
  });
};

const loadComprobantesPagosSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPagos: updateObject(state.comprobantesPagos, {
      loading: false,
      comprobantes,
      estaCargado: true
    })
  });
};

const loadComprobantesPagosFail = (state, error) => {
  return updateObject(state, {
    comprobantesPagos: updateObject(state.comprobantesPagos, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load comprobantes de Compras
const loadComprobantesComprasStart = (state) => {
  return updateObject(state, {
    comprobantesCompras: updateObject(state.comprobantesCompras, {
      loading: true,
      comprobantes: [],
      error: null
    })
  });
};

const loadComprobantesComprasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesCompras: updateObject(state.comprobantesCompras, {
      loading: false,
      comprobantes,
      estaCargado: true
    })
  });
};

const loadComprobantesComprasFail = (state, error) => {
  return updateObject(state, {
    comprobantesCompras: updateObject(state.comprobantesCompras, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load deuda por categoria de proveedor
const loadDeudaPorCategoriaDeProveedorStart = (state) => {
  return updateObject(state, {
    deudasPorCategoriaDeProveedor: updateObject(state.deudasPorCategoriaDeProveedor, {
      loading: true,
      error: null,
      deudas: [],
      totalDeudas: 0,
      estaCargado: false,
      valoresNegativos: []
    })
  });
};

const loadDeudaPorCategoriaDeProveedorSuccess = (state, deudas, totalDeudas, valoresNegativos) => {
  return updateObject(state, {
    deudasPorCategoriaDeProveedor: updateObject(state.deudasPorCategoriaDeProveedor, {
      loading: false,
      deudas,
      totalDeudas,
      estaCargado: true,
      valoresNegativos
    })
  });
};

const loadDeudaPorCategoriaDeProveedorFail = (state, error) => {
  return updateObject(state, {
    deudasPorCategoriaDeProveedor: updateObject(state.deudasPorCategoriaDeProveedor, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearDeudaPorCategoriaDeProveedor = (state) => {
  return updateObject(state, {
    deudasPorCategoriaDeProveedor: updateObject(state.deudasPorCategoriaDeProveedor, {
      loading: false,
      error: null,
      deudas: [],
      valoresNegativos: [],
      totalDeudas: 0,
      estaCargado: false
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEXTO_CHEQUES_PROPIOS_START:
      return textoChequesPropiosStart(state);
    case actionTypes.TEXTO_CHEQUES_PROPIOS_SUCCESS:
      return textoChequesPropiosSuccess(state, action.monto, action.promedioDias);
    case actionTypes.TEXTO_CHEQUES_PROPIOS_FAIL:
      return textoChequesPropiosFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_CHEQUES_PROPIOS:
      return clearTextoChequesPropios(state);

    case actionTypes.TEXTO_PLAZO_MEDIO_A_PAGAR_START:
      return textoDeudasPorComprasStart(state);
    case actionTypes.TEXTO_PLAZO_MEDIO_A_PAGAR_SUCCESS:
      return textoDeudasPorComprasSuccess(state, action.deuda, action.promedioDias);
    case actionTypes.TEXTO_PLAZO_MEDIO_A_PAGAR_FAIL:
      return textoDeudasPorComprasFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_PLAZO_MEDIO_A_PAGAR:
      return clearTextoDeudasPorCompras(state);

    case actionTypes.PIE_SALDO_ACREEDORES_START:
      return pieSaldoAcreedoresStart(state);
    case actionTypes.PIE_SALDO_ACREEDORES_SUCCESS:
      return pieSaldoAcreedoresSuccess(
        state,
        action.deudasCompleto,
        action.deudasPorPeriodo,
        action.periodos,
        action.totalDeudas
      );
    case actionTypes.PIE_SALDO_ACREEDORES_FAIL:
      return pieSaldoAcreedoresFail(state, action.error);
    case actionTypes.CLEAR_PIE_SALDO_ACREEDORES:
      return clearPieSaldoAcreedores(state);

    case actionTypes.TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_START:
      return textoPromDiasPagosStart(state);
    case actionTypes.TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_SUCCESS:
      return textoPromDiasPagosSuccess(state, action.dias, action.total);
    case actionTypes.TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_FAIL:
      return textoPromDiasPagosFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES:
      return clearTextoPromDiasPagos(state);

    case actionTypes.TEXTO_PAGOS_DEL_MES_START:
      return textoPagosDelMesStart(state);
    case actionTypes.TEXTO_PAGOS_DEL_MES_SUCCESS:
      return textoPagosDelMesSuccess(state, action.mesActual, action.mesAnterior);
    case actionTypes.TEXTO_PAGOS_DEL_MES_FAIL:
      return textoPagosDelMesFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_PAGOS_DEL_MES:
      return clearTextoPagosDelMes(state);

    case actionTypes.LOAD_COMPROBANTES_PAGOS_START:
      return loadComprobantesPagosStart(state);
    case actionTypes.LOAD_COMPROBANTES_PAGOS_SUCCESS:
      return loadComprobantesPagosSuccess(state, action.comprobantesPagos);
    case actionTypes.LOAD_COMPROBANTES_PAGOS_FAIL:
      return loadComprobantesPagosFail(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_COMPRAS_START:
      return loadComprobantesComprasStart(state);
    case actionTypes.LOAD_COMPROBANTES_COMPRAS_SUCCESS:
      return loadComprobantesComprasSuccess(state, action.comprobantesCompras);
    case actionTypes.LOAD_COMPROBANTES_COMPRAS_FAIL:
      return loadComprobantesComprasFail(state, action.error);

    case actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_START:
      return loadDeudaPorCategoriaDeProveedorStart(state);
    case actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_SUCCESS:
      return loadDeudaPorCategoriaDeProveedorSuccess(state, action.deudas, action.totalDeudas, action.valoresNegativos);
    case actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_FAIL:
      return loadDeudaPorCategoriaDeProveedorFail(state, action.error);
    case actionTypes.CLEAR_DEUDA_POR_CATEGORIA_DE_PROVEEDOR:
      return clearDeudaPorCategoriaDeProveedor(state);

    default:
      return state;
  }
};

export default reducer;
