import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import LineChart from '../../../Charts/LineChart';

const LineEvolucionVentasVendedores = (props) => {
  return (
    <LineChart
      title={'Evolución de ventas por mes'}
      textoTooltip={'Monto'}
      categories={
        props.ventas &&
        props.ventas.map((venta) => {
          return new Date(venta.anio, venta.mes - 1, 1);
        })
      }
      series={props.ventas && props.ventas.map((venta) => venta.monto)}
      width={props.width}
      loading={props.loading}
      imgSinDatos={props.ventas.length === 0}
      showXLabels
      labelsRotateAlways
      onFiltersClick={() => props.onShowFiltersModal()}
      height={370}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.vendedores.evolucionVentasMensualDeVendedores.ventas,
    loading: state.vendedores.evolucionVentasMensualDeVendedores.loading,
    fechaPieVentas: state.vendedores.evolucionVentasMensualDeVendedores.fecha,
    totalVentas: state.vendedores.evolucionVentasMensualDeVendedores.totalVentas,
    estaCargado: state.vendedores.evolucionVentasMensualDeVendedores.estaCargado
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Evolución de ventas por mes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionVentasVendedores);
