import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClickSobreCategoriaDelGrafico from '../../../../assets/images/ClickSobreCategoriaDelGrafico.png';

import classes from './TablaComparacionVentasEntreSucursalesPorSeleccion.css';
import { MTableBodyRow } from 'material-table';
import TablaComparacionVentasDeSucursalesDelClientePorArticulo from '../TablaComparacionVentasDeSucursalesDelClientePorArticulo/TablaComparacionVentasDeSucursalesDelClientePorArticulo';
import TablaComparacionVentasDeSucursalesDelArticuloPorCliente from '../TablaComparacionVentasDeSucursalesDelArticuloPorCliente/TablaComparacionVentasDeSucursalesDelArticuloPorCliente';

const TablaComparacionVentasEntreSucursalesPorSeleccion = (props) => {
  const { sucursalesComparar, fechaComparar, dataSelectionId } = props;
  const [tableState, setTableState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });

  const inCategoriaDeCliente = dataSelectionId === 'categoriaDeCliente';
  const inZona = dataSelectionId === 'zona';
  const inProvincia = dataSelectionId === 'provincia';

  const verPorClientes = inCategoriaDeCliente || inZona || inProvincia;

  const registerType = verPorClientes ? 'Cliente' : 'Artículo';

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      sucursalesComparar.map((sucursal) => {
        const separarFecha = moment(fechaComparar).subtract(1, 'month').format('MM/YYYY').split('/');
        const mes = separarFecha[0];
        const anio = separarFecha[1];
        return props[props.loadDetallesKey](
          sucursal.id,
          props.categoria.id,
          mes,
          anio,
          (data) => {
            if (tableState.length === 1) {
              armarComparacion(data);
              setLoading(false);
            } else {
              tableState.push(data);
            }
            setTimeout(
              () =>
                window.scroll({
                  top: document.body.offsetHeight,
                  left: 0,
                  behavior: 'smooth'
                }),
              100
            );
          },
          (error) => {
            setLoading(false);
          }
        );
      });
    }, 500);
  }, []);

  const columns = [
    {
      title: registerType,
      field: 'item',
      render: registerType === "Artículo" ? (rowData) => rowData.itemId.trim() + " - " + rowData.item.trim() : (rowData) => rowData.item,
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos `,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];

    tableState[0].forEach((ventasCompleto) => {
      const verify = arrayAComparar.filter((gasto) => gasto.nombre === ventasCompleto.nombre);
      if (verify.length === 0) {
        arrayNuevo.push({ ...ventasCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((gasto) => {
      return arrayNuevo.push(gasto);
    });

    arrayAComparar.forEach((ventasOrdenadas) => {
      const verify = tableState[0].filter((venta) => venta.nombre === ventasOrdenadas.nombre);

      if (verify.length === 0) {
        tableState[0].push({ ...ventasOrdenadas, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));
    setTableState([
      { dateComplete: fechaComparar, ventasDetallada: orderTableState },
      { dateComplete: fechaComparar, ventasDetallada: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];

    tableState.map((data, key) => {
      if (key === 0) {
        return data.ventasDetallada.map((ventaDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].ventasDetallada[index];
          const ventasDelIndex = ventasDetalldasDelIndex
            ? tableState[1].ventasDetallada[index]
            : { mes: 0, anio: 0, id: '0', nombre: 'undefined', monto: 0, porcentajeDeParticipacion: 0 };

          return formatTableData.push({
            itemId: ventaDetallada.id,
            item: ventaDetallada.nombre,
            monto1: ventaDetallada.monto,
            porcParticipacion1: ventaDetallada.porcentajeDeParticipacion,
            monto2: ventasDelIndex.monto,
            porcParticipacion2: ventasDelIndex.porcentajeDeParticipacion,
            difMontos: calcularDif(ventaDetallada.monto, ventasDelIndex.monto),
            porcComparacion: calcularPorc(ventaDetallada.monto, ventasDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sucursalesComparar.length === 2) {
      if (sortConfig.key === sortKey) {
        setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
      } else {
        setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
      }
    }
  };

  const compareByAsc = (a, b, key) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
  };

  const compareByDesc = (a, b, key) => {
    if (a[key] < b[key]) return 1;
    if (a[key] > b[key]) return -1;
  };

  const headersBackgroundColor = 'rgb(235, 235, 235)';

  const tableRef = useRef();

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        {/*<TextField
          id="input-with-icon-textfield"
          placeholder="Buscar"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />*/}
        {/*!loading ? (
          <TablaComparacionVentasToExcel
            ventasPor={registerType()}
            comprobantes={data()}
            fechas={[
              moment(fechasComparar[0]).subtract(1, 'month').format('MM/YYYY'),
              moment(fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')
            ]}
          />
        ) : (
          <div></div>
        )*/}
      </div>
      {sucursalesComparar.length !== 2 ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="" src={ClickSobreCategoriaDelGrafico} width={400} />
        </div>
      ) : (
        <Table
          style={{ paddingLeft: '20px' }}
          columns={columns}
          tableRef={tableRef}
          data={data()}
          noBoxShadow
          loading={loading}
          components={{
            Header: (props) => {
              return (
                <TableHead>
                  {sucursalesComparar.length === 2 && (
                    <TableRow>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                        align="left"
                      ></TableCell>

                      <TableCell
                        style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                      ></TableCell>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px'
                        }}
                        colSpan={2}
                        align="center"
                      ></TableCell>
                      <TableCell
                        style={{
                          paddingBottom: '5px',
                          paddingTop: '5px',
                          paddingLeft: '60px'
                        }}
                        colSpan={2}
                        align="center"
                      ></TableCell>
                      <TableCell
                        style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                        align="center"
                      ></TableCell>
                      <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                      align="left"
                    ></TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('item')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        width: window.screen.width < 1367 ? '265px' : '410px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="left"
                    >
                      {registerType}
                    </TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('monto1')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcParticipacion1')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('monto2')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcParticipacion2')}
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: headersBackgroundColor
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('difMontos')}
                      style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      className={sucursalesComparar.length === 2 ? classes.SorteableHeaderTitle : classes.HeaderTitle}
                      onClick={() => onSort('porcComparacion')}
                      style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>
              );
            },
            Row: (props) => {
              return (
                <MTableBodyRow
                  {...props}
                  detailPanel={
                    !props.data.itemId
                      ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                      : props.detailPanel
                  }
                />
              );
            }
          }}
          detailPanel={[
            {
              disabled: false,
              tooltip: verPorClientes ? 'Mostrar Comprobantes' : 'Mostrar ventas por cliente',
              render: (rowData) => {
                return (
                  <div className={classes.TablasComparacionComprobantesContainer}>
                    {verPorClientes ? (
                      <TablaComparacionVentasDeSucursalesDelClientePorArticulo
                        sucursalesComparar={sucursalesComparar}
                        fechaComparar={fechaComparar}
                        clienteId={rowData.itemId}
                      />
                    ) : (
                      <TablaComparacionVentasDeSucursalesDelArticuloPorCliente
                        sucursalesComparar={sucursalesComparar}
                        fechaComparar={fechaComparar}
                        articuloId={rowData.itemId}
                      />
                    )}
                  </div>
                );
              }
            }
          ]}
          customOptions={{
            grouping: false,
            draggable: false,
            search: false,
            paging: false,
            maxBodyHeight: 400,
            showTitle: false,
            tableLayout: 'fixed',
            toolbar: false,
            rowStyle: (rowData, config) => {
              return {
                cursor: 'auto',
                backgroundColor: (!rowData.itemId && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
              };
            },
            cellStyle: (cellData) => ({
              fontWeight: cellData === 'Total' && 'bolder'
            })
          }}
          tableKey={"tabla-comparacion-ventas-sucursales-seleccion"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vendedorSelected: state.vendedores.vendedorSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDetallesProvincia: (sucursalId, provinciaId, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDeLaProvincia(mes, anio, sucursalId, provinciaId, success, errorFail)
      ),

    onLoadDetallesZona: (sucursalId, zonaId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDeLaSucursalPorClienteDeLaZona(mes, anio, sucursalId, zonaId, success, errorFail)),

    onLoadDetallesaMarca: (sucursalId, marcaId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDeLaSucursalPorArticuloDeLaMarca(mes, anio, sucursalId, marcaId, success, errorFail)),

    onLoadDetallesRubro: (sucursalId, rubroId, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDeLaSucursalPorArticuloDelRubro(mes, anio, sucursalId, rubroId, success, errorFail)),

    onLoadDetallesSubrubro: (sucursalId, subrubroId, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorArticuloDelSubrubro(mes, anio, sucursalId, subrubroId, success, errorFail)
      ),

    onLoadDetallesCategoriaDeCliente: (sucursalId, categoriaDeClienteId, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDeLaCategoria(
          mes,
          anio,
          sucursalId,
          categoriaDeClienteId,
          success,
          errorFail
        )
      ),

    onLoadDetallesVendedor: (sucursalId, subrubroId, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDelVendedor(mes, anio, sucursalId, subrubroId, success, errorFail)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionVentasEntreSucursalesPorSeleccion);
