import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Spinner from '../Spinner/Spinner';
import { storageClienteId } from '../../../shared/sessionData';

const ClienteSelector = (props) => {
  useEffect(() => {
    if (props.clientes.length === 0) {
      props.onLoadClientes(() => {
        props.onSeleccionarCliente(storageClienteId());
      });
    }
  }, []);

  return props.loading ? (
    <Spinner size="small" />
  ) : (
    <FormControl style={{ margin: '0px 10px 0px 10px' }}>
      <InputLabel id="empresaInputLabel">Clientes</InputLabel>
      <Select
        labelId="clienteLabel"
        id="clienteSelect"
        value={props.clienteSelectedId ? props.clienteSelectedId : storageClienteId()}
        onChange={(event) => {
          props.onSeleccionarCliente({ clienteIdPg: event.target.value });
          if (props.reloadTiemposRequest) {
            props.onReLoadTiemposRequest(event.target.value);
          }
        }}
      >
        {props.clientes.map((cliente) => (
          <MenuItem key={cliente.id} value={cliente.id}>
            {cliente.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.clientes.clientes.loading,
    clientes: state.clientes.clientes.clientes,
    clienteSelectedId: state.clientes.clientSelected.clienteIdPg
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadClientes: (success) => dispatch(actions.loadClientes(success)),
    onSeleccionarCliente: (cliente) => dispatch(actions.clientSelected(cliente)),
    onReLoadTiemposRequest: (clienteId) => dispatch(actions.loadTiemposRequest(clienteId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClienteSelector);
