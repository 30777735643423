import React from 'react';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './Styles/BigChartCard.css';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import { colorsChart } from '../../shared/colorsCharts';

const CustomMixedChart = (props) => {
  const {
    chartRef
  } = props;
  
  const options = {
    ...props.customOptions,
    colors: props.colors ? props.colors : colorsChart
  };

  return (
    <div
      ref={chartRef}
      className={classes.BigChartCard}
      style={{ width: props.width, minHeight: props.loading && '240px', boxShadow: props.disableBoxShadow && 'none' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          marginLeft: '20px'
        }}
      >
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%' }}>{props.title}</div>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.subTitle && (
          <p style={{ marginTop: '1px', fontSize: '12px', marginBottom: '-12px' }}>{props.subTitle}</p>
        )}
        {props.children}
      </div>
      {props.loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner progress={props.progressLoading} />
        </div>
      ) : props.disabled ? (
        <div style={{ maxHeight: '245px' }}>
          <img alt="Indicador Inhabilitado" src={IndicadorDeshabilitado} />
        </div>
      ) : props.imgSinDatos || props.noClient ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="Sin resultados" src={SinResultados} style={{ maxWidth: '100%' }} />
        </div>
      ) : (
        <Chart
          options={options}
          series={props.series}
          height={props.chartHeight}
          type={props.chartType}
          width={props.chartWidth}
        />
      )}
    </div>
  );
};

export default CustomMixedChart;
