import React, { useState, Fragment, useEffect, useRef } from 'react';
import * as actions from '../../../../../store/actions';

import { formatNumber } from '../../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../../shared/sessionData';
import CustomMixedChart from '../../../../Charts/CustomMixedChart';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Dialog from '../../../../UI/Dialog/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import ViewInTable from '../../../../../assets/images/ViewInTable.svg';
import AlignHorizontalChart from '../../../../../assets/images/AlignHorizontalChart.svg';
import AlignVerticalChart from '../../../../../assets/images/AlignVerticalChart.svg';
import TableUtilidadesToExcel from '../TablesUtilidades/TableUtilidadesToExcel';
import FilterListIcon from '@material-ui/icons/FilterList';
import ConfigChartIcon from '../../../../../assets/images/IconConfigChart.png';
import { enviarPorMailOption } from '../../../../UI/EnviarChart/EnviarChartOptions';

import classes from './BarUtilidadesTooltip.css';
import { connect } from 'react-redux';
import ModalUtilidadesConfigBody from '../ModalUtilidadesConfig/ModalUtilidadesConfigBody';

const BarUtilidadesChartBase = (props) => {
  let chartRef = useRef(null);

  const [ordenSelected, setOrdenSelected] = useState('monto');
  const [showDialog, setShowDialog] = useState(false);
  const [inHorizontalView, setInHorizontalView] = useState(false);
  const [loadingHorizontal, setLoadingHorizontal] = useState(false);
  const [utilidadesLocal, setUtilidadesLocal] = useState([]);

  useEffect(() => {
    setUtilidadesLocal(
      props.utilidades
        .filter(props.disabledOcultar ? (util) => util : (util) => util.estaOculto === false)
        .sort((a, b) => {
          return a[ordenSelected] > b[ordenSelected] ? -1 : 1;
        })
    );
  }, [props.utilidades, loadingHorizontal, ordenSelected]);

  const options = {
    series: [
      {
        name: 'Costo',
        data: loadingHorizontal ? [] : utilidadesLocal.map((utilidad) => utilidad.costo)
      },
      {
        name: 'Utilidad',
        data: loadingHorizontal ? [] : utilidadesLocal.map((utilidad) => utilidad.monto)
      }
    ],
    options: {
      chart: {
        type: 'bar',

        dropShadow: {
          enabled: true,
          color: '#000',
          top: 5,
          left: 3,
          blur: 7,
          opacity: 0.2
        },
        height: props.customHeight ? props.customHeight : 350,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            customIcons: [
              enviarPorMailOption(chartRef, `Análisis por ${props.utilidadPor}`),
            ]
          }
        },
        zoom: {
          enabled: true,
          type: 'y',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div class=${classes.TooltipContainer}>
              <div class=${classes.Title}>${utilidadesLocal[dataPointIndex].nombre}</div>
              <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              <div class=${classes.TextoContainer}>
                <div
                  class=${classes.Circle}
                  style="background: #FEB019;"
                ></div>
                <span class=${classes.Texto}>
                  Ventas totales: ${storageMonedaSigno()} ${formatNumber(utilidadesLocal[dataPointIndex].ventas)} 
                </span>
                </div>
                <div class=${classes.TextoContainer}>
                <div
                  class=${classes.Circle}
                  style="background: #FEE185;"
                ></div>
                <span class=${classes.Texto}>
                  Cantidades vendidas: ${utilidadesLocal[dataPointIndex].unidadesVendidas} uds.
                </span>
                </div>
              <div class=${classes.TextoContainer}>              
                <div
                  class=${classes.Circle}
                  style="background: #008FFB;"
                ></div>
                <span class=${classes.Texto}>
                  Costo: ${storageMonedaSigno()} ${formatNumber(utilidadesLocal[dataPointIndex].costo)}
                </span>
                </div>
                <div class=${classes.TextoContainer}>
                <div
                  class=${classes.Circle}
                  style="background: #00E396;"
                ></div>
                <span class=${classes.Texto}>
                  Utilidad: ${storageMonedaSigno()} ${formatNumber(utilidadesLocal[dataPointIndex].monto)} 
                </span>
                </div>                
                <div class=${classes.TextoContainer}>
                <div
                  class=${classes.Circle}
                  style="background: #FF4560;"
                ></div>
                <span class=${classes.Texto}>
                  Utilidad segun costo: ${formatNumber(utilidadesLocal[dataPointIndex].utilidadPorcentualSegunCosto)}%
                </span>
                </div>
                <div class=${classes.TextoContainer}>
                <div
                  class=${classes.Circle}
                  style="background: #775DD0;"
                ></div>
                <span class=${classes.Texto}>
                  Utilidad segun ventas: ${formatNumber(utilidadesLocal[dataPointIndex].utilidadPorcentualSegunVentas)}%
                </span>
                </div>
              </div>
            </div>
          `;
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: inHorizontalView
        }
      },
      xaxis: {
        categories: utilidadesLocal.map((utilidad) => utilidad.nombre),
        tooltip: {
          enabled: false
        },
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          trim: true,
          maxHeight: 120,
          formatter: inHorizontalView
            ? function (val, opts) {
                return inHorizontalView && opts !== undefined && opts.dataPointIndex >= 0
                  ? utilidadesLocal[opts.dataPointIndex].nombre
                  : `${storageMonedaSigno()} ${formatNumber(val)}`;
              }
            : undefined
        },
        tickPlacement: !inHorizontalView ? 'on' : undefined
      },
      yaxis: {
        categories: inHorizontalView
          ? props.categories.filter((util) => util.estaOculto === false)
          : utilidadesLocal.filter((util) => util.estaOculto === false),
        showAlways: false,
        forceNiceScale: true,
        labels: {
          minWidth: !inHorizontalView ? undefined : 130,
          formatter: !inHorizontalView
            ? function (val, opts) {
                return inHorizontalView && opts !== undefined && opts.dataPointIndex >= 0
                  ? utilidadesLocal[opts.dataPointIndex].nombre
                  : `${storageMonedaSigno()} ${formatNumber(val)}`;
              }
            : undefined
        }
      },
      legend: {
        position: 'bottom'
      },
      fill: {
        opacity: 1
      }
    }
  };

  const handleHorizontalViewChange = (view) => {
    setLoadingHorizontal(true);
    setInHorizontalView(view);
    setTimeout(function () {
      setLoadingHorizontal(false);
    }, 1000);
  };

  const wrapperTitleIcons = (icon, titleTooltip, click) => {
    return (
      <Tooltip title={titleTooltip}>
        <IconButton size="small" style={{ marginRight: '10px' }} onClick={() => click()}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  };

  const armarTitleConFiltros = () => {
    const title = props.disabledTitle ? '' : `Análisis por ${props.utilidadPor}`;
    return (
      <Grid container
        direction='row'
        alignItems='center'
        style={{ justifyContent: 'space-between' }}        
      >
        <Grid item xs={12} sm={12} md={6} lg={3}>{title}</Grid>
        {!props.disabled && props.utilidades !== 0 && !props.loading && (
          <Grid item xs={12} sm={12} md={6} lg={4}
            container
            direction='row'
            alignItems='center'
            style={{ justifyContent: 'space-between' }}  
          >
            <Grid item md={6}
              style={{ display: 'flex', alignItems: 'baseline', fontWeight: 400 }}>
              <div>Ordenar por:</div>
              <Select
                input={<Input classes={{ input: classes.SelectRoot }} />}
                style={{ marginLeft: '5px' }}
                value={ordenSelected}
                onChange={(e) => setOrdenSelected(e.target.value)}
              >
                <MenuItem dense value={'unidadesVendidas'}>
                  Cantidad
                </MenuItem>
                <MenuItem dense value={'costo'}>
                  Costo
                </MenuItem>
                <MenuItem dense value={'monto'}>
                  Utilidad
                </MenuItem>
                <MenuItem dense value={'ventas'}>
                  Ventas
                </MenuItem>
              </Select>
            </Grid>
            <Grid item md={6}
              container
              direction='row'
              alignItems='center'
              style={{ justifyContent: 'space-between', width: 'auto' }}  
            >
              <Grid item md={3}>
                {wrapperTitleIcons(<img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />, 'Ver en tabla', () =>
                  setShowDialog(true)
                )}
              </Grid>
              <Grid item md={3}>
                {wrapperTitleIcons(
                  <img
                    style={{ opacity: 0.5 }}
                    alt=""
                    src={inHorizontalView ? AlignVerticalChart : AlignHorizontalChart}
                  />,
                  inHorizontalView ? 'Mostrar grafico vertical' : 'Mostrar grafico horizontal',
                  () => handleHorizontalViewChange(!inHorizontalView)
                )}
              </Grid>
              {!props.disabledOcultar &&
                <Grid item md={3}>
                  {wrapperTitleIcons(
                    <img style={{ opacity: 0.5 }} alt="" src={ConfigChartIcon} />,
                    `Configurar ${props.utilidadPor}s ${
                      props.utilidadPor[props.utilidadPor.length - 1] === 'a' ? 'activas' : 'activos'
                    }`,
                    () =>
                      props.onShowConfigChartModal(
                        true,
                        title,
                        <ModalUtilidadesConfigBody
                          utilidades={props.utilidades}
                          changeUtilidadesConfig={(newUtilidades) => onChangeUtilidadesConfig(newUtilidades)}
                          utilidadPor={props.utilidadPor}
                        />
                      )
                  )}
                </Grid>}              
              <Grid item md={3}>
                {wrapperTitleIcons(
                  <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />,
                  'Ver filtros del indicador',
                  () => props.onShowFiltersModal(`Análisis por ${props.utilidadPor}`)
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const onChangeUtilidadesConfig = (newUtilidades) => {
    setLoadingHorizontal(true);
    props.onChangeUtilOcultas(newUtilidades);
    props.onShowConfigChartModal(false, '', '', '');
    setTimeout(function () {
      setLoadingHorizontal(false);
    }, 1000);
  };

  return (
    <Fragment>
      <CustomMixedChart
        title={armarTitleConFiltros()}
        subTitle={props.total && `Total: ${storageMonedaSigno()} ${formatNumber(props.total)}`}
        customOptions={options.options}
        chartType="bar"
        disableBoxShadow={props.disableBoxShadow}
        series={options.series}
        imgSinDatos={props.chartSinDatos || utilidadesLocal.length === 0}
        loading={props.loading || loadingHorizontal}
        chartHeight={props.customHeight ? props.customHeight : 300}
        chartWidth={'99.9%'}
        chartRef={chartRef}
      />
      <Dialog
        title={
          <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
            <div className={classes.TitleDialogTexto}>
              <Typography variant="h5">{props.disabledTitle ? '' : `Análisis por ${props.utilidadPor}`}</Typography>
              <div>
                <TableUtilidadesToExcel utilidades={props.utilidades} utilidadPor={props.utilidadPor} />
                <IconButton aria-label="close" onClick={() => setShowDialog(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        }
        fullWidth
        show={showDialog}
        body={props.utilidadTable}
        onClose={() => setShowDialog(false)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta'])),
    onShowConfigChartModal: (show, title, body, footer) =>
      dispatch(actions.showGlobalModal(show, title, body, footer, undefined))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarUtilidadesChartBase);
