// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaRentabilidadEvolutivo__TableRentabilidadCell__uR9rD {\r\n  padding-top: 5px !important;\r\n  padding-bottom: 5px !important;\r\n}\r\n\r\n.TablaRentabilidadEvolutivo__TableRentabilidadCellWithClick__i5cu2 {\r\n  transition: 0.5s;\r\n  cursor: pointer;\r\n}\r\n\r\n.TablaRentabilidadEvolutivo__TableRentabilidadCellWithClick__i5cu2:hover {\r\n  background-color: gray;\r\n  transition: 0.5s;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableRentabilidadCell": "TablaRentabilidadEvolutivo__TableRentabilidadCell__uR9rD",
	"TableRentabilidadCellWithClick": "TablaRentabilidadEvolutivo__TableRentabilidadCellWithClick__i5cu2"
};
export default ___CSS_LOADER_EXPORT___;
