import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaVentasDeLaSucursalPorClienteDeLaSeleccion.css';
import TablaVentasDeLaSucursalPorArticuloDelCliente from '../TablaVentasDeLaSucursalPorArticuloDelCliente/TablaVentasDeLaSucursalPorArticuloDelCliente';

const TablaVentasDeLaSucursalPorClienteDeLaSeleccion = (props) => {
  const { dataSelectedId, clientesPrimero } = props;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes ? props.mes : props.itemExpanded.mes;
  const anio = props.anio ? props.anio : props.itemExpanded.anio;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      onLoadKey: 'onLoadVentasPorClienteDeLaCategoria'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadVentasPorClienteDelVendedor'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadVentasPorClienteDeLaProvincia'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadVentasPorClienteDeLaZona'
    }
  };

  useEffect(() => {
    setLoading(true);
    props[dataOptions[dataSelectedId].onLoadKey](mes, anio, props.sucursalId, props.itemExpanded.id, (response) => {
      setVentasLocal(response);
      setLoading(false);
      props.endRequest && props.endRequest(response.length);
    });
  }, []);

  const columns = [
    {
      title: 'Cliente',
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion
      };
    });
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.tablaVentasPorClienteDeLaSeleccion}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        columns={columns}
        loading={loading}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return (
            <TablaVentasDeLaSucursalPorArticuloDelCliente
              endRequest={() => autoScroll(rowData)}
              sucursalId={props.sucursalId}
              clienteId={rowData.id}
              mes={mes}
              anio={anio}
            />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla--ventas-sucursal-cliente-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fechaVentasDelMes: state.ventas.fechaVentasDelMes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorClienteDeLaCategoria: (mes, anio, sucursalId, categoriaId, success, failError) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDeLaCategoria(mes, anio, sucursalId, categoriaId, success, failError)
      ),

    onLoadVentasPorClienteDelVendedor: (mes, anio, sucursalId, vendedorId, success, failError) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDelVendedor(mes, anio, sucursalId, vendedorId, success, failError)
      ),

    onLoadVentasPorClienteDeLaProvincia: (mes, anio, sucursalId, provinciaId, success, failError) =>
      dispatch(
        actions.loadVentasDeLaSucursalPorClienteDeLaProvincia(mes, anio, sucursalId, provinciaId, success, failError)
      ),

    onLoadVentasPorClienteDeLaZona: (mes, anio, sucursalId, zonaId, success, failError) =>
      dispatch(actions.loadVentasDeLaSucursalPorClienteDeLaZona(mes, anio, sucursalId, zonaId, success, failError))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDeLaSucursalPorClienteDeLaSeleccion);
