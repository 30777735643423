import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import BarChart from '../../../Charts/BarChart';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import ViewInTable from '../../../../assets/images/ViewInTable.svg';
import Dialog from '../../../UI/Dialog/Dialog';
import BarVentasDeSucursalesPorSeleccionToTable from './BarVentasDeSucursalesPorSeleccionToTable';
import classes from './BarVentasDeSucursalesPorSeleccion.css';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber } from '../../../../shared/formatNumber';

const BarVentasDeSucursalesPorSeleccion = (props) => {
  const { fechaVentasDelMes, dataSelectedId } = props;
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (!props[dataOptions()[dataSelectedId].stateKey].estaCargado && props.ejecucionDePagina) {
      props[dataOptions()[dataSelectedId].onLoadKey](fechaVentasDelMes.mes, fechaVentasDelMes.anio);
    }
  }, [dataSelectedId, props.ejecucionDePagina]);

  const dataOptions = (item) => {
    return {
      categoriaDeCliente: {
        title: 'Categoría de cliente',
        stateKey: 'ventasPorCategoriaDeCliente',
        onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
      },
      marca: {
        title: 'Marca',
        stateKey: 'ventasPorMarca',
        onLoadKey: 'onLoadVentasPorMarca'
      },
      rubro: {
        title: 'Rubro',
        stateKey: 'ventasPorRubro',
        onLoadKey: 'onLoadVentasPorRubro'
      },
      subrubro: {
        title: 'Subrubro',
        stateKey: 'ventasPorSubrubro',
        onLoadKey: 'onLoadVentasPorSubrubro'
      },
      vendedor: {
        title: 'Vendedor',
        stateKey: 'ventasPorVendedor',
        onLoadKey: 'onLoadVentasPorVendedor'
      },
      provincia: {
        title: 'Provincia',
        stateKey: 'ventasPorProvincia',
        onLoadKey: 'onLoadVentasPorProvincia'
      },
      zona: {
        title: 'Zona',
        stateKey: 'ventasPorZona',
        onLoadKey: 'onLoadVentasPorZona'
      }
    };
  };

  const data = () => {
    return props[dataOptions()[dataSelectedId].stateKey].sucursales.map((sucursal) => {
      return {
        name: sucursal.nombre,
        data: sucursal.ventas.map((ventas) => {
          return ventas.monto;
        })
      };
    });
  };

  const categorias = () => {
    const exist = props[dataOptions()[dataSelectedId].stateKey].sucursales.length !== 0;
    return (
      exist &&
      props[dataOptions()[dataSelectedId].stateKey].sucursales[0].ventas.map((venta) => {
        return venta.nombre.trim().split(' ');
      })
    );
  };

  const buildCustomTooltip = (series, seriesIndex, dataPointIndex, w) => {
    const categories = categorias();
    const selectCategory = categories[dataPointIndex];

    return `<div class=${classes.TooltipContainer}>
      <div class=${classes.Title}>${selectCategory.length === 1 ? selectCategory : selectCategory.join(' ')}</div>
      <div class=${classes.Divider}></div>
              <div class=${classes.Body}>
              ${w.config.series
                .map((vendedor) => {
                  return `
                  <div class=${classes.TextoContainer}>
                    <div
                      class=${classes.Circle}
                      style="background: ${w.config.colors[w.globals.seriesNames.indexOf(vendedor.name)]};"
                    ></div>
                      <div class=${classes.Texto}>
                      <span>
                      ${vendedor.name}:
                      </span>
                      <span>
                      ${storageMonedaSigno()} ${formatNumber(vendedor.data[dataPointIndex])} 
                      </span>
                      </div>
                    </div>
                `;
                })
                .join('')}
              </div>
      </div>
      `;
  };

  return (
    <Fragment>
      <BarChart
        title={
          <div style={{ opacity: 0.5, marginRight: '10px' }}>
            {!props[dataOptions()[dataSelectedId].stateKey].loading && (
              <Tooltip title={'Ver en tabla'}>
                <IconButton size={'small'} onClick={() => setShowDialog(true)}>
                  <img alt="" src={ViewInTable} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
        titleJustifyContent={'flex-end'}
        series={data()}
        categories={categorias()}
        loading={props[dataOptions()[dataSelectedId].stateKey].loading}
        chartTextAlign={props[dataOptions()[dataSelectedId].stateKey].loading ? 'center' : 'left'}
        chartStacked
        height={400}
        noBoxShadow
        gridShow
        xaxisTickPlacement
        tooltipEnabled
        tooltipCustom={(series, seriesIndex, dataPointIndex, w) =>
          buildCustomTooltip(series, seriesIndex, dataPointIndex, w)
        }
      />
      <Dialog
        title={
          <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
            <div className={classes.TitleDialogTexto}>
              <Typography variant="h5">{`Ventas por sucursal por ${dataOptions()[
                dataSelectedId
              ].title.toLowerCase()} del ${fechaVentasDelMes.mes}/${fechaVentasDelMes.anio}`}</Typography>
              <div>
                <IconButton aria-label="close" onClick={() => setShowDialog(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        }
        fullWidth
        show={showDialog}
        body={
          <BarVentasDeSucursalesPorSeleccionToTable
            datas={data()}
            categories={categorias()}
            ventasPor={dataOptions()[dataSelectedId].title}
            fecha={fechaVentasDelMes}
          />
        }
        onClose={() => setShowDialog(false)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.sucursales.dataVentasDeSucursalesPorSeleccion,

    fechaVentasDelMes: state.sucursales.fechaVentasDelMesAnioDeSucursales,

    ventasPorCategoriaDeCliente: state.sucursales.ventasPorSucursalPorCategoriaDeClienteDelMes,

    ventasPorMarca: state.sucursales.ventasPorSucursalPorMarcaDelMes,
    ventasPorRubro: state.sucursales.ventasPorSucursalPorRubroDelMes,
    ventasPorSubrubro: state.sucursales.ventasPorSucursalPorSubrubroDelMes,

    ventasPorProvincia: state.sucursales.ventasPorSucursalPorProvinciaDelMes,
    ventasPorZona: state.sucursales.ventasPorSucursalPorZonaDelMes,

    ventasPorVendedor: state.sucursales.ventasPorSucursalPorVendedorDelMes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorCategoriasDeCliente: (mes, anio) =>
      dispatch(actions.loadVentasPorSucursalPorCategoriaDeClienteDelMes(mes, anio)),

    onLoadVentasPorMarca: (mes, anio) => dispatch(actions.loadVentasPorSucursalPorMarcaDelMes(mes, anio)),

    onLoadVentasPorRubro: (mes, anio) => dispatch(actions.loadVentasPorSucursalPorRubroDelMes(mes, anio)),

    onLoadVentasPorSubrubro: (mes, anio) => dispatch(actions.loadVentasPorSucursalPorSubrubroDelMes(mes, anio)),

    onLoadVentasPorVendedor: (mes, anio) => dispatch(actions.loadVentasPorSucursalPorVendedorDelMes(mes, anio)),

    onLoadVentasPorProvincia: (mes, anio) => dispatch(actions.loadVentasPorSucursalPorProvinciaDelMes(mes, anio)),

    onLoadVentasPorZona: (mes, anio) => dispatch(actions.loadVentasPorSucursalPorZonaDelMes(mes, anio)),

    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Ventas detalladas por categoría de cliente', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarVentasDeSucursalesPorSeleccion);
