import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import ColumnChart from '../../../Charts/ColumnChart';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SeleccionePeriodos from '../../../../assets/images/SeleccionePeriodos.png';
import TablaComparacionVentasPorPeriodos from '../TablaComparacionVentasPorSeleccion/TablaComparacionVentasPorPeriodos';

const BarComparacionPorPeriodosDeVenta = (props) => {
  const { ventasComparar, loading, dataOptions, dataSelectedPorPeriodosId, showMonto } = props;
  const { cleanUp } = props;

  const [showTable, setShowTable] = useState(false);
  const [flagTable, setFlagTable] = useState(false);
  const [, setCategoriasCompacion] = useState({ id: '', nombre: '' });
  const [fechasComparacion, setFechasComparacion] = useState([]);
  const [titleCategorySelected, setTitleCategorySelected] = useState('');
  const ventasDe = dataOptions && dataOptions[dataSelectedPorPeriodosId] && dataOptions[dataSelectedPorPeriodosId].description;

  useEffect(() => {
    onLimpiarChartClick();
  }, [sessionStorage.getItem('empresaId'), ventasDe]);

  const onBarClick = (config) => {
    if (titleCategorySelected === config.w.globals.categoryLabels[config.dataPointIndex]) {
      return;
    }

    setTitleCategorySelected(config.w.globals.categoryLabels[config.dataPointIndex]);
    if (fechasComparacion.length === 2) {
      fechasComparacion.splice(0, 2);
    }

    const armarCategorias = {
      id: ventasComparar[0].ventas[config.dataPointIndex].id,
      nombre: ventasComparar[0].ventas[config.dataPointIndex].nombre
    };

    ventasComparar.map((gasto) => fechasComparacion.push(gasto.dateComplete));
    setCategoriasCompacion(armarCategorias);
    setFechasComparacion(fechasComparacion);
    showTable ? setFlagTable(!flagTable) : setShowTable(true);

    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const onLimpiarChartClick = () => {
    setFlagTable(false);
    props.onLimpiarComparacionClick();
    setCategoriasCompacion({ id: '', nombre: '' });
    setFechasComparacion([]);
    setShowTable(false);
  };

  useEffect(() => {
    if (cleanUp) {
      onLimpiarChartClick();
    }
  }, [cleanUp]);

  return (
    <Fragment>
      {(ventasComparar.length > 0 || loading) && (
        <ColumnChart
          title={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
              <div>{`Comparativa de ventas por ${(ventasDe && ventasDe.toLowerCase())} entre dos periodos seleccionados`}</div>
              {ventasComparar.length > 0 && (
                <div style={{ paddingRight: '10px' }}>
                  <Tooltip title="Limpiar comparación">
                    <IconButton onClick={() => onLimpiarChartClick()} size="small">
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          }
          customSinDataImg={SeleccionePeriodos}
          customSinDataImgWidth={300}
          series={
            ventasComparar.length > 0 &&
            ventasComparar
              .sort((a, b) => {
                return new Date(a.dateComplete.anio, a.dateComplete.desde, 1) - new Date(b.dateComplete.anio, b.dateComplete.desde, 1);
              })
              .map((venta) => {
                let fecha = venta.dateComplete;
                let fechaDesde = fecha.desde < 10 ? `0${fecha.desde}` : `${fecha.desde}`;
                let fechaHasta = fecha.hasta < 10 ? `0${fecha.hasta}` : `${fecha.hasta}`;
                let labelName = fecha.desde === fecha.hasta
                  ? fecha.anio === fecha.anioHasta ? `${fechaDesde}/${fecha.anio}` : `${fechaDesde}/${fecha.anio}-${fechaHasta}/${fecha.anioHasta}`
                  : fecha.anio === fecha.anioHasta ? `${fechaDesde}-${fechaHasta}/${fecha.anio}` : `${fechaDesde}/${fecha.anio}-${fechaHasta}/${fecha.anioHasta}`;
                return {
                  name: labelName,
                  data: venta.ventas.map((ventasDetail) => showMonto ? ventasDetail.monto : ventasDetail.unidadesVendidas)
                };
              })
          }
          categories={
            ventasComparar.length > 0 &&
            ventasComparar[0].ventas.map((venta) => {
              return venta.nombre.trim();
            })
          }
          onPorcionClick={(config) => ventasComparar.length === 2 && onBarClick(config)}
          loading={loading}
          imgSinDatos={ventasComparar.length === 0}
          height={350}
          width={'100%'}
          xaxisLabelsShow
          xaxisLabelsTrim={false}
          xaxisLabelsRotate={-45}
          xaxisLabelsRotateAlways={true}
          tooltipXShow
          legendStyleFontSize={'10px'}
          customMarginTop={'0px'}
          legendShowForSingleSeries
          legendShowForNullSeries
          legendShowForZeroSeries
          legendOffSetY={20}
          legendHeight={50}
          maxWidthImgSinResult={330}
          legendPosition={'bottom'}
          showUnidades={!showMonto}
          withoutDecimals={props.withoutDecimals}
        />
      )}
      <div id="#divider" style={{ height: '10px' }} />
      {ventasComparar.length === 2 && (
        <TablaComparacionVentasPorPeriodos
          ventasComparar={ventasComparar}
          ventasDe={ventasDe}
          dataOptions={dataOptions}
          dataSelectOptions={props.dataSelectOptions}
          dataSelectionId={dataSelectedPorPeriodosId}
          showMonto={showMonto}
          exportToExcel={true}
          withoutDecimals={props.withoutDecimals}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectOptions: state.ventas.tipoVentasDesagregadas.tipos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowDetalleCategoriaModal: (show, title, body) => dispatch(actions.showGlobalModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarComparacionPorPeriodosDeVenta);
