import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import RangeSelect from '../../../../assets/images/RangeSelect.svg';
import BoxStepInfo from '../../../UI/BoxStepInfo/BoxStepInfo';
import BarComparacionPorPeriodosDeVenta from '../BarComparacionVentas/BarComparacionPorPeriodosDeVenta';
import classes from './LineEvolucionVentasPorPeriodo.css';
import { Grid } from '@material-ui/core';
import LineChartVentasComparativas from './LineChartVentasComparativas';
import LineChartEvolucionVentas from './LineChartEvolucionVentas';
import LineChartVentasComparativasPorMes from './LineChartVentasComparativasPorMes';

const LineEvolucionVentasPorPeriodo = (props) => {
  const { dataOptions } = props;
  const [dataSelectedPorPeriodosId, setDataSelectedPorPeriodosId] = useState(props.dataSelectedId);
  const [banderaVentasComparacion] = useState(false);
  const [loadingComparacion, setLoadingComparacion] = useState(false);
  const [ventasComparar, setVentasComparar] = useState([]);
  const [formatVentasCompletos, setFormatVentasCompletos] = useState([]);
  const [showMonto, setShowMonto] = useState(false);
  const [cleanUp, setCleanUp] = useState(false);
  const [wands, setWands] = useState({
    evolucion: false,
    comparativa: false,
    mensual: false
  });
  const [mensajeComparacion] = useState("Al seleccionar este nuevo período, se eliminarán de la comparación los períodos seleccionados previamente. ¿Desea continuar?");

  const verifyEqualDates = (date1, date2) => {
    return date1.desde === date2.desde
      && date1.hasta === date2.hasta
      && date1.anio === date2.anio
      && date1.anioHasta === date2.anioHasta;
  };

  const isDateSameChart = (date1, date2) => {
    return date1.chartKey === date2.chartKey;
  }

  const onMarkerClick = (chartKey, chartLoad, desde, hasta, anio, anioHasta, isMonto, dataSelectedId) => {
    let tempVentasComparar = ventasComparar;
    let tempFormatVentasCompletos = formatVentasCompletos;

    if (tempVentasComparar.length === 2) {
      if (window.confirm(mensajeComparacion)) {
        onLimpiarComparacion();
        tempVentasComparar = [];
        tempFormatVentasCompletos = [];
        setTimeout(() => setVentasComparar([]), 500);
      }
    }

    if (tempVentasComparar.length < 2) {
      const dateComplete = { chartKey, anio, anioHasta, desde, hasta };

      if (tempVentasComparar.length === 1) {
        if (!isDateSameChart(tempVentasComparar[0].dateComplete, dateComplete)) {
          onLimpiarComparacion();
          tempVentasComparar = [];
          tempFormatVentasCompletos = [];
          setTimeout(() => setVentasComparar([]), 500);
        }

        if (tempVentasComparar[0] && verifyEqualDates(tempVentasComparar[0].dateComplete, dateComplete)) {
          return;
        }
      }

      setShowMonto(isMonto);
      setDataSelectedPorPeriodosId(dataSelectedId);
      setLoadingComparacion(true);
      setTimeout(() => {
        if (tempVentasComparar.length === 1) {
          window.scroll({ top: document.body.offsetHeight, left: 0, behavior: 'smooth' });
        }
      }, 500);

      chartLoad(desde, hasta, anio, anioHasta, (ventasCompleto) => {
        if (tempVentasComparar.length === 1) {
          var arrayNuevo = [];
          tempFormatVentasCompletos[0].forEach((ventaCompleto) => {
            const verify = ventasCompleto.filter((venta) => venta.id === ventaCompleto.id);
            if (verify.length === 0) {
              arrayNuevo.push({ ...ventaCompleto, monto: 0, porcentajeDeParticipacion: 0, unidadesVendidas: 0, porcentajeDeParticipacionUnidades: 0 });
            }
          });

          ventasCompleto.map((venta) => {
            return arrayNuevo.push(venta);
          });

          ventasCompleto.forEach((ventaOrdenado) => {
            const verify = tempFormatVentasCompletos[0].filter((venta) => venta.id === ventaOrdenado.id);

            if (verify.length === 0) {
              tempFormatVentasCompletos[0].push({ ...ventaOrdenado, monto: 0, porcentajeDeParticipacion: 0, unidadesVendidas: 0, porcentajeDeParticipacionUnidades: 0 });
            }
          });

          var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
          var orderFormatVentasCompletos = tempFormatVentasCompletos[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

          setVentasComparar([
            { dateComplete: tempVentasComparar[0].dateComplete, ventas: orderFormatVentasCompletos },
            { dateComplete: dateComplete, ventas: orderArrayNuevo }
          ]);
        } else {
          tempFormatVentasCompletos.push(ventasCompleto);
          tempVentasComparar.push({ dateComplete: dateComplete, ventas: ventasCompleto });
          setFormatVentasCompletos(tempFormatVentasCompletos);
          setVentasComparar(tempVentasComparar);
        }

        setTimeout(() => setLoadingComparacion(false), 1000);
        setTimeout(() => {
          if (tempVentasComparar.length === 1) {
            window.scroll({ top: document.body.offsetHeight, left: 0, behavior: 'smooth' });
          }
        }, 1050);
      });
    }

    if (tempVentasComparar.length === 1) {
      window.scroll({ top: document.body.offsetHeight, left: 0, behavior: 'smooth' });
    }
  }

  const onLimpiarComparacion = () => {
    setVentasComparar([]);
    setFormatVentasCompletos([]);
    setCleanUp(false);
  };

  const onWandChange = (key) => {
    const wandOn = !wands[key];
    setWands({
      evolucion: false,
      comparativa: false,
      mensual: false,
      [key]: wandOn
    });

    if (wandOn) {
      setCleanUp(true);
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} container spacing={1} className={classes.Container}>

          <Grid item xs={12}>
            <BoxStepInfo
              texto={<span>Seleccione dos períodos en el gráfico "Ventas comparativas por mes", "Evolución de ventas totales por período" o "Ventas comparativas mensuales por año" utilizando la herramienta <img className={classes.IconSelectRange} alt='rangeSelect' src={RangeSelect} /> para visualizar la información en el gráfico de barras al final de la página.</span>}
            />
          </Grid>

          <Grid item xs={12}>
            <LineChartVentasComparativasPorMes
              dataSelectOptions={props.dataSelectOptions}
              dataOptions={dataOptions}
              onMarkerClick={onMarkerClick}
              withoutDecimals={props.withoutDecimals}
              inSelectRange={wands.mensual}
              onSelectRangeChange={onWandChange}
              wandKey={'mensual'}
            />
          </Grid>

          <Grid item xs={12}>
            <LineChartEvolucionVentas
              dataSelectOptions={props.dataSelectOptions}
              dataOptions={dataOptions}
              onMarkerClick={onMarkerClick}
              withoutDecimals={props.withoutDecimals}
              inSelectRange={wands.evolucion}
              onSelectRangeChange={onWandChange}
              wandKey={'evolucion'}
            />
          </Grid>

          <Grid item xs={12}>
            <LineChartVentasComparativas
              dataSelectOptions={props.dataSelectOptions}
              dataOptions={dataOptions}
              onMarkerClick={onMarkerClick}
              withoutDecimals={props.withoutDecimals}
              inSelectRange={wands.comparativa}
              onSelectRangeChange={onWandChange}
              wandKey={'comparativa'}
            />
          </Grid>

          <Grid item xs={12}>
            <BarComparacionPorPeriodosDeVenta
              inComparacion
              banderaComparacion={banderaVentasComparacion}
              loading={loadingComparacion}
              ventasComparar={ventasComparar}
              onLimpiarComparacionClick={() => onLimpiarComparacion()}
              showMonto={showMonto}
              dataOptions={dataOptions}
              dataSelectedPorPeriodosId={dataSelectedPorPeriodosId}
              cleanUp={cleanUp}
              withoutDecimals={props.withoutDecimals}
            />
          </Grid>

        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataVentasDelMesPorSelected,
    dataSelectOptions: state.ventas.tipoVentasDesagregadas.tipos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selectionId) => dispatch(actions.seleccionDataVentasPorPeriodosPor(selectionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionVentasPorPeriodo);