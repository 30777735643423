import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import ColumnChart from '../../../Charts/ColumnChart';

const BarIvaFiscalPorEmpresa = (props) => {

  const handlePorcionClick = (config) => {
    props.onPorcionClick(config);
  };

  return (
    <ColumnChart
      title={props.title}
      labelTooltipSeries={'Monto'}
      subTitle={props.subtitle}
      series={props.ivas.map((iva) => {
        return { name: iva.nombreEmpresa, data: [iva.total] };
      })}
      categories={props.ivas.map((iva) => iva.nombreEmpresa)}
      ids={props.ivas.map((iva) => iva.id)}
      loading={props.loading}
      imgSinDatos={props.ivas.length === 0 || (props.ivas.length === 1 && props.ivas[0].total === 0)}
      onPorcionClick={handlePorcionClick}
      disabled={props.estaOculto}
      height={props.height}
      width={'100%'}
      legendFontSize={'14px'}
      legendOffsetY={-5}
      legendShowForSingleSeries
      legendShowForNullSeries
      legendShowForZeroSeries
      legendPosition={'bottom'}
      onChangeViewClick={props.onChangeViewClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ivas: state.multiempresa.ivaFiscalPorEmpresa.ivas,
    loading: state.multiempresa.ivaFiscalPorEmpresa.loading,
    estaCargado: state.multiempresa.ivaFiscalPorEmpresa.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscalMultiempresa
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadIvaFiscalPorEmpresa())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarIvaFiscalPorEmpresa);
