import React, { } from 'react';
import { formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Table from '../../../UI/Table/Table';
import ViewInTable from '../../../../assets/images/ViewInTable.svg';
import { IconButton, Tooltip } from '@material-ui/core';
import classes from './ChartPivotGrid.css';
import Spinner from '../../../UI/Spinner/Spinner';
import ExportToExcelIcon from '../../../UI/Icons/ExportToExcelIcon';
import { compareByDesc } from '../../../../shared/sorting';

const CustomPieTablePivotGrid = (props) => {
  const { items, headerTitle, headerField, montoTitle, montoField, showMonto, loading } = props;

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#c9c9c9'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '12px',
      paddingBottom: '5px',
      paddingTop: '5px',
      width: width,
      color: value < 0 && 'red'
    }
  };

  const columns = () => {
    const arrColumns = [
      {
        title: headerTitle,
        field: 'name',
        align: 'left',
        cellStyle: (cellData) => cellStyles(cellData, '60%'),
        headerStyle: { ...headerStyle, width: '60%' }
      },
      {
        title: montoTitle,
        field: 'monto',
        align: 'right',
        cellStyle: (cellData) => cellStyles(cellData, '40%'),
        headerStyle: { ...headerStyle, width: '40%' },
        render: (rowData) => showMonto
          ? `${storageMonedaSigno()}${formatNumberWithoutDecimals(rowData.monto)}`
          : `${formatNumberWithoutDecimals(rowData.monto)}`
      }
    ];

    return arrColumns;
  };

  const data = () => {
    const orderItems = items.sort((a, b) => compareByDesc(a, b, montoField));

    return orderItems.map((item) => {
      return {
        name: item[headerField],
        monto: item[montoField],
      };
    });
  }

  //#region Export to Excell
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
    };
  };

  const columnsForExcel = () => {
    const arrColumns = [
      {
        title: headerTitle,
        width: { wpx: 150 },
        align: 'left',
        style: headersStyle
      },
      {
        title: montoTitle,
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      }
    ];

    return arrColumns;
  }

  const formatData = () => {
    let tableData = [];
    let tempData = data();

    tempData && tempData.map((item) => {
      const row = [
        {
          value: item.name,
          style: { font: { sz: '10' } }
        },
        {
          value: showMonto
            ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(item.monto)}`
            : `${formatNumberWithoutDecimals(item.monto)}`,
          style: cellsStyleVerifNegative(item.monto)
        }
      ];

      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnsForExcel(),
      data: formatData()
    }
  ];

  //#endregion
  return (
    <div style={{ height: '100%', width: props.width, minHeight: props.minHeight }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginInline: '10px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title}
          </div>
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como torta">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.SubtitleCard}>
          {props.subTitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subTitle}</p>}
          {items && items.length > 0 && (<ExportToExcelIcon
            filename={props.filename}
            name={headerTitle}
            dataSet={multiDataSet}
          />)}
        </div>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <div className={classes.TableContainer}>
            <Table
              style={{ width: '100%', marginBottom: '20px' }}
              columns={columns()}
              data={data()}
              loading={props.loading}
              customOptions={{
                draggable: false,
                search: false,
                paging: false,
                toolbar: false,
                showTitle: false,
                maxBodyHeight: props.height,
                rowStyle: (rowData, index) => ({
                  backgroundColor: isEven(index) ? "#f2f2f2" : "white",
                }),
              }}
              tableKey={"tabla-custom-pie-pivot-grid"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomPieTablePivotGrid;