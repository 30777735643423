import React from 'react';
import classes from './TablaInteranualContainer.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import TablaInteranualCuboVentas from '../../../Indicadores/Ventas/Interanual/TablaInteranualCuboVentas';
import TablaInteranualCuboGastos from '../../../Indicadores/Gastos/Interanual/TablaInteranualCuboGastos';
import TablaInteranualCuboUtilidad from '../../../Indicadores/Ventas/Utilidades/Interanual/TablaInteranualCuboUtilidad';
import TablaInteranualPlaceholder from '../TablaInteranualCubo/TablaInteranualPlaceholder';
import moment from 'moment';
import { fechaMinima } from '../../../../shared/fechas';


const NextArrow = (props) => {
  const { className, style, onClick, customClass } = props;
  return (
    <div className={`${className} ${customClass}`} style={{ ...style }} onClick={onClick}>
      <ArrowForwardIosOutlinedIcon fontSize="small" htmlColor="black" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, customClass } = props;
  return (
    <div className={`${className} ${customClass}`} style={{ ...style }} onClick={onClick}>
      <ArrowBackIosOutlinedIcon fontSize="small" htmlColor="black" />
    </div>
  );
};

export const TablaInteranualContainer = (props) => {
  var settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3.15,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow customClass={`${classes.customSlickNext} ${classes.customSlick}`} />,
    prevArrow: <PrevArrow customClass={`${classes.customSlickPrev} ${classes.customSlick}`} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.15,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.15,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: true
        }
      }
    ],

  };

  const getTablaCubo = (periodo, index) => {
    if (props.type === 'Venta') {
      return (
        <TablaInteranualCuboVentas
          key={index}
          fecha={periodo}
          ejecucionDePagina={props.ejecucionDePagina}
          onEliminarPeriodo={() => props.onEliminarPeriodo(periodo)}
          withoutDecimals={props.withoutDecimals}
        />
      );
    }

    if (props.type === 'Gasto') {
      return (
        <TablaInteranualCuboGastos
          key={index}
          fecha={periodo}
          ejecucionDePagina={props.ejecucionDePagina}
          onEliminarPeriodo={() => props.onEliminarPeriodo(periodo)}
          withoutDecimals={props.withoutDecimals}
        />
      );
    }

    if (props.type === 'Utilidad') {
      return (
        <TablaInteranualCuboUtilidad
          key={index}
          fecha={periodo}
          ejecucionDePagina={props.ejecucionDePagina}
          onEliminarPeriodo={() => props.onEliminarPeriodo(periodo)}
          withoutDecimals={props.withoutDecimals}
        />
      );
    }
  };

  return (
    <div style={{ width: '100%', paddingLeft: '1%', paddingRight: '1%' }}>
      <Slider {...settings}>
        {props.periodos.map((periodo, index) => {
          if (moment(periodo).isSame(moment(fechaMinima()))) {
            return <TablaInteranualPlaceholder key={index}/>;
          } else {
            return getTablaCubo(periodo, index);
          }
        })}
      </Slider>
    </div>
  );
};

export default TablaInteranualContainer;
