import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compareFechaActualFechaFija, fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';

import SinResultadosPorFecha from '../../../../assets/images/SinResultadosFecha.png';

import * as actions from '../../../../store/actions/index';
import TextChart from '../../../Charts/TextChart';

const TextoChequesPropios = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }

    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.ejecucionDePagina]);

  const isDisabledChart = (props.estaOculto && !props.empresa) || !compareFechaActualFechaFija();

  return (
    <TextChart
      title={`Cheques emitidos pendientes ${
        props.empresa ? props.empresa.nombre : ''
      } al ${fechaFinPeriodoMesActualFunc()}`}
      bigAmount={props.monto}
      bigAmountTooltip=""
      smallAmount={`${props.promedioDias}`}
      smallAmountTooltip="Prom. días de vencimiento"
      bigCurrency
      customDisabledImage={!compareFechaActualFechaFija() && !props.estaOculto ? SinResultadosPorFecha : undefined}
      disabled={isDisabledChart}
      cursorPointer={!isDisabledChart}
      loading={props.loading}
      onBodyClick={() => props.onBodyClick()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.compras.textoChequesPropios.loading,
    monto: state.compras.textoChequesPropios.monto,
    promedioDias: state.compras.textoChequesPropios.promedioDias,
    estaCargado: state.compras.textoChequesPropios.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidos
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoChequesPropios(idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearTextoChequesPropios())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoChequesPropios);
