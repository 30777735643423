// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LineComparacionVentasEntreSucursales__LineComparacionVentasEntreSucursalesContainer__TIYDG {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__selectorSucursalesAComparar__8yjSB {\r\n  display: flex;\r\n  flex: 1 1;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  padding: 5px 10px 5px 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__selectorSucursalesAComparar__8yjSB:hover {\r\n  background-color: rgb(199, 199, 199);\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__sucursalSeleccioanda__ZEw2w {\r\n  padding-left: 25px;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__SelectRoot__fAf4\\+ {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__TooltipContainer__htvdy {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__Title__Q7fDQ {\r\n  align-self: center;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__IconSelectRange__QxZyQ {\r\n  opacity: 0.6;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__Divider__TpFDR {\r\n  height: 1px;\r\n  background-color: black;\r\n  opacity: 0.5;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__Body__c3o3E {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__TextoContainer__JG3E9 {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.LineComparacionVentasEntreSucursales__Circle__TRRwD {\r\n  width: 13px;\r\n  height: 13px;\r\n  border-radius: 50%;\r\n  margin-right: 10px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LineComparacionVentasEntreSucursalesContainer": "LineComparacionVentasEntreSucursales__LineComparacionVentasEntreSucursalesContainer__TIYDG",
	"selectorSucursalesAComparar": "LineComparacionVentasEntreSucursales__selectorSucursalesAComparar__8yjSB",
	"sucursalSeleccioanda": "LineComparacionVentasEntreSucursales__sucursalSeleccioanda__ZEw2w",
	"SelectRoot": "LineComparacionVentasEntreSucursales__SelectRoot__fAf4+",
	"TooltipContainer": "LineComparacionVentasEntreSucursales__TooltipContainer__htvdy",
	"Title": "LineComparacionVentasEntreSucursales__Title__Q7fDQ",
	"IconSelectRange": "LineComparacionVentasEntreSucursales__IconSelectRange__QxZyQ",
	"Divider": "LineComparacionVentasEntreSucursales__Divider__TpFDR",
	"Body": "LineComparacionVentasEntreSucursales__Body__c3o3E",
	"TextoContainer": "LineComparacionVentasEntreSucursales__TextoContainer__JG3E9",
	"Circle": "LineComparacionVentasEntreSucursales__Circle__TRRwD"
};
export default ___CSS_LOADER_EXPORT___;
