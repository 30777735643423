import React, { Fragment, useEffect, useState } from 'react';
import classes from './InteranualUtilidad.css';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import Dialog from '../../../../UI/Dialog/Dialog';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TablaInteranualToolbar from '../../../../UI/Interanual/ToolbarInteranual/ToolbarInteranual';
import LinesInteranualUtilidad from './LinesInteranualUtilidad';
import TablaInteranualContainer from '../../../../UI/Interanual/TablaInteranualContainer/TablaInteranualContainer';
import { fechaActualFunc, fechaMinima } from '../../../../../shared/fechas';
import Spinner from '../../../../UI/Spinner/Spinner';
import PeriodoSelector from '../../../../UI/Interanual/PeriodoSelector/PeriodoSelector';
import moment from 'moment';

export const InteranualUtilidad = (props) => {
  const inPhone = window.screen.width <= 750;
  const { periodosEvoluciones, evoluciones, agregarPeriodo, eliminarPeriodo, onChangeFechaUtilidadDelMes } = props;
  const [type] = useState('Utilidad');
  const [openAgregar, setOpenAgregar] = useState(false);
  const [periodoSeleccionado, setPeriodoSeleccionado] = useState();
  const [fechaFija] = useState(fechaActualFunc());
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [periodos, setPeriodos] = useState([]);
  const [fechaPlaceholder] = useState(fechaMinima());

  useEffect(() => {
    if(periodosEvoluciones.length === 0){
      agregarPeriodo(fechaFija);
      onChangeFechaUtilidadDelMes(fechaFija.getMonth() + 1, fechaFija.getFullYear());
    }
  }, [periodosEvoluciones, fechaFija, onChangeFechaUtilidadDelMes, agregarPeriodo]);

  useEffect(() => {
    if(periodos.length === 0)
      setPeriodos(periodosEvoluciones);
  }, [periodosEvoluciones, periodos]);

  const onAgregarPeriodo = () => {
    setErrorMessage(undefined);    
    if(periodoSeleccionado){
      let existPeriodo = periodosEvoluciones.find((per) => per.getFullYear() === periodoSeleccionado._d.getFullYear()) !== undefined;
      if(existPeriodo) {
        setErrorMessage("Ya se encuentra agregado el período seleccionado.");
      }else{
        const periodoPlaceholder = periodosEvoluciones.find((per) => moment(per).isSame(moment(fechaPlaceholder)))
        if(periodoPlaceholder)
          eliminarPeriodo(periodoPlaceholder)
          
        agregarPeriodo(new Date(periodoSeleccionado._d.getFullYear(), fechaFija.getMonth(), 1));
        setOpenAgregar(false);
      }
    }    
  }

  const onEliminarPeriodo = (periodo) => {
    eliminarPeriodo(periodo);      
  }

  const onCancelarPeriodo = () => {
    setErrorMessage(undefined);     
    setPeriodoSeleccionado(null);
    setOpenAgregar(false);   
  }

  const getDatasPorPeriodo = () => {
    let datasPorPeriodos = [];
    
    evoluciones.forEach(element => {
      datasPorPeriodos.push(
        {
          anio: element.anio,
          datasPorAnio: element.utilidadesDataTable
        }
      );
    });
    
    return datasPorPeriodos;
  }

  const getPeriodos = () => {
    return periodosEvoluciones;
  }

  const getTitle = () => {
    return (
      <div style={{ width: '98%', textAlign: 'right' }}>
        <IconButton aria-label="close" onClick={() => setOpenAgregar(false)} >
          <CloseIcon />
        </IconButton>
      </div>
    )
  }

  const getBody = () => {
    return (
      <PeriodoSelector
        periodoSeleccionado={periodoSeleccionado}
        setPeriodoSeleccionado={setPeriodoSeleccionado}
        onCancelarPeriodo={onCancelarPeriodo}
        onAgregarPeriodo={onAgregarPeriodo}
        errorMessage={errorMessage}
      />
    )
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} container spacing={1} 
          className={classes.tablasInteranuales}
          style={{ marginBottom: periodos.length > 3 || inPhone ? '20px' : '10px' }}>
          <Grid item xs={12}>
            <TablaInteranualToolbar 
              periodos={getPeriodos()} 
              onAgregarClick={() => {
                setPeriodoSeleccionado();
                setErrorMessage(undefined);
                setOpenAgregar(true);
              }}
              onShowFiltersModal={() => props.onShowFiltersModal()}
              datasPorPeriodos={getDatasPorPeriodo()}
              type={type}
            />
          </Grid>
          {periodos.length === 0 ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Spinner />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TablaInteranualContainer
                ejecucionDePagina={props.ejecucionDePagina}
                periodos={getPeriodos()} 
                type={type}
                onEliminarPeriodo={onEliminarPeriodo}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.linesInteranuales}>
          <LinesInteranualUtilidad 
            periodos={getPeriodos()} 
            type={type}
          />
        </Grid>
      </Grid>
      {openAgregar && (
        <Dialog 
          show={openAgregar}
          title={getTitle()}
          onClose={() => {
            setErrorMessage(undefined);
            setOpenAgregar(false);
          }}
          body={getBody()}
          fullWidth={true}
          maxWidth={'xs'}
        >
        </Dialog>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {    
    fechaPieUtilidad: state.ventas.ventasPorCategoriaDeCliente.fecha,
    periodosEvoluciones: state.ventas.evolucionUtilidadesMensualInteranual.periodos,
    evoluciones: state.ventas.evolucionUtilidadesMensualInteranual.items,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Utilidad desglosadas por mes', ['venta'])),
    agregarPeriodo: (periodo) => dispatch(actions.agregarEvolucionDeUtilidadMensualInteranual(periodo)),
    eliminarPeriodo: (periodo) => dispatch(actions.clearEvolucionDeUtilidadMensualInteranual(periodo)),
    onChangeFechaUtilidadDelMes: (mes, anio) => dispatch(actions.seleccionFechaUtilidadDelMesPor(mes, anio)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InteranualUtilidad);