import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import BarVentasDeSucursalesPorSeleccion from '../../components/Indicadores/Sucursales/BarVentasDeSucursalesPorSeleccion/BarVentasDeSucursalesPorSeleccion';
import LineComparacionVentasEntreSucursales from '../../components/Indicadores/Sucursales/LineComparacionVentasEntreSucursales/LineComparacionVentasEntreSucursales';
import LineEvolucionVentasSucursales from '../../components/Indicadores/Sucursales/LineEvolucionVentasSucursales/LineEvolucionVentasSucursales';
import SunburstVentasPorSucursalPorSeleccion from '../../components/Indicadores/Sucursales/SunburstVentasPorSucursalPorSeleccion/SunburstVentasPorSucursalPorSeleccion';
import TablaCuboVentasSucursales from '../../components/Indicadores/Sucursales/TablaCuboVentasSucursales/TablaCuboVentasSucursales';
import TablaVentasDeSucursalesPorSeleccion from '../../components/Indicadores/Sucursales/TablaVentasDeSucursalesPorSeleccion/TablaVentasDeSucursalesPorSeleccion';
import classes from './AnalisisDeSucursales.css';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  lineEvolucionVentas: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));

const Comparacion = (props) => {
  const classes2 = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={12} lg={4}>
          <TablaCuboVentasSucursales ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <LineEvolucionVentasSucursales ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item 
        container spacing={1}
        className={classes.CombinedChartsCard}>
        <Grid item xs={12} lg={4}>
          <SunburstVentasPorSucursalPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <BarVentasDeSucursalesPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TablaVentasDeSucursalesPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
      <Grid item className={classes2.lineEvolucionVentas}>
        <LineComparacionVentasEntreSucursales ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Comparacion);
