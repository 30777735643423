import React, {Fragment} from 'react';
import { IconButton, Typography } from '@material-ui/core';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';

const BoxEjecucionIndicador = (props) => {
  
  const stretched = props.stretched
  return (
    <Fragment>
        <div style={{height: stretched ? '25%' : '10%', display: 'flex', justifyContent: 'space-between', padding: '10px'}}>
          <p style={{fontWeight: 'bold', marginTop: '5px', marginBottom: '5px'}}>{props.title}</p>
          <Typography>
            Haga click en el botón 
            <IconButton onClick={() => props.onClick()} style={{padding: '0px'}}>
              <PlayCircleFilledWhiteOutlinedIcon  style={{ marginLeft: '5px', marginRight: '5px' }} />
            </IconButton>
            para ejecutar el indicador.
          </Typography>
        </div>
    </Fragment>
  );
};

export default BoxEjecucionIndicador;