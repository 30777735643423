import React, { Fragment, useCallback, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import { Checkbox, FormControlLabel, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import CustomSelector from "../../../../UI/CustomSelector/CustomSelector";
import ButtonPivotGrid from "../../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid";
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import Spinner from "../../../../UI/Spinner/Spinner";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from '@material-ui/icons/Event';

import { createTheme } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";

moment.locale("es");

const useStylesSelector = makeStyles(() => ({
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  selectorNoAprobado: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
      backgroundColor: '#ffafaf'
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  options: {
    fontSize: 'small',
  },
  optionsNoAprobado: {
    fontSize: 'small',
    backgroundColor: '#ffafaf'
  },
}));

function getMonths() {
  return [
    { id: 4, nombre: 'Abril' },
    { id: 5, nombre: 'Mayo' },
    { id: 6, nombre: 'Junio' },
    { id: 7, nombre: 'Julio' },
    { id: 8, nombre: 'Agosto' },
    { id: 9, nombre: 'Septiembre' },
    { id: 10, nombre: 'Octubre' },
    { id: 11, nombre: 'Noviembre' },
    { id: 12, nombre: 'Diciembre' },
    { id: 1, nombre: 'Enero' },
    { id: 2, nombre: 'Febrero' },
    { id: 3, nombre: 'Marzo' },
  ]
}

const useStyles = makeStyles(() => ({
  checkBoxLabel: {
    fontSize: 'x-small',
    "& .MuiFormControlLabel-label": {
      fontSize: "x-small",
    }
  },
}));

const FiltroInformeUno = (props) => {
  const classes = useStyles();
  const { aplicarFiltros } = props;
  const { loading, loadingInforme, estaCargado, aniosFiscales, onLoadData, filtros, filtrosInforme,
    onUpdateFiltros, onLoadBudgets, budgetsForecasts, loadingBudgets, anioBudgets } = props;
  const [anioFiscal, setAnioFiscal] = useState('');
  const [anioComparacion, setAnioComparacion] = useState('');
  const [mesFiscal, setMesFiscal] = useState('');
  const [meses] = useState(getMonths());
  const [acumulado, setAcumulado] = useState(filtros ? filtros.acumulado : false);
  const [idArchivo, setIdArchivo] = useState('');
  const [desde, setDesde] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [loadingDesde, setLoadingDesde] = useState(false);
  const [hasta, setHasta] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loadingHasta, setLoadingHasta] = useState(false);
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const anioFiscalSelected = anioFiscal !== '';
  const idArchivoSelected = idArchivo !== '';
  const mesSelected = mesFiscal !== '';

  const habilitar = useCallback(() => {
    const dateValid = (desde !== null && desde !== undefined && hasta !== null && hasta !== undefined)
      && desde < hasta;
    const habilitarAplicar = anioFiscal !== '' && idArchivo !== ''
      && (mesFiscal !== '' || dateValid);

    return !habilitarAplicar || loadingInforme;
  }, [anioFiscal, mesFiscal, idArchivo, desde, hasta]);

  useEffect(() => {
    if (!estaCargado && !loading)
      onLoadData();
  }, [estaCargado, loading]);

  useEffect(() => {
    if (filtros && estaCargado) {
      let item = aniosFiscales.find((i) => i.id === `${filtros.anioFiscal}`);

      if (item)
        setAnioFiscal(item);
      else if (anioFiscal !== '')
        setAnioFiscal('');
    }
  }, [filtros, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (filtros && estaCargado && filtros.anioComparacion) {
      let item = aniosFiscales.find((i) => i.id === `${filtros.anioComparacion}`);

      if (item)
        setAnioComparacion(item);
      else if (anioComparacion !== '')
        setAnioComparacion('');
    }
  }, [filtros, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (filtros) {
      let item = meses.find((i) => i.id === filtros.mesFiscal);

      if (item)
        setMesFiscal(item);
      else if (mesFiscal !== '')
        setMesFiscal('');
    }
  }, [filtros, meses]);

  useEffect(() => {
    if (filtros && filtros.idArchivo && filtros.anioFiscal === anioBudgets && budgetsForecasts) {
      let item = budgetsForecasts.find((i) => i.id === `${filtros.idArchivo}`);

      if (item)
        setIdArchivo(item);
      else if (idArchivo !== '')
        setIdArchivo('');
    }
  }, [filtros, filtros.idArchivo, anioBudgets, budgetsForecasts]);

  useEffect(() => {
    if (filtros && filtros.acumulado !== undefined) {
      setAcumulado(filtros.acumulado);
    }
  }, [filtros, filtros.acumulado]);

  useEffect(() => {
    if (filtros && !desde && filtros.desde !== desde) {
      setLoadingDesde(true);
      setDesde(filtros.desde);
      setTimeout(() => setLoadingDesde(false), 500);
    }
  }, [filtros, filtros.desde, desde]);

  useEffect(() => {
    if (filtros && !hasta && filtros.hasta !== hasta) {
      setLoadingHasta(true);
      setHasta(filtros.hasta);
      setTimeout(() => setLoadingHasta(false), 500);
    }
  }, [filtros, filtros.hasta, hasta]);

  useEffect(() => {
    if (!minDate && !maxDate && filtros && filtros.anioFiscal && filtros.mesFiscal) {
      let anio = filtros.anioFiscal;
      let mes = filtros.mesFiscal;
      let daysInMonth = 0;
      let min = null;
      let max = null;

      if (acumulado) {
        daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

        min = new Date(anio, 3, 1);
        max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      } else {
        if (mes < 4)
          anio = anio + 1;

        daysInMonth = new Date(anio, mes, 0).getDate();

        min = new Date(anio, mes - 1, 1);
        max = new Date(anio, mes - 1, daysInMonth);
      }

      setMinDate(min);
      setMaxDate(max);
    }

  }, [filtros, minDate, maxDate, acumulado]);

  const onAplicarFiltroClick = () => {
    let anio = anioFiscal ? Number(anioFiscal.id) : undefined;
    let archivo = idArchivo ? idArchivo.id : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    let desdeDate = desde ? new Date(desde) : null;
    let hastaDate = hasta ? new Date(hasta) : null;
    let comparacion = anioComparacion ? Number(anioComparacion.id) : undefined;
    onUpdateFiltros(anio, archivo, mes, acumulado, desdeDate, hastaDate, comparacion);
    aplicarFiltros(anio, archivo, mes, acumulado, desde, hasta, comparacion);
    setCambiosAplicados(true);
  }

  const onAnioFiscalChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);
    setAnioFiscal(value);
    onLoadBudgets(value.id);
    let anio = value ? Number(value.id) : undefined;
    setIdArchivo('');
    setMesFiscal('');

    setDesde(new Date(anio, 3, 1));
    setHasta(new Date(anio + 1, 2, 31));
    setMinDate(new Date(anio, 3, 1));
    setMaxDate(new Date(anio + 1, 2, 31));

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);

    setCambiosAplicados(false);
  };

  const onAnioComparacionChange = (value) => {
    setAnioComparacion(value);
    setCambiosAplicados(false);
  }

  const onIdArchivoChange = (value) => {
    setIdArchivo(value);
    setMesFiscal('');
    setCambiosAplicados(false);
  };

  const onMesFiscalChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);

    setMesFiscal(value);
    let anio = anioFiscal ? Number(anioFiscal.id) : undefined;
    let mes = value ? value.id : undefined;
    let daysInMonth = 0;
    let desdeDate = null;
    let hastaDate = null;
    let min = null;
    let max = null;

    if (acumulado) {
      daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

      desdeDate = new Date(anio, 3, 1);
      hastaDate = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      min = new Date(anio, 3, 1);
      max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
    } else {
      if (mes < 4)
        anio = anio + 1;

      daysInMonth = new Date(anio, mes, 0).getDate();

      desdeDate = new Date(anio, mes - 1, 1);
      hastaDate = new Date(anio, mes - 1, daysInMonth);
      min = new Date(anio, mes - 1, 1);
      max = new Date(anio, mes - 1, daysInMonth);
    }

    setDesde(desdeDate);
    setHasta(hastaDate);
    setMinDate(min);
    setMaxDate(max);

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);

    setCambiosAplicados(false);
  };

  const onAcumuladoChange = (value) => {
    setLoadingDesde(true);
    setLoadingHasta(true);

    setAcumulado(value);
    let anio = anioFiscal ? Number(anioFiscal.id) : undefined;
    let mes = mesFiscal ? mesFiscal.id : undefined;
    let daysInMonth = 0;
    let desdeDate = null;
    let hastaDate = null;
    let min = null;
    let max = null;

    if (value) {
      daysInMonth = new Date(mes < 4 ? anio + 1 : anio, mes, 0).getDate();

      desdeDate = new Date(anio, 3, 1);
      hastaDate = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
      min = new Date(anio, 3, 1);
      max = new Date(mes < 4 ? anio + 1 : anio, mes - 1, daysInMonth);
    } else {
      if (mes < 4)
        anio = anio + 1;

      daysInMonth = new Date(anio, mes, 0).getDate();

      desdeDate = new Date(anio, mes - 1, 1);
      hastaDate = new Date(anio, mes - 1, daysInMonth);
      min = new Date(anio, mes - 1, 1);
      max = new Date(anio, mes - 1, daysInMonth);
    }

    setDesde(desdeDate);
    setHasta(hastaDate);
    setMinDate(min);
    setMaxDate(max);

    setTimeout(() => {
      setLoadingDesde(false);
      setLoadingHasta(false);
    }, 500);
  };

  const onDesdeChange = (value) => {
    setDesde(value)
    setCambiosAplicados(false);
  };

  const onHastaChange = (value) => {
    setHasta(value);
    setCambiosAplicados(false);
  };

  return (
    <Fragment>
      <Grid item xs={12} md={11} xl={9} container spacing={1} justifyContent="flex-start">
        <Grid container spacing={1} item xs={12} md={6} xl={5}>
          <Grid item xs={3}>
            <CustomSelector
              id="anioFiscal"
              label="Año Fiscal"
              value={anioFiscal}
              items={aniosFiscales}
              onChange={(event) => onAnioFiscalChange(event.target.value)}
              isLoading={loading}
              disabled={loadingInforme}
            />
          </Grid>
          <Grid item xs={5}>
            <BudgetForecastSelector
              id="idArchivo"
              label="Budget / Forecast"
              value={idArchivo}
              items={budgetsForecasts}
              onChange={(event) => onIdArchivoChange(event.target.value)}
              isLoading={loadingBudgets}
              disabled={!anioFiscalSelected || loadingInforme}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              id="mesFiscal"
              label="Mes Fiscal"
              value={mesFiscal}
              items={meses}
              onChange={(event) => onMesFiscalChange(event.target.value)}
              disabled={!idArchivoSelected || loadingInforme}
              upperCase
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} md={6}>
          <Grid item xs={6} md={2}>
            <FormControlLabel
              label="Acumulado"
              className={classes.checkBoxLabel}
              labelPlacement="top"
              control={
                <Checkbox
                  checked={acumulado}
                  onChange={(event) => onAcumuladoChange(event.target.checked)}
                  name="acumulado"
                  color="primary"
                />
              }
              disabled={!mesSelected || loadingInforme}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {loadingDesde ? (
              <Spinner size="small" />
            ) : (
              <FiltroFechaSelector
                label="Desde"
                disabled={!mesSelected || loadingInforme}
                value={desde}
                onDateChange={(value) => onDesdeChange(value)}
                minDate={minDate}
                maxDate={hasta ? hasta : maxDate}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            {loadingHasta ? (
              <Spinner size="small" />
            ) : (
              <FiltroFechaSelector
                label="Hasta"
                disabled={!mesSelected || loadingInforme}
                value={hasta}
                onDateChange={(value) => onHastaChange(value)}
                minDate={desde ? desde : minDate}
                maxDate={maxDate}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <ButtonPivotGrid
              tooltipTitle="Aplicar filtros sobre el informe 1"
              buttonSize="small"
              text="Aplicar"
              onClick={onAplicarFiltroClick}
              loading={false}
              icon={<PlayCircleFilledWhiteOutlinedIcon fontSize="small" />}
              disabled={habilitar()}
              backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} xl={9} container spacing={1} justifyContent="flex-start">
        <Grid container spacing={1} item xs={12} md={6} xl={5}>
          <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
            <CustomSelector
              id="anioComparacion"
              label="Año Comparación"
              value={anioComparacion}
              items={aniosFiscales}
              onChange={(event) => onAnioComparacionChange(event.target.value)}
              isLoading={loading}
              disabled={loadingInforme}
            />
            <Tooltip title="Limpiar el campo Año Comparación">
              <IconButton onClick={() => onAnioComparacionChange(null)}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {filtrosInforme && (<Grid item xs={12}>
        <LeyendaFiltros
          desde={filtrosInforme.desde}
          hasta={filtrosInforme.hasta}
        />
      </Grid>)}
    </Fragment>
  )
}

//#region Selector de Budget/Forecast 
const BudgetForecastSelector = (props) => {
  const classes = useStylesSelector();
  const { id, label, value, items, onChange, seleccione, isLoading,
    disabled, required, error, helperText } = props;

  const idSelect = `${id}Select`;

  return isLoading ? (
    <Spinner size="small" />
  ) : (
    <TextField
      id={idSelect}
      select
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      placeholder={label}
      style={{ color: "black", width: "100%" }}
      disabled={disabled}
      required={required}
      className={value ? value.aprobado ? classes.selector : classes.selectorNoAprobado : classes.selector}
    >
      {seleccione &&
        <MenuItem
          className={classes.options}
          key="select"
          value="select">
          SELECCIONE...
        </MenuItem>
      }
      {items && items.map((item, index) => (
        <MenuItem
          className={item.aprobado ? classes.options : classes.optionsNoAprobado}
          key={index}
          value={item}
        >
          {item.nombre && item.nombre.trimEnd().toUpperCase()}
        </MenuItem>
      ))}
    </TextField>
  );
}
//#endregion

//#region Selector de fecha

const materialTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
  },
});

const FiltroFechaSelector = (props) => {
  const { label, disabled, value, onDateChange, minDate, maxDate } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = useCallback((value) => {
    setSelectedValue(value);
    let newDate = null;

    if (value && value._d) {
      const date = new Date(value._d);
      newDate = new Date(date.setHours(0, 0, 0, 0));
    }

    onDateChange(newDate);
  }, []);

  const onClearClick = (e) => {
    e.stopPropagation();
    setSelectedValue(null);
    onDateChange(null);
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={"es"}>
        <DatePicker
          disableToolbar
          views={['month', 'date']}
          variant="inline"
          format="DD-MM"
          margin="normal"
          id="date-picker-inline"
          label={label}
          value={selectedValue}
          onChange={date => handleDateChange(date)}
          minDate={minDate}
          maxDate={maxDate}
          autoOk
          disabled={disabled}
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
          minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          InputProps={{
            style: { fontSize: 'small' },
            endAdornment: (selectedValue
              ? (<div onClick={(e) => onClearClick(e)} style={{ marginRight: 20, cursor: "pointer" }}>
                <ClearIcon />
              </div>) : (
                <div onClick={() => setIsOpen(true)} style={{ marginRight: 20, cursor: "pointer" }}>
                  <EventIcon />
                </div>
              )
            )
          }}
          InputLabelProps={{ style: { fontSize: 'small' } }}
          style={{ marginTop: '4px' }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

//#endregion

//#region Leyenda Filtro Utilizado
const LeyendaFiltros = (props) => {
  const { desde, hasta } = props;
  const desdeText = desde instanceof Date
    ? new moment(desde).format("DD-MM-YYYY")
    : typeof desde === 'string' ? new moment(new Date(desde)).format("DD-MM-YYYY") : desde;
  const hastaText = hasta instanceof Date
    ? new moment(hasta).format("DD-MM-YYYY")
    : typeof hasta === 'string' ? new moment(new Date(hasta)).format("DD-MM-YYYY") : hasta;

  return desdeText && hastaText ? (
    <Typography variant="h6">
      La información visualizada corresponde al período de análisis del {desdeText} al {hastaText}
    </Typography>
  ) : (<Fragment></Fragment>)
}

//#endregion

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.aniosFiscales.loading,
    error: state.tenacta.aniosFiscales.error,
    aniosFiscales: state.tenacta.aniosFiscales.items,
    estaCargado: state.tenacta.aniosFiscales.estaCargado,
    loadingInforme: state.tenacta.informeUnoData.loading,
    budgetsForecasts: state.tenacta.budgetsForecasts.items,
    loadingBudgets: state.tenacta.budgetsForecasts.loading,
    anioBudgets: state.tenacta.budgetsForecasts.anioFiscal,
    filtros: state.tenacta.informeUnoFiltros,
    filtrosInforme: state.tenacta.informeUnoData.filtros,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(actions.loadAniosFiscales()),
    onUpdateFiltros: (anio, idArchivo, mes, acumulado, desde, hasta, anioComparacion) =>
      dispatch(actions.updateInformeUnoFiltros(anio, idArchivo, mes, acumulado, desde, hasta, anioComparacion)),
    onLoadBudgets: (anioFiscal) => dispatch(actions.loadBudgetsForecasts(anioFiscal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroInformeUno);