// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaVentasPorClienteDeLaCategoria__TitleCard__zSao\\+ {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.TablaVentasPorClienteDeLaCategoria__tablaVentasPorClienteDeLaCategoria__YroOQ {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n}\r\n\r\n.TablaVentasPorClienteDeLaCategoria__tablaVentasPorClienteDeLaCategoria__YroOQ button {\r\n  padding: 7px;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .TablaVentasPorClienteDeLaCategoria__tablaVentasPorClienteDeLaCategoria__YroOQ {\r\n    max-height: 743px;\r\n  }\r\n}\r\n\r\n@media (max-width: 752px) {\r\n  .TablaVentasPorClienteDeLaCategoria__tablaVentasPorClienteDeLaCategoria__YroOQ {\r\n    max-height: 400px;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleCard": "TablaVentasPorClienteDeLaCategoria__TitleCard__zSao+",
	"tablaVentasPorClienteDeLaCategoria": "TablaVentasPorClienteDeLaCategoria__tablaVentasPorClienteDeLaCategoria__YroOQ"
};
export default ___CSS_LOADER_EXPORT___;
