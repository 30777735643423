import React, { useRef } from 'react';
import classes from '../InformeTres.css';
import Table from '../../../../../UI/Table/Table';
import { formatNumberWithMonedaSigno, formatNumberWithoutDecimals } from '../../../../../../shared/formatNumber';

const TablaPorCliente = (props) => {
  const { items, maxHeight } = props;

 const headersStyle = {
    fontSize: 'smaller',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: '#efefef'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '10px',
      paddingBottom: '2px',
      paddingTop: '2px',
      paddingLeft: '3px',
      paddingRight: '3px',
      color: value < 0 && 'red',
      width
    };
  };

  const columns = [
    {
      title: '',
      field: 'cliente',
      align: 'left',
      cellStyle: (cellData) => cellStyles(cellData, '12%', true),
      headerStyle: headersStyle,
      width: '12%'
    },
    {
      title: '',
      field: 'unidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidades),
      width: '10%'
    },
    {
      title: '',
      field: 'unidadesPresupuesto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      headerStyle: headersStyle,
      render: () => '',
      width: '11%'
    },
    {
      title: '',
      field: 'unidadesDiferencia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: () => '',
      width: '10%'
    },
    {
      title: '',
      field: 'unidadesCumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      headerStyle: headersStyle,
      render: () => '',
      width: '11%'
    },
    {
      title: '',
      field: 'importeNeto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNeto),
      width: '10%'
    },
    {
      title: '',
      field: 'importeNetoPresupuesto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      render: () => '',
      headerStyle: headersStyle,
      width: '11%'
    },
    {
      title: '',
      field: 'importeNetoDiferencia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      render: () => '',
      headerStyle: headersStyle,
      width: '11%'
    },
    {
      title: '',
      field: 'importeNetoCumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '13%'),
      headerStyle: headersStyle,
      render: () => '',
      width: '13%'
    },
  ];

  const data = () => {
    return items ? items : [];
  };

  const tableRef = useRef();

  return (
    <div className={classes.tablaVentasPorRubro}>
      <Table
        tableRef={tableRef}
        style={{
          marginTop: 0,
          marginBottom: '10px',
          border: "0px solid #ccc",
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "white",
          borderRadius: "3px"
        }}
        title=""
        columns={columns}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: maxHeight
        }}
      />
    </div>
  );
};

export default TablaPorCliente;