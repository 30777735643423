import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  executeCreateGrupoEmpresas: false,
  executeUpdateGrupoEmpresas: false,
};

const executeCreateGrupoEmpresas = (state, loading) => {
  return updateObject(state, {
    executeCreateGrupoEmpresas: loading
  });
};

const executeUpdateGrupoEmpresas = (state, loading) => {
  return updateObject(state, {
    executeUpdateGrupoEmpresas: loading
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_CREATE_GRUPO_EMPRESAS:
      return executeCreateGrupoEmpresas(state, action.loading);
    case actionTypes.EXECUTE_UPDATE_GRUPO_EMPRESAS:
      return executeUpdateGrupoEmpresas(state, action.loading);
    default:
      return state;
  }
};

export default reducer;
