import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import ColumnChart from '../../../Charts/ColumnChart';
import moment from 'moment';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SeleccionePeriodos from '../../../../assets/images/SeleccionePeriodos.png';
import TablaComparacionPorCategoriaDeCliente from '../TablaComparacionPorCategoriaDeCliente/TablaComparacionPorCategoriaDeCliente';

const BarGastosPorCategoriaPorMes = (props) => {
  const { gastosComparar, loading } = props;

  const [flagTable, setFlagTable] = useState(false);
  const [fechasComparacion, setFechasComparacion] = useState([]);
  const [titleCategorySelected, setTitleCategorySelected] = useState('');

  useEffect(() => {
    if (gastosComparar.length === 0) {
      setFechasComparacion([]);
      setTitleCategorySelected('');
      setFlagTable(!flagTable);
    }
  }, [gastosComparar]);  

  const onLimpiarChartClick = () => {
    setFlagTable(false);
    props.onLimpiarComparacionClick();
    setFechasComparacion([]);
  };

  return (
    <Fragment>
      {(gastosComparar.length > 0 || loading) && (
        <ColumnChart
          title={
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}
            >
              <div>{`Comparativa de gastos por categoría entre dos periodos seleccionados`}</div>
              {gastosComparar.length > 0 && (
                <div style={{ paddingRight: '10px' }}>
                  <Tooltip title="Limpiar comparación">
                    <IconButton onClick={() => onLimpiarChartClick()} size="small">
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          }
          customSinDataImg={SeleccionePeriodos}
          customSinDataImgWidth={300}
          series={
            gastosComparar.length > 0 &&
            gastosComparar
              .sort((a, b) => {
                return new Date(a.dateComplete) - new Date(b.dateComplete);
              })
              .map((gasto) => {
                return {
                  name: moment(gasto.dateComplete).subtract(1, 'month').format('MM/YYYY'),
                  data: gasto.gastos.map((gastoDetail) => gastoDetail.monto)
                };
              })
          }
          categories={
            gastosComparar.length > 0 &&
            gastosComparar[0].gastos.map((gasto) => {
              return gasto.categoria.trim().split(' ');
            })
          }
          loading={loading}
          imgSinDatos={gastosComparar.length === 0}
          height={350}
          width={'100%'}
          xaxisLabelsShow
          xaxisLabelsTrim={false}
          tooltipXShow
          legendStyleFontSize={'10px'}
          customMarginTop={'0px'}
          legendShowForSingleSeries
          legendShowForNullSeries
          legendShowForZeroSeries
          legendOffSetY={20}
          legendHeight={50}
          maxWidthImgSinResult={330}
          legendPosition={'bottom'}
        />
      )}
      <div id="#divider" style={{ height: '10px' }} />
      {gastosComparar.length === 2 && (
        <TablaComparacionPorCategoriaDeCliente
          fechasComparar={fechasComparacion}
          gastosComparar={gastosComparar}
          flag={flagTable}
          titleCategory={titleCategorySelected}
          show={true}
          loading={loading}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChartComparacion: (mes, anio, returnData, success) =>
      dispatch(actions.loadPieGastosPorCategoriaSinDataTable(mes, anio, undefined, returnData, success)),
    onShowDetalleCategoriaModal: (show, title, body) => dispatch(actions.showGlobalModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarGastosPorCategoriaPorMes);
