import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import TableUtilidadesPorRubro from '../../Utilidades/TablesUtilidades/TableUtilidadesPorRubro';
import BarUtilidadesChartBase from './BarUtilidadesChartBase';

const BarUtilidadesPorRubro = (props) => {
  useEffect(() => {
    if (!props.estaCargado && props.ejecucionDePagina) {
      props.onLoadChart(props.mes, props.anio);
    }
  }, [props.ejecucionDePagina]);

  return (
    <BarUtilidadesChartBase
      title={'Utilidad por rubro'}
      utilidadPor="rubro"
      total={props.total}
      utilidades={props.utilidades}
      categories={props.utilidades}
      categoriasCompletas={props.utilidades}
      loading={props.loading}
      chartSinDatos={props.utilidades.length === 0}
      utilidadTable={<TableUtilidadesPorRubro />}
      onChangeUtilOcultas={(utilidades) => props.onChangeUtilidadesOcultas(utilidades)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    utilidades: state.ventas.utilidadPorRubro.utilidades,
    total: state.ventas.utilidadPorRubro.total,
    loading: state.ventas.utilidadPorRubro.loading,
    error: state.ventas.utilidadPorRubro.error,
    estaCargado: state.ventas.utilidadPorRubro.estaCargado,
    mes: state.ventas.fechaVentasDelMes.mes,
    anio: state.ventas.fechaVentasDelMes.anio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio) => dispatch(actions.loadUtilidadPorRubro(mes, anio)),
    onChangeUtilidadesOcultas: (utilidades) => dispatch(actions.updateUtilidadesPorRubroOcultos(utilidades))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarUtilidadesPorRubro);
