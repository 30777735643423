// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaIndicadoresMultiempresa__TablaIndicadoresMultiempresaContainer__HFHCT {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  background-color: white;\r\n  align-items: flex-end;\r\n  margin-top: 10px;\r\n}\r\n\r\n.TablaIndicadoresMultiempresa__TooltipPopper__sHY-G {\r\n  z-index: 1500 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablaIndicadoresMultiempresaContainer": "TablaIndicadoresMultiempresa__TablaIndicadoresMultiempresaContainer__HFHCT",
	"TooltipPopper": "TablaIndicadoresMultiempresa__TooltipPopper__sHY-G"
};
export default ___CSS_LOADER_EXPORT___;
