// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PiePlaceholder__Container__sprjN {\r\n    display:flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100%; \r\n    box-shadow: 0px 3px 6px #00000029;\r\n    box-sizing: border-box;\r\n    background-color: white;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 5px;}\r\n\r\n.PiePlaceholder__Imagen__mRqYh {\r\n    display: flex; \r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 10%;\r\n    height: 54%; \r\n    width: 50%; \r\n}\r\n\r\n.PiePlaceholder__TextContainer__qZLf\\+{\r\n    width: 100%;\r\n    display: flex; \r\n    flex-wrap: nowrap;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border:none\r\n    \r\n}\r\n\r\n.PiePlaceholder__Text__lLDJL{\r\n    font-size: 20px;\r\n    text-align: center;\r\n    flex-wrap: nowrap;\r\n    width: 100%;\r\n    color: #515151;\r\n    \r\n}\r\n\r\n.PiePlaceholder__HighlightText__S2RtO{\r\n    font-size: 20px;\r\n    color: #FF8800;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "PiePlaceholder__Container__sprjN",
	"Imagen": "PiePlaceholder__Imagen__mRqYh",
	"TextContainer": "PiePlaceholder__TextContainer__qZLf+",
	"Text": "PiePlaceholder__Text__lLDJL",
	"HighlightText": "PiePlaceholder__HighlightText__S2RtO"
};
export default ___CSS_LOADER_EXPORT___;
