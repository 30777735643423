import React, { Fragment } from 'react';
import Dialog from './Dialog';
import Button from '../Button/Button';

const confirmDialog = (props) => {
  return (
    <Dialog
      show={props.show}
      onClose={props.onClose}
      title={(<h3 style={{ marginLeft: '15px' }}>{props.title}</h3>)}
      body={props.body}
      actions={(
        <Fragment>
          <Button onClick={props.onClose}>Cancelar</Button>
          <Button onClick={props.onConfirm} autoFocus>Confirmar</Button>
        </Fragment>
      )}
    >
    </Dialog>
  );
};

export default confirmDialog;
