import React from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc,
} from "../../../../shared/fechas";

const TextoPedidosPendientesComparativo = (props) => {
  const {
    totalMesAct,
    totalMesAnt,
    loading,
    error,
  } = props;

  const chartSinDatos = error;
  const fechaTitulo = `${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`;

  return (
    <TextChart
      title={`Pedidos del mes ${fechaTitulo}`}
      bigAmount={totalMesAct}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} `}
      smallAmount={totalMesAnt}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} al ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={loading}
      imgSinDatos={chartSinDatos}
      cursorPointer={chartSinDatos}
      bigCurrency
      smallCurrency
      porcentaje
      border={props.border && !props.error && !chartSinDatos}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.pedidosMesComparativo.loading,
    error: state.ventas.pedidosMesComparativo.error,
    totalMesAnt: state.ventas.pedidosMesComparativo.totalMesAnt,
    totalMesAct: state.ventas.pedidosMesComparativo.totalMesAct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Pedidos del mes', ['pedido']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPedidosPendientesComparativo);