import React, { useEffect, useState, useRef } from 'react';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';
import { Collapse, Grid, IconButton, Tooltip } from '@material-ui/core';
import classes from './Styles/BigChartCard.css';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import { formatNumber, formatNumberWithoutDecimals } from '../../shared/formatNumber';
import locales from '../../shared/locales';
import { colorsChart } from '../../shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';
import { storageMonedaSigno } from '../../shared/sessionData';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const LineChart = (props) => {
  let chartRef = useRef(null);
  const [series, setSeries] = useState(props.series);
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    setSeries(props.series);
  }, [props.series, props.seriesBandera]);

  const getNumberWithDecimals = (number) => {
    return props.withoutDecimals ? formatNumberWithoutDecimals(number) : formatNumber(number);
  }

  const options = {
    chart: {
      defaultLocale: 'es',
      locales: locales,
      height: '100%',
      type: props.chartType,
      dropShadow: props.chartDropShadow,
      events: {
        markerClick: props.eventsMarkerClick
          ? function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
            props.eventsMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config });
          }
          : undefined,
        legendClick: props.eventsLegendClick
          ? function (chartContext, seriesIndex, config) {
            props.eventsLegendClick(chartContext, seriesIndex, config);
          }
          : undefined,
        dataPointSelection: props.eventsDataPointSelection
          ? function (event, chartContext, config) {
            props.eventsDataPointSelection(event, chartContext, config);
          }
          : undefined
      },
      toolbar: {
        show: props.toolbarShow,
        tools: {
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        }
      },
      zoom: props.disableZoom
        ? undefined
        : {
          enabled: true,
          type: props.zoomType ? props.zoomType : 'xy',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        }
    },
    tooltip: {
      enabled: props.tooltipEnabled,
      intersect: props.eventsDataPointSelection,
      shared: !props.eventsDataPointSelection,
      custom: props.customTooltip
        ? function ({ series, seriesIndex, dataPointIndex, w }) {
          return props.customTooltip(series, seriesIndex, dataPointIndex, w);
        }
        : undefined
    },
    colors: props.colors ? props.colors : colorsChart,
    dataLabels: {
      enabled: props.dataLabelsEnabled,
      formatter: function (val, opts) {
        return props.formatDataLabels
          ? props.showMonto
            ? `${storageMonedaSigno()} ${getNumberWithDecimals(val)}`
            : `${formatNumber(val)}`
          : val;
      }
    },
    stroke: {
      curve: props.strokeCurve,
      width: props.strokeWidth
    },
    fill: {
      type: props.fillType,
      gradient: props.fillGradient,
      opacity: props.fillOpacity
    },
    grid: {
      borderColor: props.gridBorderColor,
      row: props.gridRow
    },
    markers: {
      size: props.markersSize,
      colors: props.markersColors,
      strokeColors: props.markersStrokeColors,
      strokeWidth: props.markersStrokeWidth,
      hover: {
        size: props.markersHoverSize
      }
    },
    xaxis: {
      categories: props.xaxisCategories,
      type: props.xaxisType,
      tickAmount: props.xaxisTickAmount,
      tooltip: {
        enabled: props.xaxisTooltip
      }
    },
    yaxis: {
      min: props.yaxysMin,
      max: props.yaxysMax,
      labels: props.yaxisLabels ? props.yaxisLabels : {}
    },
    legend: {
      enabled: props.disableLegend ? false : true,
      fontSize: props.legendFontSize,
      height: props.legendHeight,
      offsetY: props.legendOffsetY,
      position: props.legendPosition,
      horizontalAlign: props.legendHorizontalAlign,
      showForSingleSeries: props.legendShowForSingleSeries,
      showForNullSeries: props.legendShowForNullSeries,
      showForZeroSeries: props.legendShowForZeroSeries,
      onItemClick: {
        toggleDataSeries: props.disableLegendOnItemClickToggle ? false : true
      },
      onItemHover: {
        highlightDataSeries: props.disableLengendOnItemHover ? false : true
      },
      formatter: function (seriesName, opts) {
        return props.legendFormatter ? props.legendFormater : seriesName;
      }
    },
    responsive: [
      {
        breakpoint: 750,
        options: {
          chart: {
            width: '100%'
          },
          yaxis: {
            labels: {
              show: false
            }
          }
        }
      }
    ]
  };

  return (
    <div id="customLineChart" ref={chartRef} className={classes.BigChartCard}
      style={{ width: props.width, minHeight: props.loading && '240px' }}
    >
      <Grid container>
        <Grid item xs={12} container style={{ paddingBlock: '5px' }}>
          <Grid item xs={10} lg={11}>
            {collapse && props.collapseTitle ? props.collapseTitle : ''}
          </Grid>
          <Grid item xs={2} lg={1} style={{ textAlign: 'end' }}>
            <Tooltip title={collapse ? 'Expandir gráfico' : 'Colapsar gráfico'}>
              <IconButton size="small" onClick={() => setCollapse(!collapse)}>
                {collapse
                  ? <ExpandMore style={{ color: props.border && 'white' }} fontSize="large" />
                  : <ExpandLess style={{ color: props.border && 'white' }} fontSize="large" />
                }
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Collapse in={!collapse} timeout="auto" unmountOnExit>
          <Grid item xs={12} container>

            <Grid item xs={12}>
              {props.title}
            </Grid>

            <Grid item xs={12}>
              {props.subTitle}
            </Grid>

            <Grid item xs={12} className={classes.Chart}>
              {props.disabled ? (
                <div style={{ maxHeight: '245px' }}>
                  <img alt="Indicador Inhabilitado" src={IndicadorDeshabilitado} />
                </div>
              ) : props.loading ? (
                <div style={{ textAlign: 'center' }}>
                  <Spinner progress={props.progress} progressTime={props.progressTime} />
                </div>
              ) : props.imgSinDatos || props.noClient ? (
                <div style={{ textAlign: 'center' }}>
                  <img alt="Sin resultados" src={SinResultados} style={{ maxWidth: '100%' }} />
                </div>
              ) : (
                <Chart
                  options={props.personalizeOptions ? props.personalizeOptions : options}
                  style={{ textAlign: props.chartTextAlign ? props.chartTextAlign : 'center' }}
                  series={series}
                  height={props.chartHeight}
                  type={props.chartType}
                  width={props.chartWidth}
                />
              )}
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </div>
  );
};

export default LineChart;
