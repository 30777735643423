import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import { storageEmpresaId, storageMonedaId, storageToken } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';

export const loadTextoIvaFiscal = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadTextoIvaFiscalStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/Impuestos/IvaFiscalComparativo?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ivaFiscalDelMes
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(
          loadTextoIvaFiscalSuccess(
            response.data.debitoFiscalMesActual,
            response.data.creditoFiscalMesActual,
            response.data.debitoFiscalMesAnterior,
            response.data.creditoFiscalMesAnterior,
            response.data.saldo,
            response.data.saldoUltimoMesCargado
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadTextoIvaFiscalFail(error));
          } else {
            dispatch(loadTextoIvaFiscal(idEmpresa));
          }
        } else {
          dispatch(loadTextoIvaFiscalFail(error));
        }
      });
  };
};

export const clearTextoIvaFiscal = () => {
  return {
    type: actionTypes.CLEAR_TEXTO_IVA_FISCAL
  };
};

const loadTextoIvaFiscalStart = () => {
  return {
    type: actionTypes.TEXTO_IVA_FISCAL_START
  };
};

const loadTextoIvaFiscalSuccess = (debitoMesActual, creditoMesActual, debitoMesAnterior, creditoMesAnterior, saldo, saldoUltimoMesCargado) => {
  return {
    type: actionTypes.TEXTO_IVA_FISCAL_SUCCESS,
    debitoMesActual,
    creditoMesActual,
    debitoMesAnterior,
    creditoMesAnterior, 
    saldo, 
    saldoUltimoMesCargado
  };
};

const loadTextoIvaFiscalFail = (error) => {
  return {
    type: actionTypes.TEXTO_IVA_FISCAL_FAIL,
    error: error
  };
};

export const unmountTextoIvaFiscal = () => {
  return {
    type: actionTypes.TEXTO_IVA_FISCAL_SUCCESS,
    debitoMesActual: 0,
    creditoMesActual: 0,
    debitoMesAnterior: 0,
    creditoMesAnterior: 0, 
    saldo: 0,
    saldoUltimoMesCargado: false
  };
};
