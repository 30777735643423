// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InteranualVentas__tablasInteranuales__W2IdC {\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  margin-top: 0px !important;\r\n  margin-left: 0px !important;\r\n  margin-right: 0px !important;\r\n  height: 100%; \r\n}\r\n\r\n.InteranualVentas__linesInteranuales__S4m04 {\r\n  margin: 0px !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablasInteranuales": "InteranualVentas__tablasInteranuales__W2IdC",
	"linesInteranuales": "InteranualVentas__linesInteranuales__S4m04"
};
export default ___CSS_LOADER_EXPORT___;
