import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classes from './TablaComparacionPorItemDeLaCategoria.css';
import TableComprobantesGastos from '../../Comprobantes/TableComprobantesGastos/TableComprobantesGastos';
import { MTableBodyRow } from 'material-table';

const TablaComparacionPorItemDeLaCategoria = (props) => {
  const { fechasComparar } = props;
  const [tableState, setTableState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });
  const [nombreCategoria, setNombreCategoria] = useState('');

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      fechasComparar.map((fecha) => {
        const separarFecha = moment(fecha).subtract(1, 'month').format('MM/YYYY').split('/');
        const mes = separarFecha[0];
        const anio = separarFecha[1];
        return props.onLoadDetalleCategoria(
          props.categoria.categoriaId,
          props.categoria.categoria,
          mes,
          anio,
          (data) => {
            setNombreCategoria(props.categoria.categoria);
            if (tableState.length === 1) {
              armarComparacion(data);
              setLoading(false);
            } else {
              tableState.push(data);
            }
            setTimeout(
              () =>
                window.scroll({
                  top: document.body.offsetHeight,
                  left: 0,
                  behavior: 'smooth'
                }),
              100
            );
          }
        );
      });
    }, 500);
  }, [props.show, props.flag]);

  const columns = [
    {
      title: 'Item',
      field: 'item',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[0]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos ${
        props.fechasComparar.length === 2
          ? `- ${moment(props.fechasComparar[1]).subtract(1, 'month').format('MM/YYYY')}`
          : ''
      }`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];

    tableState[0].forEach((gastoCompleto) => {
      const verify = arrayAComparar.filter((gasto) => gasto.nombre === gastoCompleto.nombre);
      if (verify.length === 0) {
        arrayNuevo.push({ ...gastoCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((gasto) => {
      return arrayNuevo.push(gasto);
    });

    arrayAComparar.forEach((gastoOrdenado) => {
      const verify = tableState[0].filter((gasto) => gasto.nombre === gastoOrdenado.nombre);

      if (verify.length === 0) {
        tableState[0].push({ ...gastoOrdenado, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

    setTableState([
      { dateComplete: props.fechasComparar[0], gastosDetallados: orderTableState },
      { dateComplete: props.fechasComparar[1], gastosDetallados: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;

    tableState.map((data, key) => {
      if (key === 0) {
        return data.gastosDetallados.map((gastoDetallado, index) => {
          totalMonto1 += gastoDetallado.monto;
          totalMonto2 += tableState[1].gastosDetallados[index].monto;
          return formatTableData.push({
            itemId: gastoDetallado.itemDeCompraId,
            item: gastoDetallado.nombre,
            monto1: gastoDetallado.monto,
            porcParticipacion1: gastoDetallado.porcentajeDeParticipacion,
            monto2: tableState[1].gastosDetallados[index].monto,
            porcParticipacion2: tableState[1].gastosDetallados[index].porcentajeDeParticipacion,
            difMontos: calcularDif(gastoDetallado.monto, tableState[1].gastosDetallados[index].monto),
            porcComparacion: calcularPorc(gastoDetallado.monto, tableState[1].gastosDetallados[index].monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        item: `Total ${nombreCategoria}`,
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const compareByAsc = (a, b, key) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
  };

  const compareByDesc = (a, b, key) => {
    if (a[key] < b[key]) return 1;
    if (a[key] > b[key]) return -1;
  };

  const headerStyle = {
    paddingBottom: '5px',
    paddingTop: '5px'
  };

  const headerStyleWithColor = {
    ...headerStyle,
    backgroundColor: 'rgb(235, 235, 235)'
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Table
        style={{ paddingLeft: '20px' }}
        columns={columns}
        data={data()}
        noBoxShadow
        loading={loading}
        components={{
          Header: (props) => {
            return (
              <TableHead>
                {fechasComparar.length === 2 && (
                  <TableRow>
                    <TableCell style={{ ...headerStyle, width: '45px' }}></TableCell>
                    <TableCell
                      style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                    ></TableCell>
                    <TableCell
                      style={{
                        ...headerStyle,
                        paddingLeft: '60px'
                      }}
                      colSpan={2}
                      align="center"
                    ></TableCell>
                    <TableCell
                      style={{
                        ...headerStyle,
                        paddingLeft: '60px'
                      }}
                      colSpan={2}
                      align="center"
                    ></TableCell>
                    <TableCell style={{ ...headerStyle }} align="center"></TableCell>
                    <TableCell style={{ ...headerStyle }}></TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ width: '45px' }}></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('item')}
                    style={{
                      ...headerStyleWithColor,
                      width: window.screen.width < 1367 ? '265px' : '410px'
                    }}
                    align="left"
                  >
                    Item
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto1')}
                    style={{
                      ...headerStyleWithColor
                    }}
                    align="right"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion1')}
                    style={{
                      ...headerStyleWithColor
                    }}
                    align="right"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto2')}
                    style={{
                      ...headerStyleWithColor
                    }}
                    align="right"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion2')}
                    style={{
                      ...headerStyleWithColor
                    }}
                    align="right"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('difMontos')}
                    style={{ ...headerStyleWithColor }}
                    align="right"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcComparacion')}
                    style={{ ...headerStyleWithColor }}
                    align="right"
                  ></TableCell>
                </TableRow>
              </TableHead>
            );
          },
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
                detailPanel={
                  !props.data.itemId
                    ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                    : props.detailPanel
                }
              />
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            tooltip: 'Mostrar comprobantes',
            render: (rowData) => {
              return (
                <div className={classes.TablasComparacionComprobantesContainer}>
                  {fechasComparar.map((fecha, index) => {
                    const formatFecha = moment(fecha).subtract(1, 'month').format('MM/YYYY');
                    const separarFechas = formatFecha.split('/');
                    return (
                      <div style={{ width: '100%' }} key={index}>
                        {' '}
                        <div
                          style={{
                            marginRight: index === 0 ? '5px' : '10px',
                            marginLeft: index === 1 ? '5px' : '10px'
                          }}
                        >
                          <TableComprobantesGastos
                            itemId={rowData.itemId}
                            mes={separarFechas[0]}
                            anio={separarFechas[1]}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          }
        ]}
        customOptions={{
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          showTitle: false,
          tableLayout: 'fixed',
          toolbar: false,
          maxBodyHeight: 450,
          rowStyle: (rowData, config) => {
            return {
              cursor: 'auto',
              backgroundColor: (!rowData.itemId && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
            };
          },
          cellStyle: (cellData) => ({
            fontWeight: cellData === `Total ${nombreCategoria}` && 'bolder'
          })
        }}
        tableKey={"tabla-comparacion-item-categoria"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDetalleCategoria: (idCategoria, nombreCategoria, mes, anio, success) =>
      dispatch(
        actions.loadGastosPorItemDeCategoriaSelec(idCategoria, nombreCategoria, mes, anio, undefined, true, success)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionPorItemDeLaCategoria);
