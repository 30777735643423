import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Toolbar, IconButton, Grid, Button } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MonedaSelector from "../../MonedaSelector/MonedaSelector";
import BoxInfo from "../../BoxInfo/BoxInfo";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InflacionDesactivado from '../../../../assets/images/InflacionDesactivado.png';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AnioSelector from "../../AnioSelector/AnioSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: 'RGB(81,81,81)',
    color: 'white'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    '@media (min-width: 600px)': {
      minHeight: '55px !important'
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: 'RGB(229,229,229)'
  },
  navItemIcon: {
    color: 'white'
  },
  toolbarUsuario: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  mobileToolbarUsuario: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  Selector: {
    color: 'white',
    marginLeft: '10px',
    marginRight: '10px'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '5px'
  },
  icon: {
    fontSize: "1.8rem",
    cursor: "pointer",
    marginInline: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
}));

const PeriodoSelector = (props) => {
  const { periodoSeleccionado, setPeriodoSeleccionado, onCancelarPeriodo, 
    onAgregarPeriodo, errorMessage } = props;
  const classes = useStyles();
  const inPhone = window.screen.width <= 750;

  const monedaSelect = () => {
    return (
      <div className={classes.Selector}>
        <MonedaSelector letraColor="white" disabled={true} />
      </div>
    )
  }

  const ajustaInflacionIcon = () => {
    return (
      <IconButton size="small">
        <img alt="" src={InflacionDesactivado} />
      </IconButton>
    )
  }

  const fechaFija = (color, iconSize) => {
    return (
      <DateRangeIcon
        className={classes.icon}
        style={{
          color: color, 
          fontSize: iconSize,
        }}            
      />
    )
  }

  const ultimaActualizacion = (color, iconSize) => {
    return (
      <AccessTimeIcon
        className={classes.icon}
        style={{
          color: color, 
          fontSize: iconSize,
        }}
      />
    )
  }

  const toolbar = () => {
    return (
      <div position="fixed" className={classes.appBar}>
        <Toolbar style={{ minHeight: '55px', paddingLeft: '10px', paddingRight: '5px' }}>
          <div className={classes.toolbarUsuario}>
            {ajustaInflacionIcon()}
            {monedaSelect()}
            {fechaFija("white", "25px")}
            {ultimaActualizacion("white", "25px")}
          </div>
        </Toolbar>
      </div>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <BoxInfo>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {inPhone ? (
                <div>
                  <span>Para modificar el mes de corte de los períodos, haga click en el botón 
                    <MoreVertIcon className={classes.icon} style={{ fontSize: '1.8rem', cursor: 'pointer' }} /> en la barra superior.
                  </span>
                  <br/>
                  <span>
                    Luego utilice el botón <DateRangeIcon className={classes.icon} style={{ fontSize: '25px' }} /> en el menú desplegable.
                  </span>
                </div>
              ) : (
                <div>
                  <span>Para modificar el mes de corte de los períodos, utilice el botón        
                    <DateRangeIcon className={classes.icon} style={{ color: '',  fontSize: '25px' }} /> en la barra superior.
                  </span>
                </div>
              )}                            
            </Grid>
            {!inPhone && (
              <Grid item xs={12}>
                {toolbar()}
              </Grid>
            )}
          </Grid>       
        </BoxInfo>
      </Grid>      
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <AnioSelector
          periodoSeleccionado={periodoSeleccionado}
          setPeriodoSeleccionado={setPeriodoSeleccionado}
        />
      </Grid> 
      <Grid item xs={12} container spacing={1}
        direction='row'
        justifyContent='flex-end'
        alignContent='center'
      >
        <Grid item xs={6} md={4} lg={3}>
          <Button 
            variant="contained"
            style={{ width: '100%', color: 'white', backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }} 
            onClick={() => onCancelarPeriodo()}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%', color: 'white'}}
            onClick={() => onAgregarPeriodo()}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      {errorMessage && (
        <Grid item xs={12}>          
          <Typography color='error' style={{ fontSize: 'small' }}>
            {errorMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default PeriodoSelector;