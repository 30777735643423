import React from 'react';
import { storageFechaFija, storageMonedaSigno } from '../../../../../shared/sessionData';
import { formatNumber } from '../../../../../shared/formatNumber';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from '../BarUtilidadesCharts/BarUtilidadesTooltip.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const TableUtilidadesToExcel = (props) => {
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyle = { font: { sz: '10', bold: false }, alignment: { horizontal: 'right' } };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' }
    };
  };

  const cellsTotalStyle = { ...cellsStyle, fill: { fgColor: { rgb: 'FFD3D3D3' } }, font: { sz: '10', bold: true } };

  const formatUtilidadPorTitleColumn = () => {
    var utilidadPorPrimeraLetra = props.utilidadPor
      ? props.utilidadPor.charAt(0).toUpperCase()
      : props.titleUtilidadPor;
    var utilidadPorRest = props.utilidadPor.slice(1);
    return utilidadPorPrimeraLetra.concat(utilidadPorRest);
  };

  const formatData = () => {
    var sumTotalVentas = 0;
    var sumUnidadesVendidas = 0;
    var sumTotalCosto = 0;
    var sumUtilidad = 0;
    var formatData = [];

    props.utilidades.map((utilidad) => {
      sumTotalVentas = sumTotalVentas += utilidad.ventas;
      sumUnidadesVendidas = sumUnidadesVendidas += utilidad.unidadesVendidas;
      sumTotalCosto = sumTotalCosto += utilidad.costo;
      sumUtilidad = sumUtilidad += utilidad.monto;
      return formatData.push([
        {
          value: utilidad.nombre,
          style: { font: { sz: '8', bold: false } }
        },
        {
          value: formatNumber(utilidad.ventas),
          style: cellsStyleVerifNegative(utilidad.ventas)
        },
        {
          value: formatNumber(utilidad.unidadesVendidas),
          style: cellsStyleVerifNegative(utilidad.unidadesVendidas)
        },
        {
          value: formatNumber(utilidad.costo),
          style: cellsStyleVerifNegative(utilidad.costo)
        },
        {
          value: formatNumber(utilidad.monto),
          style: cellsStyleVerifNegative(utilidad.monto)
        },
        {
          value: `${formatNumber(utilidad.utilidadPorcentualSegunCosto)}%`,
          style: cellsStyleVerifNegative(utilidad.utilidadPorcentualSegunCosto)
        },
        {
          value: `${formatNumber(utilidad.utilidadPorcentualSegunVentas)}%`,
          style: cellsStyleVerifNegative(utilidad.utilidadPorcentualSegunVentas)
        },
        {
          value: `${formatNumber(utilidad.porcentajeUtilidadSobreElTotal)}%`,
          style: cellsStyleVerifNegative(utilidad.porcentajeUtilidadSobreElTotal)
        }
      ]);
    });

    if (formatData.length > 0) {
      formatData.push([
        {
          value: 'Total',
          style: { font: { sz: '11', bold: true }, fill: { fgColor: { rgb: 'FFD3D3D3' } } }
        },
        {
          value: formatNumber(sumTotalVentas),
          style: cellsStyleVerifNegative(sumTotalVentas)
        },
        {
          value: formatNumber(sumUnidadesVendidas),
          style: cellsStyleVerifNegative(sumUnidadesVendidas)
        },
        {
          value: formatNumber(sumTotalCosto),
          style: cellsTotalStyle
        },
        {
          value: formatNumber(sumUtilidad),
          style: cellsStyleVerifNegative(sumUtilidad)
        }
      ]);
    }
    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: `${formatUtilidadPorTitleColumn()}`,
          width: { wch: 40 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        },
        {
          title: `Ventas totales ${storageMonedaSigno()}`,
          width: { wpx: 130 },
          style: headersStyle
        },
        { title: `Cantidad vendida`, width: { wpx: 130 }, style: headersStyle },
        { title: `Costo ${storageMonedaSigno()}`, width: { wpx: 100 }, style: headersStyle },
        {
          title: `Utilidad ${storageMonedaSigno()}`,
          width: { wpx: 100 },
          style: headersStyle
        }, //char width
        { title: `Utilidad según costo`, width: { wpx: 160 }, style: headersStyle },
        { title: `Utilidad según ventas`, width: { wpx: 165 }, style: headersStyle },
        { title: `Participación sobre utilidad`, width: { wpx: 180 }, style: headersStyle },
        { title: `Participación sobre costo`, width: { wpx: 170 }, style: headersStyle },
        { title: `Participación sobre ventas`, width: { wpx: 170 }, style: headersStyle }
      ],
      data: formatData()
    }
  ];

  return (
    <ExcelFile
      filename={`Analisis por ${props.utilidadPor} al ${storageFechaFija()}`}
      element={
        <Tooltip classes={{ popper: classes.TooltipPopper }} title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'33px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={`Análisis por ${props.utilidadPor}`} />
    </ExcelFile>
  );
};

export default TableUtilidadesToExcel;
