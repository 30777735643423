import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import { storageEmpresaId, storageMonedaSigno } from '../../../../shared/sessionData';
import * as actions from '../../../../store/actions/index';
import classes from './PieTableGastosPorCategoria.css';
import PiePlaceholderPrimerNivel from './PiePlaceholderPrimerNivel';
import PieGastosPorCategoria from './PieGastosPorCategoria';
import TablaGastosPorCategoria from './TablaGastosPorCategoria';
import TablaGastosPorItemDeCateogoria from '../TablaGastosPorItemDeCategoria/TablaGastosPorItemDeCateogoria';
import Spinner from '../../../UI/Spinner/Spinner';

const PieTableGastosPorCategoria = (props) => {
  const { estaCargado, ejecucionDePagina, onLoadChart, onLoadGastosDeCat, gastos, loading, totalGastos } = props;

  const [showRelatedChart, setShowRelatedChart] = useState(false);
  const [relatedChartGastos, setRelatedChartGastos] = useState([]);
  const [relatedChartTitle, setRelatedChartTitle] = useState('Gastos por Item de Cateogoría');
  const [relatedChartFilename, setRelatedChartFilename] = useState('');
  const [relatedCharLoading, setRelatedChartLoading] = useState(false);
  const [relatedChartTotal, setRelatedChartTotal] = useState('Total:');
  const [relatedTableSheetName, setRelatedTableSheetName] = useState('Item por Categoría');
  
  const getFechaOperacion = () => {
    return props.fecha;
  }

  const getFechaPieTitle = () => {
    const title = 'Gastos por categoría';
    const fecha = `${gastos.length > 0 ? `del ${moment(getFechaOperacion()).format('MM/yyyy')}` : ''}`;
    return `${title} ${fecha}`;
  }

  const pieSubtitle = () => {
    return `Total: ${storageMonedaSigno()} ${formatNumber(totalGastos)}`;
  };

  const getFechaTableTitle = () => {
    let title = `Gastos detallados por categoría ${loading ? '--/----' : `del ${moment(getFechaOperacion()).format('MM/yyyy')}`}`;
    return title;
  }

  useEffect(() => {
    if (ejecucionDePagina && loading && relatedChartGastos.length > 0) {
      setRelatedChartLoading(true);
      setShowRelatedChart(false);
      setTimeout(() => clearRelatedChart(), 500);
    }
  }, [ejecucionDePagina, loading, relatedChartGastos]);

  useEffect(() => {
    if (ejecucionDePagina && !loading && !estaCargado) {
      const fecha = props.fechaGastosDelMes;
      const mes = fecha ? fecha.getMonth() + 1 : 0;
      const anio = fecha ? fecha.getFullYear() : 0;
      onLoadChart(mes, anio);
    }
  }, [ejecucionDePagina, estaCargado, loading]);

  //#region Click Chart
  

  const clearRelatedChart = () => {
    setRelatedChartTitle('Gastos por Item de Cateogoría');
    setRelatedChartGastos([]);
    setRelatedChartTotal('Total:');
    setRelatedTableSheetName('Item por Categoría');
    setRelatedChartLoading(false);
  }

  const onPorcionClickSuccess = useCallback((response) => {
    setRelatedChartGastos(response);
    let total = 0;
    response.forEach((gasto) => {
      total += gasto.monto;
    });

    setRelatedChartTotal(`Total: ${storageMonedaSigno()} ${formatNumber(total)}`);
    setTimeout(() => setRelatedChartLoading(false), 500);
  }, []);

  const getTitle = useCallback((item) => {
    let title = "Categoría";
    let nextTitle = "Item de Categoría";
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <span>Gastos por {nextTitle}</span>
        <span><small>{`${title}: ${item}`}</small></span>
      </div>
    )
  }, []);

  const onPorcionClick = useCallback((config) => {
    let item = config.w.config.labels[config.dataPointIndex];
    const idField = 'id_CategoriaItem';
    const field = 'categoria';

    if (item !== 'OTROS') {
      clearRelatedChart();
      setShowRelatedChart(true);
      setRelatedChartLoading(true);
      setRelatedChartTitle(getTitle(item));
      setRelatedTableSheetName(`${item}`.trimEnd());

      let filename = `Gastos por Item de Cateogoría: ${item}`;
      setRelatedChartFilename(filename);

      let gasto = gastos && gastos.find((v) => {
        let vField = v[field];

        if (vField !== null && vField !== undefined)
          return vField.toUpperCase().trim() === item.toUpperCase().trim();

        return false;
      });

      if (gasto !== null && gasto !== undefined) {
        const idCategoria = gasto[idField];
        const nombreCategoria = gasto[field];

        onLoadGastosDeCat(idCategoria, nombreCategoria, gasto.mes, gasto.anio, storageEmpresaId(), onPorcionClickSuccess)
      }
    }
  }, [gastos]);

  //#endregion

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12} container spacing={1} className={classes.CombinedChartsCard}>
        <Grid item xs={12} lg={4}>
          <PieGastosPorCategoria
            legendHeight={50}
            legendPosition={'bottom'}
            height={436}
            width={'100%'}
            chartType={'pie'}
            loading={loading}
            gastos={gastos}
            title={getFechaPieTitle()}
            filename={getFechaPieTitle()}
            subTitle={pieSubtitle()}
            onPorcionClick={onPorcionClick}
            initialLimit={20}
            spaceBetweenChartTitle={'15px'}
            headerTitle={"Categoría"}
            idField={'id_CategoriaItem'}
            nameField={'categoria'}
            valueField={'monto'}
            key={"PieGastosPorCategoria"}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TablaGastosPorCategoria
            fechaVentasDelMes={getFechaOperacion()}
            gastos={gastos}
            fecha={props.fecha}
            loading={loading}
            totalGastos={totalGastos}
            onShowFiltersModal={props.onShowFiltersModal}
            title={getFechaTableTitle()}
            key={"TablaGastosPorCategoria"}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} className={classes.CombinedChartsCard}>
        <Grid item xs={12} lg={4}>
          {showRelatedChart
            ? <PieGastosPorCategoria
                legendHeight={50}
                legendPosition={'bottom'}
                height={436}
                width={'100%'}
                chartType={'pie'}
                loading={relatedCharLoading}
                gastos={relatedChartGastos}
                title={relatedChartTitle}
                filename={relatedChartFilename}
                subTitle={relatedChartTotal}
                initialLimit={20}
                spaceBetweenChartTitle={'15px'}
                headerTitle={"Item"}
                idField={'itemDeCompraId'}
                nameField={'nombre'}
                valueField={'monto'}
                key={"PieGastosPorCategoria"}
              />
            : <PiePlaceholderPrimerNivel />
          }
        </Grid>
        <Grid item xs={12} lg={8}>
          {relatedCharLoading
            ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner />
            </div>
            : <TablaGastosPorItemDeCateogoria
              gastos={relatedChartGastos}
              fechaBusqueda={props.fecha}
              title={relatedChartTitle}
              filename={'Gastos por Item de Cateogoría'}
              sheetName={relatedTableSheetName}
              subTitle={relatedChartTotal}
            />
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.gastos.pieGastosPorCategoria.loading,
    gastos: state.gastos.pieGastosPorCategoria.gastosPorCategoriaDataTable,
    gastosNegativos: state.gastos.pieGastosPorCategoria.gastosNegativos,
    categorias: state.gastos.pieGastosPorCategoria.categorias,
    error: state.gastos.pieGastosPorCategoria.error,
    fecha: state.gastos.pieGastosPorCategoria.fecha,
    totalGastos: state.gastos.pieGastosPorCategoria.totalGastos,
    estaCargado: state.gastos.pieGastosPorCategoria.estaCargado,
    fechaGastosDelMes: state.gastos.evolucionMensualInteranual.periodoSeleccionado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosPorCategoria
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (mes, anio) => dispatch(actions.loadPieGastosPorCategoria(mes, anio)),
    onLoadGastosDeCat: (idCategoria, nombreCategoria, mes, anio, idEmpresa, success) =>
      dispatch(actions.loadGastosPorItemDeCategoriaSelec(idCategoria, nombreCategoria, mes, anio, idEmpresa, true, success)),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Gastos por categoría', ['gasto', 'categoriaItemsDeCompra']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieTableGastosPorCategoria);