import React from 'react';
import { formatNumber } from '../../shared/formatNumber';
import { storageMonedaSigno } from '../../shared/sessionData';
import Table from '../UI/Table/Table';
import classes from './Styles/SmallChartCard.css';
import ViewInTable from '../../assets/images/ViewInTable.svg';
import { IconButton, Tooltip } from '@material-ui/core';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../assets/images/IconConvertToExcel.png';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TableChart = (props) => {
  const { items, titleName, titleField, valueName, valueField, } = props;

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fafafa'
  };
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };

  const columns = () => {
    const arrColumns = [
      {
        title: `${titleName}`,
        field: 'nombre',
        align: 'left',
        cellStyle: cellStyle,
        headerStyle: headerStyle
      },
      {
        title: `${valueName}`,
        field: 'monto',
        align: 'right',
        cellStyle: cellStyle,
        headerStyle: headerStyle,
        render: (rowData) => `${storageMonedaSigno()}${formatNumber(rowData.monto)}`
      },
      {
        title: 'Porc. Participacion',
        field: 'porcParticipacion',
        align: 'right',
        cellStyle: cellStyle,
        headerStyle: headerStyle,
        render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
      }
    ];
    return arrColumns;
  };

  const data = () => {
    return items.map((item) => {
      return {
        nombre: item[`${titleField}`],
        monto: item[`${valueField}`],
        porcParticipacion: item.porcentajeDeParticipacion,
      };
    });
  }

  //#region EXCEL
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' }
    };
  };

  const formatData = () => {
    var formatData = [];
    let dataItems = data();
    dataItems.map(item => {
      return formatData.push([
        {
          value: item.nombre,
          style: { font: { sz: '10', bold: true } }
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(item.monto)}`,
          style: cellsStyleVerifNegative(item.monto)
        },
        {
          value: `${formatNumber(item.porcParticipacion)}%`,
          style: cellsStyleVerifNegative(item.porcParticipacion)
        }
      ])
    })
    return formatData;
  };

  const formatDataSet = () => {
    const formatedDataSet = [
      {
        columns: [
          {
            title: `${titleName}`,
            width: { wpx: 160 },
            style: headersStyle
          },
          {
            title: `${valueName}`,
            width: { wpx: 100 },
            style: headersStyle
          },
          {
            title: `Porc. Participación`,
            width: { wpx: 150 },
            style: headersStyle
          },
        ],
        data: formatData()
      }
    ];

    return formatedDataSet;
  };

  //#endregion

  return (
    <div className={classes.SmallChartCard} style={{ height: '100%', width: props.width, minHeight: props.minHeight }}>
      <div style={{ display: 'flex', margin: 'auto', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title}
          </div>
          {!props.loading && !props.disabled && (
            <ExcelFile
              filename={props.title}
              element={
                <Tooltip classes={{ popper: classes.TooltipPopper }} title="Descargar en excel">
                  <IconButton style={{ marginRight: '10px' }} size="small">
                    <img alt="" src={IconConvertToExcel} height={'33px'} />
                  </IconButton>
                </Tooltip>
              }
            >
              <ExcelSheet dataSet={formatDataSet()} name={props.title} />
            </ExcelFile>
          )}
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como torta">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.TitleCard}>
          {props.subtitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subtitle}</p>}
        </div>
        <div className={classes.TablaMultiempresaContainer}>
          <Table
            style={{ width: '100%', marginBottom: '20px' }}
            columns={columns()}
            data={data()}
            loading={props.loading}
            customOptions={{
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: props.height,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TableChart;