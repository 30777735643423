// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaVentasPorSucursalDeLaSeleccion__TitleCard__\\+7can {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.TablaVentasPorSucursalDeLaSeleccion__TablaVentasPorSucursalDeLaSeleccionContainer__C16g3 {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n}\r\n\r\n.TablaVentasPorSucursalDeLaSeleccion__TablaVentasPorSucursalDeLaSeleccionContainer__C16g3 button {\r\n  padding: 7px;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .TablaVentasPorSucursalDeLaSeleccion__TablaVentasPorSucursalDeLaSeleccionContainer__C16g3 {\r\n    max-height: 743px;\r\n  }\r\n}\r\n\r\n@media (max-width: 752px) {\r\n  .TablaVentasPorSucursalDeLaSeleccion__TablaVentasPorSucursalDeLaSeleccionContainer__C16g3 {\r\n    max-height: 400px;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleCard": "TablaVentasPorSucursalDeLaSeleccion__TitleCard__+7can",
	"TablaVentasPorSucursalDeLaSeleccionContainer": "TablaVentasPorSucursalDeLaSeleccion__TablaVentasPorSucursalDeLaSeleccionContainer__C16g3"
};
export default ___CSS_LOADER_EXPORT___;
