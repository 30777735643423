import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions';
import TablaCubo from '../../../UI/TablaCuboWrapper/TablaCubo';

const TablaCuboVentasDelVendedor = (props) => {
  const { vendedorSelected } = props;

  useEffect(() => {
    if (!props.estaCargado && props.ejecucionDePagina) {
      props.onLoadChart(vendedorSelected.id_vendedor);
    }
  }, [props.ejecucionDePagina]);

  const onVentasDelMesAnioClick = (mes, anio, index) => {
    props.onChangeFechaVentasDelMes(mes, anio);
    props.onChangeOptionSelected();
    props.clearIndicadores();
    props.onLoadVentasPorCategoriasDeCliente(vendedorSelected.id_vendedor, mes + 1, anio);
  };

  return (
    <TablaCubo
      title={'Ventas desglosadas por mes'}
      titleMontos={'Ventas'}
      datas={props.ventasDataTable}
      fecha={new Date(props.fecha.anio, props.fecha.mes, 1)}
      loading={props.loading}
      total={props.totalVentas}
      onSelectMesAnioClick={(mes, anio) => onVentasDelMesAnioClick(mes - 1, anio)}
      onShowFiltersModal={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventasDataTable: state.vendedores.evolucionVentasMensualDelVendedor.ventasDataTable,
    loading: state.vendedores.evolucionVentasMensualDelVendedor.loading,
    totalVentas: state.vendedores.evolucionVentasMensualDelVendedor.total,
    estaCargado: state.vendedores.evolucionVentasMensualDelVendedor.estaCargado,

    vendedorSelected: state.vendedores.vendedorSelected,

    fecha: state.vendedores.fechaVentasDelMesAnioDelVendedor,
    dataSelectedId: state.vendedores.dataVentasDelMesDelVendedorPorSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas desglosadas por mes', ['venta'])),
    onLoadChart: (idVendedor) => dispatch(actions.loadEvolucionDeVentasMensualDelVendedor(idVendedor)),
    onChangeFechaVentasDelMes: (mes, anio) => dispatch(actions.seleccionFechaVentasDelMesAnioDelVendedorPor(mes, anio)),
    onChangeOptionSelected: () => dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente')),
    onLoadVentasPorCategoriasDeCliente: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasPorCategoriaDeClienteDelVendedor(idVendedor, mes, anio)),
    clearIndicadores: () => {
      dispatch(actions.clearVentasDelMesPorMarcaDelVendedor());
      dispatch(actions.clearVentasDelMesPorRubroDelVendedor());
      dispatch(actions.clearVentasDelMesPorSubrubroDelVendedor());
      dispatch(actions.clearVentasDelMesPorProvinciaDelVendedor());
      dispatch(actions.clearVentasDelMesPorZonaDelVendedor());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaCuboVentasDelVendedor);
