import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Table from '../../../UI/Table/Table';
import { MTableHeader,MTableToolbar } from "material-table";
import { formatNumber, isEven } from '../../../../shared/formatNumber';
// import TableToExcel from "../../../UI/Table/TableToExcel";
import ModalDetallesComprobante from '../../Comprobantes/ModalDetallesComprobante/ModalDetallesComprobante';
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',    
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  iconsHeader: {
    display: 'flex',    
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxHeight: '30px',
  },
  table:{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }
}));

export const TableComprobantesCtaCte = (props) => {
  const { 
    monedaPresentacion, 
    loading, 
    comprobantes,
  } = props;

  const classes = useStyles();

  const cellStyles = {
    fontSize: 'small',
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottom: '0px',
  };

  const headerStyle = {
    fontSize: "small",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderBottom: '0px',
    backgroundColor: "rgb(220 220 220)",
  }

  const columns = [
    {
      title: "Comprobante",
      field: "nombreComprobante",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Referencia",
      field: "referencia",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Núm. Externo",
      field: "numero",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Fecha",
      field: "fecha",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Debe",
      field: "debe",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Haber",
      field: "haber",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Saldo",
      field: "saldo",
      align: "right",
      cellStyle: {
        fontSize: "smaller",
        paddingTop: "0px",
        paddingBottom: "0px",
        borderBottom: '0px',
      },
      headerStyle: headerStyle,
    },
    {
      title: "Pendiente",
      field: "pendiente",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Prox. Venc.",
      field: "fechaVencimiento",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${rowData.fechaVencimiento === '01/01/0001' || rowData.fechaVencimiento === '30/12/1899' ? '' : rowData.fechaVencimiento}`,
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante venta={!rowData.cobranza} comprobante={rowData} cobranza={rowData.cobranza}/>;
      },
    }
  ];

  const data = () => {
    return comprobantes.map((comprobante) => ({
      nombreComprobante: comprobante.nombreComprobante,
      referencia: formatValue(comprobante.referencia),
      numero: comprobante.numero,
      fecha: `${moment(comprobante.fecha).format("DD/MM/yyyy")}`,
      fechaVencimiento: `${moment(comprobante.fechaVencimiento).format("DD/MM/yyyy")}`,
      debe: formatSignoNumero(comprobante.debe),
      haber: formatSignoNumero(comprobante.haber),
      saldo: formatSignoNumero(comprobante.saldo),
      pendiente: formatSignoNumero(comprobante.pendiente),
      detallesComprobante: comprobante.articulos,
      idComproba: comprobante.idComproba,
      idSistema: comprobante.idSistema,
      idSucursal: comprobante.idSucursal,
      cobranza: comprobante.cobranza,
    }));
  };

  const formatSignoNumero = (value) => {
    return `${monedaPresentacion.signo} ${formatNumber(value)}`;
  };

  const formatValue = (value) => {
    return value !== null && value !== undefined ? Number(value).toFixed(0) : '';
  }

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lastPageIndex, setLastPageIndex] = useState(0);

  const autoScroll = () => {
    if (tableRef.current) {
      const currentItems = currentPage * rowsPerPage;
      const index = totalRows - currentItems - 1;
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[index]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  useEffect(() => {
    if(!totalRows && comprobantes){
      setTotalRows(comprobantes.length);
    }
  }, [totalRows, comprobantes]);

  useEffect(() => {
    if(isFirstLoad && totalRows){
      const lastPage = Number(totalRows / rowsPerPage);
      setCurrentPage(0);
      setIsFirstLoad(false);
      setLastPageIndex(lastPage);      
      setTimeout(() => setCurrentPage(lastPage), 500);
      autoScroll();
    }
  }, [isFirstLoad, totalRows, rowsPerPage]);

  useEffect(() => {
    if(comprobantes && totalRows)
      autoScroll()
  }, [])

  const tableRef = useRef();
  const name = "Detalle de cuenta corriente";
  const inPhone = window.screen.width <= 750;

  return (
    <Table
      tableRef={tableRef}
      title=""
      columns={columns}
      style={{ width: props.customWidth ? props.customWidth : '100%', borderRadius: '0px', boxShadow: 'none', maxWidth: inPhone ? '92vw' : '' }}
      data={data()}
      loading={loading}
      components={{
        Header: (props) => (
          <MTableHeader {...props}>
            {(props.columns[0].tableData.width = window.screen.width < 1600 ? "22%" : "17%")}
            {(props.columns[1].tableData.width = "9%")}
            {(props.columns[2].tableData.width = "14%")}
            {(props.columns[3].tableData.width = "10%")}
            {(props.columns[4].tableData.width = "10%")}
            {(props.columns[5].tableData.width = "10%")}
            {(props.columns[6].tableData.width = "10%")}
            {(props.columns[7].tableData.width = "10%")}
            {(props.columns[8].tableData.width = "12%")}
            {(props.columns[9].tableData.width = '2%')}
          </MTableHeader>
        ),
        Toolbar: props => (
          <Grid container style={{ paddingTop: '5px', paddingLeft: '10px', display: 'flex', flexDirection: 'row', justify: 'space-between' }}>
            <Grid item xs={12} md={6} style={{ alignSelf: 'flex-start' }}>
              <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.iconsHeader} style={{ alignSelf: 'flex-end' }}>
              <div style={{ marginRight: '30px' }}>
                <MTableToolbar {...props} />
              </div>                
              {/* <TableToExcel
                columns={columns}
                data={data()}
                name={name}
                filename={filename}
              /> */}
            </Grid>
          </Grid>
        ),
        }}
      customOptions={{
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        rowStyle: (rowData, index) => ({
          backgroundColor: !isEven(index) && '#f2f2f2',
        }),
        maxBodyHeight: props.maxHeight,
        minBodyHeight: props.minHeight,
        paging: false,
        initialPage: lastPageIndex,
        sorting: false, 
        search: true
      }}
      tableKey={"tabla-comprobantes-cta-cte"}
    />
  );
};

const mapStateToProps = (state) => ({  
  loading: state.clientes.cuentaCorriente.loading,
  comprobantes: state.clientes.cuentaCorriente.comprobantes,
  error: state.clientes.cuentaCorriente.error,
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComprobantesCtaCte);
