import React, { createRef, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions';
import locales from '../../../../shared/locales';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Table from '../../../UI/Table/Table';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TablaRentabilidadEvolutivoToExcel from './TablaRentabilidadEvolutivoToExcel';
import { TableCell } from '@material-ui/core';
import classes from './TablaRentabilidadEvolutivo.css';
import { useHistory } from 'react-router';
import { fechaActualFunc } from '../../../../shared/fechas';

const TablaRentabilidadEvolutivo = (props) => {
  const { loading, estaCargado, fechaPeriodo, gastosColums, itemData, onChangeGruposGastos, clearGastos, withoutDecimals } = props;
  const { costos = [], ganancias = [], utilidades = [], ventas = [] } = itemData;
  const history = useHistory();
  const [fechaFija] = useState(fechaActualFunc());

  const dataOptions = {
    gasto: {
      pageName: 'Análisis de gastos',
      ejecucionKey: 'ejecucionGastos',
      loadKey: 'onLoadGasto',
      pageRedirect: '/gastos',
      addKey: 'agregarGasto',
      periodosKey: 'periodosGastos',
    },
    utilidad: {
      pageName: 'Análisis de utilidad',
      ejecucionKey: 'ejecucionAnalisisDeUtilidad',
      loadKey: 'onLoadUtilidad',
      pageRedirect: '/analisisUtilidad',
      addKey: 'agregarUtilidad',
      periodosKey: 'periodosUtilidad',
    },
    venta: {
      pageName: 'Análisis de ventas',
      ejecucionKey: 'ejecucionAnalisisDeVenta',
      loadKey: 'onLoadVenta',
      pageRedirect: '/analisisVentas',
      addKey: 'agregarVenta',
      periodosKey: 'periodosVentas',
    },
  };

  const data = () => {
    var totalUtilidad = 0;
    var totalCosto = 0;
    var totalGanancia = 0;
    var totalVenta = 0;

    utilidades.map((utilidad) => (totalUtilidad += utilidad.monto));
    costos.map((costo) => (totalCosto += costo.monto));
    ganancias.map((ganancia) => (totalGanancia += ganancia.monto));
    ventas.map((venta) => (totalVenta += venta.monto));

    var formatData = utilidades.map((utilidad, index) => {
      const porcentajeResult = (ganancias[index].monto / ventas[index].monto) * 100;
      const porcUtilidad = utilidad && ventas[index]
        ? (utilidad.monto / ventas[index].monto) * 100
        : 0;
      const item = {
        utilidad: utilidad.monto,
        porcUtilidad: isNaN(porcUtilidad) ? 0 : porcUtilidad,
        fecha: `${locales[1].options.months[utilidad.mes - 1]}/${utilidad.anio}`,
        mes: utilidad.mes,
        anio: utilidad.anio,
        costo: costos[index] ? costos[index].monto : 0,
        ganancia: ganancias[index] ? ganancias[index].monto : 0,
        venta: ventas[index] ? ventas[index].monto : 0,
        porcResultado: isNaN(porcentajeResult) ? 0 : porcentajeResult
      };

      gastosColums.forEach((col) => {
        const monto = itemData[col.field] && itemData[col.field][index]
          ? itemData[col.field][index].monto
          : 0;
        const porcMonto = (monto / ventas[index].monto) * 100;
        item[col.field] = monto;
        item[`porc_${col.field}`] = isNaN(porcMonto) ? 0 : porcMonto;
      });

      return item;
    });

    const porcTotal = (totalGanancia / totalVenta) * 100;
    const porcUtilidadTotal = (totalUtilidad / totalVenta) * 100;

    var totalRow = {
      fecha: 'Total',
      utilidad: totalUtilidad,
      porcUtilidad: porcUtilidadTotal ? porcUtilidadTotal : 0,
      costo: totalCosto,
      ganancia: totalGanancia,
      venta: totalVenta,
      porcResultado: porcTotal ? porcTotal : 0
    };

    if (gastosColums) {
      gastosColums.forEach((col) => {
        var totalGrupo = 0;
        const montos = itemData[col.field];

        if (montos)
          montos.map((monto) => (totalGrupo += monto.monto));

        var totalPorc = (totalGrupo / totalVenta) * 100;

        totalRow[col.field] = totalGrupo;
        totalRow[`porc_${col.field}`] = totalPorc ? totalPorc : 0;
      });
    }

    formatData.push(totalRow);

    return formatData;
  };

  const tableRef = createRef();

  const cellStyles = {
    paddingBottom: '2px',
    paddingTop: '2px',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderBottom: '0px'
  };

  const headerStyles = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderBottom: '0px'
  };

  const columns = () => {
    let cols = [
      {
        title: 'Fecha',
        field: 'fecha',
        align: 'left',
        cellStyle: cellStyles,
        headerStyle: headerStyles
      },
      {
        title: `Ventas ${storageMonedaSigno()}`,
        field: 'venta',
        align: 'right',
        type: 'currency',
        cellStyle: cellStyles,
        headerStyle: headerStyles
      },
      {
        title: `Costos ${storageMonedaSigno()}`,
        field: 'costo',
        align: 'right',
        type: 'currency',
        cellStyle: cellStyles,
        headerStyle: headerStyles
      },
      {
        title: `Utilidad ${storageMonedaSigno()}`,
        field: 'utilidad',
        align: 'right',
        type: 'currency',
        cellStyle: cellStyles,
        headerStyle: headerStyles
      },
      {
        title: 'Utilidad %',
        field: 'porcUtilidad',
        align: 'right',
        type: 'numeric',
        cellStyle: cellStyles,
        headerStyle: { ...headerStyles, textAlign: 'right' }
      }
    ];

    if (gastosColums && gastosColums.length > 0) {
      gastosColums.forEach((col) => {
        cols.push({
          title: `${col.nombre} ${storageMonedaSigno()}`,
          field: col.field,
          align: 'right',
          type: 'currency',
          cellStyle: cellStyles,
          headerStyle: headerStyles
        });
        cols.push({
          title: `${col.nombre} %`,
          field: `porc_${col.field}`,
          align: 'right',
          type: 'numeric',
          width: 350,
          cellStyle: cellStyles,
          headerStyle: { ...headerStyles, textAlign: 'right' }
        });
      });
    }

    cols.push({
      title: `Resultado ${storageMonedaSigno()}`,
      field: 'ganancia',
      align: 'right',
      type: 'currency',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    });

    cols.push({
      title: 'Resultado %',
      field: 'porcResultado',
      align: 'right',
      type: 'numeric',
      cellStyle: cellStyles,
      headerStyle: { ...headerStyles, textAlign: 'right' }
    });

    return cols;
  }

  const buildPorcResultadoWithTrendingIcon = (value) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
        {`${formatNumber(Math.abs(value))}`}
        {value === 0 || value === 'NaN' ? (
          <TrendingFlatIcon style={{ color: 'black', width: '25px' }} />
        ) : value > 0 ? (
          <TrendingUpIcon style={{ color: 'green', width: '25px' }} />
        ) : (
          <TrendingDownIcon style={{ color: 'red', width: '25px' }} />
        )}
      </div>
    );
  };

  const isGrupoGastoColumn = (field) => {
    return gastosColums && gastosColums.find((col) => col.field === field) !== undefined;
  };

  const buildTableCellWithValidations = (cellProps) => {
    const field = cellProps.columnDef.field;
    const data = cellProps.rowData[field];
    const inTotal = cellProps.rowData.fecha === 'Total';
    const inFecha = field === 'fecha';
    const inPorcResult = field && field.indexOf('porc') > -1;
    const enableClick = field === 'venta' || field === 'utilidad' || field === 'gasto' || isGrupoGastoColumn(field);

    return (
      <TableCell
        className={[
          classes.TableRentabilidadCell,
          inFecha || inTotal || !enableClick ? '' : classes.TableRentabilidadCellWithClick
        ].join(' ')}
        style={{ textAlign: !inFecha && 'right', minWidth: inFecha && '80px', maxWidth: inFecha && '110px' }}
        onClick={() => enableClick && onCellClick(cellProps, cellProps.columnDef.field)}
      >
        {inFecha ? data : inPorcResult ? buildPorcResultadoWithTrendingIcon(data) : withoutDecimals ? formatNumberWithoutDecimals(data) : formatNumber(data)}
      </TableCell>
    );
  };

  const agregarPeriodoAndRedirect = (seletedField, mes, anio) => {
    agregarPeriodo(seletedField, mes, anio);
    loadsAndRedirect(seletedField, mes, anio);
  }

  const onCellClick = (customProps, field) => {
    const mes = customProps.rowData.mes;
    const anio = customProps.rowData.anio;

    if (field === 'venta' || field === 'utilidad' || !gastosColums) {
      agregarPeriodoAndRedirect(field, mes, anio);
    } else {
      const grupoSelected = gastosColums.find((col) => col.field === field);

      if (grupoSelected) {
        const onSuccess = () => {
          clearGastos();
          agregarPeriodoAndRedirect('gasto', mes, anio);
        };
        const gruposGastos = { id: grupoSelected.gruposGastosId };
        onChangeGruposGastos(gruposGastos, onSuccess);
      }
    }
  };

  const agregarPeriodo = (dataSelectedId, mes, anio) => {
    let periodos = props[dataOptions[dataSelectedId].periodosKey];
    let existePeriodo = false;

    if (periodos && periodos.length !== 0) {
      existePeriodo = periodos.find((item) => item.getFullYear() === fechaPeriodo.anio) !== undefined;
    } else {
      if (fechaFija.getFullYear() !== fechaPeriodo.anio)
        props[dataOptions[dataSelectedId].addKey](fechaFija.getMonth() + 1, fechaFija.getFullYear(), fechaFija);
    }

    if (!existePeriodo) {
      let periodo = new Date(fechaPeriodo.anio, fechaPeriodo.mes, fechaFija.getDay());
      props[dataOptions[dataSelectedId].addKey](mes, anio, periodo);
    }
  }

  const loadsAndRedirect = (dataSelectedId, mes, anio) => {
    props[dataOptions[dataSelectedId].loadKey](mes, anio);
    history.push(dataOptions[dataSelectedId].pageRedirect);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Table
        tableRef={tableRef}
        title=""
        columns={columns()}
        data={data()}
        loading={loading && !estaCargado}
        components={{
          Toolbar: (props) => {
            return (
              <div style={{ textAlign: 'right' }}>
                <TablaRentabilidadEvolutivoToExcel
                  datas={data()}
                  columns={columns()}
                />
              </div>
            );
          },
          Cell: (props) => {
            return buildTableCellWithValidations(props);
          }
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          pageSize: 13,
          paging: false,
          rowStyle: (rowData) => ({
            backgroundColor: (rowData.tableData.id === 13 && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
          }),
          cellStyle: (cellData) => ({
            fontWeight: cellData === 'Total' && 'bolder',
            paddingTop: '5px',
            paddingBottom: '5px'
          }),
        }}
        tableKey={"tabla-rentabilidad-evolutivo"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  itemData: state.integradores.lineRentabilidadEvolutivo,
  loading: state.integradores.lineRentabilidadEvolutivo.loading,
  fechaPeriodo: state.integradores.fechaPeriodoRentabilidad,
  estaCargado: state.integradores.lineRentabilidadEvolutivo.estaCargado,
  gastosColums: state.integradores.lineRentabilidadEvolutivo.columns,

  ejecucionGastos: state.ejecucionPaginas.ejecucionGastos,
  ejecucionAnalisisDeVenta: state.ejecucionPaginas.ejecucionAnalisisDeVenta,
  ejecucionAnalisisDeUtilidad: state.ejecucionPaginas.ejecucionAnalisisDeUtilidad,

  periodosGastos: state.gastos.evolucionMensualInteranual.periodos,
  periodosUtilidad: state.ventas.evolucionUtilidadesMensualInteranual.periodos,
  periodosVentas: state.ventas.evolucionVentasMensualInteranual.periodos,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadGasto: (mes, anio) => {
      dispatch(actions.ejecutarGastos());
      dispatch(actions.seleccionFechaDeGastosMensualInteranual(mes, anio));
      dispatch(actions.loadPieGastosPorCategoria(mes, anio));
    },
    onLoadUtilidad: (mes, anio) => {
      dispatch(actions.ejecutarAnalisisDeUtilidad());
      dispatch(actions.seleccionFechaUtilidadDelMesPor(mes, anio));
      dispatch(actions.seleccionDataUtilidadDelMesPor('categoriaDeCliente'));
      dispatch(actions.loadUtilidadPorCategoriaDeCliente(mes, anio));
      dispatch(actions.clearUtilidadDelMesPorMarca());
      dispatch(actions.clearUtilidadDelMesPorRubro());
      dispatch(actions.clearUtilidadDelMesPorSubrubro());
      dispatch(actions.clearUtilidadDelMesPorProvincia());
      dispatch(actions.clearUtilidadDelMesPorVendedor());
      dispatch(actions.clearUtilidadDelMesPorZona());
    },
    onLoadVenta: (mes, anio) => {
      dispatch(actions.ejecutarAnalisisDeVentas());
      dispatch(actions.seleccionFechaVentasDelMesPor(mes, anio));
      dispatch(actions.seleccionDataVentasDelMesPor('categoriaDeCliente'));
      dispatch(actions.loadVentasPorCategoriaDeCliente(mes, anio));
      dispatch(actions.clearVentasDelMesPorMarca());
      dispatch(actions.clearVentasDelMesPorRubro());
      dispatch(actions.clearVentasDelMesPorSubrubro());
      dispatch(actions.clearVentasDelMesPorVendedor());
      dispatch(actions.clearVentasDelMesPorProvincia());
      dispatch(actions.clearVentasDelMesPorZona());
    },
    agregarGasto: (mes, anio, periodo) => {
      dispatch(actions.agregarEvolucionDeGastosMensualInteranual(periodo));
      dispatch(actions.seleccionFechaDeGastosMensualInteranual(mes, anio));
    },
    agregarUtilidad: (mes, anio, periodo) => {
      dispatch(actions.agregarEvolucionDeUtilidadMensualInteranual(periodo));
      dispatch(actions.seleccionFechaUtilidadDelMesPor(mes, anio));
    },
    agregarVenta: (mes, anio, periodo) => {
      dispatch(actions.agregarEvolucionDeVentasMensualInteranual(periodo));
      dispatch(actions.seleccionFechaVentasDelMesPor(mes, anio));
    },
    onChangeGruposGastos: (gruposGastosId, onSuccess) => dispatch(actions.cambiarGruposGastosDeUsuario(gruposGastosId, onSuccess)),
    clearGastos: () => {
      dispatch(actions.clearEvolucionesDeGastosMensualInteranual());
      dispatch(actions.clearEvolucionGastosPorCategoria());
      dispatch(actions.clearPieGastosPorCategoria());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaRentabilidadEvolutivo);
