import { formatNumber } from "../formatNumber";

export const getFormat = (monedaSigno) => {
   return [
  {
    caption: "- Rubro",
    dataField: "rubro",
    name: "rubro",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Artículo",
    dataField: "articulo",
    name: "articulo",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Subrubro",
    dataField: "subrubro",
    name: "subrubro",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Cliente",
    dataField: "cliente",
    name: "cliente",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Categoría de Cliente",
    dataField: "categoriaCliente",
    name: "categoriaCliente",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Vendedor",
    dataField: "vendedor",
    name: "vendedor",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Marca",
    dataField: "marca",
    name: "marca",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Zona",
    dataField: "zona",
    name: "zona",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Provincia",
    dataField: "provincia",
    name: "provincia",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "- Año",
    dataField: "anio",
    name: "anio",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    format: ',##0'
  },
  {
    caption: "- Mes",
    dataField: "mes",
    name: "mes",
    area: 'row',
    isMeasure: false,
    expanded: false,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },

  {
    caption: "| Unidades Fact.",
    dataField: "unidadesFacturadas",
    name: "unidadesFacturadas",
    dataType: 'number',
    area: 'data',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    summaryType: 'sum',
    format: ',##0.00'
  },
  {
    caption: "| Unidades Pend.",
    dataField: "unidadesPendientes",
    name: "unidadesPendientes",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    format: ',##0.00'
  },
  {
    caption: "| Total Unidades",
    dataField: "unidadesTotales",
    name: "unidadesTotales",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    format: ',##0.00'
  },  
  {
    caption: "| Unidades año comparación",
    dataField: "unidadesTotalComparacion",
    name: "unidadesTotalComparacion",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    format: ',##0.00'
  },
  {
    caption: "| Dif. Unidades año comparación",
    name: "unidadesDiferenciaComparacion",
    dataType: 'number',
    area: 'data',
    format: ',##0.00'
  },

  {
    caption: "| Neto Fact.",
    dataField: "netoFacturado",
    name: "netoFacturado",
    dataType: 'number',
    area: 'data',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
  },
  {
    caption: "| Neto Pend.",
    dataField: "netoPendiente",
    name: "netoPendiente",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "| Total Neto",
    dataField: "netoTotal",
    name: "netoTotal",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "| Neto año comparación",
    dataField: "netoTotalComparacion",
    name: "netoTotalComparacion",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "| Dif. Neto año comparación",
    name: "netoDiferenciaComparacion",
    dataType: 'number',
    area: 'data',
    customizeText: function (cellInfo) {
      return `${monedaSigno} ${formatNumber(cellInfo.value)}`;
    }
  },

  {
    caption: "| Descuento",
    dataField: "descuento",
    name: "descuento",
    dataType: 'number',
    area: 'data',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
  }, 

  {
    caption: "| Bruto Fact.",
    dataField: "brutoFacturado",
    name: "brutoFacturado",
    dataType: 'number',
    area: 'data',
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
  }, 
  {
    caption: "| Bruto Pend.",
    dataField: "brutoPendiente",
    name: "brutoPendiente",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "| Total Bruto",
    dataField: "brutoTotal",
    name: "brutoTotal",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "| Bruto año comparación",
    dataField: "brutoTotalComparacion",
    name: "brutoTotalComparacion",
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: `${monedaSigno} ,##0.00`,
    allowFiltering: true,
    allowSorting: true,
    allowSortingBySummary: true,
  },
  {
    caption: "| Dif. Bruto año comparación",
    name: "brutoDiferenciaComparacion",
    dataType: 'number',
    area: 'data',
    customizeText: function (cellInfo) {
      return `${monedaSigno} ${formatNumber(cellInfo.value)}`;
    }
  },
]};