// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnalisisDeVentas__AnalisisVentasContainer__B6S7f {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.AnalisisDeVentas__FlexHorizontal__Q-9SC {\r\n  display: flex;\r\n  flex-flow: row;\r\n  margin-bottom: 10px;\r\n  /* border: 1px solid red; */\r\n}\r\n\r\n.AnalisisDeVentas__FlexVertical__5XmPp {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-flow: column;\r\n  /* border: 1px solid red; */\r\n}\r\n\r\n.AnalisisDeVentas__CombinedChartsCard__ueAY\\+ {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  margin: 5px !important;\r\n}\r\n\r\n.AnalisisDeVentas__IndicadorBarra__bVc1i {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  margin-right: 5px;\r\n}\r\n\r\n.AnalisisDeVentas__IndicadorTabla__8XBJw {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  margin-left: 5px;\r\n}\r\n\r\n.AnalisisDeVentas__separador__I3WZC {\r\n  width: 10px;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .AnalisisDeVentas__IndicadorBarra__bVc1i {\r\n    max-width: 682px;\r\n  }\r\n  .AnalisisDeVentas__IndicadorTabla__8XBJw {\r\n    max-width: 682px;\r\n  }\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnalisisVentasContainer": "AnalisisDeVentas__AnalisisVentasContainer__B6S7f",
	"FlexHorizontal": "AnalisisDeVentas__FlexHorizontal__Q-9SC",
	"FlexVertical": "AnalisisDeVentas__FlexVertical__5XmPp",
	"CombinedChartsCard": "AnalisisDeVentas__CombinedChartsCard__ueAY+",
	"IndicadorBarra": "AnalisisDeVentas__IndicadorBarra__bVc1i",
	"IndicadorTabla": "AnalisisDeVentas__IndicadorTabla__8XBJw",
	"separador": "AnalisisDeVentas__separador__I3WZC"
};
export default ___CSS_LOADER_EXPORT___;
