import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LineEvolucionVentasDelVendedor from '../../components/Indicadores/Vendedores/LineEvolucionVentasDelVendedor/LineEvolucionVentasDelVendedor';
import LineEvolucionVentasDelVendedorPorSeleccion from '../../components/Indicadores/Vendedores/LineEvolucionVentasDelVendedorPorSeleccion/LineEvolucionVentasDelVendedorPorSeleccion';
import PieVentasDelVendedorPorSeleccion from '../../components/Indicadores/Vendedores/PieVentasDelVendedorPorSeleccion/PieVentasDelVendedorPorSeleccion';
import TablaCuboVentasDelVendedor from '../../components/Indicadores/Vendedores/TablaCuboVentasDelVendedor/TablaCuboVentasDelVendedor';
import TablaVentasDelVendedorPorSeleccion from '../../components/Indicadores/Vendedores/TablaVentasDelVendedorPorSeleccion/TablaVentasDelVendedorPorSeleccion';
import { Grid } from '@material-ui/core';
import classes from './AnalisisDeVendedores.css';

const useStyles = makeStyles((theme) => ({
  lineEvolucionVentas: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  }
}));

const Ventas = (props) => {
  const classes2 = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={12} lg={4}>
          <TablaCuboVentasDelVendedor ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <LineEvolucionVentasDelVendedor ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item 
        container spacing={1}
        className={classes.CombinedChartsCard}>
        <Grid item xs={12} lg={4}>
          <PieVentasDelVendedorPorSeleccion
            ejecucionDePagina={props.ejecucionDePagina}
            inGastos
            legendHeight={50}
            legendPosition={'bottom'}
            height={436}
            width={'100%'}
            chartType={'pie'}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TablaVentasDelVendedorPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item className={classes2.lineEvolucionVentas}>
        <LineEvolucionVentasDelVendedorPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
    </Grid>
  );
};

export default Ventas;
