import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import moment from 'moment';

import EmpresaSelector from '../../components/UI/SelectorEmpresa/EmpresaSelector';
import { storageMultiempresa } from '../../shared/sessionData';
import Tabs from '../../components/UI/Tabs/Tabs';
import Ventas from './Ventas';

import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

import classes from './AnalisisDeVendedores.css';
import VendedorSelector from '../../components/UI/VendedorSelector/VendedorSelector';
import Comparacion from './Comparacion';

const AnalisisDeVentas = (props) => {
  sessionStorage.setItem('seccionNombre', 'Análisis de vendedores');

  const isMultiemp = storageMultiempresa() === 'true';

  const handleChangeVendedorSeleccionado = (e) => {
    props.onChangeOptionSelected();
    props.handleChangeVendedor(props.vendedores.filter((vend) => vend.id_vendedor === e.target.value)[0]);

    props.actualizarAnalisisVendedores(e.target.value);
  };

  return (
    <div className={classes.AnalisisVendedoresContainer}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: !isMultiemp && '10px'
        }}
      >
        {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
        <VendedorSelector
          endLoadVendedores={(primerVendedor) => {
            props.handleChangeVendedor(primerVendedor);
          }}
          compactInput
          vendedorSelectedId={props.vendedorSelected.id_vendedor}
          onChangeVendedorSeleccionado={(e) => handleChangeVendedorSeleccionado(e)}
        />
      </div>
      {!props.ejecucionAnalisisDeVendedor ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <Tabs
          tabs={['Ventas', 'Comparación']}
          views={[
            <Ventas ejecucionDePagina={props.ejecucionAnalisisDeVendedor} />,
            <Comparacion ejecucionDePagina={props.ejecucionAnalisisDeVendedor} />
          ]}
          flagResetIndex={props.ultimaActualizacionAnalisisVendedores}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionAnalisisDeVendedor: state.ejecucionPaginas.ejecucionAnalisisDeVendedor,
    vendedorSelected: state.vendedores.vendedorSelected,
    vendedores: state.filtros.vendedores.vendedores,
    ultimaActualizacionAnalisisVendedores: state.ultimasActualizaciones.analisisVendedores
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: () => dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente')),

    updateUltimaActualizacion: () =>
      dispatch(actions.updateUltimaActualizacionAnalisisVentas(moment(Date.now()).format('DD/MM/YYYY HH:mm'))),
    handleChangeVendedor: (vendedor) => dispatch(actions.seleccionVendedorAnalisisVendedores(vendedor)),
    actualizarAnalisisVendedores: (idVendedor) => dispatch(actions.actualizarAnalisisVendedores(idVendedor))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalisisDeVentas);
