/*eslint-disable */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';
import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';

const TextoGastosDelMes = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }

    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.ejecucionDePagina]);
  const isDisabledChart = props.estaOculto && !props.empresa;

  return (
    <TextChart
      title={`Gastos del mes ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} ${
        props.empresa ? props.empresa.nombre : ''
      }`}
      bigAmount={props.mesActual}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} a ${fechaFinPeriodoMesActualFunc()} `}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} a ${fechaFinPeriodoMesAnteriorFunc()}`}
      smallAmount={props.mesAnterior}
      loading={props.loading}
      disabled={isDisabledChart}
      comparacionInvertida
      bigCurrency
      smallCurrency
      porcentaje
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.gastos.textoGastosDelMes.loading,
    mesActual: state.gastos.textoGastosDelMes.mesActual,
    mesAnterior: state.gastos.textoGastosDelMes.mesAnterior,
    error: state.gastos.textoGastosDelMes.error,
    estaCargado: state.gastos.textoGastosDelMes.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadTextoGastosDelMes(idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearTextoGastosDelMes()),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Gastos del mes', ['gasto', 'categoriaItemsDeCompra']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoGastosDelMes);
/*eslint-enable */
