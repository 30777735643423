// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnalisisDeVendedores__AnalisisVendedoresContainer__DEC7k {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.AnalisisDeVendedores__FlexHorizontal__cBHL5 {\r\n  display: flex;\r\n  flex-flow: row;\r\n  margin-bottom: 10px;\r\n  /* border: 1px solid red; */\r\n}\r\n\r\n.AnalisisDeVendedores__SelectRoot__nTxSv {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n\r\n.AnalisisDeVendedores__CombinedChartsCard__Fsof\\+ {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  margin: 5px !important;\r\n}\r\n\r\n.AnalisisDeVendedores__FlexVertical__BffC4 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-flow: column;\r\n  /* border: 1px solid red; */\r\n}\r\n\r\n.AnalisisDeVendedores__CombinedChartsCard__Fsof\\+ {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n}\r\n\r\n.AnalisisDeVendedores__IndicadorBarra__fov1- {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  margin-right: 5px;\r\n}\r\n\r\n.AnalisisDeVendedores__IndicadorTabla__mixMh {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  margin-left: 5px;\r\n}\r\n\r\n.AnalisisDeVendedores__separador__1Kg6M {\r\n  width: 10px;\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .AnalisisDeVendedores__IndicadorBarra__fov1- {\r\n    max-width: 682px;\r\n  }\r\n  .AnalisisDeVendedores__IndicadorTabla__mixMh {\r\n    max-width: 682px;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnalisisVendedoresContainer": "AnalisisDeVendedores__AnalisisVendedoresContainer__DEC7k",
	"FlexHorizontal": "AnalisisDeVendedores__FlexHorizontal__cBHL5",
	"SelectRoot": "AnalisisDeVendedores__SelectRoot__nTxSv",
	"CombinedChartsCard": "AnalisisDeVendedores__CombinedChartsCard__Fsof+",
	"FlexVertical": "AnalisisDeVendedores__FlexVertical__BffC4",
	"IndicadorBarra": "AnalisisDeVendedores__IndicadorBarra__fov1-",
	"IndicadorTabla": "AnalisisDeVendedores__IndicadorTabla__mixMh",
	"separador": "AnalisisDeVendedores__separador__1Kg6M"
};
export default ___CSS_LOADER_EXPORT___;
