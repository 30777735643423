// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetallesComprobanteCobranza__infoComprobanteContainer__2gEzq {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    flex-direction: column;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .DetallesComprobanteCobranza__infoComprobanteRow__uRrdI {\r\n    display: flex;\r\n  }\r\n  \r\n  .DetallesComprobanteCobranza__titleTotales__UzEI2 {\r\n    font-size: small;\r\n    font-weight: 500;\r\n    margin-bottom: 10px !important;\r\n    text-align: right;\r\n  }\r\n  \r\n  .DetallesComprobanteCobranza__Spinner__9ugXt {\r\n    position: absolute;\r\n    top: 25%;\r\n    right: 44%;\r\n    z-index: 1500;\r\n  }\r\n  \r\n  .DetallesComprobanteCobranza__footer__EzvEM {\r\n    display: flex;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .DetallesComprobanteCobranza__TitleCard__Pxuq- {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-content: center;\r\n    background: rgb(251, 175, 64);\r\n    background: linear-gradient(90deg, rgba(251, 175, 64, 1) 0%, rgba(241, 91, 41, 1) 100%);\r\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#fbaf40\",endColorstr=\"#f15b29\",GradientType=1);\r\n    margin: -15px -15px 15px -15px;\r\n    padding-bottom: 10px;\r\n    padding-left: 15px;\r\n  }\r\n  ", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoComprobanteContainer": "DetallesComprobanteCobranza__infoComprobanteContainer__2gEzq",
	"infoComprobanteRow": "DetallesComprobanteCobranza__infoComprobanteRow__uRrdI",
	"titleTotales": "DetallesComprobanteCobranza__titleTotales__UzEI2",
	"Spinner": "DetallesComprobanteCobranza__Spinner__9ugXt",
	"footer": "DetallesComprobanteCobranza__footer__EzvEM",
	"TitleCard": "DetallesComprobanteCobranza__TitleCard__Pxuq-"
};
export default ___CSS_LOADER_EXPORT___;
