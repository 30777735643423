import { Grid } from '@material-ui/core';
import React from 'react';
import BarUtilidadesPorMarca from '../../components/Indicadores/Ventas/Utilidades/BarUtilidadesCharts/BarUtilidadesPorMarca';
import BarUtilidadesPorRubro from '../../components/Indicadores/Ventas/Utilidades/BarUtilidadesCharts/BarUtilidadesPorRubro';
import BarUtilidadesPorSubrubro from '../../components/Indicadores/Ventas/Utilidades/BarUtilidadesCharts/BarUtilidadesPorSubrubro';

const Utilidad = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <BarUtilidadesPorMarca ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
      <Grid item xs={12}>
        <BarUtilidadesPorRubro ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
      <Grid item xs={12}>
        <BarUtilidadesPorSubrubro ejecucionDePagina={props.ejecucionDePagina} />
      </Grid>
    </Grid>
  );
};

export default Utilidad;
