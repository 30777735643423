import React, { useState, useEffect } from "react";
import * as actions from './../../../../store/actions';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import CustomSelector from "../../CustomSelector/CustomSelector";
import ButtonPivotGrid from "../ButtonPivotGrid/ButtonPivotGrid";
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import { capitalizeName } from "../../../../shared/utility";
import { compareByAsc } from "../../../../shared/sorting";

const FiltrosChartPivotGrid = (props) => {
  const { selectOptions, filters, onAplicarFiltro, filtrosChart, relatedFilter,
    optionItems } = props;
  const [optionSelectedId, setOptionSelectedId] = useState('');
  const [filterSelected, setFilterSelected] = useState('');
  const [ordenSelected, setOrdenSelected] = useState('');
  const [orderFilters, setOrderFilters] = useState([]);
  const [seriesFilters, setSeriesFilters] = useState([]);
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const [extraFilters, setExtraFilters] = useState([]);
  const [relatedFilterValue, setRelatedFilterValue] = useState('');

  useEffect(() => {
    if (filtrosChart && optionSelectedId === '' && filterSelected === '' && ordenSelected === '') {
      let selectItem = null;
      let filterItem = null;
      let orders = [];
      let orderItem = null;
      let series = [];
      let extraFiltersTemp = undefined;
      let relatedFiltersTemp = undefined;

      if (selectOptions && selectOptions.length > 0) {
        selectItem = selectOptions.find((opt) => opt.id === filtrosChart.optionSelectedId);

        if (!selectItem)
          selectItem = selectOptions[0];
      }

      if (filters && filters.length > 0) {
        filterItem = filters.find((opt) => opt.id === filtrosChart.fieldSelectedId);

        if (!filterItem)
          filterItem = filters[0];

        orders = filterItem.orderItems && filterItem.orderItems.map((item, index) => {
          return { id: item.name, nombre: item.caption ? item.caption.replace('| ', '') : '', ...item };
        });

        if (orders && orders.length > 0) {
          orderItem = orders.find((opt) => opt.id === filtrosChart.orderSelectedId);

          if (!orderItem)
            orderItem = orders[0];
        }

        series = filterItem.seriesItems && filterItem.seriesItems.map((item, index) => {
          return { id: item.name, nombre: item.caption ? item.caption.replace('| ', '') : '', ...item };
        });
      }

      if (selectItem && filterItem && orderItem && optionItems) {
        setOptionSelectedId(selectItem);
        setFilterSelected(filterItem);
        setOrderFilters(orders);
        setOrdenSelected(orderItem);
        setSeriesFilters(series);
        let relatedOptions = optionItems[selectItem.id] && optionItems[selectItem.id].items
          && optionItems[selectItem.id].items.map((item) => { return { id: item.name, nombre: item.name, ...item }; });

        if (filtrosChart.extraFilters) {
          extraFiltersTemp = filtrosChart.extraFilters;
          let extra = Object.keys(filtrosChart.extraFilters).map((keyName) => {
            const options = (optionItems[keyName]
              ? optionItems[keyName].items && optionItems[keyName].items.map((item) => {
                return { id: item.name, nombre: item.name, ...item };
              })
              : []).sort((a, b) => compareByAsc(a, b, 'name'));

            let value = [];
            filtrosChart.extraFilters[keyName].forEach((filter) => {
              let item = options.find((opt) => opt.name === filter);
              if (item)
                value.push(item);
            });
            return {
              name: keyName,
              value: value,
              options: options
            }
          });
          setExtraFilters(extra);
        } else {
          if (props.extraFilters && props.extraFilters.length > 0 && extraFilters.length <= 0 && optionItems) {
            setExtraFilters(
              props.extraFilters.map((filter) => {
                const options = (optionItems[filter]
                  ? optionItems[filter].items && optionItems[filter].items.map((item) => {
                    return { id: item.name, nombre: item.name, ...item };
                  })
                  : []).sort((a, b) => compareByAsc(a, b, 'name'));
                return {
                  name: filter,
                  value: [...options],
                  options: options
                }
              })
            )

          }
        }

        if (filtrosChart.relatedFilters) {
          relatedFiltersTemp = filtrosChart.relatedFilters;
          let related = Object.keys(filtrosChart.relatedFilters).map((keyName) => {
            const options = (optionItems[keyName]
              ? optionItems[keyName].items && optionItems[keyName].items.map((item) => {
                return { id: item.name, nombre: item.name, ...item };
              })
              : []).sort((a, b) => compareByAsc(a, b, 'name'));

            let value = [];
            filtrosChart.relatedFilters[keyName].forEach((filter) => {
              let item = options.find((opt) => opt.name === filter);
              if (item)
                value.push(item);
            });

            return {
              name: keyName,
              value: value,
              options: options
            }
          });
          setRelatedFilterValue(related && related[0]);
        } else {
          setRelatedFilterValue({ name: selectItem.id, value: [...relatedOptions], options: relatedOptions });
        }

        onAplicarFiltro(selectItem, filterItem, series, orderItem, extraFiltersTemp, relatedFiltersTemp);
        setCambiosAplicados(true);
      }
    }
  }, [filtrosChart, selectOptions, filters, optionSelectedId, filterSelected, ordenSelected, 
      props.extraFilters, extraFilters, optionItems, onAplicarFiltro]);

  const onOptionChange = (value) => {
    setOptionSelectedId(value);

    let options = optionItems[value.id] && optionItems[value.id].items ? optionItems[value.id].items.map((item) => {
      return { id: item.name, nombre: item.name, ...item };
    }) : [];

    onExtraFilterChange(value.id, [...options]);
    setRelatedFilterValue({ name: value.id, value: [...options], options });
    setCambiosAplicados(false);
  }

  const onFilterChange = (value) => {
    setFilterSelected(value);
    const orders = value.orderItems && value.orderItems.map((item, index) => {
      return { id: index, nombre: item.caption ? item.caption.replace('| ', '') : '', ...item };
    });
    setOrderFilters(orders);

    const series = value.seriesItems && value.seriesItems.map((item, index) => {
      return { id: item.name, nombre: item.caption ? item.caption.replace('| ', '') : '', ...item };
    });
    setSeriesFilters(series);

    setCambiosAplicados(false);
  }

  const onOrdenSelectedChange = (value) => {
    setOrdenSelected(value);
    setCambiosAplicados(false);
  }

  const onAplicarFiltroClick = () => {
    const extraFiltersResult = {};
    const relatedFiltersResult = {};

    if (extraFilters && extraFilters.length > 0) {
      extraFilters.forEach((filter) => {
        extraFiltersResult[filter.name] = filter.value.map((val) => { return val.name; });
      });
    }

    if (relatedFilterValue) {
      relatedFiltersResult[relatedFilterValue.name] = relatedFilterValue.value.map((val) => { return val.name; });
    }

    onAplicarFiltro(optionSelectedId, filterSelected, seriesFilters, ordenSelected, extraFiltersResult, relatedFiltersResult);
    setCambiosAplicados(true);
  }

  const habilitar = () => {
    return optionSelectedId !== '' && filterSelected !== '' && ordenSelected !== '';
  }

  const extraSize = extraFilters && extraFilters.length > 0
    ? relatedFilter ? Number(12 / (extraFilters.length + 1)) : Number(12 / extraFilters.length)
    : 0;

  const onExtraFilterChange = (extraFilter, value) => {
    let tempFilters = [...extraFilters];
    tempFilters.forEach((filter) => {
      if (filter.name === extraFilter) {
        filter.value = value;
      }
    });
    setExtraFilters(tempFilters);
  }

  const onRelatedFilterChange = (value) => {
    const tempItem = { ...relatedFilterValue };
    tempItem.value = value;
    setRelatedFilterValue(tempItem);
  }

  const getExtraFilterSelector = (filter, index) => {
    return (
      <CustomSelector
        key={index}
        id={filter.name}
        label={filter && `Filtrar por ${capitalizeName(filter.name)}`}
        value={filter.value}
        items={filter.options}
        onChange={(value) => onExtraFilterChange(filter.name, value)}
        multiple
        selectAll
      />
    );
  }

  return (
    <Grid item xs={12} md={12} xl={9} container spacing={1}>
      <Grid item xs={12} md={4} xl={4} container spacing={1}>
        <Grid item xs={6} md={4}>
          <CustomSelector
            id="chartOptions"
            label="Visualizar por"
            value={optionSelectedId}
            items={selectOptions}
            onChange={(e) => onOptionChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomSelector
            id="chartFilters"
            label="Campos"
            value={filterSelected}
            items={filters}
            onChange={(e) => onFilterChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomSelector
            id="chartOrder"
            label="Ordenar por"
            value={ordenSelected}
            items={orderFilters}
            onChange={(e) => onOrdenSelectedChange(e.target.value)}
          />
        </Grid>
      </Grid>
      {(extraFilters && extraFilters.length > 0) && (
        <Grid item xs={12} md={6} xl={6} container spacing={1}>
          {extraFilters && extraFilters.map((item, index) => (
            <Grid item xs={6} md={extraSize}>
              {getExtraFilterSelector(item, index)}
            </Grid>
          ))}
          {(relatedFilter && optionSelectedId && props.extraFilters && !props.extraFilters.includes(optionSelectedId.id)) && (
            <Grid item xs={6} md={3}>
              {relatedFilterValue && (
                <CustomSelector
                  id={relatedFilterValue.name}
                  label={`Filtrar por ${capitalizeName(relatedFilterValue.name)}`}
                  value={relatedFilterValue.value}
                  items={relatedFilterValue.options}
                  onChange={(value) => onRelatedFilterChange(value)}
                  multiple
                  selectAll
                />
              )}
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={6} md={1} xl={1}>
        <ButtonPivotGrid
          tooltipTitle="Aplicar filtros sobre el informe 1"
          buttonSize="small"
          text="Aplicar"
          onClick={onAplicarFiltroClick}
          loading={false}
          icon={<PlayCircleFilledWhiteOutlinedIcon fontSize="small" />}
          disabled={!habilitar()}
          backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    filtrosChart: state.tenacta.filtrosChartInformeUno,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (title) => dispatch(actions.showListFiltersModal(true, title, ['venta'])),
    onUpdateFiltros: (filtros) => dispatch(actions.updateFiltrosChartInformeUno(filtros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltrosChartPivotGrid);