import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';

import Chart from 'react-apexcharts';

import Spinner from '../UI/Spinner/Spinner';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';

import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import classes from './Styles/BigChartCard.css';
import locales from '../../shared/locales';
import { storageMonedaSigno } from '../../shared/sessionData';
import { formatNumber } from '../../shared/formatNumber';
import { colorsChart } from '../../shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const BarChart = (props) => {
  let chartRef = useRef(null);

  const options = {
    chart: {
      defaultLocale: 'es',
      locales: locales,
      type: 'bar',
      stacked: props.chartStacked,
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        }
      },
      zoom: {
        enabled: true,
        type: 'y',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (props.seriesRedirectGetter) {
            const redirect = props.seriesRedirectGetter
              ? props.seriesRedirectGetter(config.seriesIndex, config.dataPointIndex)
              : '';
            props.history.push(redirect);
          }
          if (props.onPorcionClick) {
            props.onPorcionClick(config);
          }
        }
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 5,
        left: 3,
        blur: 7,
        opacity: 0.2
      }
    },
    colors: props.colors ? props.colors : colorsChart,
    dataLabels: {
      enabled: props.dataLabelsEnabled,
      formatter: function (val, opts) {
        return props.dataLabelsFormatter ? props.dataLabelsFormatter(val, opts) : val;
      }
    },
    tooltip: {
      enabled: props.tooltipEnabled,
      custom: props.tooltipCustom
        ? function ({ series, seriesIndex, dataPointIndex, w }) {
            return props.tooltipCustom(series, seriesIndex, dataPointIndex, w);
          }
        : undefined
    },
    grid: { show: props.gridShow },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: props.plotOptionsBarDistributed
      }
    },
    xaxis: {
      categories: props.categories,
      labels: {
        trim: true
      },
      tickPlacement: props.xaxisTickPlacement
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return `${storageMonedaSigno()}${formatNumber(val)}`;
        }
      }
    },
    legend: {
      show: !props.disabledLegendShow,
      position: 'right',
      offsetY: 100
    },
    fill: {
      opacity: 1
    }
  };

  /* const titleOnClickHandler = (redirectPath) => {
    if (redirectPath) props.history.push(redirectPath);
  };*/

  return (
    <div 
      ref={chartRef} 
      style={{ height: '100%', width: '100%', boxShadow: props.noBoxShadow && 'none' }}
      className={classes.BigChartCard}
    >
      <div style={{ display: 'flex', justifyContent: props.titleJustifyContent && props.titleJustifyContent }}>
        <div className={classes.TitleCard}>
          <div
            style={{
              fontWeight: 'bold',
              marginBottom: '1px',
              marginTop: '14px',
              width: '100%',
              display: 'flex',
              alignItems: props.alignTitleItems ? props.alignTitleItems : 'center',
              justifyContent: 'space-between'
            }}
          >
            {props.title}
          </div>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.children}
      </div>
      {props.disabled ? (
        <img alt="Indicador Deshabilitado" src={IndicadorDeshabilitado} />
      ) : props.loading ? (
        <Spinner />
      ) : props.series.length > 0 ? (
        <Chart
          options={options}
          style={{ textAlign: props.chartTextAlign ? props.chartTextAlign : 'center' }}
          series={props.series}
          type="bar"
          height={props.height}
        />
      ) : (
        <img alt="Sin Resultados" src={SinResultados} />
      )}
    </div>
  );
};

export default withRouter(BarChart);
