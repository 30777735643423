import React from "react";
import TableChangeLogs from "../../components/ChangeLogs/TableChangeLogs/TableChangeLogs";

const ChangeLogs = (props) => {
  sessionStorage.setItem('seccionNombre', 'Versionado');
  
  return (
    <div>
      <TableChangeLogs />
    </div>
  );
};

export default ChangeLogs;
