import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';

const TextoTotalStockValorizado = (props) => {
    const {
        ordenamientoSelected,
        fechaDeudaDelMes,
        ejecucionDePagina
    } = props

  useEffect(() => {
    if (ejecucionDePagina) {
        props.onLoadStockValorizado();
      }
    }, [ordenamientoSelected, fechaDeudaDelMes]);
  const isDisabledChart = props.estaOculto && !props.empresa;

  const totalCost = () => {
        var total = 0 
        for(var i = 0; i < props.stockValorizado.length; i++)
            total += props.stockValorizado[i]["costoTotal"]
        
        return total
  }
  return (
    <TextChart
      title={`Costo total`}
      bigAmount={`${totalCost()}`}
      bigAmountTooltip={`Total`}
      bigCurrency
      loading={props.loading}
      disabled={isDisabledChart}
      porcentaje
      border={props.border && !props.error}
      imgSinDatos={props.error}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    stockValorizado: state.stock.stockValorizado.stock,
    loading: state.stock.stockValorizado.loading,
    estaCargado: state.stock.stockValorizado.estaCargado,
    ejecucionIndicador: state.stock.stockValorizado.ejecucionIndicador,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadStockValorizado: (idEmpresa) => dispatch(actions.stockValorizado(idEmpresa)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoTotalStockValorizado);
