import axios from '../../axios-dashboard';
import { fechaActualFunc } from '../../shared/fechas';
import idIndicadores from '../../shared/idIndicadores';
import { storageEmpresaId, storageMonedaId, storageToken } from '../../shared/sessionData';
import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';

export const loadBarVentasPorSucursalDelPeriodoComparativo = (fechaDesde, fechaHasta) => {
  return (dispatch) => {
    dispatch(loadBarVentasPorSucursalDelPeriodoComparativoStart());

    setTimeout(function () {
      const ventasPorSucursalPeriodoActual = [
        {
          Sucursal: {
            Id: '001',
            Nombre: 'Abasto'
          },
          Monto: 1250000
        },
        {
          Sucursal: {
            Id: '002',
            Nombre: 'Alto Palermo'
          },
          Monto: 750000
        },
        {
          Sucursal: {
            Id: '003',
            Nombre: 'Unicenter'
          },
          Monto: 652000
        },
        {
          Sucursal: {
            Id: '004',
            Nombre: 'Alcorta'
          },
          Monto: 810500
        },
        {
          Sucursal: {
            Id: '005',
            Nombre: 'DOT'
          },
          Monto: 610500
        },
        {
          Sucursal: {
            Id: '006',
            Nombre: 'Av. Córdoba'
          },
          Monto: 910500
        },
        {
          Sucursal: {
            Id: '007',
            Nombre: 'Outlet'
          },
          Monto: 510500
        }
      ];

      const ventasPorSucursalPeriodoAnterior = [
        {
          Sucursal: {
            Id: '001',
            Nombre: 'Abasto'
          },
          Monto: 851000
        },
        {
          Sucursal: {
            Id: '002',
            Nombre: 'Alto Palermo'
          },
          Monto: 941500
        },
        {
          Sucursal: {
            Id: '003',
            Nombre: 'Unicenter'
          },
          Monto: 621000
        },
        {
          Sucursal: {
            Id: '004',
            Nombre: 'Alcorta'
          },
          Monto: 653500
        },
        {
          Sucursal: {
            Id: '005',
            Nombre: 'DOT'
          },
          Monto: 536980
        },
        {
          Sucursal: {
            Id: '006',
            Nombre: 'Av. Córdoba'
          },
          Monto: 1205310
        },
        {
          Sucursal: {
            Id: '007',
            Nombre: 'Outlet'
          },
          Monto: 465108
        }
      ];

      const sucursales = ventasPorSucursalPeriodoActual.map((ventasSucursal) => ventasSucursal.Sucursal.Nombre);

      const seriePeriodoActual = {
        name: 'Periodo actual',
        data: ventasPorSucursalPeriodoActual.map((ventasSucursal) => ventasSucursal.Monto)
      };

      const seriePeriodoAnterior = {
        name: 'Periodo anterior',
        data: ventasPorSucursalPeriodoAnterior.map((ventasSucursal) => ventasSucursal.Monto)
      };

      dispatch(
        loadBarVentasPorSucursalDelPeriodoComparativoSuccess(seriePeriodoActual, seriePeriodoAnterior, sucursales)
      );
    }, 2500);
  };
};
const loadBarVentasPorSucursalDelPeriodoComparativoStart = () => {
  return {
    type: actionTypes.BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_START
  };
};
const loadBarVentasPorSucursalDelPeriodoComparativoSuccess = (
  ventasPorSucursalPeriodoActual,
  ventasPorSucursalPeriodoAnterior,
  sucursales
) => {
  return {
    type: actionTypes.BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_SUCCESS,
    ventasPorSucursalPeriodoActual: ventasPorSucursalPeriodoActual,
    ventasPorSucursalPeriodoAnterior: ventasPorSucursalPeriodoAnterior,
    sucursales: sucursales
  };
};

export const seleccionSucursalAnalisisDeSucursal = (sucursal) => {
  return {
    type: actionTypes.SELECCION_SUCURSAL_ANALISIS_DE_SUCURSAL,
    sucursal
  };
};

export const seleccionDataVentasDeLaSucursalPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR,
    selectionId
  };
};

export const seleccionFechaVentasDelMesAnioDeLaSucursalPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_LA_SUCURSAL,
    mes,
    anio
  };
};

export const seleccionDataVentasDeSucursalesPor = (selectionId) => {
  return {
    type: actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_SUCURSALES_POR,
    selectionId
  };
};

export const seleccionFechaVentasDelMesAnioDeSucursalesPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_SUCURSALES,
    mes,
    anio
  };
};

export const executeCambiarSucursal = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_SUCURSAL,
    loading
  };
};

export const cambiarSucursal = (idEmpresa, idUsuario, idSucursal) => {
  return (dispatch) => {
    dispatch(executeCambiarSucursal(true));
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));

    axios
      .put(
        `/sucursales/cambiarSucursarPorDefecto?empresaId=${idEmpresa}&usuarioId=${idUsuario}&sucursalId=${idSucursal}&indicadorId=${idIndicadores.cambiarSucursal}`,
        null,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        executeCambiarSucursal(false);
      });
  };
};

const executeCreateSucursal = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_SUCURSAL,
    loading
  };
};

export const createSucursal = (nuevaSucursal, success) => {
  return (dispatch) => {
    dispatch(executeCreateSucursal(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`/sucursales/sucursal`, nuevaSucursal, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(executeCreateSucursal(false));
        dispatch(loadSucursales(nuevaSucursal.empresaId, true));
        dispatch(
          enqueueSnackbar({
            message: `Sucursal creada con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        return success();
      })
      .catch((error) => {
        dispatch(executeCreateSucursal(false));
      });
  };
};

const executeUpdateSucursal = (loading) => {
  return {
    type: actionTypes.EXECUTE_UPDATE_SUCURSAL,
    loading
  };
};

export const updateSucursal = (sucursalId, updatedSucursal) => {
  return (dispatch) => {
    dispatch(executeUpdateSucursal(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .put(`/sucursales/sucursal?sucursalId=${sucursalId}`, updatedSucursal, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Sucursal modificada con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(executeUpdateSucursal(false));
        // window.location.reload();
      })
      .catch((error) => {
        dispatch(executeUpdateSucursal(false));
      });
  };
};

export const clearSucursales = () => {
  return {
    type: actionTypes.CLEAR_SUCURSALES
  };
};

export const loadSucursales = (empresaId, incluirInactivas, success) => {
  return (dispatch) => {
    dispatch(loadSucursalesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/sucursales/sucursales?empresaId=${empresaId}&incluirInactivas=${incluirInactivas}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadSucursalesSuccess(response.data));

        return success && success(response.data);
      })
      .catch((error) => {
        dispatch(loadSucursalesFail(error));
      });
  };
};

const loadSucursalesStart = () => {
  return {
    type: actionTypes.LOAD_SUCURSALES_START
  };
};

const loadSucursalesSuccess = (sucursales) => {
  return {
    type: actionTypes.LOAD_SUCURSALES_SUCCESS,
    sucursales
  };
};

const loadSucursalesFail = (error) => {
  return {
    type: actionTypes.LOAD_SUCURSALES_FAIL,
    error
  };
};

export const loadSucursalById = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/sucursales/sucursal?sucursalId=${sucursalId}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        success(response.data);
      })
      .catch((error) => {
        errorFail(error);
      });
  };
};

export const loadVentasPorMesDeLaSucursal = () => {
  return (dispatch) => {
    dispatch(loadVentasPorMesDeLaSucursalStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/ventasPorMesSucursales?monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesDeLaSucursal
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalVentas += venta.monto);
        });
        dispatch(loadVentasPorMesDeLaSucursalSuccess(response.data, formatVentasParaTabla, totalVentas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasPorMesDeLaSucursalFail(error));
          } else {
            dispatch(loadVentasPorMesDeLaSucursal());
          }
        } else {
          dispatch(loadVentasPorMesDeLaSucursalFail(error));
        }
      });
  };
};
export const clearVentasPorMesDeLaSucursal = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_MES_DE_LA_SUCURSAL
  };
};
const loadVentasPorMesDeLaSucursalStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_START
  };
};
const loadVentasPorMesDeLaSucursalSuccess = (ventas, ventasDataTable, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_SUCCESS,
    ventas,
    ventasDataTable,
    totalVentas
  };
};
const loadVentasPorMesDeLaSucursalFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorCategoriaDeCliente = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorCategoriaDeClienteStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorCategoriaDeCliente?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(mes, anio) : new Date();

        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));

          dispatch(
            loadVentasDelMesDeLaSucursalPorCategoriaDeClienteSuccess(response.data, ventasNegativo, fecha, totalVentas)
          );
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorCategoriaDeClienteFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE
  };
};
const loadVentasDelMesDeLaSucursalPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START
  };
};
const loadVentasDelMesDeLaSucursalPorCategoriaDeClienteSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorMarca = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorMarcaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMarca?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorMarca
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();
        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));
          dispatch(loadVentasDelMesDeLaSucursalPorMarcaSuccess(response.data, ventasNegativo, fecha, totalVentas));
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorMarcaFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorMarca = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA
  };
};
const loadVentasDelMesDeLaSucursalPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_START
  };
};
const loadVentasDelMesDeLaSucursalPorMarcaSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorRubro = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorRubroStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorRubro?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorRubro
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();
        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));
          dispatch(loadVentasDelMesDeLaSucursalPorRubroSuccess(response.data, ventasNegativo, fecha, totalVentas));
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorRubroFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorRubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO
  };
};
const loadVentasDelMesDeLaSucursalPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_START
  };
};
const loadVentasDelMesDeLaSucursalPorRubroSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorSubrubro = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorSubrubroStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSubrubro?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorSubrubro
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();
        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));
          dispatch(loadVentasDelMesDeLaSucursalPorSubrubroSuccess(response.data, ventasNegativo, fecha, totalVentas));
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorSubrubroFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO
  };
};
const loadVentasDelMesDeLaSucursalPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_START
  };
};
const loadVentasDelMesDeLaSucursalPorSubrubroSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorProvincia = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorProvinciaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorProvincia?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorProvincia
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();

        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));
          dispatch(loadVentasDelMesDeLaSucursalPorProvinciaSuccess(response.data, ventasNegativo, fecha, totalVentas));
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorProvinciaFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA
  };
};
const loadVentasDelMesDeLaSucursalPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_START
  };
};
const loadVentasDelMesDeLaSucursalPorProvinciaSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorZona = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorZonaStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorZona?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorZona
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();

        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));
          dispatch(loadVentasDelMesDeLaSucursalPorZonaSuccess(response.data, ventasNegativo, fecha, totalVentas));
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorZonaFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorZona = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA
  };
};
const loadVentasDelMesDeLaSucursalPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_START
  };
};
const loadVentasDelMesDeLaSucursalPorZonaSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_FAIL,
    error
  };
};

export const loadVentasDelMesDeLaSucursalPorVendedor = (mes, anio, sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDelMesDeLaSucursalPorVendedorStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorVendedor?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorVendedor
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = '';
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();

        if (success) {
          return success(response.data);
        } else {
          dispatch(seleccionFechaVentasDelMesAnioDeLaSucursalPor(mes, anio));
          dispatch(loadVentasDelMesDeLaSucursalPorVendedorSuccess(response.data, ventasNegativo, fecha, totalVentas));
        }
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadVentasDelMesDeLaSucursalPorVendedorFail(error));
        }
      });
  };
};
export const clearVentasDeLaSucursalPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR
  };
};
const loadVentasDelMesDeLaSucursalPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_START
  };
};
const loadVentasDelMesDeLaSucursalPorVendedorSuccess = (ventas, ventasNegativo, fecha, totalVentas) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    fecha,
    totalVentas
  };
};
const loadVentasDelMesDeLaSucursalPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDeLaSucursalPorClienteDeLaCategoria = (
  mes,
  anio,
  sucursalId,
  categoriaId,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorClienteDeLaCategoria?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&categoriaId=${categoriaId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorArticuloDeLaMarca = (mes, anio, sucursalId, marcaId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorArticuloDeLaMarca?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&marcaId=${marcaId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorArticuloDeLaMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError && failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorArticuloDelRubro = (mes, anio, sucursalId, rubroId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorArticuloDelRubro?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&rubroId=${rubroId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorArticuloDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorArticuloDelSubrubro = (mes, anio, sucursalId, subrubroId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorArticuloDelSubrubro?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&subrubroId=${subrubroId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorArticuloDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorClienteDeLaProvincia = (
  mes,
  anio,
  sucursalId,
  provinciaId,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorClienteDeLaProvincia?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&provinciaId=${provinciaId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorClienteDeLaProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorClienteDeLaZona = (mes, anio, sucursalId, zonaId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorClienteDeLaZona?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&zonaId=${zonaId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorClienteDeLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorClienteDelVendedor = (mes, anio, sucursalId, vendedorId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorClienteDelVendedor?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&vendedorId=${vendedorId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorClienteDelVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorClienteDelArticulo = (mes, anio, sucursalId, articuloId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorClienteDelArticulo?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&articuloId=${articuloId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorClienteDelArticulo
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalPorArticuloDelCliente = (mes, anio, sucursalId, clienteId, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorArticuloDelCliente?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&clienteId=${clienteId}&monedaId=${storageMonedaId()}&empresaId=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalPorArticuloDelCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDeLaSucursalDelArticuloAlCliente = (
  mes,
  anio,
  sucursalId,
  articuloId,
  clienteId,
  success,
  failError
) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDelArticuloAlCliente?mes=${mes}&anio=${anio}&sucursalId=${sucursalId}&articuloId=${articuloId}&clienteId=${clienteId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalDelArticuloAlCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadEvolucionVentasDeLaSucursalPorCategoriaDeCliente = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorCategoriaDeCliente?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE
  };
};
const loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START
  };
};
const loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadEvolucionVentasDeLaSucursalPorMarca = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeLaSucursalPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorMarca?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorMarcaSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorMarcaFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorMarca = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA
  };
};
const loadEvolucionVentasDeLaSucursalPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_START
  };
};
const loadEvolucionVentasDeLaSucursalPorMarcaSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_FAIL,
    error
  };
};

export const loadEvolucionVentasDeLaSucursalPorRubro = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeLaSucursalPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorRubro?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorRubroSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorRubroFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorRubro = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO
  };
};
const loadEvolucionVentasDeLaSucursalPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_START
  };
};
const loadEvolucionVentasDeLaSucursalPorRubroSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_FAIL,
    error
  };
};

export const loadEvolucionVentasDeLaSucursalPorSubrubro = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeLaSucursalPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorSubrubro?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorSubrubroSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorSubrubroFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO
  };
};
const loadEvolucionVentasDeLaSucursalPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_START
  };
};
const loadEvolucionVentasDeLaSucursalPorSubrubroSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadEvolucionVentasDeLaSucursalPorProvincia = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeLaSucursalPorProvinciaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorProvincia?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorProvinciaSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorProvinciaFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA
  };
};
const loadEvolucionVentasDeLaSucursalPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_START
  };
};
const loadEvolucionVentasDeLaSucursalPorProvinciaSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL,
    error
  };
};

export const loadEvolucionVentasDeLaSucursalPorZona = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadEvolucionVentasDeLaSucursalPorZonaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorZona?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorZonaSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorZonaFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorZona = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA
  };
};
const loadEvolucionVentasDeLaSucursalPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_START
  };
};
const loadEvolucionVentasDeLaSucursalPorZonaSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_FAIL,
    error
  };
};

export const loadEvolucionVentasDeLaSucursalPorVendedor = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasDeLaSucursalPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesPorVendedor?idMoneda=${storageMonedaId()}&sucursalId=${sucursalId}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDeLaSucursalEvolutivoPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasDeLaSucursalPorVendedorSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        if (errorFail) {
          return errorFail(error);
        } else {
          dispatch(loadEvolucionVentasDeLaSucursalPorVendedorFail(error));
        }
      });
  };
};
export const clearEvolucionVentasDeLaSucursalPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR
  };
};
const loadEvolucionVentasDeLaSucursalPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_START
  };
};
const loadEvolucionVentasDeLaSucursalPorVendedorSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS,
    ventas,
    fechas,
    total
  };
};
const loadEvolucionVentasDeLaSucursalPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL,
    error
  };
};

export const loadEvolucionDeVentasMensualDeSucursales = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeVentasMensualDeSucursalesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorMesSucursales?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorMesDeLaSucursal
        }`,
        {
          headers: { Authorization: authStr, 'No-Aplica-Filtro-Sucursales': true }
        }
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = {
              anio: venta.anio,
              meses: []
            };
          }
          formatVentasParaTabla[venta.anio].meses.push({
            mes: venta.mes,
            monto: venta.monto
          });
          return (totalVentas += venta.monto);
        });
        dispatch(loadEvolucionDeVentasMensualDeSucursalesSuccess(response.data, formatVentasParaTabla, totalVentas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEvolucionDeVentasMensualDeSucursalesFail(error));
          } else {
            dispatch(loadEvolucionDeVentasMensualDeSucursales(idEmpresa));
          }
        } else {
          dispatch(loadEvolucionDeVentasMensualDeSucursalesFail(error));
        }
      });
  };
};

export const clearEvolucionDeVentasMensualDeSucursales = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DE_SUCURSALES
  };
};

const loadEvolucionDeVentasMensualDeSucursalesStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_START
  };
};

const loadEvolucionDeVentasMensualDeSucursalesSuccess = (ventas, ventasDataTable, totalVentas) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_SUCCESS,
    ventas,
    ventasDataTable,
    totalVentas
  };
};

const loadEvolucionDeVentasMensualDeSucursalesFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorCategoriaDeClienteDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPorCategoriaDeClienteDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/sucursales/VentasPorSucursalPorCategoriaDeCliente?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;
        let total = 0;

        sucursales.map((sucursal) => {
          return sucursal.ventas.map((venta) => {
            return (total += venta.monto);
          });
        });

        return dispatch(
          loadVentasPorSucursalPorCategoriaDeClienteDelMesSuccess(sucursales, labels, parents, values, date, total)
        );
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPorCategoriaDeClienteDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPorCategoriaDeClienteDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES
  };
};

const loadVentasPorSucursalPorCategoriaDeClienteDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START
  };
};

const loadVentasPorSucursalPorCategoriaDeClienteDelMesSuccess = (sucursales, labels, parents, values, fecha, total) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha,
    total
  };
};

const loadVentasPorSucursalPorCategoriaDeClienteDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorMarcaDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPorMarcaDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());

    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/sucursales/VentasPorSucursalPorMarca?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;

        dispatch(loadVentasPorSucursalPorMarcaDelMesSuccess(sucursales, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPorMarcaDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPorMarcaDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES
  };
};

const loadVentasPorSucursalPorMarcaDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_START
  };
};

const loadVentasPorSucursalPorMarcaDelMesSuccess = (sucursales, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorSucursalPorMarcaDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorRubroDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPorRubroDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/sucursales/VentasPorSucursalPorRubro?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;

        return dispatch(loadVentasPorSucursalPorRubroDelMesSuccess(sucursales, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPorRubroDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPorRubroDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES
  };
};

const loadVentasPorSucursalPorRubroDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_START
  };
};

const loadVentasPorSucursalPorRubroDelMesSuccess = (sucursales, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorSucursalPorRubroDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorSubrubroDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPorSubrubroDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/sucursales/VentasPorSucursalPorSubrubro?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;

        return dispatch(loadVentasPorSucursalPorSubrubroDelMesSuccess(sucursales, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPorSubrubroDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPorSubrubroDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES
  };
};

const loadVentasPorSucursalPorSubrubroDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_START
  };
};

const loadVentasPorSucursalPorSubrubroDelMesSuccess = (sucursales, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorSucursalPorSubrubroDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorProvinciaDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPorProvinciaDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/sucursales/VentasPorSucursalPorProvincia?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDeLaProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;

        return dispatch(loadVentasPorSucursalPorProvinciaDelMesSuccess(sucursales, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPorProvinciaDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPorProvinciaDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES
  };
};

const loadVentasPorSucursalPorProvinciaDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_START
  };
};

const loadVentasPorSucursalPorProvinciaDelMesSuccess = (sucursales, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorSucursalPorProvinciaDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorZonaDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPoZonaDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);

    axios
      .get(
        `/sucursales/VentasPorSucursalPorZona?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDeLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;

        return dispatch(loadVentasPorSucursalPoZonaDelMesSuccess(sucursales, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPoZonaDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPoZonaDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES
  };
};

const loadVentasPorSucursalPoZonaDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_START
  };
};

const loadVentasPorSucursalPoZonaDelMesSuccess = (sucursales, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorSucursalPoZonaDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_FAIL,
    error
  };
};

export const loadVentasPorSucursalPorVendedorDelMes = (mes, anio) => {
  return (dispatch) => {
    dispatch(loadVentasPorSucursalPorVendedorDelMesStart());
    const authStr = 'Bearer '.concat(storageToken());
    const date = new Date(anio, mes, 1);
    axios
      .get(
        `/sucursales/VentasPorSucursalPorVendedor?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        const { sucursales, labels, parents, values } = response.data;
        return dispatch(loadVentasPorSucursalPorVendedorDelMesSuccess(sucursales, labels, parents, values, date));
      })
      .catch((error) => {
        dispatch(loadVentasPorSucursalPorVendedorDelMesFail(error));
      });
  };
};

export const clearVentasPorSucursalPorVendedorDelMes = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES
  };
};

const loadVentasPorSucursalPorVendedorDelMesStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_START
  };
};

const loadVentasPorSucursalPorVendedorDelMesSuccess = (sucursales, labels, parents, values, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_SUCCESS,
    sucursales,
    labels,
    parents,
    values,
    fecha
  };
};

const loadVentasPorSucursalPorVendedorDelMesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_FAIL,
    error
  };
};

export const loadVentasDeSucursalesPorCategoriaDeCliente = (mes = 0, anio = 0, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadVentasDeSucursalesPorCategoriaDeClienteStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorCategoriaDeCliente?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasDelMesDeLaSucursalPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const ventasNegativo = response.data.filter((rd) => rd.monto < 0);
        const formatVentasDataTable = [];

        let totalVentas = 0;
        response.data.map((venta) => (totalVentas += venta.monto));
        let fecha = fechaActualFunc();
        fecha = response.data.length > 0 ? new Date(anio, mes) : new Date();
        dispatch(
          loadVentasDeSucursalesPorCategoriaDeClienteSuccess(
            response.data,
            ventasNegativo,
            formatVentasDataTable,
            fecha,
            totalVentas
          )
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadVentasDeSucursalesPorCategoriaDeClienteFail(error));
          } else {
            dispatch(loadVentasDeSucursalesPorCategoriaDeCliente(mes, anio, idEmpresa));
          }
        } else {
          dispatch(loadVentasDeSucursalesPorCategoriaDeClienteFail(error));
        }
      });
  };
};

export const clearVentasDeSucursalesPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE
  };
};

const loadVentasDeSucursalesPorCategoriaDeClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_START
  };
};

const loadVentasDeSucursalesPorCategoriaDeClienteSuccess = (
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  totalVentas
) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_SUCCESS,
    ventas,
    ventasNegativo,
    ventasDataTable,
    fecha,
    totalVentas
  };
};

const loadVentasDeSucursalesPorCategoriaDeClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_FAIL,
    error
  };
};

export const loadVentasDeSucursalesDelMesPorMarca = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeSucursalesDelMesPorMarcaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorMarca?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasDeLasSucursalesPorMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          let fecha = '';
          fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes) : new Date(anio, mes);

          dispatch(loadVentasDeSucursalesDelMesPorMarcaSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeSucursalesDelMesPorMarcaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeSucursalesDelMesPorMarca = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA
  };
};

const loadVentasDeSucursalesDelMesPorMarcaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_START
  };
};

const loadVentasDeSucursalesDelMesPorMarcaSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeSucursalesDelMesPorMarcaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_FAIL,
    error
  };
};

export const loadVentasDeSucursalesDelMesPorRubro = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeSucursalesDelMesPorRubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorRubro?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes) : new Date(anio, mes);

          dispatch(loadVentasDeSucursalesDelMesPorRubroSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeSucursalesDelMesPorRubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeSucursalesDelMesPorRubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO
  };
};

const loadVentasDeSucursalesDelMesPorRubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_START
  };
};

const loadVentasDeSucursalesDelMesPorRubroSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeSucursalesDelMesPorRubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_FAIL,
    error
  };
};

export const loadVentasDeSucursalesDelMesPorSubrubro = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeSucursalesDelMesPorSubrubroStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorSubrubro?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes) : new Date(anio, mes);

          dispatch(loadVentasDeSucursalesDelMesPorSubrubroSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeSucursalesDelMesPorSubrubroFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeSucursalesDelMesPorSubrubro = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO
  };
};

const loadVentasDeSucursalesDelMesPorSubrubroStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_START
  };
};

const loadVentasDeSucursalesDelMesPorSubrubroSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeSucursalesDelMesPorSubrubroFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_FAIL,
    error
  };
};

export const loadVentasDeSucursalesDelMesPorVendedor = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeSucursalesDelMesPorVendedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorVendedor?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));

          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes) : new Date(anio, mes);
          dispatch(loadVentasDeSucursalesDelMesPorVendedorSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeSucursalesDelMesPorVendedorFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentasDeSucursalesDelMesPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR
  };
};

const loadVentasDeSucursalesDelMesPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_START
  };
};

const loadVentasDeSucursalesDelMesPorVendedorSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeSucursalesDelMesPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_FAIL,
    error
  };
};

export const loadVentasDeSucursalesDelMesPorProvincia = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentassDeSucursalesDelMesPorProvinciaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorProvincia?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes) : new Date(anio, mes);

          dispatch(loadVentassDeSucursalesDelMesPorProvinciaSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentassDeSucursalesDelMesPorProvinciaFail(error));
        return errorFail && errorFail(error);
      });
  };
};

export const clearVentassDeSucursalesDelMesPorProvincia = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA
  };
};

const loadVentassDeSucursalesDelMesPorProvinciaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_START
  };
};

const loadVentassDeSucursalesDelMesPorProvinciaSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentassDeSucursalesDelMesPorProvinciaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_FAIL,
    error
  };
};

export const loadVentasDeSucursalesDelMesPorZona = (mes = 0, anio = 0, success, errorFail) => {
  return (dispatch) => {
    !success && dispatch(loadVentasDeSucursalesDelMesPorZonaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasDeLasSucursalesPorZona?mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          let totalVentas = 0;
          let ventasNegativo = response.data.filter((venta) => venta.monto < 0);
          response.data.map((venta) => (totalVentas += venta.monto));
          const fecha =
            response.data.length > 0 ? new Date(response.data[0].anio, response.data[0].mes) : new Date(anio, mes);

          dispatch(loadVentasDeSucursalesDelMesPorZonaSuccess(response.data, ventasNegativo, totalVentas, fecha));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        dispatch(loadVentasDeSucursalesDelMesPorZonaFail(error));
        return errorFail(error);
      });
  };
};

export const clearVentasDeSucursalesDelMesPorZona = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA
  };
};

const loadVentasDeSucursalesDelMesPorZonaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_START
  };
};

const loadVentasDeSucursalesDelMesPorZonaSuccess = (ventas, ventasNegativo, total, fecha) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_SUCCESS,
    ventas,
    ventasNegativo,
    total,
    fecha
  };
};

const loadVentasDeSucursalesDelMesPorZonaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_FAIL,
    error
  };
};

export const loadVentasPorSucursalDeLaCategoriaDeCliente = (mes, anio, categoriaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDeLaCategoriaDeCliente?mes=${mes}&anio=${anio}&categoriaId=${categoriaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalPorZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorSucursalDeLaMarca = (mes, anio, marcaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDeLaMarca?mes=${mes}&anio=${anio}&marcaId=${marcaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDeLaMarca
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorSucursalDelRubro = (mes, anio, rubroId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDelRubro?mes=${mes}&anio=${anio}&rubroId=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorSucursalDelSubrubro = (mes, anio, subrubroId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDelSubrubro?mes=${mes}&anio=${anio}&subrubroId=${subrubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDelSubrubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorSucursalDeLaProvincia = (mes, anio, provinciaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDeLaProvincia?mes=${mes}&anio=${anio}&provinciaId=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDeLaProvincia
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorSucursalDeLaZona = (mes, anio, zonaId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDeLaZona?mes=${mes}&anio=${anio}&zonaId=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDeLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorSucursalDelVendedor = (mes, anio, vendedorId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalDelVendedor?mes=${mes}&anio=${anio}&vendedorId=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasPorSucursalDelVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesDeSucursalesPorClienteDelVendedor = (mes, anio, vendedorId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/ventas/VentasDelMesPorClienteDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.VentasDelMesPorClienteDelVendedor
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail(error);
      });
  };
};

export const loadEvolucionVentasPorSucursal = (idEmpresa = storageEmpresaId(), success) => {
  return (dispatch) => {
    dispatch(loadEvolucionVentasPorSucursalStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/sucursales/VentasPorSucursalPorMes?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasPorMesDeLaSucursal
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        let total = 0;
        let fechas = [];
        response.data.map((venta, index) =>
          venta.montos.map((montoMesAnio) => {
            if (index === 0) {
              fechas.push({ mes: montoMesAnio.mes, anio: montoMesAnio.anio });
            }
            return (total += montoMesAnio.monto);
          })
        );
        dispatch(loadEvolucionVentasPorSucursalSuccess(response.data, fechas, total));
        return success(response.data, fechas, total);
      })
      .catch((error) => {
        dispatch(loadEvolucionVentasPorSucursalFail(error));
      });
  };
};

export const clearEvolucionVentasPorSucursal = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_SUCURSAL
  };
};

const loadEvolucionVentasPorSucursalStart = () => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_START
  };
};

const loadEvolucionVentasPorSucursalSuccess = (ventas, fechas, total) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_SUCCESS,
    ventas,
    fechas,
    total
  };
};

const loadEvolucionVentasPorSucursalFail = (error) => {
  return {
    type: actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_FAIL,
    error
  };
};
