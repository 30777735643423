import React from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import IconConvertToPdf from '../../../assets/images/IconConvertToPdf.png';
import Spinner from "../Spinner/Spinner";

const ExportToPdfIcon = (props) => {
  const { element, fileName, onShowModal, onCloseModal, sendNotification, disabled,
    tooltipTitle, beforeExport, afterExport } = props;

  const body = () => {
    return (
      <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Spinner />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            Generando PDF...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const onExporting = (e) => {
    setTimeout(() => {
      let scale = 0.85;

      html2canvas(element, { logging: false, scale: scale })
        .then(function (canvas) {
          let landscape = true;
          var margin = 15;
          let docWidth = landscape ? 842 : 595;
          let docHeight = landscape ? 595 : 842;
          var htmlWidth = canvas.width;
          var htmlHeight = canvas.height;
          let ratio = landscape ? docHeight / docWidth : docWidth / docHeight;
          var pdfWidth = htmlWidth + (margin * 2);
          var pdfHeight = Math.ceil((pdfWidth * ratio)) + (margin * 2);
          var canvas_image_width = htmlWidth;
          var canvas_image_height = htmlHeight;
          var totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

          canvas.getContext('2d');
          const imgData = canvas.toDataURL('image/png', 1);
          let pdf = new jsPDF("landscape", 'pt', [pdfWidth, pdfHeight], true);
          pdf.addImage(imgData, 'PNG', margin, margin, canvas_image_width, canvas_image_height, undefined, 'FAST');

          if (totalPDFPages > 0) {
            for (var i = 1; i <= totalPDFPages; i++) {
              const position = -(pdfHeight * i) + (margin * 4);
              pdf.addPage([pdfWidth, pdfHeight], "landscape");
              pdf.addImage(imgData, 'PNG', margin, position, canvas_image_width, canvas_image_height, undefined, 'FAST');
            }

            pdf.save(`${fileName}.pdf`);
          } else {
            pdf.save(`${fileName}.pdf`);
          }
        })
        .catch((reason) => {
          console.log(reason);
          sendNotification({
            message: `Error al generar el PDF, intente nuevamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          });
        })
        .finally(() => {
          if (afterExport)
            afterExport();

          onCloseModal();
        });

    }, 1000);

    e.cancel = true;
  }

  const startExport = (e) => {
    if (!element)
      return;

    onShowModal(body());

    window.scrollTo(0, 0);

    if (beforeExport) {
      beforeExport(e);

      setTimeout(() => onExporting(e), 500);
    } else {
      onExporting(e);
    }
  }

  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : "Descargar en Pdf"}>
      <IconButton disabled={disabled} onClick={(e) => startExport(e)} style={{ marginRight: '10px' }} size="small">
        <img alt="" src={IconConvertToPdf} height={'35px'} />
      </IconButton>
    </Tooltip>
  )
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (body) => dispatch(actions.showGlobalModal(true, '', body, undefined, false, true, 'sm')),
    onCloseModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true, 'sm')),
    sendNotification: (notification) => dispatch(actions.enqueueSnackbar(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportToPdfIcon);