import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../../shared/formatNumber';
import Table from '../../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../../shared/sessionData';

import classes from './TablaUtilidadPorArticuloDeLaSeleccion.css';
import TablaUtilidadPorClienteDeLaSeleccion from '../TablaUtilidadPorClienteDeLaSeleccion/TablaUtilidadPorClienteDeLaSeleccion';
import TableComprobantesUtilidad from '../../../Comprobantes/TableComprobantesUtilidad/TableComprobantesUtilidad';

const TablaUtilidadPorArticuloDeLaSeleccion = (props) => {
  const { dataSelectedId, clientesPrimero } = props;
  const [utilidadLocal, setUtilidadLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes ? props.mes : props.itemExpanded.mes;
  const anio = props.anio ? props.anio : props.itemExpanded.anio;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      onLoadKey: 'onLoadUtilidadDelClientePorArticulos'
    },
    marca: {
      title: 'Marca',
      onLoadKey: 'onLoadUtilidadPorArticulosDeLaMarca'
    },
    rubro: {
      title: 'Rubro',
      onLoadKey: 'onLoadUtilidadPorArticulosDelRubro'
    },
    subrubro: {
      title: 'Subrubro',
      onLoadKey: 'onLoadUtilidadPorArticulosDelSubrubro'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadUtilidadDelClientePorArticulos'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadUtilidadDelClientePorArticulos'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadUtilidadDelClientePorArticulos'
    }
  };

  useEffect(() => {
    setLoading(true);
    props[dataOptions[dataSelectedId].onLoadKey](props.vendedorId, props.itemExpanded.id, mes, anio, (response) => {
      setUtilidadLocal(response);
      setLoading(false);
      props.endRequest && props.endRequest(response.length);
    });
  }, []);

  const headersStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: clientesPrimero ? 'rgb(220, 220, 220)' : 'rgb(235, 235, 235)'
  };

  const columns = [
    {
      title: 'Artículo',
      field: 'name',
      align: 'left',
      render: (rowData) => rowData.id.trim() + " - " + rowData.name.trim(),
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: headersStyle
    },
    {
      title: ``,
      field: 'venta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.venta)}`
    },
    {
      title: ``,
      field: 'costo',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.costo)}`
    },
    {
      title: ``,
      field: 'cantidadVendida',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => rowData.cantidadVendida
    },
    {
      title: ``,
      field: 'utilidadPorcSegunCosto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunCosto)}%`
    },
    {
      title: ``,
      field: 'utilidadPorcSegunVenta',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.utilidadPorcSegunVenta)}%`
    },
    {
      title: ``,
      field: 'porcUtilidadSobreElTotal',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.porcUtilidadSobreElTotal)}%`
    },
    {
      title: ``,
      field: 'utilidad',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headersStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.utilidad)}`
    }
  ];

  const data = () => {
    return utilidadLocal.map((utilidad) => {
      return {
        id: utilidad.id,
        mes: utilidad.mes,
        anio: utilidad.anio,
        name: utilidad.nombre,
        utilidad: utilidad.monto,
        costo: utilidad.costo,
        venta: utilidad.ventas,
        cantidadVendida: utilidad.unidadesVendidas,
        utilidadPorcSegunCosto: utilidad.utilidadPorcentualSegunCosto,
        utilidadPorcSegunVenta: utilidad.utilidadPorcentualSegunVentas,
        porcUtilidadSobreElTotal: utilidad.porcentajeUtilidadSobreElTotal
      };
    });
  };

  const inCategoriaDeCliente = dataSelectedId === 'categoriaDeCliente';
  const inZona = dataSelectedId === 'zona';
  const inProvincia = dataSelectedId === 'provincia';
  const inVendedor = dataSelectedId === 'vendedor';

  const verComprobantes = inCategoriaDeCliente || inZona || inProvincia || inVendedor;

  const tableRef = useRef();

  return (
    <div className={classes.tablaUtilidadPorArticuloDeLaSeleccion}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        columns={columns}
        loading={loading}
        detailPanel={(rowData) => {
          return verComprobantes ? (
            <TableComprobantesUtilidad
              articuloId={rowData.id}
              clienteId={props.itemExpanded.id}
              mes={mes}
              anio={anio}
              customWidth={'95%'}
            />
          ) : (
            <TablaUtilidadPorClienteDeLaSeleccion dataSelectedId={dataSelectedId} itemExpanded={rowData} />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-utilidad-por-articulo-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadUtilidadDelClientePorArticulos: (idVendedor, idCliente, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadDelClientePorArticulo(idVendedor, idCliente, mes, anio, success, errorFail)),

    onLoadUtilidadPorArticulosDeLaMarca: (idVendedor, idMarca, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorArticuloDeLaMarca(idVendedor, idMarca, mes, anio, success, errorFail)),

    onLoadUtilidadPorArticulosDelRubro: (idVendedor, idRubro, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorArticuloDelRubro(idVendedor, idRubro, mes, anio, success, errorFail)),

    onLoadUtilidadPorArticulosDelSubrubro: (idVendedor, idSubrubro, mes, anio, success, errorFail) =>
      dispatch(actions.loadUtilidadPorArticuloDelSubrubro(idVendedor, idSubrubro, mes, anio, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaUtilidadPorArticuloDeLaSeleccion);
