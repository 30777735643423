import React, {} from "react";
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PivotGridInformeUno from "../../../components/Indicadores/Personalizados/Tenacta/InformeUno/PivotGridInformeUno";

const Informes = (props) => {
  const { ejecucionDePagina } = props;

  return (
    <Grid container spacing={1} id="informeUno">
      <Grid item xs={12}>
        <PivotGridInformeUno ejecucionDePagina={ejecucionDePagina} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Informes);