import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { MTableHeader } from 'material-table';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../shared/formatNumber';
import ModalDetallesComprobante from '../../Comprobantes/ModalDetallesComprobante/ModalDetallesComprobante';
import classes from './TablaComparacionVentasDeLaSeleccion.css';

const TablaComparacionVentasPorPeriodosComprobantes = (props) => {
  const { onLoadComprobantes, itemExpanded, tipoParent, filtrosParent, withoutDecimals } = props;
  const [comprobantesState, setComprobantesState] = useState([]);
  const [loading, setLoading] = useState(false);

  const onLoadSuccess = (comprobantes) => {
    setComprobantesState(comprobantes);
    setLoading(false);
  }

  const onLoadFail = () => {
    setComprobantesState([]);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    onLoadComprobantes(itemExpanded.itemId, tipoParent, filtrosParent, props.desde, props.hasta, props.anio, onLoadSuccess, onLoadFail);
  }, []);

  const cellStyles = {
    fontSize: 'smaller',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(205 205 205)',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const columns = [
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle
    },
    {
      title: 'Importe',
      field: 'monto',
      align: 'right',
      cellStyle: (cellData) => {
        return {
          fontSize: 'smaller',
          paddingBottom: '0px',
          paddingTop: '0px',
          paddingLeft: '5px',
          paddingRight: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => withoutDecimals ? `${storageMonedaSigno()} ${formatNumberWithoutDecimals(rowData.monto)}` : `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante
          venta
          comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }}
          withoutDecimals={withoutDecimals}
        />;
      }
    }
  ];

  const data = () => {
    return comprobantesState.map((comprobante) => ({
      fecha: comprobante.fecha,
      comprobante: comprobante.comprobante,
      referencia: comprobante.referencia,
      numeroExterno: comprobante.numeroExterno,
      monto: comprobante.monto,
      idComproba: comprobante.idComproba,
      idSistema: comprobante.idSistema,
      idSucursal: comprobante.idSucursal
    }));
  };

  return (
    <div className={classes.TablaVentasDesagregadasMontoUnidad}>
      <Table
        columns={columns}
        style={{ width: props.customWidth ? props.customWidth : '100%', marginTop: 0, marginBottom: '15px' }}
        data={data()}
        noBoxShadow
        loading={loading}
        components={{
          Header: (props) => (
            <MTableHeader {...props}>
              {(props.columns[0].tableData.width = '20px')}
              {(props.columns[1].tableData.width = '250px')}
              {(props.columns[2].tableData.width = '20px')}
              {(props.columns[3].tableData.width = window.screen.width > 1600 ? ' 175px' : '165px')}
              {(props.columns[4].tableData.width = window.screen.width > 1600 ? ' 100px' : '135px')}
              {(props.columns[5].tableData.width = '35px')}
            </MTableHeader>
          )
        }}
        customOptions={{
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          toolbar: false,
          showTitle: false,
          detailPanelType: 'single',
          detailPanelColumnAlignment: 'right',
          paging: false,
          maxBodyHeight: comprobantesState && comprobantesState.length < 5 ? 450 : comprobantesState.length < 10 ? 500 : 550,
          rowStyle: (rowData, index) => ({
            cursor: 'auto',
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(index) && '#f2f2f2'
          }),
        }}
      />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadComprobantes: (id, tipoParent, filtrosParent, desde, hasta, anio, success, errorFail) =>
      dispatch(actions.loadDesagregadasPorPeriodosPorComprobantesPorTipoDeVenta(id, tipoParent, filtrosParent, desde, hasta, anio, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionVentasPorPeriodosComprobantes);