// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gastos__gastosContainer__6yyjv {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n}\r\n\r\n.Gastos__FlexHorizontal__E-po- {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-flow: row;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.Gastos__separator__HBnm5 {\r\n  width: 10px;\r\n}\r\n\r\n.Gastos__FlexVertical__GJ3qh {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n  margin: 10px;\r\n  margin-top: 0;\r\n  margin-left: 0;\r\n}\r\n\r\n.Gastos__pieGastosPorCategoria__hzQ9r {\r\n  height: 373px;\r\n}\r\n\r\n.Gastos__CombinedChartsCard__F5oqY {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  background-color: white;\r\n  margin: 5px !important;\r\n}\r\n\r\n@media (max-width: 1369px) {\r\n  .Gastos__pieGastosPorCategoria__hzQ9r {\r\n    height: 371px;\r\n    width: 490px !important;\r\n  }\r\n}\r\n\r\n@media (max-width: 769px) {\r\n  .Gastos__gastosContainer__6yyjv {\r\n    display: flex;\r\n    flex: 1 1;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .Gastos__pieGastosPorCategoria__hzQ9r {\r\n    height: 375px;\r\n    width: 520px;\r\n  }\r\n\r\n  .Gastos__FlexHorizontal__E-po- {\r\n    display: inline-block;\r\n  }\r\n\r\n  .Gastos__FlexVertical__GJ3qh {\r\n    display: inline-block;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gastosContainer": "Gastos__gastosContainer__6yyjv",
	"FlexHorizontal": "Gastos__FlexHorizontal__E-po-",
	"separator": "Gastos__separator__HBnm5",
	"FlexVertical": "Gastos__FlexVertical__GJ3qh",
	"pieGastosPorCategoria": "Gastos__pieGastosPorCategoria__hzQ9r",
	"CombinedChartsCard": "Gastos__CombinedChartsCard__F5oqY"
};
export default ___CSS_LOADER_EXPORT___;
