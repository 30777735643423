import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc
} from '../../../../shared/fechas';

const TextoVentasMensuales = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart(props.idCliente, props.vendedorSeleccionado);
    }

    if (props.empresa) {
      props.onLoadChart(props.vendedorSeleccionado, props.empresa.id);
    }

    return () => {
      if (props.empresa) {
        props.onUnmountChart();
      }
    };
  }, [props.ejecucionDePagina]);

  const isDisabledChart = props.estaOculto && !props.empresa;

  return (
    <TextChart
      title={`Ventas del mes ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} ${
        props.empresa ? `${props.empresa.nombre}` : ``
      }`}
      bigAmount={props.mesActual}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} a ${fechaFinPeriodoMesActualFunc()} `}
      smallAmount={props.mesAnterior}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} a ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={props.loading}
      porcentaje
      bigCurrency
      smallCurrency
      disabled={isDisabledChart}
      border={props.border && !props.error}
      imgSinDatos={props.error}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.pieVentasComparativo.loading,
    error: state.ventas.pieVentasComparativo.error,
    mesActual: state.ventas.pieVentasComparativo.mesActual,
    mesAnterior: state.ventas.pieVentasComparativo.mesAnterior,
    estaCargado: state.ventas.pieVentasComparativo.estaCargado,
    vendedorSeleccionado: state.filtros.vendedores.seleccionado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDelMes,
    recargarIndicador: state.configuraciones.actualizacionIndicadores.fotoDelDia
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (vendedor, idEmpresa) => dispatch(actions.loadPieVentasComparativo(vendedor, idEmpresa)),
    onUnmountChart: () => dispatch(actions.clearPieVentasComparativo()),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas del mes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoVentasMensuales);
