import React from 'react';
import { connect } from 'react-redux';
import BoxEjecutarPaginaInfo from '../../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

import classes from '../Tenacta.css';
import { storageEsMultiempresa } from '../../../shared/sessionData';
import EmpresaSelector from '../../../components/UI/SelectorEmpresa/EmpresaSelector';
import InformesTabs from '../../../components/UI/Informes/InformesTabs/InformesTabs';
import TablasInformeDos from '../../../components/Indicadores/Personalizados/Tenacta/InformeDos/TablasInformeDos/TablasInformeDos';
import ImportacionDos from './ImportacionDos';

const InformeDos = (props) => {
  sessionStorage.setItem('seccionNombre', 'Informe 2');
  const isMultiemp = storageEsMultiempresa();

  return (
    <div className={classes.PanelContainer}>
      {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {!props.ejecucionDePagina ?
        (<BoxEjecutarPaginaInfo />) :
        (<div>
          <InformesTabs
            tabs={['Budget/Forecast', 'Importar Budget/Forecast']}
            views={[
              <TablasInformeDos
                ejecucionDePagina={props.ejecucionDePagina}
                containerWidth={window.innerWidth < 1350 ? "92.5vw" : window.innerWidth < 1900 ? "93.5vw" : "100%"}
                minHeight={450}
              />,
              <ImportacionDos ejecucionDePagina={props.ejecucionDePagina} />
            ]}
            rootClass={classes.TabsRoot}
          />
        </div>)}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ejecucionDePagina: state.ejecucionPaginas.ejecucionInformeDosTenacta
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InformeDos);