import React, { useRef } from 'react';
import classes from '../InformeTres.css';
import Table from '../../../../../UI/Table/Table';
import { ChevronRight } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { formatNumber, formatNumberWithMonedaSigno, formatNumberWithoutDecimals } from '../../../../../../shared/formatNumber';
import TablaPorCliente from '../TablaPorCliente/TablaPorCliente';

const TablaPorArticulo = (props) => {
  const { articulos, expandAll, maxHeight } = props;

 const headersStyle = {
    fontSize: 'smaller',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: '#efefef'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '11px',
      paddingBottom: '2px',
      paddingTop: '2px',
      paddingLeft: '3px',
      paddingRight: '3px',
      color: value < 0 && 'red',
      width
    };
  };

  const columns = [
    {
      title: '',
      field: 'articulo',
      align: 'left',
      cellStyle: (cellData) => cellStyles(cellData, '12%', true),
      headerStyle: headersStyle,
      width: '12%'
    },
    {
      title: '',
      field: 'unidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidades),
      width: '10%'
    },
    {
      title: '',
      field: 'unidadesPresupuesto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidadesPresupuesto),
      width: '11%'
    },
    {
      title: '',
      field: 'unidadesDiferencia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithoutDecimals(rowData.unidadesDiferencia),
      width: '10%'
    },
    {
      title: '',
      field: 'unidadesCumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.unidadesCumplimiento * 100)}%`,
      width: '11%'
    },
    {
      title: '',
      field: 'importeNeto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      headerStyle: headersStyle,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNeto),
      width: '10%'
    },
    {
      title: '',
      field: 'importeNetoPresupuesto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '11%'),
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNetoPresupuesto),
      headerStyle: headersStyle,
      width: '11%'
    },
    {
      title: '',
      field: 'importeNetoDiferencia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '10%'),
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importeNetoDiferencia),
      headerStyle: headersStyle,
      width: '11%'
    },
    {
      title: '',
      field: 'importeNetoCumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '13%'),
      headerStyle: headersStyle,
      render: (rowData) => `${formatNumber(rowData.importeNetoCumplimiento * 100)}%`,
      width: '13%'
    },
  ];

  const detailPanel = [
    {
      disabled: false,
      tooltip: 'Ver clientes',
      icon: () => <ChevronRight fontSize='small' />,
      openIcon: () => <KeyboardArrowDownIcon fontSize='small' />,
      render: (rowData) => {
        return (
          <div className={classes.tablaVentasPorArticulo}>
            <div style={{ width: '100%', paddingLeft: '50px' }}>
              <TablaPorCliente
                idLinea={rowData.idLinea}
                idRubro={rowData.idRubro}
                idArticulo={rowData.idArticulo}
                items={rowData.clientes}
                maxHeight={maxHeight}
              />
            </div>
          </div>
        )
      }
    }
  ];

  const data = () => {
    const result = [];
    const items = articulos ? articulos : [];

    items.forEach((item, index) => {
      result.push({
        ...item,
        tableData: {
          id: index,
          showDetailPanel: expandAll ? detailPanel[0].render : undefined,
        }
      })
    });

    return result;
  };

  const tableRef = useRef();

  return (
    <div className={classes.tablaVentasPorRubro}>
      <Table
        tableRef={tableRef}
        style={{
          marginTop: 0,
          marginBottom: '10px',
          border: "0px solid #ccc",
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "white",
          borderRadius: "3px"
        }}
        title=""
        columns={columns}
        data={data()}
        detailPanel={detailPanel}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: maxHeight,
          defaultExpanded: true,
          headerStyle: { backgroundColor: '#efefef' }
        }}
      />
    </div>
  );
};

export default TablaPorArticulo;