import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import TextChart from '../../../Charts/TextChart';

const TextoTotalDeudaClientes = (props) => {
    const {
        ordenamientoSelected,
        fechaDeudaDelMes,
        ejecucionDePagina
    } = props

  useEffect(() => {
    if (ejecucionDePagina) {
        props.onLoadDeudasDelMesPorOrdenamiento(
          fechaDeudaDelMes.mes,
          fechaDeudaDelMes.anio
        );
      }
    }, [ordenamientoSelected, fechaDeudaDelMes]);
  const isDisabledChart = props.estaOculto && !props.empresa;

  return (
    <TextChart
      title={`Total`}
      bigAmount={`${props.deudasPorCliente.total}`}
      bigAmountTooltip={`Total`}
      bigCurrency
      loading={props.deudasPorCliente.loading}
      disabled={isDisabledChart}
      porcentaje
      border={props.border && !props.error}
      imgSinDatos={props.error}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fechaDeudaDelMes: state.ventas.fechaDeudasDelMesAnio,
    deudasPorCliente: state.ventas.deudaDelMesPorCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDeudasDelMesPorOrdenamiento: (mes, anio) => dispatch(actions.loadDeudaDelMesPorOrdenamiento(2, mes, anio)),   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoTotalDeudaClientes);
