import React, { Fragment, useRef, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions';
import { Grid, Typography } from "@material-ui/core";
import classes from "../InformeTres.css";
import { storageEmpresaId, storageUsuarioId } from "../../../../../../shared/sessionData";
import TablasInformeTresToExcel from "./TablasInformeTresToExcel";
import ExportToPdfIcon from "../../../../../UI/Icons/ExportToPdfIcon";
import FiltrosInformeTres from "../FiltrosInformeTres/FiltrosInformeTres";
import TablaPorLinea from '../TablaPorLinea/TablaPorLinea';

const TablasInformeTres = (props) => {

  const { anioInforme, aprobado, budgetAnterior, datos, onLoadData, loading } = props;

  //#region Filtro
  const onAplicarFiltroClick = (anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta,
    unidadDeNegocio, canal, vendedor, vendedores) => {
    const request = {
      anioFiscal: typeof anioFiscal === 'object' ? anioFiscal.getFullYear() : anioFiscal,
      idArchivo,
      mesFiscal,
      acumulado,
      idEmpresa: storageEmpresaId(),
      idUsuario: storageUsuarioId(),
      desde,
      hasta,
      unidadDeNegocio,
      canal,
      vendedor,
      vendedores
    };

    onLoadData(request);
  }

  //#endregion

  //#region Datos
  const datoPorLinea = (linea) => {
    if (!datos)
      return [];

    const datoLinea = datos.find((item) => item.linea.trimEnd() === linea);
    return datoLinea ? datoLinea.items : [];
  }

  //#endregion

  //#region Export a PDF
  const informeTresRef = useRef(null);
  const [onExport, setOnExport] = useState(false);

  const onExportStart = () => {
    setOnExport(true);
  }

  const onExportFinish = () => {
    setOnExport(false);
  }
  //#endregion

  return (
    <div ref={informeTresRef}>
      <Grid container style={{ height: "100%", minHeight: props.minHeight + 50 }}>
        <Grid item xs={12}
          container spacing={1}
          direction='row' justifyContent='space-between' alignContent='flex-start'
          style={{
            margin: 'auto', border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "white", borderRadius: "3px", padding: '10px'
          }}
        >
          <Grid item xs={12} container className={classes.TitleContainer} direction="row" justifyContent='space-between'>
            <Grid item xs={8} lg={2}>
              <Typography variant="h5">
                INFORME 3
              </Typography>
            </Grid>
            <Grid container spacing={1} item xs={4} lg={1} direction='row' justifyContent='flex-end'>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <ExportToPdfIcon
                  element={informeTresRef && informeTresRef.current}
                  fileName='Informe 3 - Objetivos por rubros'
                  tooltipTitle={"Descargar tablas en Pdf"}
                  beforeExport={() => onExportStart()}
                  afterExport={() => onExportFinish()}
                />
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <TablasInformeTresToExcel
                  data={datos}
                  name='Objetivos'
                  filename='Informe 3 - Objetivos por rubros'
                  tooltipTitle={"Descargar tablas en Excel"}
                />
              </Grid>
            </Grid>
          </Grid>
          <FiltrosInformeTres
            aplicarFiltros={onAplicarFiltroClick}
          />
          <Grid item xs={12}>
            {!aprobado && (
              <Typography variant="subtitle1" color="error">
                El budget/forecast asociado no está aprobado.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {budgetAnterior && anioInforme && (
              <Typography variant="subtitle1" color="error">
                El budget/forecast asociado es del año: {anioInforme}.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} style={{ marginTop: '20px' }} direction="row" justifyContent="center">
          <Grid item xs={12}>
            <TablaPorLinea
              nombreLinea={"ASPEN"}
              loading={loading}
              datos={datos}
              onExport={onExport}
            />
          </Grid>
          <Grid item xs={12}>
            <TablaPorLinea
              nombreLinea={"ORTOPEDIA"}
              loading={loading}
              datos={datos}
              onExport={onExport}
            />
          </Grid>
          <Grid item xs={12}>
            <TablaPorLinea
              nombreLinea={"IMETEC"}
              loading={loading}
              datos={datos}
              onExport={onExport}
            />
          </Grid>
          <Grid item xs={12}>
            <TablaPorLinea
              nombreLinea={"TENACTA"}
              loading={loading}
              datos={datos}
              onExport={onExport}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.informeTresData.loading,
    error: state.tenacta.informeTresData.error,
    estaCargado: state.tenacta.informeTresData.estaCargado,
    datos: state.tenacta.informeTresData.datos,
    aprobado: state.tenacta.informeTresData.aprobado,
    budgetAnterior: state.tenacta.informeTresData.esAnterior,
    filtros: state.tenacta.informeTresFiltros,
    anioInforme: state.tenacta.informeTresData.anioInforme
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (request) => dispatch(actions.loadInformeTresUnidades(request))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablasInformeTres);