import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  indicadoresPorUsuario: {
    indicadores: [],
    loading: false,
    error: null
  },
  indicadoresOcultosPorUsuario: {
    indicadores: {
      ventasDelMes: false,
      ventasDelDia: false,
      ivaFiscal: false,
      cobranzasDelMes: false,
      gastosPorCategoria: false,
      saldoDeudores: false,
      saldoAcreedores: false,
      plazoMedioVencACobrar: false,
      plazoMedioVencAPagar: false,
      promDiasDeCobranzas: false,
      promDiasDePagos: false,
      chequesEnCartera: false,
      chequesRechazados: false,
      chequesEmitidos: false,
      gastosDelMes: false,
      pagosDelMes: false,
      ivaFiscalMultiempresa: false,
      chequesEnCarteraMultiempresa: false,
      chequesRechazadosMultiempresa: false,
      chequesEmitidosPendientesMultiempresa: false,
      cobranzasMensualesMultiempresa: false,
      gastosDelMesMultiempresa: false,
      pagosDelMesMultiempresa: false,
      saldoAcreedoresMultiempresa: false,
      saldoDeudoresMultiempresa: false,
      ventasDiariasMultiempresa: false,
      ventasMensualesMultiempresa: false,
      rentabilidadMultiempresa: false,
      deudaPorCategoriaDeCliente: false,
      deudaPorCategoriaDeProveedor: false
    },
    loading: true,
    error: null
  },
  postIndicadoresOcultos: {
    loading: false
  },
  postComprobantes: {
    loading: false,
    modificado: false
  },
  postCategoriasItemsDeCompras: {
    loading: false,
    modificado: false
  },
  postCategoriasDeCliente: {
    loading: false,
    modificado: false
  },
  postCategoriasDeProveedor: {
    loading: false,
    modificado: false
  },
  loadCategoriasItemsDeCompras: {
    categoriasItemsDeCompras: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadTiemposRequest: {
    indicadores: [],
    tiempos: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  actualizacionIndicadores: {
    fotoDelDia: false,
    gastos: false,
    multiempresa: false
  },
  categoriasDeProveedor: {
    categorias: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  executeCambiarAjustaPorInflacion: false,
  loadInflacion: {
    inflaciones: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadInflacionPorAnio: {
    inflaciones: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadNovedades: {
    novedades: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadEstadosCheques: {
    estados: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadChequesValorEstado: {
    chequesValorEstado: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  pivotGridConfiguraciones: {
    items: []
  },
  pivotGridInformaciones: {
    items: []
  }
};

//#region load Indicadores
const loadIndicadoresStart = (state) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: true,
      indicadores: [],
      error: null
    })
  });
};

const loadIndicadoresSuccess = (state, indicadores) => {
  return updateObject(state, {
    indicadoresPorUsuario: updateObject(state.indicadoresPorUsuario, {
      loading: false,
      indicadores
    })
  });
};

const loadIndicadoresFail = (state, error) => {
  return updateObject(state, {
    indicadoresPorUsuario: updateObject(state.indicadoresPorUsuario, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load Indicadores Ocultos
const loadIndicadoresOcultosStart = (state) => {
  return updateObject(state, {
    indicadoresOcultosPorUsuario: updateObject(state.indicadoresOcultosPorUsuario, {
      loading: true,
      indicadores: [],
      error: null
    })
  });
};

const loadIndicadoresOcultosSuccess = (state, indicadores) => {
  return updateObject(state, {
    indicadoresOcultosPorUsuario: updateObject(state.indicadoresOcultosPorUsuario, {
      loading: false,
      indicadores
    })
  });
};

const loadIndicadoresOcultosFail = (state, error) => {
  return updateObject(state, {
    indicadoresOcultosPorUsuario: updateObject(state.indicadoresOcultosPorUsuario, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region post Indicadores Ocultos
const executePostIndicadoresOcultos = (state, loading) => {
  return updateObject(state, {
    postIndicadoresOcultos: updateObject(state.postIndicadoresOcultos, {
      loading
    })
  });
};
//#endregion

const executePostComprobantes = (state, loading, modificado) => {
  return updateObject(state, {
    postComprobantes: updateObject(state.postComprobantes, {
      loading,
      modificado
    })
  });
};

const executePostCategoriasItemsDeCompras = (state, loading, modificado) => {
  return updateObject(state, {
    postCategoriasItemsDeCompras: updateObject(state.postCategoriasItemsDeCompras, {
      loading,
      modificado
    })
  });
};

const executePostCategoriasDeCliente = (state, loading, modificado) => {
  return updateObject(state, {
    postCategoriasDeCliente: updateObject(state.postCategoriasDeCliente, {
      loading,
      modificado
    })
  });
};

const executePostCategoriasDeProveedor = (state, loading, modificado) => {
  return updateObject(state, {
    postCategoriasDeProveedor: updateObject(state.postCategoriasDeProveedor, {
      loading,
      modificado
    })
  });
};

const clearModificacionesConfiguracion = (state) => {
  return updateObject(state, {
    postComprobantes: updateObject(state.postComprobantes, {
      loading: false,
      modificado: false
    }),
    postCategoriasItemsDeCompras: updateObject(state.postCategoriasItemsDeCompras, {
      loading: false,
      modificado: false
    })
  });
};
//#endregion

//#region load Categorias Items de compras
const loadCategoriasItemsDeComprasStart = (state) => {
  return updateObject(state, {
    loadCategoriasItemsDeCompras: updateObject(state.loadCategoriasItemsDeCompras, {
      loading: true,
      estaCargado: false,
      categoriasItemsDeCompras: [],
      error: null
    })
  });
};

const loadCategoriasItemsDeComprasSuccess = (state, categoriasItemsDeCompras) => {
  return updateObject(state, {
    loadCategoriasItemsDeCompras: updateObject(state.loadCategoriasItemsDeCompras, {
      loading: false,
      estaCargado: true,
      categoriasItemsDeCompras
    })
  });
};

const loadCategoriasItemsDeComprasFail = (state, error) => {
  return updateObject(state, {
    loadCategoriasItemsDeCompras: updateObject(state.loadCategoriasItemsDeCompras, {
      loading: false,
      estaCargado: true,
      error
    })
  });
};
//#endregion

//#region load Tiempos request
const loadTiemposRequestStart = (state) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: true
    })
  });
};

const loadTiemposRequestSuccess = (state, indicadores, tiempos) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      indicadores,
      tiempos,
      estaCargado: true
    })
  });
};

const loadTiemposRequestFail = (state, error) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearTiemposRequest = (state) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      indicadores: [],
      tiempos: [],
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region actualizar indicadores
const actualizarIndicadores = (state, page) => {
  switch (page) {
    case '/fotoDelDia' || '':
      return updateObject(state, {
        actualizacionIndicadores: updateObject(state.actualizacionIndicadores, {
          fotoDelDia: !state.actualizacionIndicadores.fotoDelDia
        })
      });

    case '/gastos':
      return updateObject(state, {
        actualizacionIndicadores: updateObject(state.actualizacionIndicadores, {
          gastos: !state.actualizacionIndicadores.fotoDelDia
        })
      });

    case '/multiempresa':
      return updateObject(state, {
        actualizacionIndicadores: updateObject(state.actualizacionIndicadores, {
          multiempresa: !state.actualizacionIndicadores.fotoDelDia
        })
      });

    default:
      return state;
  }
};
//#endregion

//#region load Categorias de proveedor
const loadCategoriasDeProveedorStart = (state) => {
  return updateObject(state, {
    categoriasDeProveedor: updateObject(state.categoriasDeProveedor, {
      loading: true
    })
  });
};

const loadCategoriasDeProveedorSuccess = (state, categorias) => {
  return updateObject(state, {
    categoriasDeProveedor: updateObject(state.categoriasDeProveedor, {
      loading: false,
      categorias,
      estaCargado: true
    })
  });
};

const loadCategoriasDeProveedorFail = (state, error) => {
  return updateObject(state, {
    categoriasDeProveedor: updateObject(state.categoriasDeProveedor, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load Inflacion
const loadInflacionesStart = (state) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: true,
      inflaciones: [],
      error: null
    })
  });
};

const loadInflacionesSuccess = (state, inflaciones) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      inflaciones
    })
  });
};

const loadInflacionesFail = (state, error) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      error
    })
  });
};

const loadInflacionPorAnioStart = (state) => {
  return updateObject(state, {
    loadInflacionPorAnio: updateObject(state.loadInflacionPorAnio, {
      loading: true,
      inflaciones: [],
      error: null
    })
  });
};

const loadInflacionPorAnioSuccess = (state, inflaciones) => {
  return updateObject(state, {
    loadInflacionPorAnio: updateObject(state.loadInflacionPorAnio, {
      loading: false,
      inflaciones,
      estaCargado: true
    })
  });
};

const loadInflacionPorAnioFail = (state, error) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load Novedades
const loadNovedadesStart = (state) => {
  return updateObject(state, {
    loadNovedades: updateObject(state.loadNovedades, {
      loading: true,
      novedades: [],
      error: null
    })
  });
};

const loadNovedadesSuccess = (state, novedades) => {
  return updateObject(state, {
    loadNovedades: updateObject(state.loadNovedades, {
      loading: false,
      novedades
    })
  });
};

const loadNovedadesFail = (state, error) => {
  return updateObject(state, {
    loadNovedades: updateObject(state.loadNovedades, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load EstadosCheques
const loadEstadosChequesStart = (state) => {
  return updateObject(state, {
    loadEstadosCheques: updateObject(state.loadEstadosCheques, {
      loading: true,
      estados: [],
      error: null
    })
  });
};

const loadEstadosChequesSuccess = (state, estados) => {
  return updateObject(state, {
    loadEstadosCheques: updateObject(state.loadEstadosCheques, {
      loading: false,
      estados
    })
  });
};

const loadEstadosChequesFail = (state, error) => {
  return updateObject(state, {
    loadEstadosCheques: updateObject(state.loadEstadosCheques, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load ChequesValorEstado
const loadChequesValorEstadoStart = (state) => {
  return updateObject(state, {
    loadChequesValorEstado: updateObject(state.loadChequesValorEstado, {
      loading: true,
      chequesValorEstado: [],
      error: null
    })
  });
};

const loadChequesValorEstadoSuccess = (state, chequesValorEstado) => {
  return updateObject(state, {
    loadChequesValorEstado: updateObject(state.loadChequesValorEstado, {
      loading: false,
      chequesValorEstado
    })
  });
};

const loadChequesValorEstadoFail = (state, error) => {
  return updateObject(state, {
    loadChequesValorEstado: updateObject(state.loadChequesValorEstado, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region Pivot Grid Configuraciones
const setItemsConfiguraciones = (state, key, configuraciones, loading, estaCargado, error) => {
  let items = state.pivotGridConfiguraciones.items;
  items = items.filter((item) => item.key !== key);
  let selected = configuraciones && configuraciones.length !== 0 ? configuraciones[0] : undefined;

  let newItem = {
    key,
    configuraciones,
    selected,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
};

const loadPivotGridConfiguracionesStart = (state, key) => {
  let items = setItemsConfiguraciones(state, key, [], true, false, null);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items
    })
  });
};

const loadPivotGridConfiguracionesSuccess = (state, key, configuraciones) => {
  let items = setItemsConfiguraciones(state, key, configuraciones, false, true, null);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items
    })
  });
};

const loadPivotGridConfiguracionesFail = (state, key, error) => {
  let items = setItemsConfiguraciones(state, key, [], false, true, error);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items
    })
  });
};

const clearPivotGridConfiguraciones = (state, key) => {
  let items = state.pivotGridConfiguraciones.items.filter((item) => item.key !== key);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items
    })
  });
};

//#endregion

//#region Pivot Grid Informaciones
const setItemsInformaciones = (state, key, informaciones, loading, estaCargado, error) => {
  let items = state.pivotGridInformaciones.items;
  items = items.filter((item) => item.key !== key);

  let newItem = {
    key,
    informaciones,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
};

const loadPivotGridInformacionesStart = (state, key) => {
  let items = setItemsInformaciones(state, key, [], true, false, null);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items
    })
  });
};

const loadPivotGridInformacionesSuccess = (state, key, informaciones) => {
  let items = setItemsInformaciones(state, key, informaciones, false, true, null);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items
    })
  });
};

const loadPivotGridInformacionesFail = (state, key, error) => {
  let items = setItemsInformaciones(state, key, [], false, true, error);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items
    })
  });
};

const clearPivotGridInformaciones = (state, key) => {
  let items = state.pivotGridInformaciones.items.filter((item) => item.key !== key);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items
    })
  });
};

//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION:
      return { ...state, executeCambiarAjustaPorInflacion: action.loading };

    case actionTypes.LOAD_CONFIGURACION_INDICADORES_START:
      return loadIndicadoresStart(state);
    case actionTypes.LOAD_CONFIGURACION_INDICADORES_SUCCESS:
      return loadIndicadoresSuccess(state, action.indicadores);
    case actionTypes.LOAD_CONFIGURACION_INDICADORES_FAIL:
      return loadIndicadoresFail(state, action.error);

    case actionTypes.LOAD_INDICADORES_OCULTOS_START:
      return loadIndicadoresOcultosStart(state);
    case actionTypes.LOAD_INDICADORES_OCULTOS_SUCCESS:
      return loadIndicadoresOcultosSuccess(state, action.indicadores);
    case actionTypes.LOAD_INDICADORES_OCULTOS_FAIL:
      return loadIndicadoresOcultosFail(state, action.error);

    case actionTypes.EXECUTE_POST_INDICADORES_OCULTOS:
      return executePostIndicadoresOcultos(state, action.loading);
    case actionTypes.EXECUTE_POST_COMPROBANTES:
      return executePostComprobantes(state, action.loading, action.modificado);
    case actionTypes.EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA:
      return executePostCategoriasItemsDeCompras(state, action.loading, action.modificado);

    case actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START:
      return loadCategoriasItemsDeComprasStart(state);
    case actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS:
      return loadCategoriasItemsDeComprasSuccess(state, action.categoriasItemsDeCompras);
    case actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL:
      return loadCategoriasItemsDeComprasFail(state, action.error);

    case actionTypes.LOAD_TIEMPOS_REQUEST_START:
      return loadTiemposRequestStart(state);
    case actionTypes.LOAD_TIEMPOS_REQUEST_SUCCESS:
      return loadTiemposRequestSuccess(state, action.indicadores, action.tiempos);
    case actionTypes.LOAD_TIEMPOS_REQUEST_FAIL:
      return loadTiemposRequestFail(state, action.error);
    case actionTypes.CLEAR_TIEMPOS_REQUEST:
      return clearTiemposRequest(state);

    case actionTypes.ACTUALIZAR_INDICADORES:
      return actualizarIndicadores(state, action.page);

    case actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_START:
      return loadCategoriasDeProveedorStart(state);
    case actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS:
      return loadCategoriasDeProveedorSuccess(state, action.categorias);
    case actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL:
      return loadCategoriasDeProveedorFail(state, action.error);

    case actionTypes.LOAD_INFLACION_START:
      return loadInflacionesStart(state);
    case actionTypes.LOAD_INFLACION_SUCCESS:
      return loadInflacionesSuccess(state, action.inflaciones);
    case actionTypes.LOAD_INFLACION_FAIL:
      return loadInflacionesFail(state, action.error);

    case actionTypes.LOAD_INFLACION_POR_ANIO_START:
      return loadInflacionPorAnioStart(state);
    case actionTypes.LOAD_INFLACION_POR_ANIO_SUCCESS:
      return loadInflacionPorAnioSuccess(state, action.inflaciones);
    case actionTypes.LOAD_INFLACION_POR_ANIO_FAIL:
      return loadInflacionPorAnioFail(state, action.error);

    case actionTypes.LOAD_NOVEDADES_START:
      return loadNovedadesStart(state);
    case actionTypes.LOAD_NOVEDADES_SUCCESS:
      return loadNovedadesSuccess(state, action.novedades);
    case actionTypes.LOAD_NOVEDADES_FAIL:
      return loadNovedadesFail(state, action.error);

    case actionTypes.LOAD_ESTADOS_CHEQUES_START:
      return loadEstadosChequesStart(state);
    case actionTypes.LOAD_ESTADOS_CHEQUES_SUCCESS:
      return loadEstadosChequesSuccess(state, action.estados);
    case actionTypes.LOAD_ESTADOS_CHEQUES_FAIL:
      return loadEstadosChequesFail(state, action.error);

    case actionTypes.LOAD_CHEQUES_VALOR_ESTADO_START:
      return loadChequesValorEstadoStart(state);
    case actionTypes.LOAD_CHEQUES_VALOR_ESTADO_SUCCESS:
      return loadChequesValorEstadoSuccess(state, action.chequesValorEstado);
    case actionTypes.LOAD_CHEQUES_VALOR_ESTADO_FAIL:
      return loadChequesValorEstadoFail(state, action.error);

    case actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_START:
      return loadPivotGridConfiguracionesStart(state, action.key);
    case actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_SUCCESS:
      return loadPivotGridConfiguracionesSuccess(state, action.key, action.configuraciones);
    case actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_FAIL:
      return loadPivotGridConfiguracionesFail(state, action.key, action.error);
    case actionTypes.CLEAR_PIVOT_GRID_CONFIGURACIONES:
      return clearPivotGridConfiguraciones(state, action.key);

    case actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_START:
      return loadPivotGridInformacionesStart(state, action.key);
    case actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_SUCCESS:
      return loadPivotGridInformacionesSuccess(state, action.key, action.informaciones);
    case actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_FAIL:
      return loadPivotGridInformacionesFail(state, action.key, action.error);
    case actionTypes.CLEAR_PIVOT_GRID_INFORMACIONES:
      return clearPivotGridInformaciones(state, action.key);

    case actionTypes.CLEAR_MODIFICACION_CONFIGURACION:
      return clearModificacionesConfiguracion(state);

    case actionTypes.EXECUTE_POST_CATEGORIAS_DEL_CLIENTE:
      return executePostCategoriasDeCliente(state, action.loading, action.modificado)

      case actionTypes.EXECUTE_POST_CATEGORIAS_DEL_PROVEEDOR:
        return executePostCategoriasDeProveedor(state, action.loading, action.modificado)  
    default:
      return state;
  }
};

export default reducer;
