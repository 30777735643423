import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';
import { formatNumber } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import * as actions from '../../../../store/actions/index';
import PieTableChart from '../../../Charts/PieTableChart';

const PieSaldoAcreedores = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.empresa && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }
  }, [props.ejecucionDePagina]);

  const isDisabledChart = props.estaOculto && !props.empresa;

  const gastosNegativos = () => {
    return (
      !isDisabledChart && props.valoresNegativos.map((valorNegativo) => {
        const total = props.total && props.total !== 0 ? props.total : 1;
        const porcentajeDeParticipacion = (valorNegativo.importe / total) * 100;
        return {
          nombre: valorNegativo.categoria,
          monto: valorNegativo.importe,
          porcentajeDeParticipacion,
        };
      })
    );
  }

  const formatGastos = () => {
    const gastosPos = props.deudas.map((element) => {
      const nombre = element.categoria && element.categoria.trim();
      const monto = element.importe;
      const total = props.total && props.total !== 0 ? props.total : 1;
      const porcentajeDeParticipacion = (monto / total) * 100;
      return {
        nombre,
        monto,
        porcentajeDeParticipacion,
      }
    });
    const gastosNeg = gastosNegativos();
    const result = [].concat(gastosPos).concat(gastosNeg);
    return result;
  }

  return (
    <PieTableChart
      title={`Saldo acreedor ${props.empresa ? props.empresa.nombre : ''
        } al ${fechaFinPeriodoMesActualFunc()}`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(isDisabledChart ? 0 : props.total)}`}
      labels={props.deudas.map((deuda) => deuda.categoria.trim())}
      series={props.deudas.map((deuda) => deuda.importe)}
      valoresNegativos={gastosNegativos()}
      loading={props.loading && !isDisabledChart}
      disabled={isDisabledChart}
      imgSinDatos={Math.abs(props.total) <= 0 || isDisabledChart}
      legendPosition={props.legendPosition}
      chartType={props.chartType}
      height={props.height}
      width={props.width}
      spaceBetweenChartTitle={props.spaceBetweenChartTitle}
      legendHeight={props.legendHeight}
      onFiltersClick={() => props.onShowFiltersModal()}
      minHeight={props.minHeight}
      items={formatGastos()}
      titleName='Categoría'
      titleField='nombre'
      valueName='Monto'
      valueField='monto'
      maxWidthImgSinResult={'275px'}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.compras.deudasPorCategoriaDeProveedor.loading,
    deudas: state.compras.deudasPorCategoriaDeProveedor.deudas,
    valoresNegativos: state.compras.deudasPorCategoriaDeProveedor.valoresNegativos,
    total: state.compras.deudasPorCategoriaDeProveedor.totalDeudas,
    estaCargado: state.compras.deudasPorCategoriaDeProveedor.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.deudaPorCategoriaDeProveedor
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadDeudaPorCategoriaDeProveedor(idEmpresa)),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Saldo por categoría de proveedor', ['categoriaDeProveedor']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieSaldoAcreedores);
