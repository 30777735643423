import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../shared/sessionData';

import * as actions from '../../../store/actions/index';

import LineChart from '../../Charts/LineChart';

const LineChequesEnCartera = (props) => {
  useEffect(() => {
    if (!props.empresa && props.cheques.length === 0) {
      props.onLoadChart();
    }
    if (props.empresa) {
      props.onLoadChart(props.empresa.id);
    }
  }, []);

  return (
    <LineChart
      title={`Cheques en cartera ${props.empresa ? props.empresa.nombre : ''}`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.totalCheques)}`}
      textoTooltip={'Monto'}
      series={props.cheques.map((cheque) => cheque.valor)}
      categories={props.cheques.map((cheque, index) =>
        index === 0 ? cheque.mes : `${new Date(cheque.anio, cheque.mes - 1, 1)}`
      )}
      loading={props.loading}
      imgSinDatos={props.cheques.length === 0}
      showXLabels
      height={250}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.lineChequesEnCartera.loading,
    cheques: state.ventas.lineChequesEnCartera.cheques,
    totalCheques: state.ventas.lineChequesEnCartera.totalCheques
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idEmpresa) => dispatch(actions.loadLineChequesEnCartera(idEmpresa))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineChequesEnCartera);
