// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tenacta__PanelContainer__T32s6 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.Tenacta__TabsRoot__VogxH {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PanelContainer": "Tenacta__PanelContainer__T32s6",
	"TabsRoot": "Tenacta__TabsRoot__VogxH"
};
export default ___CSS_LOADER_EXPORT___;
