import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaVentasPorSucursalDeLaSeleccion.css';
import TablaVentasDeLaSucursalPorClienteDeLaSeleccion from '../TablaVentasDeLaSucursalPorClienteDeLaSeleccion/TablaVentasDeLaSucursalPorClienteDeLaSeleccion';
import TablaVentasDeLaSucursalPorArticuloDeLaSeleccion from '../TablaVentasDeLaSucursalPorArticuloDeLaSeleccion/TablaVentasDeLaSucursalPorArticuloDeLaSeleccion';

const TablaVentasPorSucursalDeLaSeleccion = (props) => {
  const { dataSelectedId } = props;
  const dataExpanded = props.itemExpanded;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes;
  const anio = props.anio;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      onLoadKey: 'onLoadVentasPorSucursalDeLaCategoriaDeCliente'
    },
    marca: {
      title: 'Marca',
      onLoadKey: 'onLoadVentasPorSucursalDeLaMarca'
    },
    rubro: {
      title: 'Rubro',
      onLoadKey: 'onLoadVentasPorSucursalDelRubro'
    },
    subrubro: {
      title: 'Subrubro',
      onLoadKey: 'onLoadVentasPorSucursalDelSubrubro'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadVentasPorSucursalDeLaProvincia'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadVentasPorSucursalDeLaZona'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadVentasPorSucursalDelVendedor'
    }
  };

  useEffect(() => {
    setLoading(true);
    props[dataOptions[dataSelectedId].onLoadKey](
      mes,
      anio,
      props.itemExpanded.id,
      (response) => {
        setVentasLocal(response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: 'Sucursal',
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        mes: venta.mes,
        anio: venta.anio
      };
    });
  };

  const inCategoriaDeCliente = dataSelectedId === 'categoriaDeCliente';
  const inZona = dataSelectedId === 'zona';
  const inProvincia = dataSelectedId === 'provincia';

  const clientesPrimero = inCategoriaDeCliente || inZona || inProvincia;
  const tableRef = useRef();

  return (
    <div className={classes.TablaVentasPorSucursalDeLaSeleccionContainer}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        columns={columns}
        loading={loading}
        data={data()}
        detailPanel={(rowData) => {
          return clientesPrimero ? (
            <TablaVentasDeLaSucursalPorClienteDeLaSeleccion
              dataSelectedId={dataSelectedId}
              itemExpanded={dataExpanded}
              sucursalId={rowData.id}
              mes={mes}
              anio={anio}
            />
          ) : (
            <TablaVentasDeLaSucursalPorArticuloDeLaSeleccion
              dataSelectedId={dataSelectedId}
              itemExpanded={dataExpanded}
              sucursalId={rowData.id}
              mes={mes}
              anio={anio}
            />
          );
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-ventas-por-sucursal-seleccion"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorSucursalDeLaCategoriaDeCliente: (mes, anio, categoriaId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDeLaCategoriaDeCliente(mes, anio, categoriaId, success, errorFail)),

    onLoadVentasPorSucursalDeLaMarca: (mes, anio, marcaId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDeLaMarca(mes, anio, marcaId, success, errorFail)),

    onLoadVentasPorSucursalDelRubro: (mes, anio, rubroId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDelRubro(mes, anio, rubroId, success, errorFail)),

    onLoadVentasPorSucursalDelSubrubro: (mes, anio, subrubroId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDelSubrubro(mes, anio, subrubroId, success, errorFail)),

    onLoadVentasPorSucursalDeLaProvincia: (mes, anio, provinciaId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDeLaProvincia(mes, anio, provinciaId, success, errorFail)),

    onLoadVentasPorSucursalDeLaZona: (mes, anio, zonaId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDeLaZona(mes, anio, zonaId, success, errorFail)),

    onLoadVentasPorSucursalDelVendedor: (mes, anio, vendedorId, success, errorFail) =>
      dispatch(actions.loadVentasPorSucursalDelVendedor(mes, anio, vendedorId, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasPorSucursalDeLaSeleccion);
