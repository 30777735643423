import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions/index';
import classes from '../TablaPorLinea/TablaPorLinea.css';
import { formatNumber, formatNumberWithoutDecimals, formatNumberWithoutDecimalsWithMonedaSigno, isEven } from '../../../../../../shared/formatNumber';
import Table from '../../../../../UI/Table/Table';
import { storageEmpresaId, storageUsuarioId } from '../../../../../../shared/sessionData';

const TablaPorArticulo = (props) => {
  const { filtros, articulos, onLoadData, idLinea, idRubro, rowType, objetivo, pendientes } = props;
  const [datos, setDatos] = useState([]);
  const [estaCargado, setEstaCargado] = useState(false);
  const [loading, setLoading] = useState(false);
  const monto = objetivo && objetivo.monto;

  const onSuccess = (response) => {
    setDatos(response.datos);
    setEstaCargado(true);
    setLoading(false);
  }

  const onFail = (error) => {
    console.log(error);
    setDatos([]);
    setEstaCargado(true);
    setLoading(false);
  }

  useEffect(() => {
    if (!estaCargado && objetivo && articulos && articulos.length > 0) {
      let datosTemp = {};
      datosTemp[objetivo.key] = [{ items: articulos }];
      setDatos(datosTemp);
      setEstaCargado(true);
    }
  }, [estaCargado, objetivo, articulos]);

  useEffect(() => {
    if (!estaCargado && !pendientes && (!articulos || articulos.length <= 0) && !loading) {
      const request = {
        anioFiscal: filtros.anioFiscal,
        idArchivo: filtros.idArchivo,
        mesFiscal: filtros.mesFiscal,
        acumulado: filtros.acumulado,
        idEmpresa: storageEmpresaId(),
        idUsuario: storageUsuarioId(),
        desde: filtros.desde,
        hasta: filtros.hasta,
        idLinea,
        idRubro,
        rowType
      };

      setLoading(true);
      onLoadData(request, onSuccess, onFail);
    }
  }, [estaCargado, loading, articulos, filtros, pendientes]);

  const headersStyle = {
    fontSize: 'smaller',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: 'rgb(205, 205, 205)'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '11px',
      paddingBottom: '2px',
      paddingTop: '2px',
      paddingLeft: '5px',
      paddingRight: '5px',
      color: value < 0 && 'red',
      width
    };
  };

  const renderRow = (rowData, field) => {
    if (!rowData || !rowData.articulo)
      return '';

    if (rowData[field] === undefined || rowData[field] === null)
      return `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(0) : formatNumberWithoutDecimals(0)}`;

    let articulo = rowData.articulo.replace(/\s+/g, '').toLowerCase();
    return articulo === 'cumpl.volumen' || articulo === 'cumpl.canal' || articulo === 'mixcanal' || articulo === 'base'
      ? `${formatNumber(rowData[field] * 100)}%`
      : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData[field]) : formatNumberWithoutDecimals(rowData[field])}`;
  }

  const renderTotalColumn = (rowData) => {
    if (!rowData.articulo)
      return '';

    let articulo = rowData.articulo.replace(/\s+/g, '').toLowerCase();
    return articulo === 'cumpl.volumen' || articulo === 'mixcanal' || articulo === 'base'
      ? `${formatNumber(rowData.total * 100)}%`
      : articulo === 'cumpl.canal' ? '' : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData.total) : formatNumberWithoutDecimals(rowData.total)}`;
  }

  const columns = [
    {
      title: '',
      field: 'idNombreArticulo',
      align: 'left',
      cellStyle: {
        fontSize: '10px',
        paddingBottom: '2px',
        paddingTop: '2px',
        paddingLeft: '5px',
        paddingRight: '5px',
        textTransform: 'uppercase',
        width: '23%'
      },
      headerStyle: headersStyle,
      width: '23%'
    },
    {
      title: '',
      field: 'total',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => renderTotalColumn(rowData),
      width: '5%'
    },
    {
      title: '',
      field: 'mix',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => objetivo.key === 'precioUnidad' || (rowType === 2 && idRubro === "0") || (rowData.articulo && (rowData.articulo === 'Mix canal' || rowData.articulo === 'Base' ||rowData.articulo.includes('Cumpl') || rowData.articulo.includes('Pendientes')))
        ? '' 
        : `${formatNumber(rowData.mix * 100)}%`,
      width: '5%'
    },
    {
      title: '',
      field: 'cumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => (rowType === 2 && idRubro === "0") || (rowData.articulo && (rowData.articulo === 'Mix canal' || rowData.articulo === 'Base' || rowData.articulo.includes('Cumpl') || rowData.articulo.includes('Pendientes') || rowData.rowType === 1))
        ? '' 
        : `${formatNumber(rowData.cumplimiento * 100)}%`,
      width: '5%'
    },
    {
      title: '',
      field: 'supermercados',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'supermercados'),
      width: '5%'
    },
    {
      title: '',
      field: 'grandesRetails',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '7%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'grandesRetails'),
      width: '7%'
    },
    {
      title: '',
      field: 'medianosRetails',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'medianosRetails'),
      width: '8%'
    },
    {
      title: '',
      field: 'ecommerce',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'ecommerce'),
      width: '8%'
    },
    {
      title: '',
      field: 'webPropia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'webPropia'),
      width: '8%'
    },
    {
      title: '',
      field: 'pequeniosRetails',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'pequeniosRetails'),
      width: '8%'
    },
    {
      title: '',
      field: 'droguerias',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '7%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'droguerias'),
      width: '7%'
    },
    {
      title: '',
      field: 'farmacias',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '6%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'farmacias'),
      width: '6%'
    },
    {
      title: '',
      field: 'ortopedias',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '6%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'ortopedias'),
      width: '6%'
    }
  ];

  const data = () => {
    if (!datos || !objetivo || !datos[objetivo.key] || datos[objetivo.key].length <= 0)
      return [];

    const datoObjetivo = datos[objetivo.key][0].items;
    return datoObjetivo && datoObjetivo.map((venta) => {
      return { ...venta };
    });
  };

  const tableRef = useRef();

  return (
    <div className={classes.tablaVentasPorRubro}>
      <Table
        tableRef={tableRef}
        style={{
          marginTop: 0,
          marginBottom: '10px',
          border: "0px solid #ccc",
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "white",
          borderRadius: "3px"
        }}
        title=""
        columns={columns}
        loading={loading}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 500,
          rowStyle: (rowData, index) => ({
            backgroundColor: rowData.rowType === 0
              ? !isEven(index) ? '#FBE4B5' : '#FFDD98'
              : rowData.rowType === 1
                ? !isEven(index) ? '#E4D8FA' : '#DBCAFE'
                : !isEven(index) ? '#8FE1A7' : '#8FE1A7',
            fontWeight: rowData.calculo && 'bold',
            fontSize: rowData.rowType === 2 ? '12px' : '11px'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filtros: state.tenacta.informeDosFiltros,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (request, success, errorFail) =>
      dispatch(actions.loadInformeDosUnidadesPorRubro(request, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaPorArticulo);