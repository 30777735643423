// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaMultiempresa__SmallChartCard__pMYdc {\r\n  border: 0px solid #ccc;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  box-sizing: border-box;\r\n  text-align: center;\r\n  background-color: white;\r\n  border-radius: 3px;\r\n  height: 100%;\r\n}\r\n\r\n.TablaMultiempresa__TablaMultiempresaContainer__B0a4A {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  background-color: white;\r\n  align-items: flex-end;\r\n  margin-bottom: 5px;\r\n  margin-right: 20px; \r\n}\r\n\r\n.TablaMultiempresa__TooltipPopper__BoFlS {\r\n  z-index: 1500 !important;\r\n}\r\n\r\n.TablaMultiempresa__TitleCard__XO7RU {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SmallChartCard": "TablaMultiempresa__SmallChartCard__pMYdc",
	"TablaMultiempresaContainer": "TablaMultiempresa__TablaMultiempresaContainer__B0a4A",
	"TooltipPopper": "TablaMultiempresa__TooltipPopper__BoFlS",
	"TitleCard": "TablaMultiempresa__TitleCard__XO7RU"
};
export default ___CSS_LOADER_EXPORT___;
