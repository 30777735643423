import React, { useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classes from './PieTableGastosPorCategoria.css';
import * as actions from '../../../../store/actions/index';
import { formatNumber, isEven } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';
import TablaGastosPorItemDeCateogoria from '../TablaGastosPorItemDeCategoria/TablaGastosPorItemDeCateogoria';
import TablaGastosPorCategoriaToExcel from './TablaGastosPorCategoriaToExcel';
import Table from '../../../UI/Table/Table';

const TablaGastosPorCategoria = (props) => {
  const { gastos, fecha, loading, totalGastos } = props;

  const columns = [
    {
      title: 'Categoría',
      field: 'categoria',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      }
    },
    {
      title: `Porc. participación`,
      field: 'porcParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
    },
    {
      title: `Monto`,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return gastos.map((gasto) => ({
      categoriaItemId: gasto.id_CategoriaItem,
      categoria: gasto.categoria,
      porcParticipacion: gasto.porcentajeDeParticipacion,
      monto: gasto.monto
    }));
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  return (
    <div className={classes.TablaGastosMensualesPorCatContainer}>
      <div className={classes.TitleCard}>
        <p style={{ fontWeight: 'bold', marginLeft: '20px', marginRight: '20px', marginBottom: '1px' }}>
          {`Gastos detallados por categoría ${
            gastos.length > 0 && !loading ? `del ${moment(fecha).format('MM/yyyy')}` : ''
          }`}
        </p>
        <div>
          <TablaGastosPorCategoriaToExcel gastos={data()} fecha={loading ? '' : moment(fecha).format('MM/yyyy')} />
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <p
        style={{ marginTop: '1px', marginLeft: '20px', marginBottom: '2px', fontSize: '12px' }}
      >{`Total: ${storageMonedaSigno()} ${formatNumber(totalGastos)}`}</p>
      <Table
        tableRef={tableRef}
        title=""
        loading={props.loading}
        columns={columns}
        data={data()}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return (
            <TablaGastosPorItemDeCateogoria
              categoriaItem={rowData}
              endRequest={() => autoScroll(rowData)}
              fechaBusqueda={fecha}
              child
            />
          );
        }}
        noBoxShadow
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 400,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
        tableKey={"tabla-gastos-mensuales-por-categoria"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadPieGastosPorCategoria()),
    onLoadGastosDeCat: (idCategoria, nombreCategoria, mes, anio, success) =>
      dispatch(
        actions.loadGastosPorItemDeCategoriaSelec(idCategoria, nombreCategoria, mes, anio, undefined, true, success)
      ),
    onUnmountComponent: () => dispatch(actions.unmountGastosPorItemDeCategoriaSelec()),
    onShowFiltersModal: () =>
      dispatch(
        actions.showListFiltersModal(true, 'Gastos detallados por categoría', ['gasto', 'categoriaItemsDeCompra'])
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaGastosPorCategoria);
