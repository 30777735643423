import React, { useState } from 'react';
import ExportToExcelIcon from '../../../../../UI/Icons/ExportToExcelIcon';
import { getLineaDataSet } from '../InformeTresHelpers';

const TablasInformeTresToExcel = (props) => {
  const { data, name, filename, disabled, tooltipTitle } = props;

  const [lineas] = useState(['ASPEN', 'IMETEC', 'ORTOPEDIA', 'TENACTA']);

  const getDataSet = () => {
    let dataSet = [];

    lineas.forEach((linea, index) => {
      const lineaDataSet = getLineaDataSet(linea, data);

      dataSet.push({
        ySteps: index !== 0 ? 3 : 0,
        columns: lineaDataSet.columns,
        data: lineaDataSet.data
      })
    });

    return dataSet;
  }

  return (
    <ExportToExcelIcon
      filename={filename}
      name={name}
      dataSet={getDataSet()}
      disabled={disabled}
      tooltipTitle={tooltipTitle}
    />
  )
}

export default TablasInformeTresToExcel;