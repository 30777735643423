import React from 'react';
import { connect } from 'react-redux';
import BoxEjecutarPaginaInfo from '../../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

import classes from '../Tenacta.css';
import { storageEsMultiempresa } from '../../../shared/sessionData';
import EmpresaSelector from '../../../components/UI/SelectorEmpresa/EmpresaSelector';
import InformesTabs from '../../../components/UI/Informes/InformesTabs/InformesTabs';
import TablasInformeTres from '../../../components/Indicadores/Personalizados/Tenacta/InformeTres/TablasInformeTres/TablasInformeTres';
import ImportacionInformeTres from './ImportacionInformeTres';

const InformeTres = (props) => {
  sessionStorage.setItem('seccionNombre', 'Informe 3');
  const isMultiemp = storageEsMultiempresa();

  return (
    <div className={classes.PanelContainer}>
      {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {!props.ejecucionDePagina ?
        (<BoxEjecutarPaginaInfo />) :
        (<div>
          <InformesTabs
            tabs={['Budget/Forecast', 'Importar Budget/Forecast']}
            views={[
              <TablasInformeTres
                ejecucionDePagina={props.ejecucionDePagina}
                containerWidth={window.innerWidth < 1350 ? "92.5vw" : window.innerWidth < 1900 ? "93.5vw" : "100%"}
                minHeight={450}
              />,
              <ImportacionInformeTres ejecucionDePagina={props.ejecucionDePagina} />
            ]}
            rootClass={classes.TabsRoot}
          />
        </div>)}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ejecucionDePagina: state.ejecucionPaginas.ejecucionInformeTresTenacta
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InformeTres);