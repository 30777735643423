// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaComparacionVentasDeLaSeleccion__SorteableHeaderTitle__A2G\\+F:hover {\r\n  color: rgba(0, 0, 0, 0.54);\r\n  cursor: pointer;\r\n}\r\n\r\n.TablaComparacionVentasDeLaSeleccion__TablasComparacionComprobantesContainer__HJBhD {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.TablaComparacionVentasDeLaSeleccion__TablaVentasDesagregadasMontoUnidad__p82JW {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n  height: 100%;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SorteableHeaderTitle": "TablaComparacionVentasDeLaSeleccion__SorteableHeaderTitle__A2G+F",
	"TablasComparacionComprobantesContainer": "TablaComparacionVentasDeLaSeleccion__TablasComparacionComprobantesContainer__HJBhD",
	"TablaVentasDesagregadasMontoUnidad": "TablaComparacionVentasDeLaSeleccion__TablaVentasDesagregadasMontoUnidad__p82JW"
};
export default ___CSS_LOADER_EXPORT___;
