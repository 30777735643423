import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../../../shared/formatNumber';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import * as actions from '../../../../store/actions/index';

import PieChart from '../../../Charts/PieChart';
import { storageFechaFija, storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './PieVentasPorSeleccion.css';

const PieVentasPorSeleccion = (props) => {
  const { dataSelectedId,forcedDataOption } = props;
  const isDisabledChart = props.estaOculto;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      stateKey: 'ventasPorCategoriaDeCliente',
      onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
    },
    marca: {
      title: 'Marca',
      stateKey: 'ventasPorMarca',
      onLoadKey: 'onLoadVentasPorMarca'
    },
    rubro: {
      title: 'Rubro',
      stateKey: 'ventasPorRubro',
      onLoadKey: 'onLoadVentasPorRubro'
    },
    subrubro: {
      title: 'Subrubro',
      stateKey: 'ventasPorSubrubro',
      onLoadKey: 'onLoadVentasPorSubrubro'
    },
    vendedor: {
      title: 'Vendedor',
      stateKey: 'ventasPorVendedor',
      onLoadKey: 'onLoadVentasPorVendedor'
    },
    provincia: {
      title: 'Provincia',
      stateKey: 'ventasPorProvincia',
      onLoadKey: 'onLoadVentasPorProvincia'
    },
    zona: {
      title: 'Zona',
      stateKey: 'ventasPorZona',
      onLoadKey: 'onLoadVentasPorZona'
    }
  };

  const dataSelectOptions = [
    {
      id: 'categoriaDeCliente',
      title: 'Categoría de cliente'
    },
    {
      id: 'marca',
      title: 'Marca'
    },
    {
      id: 'rubro',
      title: 'Rubro'
    },
    {
      id: 'subrubro',
      title: 'Subrubro'
    },
    {
      id: 'vendedor',
      title: 'Vendedor'
    },
    {
      id: 'provincia',
      title: 'Provincia'
    },
    {
      id: 'zona',
      title: 'Zona'
    }
  ];

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    props.onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const fechaDeDatos = props[dataOptions[dataSelectedId].stateKey].fecha;
    const loading = props[dataOptions[dataSelectedId].stateKey].loading;

    return (
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 400, marginRight: '15px' }}>
        <div className={classes.LabelVerPor}>
          Ver ventas del{' '}
          {loading ? '--/----' : moment(fechaDeDatos ? fechaDeDatos : storageFechaFija()).format('MM/YYYY')} por:{' '}
        </div>
        { forcedDataOption ? 
          <span style={{ marginLeft: '5px' }}>{dataOptions[dataSelectedId].title}</span> :
          <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: '5px' }}
          value={props.dataSelectedId}
          onChange={(e) => handleChangeOptionSelected(e)}
          >
          { dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id}>
                {option.title}
              </MenuItem>
            );
          })}
          </Select>
        }
      </div>
    );
  };

  const gastosNegativos = () => {
    return (
      !isDisabledChart &&
      props[dataOptions[dataSelectedId].stateKey].ventasNegativo.map((ventaNegativo) => ({
        nombre: ventaNegativo[dataSelectedId === 'categoriaDeCliente' ? 'categoria' : 'nombre'],
        monto: ventaNegativo.monto
      }))
    );
  };

  useEffect(() => {
    if(forcedDataOption !== undefined)
      props.onChangeOptionSelected(forcedDataOption)
  }, [forcedDataOption])
  
  return (
    <PieChart
      title={optionSelector()}
      disabledCss
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(props[dataOptions[dataSelectedId].stateKey].total)}`}
      series={props[dataOptions[dataSelectedId].stateKey].ventas.map((venta) => venta.monto)}
      labels={props[dataOptions[dataSelectedId].stateKey].ventas.map(
        (venta) => venta[dataSelectedId === 'categoriaDeCliente' ? 'categoria' : 'nombre']
      )}
      valoresNegativos={gastosNegativos()}
      loading={props[dataOptions[dataSelectedId].stateKey].loading}
      imgSinDatos={props[dataOptions[dataSelectedId].stateKey].ventas.length === 0}
      height={props.height}
      disabled={isDisabledChart}
      width={props.width}
      chartType={props.chartType}
      spaceBetweenChartTitle={props.spaceBetweenChartTitle}
      legendHeight={props.legendHeight}
      legendPosition={props.legendPosition}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    dataSelectedId: state.ventas.dataVentasDelMesPorSelected,
    fechaVentasDelMes: state.ventas.fechaVentasDelMes,
    ventasPorCategoriaDeCliente: state.ventas.ventasPorCategoriaDeCliente,
    ventasPorMarca: state.ventas.ventasDelMesPorMarcas,
    ventasPorProvincia: state.ventas.ventasDelMesPorProvincias,
    ventasPorRubro: state.ventas.ventasDelMesPorRubros,
    ventasPorSubrubro: state.ventas.ventasDelMesPorSubrubros,
    ventasPorVendedor: state.ventas.ventasDelMesPorVendedores,
    ventasPorZona: state.ventas.ventasDelMesPorZonas
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => dispatch(actions.seleccionDataVentasDelMesPor(selecionId)),

    onLoadVentasPorCategoriasDeCliente: (mes, anio) => dispatch(actions.loadVentasPorCategoriaDeCliente(mes, anio)),
    onLoadVentasPorMarca: (mes, anio) => dispatch(actions.loadVentasDelMesPorMarca(undefined, mes, anio)),
    onLoadVentasPorRubro: (mes, anio) => dispatch(actions.loadVentasDelMesPorRubro(undefined, mes, anio)),
    onLoadVentasPorSubrubro: (mes, anio) => dispatch(actions.loadVentasDelMesPorSubrubro(undefined, mes, anio)),
    onLoadVentasPorVendedor: (mes, anio) => dispatch(actions.loadVentasDelMesPorVendedor(mes, anio)),
    onLoadVentasPorProvincia: (mes, anio) => dispatch(actions.loadVentasDelMesPorProvincia(mes, anio)),
    onLoadVentasPorZona: (mes, anio) => dispatch(actions.loadVentasDelMesPorZona(undefined, mes, anio))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieVentasPorSeleccion);
