import React from 'react';
import { connect } from 'react-redux';
import TableUtilidadesBase from './TableUtilidadesBase';

const TableUtilidadesPorMarca = (props) => {
  return (
    <TableUtilidadesBase
      titleUtilidadPor="Marca"
      fieldUtilidadPor="marca"
      utilidades={props.utilidades}
      loading={props.loading}
      total={props.total}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    utilidades: state.ventas.utilidadPorMarca.utilidades,
    total: state.ventas.utilidadPorMarca.total,
    loading: state.ventas.utilidadPorMarca.loading,
    error: state.ventas.utilidadPorMarca.error,
    estaCargado: state.ventas.utilidadPorMarca.estaCargado
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableUtilidadesPorMarca);
