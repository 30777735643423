import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../shared/formatNumber';
import * as actions from '../../../../store/actions/index';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { fechaFinPeriodoMesActualFunc } from '../../../../shared/fechas';
import PieTableChart from '../../../Charts/PieTableChart';

const PieDeudaPorCategoriaDeCliente = (props) => {
  useEffect(() => {
    if (!props.estaCargado && !props.estaOculto && props.ejecucionDePagina) {
      props.onLoadChart();
    }
  }, [props.ejecucionDePagina]);

  const isDisabledChart = props.estaOculto && !props.empresa;

  const titles = () => {
    return `Saldo deudores al ${fechaFinPeriodoMesActualFunc()}`;
  };

  const subtitles = () => {
    return `Total: ${storageMonedaSigno()} ${formatNumber(props.total)}`;
  };

  const series = () => {
    return props.categorias.map((categoria) => Math.abs(categoria.importe));
  };

  const labels = () => {
    return props.categorias.map((categoria) => categoria.categoria.trim());
  };

  const gastosNegativos = () => {
    return (
      !isDisabledChart && props.valoresNegativos.map((valorNegativo) => {
        const total = props.total && props.total !== 0 ? props.total : 1;
        const porcentajeDeParticipacion = (valorNegativo.importe / total) * 100;
        return {
          nombre: valorNegativo.categoria,
          monto: valorNegativo.importe,
          porcentajeDeParticipacion,
        };
      })
    );
  };

  const formatGastos = () => {
    const gastosPos = props.categorias.map((element) => {
      const nombre = element.categoria && element.categoria.trim();
      const monto = element.importe;
      const total = props.total && props.total !== 0 ? props.total : 1;
      const porcentajeDeParticipacion = (monto / total) * 100;
      return {
        nombre,
        monto,
        porcentajeDeParticipacion,
      }
    });
    const gastosNeg = gastosNegativos();
    const result = [].concat(gastosPos).concat(gastosNeg);
    return result;
  }

  return (
    <PieTableChart
      ids={props.categorias.map((categoria) => categoria.categoriaId)}
      title={titles()}
      subTitle={subtitles()}
      series={series()}
      labels={labels()}
      valoresNegativos={gastosNegativos()}
      loading={props.loading}
      disabled={props.estaOculto}
      imgSinDatos={props.total <= 0}
      legendPosition={props.legendPosition}
      chartType={props.chartType}
      height={props.height}
      width={props.width}
      spaceBetweenChartTitle={props.spaceBetweenChartTitle}
      legendHeight={props.legendHeight}
      onFiltersClick={() => props.onShowFiltersModal()}
      minHeight={props.minHeight}
      items={formatGastos()}
      titleName='Categoría'
      titleField='nombre'
      valueName='Monto'
      valueField='monto'
      maxWidthImgSinResult={'275px'}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    categorias: state.ventas.deudaPorCategoriaDeCliente.categorias,
    valoresNegativos: state.ventas.deudaPorCategoriaDeCliente.valoresNegativos,
    total: state.ventas.deudaPorCategoriaDeCliente.total,
    loading: state.ventas.deudaPorCategoriaDeCliente.loading,
    error: state.ventas.deudaPorCategoriaDeCliente.error,
    estaCargado: state.ventas.deudaPorCategoriaDeCliente.estaCargado,
    estaOculto: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.deudaPorCategoriaDeCliente
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadDeudaPorCategoriaDeCliente()),
    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Saldo por categoría de cliente', ['categoriaDeCliente']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieDeudaPorCategoriaDeCliente);