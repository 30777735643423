import React from 'react';
import { connect } from 'react-redux';

import classes from './BarVentasDeVendedoresPorSeleccion.css';

import { formatNumber } from '../../../../shared/formatNumber';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import Table from '../../../UI/Table/Table';
import BarVentasDeVendedoresPorSeleccionInTableToExcel from './BarVentasDeVendedoresPorSeleccionInTableToExcel';
import { Tooltip } from '@material-ui/core';

const TablaVentasDeVendedoresPorSeleccion = (props) => {
  const { ventasPor, datas, categories, fecha } = props;

  const columns = () => {
    const formatColumns = [
      {
        title: ventasPor,
        field: 'name',
        align: 'left',
        cellStyle: {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px'
        },
        headerStyle: {
          fontSize: '14px',
          paddingTop: '0px',
          paddingBottom: '0px',
          backgroundColor: '#fafafa'
        }
      }
    ];

    datas.map((data) => {
      return formatColumns.push({
        title: data.name,
        field: data.name,
        align: 'right',
        type: 'numeric',
        cellStyle: (cellData) => {
          return {
            fontSize: '12px',
            paddingBottom: '5px',
            paddingTop: '5px',
            color: cellData < 0 && 'red'
          };
        },
        headerStyle: {
          fontSize: '14px',
          paddingTop: '0px',
          paddingBottom: '0px',
          backgroundColor: '#fafafa'
        },
        render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData[data.name] ? rowData[data.name] : 0)}`
      });
    });

    return formatColumns;
  };

  const data = () => {
    const formatData = [];
    categories.map((categoria, categoriaIndex) => {
      let formatCategoria = {
        name: categoria
      };
      datas.map((vendedorMontos) => {
        return (formatCategoria = { ...formatCategoria, [vendedorMontos.name]: vendedorMontos.data[categoriaIndex] });
      });
      return formatData.push(formatCategoria);
    });

    return formatData;
  };

  return (
    <div className={classes.TablaVentasDeVendedoresPorSeleccionContainer}>
      <div className={classes.TitleCard}>
        <Tooltip title={'Exportar a excel'}>
          <BarVentasDeVendedoresPorSeleccionInTableToExcel
            datas={data()}
            columns={columns()}
            ventasPor={ventasPor}
            fecha={fecha}
          />
        </Tooltip>
      </div>
      <Table
        title=""
        columns={columns()}
        data={data()}
        noBoxShadow
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          fixedColumns: true,
          layout: 'fixed',
          showTitle: false,
          maxBodyHeight: 600,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red'
          })
        }}
        tableKey={"tabla-bar-ventas-vendedores-seleccion-table"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDeVendedoresPorSeleccion);
