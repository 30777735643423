// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportadorBudgetForecastDos__TitleContainer__cersO {\r\n  margin-block: 15px !important;\r\n  width: 100%;\r\n  text-align: left !important;\r\n}\r\n\r\n.ImportadorBudgetForecastDos__Title__gGj83 {\r\n  font-weight: bold !important;\r\n  font-size: medium !important;\r\n}\r\n\r\n.ImportadorBudgetForecastDos__Subtitle__\\+DAG1 {\r\n  font-size: small !important;\r\n}\r\n\r\n.ImportadorBudgetForecastDos__ConfigIcon__EIqco {\r\n  margin: 15px !important;\r\n  text-align: end !important;\r\n}\r\n\r\n.ImportadorBudgetForecastDos__TabsContainer__Qa4kU {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}\r\n\r\n.ImportadorBudgetForecastDos__TabsScroller__2BOcr {\r\n  display: flex !important;\r\n  justify-content: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.ImportadorBudgetForecastDos__TabsIndicator__tzFRf {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleContainer": "ImportadorBudgetForecastDos__TitleContainer__cersO",
	"Title": "ImportadorBudgetForecastDos__Title__gGj83",
	"Subtitle": "ImportadorBudgetForecastDos__Subtitle__+DAG1",
	"ConfigIcon": "ImportadorBudgetForecastDos__ConfigIcon__EIqco",
	"TabsContainer": "ImportadorBudgetForecastDos__TabsContainer__Qa4kU",
	"TabsScroller": "ImportadorBudgetForecastDos__TabsScroller__2BOcr",
	"TabsIndicator": "ImportadorBudgetForecastDos__TabsIndicator__tzFRf"
};
export default ___CSS_LOADER_EXPORT___;
