import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';
import axios from 'axios';
import moment from 'moment';
import { storageClienteId, storageEmpresaId, storageToken } from '../../shared/sessionData';
import idIndicadores from '../../shared/idIndicadores';
import { baseUrlDashboard } from '../../shared/urlApps';

export const loadIndicadores = (token = storageToken()) => {
  return (dispatch) => {
    dispatch(loadIndicadoresStart());
    const authStr = 'Bearer '.concat(token);
    axios
      .get(
        `${baseUrlDashboard()}configuraciones/IndicadoresPorUsuario?indicadorId=${idIndicadores.indicadoresPorUsuario}`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(loadIndicadoresSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadIndicadoresFail(error));
      });
  };
};

const loadIndicadoresStart = () => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_INDICADORES_START
  };
};

const loadIndicadoresSuccess = (indicadores) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_INDICADORES_SUCCESS,
    indicadores
  };
};

const loadIndicadoresFail = (error) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_INDICADORES_FAIL,
    error
  };
};

export const loadIndicadoresOcultos = (token, success) => {
  return (dispatch) => {
    dispatch(loadIndicadoresOcultosStart());
    const authStr = 'Bearer '.concat(token);
    axios
      .get(
        `${baseUrlDashboard()}configuraciones/IndicadoresOcultosPorUsuario?indicadorId=${
          idIndicadores.indicadoresOcultosPorUsuario
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const indicadoresOcultos = {
          ventasDelMes: response.data.includes(idIndicadores.ventasDelMes),
          ventasDelDia: response.data.includes(idIndicadores.ventasDelDia),
          ivaFiscal: response.data.includes(idIndicadores.ivaFiscalDelMes),
          cobranzasDelMes: response.data.includes(idIndicadores.cobranzasDelMes),
          gastosPorCategoria: response.data.includes(idIndicadores.gastosPorCategoria),
          saldoDeudores: response.data.includes(idIndicadores.saldoDeudores),
          saldoAcreedores: response.data.includes(idIndicadores.saldoAcreedores),
          plazoMedioVencACobrar: response.data.includes(idIndicadores.plazoMedioVencACobrar),
          plazoMedioVencAPagar: response.data.includes(idIndicadores.plazoMedioVencAPagar),
          promDiasDeCobranzas: response.data.includes(idIndicadores.promDiasDeCobranzas),
          promDiasDePagos: response.data.includes(idIndicadores.promDiasDePagos),
          chequesEnCartera: response.data.includes(idIndicadores.chequesEnCartera),
          chequesRechazados: response.data.includes(idIndicadores.chequesRechazados),
          chequesEmitidos: response.data.includes(idIndicadores.chequesEmitidos),
          gastosDelMes: response.data.includes(idIndicadores.gastosDelMes),
          pagosDelMes: response.data.includes(idIndicadores.pagosDelMes),
          ivaFiscalMultiempresa: response.data.includes(idIndicadores.ivaFiscalMultiempresa),
          chequesEnCarteraMultiempresa: response.data.includes(idIndicadores.chequesEnCarteraMultiempresa),
          chequesEmitidosPendientesMultiempresa: response.data.includes(
            idIndicadores.chequesEmitidosPendientesMultiempresa
          ),
          cobranzasMensualesMultiempresa: response.data.includes(idIndicadores.cobranzasMensualesMultiempresa),
          gastosDelMesMultiempresa: response.data.includes(idIndicadores.gastosDelMesMultiempresa),
          pagosDelMesMultiempresa: response.data.includes(idIndicadores.pagosDelMesMultiempresa),
          saldoAcreedoresMultiempresa: response.data.includes(idIndicadores.saldoAcreedoresMultiempresa),
          saldoDeudoresMultiempresa: response.data.includes(idIndicadores.saldoDeudoresMultiempresa),
          ventasDiariasMultiempresa: response.data.includes(idIndicadores.ventasDiariasMultiempresa),
          ventasMensualesMultiempresa: response.data.includes(idIndicadores.ventasMensualesMultiempresa),
          rentabilidadMultiempresa: response.data.includes(idIndicadores.rentabilidadMultiempresa),
          deudaPorCategoriaDeCliente: response.data.includes(idIndicadores.deudaPorCategoriaDeCliente),
          deudaPorCategoriaDeProveedor: response.data.includes(idIndicadores.deudaPorCategoriaDeProveedor)
        };

        dispatch(loadIndicadoresOcultosSuccess(indicadoresOcultos));
        return success();
      })
      .catch((error) => {
        dispatch(loadIndicadoresOcultosFail(error));
      });
  };
};

const loadIndicadoresOcultosStart = () => {
  return {
    type: actionTypes.LOAD_INDICADORES_OCULTOS_START
  };
};

const loadIndicadoresOcultosSuccess = (indicadores) => {
  return {
    type: actionTypes.LOAD_INDICADORES_OCULTOS_SUCCESS,
    indicadores
  };
};

const loadIndicadoresOcultosFail = (error) => {
  return {
    type: actionTypes.LOAD_INDICADORES_OCULTOS_FAIL,
    error
  };
};

export const postIndicadoresOcultos = (indicadores) => {
  return (dispatch) => {
    dispatch(executePostIndicadoresOcultos(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}configuraciones/IndicadoresPorUsuario`, indicadores, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        window.location.reload();
        dispatch(executePostIndicadoresOcultos(false));
      })
      .catch((error) => {
        dispatch(executePostIndicadoresOcultos(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostIndicadoresOcultos = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_INDICADORES_OCULTOS,
    loading
  };
};

export const loadCategoriasItemsDeCompras = (empresaId) => {
  return (dispatch) => {
    dispatch(loadCategoriasItemsDeComprasStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `${baseUrlDashboard()}configuraciones/CategoriasItemsDeCompras?empresaId=${empresaId}&indicadorId=${
          idIndicadores.categoriaDeItems
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(loadCategoriasItemsDeComprasSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadCategoriasItemsDeComprasFail(error));
      });
  };
};

const loadCategoriasItemsDeComprasStart = () => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START
  };
};

const loadCategoriasItemsDeComprasSuccess = (categoriasItemsDeCompras) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS,
    categoriasItemsDeCompras
  };
};

const loadCategoriasItemsDeComprasFail = (error) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL,
    error
  };
};

export const postCategoriasItemsCompra = (empresaId, categItems) => {
  return (dispatch) => {
    dispatch(executePostCategoriaItemsCompra(true, false));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}configuraciones/CategoriasItemsDeCompras?idEmpresa=${empresaId}`, categItems, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Categorías actualizadas correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        )
        dispatch(executePostCategoriaItemsCompra(false, true));
      })
      .catch((error) => {
        dispatch(executePostCategoriaItemsCompra(false, false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostCategoriaItemsCompra = (loading, modificado) => {
  return {
    type: actionTypes.EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA,
    loading,
    modificado
  };
};

export const loadTiemposRequest = (clienteId = storageClienteId()) => {
  return (dispatch) => {
    dispatch(loadTiemposRequestStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}configuraciones/CabecerasTiemposIndicadores?clienteId=${clienteId}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        let indicadores = [];
        let tiempos = [];
        response.data.map((request) => {
          return indicadores.push({
            id: request.id,
            nombre: request.nombre,
            ultimoTiempoService: request.tiemposRequest.length === 0 ? 0 : request.tiemposRequest[0].tiempoService,
            ultimaFecha: request.tiemposRequest.length === 0 ? '-' : request.tiemposRequest[0].fecha
          });
        });
        dispatch(loadTiemposRequestSuccess(indicadores, tiempos));
      })
      .catch((error) => {
        dispatch(loadTiemposRequestFail(error));
      });
  };
};

export const clearTiemposReq = () => {
  return {
    type: actionTypes.CLEAR_TIEMPOS_REQUEST
  };
};

const loadTiemposRequestStart = () => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_START
  };
};

const loadTiemposRequestSuccess = (indicadores, tiempos) => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_SUCCESS,
    indicadores,
    tiempos
  };
};

const loadTiemposRequestFail = (error) => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_FAIL,
    error
  };
};

export const loadDetalleTiemposRequest = (indicadorId, clienteId = storageClienteId(), success, fail) => {
  const authStr = 'Bearer '.concat(storageToken());
  axios
    .get(
      `${baseUrlDashboard()}configuraciones/DetalleTiemposIndicador?clienteId=${clienteId}&indicadorId=${indicadorId}`,
      {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      }
    )
    .then((response) => {
      return success(response.data);
    })
    .catch((error) => {
      return fail(error);
    });
};

export const actualizarIndicadores = (page) => {
  return {
    type: actionTypes.ACTUALIZAR_INDICADORES,
    page
  };
};

export const cambiarFechaFija = (fechaFija) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .put(`${baseUrlDashboard()}configuraciones/cambiarFechaFija?clienteId=${storageClienteId()}`, fechaFija, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        sessionStorage.setItem('fechaFija', moment(response.data).format('MM-DD-YYYY'));
        window.location.reload();
      })
      .catch((error) => {});
  };
};

export const loadCategoriasDeProveedor = (empresaId) => {
  return (dispatch) => {
    dispatch(loadCategoriasDeProveedorStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `${baseUrlDashboard()}configuraciones/CategoriasDeProveedores?empresaId=${empresaId}&indicadorId=${
          idIndicadores.categoriasDeProveedor
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(loadCategoriasDeProveedorSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadCategoriasDeProveedorFail(error));
      });
  };
};

const loadCategoriasDeProveedorStart = () => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_START
  };
};

const loadCategoriasDeProveedorSuccess = (categorias) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS,
    categorias
  };
};

const loadCategoriasDeProveedorFail = (error) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL,
    error
  };
};

export const postCategoriasDeProveedor = (empresaId, categItems) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}configuraciones/CategoriasDeProveedores?idEmpresa=${empresaId}`, categItems, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Categorías actualizadas correctamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          }))
        dispatch(
          executePostCategoriasDelProveedor(false, true))
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostCategoriasDelProveedor = (loading, modificado) => {
  return {
    type: actionTypes.EXECUTE_POST_CATEGORIAS_DEL_CLIENTE,
    loading,
    modificado
  };
};

export const CambiarAjustaPorInflacion = (ajustaInflacion) => {
  return (dispatch) => {
    dispatch(executeCambiarAjustaPorInflacion(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .put(
        `${baseUrlDashboard()}configuraciones/AjustaPorInflacion?calculoConInflacion=${ajustaInflacion}`,
        {},
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        sessionStorage.setItem('ajustaInflacion', ajustaInflacion);
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executeCambiarAjustaPorInflacion(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executeCambiarAjustaPorInflacion = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION,
    loading
  };
};

export const loadInflacion = () => {
  return (dispatch) => {
    dispatch(loadInflacionStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}configuraciones/Inflacion?listaSoloUltimoAnio=true`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInflacionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadInflacionFail(error));
      });
  };
};

const loadInflacionStart = () => {
  return {
    type: actionTypes.LOAD_INFLACION_START
  };
};

const loadInflacionSuccess = (inflaciones) => {
  return {
    type: actionTypes.LOAD_INFLACION_SUCCESS,
    inflaciones
  };
};

const loadInflacionFail = (error) => {
  return {
    type: actionTypes.LOAD_INFLACION_FAIL,
    error
  };
};

export const LoadInflacionPorAnio = () => {
  return (dispatch) => {
    dispatch(loadInflacionPorAnioStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}configuraciones/InflacionPorAnio`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInflacionPorAnioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadInflacionPorAnioFail(error));
      });
  };
};

const loadInflacionPorAnioStart = () => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_START
  };
};

const loadInflacionPorAnioSuccess = (inflaciones) => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_SUCCESS,
    inflaciones
  };
};

const loadInflacionPorAnioFail = (error) => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_FAIL,
    error
  };
};

export const loadNovedades = (mostrarTodasLasNovedades) => {
  return (dispatch) => {
    dispatch(loadNovedadesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `${baseUrlDashboard()}configuraciones/Novedades?aplicacionId=${1}&mostrarTodasLasNovedades=${mostrarTodasLasNovedades}`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        dispatch(loadNovedadesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadNovedadesFail(error));
      });
  };
};

const loadNovedadesStart = () => {
  return {
    type: actionTypes.LOAD_NOVEDADES_START
  };
};

const loadNovedadesSuccess = (novedades) => {
  return {
    type: actionTypes.LOAD_NOVEDADES_SUCCESS,
    novedades
  };
};

const loadNovedadesFail = (error) => {
  return {
    type: actionTypes.LOAD_NOVEDADES_FAIL,
    error
  };
};

export const descartarNovedades = (success, fail) => {
  return (dispatch, getState) => {
    const novedades = getState().configuraciones.loadNovedades.novedades;
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`${baseUrlDashboard()}configuraciones/DescartarNovedades`, novedades, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        return success && success();
      })
      .catch((error) => {
        return fail && fail();
      });
  };
};

export const loadEstadosCheques = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEstadosChequesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`${baseUrlDashboard()}cheques/Estados?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.estadosCheques}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadEstadosChequesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadEstadosChequesFail(error));
      });
  };
};

const loadEstadosChequesStart = () => {
  return {
    type: actionTypes.LOAD_ESTADOS_CHEQUES_START
  };
};

const loadEstadosChequesSuccess = (estados) => {
  return {
    type: actionTypes.LOAD_ESTADOS_CHEQUES_SUCCESS,
    estados
  };
};

const loadEstadosChequesFail = (error) => {
  return {
    type: actionTypes.LOAD_ESTADOS_CHEQUES_FAIL,
    error
  };
};

export const loadChequesValorEstado = ({ propios, idEmpresa = storageEmpresaId(), success, errorFail }) => {
  return (dispatch) => {
    !success && dispatch(loadChequesValorEstadoStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `${baseUrlDashboard()}cheques/ValoresYEstados?propios=${propios}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.chequesValorEstado
        }`,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        !success && dispatch(loadChequesValorEstadoSuccess(response.data));
        return success && success(response.data);
      })
      .catch((error) => {
        !success && dispatch(loadChequesValorEstadoFail(error));
        return errorFail && errorFail(error);
      });
  };
};

const loadChequesValorEstadoStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_VALOR_ESTADO_START
  };
};

const loadChequesValorEstadoSuccess = (chequesValorEstado) => {
  return {
    type: actionTypes.LOAD_CHEQUES_VALOR_ESTADO_SUCCESS,
    chequesValorEstado
  };
};

const loadChequesValorEstadoFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_VALOR_ESTADO_FAIL,
    error
  };
};

export const postChequesValorEstado = (valoresYEstados, propios, idEmpresa = storageEmpresaId(), success, fail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(
        `${baseUrlDashboard()}cheques/ValoresYEstados?propios=${propios}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.postChequesValorEstado
        }`,
        valoresYEstados,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success();
      })
      .catch((error) => {
        return fail && fail();
      });
  };
};

export const cambiarOrdenStockValorizado = (idUsuario, orden, success) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .put(
        `${baseUrlDashboard()}configuraciones/CambiarOrdenStockValorizado?idUsuario=${idUsuario}&orden=${orden}`,
        {},
        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        if (success) success();
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const cambiarOrdenDeudaCliente = (idUsuario, orden, success) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .put(
        `${baseUrlDashboard()}configuraciones/CambiarOrdenDeudaCliente?idUsuario=${idUsuario}&orden=${orden}`,
        {},
        { headers: { Authorization: authStr } }
      )
      .then((response) => {
        if (success) success();
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const clearModificacionesConfiguracion = () => {
  return {
    type: actionTypes.CLEAR_MODIFICACION_CONFIGURACION
  };
};
