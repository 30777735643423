import React from "react";
import CustomSelector from "../../CustomSelector/CustomSelector";

const SubtotalSelector = (props) => {
  const { selectedTotalRows, selectItems, onSelectedTotalRowsChange } = props;

  return (
    <CustomSelector
      id='mostrarSubtotales'
      label='Mostrar subtotales en filas'
      labelSize='medium'
      value={selectedTotalRows}
      items={selectItems}
      onChange={onSelectedTotalRowsChange}
      multiple
      selectAll
    />
  )
}

export default SubtotalSelector;