import React, { Fragment, useEffect, useRef, useState } from 'react';
import classes from './TablaPorLinea.css';
import { formatNumber, formatNumberWithoutDecimals, formatNumberWithoutDecimalsWithMonedaSigno, isEven } from '../../../../../../shared/formatNumber';
import Table from '../../../../../UI/Table/Table';
import TablaPorArticulo from '../TablaPorArticulo/TablaPorArticulo';
import { ChevronRight, KeyboardArrowUp } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Grid, TableCell, TableHead, TableRow } from '@material-ui/core';
import TablaPorLineaToExcel from './TablaPorLineaToExcel';
import { MTableBody } from 'material-table';
import Spinner from '../../../../../UI/Spinner/Spinner';
import ButtonPivotGrid from '../../../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid';
import ExportToPdfIcon from '../../../../../UI/Icons/ExportToPdfIcon';

const TablaPorLinea = (props) => {
  const { nombreEmpresa, datos, loading, objetivo, onExport } = props;
  const [expandAll, setExpandAll] = useState(false);
  const [loadingExpand, setLoadinExpand] = useState(false);

  const headersStyle = {
    fontSize: '12x',
    paddingBlock: '5px',
    paddingInline: '5px',
    backgroundColor: '#fff0f6',
    position: 'sticky',
    top: '0px',
    zIndex: 20
  };

  const cellStyles = (value, width, upper = false) => {
    return {
      fontSize: '12px',
      paddingBlock: '5px',
      paddingInline: '5px',
      color: value < 0 && 'red',
      textTransform: upper && 'uppercase',
      width: width
    };
  };

  const renderRow = (rowData, field) => {
    const monto = objetivo && objetivo.monto;

    if (!rowData.rubro)
      return '';

    if (rowData[field] === undefined || rowData[field] === null)
      return `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(0) : formatNumberWithoutDecimals(0)}`;

    let rubro = rowData.rubro.replace(/\s+/g, '').toLowerCase();
    return rubro === 'cumpl.volumen' || rubro === 'cumpl.canal' || rubro === 'mixcanal' || rubro === 'base' || rubro === 'cumplimiento'
      ? `${formatNumber(rowData[field] * 100)}%`
      : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData[field]) : formatNumberWithoutDecimals(rowData[field])}`;
  }

  const renderTotalColumn = (rowData) => {
    const monto = objetivo && objetivo.monto;

    if (!rowData.rubro)
      return '';

    let rubro = rowData.rubro.replace(/\s+/g, '').toLowerCase();
    return rubro === 'cumpl.volumen' || rubro === 'mixcanal' || rubro === 'base' || rubro === "cumplimiento"
      ? `${formatNumber(rowData.total * 100)}%`
      : rubro === 'cumpl.canal'
        ? ''
        : `${monto ? formatNumberWithoutDecimalsWithMonedaSigno(rowData.total) : formatNumberWithoutDecimals(rowData.total)}`;
  }

  const columns = [
    {
      title: `${nombreEmpresa}`,
      field: 'rubro',
      align: 'left',
      cellStyle: (cellData) => cellStyles(cellData, '23%', true),
      headerStyle: headersStyle,
      width: '23%'
    },
    {
      title: 'Total',
      field: 'total',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => renderTotalColumn(rowData),
      width: '5%'
    },
    {
      title: 'Mix',
      field: 'mix',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => nombreEmpresa === "TENACTA" || objetivo.key === 'precioUnidad' || rowData.rubro && (rowData.rubro === 'Mix canal' || rowData.rubro === 'Base' || rowData.rubro.includes('Cumpl') || rowData.rubro.includes('Pendientes')) ? '' : `${formatNumber(rowData.mix * 100)}%`,
      width: '5%'
    },
    {
      title: 'Cumpl.',
      field: 'cumplimiento',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => nombreEmpresa === "TENACTA" || rowData.rubro && (rowData.rubro === 'Mix canal' || rowData.rubro === 'Base' || rowData.rubro.includes('Cumpl') || rowData.rubro.includes('Pendientes') || rowData.rowType === 1) ? '' : `${formatNumber(rowData.cumplimiento * 100)}%`,
      width: '5%'
    },
    {
      title: 'Superm.',
      field: 'supermercados',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '5%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'supermercados'),
      width: '5%'
    },
    {
      title: 'Gr. Retails',
      field: 'grandesRetails',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '7%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'grandesRetails'),
      width: '7%'
    },
    {
      title: 'Med.Retails',
      field: 'medianosRetails',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'medianosRetails'),
      width: '8%'
    },
    {
      title: 'E-commerce',
      field: 'ecommerce',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'ecommerce'),
      width: '8%'
    },
    {
      title: 'Web Propia',
      field: 'webPropia',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'webPropia'),
      width: '8%'
    },
    {
      title: 'Peq. Retails',
      field: 'pequeniosRetails',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '8%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'pequeniosRetails'),
      width: '8%'
    },
    {
      title: 'Droguer.',
      field: 'droguerias',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '7%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'droguerias'),
      width: '7%'
    },
    {
      title: 'Farmac.',
      field: 'farmacias',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '6%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'farmacias'),
      width: '6%'
    },
    {
      title: 'Ortoped.',
      field: 'ortopedias',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => cellStyles(cellData, '6%'),
      headerStyle: headersStyle,
      render: (rowData) => renderRow(rowData, 'ortopedias'),
      width: '6%'
    }
  ];

  const data = () => {
    return datos && datos.map((venta) => {
      return { ...venta };
    });
  };

  const onExpandClick = (expand) => {
    setLoadinExpand(true);
    setExpandAll(expand);
    setTimeout(() => setLoadinExpand(false), 500);
  }

  const renderToolbar = () => {
    return (
      <Grid container spacing={1} direction='row' justifyContent='flex-end'>
        <Grid item xs={12} md={6} lg={3} container direction='row' justifyContent='flex-end'>
          <Grid item xs={4}>
            {nombreEmpresa === "TENACTA" ?
              <Fragment></Fragment>
              : <ButtonPivotGrid
                tooltipTitle={expandAll ? "Colapsar rubros" : "Expandir rubros"}
                buttonSize="small"
                text={expandAll ? "Colapsar" : "Expandir"}
                onClick={() => onExpandClick(!expandAll)}
                icon={expandAll ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                disabled={loadingExpand || !datos || datos.length <= 0}
              />
            }
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'end' }}>
            <ExportToPdfIcon
              element={customPivotGridRef && customPivotGridRef.current}
              fileName={`Informe 2 - ${objetivo && objetivo.nombre}`}
              tooltipTitle={"Descargar tabla en Pdf"}
              beforeExport={() => onExportStart()}
              afterExport={() => onExportFinish()}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'end' }}>
            <TablaPorLineaToExcel
              data={data()}
              columns={columns}
              name={nombreEmpresa}
              filename={`Informe 2 - ${objetivo && objetivo.nombre}`}
              tooltipTitle={"Descargar tabla en Excel"}
              monto={objetivo && objetivo.monto}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const tableRef = useRef();

  //#region Export a PDF
  const customPivotGridRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('550px');
  const [onLocalExport, setOnLocalExport] = useState(false);

  const onExportStart = () => {
    setOnLocalExport(true);
    setMaxHeight('100%');
  }

  const onExportFinish = () => {
    setOnLocalExport(false);
    setMaxHeight('550px');
  }

  useEffect(() => {
    if (!onLocalExport && onExport && maxHeight !== '100%')
      setMaxHeight('100%');
  }, [onLocalExport, onExport, maxHeight]);

  useEffect(() => {
    if (!onLocalExport && !onExport && maxHeight !== '550px')
      setMaxHeight('550px');
  }, [onLocalExport, onExport, maxHeight]);

  //#endregion

  return (
    <div ref={customPivotGridRef} className={classes.tablaVentasPorRubro} style={{ boxShadow: "0px 3px 6px #00000029" }}>
      {loadingExpand
        ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <Fragment>
            <div style={{ backgroundColor: '#fff0f6' }}>
              {renderToolbar()}
            </div>
            <Table
              noBoxShadow
              tableRef={tableRef}
              style={{
                marginTop: 0, marginBottom: '10px', border: "0px solid #ccc", backgroundColor: "white", borderRadius: "3px"
              }}
              title=""
              columns={columns}
              loading={loading}
              data={data()}
              detailPanel={[
                {
                  disabled: false,
                  tooltip: 'Ver artículos',
                  icon: () => <ChevronRight fontSize='small' />,
                  openIcon: () => <KeyboardArrowDownIcon fontSize='small' />,
                  render: (rowData) => {
                    return rowData.calculo && rowData.rubro !== "Pendientes" ?
                      (
                        <Fragment></Fragment>
                      ) : (
                        <div className={classes.tablaVentasPorArticulo}>
                          <div style={{ width: '100%', paddingLeft: '50px' }}>
                            <TablaPorArticulo
                              idLinea={rowData.idLinea}
                              idRubro={rowData.idRubro}
                              rowType={rowData.rowType}
                              articulos={rowData.articulos}
                              objetivo={objetivo}
                              pendientes={rowData.rubro === "Pendientes"}
                            />
                          </div>
                        </div>
                      );
                  }
                }
              ]}
              customOptions={{
                thirdSortClick: false,
                sorting: false,
                grouping: false,
                draggable: false,
                search: false,
                paging: false,
                toolbar: false,
                showTitle: false,
                maxBodyHeight: maxHeight,
                rowStyle: (rowData, index) => ({
                  backgroundColor: rowData.rowType === 0
                    ? !isEven(index) ? '#FBE4B5' : '#FFDD98'
                    : rowData.rowType === 1
                      ? !isEven(index) ? '#E4D8FA' : '#DBCAFE'
                      : !isEven(index) ? '#9EF7B7' : '#8FE1A7',
                  fontWeight: rowData.calculo && 'bold',
                  fontSize: rowData.rowType === 2 ? '12px' : '11px'
                }),
                defaultExpanded: true
              }}
              components={{
                Header: (props) => {
                  return (
                    <TableHead style={{ backgroundColor: '#fff0f6' }}>
                      <TableRow>
                        <TableCell style={{ maxWidth: '5px' }}></TableCell>
                        <TableCell style={headersStyle} align="left" >
                          {nombreEmpresa}
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Total
                        </TableCell>
                        <TableCell style={headersStyle} align="right">
                          Mix
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Cumpl.
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Superm.
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Gr. Retails
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Med.Retails
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          E-commerce
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Web Propia
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Peq. Retails
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Droguer.
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Farmac.
                        </TableCell>
                        <TableCell style={headersStyle} align="right" >
                          Ortoped.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  );
                },
                Body: (props) => {
                  if (expandAll) {
                    let renderData = props.renderData
                    renderData.forEach((d, i) => {
                      if (!renderData[i].tableData.hasOwnProperty("showDetailPanel"))
                        renderData[i].tableData.showDetailPanel = props.detailPanel[0].render
                    })
                    props = { ...props, renderData: renderData }
                  }
                  return <MTableBody {...props} />
                }
              }}
            />
          </Fragment>
        )}
    </div>
  );
};

export default TablaPorLinea;