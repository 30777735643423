/*eslint-disable */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../shared/sessionData';

import classes from './TablaGastosPorItemDeCateogoria.css';
import TableComprobantesGastos from '../../Comprobantes/TableComprobantesGastos/TableComprobantesGastos';
import ExportToExcelIcon from '../../../UI/Icons/ExportToExcelIcon';
import { IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const TablaGastosPorItemDeCateogoria = (props) => {
  const { gastos, child, categoriaItem, title, subTitle, filename, sheetName } = props;
  const [gastosLocal, setGastosLocal] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [estaCargado, setEstaCargado] = useState(false);
  const mes = props.fechaBusqueda ? props.fechaBusqueda.getMonth() + 1 : 0;
  const anio = props.fechaBusqueda ? props.fechaBusqueda.getFullYear() : 0;

  useEffect(() => {
    if (child && !estaCargado) {
      if (categoriaItem) {
        setLoadingLocal(true);
        props.onLoadGastosDeCat(categoriaItem.categoriaItemId, categoriaItem.categoria, mes, anio,
          (response) => {
            setGastosLocal(response);
            setLoadingLocal(false);
            setEstaCargado(true);
            props.endRequest && props.endRequest(response.length);
          }
        );
      }
    }
  }, [child, estaCargado, categoriaItem]);

  useEffect(() => {
    if (!child && !estaCargado) {
      setLoadingLocal(true);
      setGastosLocal(gastos);
      setEstaCargado(true);
      setTimeout(() => setLoadingLocal(false), 500);
    }
  }, [child, estaCargado]);

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fafafa'
  }

  const childHeaderStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(235, 235, 235)',
    height: '5px'
  }

  const columns = [
    {
      title: 'Item',
      field: 'item',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: child ? childHeaderStyle : headerStyle
    },
    {
      title: `${child ? "" : "Porc. Participación"}`,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: child ? childHeaderStyle : headerStyle,
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: `${child ? "" : "Monto"}`,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: child ? childHeaderStyle : headerStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return gastosLocal.map((gasto) => ({
      itemId: gasto.itemDeCompraId,
      item: gasto.nombre,
      monto: gasto.monto,
      porcentajeDeParticipacion: gasto.porcentajeDeParticipacion
    }));
  };

  //#region Export to Excell
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
    };
  };

  const columnsForExcel = () => {
    const arrColumns = [
      {
        title: "Item",
        width: { wpx: 150 },
        align: 'left',
        style: headersStyle
      },
      {
        title: "Porc. Participación",
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      },
      {
        title: 'Monto',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      }
    ];

    return arrColumns;
  }

  const formatData = () => {
    let tableData = [];

    gastos && gastos.map((gasto) => {
      const row = [
        {
          value: gasto.nombre,
          style: { font: { sz: '10' } }
        },
        {
          value: `${formatNumber(gasto.porcentajeDeParticipacion)}%`,
          style: cellsStyleVerifNegative(gasto.porcentajeDeParticipacion)
        },
        {
          value: `${storageMonedaSigno()}${formatNumber(gasto.monto)}`,
          style: cellsStyleVerifNegative(gasto.monto)
        }
      ];

      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnsForExcel(),
      data: formatData()
    }
  ];
  //#endregion

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest();
    }
  };

  const renderTable = () => {
    return (
      <div className={classes.tablaGastosPorItemDeCat}>
        <Table
          tableRef={tableRef}
          title=""
          style={{ width: '95%', marginTop: 0, marginBottom: '10px' }}
          columns={columns}
          data={data()}
          loading={loadingLocal}
          detailPanel={(rowData) => {
            setTimeout(() => {
              autoScroll(rowData);
            }, 100);

            return (
              <TableComprobantesGastos
                customWidth={'90%'}
                itemId={rowData.itemId}
                endRequest={() => autoScroll(rowData)}
                mes={mes}
                anio={anio}
              />
            );
          }}
          customOptions={{
            thirdSortClick: false,
            sorting: true,
            grouping: false,
            draggable: false,
            search: false,
            paging: false,
            toolbar: false,
            showTitle: false,
            maxBodyHeight: 316,
            rowStyle: (rowData) => ({
              color: rowData.tableData.monto < 0 && 'red',
              backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
            })
          }}
          tableKey={"tabla-gastos-por-item-de-categoria"}
        />
      </div>
    )
  }

  const renderChildTable = () => {
    return (
      <div className={classes.tablaGastosPorItemDeCat}>
        {renderTable()}
      </div>
    )
  }

  const renderMasterTable = () => {
    return (<div className={classes.TablaGastosMensualesPorCatContainer}>
      <div className={classes.TitleCard}>
        <p style={{ fontWeight: 'bold', marginLeft: '20px', marginRight: '20px', marginBottom: '1px' }}>
          {title}
        </p>
        <div>
          <ExportToExcelIcon
            filename={filename}
            name={sheetName}
            dataSet={multiDataSet}
          />
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <p style={{ marginTop: '1px', marginLeft: '20px', marginBottom: '2px', fontSize: '12px' }}>
        {subTitle}
      </p>
      {renderTable()}
    </div>)
  }

  return child ? (
    <Fragment>
      {renderChildTable()}
    </Fragment>) : (
    <Fragment>
      {renderMasterTable()}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    nombreCategoria: state.gastos.gastosPorItemDeCategoria.nombreCategoria,
    fecha: state.gastos.gastosPorItemDeCategoria.fecha,
    loading: state.gastos.gastosPorItemDeCategoria.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUnmountComponent: () => dispatch(actions.unmountGastosPorItemDeCategoriaSelec()),
    onLoadGastosDeCat: (idCategoria, nombreCategoria, mes, anio, success) =>
      dispatch(
        actions.loadGastosPorItemDeCategoriaSelec(idCategoria, nombreCategoria, mes, anio, undefined, true, success)
      ),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Detalle de la categoría', ['gasto']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaGastosPorItemDeCateogoria);